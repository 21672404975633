export const _SELF_CONTAINED_COUNT = {
  TILES: 'tiles',
  SLATE: 'slate',
  STONE: 'stone',
  FELT: 'felt',
  ASPHALT: 'asphalt',
  CONCRETE: 'concrete',
  METAL: 'metal',
  THATCH: 'thatch',
  OTHER: 'other',
};

_SELF_CONTAINED_COUNT.OPTIONS = Array.from('1234').map(e => {
  return {
    code: e,
    text: e
  }
});

_SELF_CONTAINED_COUNT.OPTIONS.unshift({
  code: '',
  text: ''
});

_SELF_CONTAINED_COUNT.OPTIONS.push({
  code: '5+',
  text: '5+'
});
