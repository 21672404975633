import {_OTHER} from "./other";

export const _ACCOUNTANT_QUALIFICATION_TYPE = {
};

_ACCOUNTANT_QUALIFICATION_TYPE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: 'FCA',
        text: 'FCA'
    },
    {
        code: 'ACA',
        text: 'ACA'
    },
    {
        code: 'ACCA',
        text: 'ACCA'
    },
    {
        code: 'CIMA',
        text: 'CIMA'
    },
    {
        code: 'ScottishCA',
        text: 'Scottish CA'
    },
    {
        code: _OTHER,
        text: 'Other (add)'
    }
];