export const _GENDER_TYPE = {
    MALE: 'no',
    FEMALE: 'single',
    NO_SAY: 'double',
    OTHER: 'other'
};

_GENDER_TYPE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    { 
        code: _GENDER_TYPE.MALE, 
        text: 'Male' 
    },
    {
        code: _GENDER_TYPE.FEMALE, 
        text: 'Female' 
    },
    {
        code: _GENDER_TYPE.NO_SAY, 
        text: 'Prefer not to say' 
    },
    {
        code: _GENDER_TYPE.OTHER,
        text: 'Other (add)'
    }
];