import React, { Component } from 'react';
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";

class ControlButtons extends Component {
    static propTypes = {
        nextPage: PropTypes.func,
        nextPath: PropTypes.string,
        nextLabel: PropTypes.string,
        previousPage: PropTypes.func,
        previousPath: PropTypes.string
    };

    onPreviousClick = () => {
        this.props.previousPage && this.props.previousPage();
        this.props.previousPath && this.props.history.push(this.props.previousPath);
    };

    onNextClick = () => {
        this.props.nextPage && this.props.nextPage();
        this.props.nextPath && this.props.history.push(this.props.nextPath);
    };

    render() {
        let backClazz = (this.props.nextPage || this.props.nextPath) ? 'btn-secondary' : 'btn-primary';

        return (
            <div className="control-buttons text-center">
                {
                    (this.props.previousPage || this.props.previousPath) &&
                    <button key="previous"
                        className={`btn ${backClazz} previous`}
                        onClick={this.onPreviousClick}><span className='button-text'>Back</span></button>
                }
                {
                    (this.props.nextPage || this.props.nextPath) &&
                    <button key="next"
                        className="btn btn-primary next"
                        onClick={this.onNextClick}><span className='button-text'>{this.props.nextLabel || 'Next'}</span></button>
                }
            </div>
        );
    }
}

export default withRouter(ControlButtons);
