export const _EPC_RATING = {
    A: 'a',
    B: 'b',
    C: 'c',
    D: 'd',
    E: 'e',
    F: 'f',
    G: 'g',
    EXEMPT: 'exempt',
    UNKNOWN: 'unknown'
};

_EPC_RATING.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: _EPC_RATING.A,
        text: 'A'
    },
    {
        code: _EPC_RATING.B,
        text: 'B'
    },
    {
        code: _EPC_RATING.C,
        text: 'C'
    },
    {
        code: _EPC_RATING.D,
        text: 'D'
    },
    {
        code: _EPC_RATING.E,
        text: 'E'
    },
    {
        code: _EPC_RATING.F,
        text: 'F'
    },
    {
        code: _EPC_RATING.G,
        text: 'G'
    },
    {
        code: _EPC_RATING.EXEMPT,
        text: 'Exempt'
    },
    {
        code: _EPC_RATING.UNKNOWN,
        text: 'Unknown'
    }
];

_EPC_RATING.showAdvisorWarning = (code) => {
    return [
        _EPC_RATING.F,
        _EPC_RATING.G,
        _EPC_RATING.EXEMPT,
        _EPC_RATING.UNKNOWN
    ].includes(code);
};
