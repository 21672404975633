export const _EMPLOYEE_TYPE = {
    PERMANENT: 'permanent',
    TEMP: 'tempfixed',
    ZERO: 'zero'
};

_EMPLOYEE_TYPE.OPTIONS = [
    { code: _EMPLOYEE_TYPE.PERMANENT, text: 'Permanent'},
    { code: _EMPLOYEE_TYPE.TEMP, text: 'Temporary or fixed term'},
    { code: _EMPLOYEE_TYPE.ZERO, text: 'Zero hours or worker'},
];
