import * as React from "react";

import QuestionBlock from "../../../../form/QuestionBlock";
import {FieldLabel} from "common-form/components/FieldLabel";
import {LABEL_TYPE} from "../../../../form/components/Field";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldRadioGroup from "../../../../form/components/radio/FieldRadioGroup";
import FieldDate from "common-form/components/FieldDate";
import FieldTextArea from "../../../../form/components/FieldTextArea";
import TimeDiff from "../../../../form/components/TimeDiff";

import {
    FIELDS,
    REPAYMENT_PREFERENCE_TYPE
} from "common-hypotheca";


export default function MortgageFeatures(parent, nextQuestionId) {

    function prefersPayAsMuch() {
        return parent.getValue(FIELDS.MM.MP.PreferencesAdditionalRepayments) === REPAYMENT_PREFERENCE_TYPE.MUCH;
    }

    return {
        block: (params) =>

            <QuestionBlock {...params}>

                <FieldLabel
                    label="In terms of other mortgage features, tell us what you prefer"
                />

                <FieldRadioGroup
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MP.PreferencesAdditionalRepayments)}
                />

                {
                    prefersPayAsMuch() && 

                    <FieldTextArea
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MM.MP.PreferencesAdditionalRepaymentsReason)}
                    />

                }


                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MP.RequireFreeLegalFees)}
                />
                <FieldTextArea
                    labelType={LABEL_TYPE.Small}
                    show={() => parent.getValue(FIELDS.MM.MP.RequireFreeLegalFees) === true}
                    placeholder="Optional supporting text ..."
                    {...parent.getField(FIELDS.MM.MP.RequireFreeLegalFeesReason)}
                />


                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MP.RequireNoValuationFees)}
                />
                <FieldTextArea
                    labelType={LABEL_TYPE.Small}
                    show={() => parent.getValue(FIELDS.MM.MP.RequireNoValuationFees) === true}
                    placeholder="Optional supporting text ..."
                    {...parent.getField(FIELDS.MM.MP.RequireNoValuationFeesReason)}
                />


                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MP.AddProductFeesToLoanAmount)}
                />
                <FieldTextArea
                    labelType={LABEL_TYPE.Small}
                    show={() => parent.getValue(FIELDS.MM.MP.AddProductFeesToLoanAmount) === true}
                    {...parent.getField(FIELDS.MM.MP.AddProductFeesToLoanAmountReason)}
                />


                <div className="date-boxes">
                <FieldDate
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MP.MortgageTargetCompletionDate)}
                />
                </div>

                <TimeDiff 
                    label="Target date is {0} away."
                    fromValue={new Date()}
                    format="ymd"
                    toValue={parent.getValue(FIELDS.MM.MP.MortgageTargetCompletionDate)}
                />

                <FieldTextArea
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MP.MortgageTargetCompletionDateReason)}
                />

            </QuestionBlock>,


        validate: (context) => {

            context           
            .mandatory(FIELDS.MM.MP.PreferencesAdditionalRepayments)
            .mandatory(FIELDS.MM.MP.RequireFreeLegalFees)
            .mandatory(FIELDS.MM.MP.RequireNoValuationFees)
            .mandatory(FIELDS.MM.MP.AddProductFeesToLoanAmount)
            .date(FIELDS.MM.MP.MortgageTargetCompletionDate, null, 'Please enter a future date')
            .dateMin(FIELDS.MM.MP.MortgageTargetCompletionDate, new Date(), 'Please enter a future date')
            .optional(FIELDS.MM.MP.MortgageTargetCompletionDateReason)
            ;

            context
            .mandatoryOrCleanup(prefersPayAsMuch(), FIELDS.MM.MP.PreferencesAdditionalRepaymentsReason)
            ;

            context
            .optionalOrCleanup(parent.getValue(FIELDS.MM.MP.RequireFreeLegalFees) === true, FIELDS.MM.MP.RequireFreeLegalFeesReason)
            .optionalOrCleanup(parent.getValue(FIELDS.MM.MP.RequireNoValuationFees) === true, FIELDS.MM.MP.RequireNoValuationFeesReason)
            .mandatoryOrCleanup(parent.getValue(FIELDS.MM.MP.AddProductFeesToLoanAmount) === true, FIELDS.MM.MP.AddProductFeesToLoanAmountReason)
            ;

        },

        nextQuestionId: () => nextQuestionId
    };

};