export const _MARKETING_SOURCE_LETTING_AGENT_OTHER = 'other';

export const _MARKETING_SOURCE_LETTING_AGENT = [
    {
        code: '',
        text: ''
    },
    {
        code: 'holiday_cottages',
        text: 'Holiday Cottages',
        obsolete: true
    },
    {
        code: 'lakelovers',
        text: 'Lakelovers'
    },
    {
        code: 'character_cottages',
        text: 'Character Cottages'
    },
    {
        code: 'cottages_com',
        text: 'Cottages.com'
    },
    {
        code: _MARKETING_SOURCE_LETTING_AGENT_OTHER,
        text: 'Other (add)'
    }
];