import React, { Fragment } from "react";
import { FormButton } from '../../form/components';
import Breadcrumbs from "../../status/Breadcrumbs";
import Autosave from "./Autosave";
import ProgressBarContainer from "./ProgressBarContainer";
import {dashboardLink} from "../../util/links";
import {getValuePossiblyInteger} from "../../util/values";

class Header extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            previousApplicantId: 0
        };
      //  console.log('Header constr', props);
    }

    componentDidUpdate() {
      //  console.log('Header uopdate', this.props, this.state);
    }
   
    render() {
        return (
            <Fragment>
                <div className='row'>
                    <div className='col-12 application-header'>
                        <Breadcrumbs {...this.props} />
                        <FormButton
                            link
                            text={`Save & Exit`}
                            path={dashboardLink(getValuePossiblyInteger(this.props.match.params.sectionId), getValuePossiblyInteger(this.props.match.params.applicantId))} />
                        <Autosave {...this.props} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 no-padding'>
                       <ProgressBarContainer />
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Header;
