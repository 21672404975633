export const _APPLICATION_TYPE = {
    /**
     * @deprecated not used anymore
     */
    DECISION_IN_PRINCIPLE: {
        code: 'decision_in_principle',
        desc: 'Decision in Principle'
    },

    NEW_MORTGAGE_TO_BUY: {
        code: 'new_mortgage_to_buy',
        desc: 'New Mortgage to Buy',
    },
    MORTGAGE_EXISTING_LET: {
        code: 'mortgage_existing_let',
        desc: 'Mortgage existing Let',
    },

    /**
     * @deprecated This is now determined by mortgage purpose.
     */
    REMORTGAGE_EXISTING_LET: {
        code: 'remortgage_existing_let',
        desc: 'Re-Mortgage existing Let',
    },
};

