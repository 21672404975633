export const _PURCHASE_STAGE = {
    STILL_BROWSING: 'still_browsing',
    ABOUT_TO_OFFER: 'about_to_offer',
    OFFER_ACCEPTED: 'offer_accepted',
    NEED_MORTGAGE: 'need_mortgage'
};

_PURCHASE_STAGE.OPTIONS = [
    {
        code: _PURCHASE_STAGE.STILL_BROWSING,
        text: 'Still browsing',
        icon: 'search'
    },
    {
        code: _PURCHASE_STAGE.ABOUT_TO_OFFER,
        text: 'About to offer',
        icon: 'local_offer'
    },
    {
        code: _PURCHASE_STAGE.OFFER_ACCEPTED,
        text: 'Offer accepted',
        icon: 'accessibility_new'
    },
    {
        code: _PURCHASE_STAGE.NEED_MORTGAGE,
        text: 'Need a mortgage now!',
        icon: 'priority_high'
    }
];