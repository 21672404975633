import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import { wrap } from './util/wrap';

// Polyfills
require ('core-js/library/fn/object/assign');
require ('core-js/library/fn/promise');

require('ideal-postcodes-core');
require('ideal-postcodes-autocomplete');

const domNode = document.getElementById('root');
const root = domNode === null ? null : createRoot(domNode);

if (domNode !== null) {
    root.render(wrap(<App/>));
}
