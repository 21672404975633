import React from "react";

import PropTypes from "prop-types";
import Field from "../../../../../form/components/Field";
import FieldLabel from "common-form/components/FieldLabel";
import FieldInput from "common-form/components/FieldInput";
import FieldCurrency from "../../../../../form/components/FieldCurrency";
import { LABEL_TYPE } from "../../../../../form/components/Field";
import {extractFieldName, extractFrontendLabel} from "common-hypotheca";

class FinancialTable extends Field {

    constructor(props) {
        super(props);

        let totalYears = this.props.totalYears;
        if (!totalYears) {
            totalYears = 3;
        }

        let arr = this.props.container[extractFieldName(this.props.field)];
        if (!arr) {
            arr = [];
        }

        while(arr.length < totalYears) {
            arr.push({});
        }

        this.props.container[extractFieldName(this.props.field)] = arr;

        this.state = {
            value: arr
        };

        this.checkValidity();

        this.props.onValidity({
            isValid: this.state.isValid
        });

    }

    extractFieldName(field) {
        return field.name || field;
    }

    getValue(index, tableField) {
        if (this.state.value[index][this.extractFieldName(tableField)] === undefined)
            this.state.value[index][this.extractFieldName(tableField)] = null;

        return this.state.value[index][this.extractFieldName(tableField)];
    }

    checkValidity() {

        const rows = this.state.value;
        for(let rowIndex = 0; rowIndex < rows.length; rowIndex++) {

            if (this.getError(rowIndex, this.props.periodField)) {
                
                this.state = {
                    ...this.state,
                    isValid: false
                };
                return;

            }

            for(let cellIndex = 0; cellIndex < this.props.currencyFields.length; cellIndex++) {

                const field = this.props.currencyFields[cellIndex];
                const fieldObj = field.field || field;

                if (this.getError(rowIndex, fieldObj)) {

                    this.state = {
                        ...this.state,
                        isValid: false
                    };
                    return;
                    
                }
            };
           
        };

        this.state = {
            ...this.state,
            isValid: true
        };

    }

    handleChange(index, tableField, app, field, value) {       
        this.state.value[index][this.extractFieldName(tableField)] = value;

        if (this.props.onChange) {

            this.checkValidity();

            this.props.onChange();

            this.props.onValidity({
                isValid: this.state.isValid
            });
        }
    }

    getError(index, field) {
        const value = this.state.value[index][this.extractFieldName(field)];
        return index > 1 || (value != null && (typeof(value) === 'number' || (value !== '-' && value.length > 0))) ? '' : 'required';
    }

    getDisplayError(index, field) {
        if (this.props.validate ) {
            return this.getError(index, field)
        } else {
            return '';
        }
    }

    
    renderCurrencies(i) {

        const inputs = [];
        for(const field of this.props.currencyFields) {

            const fieldObj = field.field || field;
            const tooltip = field.tooltip || null;

            inputs.push(
              
                <div key={`${this.extractFieldName(fieldObj)}${i}`} className="col-12 col-sm-12 col-lg">

                    <FieldCurrency
                        label={extractFrontendLabel(fieldObj)}
                        labelType={LABEL_TYPE.Small}
                        field={this.extractFieldName(fieldObj)}
                        error={this.getDisplayError(i, fieldObj)}
                        value={this.getValue(i, fieldObj)}
                        handleFieldFilled={this.handleChange.bind(this, i, fieldObj)}
                        tooltip={tooltip}
                        allowNegative={field.allowNegative || false}
                    />

                </div>

            );
        }

        return inputs;
    }

    render() {

        const year = new Date().getFullYear();

        const blocks = [];

        blocks.push(
            <div key="financialTableTitle">
            <FieldLabel 
                label= {`Please enter your share of the historical financial information in the table below`}
            />

            <FieldLabel 
                label= {`Set-up your accounting period end dates for each year that you have trading results, starting with the most recent period to be completed.
                Please enter at least 2 years' worth of trading data and, if a field does not apply, then please enter £0.`}
                labelType={LABEL_TYPE.Description}
            />

            <FieldLabel 
                label= {`If it is easier for you, then feel free to input the salary and dividend figures for the last two tax years, as declared on your tax return.`}
                labelType={LABEL_TYPE.Description}
            />
            </div>
        );

        for(let i=0; i < this.state.value.length; i++) {

            blocks.push(

                <div key={i}>
                    <div className="form-row financial-table-row">

                        <div className="col-12 col-sm-12 col-lg">


                            <FieldInput
                                label={extractFrontendLabel(this.props.periodField)}
                                labelType={LABEL_TYPE.Small}
                                field={this.extractFieldName(this.props.periodField)}
                                error={this.getDisplayError(i, this.props.periodField)}
                                value={this.getValue(i, this.props.periodField)}
                                handleFieldFilled={this.handleChange.bind(this, i, this.props.periodField)}
                                placeholder={`03/${year-(i+1)}`}
                            />

                        </div>

                        {
                            this.renderCurrencies(i)
                        }


                    </div>

                    <hr/>

                </div>
            );
        }

        return blocks;
    }
}

FinancialTable.propTypes = {
    container: PropTypes.any.isRequired,
    field: PropTypes.any.isRequired,
    periodField: PropTypes.object.isRequired,
    currencyFields: PropTypes.array.isRequired,
    validate: PropTypes.bool,
    totalYears: PropTypes.number,
    onValidity: PropTypes.func,
    onChange: PropTypes.func
};

export default FinancialTable;
