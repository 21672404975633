import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";

import FieldDate from "common-form/components/FieldDate";
import FieldGroup from "../../../../../form/components/FieldGroup";
import FieldInput from "common-form/components/FieldInput";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import TimeDiff from "../../../../../form/components/TimeDiff";
import AddressLookup from "../../../../../form/components/AddressLookup";
import { LABEL_TYPE } from "../../../../../form/components/Field";

import {extractFieldName, FIELDS} from "common-hypotheca";
import { validateDirectorBasic } from "./validation";


export default function DirectorBasic(parent) {

    
    return {
        block: (params)=>
            <QuestionBlock {...params}>

                <FieldGroup
                    ref={params.groupAddableRef}
                    item={parent.getCurrentEmployment()}
                    className="date-boxes"
                    {...parent.getField(FIELDS.MS.EI.Employments)}
                    validate={(context) => {

                        validateDirectorBasic(parent, context, parent.state.employmentIndex);

                    }}
                >

                    <FieldInput
                        label="Company name"
                        field={FIELDS.MS.EI.EmploymentName}
                    />

                    <FieldInput
                        label="Company description"
                        field={FIELDS.MS.EI.EmploymentDescription}
                    />

                    <FieldInput
                        field={FIELDS.MS.EI.DirSharePctCapitalOwned}
                    />

                    <AddressLookup
                        label="Company address"
                        labelType={LABEL_TYPE.Large}
                        fields={[
                            FIELDS.MS.EI.DirShareTradingAddress, 
                            FIELDS.MS.EI.DirShareTradingAddress2,
                            FIELDS.MS.EI.DirShareTradingAddressTown,
                            FIELDS.MS.EI.DirShareTradingAddressCounty,
                            FIELDS.MS.EI.DirShareTradingAddressPostcode
                        ]}
                    />

                    <FieldDate
                        label="What date did you start with the company?"
                        field={FIELDS.MS.EI.EmploymentStartDate}
                    />

                    {
                        !parent.isFirstEmployment() &&
                        <FieldRadioYesNo
                            field={FIELDS.MS.EI.EmploymentIsCurrent}
                        />
                    }

                    <TimeDiff
                        applicable={
                            parent.isFirstEmployment()
                            || parent.getEmploymentField(extractFieldName(FIELDS.MS.EI.EmploymentIsCurrent)).value === true
                        }
                        updateFactFindFunction={parent.updateFactFindBeenInThisJobFor}
                        fromValue={parent.getEmploymentField(extractFieldName(FIELDS.MS.EI.EmploymentStartDate)).value}
                        toValue={new Date()}
                        format="ym"
                        label="You have been in this job for {0}."
                    />

                    <FieldDate
                        label="When did this job end?"
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.EmploymentIsCurrent)] === false}
                        field={FIELDS.MS.EI.EmploymentEndDate}
                    />


                </FieldGroup>

            </QuestionBlock>,
        nextQuestionId: () => parent.afterDirectorBasic()
    };



};