export const _ROOF_TYPE = {
    TILES: 'tiles',
    SLATE: 'slate',
    STONE: 'stone',
    FELT: 'felt',
    ASPHALT: 'asphalt',
    CONCRETE: 'concrete',
    METAL: 'metal',
    THATCH: 'thatch',
    OTHER: 'other',
};

_ROOF_TYPE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: _ROOF_TYPE.TILES,
        text: 'Tiles'
    },
    {
        code: _ROOF_TYPE.SLATE,
        text: 'Slate'
    },
    {
        code: _ROOF_TYPE.STONE,
        text: 'Stone'
    },
    {
        code: _ROOF_TYPE.FELT,
        text: 'Felt'
    },
    {
        code: _ROOF_TYPE.ASPHALT,
        text: 'Asphalt'
    },
    {
        code: _ROOF_TYPE.CONCRETE,
        text: 'Concrete'
    },
    {
        code: _ROOF_TYPE.METAL,
        text: 'Metal'
    },
    {
        code: _ROOF_TYPE.THATCH,
        text: 'Thatch'
    },
    {
        code: _ROOF_TYPE.OTHER,
        text: 'Other (add)'
    }
];