export const _PERSONAL_RISK_ITEMS = {
    PHYSICAL_DISABILITY: 'physical_disability',
    MENTAL_HEALTH_ISSUES: 'mental_health_issues',
    CARING_RESPONSIBILITIES: 'caring_responsibilities',
    DIFFICULTIES_LITERACY_NUMERACY_COMPUTER: 'difficulties_literacy_numeracy_computer',
    RECENT_LIFE_EVENT: 'recent_life_event',
    ADDICTION: 'addiction',
    LANGUAGE_BARRIER: 'language_barrier',
    THIRD_PARTY_PRESSURE: 'third_party_pressure',
    OTHER: 'other'
}

_PERSONAL_RISK_ITEMS.OPTIONS = [
    {
        code: _PERSONAL_RISK_ITEMS.PHYSICAL_DISABILITY,
        text: 'Physical Disability'
    },
    {
        code: _PERSONAL_RISK_ITEMS.MENTAL_HEALTH_ISSUES,
        text: 'Any mental health issues, including diagnosed conditions with cognitive impairment'
    },
    {
        code: _PERSONAL_RISK_ITEMS.CARING_RESPONSIBILITIES,
        text: 'Caring responsibilities'
    },
    {
        code: _PERSONAL_RISK_ITEMS.DIFFICULTIES_LITERACY_NUMERACY_COMPUTER,
        text: 'Difficulty with literacy, numeracy or computer skills'
    },
    {
        code: _PERSONAL_RISK_ITEMS.RECENT_LIFE_EVENT,
        text: 'Recent life event- redundancy, relationship breakdown or bereavement etc.'
    },
    {
        code: _PERSONAL_RISK_ITEMS.ADDICTION,
        text: 'Addiction'
    },
    {
        code: _PERSONAL_RISK_ITEMS.LANGUAGE_BARRIER,
        text: 'Language barrier'
    },
    {
        code: _PERSONAL_RISK_ITEMS.THIRD_PARTY_PRESSURE,
        text: 'Any pressure from a third party'
    },
    {
        code: _PERSONAL_RISK_ITEMS.OTHER,
        text: 'Other'
    },
];