import homeIcon from "../../images/svg/home.svg";
import React from "react";

class HomeIcon extends React.Component {
    render() {
        return <img
            alt='Home'
            src={homeIcon}
            width={20}
            height={18}
        />
    }
}

export default HomeIcon;

