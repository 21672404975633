import * as React from "react";

import QuestionBlock from "../../../../form/QuestionBlock";
import FieldLabel from "common-form/components/FieldLabel";
import FieldTextArea from "../../../../form/components/FieldTextArea";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldRadioGroup from "../../../../form/components/radio/FieldRadioGroup";
import {LABEL_TYPE} from "../../../../form/components/Field";


import {
    FIELDS, 
} from "common-hypotheca";


export default function Repayments(parent, nextBlockId) {
    
    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldLabel 
                    label="Moving on to mortgage repayment types, please share with us your thoughts on the following"
                />

                <FieldRadioYesNo 
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MP.WantsCertaintyMortgageRepaid)}
                />

                <FieldTextArea
                    labelType={LABEL_TYPE.Small}
                    show={() => parent.getValue(FIELDS.MM.MP.WantsCertaintyMortgageRepaid) === false}
                    placeholder="Optional supporting text ..."
                    {...parent.getField(FIELDS.MM.MP.WantsCertaintyMortgageRepaidReason)}
                />

                <FieldRadioYesNo 
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MP.AcceptsMortgageRepaidInvestmentPlan)}
                />

                <FieldTextArea
                    labelType={LABEL_TYPE.Small}
                    show={() => parent.getValue(FIELDS.MM.MP.AcceptsMortgageRepaidInvestmentPlan) === true}
                    placeholder="Optional supporting text ..."
                    {...parent.getField(FIELDS.MM.MP.AcceptsMortgageRepaidInvestmentPlanReason)}
                />

                <FieldRadioYesNo 
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MP.AcceptsNotMakingCapitalRepayments)}
                />

                <FieldTextArea
                    labelType={LABEL_TYPE.Small}
                    show={() => parent.getValue(FIELDS.MM.MP.AcceptsNotMakingCapitalRepayments) === true}
                    placeholder="Optional supporting text ..."
                    {...parent.getField(FIELDS.MM.MP.AcceptsNotMakingCapitalRepaymentsReason)}
                />

                <FieldRadioGroup
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MP.PreferredMortgageType)}
                />

            </QuestionBlock>,


        validate: (context) => {
            context
            .mandatory(FIELDS.MM.MP.WantsCertaintyMortgageRepaid)
            .mandatory(FIELDS.MM.MP.AcceptsMortgageRepaidInvestmentPlan)
            .mandatory(FIELDS.MM.MP.AcceptsNotMakingCapitalRepayments)
            .mandatory(FIELDS.MM.MP.PreferredMortgageType)
            ;

            context
            .optionalOrCleanup(parent.getValue(FIELDS.MM.MP.WantsCertaintyMortgageRepaid) === false, FIELDS.MM.MP.WantsCertaintyMortgageRepaidReason)
            .optionalOrCleanup(parent.getValue(FIELDS.MM.MP.AcceptsMortgageRepaidInvestmentPlan) === true, FIELDS.MM.MP.AcceptsMortgageRepaidInvestmentPlanReason)
            .optionalOrCleanup(parent.getValue(FIELDS.MM.MP.AcceptsNotMakingCapitalRepayments) === true, FIELDS.MM.MP.AcceptsNotMakingCapitalRepaymentsReason)
            ;

        },

        nextQuestionId: nextBlockId
    };

    
};