import React from "react";
import PropTypes from "prop-types";

import FieldDropdown from "./dropdown/FieldDropdown"
import FieldDropdownItem from "./dropdown/FieldDropdownItem";



export default class CopyApplicantsData extends React.Component {

    constructor(props) {
        super(props);

        if (!props.field && !props.fields) {
            throw new Error('CopyApplicantsData.Either field or fields are required!');
        }

        if (props.selectSingleValues && !this.props.formatSingleValue) {
            throw new Error('CopyApplicantsData.FormatSingleValue is required when SelectSingleValues');
        }

        this.state = {
            sources: this.buildSources()
        };

        // if no dropdown, by default only possible source
        if (this.state.sources.length === 1) {
            this.state.selectedSource = this.state.sources[0].key;
        }

        //TODO: show number of entries in dropdown item, for each applicant
    }

    getApplicants() {
        return this.props.adapter.getApplicants();
    }

    getFieldValue(container, field) {
        return container.fields[field.field];
    }

    getContainer(applicant) {
        return this.props.adapter.fieldsContainer(applicant.applicantId, this.props.sectionId, this.props.partId);
    }

    getApplicantWithId(applicantId) {
        return this.getApplicants().find(a => a.applicantId === applicantId);
    }



    buildSources() {

        const applicants = this.getApplicants().filter(a => a.applicantId !== this.props.currentApplicantId);
        if (this.props.selectSingleValues) {

            const sources = [];
            applicants.forEach(applicant => {

                const values = this.getFieldValue(this.getContainer(applicant), this.props.field) || [];

                values.forEach((value, index) => {

                    sources.push({
                        key: applicant.applicantId + '-' + index,
                        label: this.props.formatSingleValue(applicant, value)
                    });

                });

            });

            return sources;

        } else {

            return applicants.map(applicant => {

                return {
                    key: applicant.applicantId,
                    label: applicant.applicantName
                };

            });
        }
    }


    getTargetApplicant() {
        return this.getApplicants().find(s => s.applicantId === this.props.currentApplicantId);
    }

    getSourceApplicant() {

        let sourceApplicant = null;
        if (this.props.selectSingleValues) {

            const applicantId = parseInt(this.state.selectedSource.split('-')[0], 0);
            sourceApplicant = this.getApplicantWithId(applicantId);

        } else {
            sourceApplicant = this.getApplicantWithId(parseInt(this.state.selectedSource, 0));
        }

        return sourceApplicant;
    }

    getSourceIndex() {
        if (this.props.selectSingleValues) {
            return parseInt(this.state.selectedSource.split('-')[1], 0);
        }

        return null;
    }

    handleFieldFilled(field, sourceContainer, sourceIndex, targetApplicant, targetContainer) {

        const sourceValue = this.getFieldValue(sourceContainer, field);

        let targetValue = null;

        if (sourceIndex != null) {

            const sourceItem = sourceValue[sourceIndex];
            targetValue = this.getFieldValue(targetContainer, field);

            if (!Array.isArray(targetValue)) {
                targetValue = [];
            }

            targetValue.push(JSON.parse(JSON.stringify(sourceItem)));

        } else {
            targetValue = sourceValue ? JSON.parse(JSON.stringify(sourceValue)) : sourceValue;
        }

        field.handleFieldFilled(
            targetApplicant.applicantId,
            field.field,
            targetValue
        );
    }

    handleCopy() {

        if (this.state.selectedSource == null) {
            return;
        }

        const sourceApplicant = this.getSourceApplicant();
        const sourceContainer = this.getContainer(sourceApplicant);
        const sourceIndex = this.getSourceIndex();

        const targetApplicant = this.getTargetApplicant();
        const targetContainer = this.getContainer(targetApplicant);

        const fields = this.props.fields || [this.props.field];
        fields.forEach(field => this.handleFieldFilled(field, sourceContainer, sourceIndex, targetApplicant, targetContainer));
    }





    getButtonLabel() {
        return this.shouldShowDropdown()
            ? 'Copy'
            : this.state.sources[0].label;
    }

    shouldShowDropdown() {
        return this.state.sources.length > 1;
    }

    handleSelectedSource(a, b, sourceKey) {
        this.state.selectedSource = sourceKey.indexOf('-') > -1 ? sourceKey : parseInt(sourceKey);
    }

    renderDropdown() {

        const items = this.state.sources.map(source => {

            return <FieldDropdownItem
                key={source.key}
                code={source.key}
                label={source.label}
            />

        });

        items.unshift(
            <FieldDropdownItem
                key=""
                code=""
                label={this.props.emptyLabel || 'Select applicant ...'}
            />
        );

        return <FieldDropdown
            field="selectedSource"
            handleFieldFilled={(a, b, c) => this.handleSelectedSource(a, b, c)}
        >
            {
                items
            }
        </FieldDropdown>
    }

    render() {

        if (this.state.sources.length === 0) {
            return null;
        }

        return <React.Fragment>

            <div className="form-row">
                <div className="col-sm">
                    <label>
                        {this.props.label}
                    </label>
                </div>
            </div>

            <div className="form-row">

                {
                    this.shouldShowDropdown() &&
                    <div className="col-sm">
                        {
                            this.renderDropdown()
                        }
                    </div>
                }

                <div className="col-sm-auto">
                    <div
                        className="btn btn-primary btn-simple btn-copy"
                        onClick={this.handleCopy.bind(this)}>
                        <span>{this.getButtonLabel()}</span>
                    </div>
                </div>

            </div>

        </React.Fragment>
            ;

    }
}

CopyApplicantsData.propTypes = {
    label: PropTypes.string.isRequired,
    currentApplicantId: PropTypes.any.isRequired,
    sectionId: PropTypes.any.isRequired,
    partId: PropTypes.any.isRequired,
    adapter: PropTypes.any.isRequired,
    fields: PropTypes.any,
    field: PropTypes.any,
    selectSingleValues: PropTypes.bool,
    formatSingleValue: PropTypes.func,
    emptyLabel: PropTypes.string
};
