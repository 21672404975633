export const _MORTGAGE_PURPOSE = {
    REPLACE_EXISTING: 'replace_existing',
    NEW: 'new'
};

_MORTGAGE_PURPOSE.OPTIONS = [
    {
        code: _MORTGAGE_PURPOSE.REPLACE_EXISTING,
        text: 'Replace an existing mortgage',
        icon: 'autorenew',
        tooltip: 'Select this option if you already have a mortgage on the property.'
    },
    {
        code: _MORTGAGE_PURPOSE.NEW,
        text: 'Brand new mortgage!',
        icon: 'toys',
        tooltip: 'Select this option if the property has no current mortgage.'
    }
];