import {_MORTGAGE_PURPOSE} from "./picture_mortgage_purpose";
import {_APPLICATION_TYPE} from "./application_type";
import {_PURCHASE_STAGE} from "./picture_purchase_stage";

/**
 * Composite filter for mortgage type and urgency.
 */
export const _URGENCY_FILTER_TYPE = [
    {
        value: _APPLICATION_TYPE.NEW_MORTGAGE_TO_BUY + "/" + _PURCHASE_STAGE.STILL_BROWSING,
        label: "Buying->Still Browsing"
    },
    {
        value: _APPLICATION_TYPE.NEW_MORTGAGE_TO_BUY + "/" + _PURCHASE_STAGE.ABOUT_TO_OFFER,
        label: "Buying->About to offer"
    },
    {
        value: _APPLICATION_TYPE.NEW_MORTGAGE_TO_BUY + "/" + _PURCHASE_STAGE.OFFER_ACCEPTED,
        label: "Buying->Offer accepted"
    },
    {
        value: _APPLICATION_TYPE.NEW_MORTGAGE_TO_BUY + "/" + _PURCHASE_STAGE.NEED_MORTGAGE,
        label: "Buying->Need mortgage now"
    },
    {
        value: _APPLICATION_TYPE.MORTGAGE_EXISTING_LET + "/" + _MORTGAGE_PURPOSE.NEW,
        label: "New mortgage"
    },
    {
        value: _APPLICATION_TYPE.MORTGAGE_EXISTING_LET + "/" + _MORTGAGE_PURPOSE.REPLACE_EXISTING,
        label: "Remortgage"
    }
];