
import React, { Component,Fragment } from 'react';
import PropTypes from "prop-types";
import alertIconInfo from "../../../images/svg/alertIconInfo.svg";
import alertIconWarning from "../../../images/svg/alertIconWarning.svg";
import alertIconDanger from "../../../images/svg/alertIconDanger.svg";
import alertIconSuccess from "../../../images/svg/alertIconSuccess.svg";
export const ALERTTYPE = {
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'danger',
    INFO: 'info'
}
export class Alert extends Component {
    static propTypes = {
        text: PropTypes.string,
        type: PropTypes.string,
        canDismiss: PropTypes.bool,
        show: PropTypes.any
    }

    static defaultProps = {
        text: '',
        canDismiss: false,
        type: ALERTTYPE.INFO
    }
    constructor(props) {
        super(props)
        this.state = {
            shown: true
        }
    }

    
    show = () => {

        if (this.props.show === undefined)
            return true;

        if (typeof this.props.show === 'boolean' && !this.props.show) {
            return false;
        }

        if (typeof this.props.show === 'function' && !this.props.show(this.props.fieldsContainer)) {
            return false;
        }

        return true;
    };

//<img src={alertIconInfo} width="28" height="28" />

    getIcon = () => {
        switch(this.props.type){
            case ALERTTYPE.INFO:
				
           // return <Fragment><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><path id="alert-info" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1-8h-2V7h2v2z"/></svg></Fragment>
			return <Fragment><img alt='' src={alertIconInfo} className="alert-icon" width="28" height="28" /></Fragment>
            case ALERTTYPE.WARNING:
            return <Fragment><img alt='' src={alertIconWarning} className="alert-icon" width="28" height="28" /></Fragment>
            case ALERTTYPE.DANGER:
            return <Fragment><img alt='' src={alertIconDanger} className="alert-icon" width="28" height="28" /></Fragment>
            case ALERTTYPE.SUCCESS:
            return <Fragment><img alt='' src={alertIconSuccess} className="alert-icon" width="28" height="28" /></Fragment>
            default:
            return <Fragment>?</Fragment>
        }
    }
    render() {
        //If you need to add fadeout /animate we need to discuss.
        if (!this.state.shown || !this.show()) {
            return null
        }
        return (
            <div className={`alert alert-${this.props.type}`}>
                {this.getIcon()}
                <p>
                {this.props.text} 
                
                
                {
                    !!this.props.canDismiss &&
                    <span onClick={() => this.setState({ shown: false })}>X</span>
                }
                </p>
                {this.props.children}
            </div>
        );
    }
}

export default Alert;