import React from 'react';

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldDate from "common-form/components/FieldDate";
import {FieldDropdown} from "../../../../../form/components/dropdown";
import FieldInput from "common-form/components/FieldInput";
import TimeDiff from "../../../../../form/components/TimeDiff";

import { getAgoValue, getAgoDate } from "common-hypotheca/src/dates";

import {
    FIELDS,
    GENDER_TYPE
} from "common-hypotheca";
import {Alert, ALERTTYPE} from "../../../../../form/components/alert/Alert";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import {LABEL_TYPE} from "../../../../../form/components/Field";
import FieldLabel from "common-form/components/FieldLabel";
import FieldTextArea from "../../../../../form/components/FieldTextArea";
import {FieldCheckboxGroup} from "../../../../../form/components/checkbox";

export default function Meta(parent, nextBlockId) {

    function getAgeYears() {
        const years = getAgoValue(parent.getValue(FIELDS.MS.PD.DOB), 'years');
        return years > 0 && years < 120 ? years : null;
    }

    function startingInRetirement() {
        const ageYears = getAgeYears();
        const retirementAge = parent.getValue(FIELDS.MS.PD.RetirementAge);

        return ageYears !== null
            && ageYears !== undefined
            && retirementAge !== null
            && retirementAge !== undefined
            && retirementAge !== ''
            && !isNaN(ageYears)
            && !isNaN(retirementAge)
            && ageYears >= retirementAge
            && retirementAge >= 10;
    }

    const personalRiskItems = parent.fieldValue(FIELDS.MS.PD.PersonalRiskItems);
    const hasPersonalRiskItem = !!personalRiskItems && personalRiskItems.length > 0;

    function hasGenderOther() {
        return parent.getValue(FIELDS.MS.PD.Gender) === GENDER_TYPE.OTHER;
    }

    return {
        block: (params)=>
            <QuestionBlock {...params}>

                <div className='date-boxes'>
                <FieldDate
                    {...parent.getFormField(null, FIELDS.MS.PD.DOB)}
                />
                </div>

                <TimeDiff
                    updateFactFindFunction={
                        (age, ageCalcDate) => {
                            parent.props.adapter.updateField(age, FIELDS.MS.PD.Age, ...parent.defaultFieldLocation());
                            parent.props.adapter.updateField(ageCalcDate, FIELDS.MS.PD.AgeCalcDate, ...parent.defaultFieldLocation());
                        }
                    }
                    applicable={!!getAgeYears()}
                    format="ym"
                    label="You are {0} old."
                    fromValue={parent.getValue(FIELDS.MS.PD.DOB)}
                    toValue={new Date()}
                />

                <FieldDropdown 
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.Gender)}
                >
                </FieldDropdown>
                <FieldInput
                    show={() => hasGenderOther() }
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.GenderOther)}
                />

                <FieldDropdown 
                    {...parent.getFormField(null, FIELDS.MS.PD.Nationality)}
                />

                <FieldInput
                    {...parent.getFormField(null, FIELDS.MS.PD.NINumber)}
                />

                <FieldDropdown 
                    {...parent.getFormField(null, FIELDS.MS.PD.RelationshipStatus)}
                >
                </FieldDropdown>

                <FieldInput 
                    {...parent.getFormField(null, FIELDS.MS.PD.RetirementAge)}
                    type='number'
                />

                {
                    startingInRetirement() &&
                    <Alert
                        type={ALERTTYPE.WARNING}
                        text={'You are in retirement or will retire soon.'}
                    />
                }

                <FieldLabel
                    label='Are there any adjustments that you need to be considered?'
                />

                <FieldRadioYesNo
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.AdjustmentsConsideration)}
                    labelType={LABEL_TYPE.Small}
                />
                <FieldTextArea
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.AdjustmentsConsiderationDetails)}
                    labelType={LABEL_TYPE.Small}
                    show={parent.fieldValue(FIELDS.MS.PD.AdjustmentsConsideration) === true}
                />

                <FieldRadioYesNo
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.ReligiousNeeds)}
                />
                <FieldTextArea
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.ReligiousNeedsDetails)}
                    labelType={LABEL_TYPE.Small}
                    show={parent.fieldValue(FIELDS.MS.PD.ReligiousNeeds) === true}
                />

                <FieldCheckboxGroup
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.PersonalRiskItems)}
                />
                <FieldTextArea
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.PersonalRiskItemsDetails)}
                    labelType={LABEL_TYPE.Small}
                    show={hasPersonalRiskItem}
                />


            </QuestionBlock>,
        validate: (context) => {

            context
                .date(FIELDS.MS.PD.DOB)
                .dateMax(FIELDS.MS.PD.DOB, getAgoDate(21, 'years'), 'You must have at least 21 years')
                .mandatoryOrCleanup(hasGenderOther(), FIELDS.MS.PD.GenderOther)
                .mandatory(FIELDS.MS.PD.NINumber)
                .mandatory(FIELDS.MS.PD.RelationshipStatus)
                .mandatory(FIELDS.MS.PD.RetirementAge)
                .maxValue(FIELDS.MS.PD.RetirementAge, 100, 'Value should be less than or equal to 100')
                .mandatory(FIELDS.MS.PD.AdjustmentsConsideration)
                .mandatoryOrCleanup(
                    parent.fieldValue(FIELDS.MS.PD.AdjustmentsConsideration) === true,
                    FIELDS.MS.PD.AdjustmentsConsiderationDetails
                )
                .mandatory(FIELDS.MS.PD.ReligiousNeeds)
                .mandatoryOrCleanup(
                    parent.fieldValue(FIELDS.MS.PD.ReligiousNeeds) === true,
                    FIELDS.MS.PD.ReligiousNeedsDetails
                )
                .recordedCheckboxOptions(FIELDS.MS.PD.PersonalRiskItems)
                .mandatoryOrCleanup(
                    hasPersonalRiskItem,
                    FIELDS.MS.PD.PersonalRiskItemsDetails
                )
                ;

            if (!context.isOneFieldValidation() && !parent.fieldHasValueAny(null, FIELDS.MS.PD.Nationality)) {
                // GBR is first option by default, but we need to set it up on next button
                parent.handleFilledField(null, FIELDS.MS.PD.Nationality, "GBR", null, true);
            }
        },

        nextQuestionId: () => nextBlockId
    };
};

