import * as React from "react";

import QuestionBlock from "../../../../form/QuestionBlock";
import FieldInput from "common-form/components/FieldInput";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import { LABEL_TYPE } from "../../../../form/components/Field";

import {
    FIELDS
} from "common-hypotheca";
import FieldTextArea from "../../../../form/components/FieldTextArea";


export default function Improvements(parent, nextBlockId) {

    function isDoingImprovements() {
        return parent.getValue(FIELDS.MP.PD.PurchaseNewBuildDoImprovements) === true;
    }


    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldRadioYesNo
                    {...parent.getField(FIELDS.MP.PD.PurchaseNewBuildDoImprovements)}
                />

                <FieldTextArea
                    show={() => isDoingImprovements() }
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MP.PD.PurchaseNewBuildDoImprovementsDescr)}
                />

                <FieldInput
                    show={() => isDoingImprovements() }
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MP.PD.PurchaseNewBuildDoImprovementsCost)}
                />


            </QuestionBlock>,


        validate: (context) => {

            context
                .mandatory(FIELDS.MP.PD.PurchaseNewBuildDoImprovements)
                .mandatoryOrCleanup(isDoingImprovements(), [
                    FIELDS.MP.PD.PurchaseNewBuildDoImprovementsDescr,
                    FIELDS.MP.PD.PurchaseNewBuildDoImprovementsCost
                ]);
        },

        nextQuestionId: () => nextBlockId
    };

    
};