import * as React from "react";

import { CAN_SUBMIT } from "../../../constants";

import MultiBlockFormSingleApplicant from "../../../form/MultiBlockFormSingleApplicant";
import QuestionBlock from "../../../form/QuestionBlock";
import FieldLabel from "common-form/components/FieldLabel";
import FormButton from "../../../form/components/FormButton";
import FormImage, {IMAGE_MORTGAGE} from "../../../form/components/FormImage";
import { LABEL_TYPE } from "../../../form/components/Field";

import BuyNewPlace from "./details/BuyNewPlace";
import Remortgage from "./details/Remortgage";
import NewMortgageExistingAsset from "./details/NewMortgageExistingAsset";
import TimeTerm from "./details/TimeTerm";
import RemortgageDetails from "./details/RemortgageDetails";

import { roundTwoDecs } from "../../../util/numbers";


import {
    SECTION,
    MY_MORTGAGE,
} from "common-hypotheca";
import ErrorPart from "../../ErrorPart";


const BLOCK_WELCOME = 1;
const BLOCK_BUY_NEW_PLACE = 3;
const BLOCK_REMORTGAGE = 6;
const BLOCK_NEW_MORTGAGE_EXISTING_ASSET = 10;
const BLOCK_TIME_TERM = 14;
const BLOCK_REMORTGAGE_DETAILS = 20;


class MortgageDetails extends MultiBlockFormSingleApplicant {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            sectionId: SECTION.MY_MORTGAGE,
            partId: MY_MORTGAGE.MORTGAGE_DETAILS,

            slideIds: [BLOCK_WELCOME],
            activeSlideId: BLOCK_WELCOME
        };
    }

    /**
     * @returns {number} of expected slides for this part.
     */
    slidesExpected = () => {
        return 3;
    };

    checkForCompleteState = data => {
        this.partContainer().isComplete = true;
        return data;
    };

    getField = (fieldName) => {
        return this.getFormField(null, fieldName);
    };

    getValue = (fieldName) => {
        return this.getField(fieldName).value;
    };
    
    isMortgageToBuyNewPlace = () => {
        return this.props.adapter.isMortgageToBuyNewPlace();
    };

    isReplaceExistingMortgage = () => {
        return this.props.adapter.isReplaceExistingMortgage();
    };

    isMortgageToBuyExistingPlace = () => {
        return this.props.adapter.isMortgageExistingLet();
    };

    isPurchaseStage = (stage) => {
        return this.props.adapter.isPurchaseStage(stage);
    };

    getApplicationDateOfBirth = () => {
        return this.props.adapter.getApplicationDateOfBirth();
    };

    getApplicationRetirementAge = () => {
        return this.props.adapter.getApplicationRetirementAge();
    };

    getPropertyValue = () => {
        return this.props.adapter.getPropertyValue();
    };

    updateValue = (field, value) => {
        this.props.adapter.updateField(value, field, null, this.state.sectionId, this.state.partId);
    };

    updateLoanToValue = (field, borrowAmountField, propertyValue) => {
        return this.props.adapter.updateLoanToValue(field, borrowAmountField, propertyValue, this.state.sectionId, this.state.partId);
    };

    getQuestion = which => {
        switch(which) {

            case BLOCK_WELCOME:
                return {
                    block: (params)=>
                        <QuestionBlock {...params} controlButtons={null}>

                            <FieldLabel
                                label={`Great job so far ${this.getApplicantName()}! Let's now talk about the mortgage that you are applying for`}
                            />

                            <FieldLabel
                                label="Tell us what you have in mind regarding your new mortgage, including any plans you might have for additional funds."
                                labelType={LABEL_TYPE.Description}
                            />

                            <FormButton
                                text="Ready!"
                                onClick={() => this.swiperNext()}
                            />
                            <FormImage imageType={IMAGE_MORTGAGE}/>
                        </QuestionBlock>,
                    canNext: () => true,
                    nextQuestionId: () => {

                        if (this.isMortgageToBuyNewPlace()) {
                            return BLOCK_BUY_NEW_PLACE;
                        } else if (this.isReplaceExistingMortgage()) {
                            return BLOCK_REMORTGAGE;
                        } else if (this.isMortgageToBuyExistingPlace()) {
                            return BLOCK_NEW_MORTGAGE_EXISTING_ASSET;
                        }

                    }
                };

            case BLOCK_BUY_NEW_PLACE:
                return BuyNewPlace(this, BLOCK_TIME_TERM);

            case BLOCK_REMORTGAGE:
                return Remortgage(this, BLOCK_TIME_TERM);

            case BLOCK_NEW_MORTGAGE_EXISTING_ASSET:
                return NewMortgageExistingAsset(this, BLOCK_TIME_TERM);

            case BLOCK_TIME_TERM:
                return TimeTerm(this, () => {
                    return this.isReplaceExistingMortgage() ? BLOCK_REMORTGAGE_DETAILS : CAN_SUBMIT
                });

            case BLOCK_REMORTGAGE_DETAILS:
                return RemortgageDetails(this, CAN_SUBMIT);

            default:
                return ErrorPart();
        }
    };

}

export default MortgageDetails;
