import React from 'react';
import { FormButton } from '../form/components';
import moment from 'moment'
import { Bin } from '../form/icons'
import { APP_STATUS } from 'common-hypotheca';
class ExistingApplicationSummary extends React.Component {
    static propTypes = {
    };

    static defaultProps = {
        status: ''
    };

    handleDeleteClick(e) {
        e.stopPropagation()
        if (!this.canDelete()) {
            return
        }
        this.props.onDeleteClick()
    }
 
    getAddress = () => {
        const address = this.props.adapter.getPropertyAddress();
        return address && address.CurrentAddressPostcode || '-';
    };

    getNiceDate = time => {
        return moment(time).format('DD/MM/YY HH:mm:ss')
    };

    getShortDate = time => {
        return moment(time).format('DD/MM/YY')
    };

    canDelete = () => {
        return [
            APP_STATUS.STARTED,
            APP_STATUS.BORROW_BAD,
            APP_STATUS.BORROW_GOOD,
            APP_STATUS.INITIAL
        ].includes(this.props.application.appStatus)
    };

    render() {
        // console.log('render app form ' + Date.now(), this.state);
        let app = this.props.application

        return (
            <React.Fragment>
                <tr >
                    <td>
                        {this.props.number}
                        <span className='applications-short-date'>: Edited {app.time && app.time.lastSaved ? this.getShortDate(app.time.lastSaved) : '-'}</span>
                    </td>
                    <td >
                        {app.time && app.time.created ? this.getNiceDate(app.time.created) : '-'}
                    </td>
                    <td>
                        {app.time && app.time.lastSaved ? this.getNiceDate(app.time.lastSaved) : '-'}
                    </td>
                    <td>
                        {this.props.appStatus}
                    </td>
                    <td>
                        {this.getAddress()}
                    </td>
                    <td>
                        <FormButton text='Select' onClick={this.props.onClick} />
                    </td>
                    <td>
                        <div className={`icon ${this.canDelete() ? '' : ' disabled '}`} onClick={(e) => this.handleDeleteClick(e)}>
                            <Bin />
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }


}

export default ExistingApplicationSummary;
