export const _FREEHOLD_SHARE = {
    NO: 'no',
    YES_MANAGEMENT_COMPANY_SHARES: 'yes_management_company_shares',
    YES_IN_PERSONAL_NAME: 'yes_in_personal_name',
    YES_OTHER: 'other',
    DONT_KNOW: 'dont_know'
};

_FREEHOLD_SHARE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: _FREEHOLD_SHARE.NO,
        text: 'No'
    },
    {
        code: _FREEHOLD_SHARE.YES_MANAGEMENT_COMPANY_SHARES,
        text: 'Yes, via shares in a management company'
    },
    {
        code: _FREEHOLD_SHARE.YES_IN_PERSONAL_NAME,
        text: 'Yes, in personal name'
    },
    {
        code: _FREEHOLD_SHARE.YES_OTHER,
        text: 'Yes, other'
    },
    {
        code: _FREEHOLD_SHARE.DONT_KNOW,
        text: 'Don’t know'
    }
];

_FREEHOLD_SHARE.showDetails = (value) => value === _FREEHOLD_SHARE.YES_OTHER;

/** Show admin/frontend warning that freehold share structure should be investigated */
_FREEHOLD_SHARE.showWarning = (value) => {
    return [
        _FREEHOLD_SHARE.YES_IN_PERSONAL_NAME,
        _FREEHOLD_SHARE.YES_OTHER,
        _FREEHOLD_SHARE.DONT_KNOW
    ].includes(value);
};