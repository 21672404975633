import React from "react";
import icon from "../images/tooltip.svg";
import ReactTooltip from 'react-tooltip';
import PropTypes from "prop-types";

const isMobile = function () {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};


let lastId = 0;
function iconId() {
    lastId++;
    return 'tooltip-' + lastId;
}

let clicked;
let clickedTarget;

class HelpIcon extends React.Component {
    iconMouseEnter = (id, target) => {
        console.log('mouse enter');
        if (clicked !== id)
            ReactTooltip.show(target);
    };

    iconMouseLeave = (id, target) => {
        console.log('mouse leave [' + id + '][' + clicked + ']');
        if (clicked !== id)
            ReactTooltip.hide(target);
    };

    iconClicked = (id, event) => {
        console.log('clicked');
        if (clicked === id) {
            ReactTooltip.hide(clickedTarget);
            event.stopPropagation();
            event.preventDefault();
        } else {
            console.log('clicked !== id', event);
            event.stopPropagation();
            event.preventDefault();

            if (clicked !== undefined)
                ReactTooltip.hide(clickedTarget);

            clicked = id;
            clickedTarget = event.target;
            ReactTooltip.show(event.target);
        }
    };

    afterHide = (id) => {
        console.log('after hide');

        if (clicked === id) {
            clicked = undefined;
            clickedTarget = undefined;
        }
    };

    id;

    render() {
        if (this.id === undefined)
            this.id = iconId();

        let place = this.props.place || 'top';

        let params = {};

        if (!isMobile()) {
            params['onMouseEnter'] = (event) => this.iconMouseEnter(this.id, event.target);
            params['onMouseLeave'] = (event) => this.iconMouseLeave(this.id, event.target);
        }

        return (
            <React.Fragment>
                <img
                    alt='Help'
                    className='help-icon'
                    data-tip
                    data-event='bogus-event'
                    data-event-off=''
                    data-for={this.id}
                    src={icon}
                    width={24}
                    height={24}
                    onClick={(event) => this.iconClicked(this.id, event)}
                    {...params}
                />
                <ReactTooltip
                    id={this.id}
                    place={place}
                    effect="solid"
                    globalEventOff='click'
                    afterHide={() => this.afterHide(this.id)}
                >{this.props.children}</ReactTooltip>
            </React.Fragment>
        );
    }
}

HelpIcon.propTypes = {
    place: PropTypes.string
};

export default HelpIcon;