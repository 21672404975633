import React, {Fragment, useState} from 'react';
import { Auth } from 'aws-amplify';

import FieldInput from 'common-form/components/FieldInput';
import FormButton from '../form/components/FormButton';
import Alert, { ALERTTYPE } from '../form/components/alert/Alert';
import {isAuth2} from "../amplify-configurator";
import Loading from "../form/icons/Loading";

function ChangeEmail({user}) {

    const [currentEmail, setCurrentEmail] = useState(user.email);
    const [newEmail, setNewEmail] = useState('');
    const [emailVerified, setEmailVerified] = useState(user.emailVerified);
    const [changing, setChanging] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);

    function canConfirm() {
        return !!newEmail && newEmail !== currentEmail;
    }

    function onConfirm() {
        if (!canConfirm()) {
            return;
        }

        setError(null);
        setSaving(true);

        let em = newEmail.toLowerCase();

        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.updateUserAttributes(user, {
                    email: em
                })
            })
            .then(data => {
                setUpdated(true);
                setChanging(false);
                setNewEmail('');
                setEmailVerified(false);
                setCurrentEmail(em);
                setSaving(false);
            })
            .catch(err => {
                setError(err.message);
                setSaving(false);
            });
    }

    let components = {
        newEmail: {
            label: 'Please enter your new email',
            value: newEmail,
            field: 'newEmail',
            handleFieldFilled: (a, f, newEmail) => setNewEmail(newEmail),
            type: 'email'
        },
        confirm: {
            text: 'Confirm',
            onClick: () => onConfirm(),
            class: `btn btn-success `,
            disabled: !canConfirm()
        },
        change: {
            text: 'Update',
            onClick: () => setChanging(true),
        }
    };

    return (
        <Fragment>
            <div className='row'>
                <div className='col-12'>
                    <h2>Change email</h2>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <p>Current email: {currentEmail} {
                        !emailVerified &&
                        'Not'
                    } Verified</p>
                </div>
            </div>

            {
                changing
                ?   <Fragment>
                        <div className='row'>
                            <div className='col-12'>
                                <p>Please take care to only enter a new email address that you control and can access now and do not log out of the system until you have received the verification code.</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 small-label'>
                                <FieldInput {...components.newEmail} />
                            </div>
                        </div>

                        <div className='row padding-bottom'>
                            <div className='col-12'>
                                <FormButton {...components.confirm} />
                                {
                                    saving &&
                                    <Loading buttonLine/>
                                }
                            </div>
                        </div>
                    </Fragment>
                : <div className='row padding-bottom'>
                        <div className='col-12'>
                            <FormButton {...components.change} disabled={isAuth2()}/>
                        </div>
                    </div>
            }
            {
                (updated || !emailVerified) &&
                <div className='row'>
                    <div className='col-12'>
                        <Alert text='Please check your email for your confirmation code. You must enter this when you next log in.' />
                    </div>
                </div>
            }

            <div className='row'>
                <div className='col-12'>
                    {
                        !!error &&
                        <Alert type={ALERTTYPE.DANGER} text={error} />
                    }
                </div>
            </div>

        </Fragment>
    );
}

export default ChangeEmail;

