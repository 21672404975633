import { connect } from 'react-redux';
import React, {Fragment} from "react";
import $ from "jquery";
import {savedApplication, setData} from "../redux/actions";
import SignIn from "../auth/SignIn";

const FAILURES_BEFORE_WARNING = 3;

class AutoSaveFailureOverlay extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            ignored: false
        };
    }

    handleRetry = () => {
        this.props.setData();
        this.props.savedApplication(true, this.props.autoSaveFailures);
    };

    handleDismiss = () => {
        this.setState({
            ignored: true
        });
    };

    handleLogout = async () => {
        await SignIn.handleLogout(this.props.history);
        this.hideModal();
    };

    shouldShow = () => {
        return !!this.props.autoSaveError
            || this.props.autoSaveFailures >= FAILURES_BEFORE_WARNING && !this.state.ignored;
    };

    componentDidUpdate() {
        if (this.shouldShow()) {
            $(this.myRef.current).modal('show');
        } else {
            this.hideModal();
        }
    }

    hideModal() {
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
    }

    render() {
        if (!this.shouldShow()) {
            return '';
        }

        return (
            <div ref={this.myRef} className="modal fade" id="exampleModal" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Oops, we cannot save your info!</h5>
                            <button type="button" className="close" aria-label="Close" data-dismiss="modal" />
                        </div>
                        <div className="modal-body">
                            {
                                !this.props.autoSaveError &&
                                <Fragment key='network-error'>
                                    {
                                        /*
                                        This is probably due to weak internet connectivity. If you think your device will
                                        regain internet connectivity soon, please feel free to continue. Otherwise, please
                                        try to restore internet connectivity on your device before proceeding, otherwise
                                        you may lose some of your information.
                                        */
                                    }
                                    This is probably due to weak internet connectivity. Please try to restore connectivity on your device and try again!
                                </Fragment>
                            }
                            {
                                !!this.props.autoSaveError &&
                                <Fragment key='autosave-error'>
                                    {this.props.autoSaveError}
                                </Fragment>
                            }
                            <br/>
                            <br/>
                            {
                                !this.props.autoSaveError &&
                                <Fragment key='retry-ingore'>
                                    {
                                        this.props.lastSavedApplication === true
                                            ? <button
                                                style={{minWidth: 190}}
                                                disabled="disabled"
                                                type="button"
                                                className="btn btn-disabled spaced">Retrying...</button>
                                            : <button
                                                style={{minWidth: 190}}
                                                type="button"
                                                className="btn btn-primary spaced"
                                                onClick={this.handleRetry}>Retry autosave</button>
                                    }
                                    {
                                        /*
                                            Ignore disabled as there are users losing their data, likely
                                            by clicking ignore.

                                            <button
                                                type="button"
                                                className="btn btn-secondary spaced"
                                                onClick={this.handleDismiss}>Ignore</button>
                                         */
                                    }
                                </Fragment>
                            }
                            {
                                !!this.props.autoSaveError &&
                                <Fragment key='logout'>
                                    <button
                                        type="button"
                                        className="btn btn-primary spaced"
                                        onClick={this.handleLogout}>Logout</button>
                                </Fragment>
                            }
                        </div>
                        <div className="modal-footer">
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    autoSaveFailures: state.autoSaveFailures,
    autoSaveError: state.autoSaveError,
    lastSavedApplication: state.lastSavedApplication
});

const mapDispatchToProps = dispatch => ({
    setData: () => dispatch(setData()),
    savedApplication: (when, failures) => dispatch(savedApplication(when, failures))
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoSaveFailureOverlay);
