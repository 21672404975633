import React, {Component, Fragment} from 'react';
import HelpIcon from "common-form/components/HelpIcon";
import RangeSlider from "../../form/components/RangeSlider";
import RangeSliderInterest from "../../form/components/RangeSliderInterest";
import AffordabilityMortgageTypeRadios from "./AffordabilityMortgageTypeRadios";
import LoanCalc from 'loan-calc';

const STAMP_DUTY_TAX = 5;

const DEFAULT_VALUES = {
    propertyValue: 350000,
    deposit: 87500,
    annualGrossRentalIncome: 21000,
    rate: RangeSliderInterest.DEFAULT,
    mortgageType: AffordabilityMortgageTypeRadios.VALUE_INTEREST_ONLY,
    term: 25
};

const MIN_VALUES = {
    propertyValue: 50000,
    deposit: 20000,
    annualGrossRentalIncome: 5000,
    term: 2
};

const MAX_VALUES = {
    propertyValue: 1300000,
    deposit: 1000000,
    annualGrossRentalIncome: 100000,
    term: 30
};

const STEPS = {
    propertyValue: 1000,
    deposit: 1000,
    annualGrossRentalIncome: 500
};

const SMALL_STEP_BELOW = {
    propertyValue: 300000,
    // deposit small step threshold should not be smaller than propertyValue threshold - min borrowing
    deposit: 300000
};

const MIN_BORROWING = 30000;

class AffordabilityCalculator extends Component {
    addSlice = (amount, from, to, percentage) => {
        let result;
        if (amount <= from)
            result = 0;
        else
            result = ((amount > to ? to : amount) - from) * percentage / 100;
        return result;
    };

    static PARAMS_OUTSIDE_MESSAGE = {
        error: 'The data you have manually entered is outside of the allowable limits. Please reset sliders or amend your information'
    };

    updatedResult = (params, rangeChanged, fieldChanged) => {
        if (params.propertyValue < MIN_VALUES.propertyValue
            || params.annualGrossRentalIncome < MIN_VALUES.annualGrossRentalIncome
            || params.propertyValue > MAX_VALUES.propertyValue
            || params.annualGrossRentalIncome > MAX_VALUES.annualGrossRentalIncome
            || !RangeSliderInterest.allowed(params.rate)
            || params.term < MIN_VALUES.term
            || params.term > MAX_VALUES.term) {
            return AffordabilityCalculator.PARAMS_OUTSIDE_MESSAGE;
        }

        /*
        let data1 = Math.round(params.annualGrossRentalIncome/145*100/0.055/1000)*1000;
        let data3 = params.propertyValue * 0.75;
        let data4 = 1000000; // max borrowable
        */

        // */ 2 for nonlinear steps
        let minDeposit = Math.round(params.propertyValue * 0.25 / STEPS.deposit * 2) * STEPS.deposit / 2;
        let maxDeposit = params.propertyValue - MIN_BORROWING;
        let deposit = params.deposit;

        if (deposit > maxDeposit) {
            if (rangeChanged || fieldChanged === 'propertyValue') {
                deposit = maxDeposit;
            } else {
                return AffordabilityCalculator.PARAMS_OUTSIDE_MESSAGE;
            }
        }
        if (deposit < minDeposit) {
            if (rangeChanged || fieldChanged === 'propertyValue') {
                deposit = minDeposit;
            } else {
                return AffordabilityCalculator.PARAMS_OUTSIDE_MESSAGE;
            }
        }

        let borrowable = params.propertyValue - deposit;
        let ltv = Math.round(borrowable / params.propertyValue * 100);

        let minAnnualGrossRentalIncome = Math.round(borrowable*0.055/100*145/STEPS.annualGrossRentalIncome)*STEPS.annualGrossRentalIncome;
        let annualGrossRentalIncome = params.annualGrossRentalIncome;

        if (annualGrossRentalIncome < minAnnualGrossRentalIncome) {
            if (rangeChanged || fieldChanged === 'propertyValue' || fieldChanged === 'deposit') {
                annualGrossRentalIncome = minAnnualGrossRentalIncome;
            } else {
                return AffordabilityCalculator.PARAMS_OUTSIDE_MESSAGE;
            }
        }

        let stampDutyLandTax =
            + this.addSlice(params.propertyValue, 0, 250000, 0)
            + this.addSlice(params.propertyValue, 250000, 925000, 5)
            + this.addSlice(params.propertyValue, 925000, 1500000, 10)
            + this.addSlice(params.propertyValue, 1500000, 10000000, 12)
            + params.propertyValue * STAMP_DUTY_TAX / 100;

        let stampDutyLandTaxReduced =
            + this.addSlice(params.propertyValue, 0, 500000, 0)
            + this.addSlice(params.propertyValue, 500000, 925000, 5)
            + this.addSlice(params.propertyValue, 925000, 1500000, 10)
            + this.addSlice(params.propertyValue, 1500000, 10000000, 12)
            + params.propertyValue * STAMP_DUTY_TAX / 100;

        if (borrowable < MIN_BORROWING) {
            return AffordabilityCalculator.PARAMS_OUTSIDE_MESSAGE;
        }

        return {
            error: undefined,
            borrowable,
            stampDutyLandTax,
            stampDutyLandTaxReduced,
            annualGrossRentalIncome,
            deposit,
            ltv,
            monthlyPayment: Math.round(LoanCalc.paymentCalc({
                amount: borrowable,
                rate: params.rate,
                termMonths: params.term * 12
            }))
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            ...DEFAULT_VALUES,
            ...this.updatedResult(DEFAULT_VALUES),
            showStampDutyLandTax: false
        }
    }

    resetSliders = (event) => {
        this.setState({
            ...DEFAULT_VALUES,
            ...this.updatedResult(DEFAULT_VALUES)
        });
        event.preventDefault();
    };

    handleFieldChange = (name, value, rangeChanged) => {
        let newState = {};
        newState[name] = value;
        this.setState(newState);
        this.setState(this.updatedResult({ ...this.state, ...newState}, rangeChanged, name));
    };

    showStampDutyLandTax = (value) => {
        this.setState({
            showStampDutyLandTax: value
        })
    };

    //
    // Bootstrap 4: float-right / float-left
    // Bootstrap 5: float-end / float-start
    //

    render() {
        let stampDutyMarkup = (
            <Fragment>
                <React.Fragment>
                    <div className='float-right float-end'>
                        <HelpIcon>
                            Based on HMRC rules for second home purchases in England, effective as at 24/25.
                        </HelpIcon>
                    </div>
                    <div className='affordability-calc-stamp-duty card bg-light border-0 mt-5 p-4'>
                        {
                            // this.state.stampDutyLandTax === this.state.stampDutyLandTaxReduced &&
                            <Fragment key='stamp-duty-normal'>
                                Stamp Duty Land Tax (England), including the {STAMP_DUTY_TAX}% second home surcharge, will
                                be: &#163;{this.state.stampDutyLandTax.toLocaleString('en')}
                            </Fragment>
                        }
                        {
                            /*
                            this.state.stampDutyLandTax > this.state.stampDutyLandTaxReduced &&
                            <Fragment key='stamp-duty-normal'>
                                Stamp Duty Land Tax (England), including the {STAMP_DUTY_TAX}% second home surcharge, will
                                be: &#163;{this.state.stampDutyLandTaxReduced.toLocaleString('en')} <span className='covid-discount'>including a saving of
                                &#163;{(this.state.stampDutyLandTax-this.state.stampDutyLandTaxReduced).toLocaleString('en')} from
                                the Covid-19 relief scheme.</span>
                            </Fragment>
                            */
                        }
                    </div>
                </React.Fragment>
            </Fragment>
        );

        let monthlyMarkup = (
            <Fragment>
                {
                    this.state.mortgageType === AffordabilityMortgageTypeRadios.VALUE_INTEREST_ONLY &&
                    <strong>{RangeSliderInterest.text(this.state.borrowable, this.state.rate)}</strong>
                }
                {
                    this.state.mortgageType === AffordabilityMortgageTypeRadios.VALUE_REPAYMENT &&
                    <strong>
                        <p className="padding-top">
                            Assuming a {this.state.rate}% repayment mortgage, this would mean monthly payments
                            of &#163;{this.state.monthlyPayment.toLocaleString('en')}
                        </p>
                    </strong>
                }
            </Fragment>
        );

        return (
            <div className="container">
                <div className="row">
                    {
                        this.state.error &&
                        <div key='error-mobile' className="col-12 d-lg-none sticky-top-md">
                            <div className="affordability-calc-estimate p-2 my-2">
                                <p className="lead text-center">{this.state.error}</p>
                            </div>
                        </div>
                    }
                    {
                        !this.state.error &&
                        <div key='success-mobile' className="col-12 d-lg-none sticky-top-md">
                            <div className="affordability-calc-estimate p-2 my-2">
                                <p className="lead">Borrowing:</p>
                                <h1 className="display-3 float-left float-start">&#163;{this.state.borrowable.toLocaleString('en')}</h1>
                                <div className='help-icon-container float-right float-end'>
                                    <HelpIcon place='left'>
                                        Calculations are based on the affordability criteria used by
                                        many of the leading lenders of holiday let mortgages. This is
                                        an indication only and is not a guarantee of value or
                                        that a mortgage offer will be issued
                                    </HelpIcon>
                                </div>
                                <div className="clearfix"/>
                            </div>
                        </div>
                    }

                    {
                        this.state.error &&
                        <div key='error' className="col-12 col-lg-5 d-none d-lg-block">
                            <div className="affordability-calc-estimate p-2 mt-4">
                                <p className="lead text-center">{this.state.error}</p>
                            </div>
                        </div>
                    }
                    {
                        !this.state.error &&
                        <div key='success' className="col-12 col-lg-5">
                            <div className='help-icon-container float-right float-end mt-4 d-none d-lg-block'>
                                <HelpIcon place='left'>
                                    Calculations are based on the affordability criteria used by
                                    many of the leading lenders of holiday let mortgages. This is
                                    an indication only and is not a guarantee of value or
                                    that a mortgage offer will be issued
                                </HelpIcon>
                            </div>
                            <div className="affordability-calc-estimate mt-4 d-none d-lg-block">
                                <p className="lead">Borrowing:</p>
                                <h1 className="display-3">&#163;{this.state.borrowable.toLocaleString('en')}</h1>
                            </div>
                            <div className="affordability-calc-ltv">
                                Loan to Value calculated as: {this.state.ltv}%.
                            </div>
                            <div className="affordability-calc-monthly mt-3 d-none d-lg-block">
                                {
                                    monthlyMarkup
                                }
                            </div>
                            <div className="d-none d-lg-block">
                                {stampDutyMarkup}
                            </div>
                        </div>
                    }
                    <div className="col-12 order-lg-first col-lg-7">
                        <form className="align-middle" style={{display: "inline-block", width: '100%'}}
                              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        >
                            <br/>

                            <RangeSlider id="propertyValue"
                                         text="Property value:"
                                         min={MIN_VALUES.propertyValue}
                                         max={MAX_VALUES.propertyValue}
                                         step={STEPS.propertyValue}
                                         handleRangeChange={this.handleFieldChange}
                                         value={this.state.propertyValue}
                                         exponential={true}
                                         exponentialSmallStepBelow={SMALL_STEP_BELOW.propertyValue}
                            />
                            <br/>
                            <RangeSlider id="deposit"
                                         text="Deposit:"
                                         min={MIN_VALUES.deposit}
                                         max={MAX_VALUES.deposit}
                                         step={STEPS.deposit}
                                         handleRangeChange={this.handleFieldChange}
                                         value={this.state.deposit}
                                         exponential={true}
                                         exponentialSmallStepBelow={SMALL_STEP_BELOW.deposit}
                            />
                            <br/>
                            <RangeSlider id="annualGrossRentalIncome"
                                         text="Annual gross rental income:"
                                         min={MIN_VALUES.annualGrossRentalIncome}
                                         max={MAX_VALUES.annualGrossRentalIncome}
                                         step={STEPS.annualGrossRentalIncome}
                                         handleRangeChange={this.handleFieldChange}
                                         value={this.state.annualGrossRentalIncome}
                                         exponential={true}
                            />
                            <br/>
                            <RangeSliderInterest
                                handleRangeChange={this.handleFieldChange}
                                value={this.state.rate}
                            />
                            <br/>
                            <button className='btn btn-link float-right float-end'
                                    onClick={this.resetSliders}
                            >
                                Reset Sliders
                            </button>
                            <AffordabilityMortgageTypeRadios
                                onChange={(value) => this.handleFieldChange('mortgageType', value)}
                                text='Mortgage Type'
                                value={this.state.mortgageType}
                            />
                            {
                                this.state.mortgageType === AffordabilityMortgageTypeRadios.VALUE_REPAYMENT &&
                                <RangeSlider id="term"
                                             text="Mortgage term:"
                                             min={MIN_VALUES.term}
                                             max={MAX_VALUES.term}
                                             step={1}
                                             handleRangeChange={this.handleFieldChange}
                                             value={this.state.term}
                                             symbol /* empty symbol */
                                />
                            }
                            <div className="affordability-calc-monthly mt-3 d-lg-none">
                                {
                                    monthlyMarkup
                                }
                            </div>

                        </form>
                    </div>
                </div>
                {
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <div className="d-lg-none">{stampDutyMarkup}</div>
                        {
                            /*
                            <div className="row mt-5">
                                <div className="col-6 text-center">
                                    <button type="button"
                                            className="btn btn-outline-primary btn-lg"
                                            style={{whiteSpace: 'normal'}}
                                            onClick={() => window.location='/app/register'}>
                                        Looking to Buy a New Holiday Let
                                    </button>
                                </div>
                                <div className="col-6 text-center">
                                    <button type="button"
                                            className="btn btn-outline-primary btn-lg"
                                            style={{whiteSpace: 'normal'}}
                                            onClick={() => window.location='/app/register'}>
                                        Mortgage an Existing Holiday Let
                                    </button>
                                </div>
                            </div>
                            */
                        }
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default AffordabilityCalculator;