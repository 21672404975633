import * as React from 'react';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';

import Header from '../welcome/Header'
import {isAuth2} from "../amplify-configurator";
import Alert, {ALERTTYPE} from "../form/components/alert/Alert";

class Account extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="container my-account">
                    <div className='row'>
                        <div className='col-12'>
                            <Header {...this.props} type={Header.TYPES.MY_ACCOUNT}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-12 col-md-8 offset-md-2`}>
                            <div className="card account">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h5 className="padding-bottom">My account</h5>
                                        </div>
                                    </div>

                                    {
                                        isAuth2() &&
                                        <Alert type={ALERTTYPE.WARNING} text='Changes not possible in this mode.' />
                                    }

                                    <div className='row'>
                                        <div className='col-12'>
                                            <ChangeEmail  {...this.props} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <ChangePassword {...this.props} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h2>Delete your account</h2>
                                            <p>Due to the regulation of mortgage applications, please contact us in order to request the deletion of your account</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }

}

export default Account;

