import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import {FieldDropdown} from "../../../../../form/components/dropdown";
import FieldGroup from "../../../../../form/components/FieldGroup";
import FieldInput from "common-form/components/FieldInput";
import FieldTextArea from "../../../../../form/components/FieldTextArea";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import { LABEL_TYPE } from "../../../../../form/components/Field";
import {extractFieldName, FIELDS, OTHER} from "common-hypotheca";
import FinancialTable from "./FinancialTable";
import { validateDirectorDetails } from "./validation";

export default function DirectorDetails(parent) {   
    return {
        block: (params)=> {

            return <QuestionBlock {...params}>

                <FieldGroup
                    ref={params.groupAddableRef}
                    item={parent.getCurrentEmployment()}
                    {...parent.getField(FIELDS.MS.EI.Employments)}
                    validate={(context) => {

                        validateDirectorDetails(parent, context, parent.state.employmentIndex);

                    }}
                >
                    
                    <FieldRadioYesNo
                        field={FIELDS.MS.EI.DirShareDoYouHaveAccountant}
                    />

                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.DirShareDoYouHaveAccountant)] === true}
                        field={FIELDS.MS.EI.DirShareAccountantName}
                    />

                    <FieldDropdown
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.DirShareDoYouHaveAccountant)] === true}
                        field={FIELDS.MS.EI.DirShareAccountantQual}
                    />

                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.DirShareDoYouHaveAccountant)] === true &&
                            container[extractFieldName(FIELDS.MS.EI.DirShareAccountantQual)] === OTHER }
                        placeholder="Other qualification description"
                        field={FIELDS.MS.EI.DirShareAccountantQualOther}
                    />

                    <FieldRadioYesNo
                        field={FIELDS.MS.EI.DirShareDoYouProdAccts}
                    />

                    <FinancialTable
                        container={parent.getCurrentEmployment()}
                        field={FIELDS.MS.EI.Sharings}
                        periodField={FIELDS.MS.EI.SharingPeriod}
                        currencyFields={[
                            FIELDS.MS.EI.SharingSalary,
                            FIELDS.MS.EI.SharingDividends,
                            {
                                field: FIELDS.MS.EI.ProfitAfterTax,
                                tooltip: 'Please enter the Profit after tax figure for the whole company, as per its accounts, as lenders like to review any change in profitability, year on year.',
                                allowNegative: true
                            }
                        ]}
                        validate={parent.state.nextTriggered}
                        onChange={parent.saveEmployments}
                        onValidity={(context) => {
                            parent.setState({
                                financialValid: context.isValid
                            });
                        }}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.EI.DirShareBusinessIncome}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.DirShareBusinessIncome)] === true}
                        field={FIELDS.MS.EI.DirShareBusinessIncomeChangedSignificantly}
                    />

                    <FieldTextArea
                        labelType={LABEL_TYPE.Small}
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.DirShareBusinessIncome)] === false}
                        placeholder="For example, has the business ceased trading ..."
                        field={FIELDS.MS.EI.DirShareBusinessIncomeNoReason}
                    />

                    <FieldTextArea
                        labelType={LABEL_TYPE.Small}
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.DirShareBusinessIncomeChangedSignificantly)] === true &&
                            container[extractFieldName(FIELDS.MS.EI.DirShareBusinessIncome)] === true
                        }
                        placeholder="For example, we sold the main division ..."
                        field={FIELDS.MS.EI.DirShareBusinessIncomeChangedReason}
                    />


                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.EI.DirShareNetMonthlyPayment}
                    />

                </FieldGroup>


            </QuestionBlock>
            ;
        },

        validate: (context) => {
            if (!parent.state.financialValid) {
                context.addError('financials', null, '');
            }
        },

        nextQuestionId: () => parent.afterEmploymentCompleted()
    };
};