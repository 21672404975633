import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldInput from "common-form/components/FieldInput";
import FieldLabel from "common-form/components/FieldLabel";
import { LABEL_TYPE } from "common-form/components/Field";
import FieldCheckbox from "common-form/components/FieldCheckbox";

import { formatCurrency } from "../../../../../util/currency";

import { FIELDS } from "common-hypotheca";

export default function LifeItems(parent, nextBlockId) {

    function isSharing() {
        return parent.shouldShowSharingCheck() && parent.getValue(FIELDS.MS.OUT.BasicAlreadyRecorded) === true;
    }

    return {
        block: (params) =>

            <QuestionBlock {...params}>
                
                <FieldLabel
                    label="Quality of life costs: how much do you spend, per month, on the following items"
                />

                <FieldLabel
                    label="If any costs are incurred jointly, record the joint cost just once and leave it blank in the other applicant's profile! If an expenditure type does not apply to you, then please just enter £0."
                    labelType={LABEL_TYPE.Description}
                />

                <FieldCheckbox 
                    label='My share of these costs has already been recorded by another applicant'
                    labelType={LABEL_TYPE.Small}
                    checked={!!parent.getValue(FIELDS.MS.OUT.BasicAlreadyRecorded)}
                    code={!parent.getValue(FIELDS.MS.OUT.BasicAlreadyRecorded)}
                    show={() => parent.shouldShowSharingCheck()}
                    onCheckboxClick={val => {
                        parent.handleFilledField(null, FIELDS.MS.OUT.BasicAlreadyRecorded, val);
                    }}

                />

                {
                    isSharing() === false && 
                    <>

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MS.OUT.BasicSocial)}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MS.OUT.BasicHomeCost)}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MS.OUT.BasicClothing)}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MS.OUT.BasicSavings)}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MS.OUT.BasicOther)}
                    />

                    <FieldLabel
                        label={`Quality of life costs total: ${formatCurrency(parent.getValue(FIELDS.MS.OUT.BasicCostsTotal) || 0)}`}
                    />

                    </>

                }

            </QuestionBlock>,

        nextQuestionId: () => nextBlockId,
        
        validate: (context) => {

            if (!parent.shouldShowSharingCheck()) {
                context.cleanupIrrelevantField(FIELDS.MS.OUT.BasicAlreadyRecorded);
            }

            // This doesn't work.
            // Requires checkbox to be checked.
            // context
            // .mandatoryOrCleanup(parent.shouldShowSharingCheck(), FIELDS.MS.OUT.BasicAlreadyRecorded)
            // ;

            context
            .mandatoryOrCleanup(isSharing() === false, [

                FIELDS.MS.OUT.BasicSocial,
                FIELDS.MS.OUT.BasicHomeCost,
                FIELDS.MS.OUT.BasicClothing,
                FIELDS.MS.OUT.BasicSavings,
                FIELDS.MS.OUT.BasicOther

            ]);


            let totalCost = 0;
            totalCost += parseFloat(parent.getValue(FIELDS.MS.OUT.BasicSocial) || 0);
            totalCost += parseFloat(parent.getValue(FIELDS.MS.OUT.BasicHomeCost) || 0);
            totalCost += parseFloat(parent.getValue(FIELDS.MS.OUT.BasicClothing) || 0);
            totalCost += parseFloat(parent.getValue(FIELDS.MS.OUT.BasicSavings) || 0);
            totalCost += parseFloat(parent.getValue(FIELDS.MS.OUT.BasicOther) || 0);

            //calculate totals - pass last param to noValidate avoid stack overflow
            parent.handleFilledField(null, FIELDS.MS.OUT.BasicCostsTotal, totalCost, null, true);
        }

    };
}