import React from "react";
import PropTypes from "prop-types";

import { LABEL_TYPE } from './Field';
import AddressInput from './AddressInput';
import FieldInput from 'common-form/components/FieldInput';
import FormButton from "./FormButton";

let addressSearchId = 0;

export default class AddressLookup extends FieldInput {

    constructor(props) {
        super(props);
        
        addressSearchId++;

        this.state.searchId = "addressSearch" + addressSearchId;
        this.state.manualInput = false;
    }

    componentDidMount() {

        new window["IdealPostcodes"].Autocomplete.Controller({
            api_key: "ak_k11y9mo0gULF9CLTrMuQYFdnFeRZq",
            inputField: "#" + this.state.searchId,
            outputFields: {
                line_1: "#addressLine1",
                line_2: "#addressLine2",
                post_town: "#addressTown",
                postcode: "#addressPostcode"
            },
            onAddressSelected: () => {

                this.setState({
                    valueSelected: true
                });

                // Ensure row is present in data for groups (such as property list or previous addresses)
                if (this.props.confirmNewRow) {
                    this.props.confirmNewRow();
                }

                setTimeout(() => {

                    document.getElementById(this.state.searchId).value = '';

                    const addressLine1 = document.getElementById('addressLine1').value;
                    const addressLine2 = document.getElementById('addressLine2').value;
                    const town = document.getElementById('addressTown').value;
                    const postCode = document.getElementById('addressPostcode').value;
    
                    this.props.fields[0].handleFieldFilled(null, this.props.fields[0].field, addressLine1);
                    this.props.fields[1].handleFieldFilled(null, this.props.fields[1].field, addressLine2);
                    this.props.fields[2].handleFieldFilled(null, this.props.fields[2].field, town);
                    this.props.fields[4].handleFieldFilled(null, this.props.fields[4].field, postCode);

                }, 500);
            }
        });


    }

    setManualInput() {
        this.setState({
            manualInput: true
        });
    }

    isValid() {
        return this.props.fields.filter(f => f.error).length === 0;
    }

    manualInput() {
        return this.state.manualInput;
    }

    shouldDisplayInputs() {
        return this.state.valueSelected || this.manualInput() || this.props.fields.some(f => f.value);
    }


    render() {

        return <React.Fragment>

            <div className="form-group">
                { 
                    this.props.label && 
                    <label className={this.getLabelClassNameFromType()} htmlFor="addressSearch">
                        {this.props.label}
                    </label>
                }

                <input id={this.state.searchId} type="text" 
                    className={"form-control " + (!this.isValid() ? ' is-invalid' : '')}
                    autoComplete="none"
                    placeholder="Type postcode, address, etc ..."
                />

                <FormButton link onClick={this.setManualInput.bind(this)} text={<strong>Enter address manually</strong>}/>
            </div>

            { 
                this.shouldDisplayInputs() && 
                <>

                <AddressInput
                    labelType={LABEL_TYPE.Small}
                    native={{ id: 'addressLine1' }}
                    {...this.props.fields[0]}
                />

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    native={{ id: 'addressLine2' }}
                    {...this.props.fields[1]}
                />

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    native={{ id: 'addressTown' }}
                    {...this.props.fields[2]}
                />

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    {...this.props.fields[3]}
                />

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    native={{ id: 'addressPostcode' }}
                    {...this.props.fields[4]}
                />
                </>
            }


        </React.Fragment>
            ;

    }

}



AddressLookup.propTypes = {
    label: PropTypes.string,
    labelType: PropTypes.string,
    fields: PropTypes.any.isRequired
};
