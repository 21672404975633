import * as React from "react";
import { FieldCheckbox } from '../../../../form/components/checkbox'
import ControlButtons from "../../../../form/ControlButtons";
import {
    SECTION,
    MY_APPLICATION
} from "common-hypotheca";
import {dashboardLink, initializeDownloadUrls} from "../../../../util/links";
import NonFormSectionPart from "../../../../form/NonFormSectionPart";

class MortgageDocuments extends NonFormSectionPart {
    static defaultProps = {
        sectionId: SECTION.MY_APPLICATION,
        partId: MY_APPLICATION.KEY_DOCS
    };

    constructor(props) {
        super(props);
        this.state = {
            documents: props.data[SECTION.MY_APPLICATION].parts.filter(part => part.id === MY_APPLICATION.KEY_DOCS)[0].documents,
            tickAllRequiredError: false,
            fileUrls: {}
        };
    }

    componentDidMount = () => {
        this.loadSignedURLS();
        let data = this.props.data;
        data[SECTION.MY_APPLICATION].parts.filter(part => part.id === MY_APPLICATION.KEY_DOCS)[0].isComplete = false;
        if (!this.state.documents.filter(doc => !doc.accepted).length) {
            data[SECTION.MY_APPLICATION].parts.filter(part => part.id === MY_APPLICATION.KEY_DOCS)[0].isComplete = true
           
        }
        this.props.setData(data)
    };

    loadSignedURLS() {
        initializeDownloadUrls(this.state.documents, this.state.fileUrls)
            .then (() => {
                // re-render when file urls were returned by s3
                this.forceUpdate();
            });
    }

    setDocOpened = key => {
        let documents = this.state.documents;
        documents.filter(document => document.key === key)[0].opened = true;
        this.setState({ documents })
    };

    tickDocument = (key, code) => {
        this.setState({
            tickAllRequiredError: false
        });

        let documents = this.state.documents;
        documents.filter(document => document.key === key)[0].accepted = code;
        documents.filter(document => document.key === key)[0].acceptedTime = code ? new Date().getTime() : null;
        this.setState({ documents })
        let data = this.props.data;
        data[SECTION.MY_APPLICATION].parts.filter(part => part.id === MY_APPLICATION.KEY_DOCS)[0].documents = documents;
        data[SECTION.MY_APPLICATION].parts.filter(part => part.id === MY_APPLICATION.KEY_DOCS)[0].isComplete = false;
        if (!this.state.documents.filter(doc => !doc.accepted).length) {
            data[SECTION.MY_APPLICATION].parts.filter(part => part.id === MY_APPLICATION.KEY_DOCS)[0].isComplete = true;
        }
        this.props.setData(data);
    };

    nextPage = () => {
        if (this.props.data[SECTION.MY_APPLICATION].parts.filter(part => part.id === MY_APPLICATION.KEY_DOCS)[0].isComplete)
            this.props.history.push(dashboardLink(SECTION.MY_APPLICATION));
        else
            this.setState({
                tickAllRequiredError: true
            });
    };

    render = () => {
        return (
            <div className="row">
                <div className={`col-12 col-md-8 offset-md-2`}>
                    <div className="card mortgage-documents">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='col-form-label'>Review key mortgage documents</div>
                                    {
                                        this.renderReadOnlyAlert()
                                    }
                                    <p>
                                        Please click to read each document below, carefully. If you have any questions or need any clarification, please get in touch with your advisor. Once you are satisfied with each, please tick the box next to each document to confirm your understanding and agreement.
                                    </p>
                                </div>
                            </div>
                            {
                                this.state.documents.map((doc, i) =>
                                    <div key={i} className='row document-row'>

                                        <div className='col-2'>
                                            {
                                                <FieldCheckbox
                                                    field={doc.key}
                                                    code={!doc.accepted}
                                                    onCheckboxClick={code => this.tickDocument(doc.key, code)}
                                                    name={`check${i}`}
                                                    checked={doc.accepted}
                                                    label={` `}
                                                    disabled={!doc.opened || this.readOnly()}
                                                />
                                            }
                                        </div>
                                        <div className='col-10'>
                                            <a className={doc.opened ? 'document-opened' : ''}
                                               target='_blank' href={this.state.fileUrls[doc.key]}
                                               onClick={() => this.setDocOpened(doc.key)}
                                               rel="noopener noreferrer"
                                            >
                                                <span className='document-icon'>&nbsp;</span> {doc.title}
                                            </a>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                this.state.tickAllRequiredError &&
                                <div className='row'>
                                    <div className='col-12'>
                                        <p className='invalid-general invalid-feedback'>
                                            All documents should be opened and then accepted.
                                        </p>
                                    </div>
                                </div>
                            }

                            <div className='row'>
                                <div className='col-12'>
                                    <ControlButtons nextPage={this.nextPage}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MortgageDocuments;
