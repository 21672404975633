import * as React from "react";

import QuestionBlock from "../../../../form/QuestionBlock";
import {FieldRadioPicture} from "../../../../form/components/radio";

import {FIELDS} from "common-hypotheca";


export default function SelectPurchaseStage(parent, nextBlockId) {


    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldRadioPicture
                    {...parent.getFormField(null, FIELDS.AS.MTA.PurchaseStage)}
                />

            </QuestionBlock>,


        validate: (context) => {
            context.mandatory(FIELDS.AS.MTA.PurchaseStage);
        },

        nextQuestionId: () => nextBlockId
    };

    
};