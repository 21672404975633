import React, {Component} from 'react';
import PropTypes from "prop-types";
import HelpIcon from "common-form/components/HelpIcon";

class FieldRadioButton extends Component {
    static defaultProps = {
        disabled: false
    };

    render() {
        let id = 'radio-' + this.props.field + '-' + this.props.code;

        // refs are used here as sometimes label click wasn't propagated to radio click, now doing
        // it manually.
        let ref = React.createRef();

        // form-check - bootstrap5
        // custom-radio formerly used for bootstrap4, still used in custom css
        return (
            <div className={`form-check custom-radio ${this.props.inline ? 'form-check-inline' : ''}`}>
                <input disabled={this.props.disabled}
                       type="radio"
                       className="form-check-input"
                       name={this.props.field}
                       value={this.props.code}
                       onChange={this.props.onChange}
                       checked={this.props.checked}
                       id={id}
                       ref={ref}
                />
                <label className="form-check-label"
                       htmlFor={id}
                       onClick={(e) =>{ref.current.click(); e.preventDefault()}}
                >
                    {this.props.label}
                    {
                        this.props.tooltip != null &&
                        <div className='help-icon-container'>
                            <HelpIcon>{this.props.tooltip}</HelpIcon>
                        </div>
                    }
                </label>
            </div>
        );
    }
}

FieldRadioButton.propTypes = {
    code: PropTypes.any.isRequired,
    inline: PropTypes.bool,
    label: PropTypes.any.isRequired,
    field: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool
};

export default FieldRadioButton;