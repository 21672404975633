import { connect } from 'react-redux';
import {
    setData,
    setUserData,
    setApplicationId
} from '../redux/actions';
import Dashboard from './Dashboard';
import {Adapter} from "../util/models";
const mapStateToProps = (state, ownProps) => ({
    data: state.data,
    adapter: new Adapter(state.data),
    applicationId:state.applicationId,
    user:state.user
}); 

const mapDispatchToProps = dispatch => ({
   // setApplicationFromUrl:(urlData) => dispatch(setApplicationFromUrl(urlData)),
    setData: (data, preventPersist) => dispatch(setData(data, preventPersist)),
    setUserData: user => dispatch(setUserData(user)),
    setApplicationId: (applicationId) => dispatch(setApplicationId(applicationId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)
