import * as React from "react";

import QuestionBlock from "../../../../form/QuestionBlock";
import FieldInput from "common-form/components/FieldInput";
import FieldTextArea from "../../../../form/components/FieldTextArea";
import FieldLabel from "common-form/components/FieldLabel";
import {LABEL_TYPE} from "common-form/components/Field";
import { ALERTTYPE, Alert } from "../../../../form/components/alert/Alert";

import {
    getTimeDiff,
    addYears, addMonths, getAgoValue
} from "common-hypotheca/src/dates";

import {
    FIELDS,
    SECTION,
    SITUATION
} from "common-hypotheca";
import {FormButton} from "../../../../form/components";
import {possessive} from "../../../../util/values";


export default function TimeTerm(parent, nextBlockId) {
    
    function getMortgageTerm() {
        const value = parseInt(parent.getValue(FIELDS.MM.MD.PreferredMortgageTerm), 0);
        return isNaN(value) ? null : value;
    }

    function getDateOfBirth(applicantId) {
        if (applicantId === undefined || applicantId === null) {
            applicantId = parent.props.adapter.applicantIds()[0];
        }
        return parent.props.adapter.fieldValue(FIELDS.MS.PD.DOB, applicantId, SECTION.SITUATION, SITUATION.PERSONAL_DETAILS);
    }
    
    function termRunsIntoRetirementAge(applicantId) {
        return getMortgageTerm() > maxApplicantMortgageTerm(applicantId);
    }

    function mortgageTermValid() {
        let mortgageTerm = getMortgageTerm();
        return mortgageTerm != null && mortgageTerm <= 50;
    }

    function maxApplicantMortgageTerm(applicantId) {
        const retirementDate = addYears(getDateOfBirth(applicantId), retirementAge(applicantId));

        // Add 3 months as mortgages usually complete in 3 months
        let maxMortgageTerm = getTimeDiff(addMonths(new Date(), 3), retirementDate, 1900, 2300);

        // Use whole years
        return maxMortgageTerm === undefined || maxMortgageTerm === null ? undefined : maxMortgageTerm.years;
    }

    function applicantMortgageCompleteAgeRoundUp(applicantId) {
        const mortgageCompleteDate = addMonths(new Date(), 3);
        const mortgageCompleteAge = getTimeDiff(getDateOfBirth(applicantId), mortgageCompleteDate, 1900, 2300);

        // Use whole years
        if (mortgageCompleteAge === undefined || mortgageCompleteAge === null) {
            return undefined;
        }

        return mortgageCompleteAge.months > 1 || mortgageCompleteAge.days > 1
            ? (mortgageCompleteAge.years + 1) + " years old"
            : mortgageCompleteAge.years + " years old";
    }

    function retirementDescription(applicantId) {
        let retirementAgeValue = retirementAge(applicantId);
        let age = getAgeYears(applicantId);

        if (retirementAgeValue === '' || retirementAgeValue === undefined || retirementAgeValue === null || isNaN(retirementAgeValue)) {
            return 'Retirement age is mandatory.';
        } else if (retirementAgeValue > 100) {
            return 'Value should be less than or equal to 100.';
        } else if (age >= retirementAgeValue) {
            return parent.props.adapter.applicantCount() === 1
                ? "Therefore, you are in retirement or will retire soon."
                : "Therefore, this person is in retirement or will retire soon.";
        } else if (getMortgageTerm() === null) {
            return '';
        }

        let diff = getMortgageTerm() - maxApplicantMortgageTerm(applicantId);

        if (isNaN(diff)) {
            return '';
        }

        if (diff < 1) {
            return 'Therefore, this person is not lending into retirement.';
        }

        let diffText = diff === 1
            ? "1 year"
            : diff + " years";

        if (diff === 1) {
            return `Therefore, ${parent.props.adapter.applicantCount() > 1 ? "this person" : "you"} might be lending
                                        into retirement for up to ${diffText}.`;
        } else {
            return `Therefore, ${parent.props.adapter.applicantCount() > 1 ? "this person is" : "you are"} lending
                                        into retirement for ${diffText}.`;
        }
    }

    function maxMortgageTerm() {
        let result = 50;
        for (let applicantId of parent.props.adapter.applicantIds()) {
            let applicantTerm = maxApplicantMortgageTerm(applicantId);
            if (!isNaN(applicantTerm) && applicantTerm < result) {
                result = applicantTerm;
            }
        }
        return result;
    }

    function termRunsIntoRetirementAgeForSomeone() {
        let result = false;
        for (let applicantId of parent.props.adapter.applicantIds()) {
            result = result || termRunsIntoRetirementAge(applicantId);
        }
        return result;
    }

    function termRunsIntoRetirementForList() {
        let applicants = parent.props.adapter.applicantIds().filter((applicantId) => termRunsIntoRetirementAge(applicantId));
        let result = '';
        for (let i = 0; i < applicants.length; i++) {
            if (i > 0 && i === applicants.length - 1) {
                result += " and ";
            } else if (i>0) {
                result += ", ";
            }

            result += parent.props.adapter.applicantName(applicants[i]);
        }

        return result;
    }

    function termDoesNotRunIntoRetirementAgeForAll() {
        let result = true;
        for (let applicantId of parent.props.adapter.applicantIds()) {
            result = result && !termRunsIntoRetirementAge(applicantId);
        }
        return result;
    }

    function retirementAge(applicantId) {
        if (applicantId === undefined || applicantId === null) {
            applicantId = parent.props.adapter.applicantIds()[0];
        }
        return parent.props.adapter.fieldValue(FIELDS.MS.PD.RetirementAge, applicantId, SECTION.SITUATION, SITUATION.PERSONAL_DETAILS);
    }

    function toggleRetirementAges() {
        parent.setState({
            retirementAgedShown: !parent.state.retirementAgedShown
        });
    }

    function retirementAgedShown() {
        return parent.state.retirementAgedShown;
    }

    function getAgeYears(applicantId) {
        const years = getAgoValue(getDateOfBirth(applicantId), 'years');
        return years > 0 && years < 120 ? years : null;
    }

    function retirementAgeValid(applicantId) {
        let retirementAgeValue = retirementAge(applicantId);
        return retirementAgeValue !== ''
            && retirementAgeValue !== undefined
            && retirementAgeValue !== null
            && !isNaN(retirementAgeValue)
            && retirementAgeValue <= 100;
    }

    function allRetirementAgesValid() {
        let result = true;
        for (let applicantId of parent.props.adapter.applicantIds()) {
            result = result && retirementAgeValid(applicantId);
        }

        return result;
    }

    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldLabel
                    label={`${parent.getApplicantName()}, how long do you want the term of your new mortgage to be?`}
                    tooltip="If you plan on lending into retirement, lenders will want to know how you will continue to make your mortgage payments."
                    tooltipPlace='bottom'
                />

                {
                    maxMortgageTerm() > 0 &&
                    allRetirementAgesValid() &&
                    parent.props.adapter.applicantCount() === 1 &&
                    <FieldLabel
                        label={`To avoid lending into proposed retirement, enter ${maxMortgageTerm()} years or less.`}
                        labelType={LABEL_TYPE.Description}
                    />
                }
                {
                    maxMortgageTerm() > 0 &&
                    allRetirementAgesValid() &&
                    parent.props.adapter.applicantCount() > 1 &&
                    <FieldLabel
                        label={`To avoid any applicant lending into their proposed retirement, enter ${maxMortgageTerm()} years or less.`}
                        labelType={LABEL_TYPE.Description}
                    />
                }

                <FieldInput
                    {...parent.getField(FIELDS.MM.MD.PreferredMortgageTerm)}
                    labelType={LABEL_TYPE.Small}
                    type="number"
                    placeholder={maxMortgageTerm() > 0 ? `Enter ${maxMortgageTerm()} or less to avoid lending into retirement` : ''}
                />

                {
                    mortgageTermValid() &&
                    termDoesNotRunIntoRetirementAgeForAll() &&
                    <>
                        <Alert
                            type={ALERTTYPE.SUCCESS}
                            text={`Mortgage term does not run into retirement${parent.props.adapter.applicantCount() > 1 ? ' for any of the applicants' : ''}.`}
                        />

                        <FieldTextArea
                            {...parent.getField(FIELDS.MM.MD.MortgageTermReason)}
                            labelType={LABEL_TYPE.Small}
                        />
                    </>
                }

                {
                    mortgageTermValid() &&
                    termRunsIntoRetirementAgeForSomeone() &&
                    <>
                        <Alert
                            type={ALERTTYPE.WARNING}
                            text={`${parent.props.adapter.applicantCount() > 1 ? termRunsIntoRetirementForList() : "You"} will be lending into retirement.`}
                        />

                        <FieldTextArea
                            {...parent.getField(FIELDS.MM.MD.HowPaymentsInRetirement)}
                            labelType={LABEL_TYPE.Small}
                            type="number"
                        />
                    </>
                }
                {
                    <FormButton
                        link
                        text={`${retirementAgedShown() ? "Hide" : "Show"} applicant retirement age${parent.props.adapter.applicantCount() > 1 ? "s": ""}`}
                        onClick={toggleRetirementAges}
                    />
                }
                {
                    retirementAgedShown() &&
                    parent.props.adapter.applicantIds().map((applicantId) =>
                        <>
                            <FieldLabel
                                key={'retirement-age-label' + applicantId}
                                label={(parent.props.adapter.applicantCount() >= 1 ? possessive(parent.props.adapter.applicantName(applicantId)) : "Your") + " proposed retirement age"}
                            />
                            {
                                <FieldLabel
                                    key={'lending-into-retirement-' + applicantId}
                                    label={
                                        `By the time of completion, after rounding up a year, let’s
                                        assume ${parent.props.adapter.applicantCount() > 1 ? parent.props.adapter.applicantName(applicantId) + " is " : "you are"} ${applicantMortgageCompleteAgeRoundUp(applicantId)}.
                                        ${retirementDescription(applicantId)}`
                                    }
                                    labelType={LABEL_TYPE.Description}
                                    className={termRunsIntoRetirementAge(applicantId) ? 'warning' : ''}
                                />
                            }
                            <FieldInput key={applicantId}
                                        {...parent.fieldComponentProperties(FIELDS.MS.PD.RetirementAge, {
                                            applicantId,
                                            sectionId: SECTION.SITUATION,
                                            partId: SITUATION.PERSONAL_DETAILS
                                        })}
                                        type='number'
                                        label
                            />
                        </>
                    )
                }

            </QuestionBlock>,


        validate: (context) => {
            context
                .mandatory(FIELDS.MM.MD.PreferredMortgageTerm)
                .between(FIELDS.MM.MD.PreferredMortgageTerm, 1, 50);

            if (context.isValid(FIELDS.MM.MD.PreferredMortgageTerm)) {
                context
                    .mandatoryOrCleanup(termRunsIntoRetirementAgeForSomeone(), FIELDS.MM.MD.HowPaymentsInRetirement)
                    .mandatoryOrCleanup(termDoesNotRunIntoRetirementAgeForAll(), FIELDS.MM.MD.MortgageTermReason);
            }

            for (let applicantId of parent.props.adapter.applicantIds()) {
                let partParams = {
                    applicantId,
                    sectionId: SECTION.SITUATION,
                    partId: SITUATION.PERSONAL_DETAILS
                };
                context
                    .mandatory(FIELDS.MS.PD.RetirementAge, partParams)
                    .maxValue(FIELDS.MS.PD.RetirementAge, 100, 'Value should be less than or equal to 100', partParams);

                // Show retirement ages if retirement age is invalid during full form validation so that error is
                // visible on screen
                if (!context.isOneFieldValidation()
                    && context.isValid(FIELDS.MM.MD.PreferredMortgageTerm)
                    && !context.isValid(FIELDS.MS.PD.RetirementAge, partParams)) {

                    parent.setState({
                        retirementAgedShown: true
                    });
                }
            }

            // Show retirement ages if user edits the preferred mortgage term field and runs into retirement.
            if (context.isOneFieldValidation()
                && context.isFieldBeingValidated(FIELDS.MM.MD.PreferredMortgageTerm)
                && context.isValid(FIELDS.MM.MD.PreferredMortgageTerm)
                && termRunsIntoRetirementAgeForSomeone()) {

                parent.setState({
                    retirementAgedShown: true
                });
            }
        },

        nextQuestionId: () => nextBlockId()
    };

    
};