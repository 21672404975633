import {connect} from "react-redux";
import { APINAME, APIPATH } from "../constants";
import { setUserData, savedUser } from '../redux/actions'
import { API } from "aws-amplify";
import { Component } from "react";

class PersistUserDataService extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    persistUserDataTimeout;

    componentDidUpdate(prevProps) {
        //  console.log('PersistUserDataService did update', this.props);
        if (prevProps.user === undefined || this.props.user === null) {
            return;
        }
        clearTimeout(this.persistUserDataTimeout);

        this.persistUserDataTimeout = setTimeout(() => {
            this.persistUserData()
        }, 1000);
    };

    persistUserData() {
        /*
        this.setState({
            saveState: SAVE_STATE.SAVING
        });
        */
        this.props.savedUser(true);
        let user = this.props.user;

        if (user === null || user === undefined) {
            return;
        }

        if (user.time === undefined) {
            user.time = {};
        }


        user.time.lastUpdated = Date.now()
        //    if(data.status === APPLICATION_STATUS.INITIAL){
        //     data.status = APPLICATION_STATUS.STARTED
        //     console.log('changed status to started')
        //    }
        
        API
            .post(APINAME, APIPATH.USER, {
                body: user
            })
            .then(response => {
                // Add your code here
                this.props.savedUser(new Date().getTime())
            })
            .catch(error => {
                console.log(error.response)
            })
            // Finally not available on MS Edge
    }

    getUserData() {
        //  console.log('getting applicaiton data', this.props.applicationId)
        API.get(APINAME, APIPATH.USER, {})
            .then(response => {
                this.props.setUserData(response.Item)
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    setUserData: (data) => dispatch(setUserData(data)),
    savedUser: when => dispatch(savedUser(when))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersistUserDataService)
