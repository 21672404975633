import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import {FieldLabel} from "common-form/components/FieldLabel";
import { LABEL_TYPE } from "common-form/components/Field";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldTextArea from "../../../../../form/components/FieldTextArea";
import {Alert, ALERTTYPE} from "../../../../../form/components/alert/Alert";

import {
    FIELDS
} from "common-hypotheca";


export default function LifeInsurance(parent, nextQuestionId) {

    function wantsInsurance(value) {
        return parent.getValue(FIELDS.MA.SSI.DoIWantLifeAssurance) === value;
    }

    return {
        block: (params) =>

            <QuestionBlock {...params}>

                <FieldLabel
                    label="Do you want to discuss arranging mortgage protection insurance?"
                />
                <FieldLabel
                    label="For your peace of mind and protection, please tell us if would you like to discuss mortgage protection insurance to ensure 
                        the new mortgage will be repaid in the event of death."
                    labelType={LABEL_TYPE.Description}
                />

                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MA.SSI.DoIWantLifeAssurance)}
                />

                {
                    wantsInsurance(false) &&
                    <FieldTextArea
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MA.SSI.DoIWantLifeAssuranceWhyNot)}
                    />
                }

                {
                    wantsInsurance(true) &&
                    <Alert
                        text="Thank you for letting us know. Your advisor will be in touch shortly."
                        type={ALERTTYPE.SUCCESS}
                    />
                }
            </QuestionBlock>,

        validate: (context) => {
            context
                .mandatory(FIELDS.MA.SSI.DoIWantLifeAssurance)
                .mandatoryOrCleanup(wantsInsurance(false), FIELDS.MA.SSI.DoIWantLifeAssuranceWhyNot)
                .cleanupIrrelevantField(FIELDS.MA.SSI.DoIWantLifeAssuranceHCMReferal);
        },

        nextQuestionId: () => nextQuestionId
    };

};