import {_FIELDS, _OPTIONS_YES_NO, _PERCENTAGE, _POUND_AMOUNT_WITH_DECIMALS} from "./fields";
import {_PREFERRED_MORTGAGE_TYPE} from "./radio_preferred_mortgage_type";
import {_INTEREST_RATE_TYPE} from "./dropdown_interest_rate_type";

_FIELDS.ADMIN = {};

_FIELDS.ADMIN.LETTER = {

    // --------
    // Suitability
    // --------

    lenderName: {
        label: 'Lender name',
    },
    productName: {
        label: 'Product name',
    },
    mortgageRepaymentType: {
        label: 'Mortgage repayment type',
        options: _PREFERRED_MORTGAGE_TYPE.OPTIONS_ADMIN
    },
    term: {
        label: 'Term (years)'
    },
    type: {
        label: 'Type',
        options: [
            {code: '', text: ''},
            {code: _INTEREST_RATE_TYPE.DISCOUNTED, text: 'Discount Variable'},
            {code: _INTEREST_RATE_TYPE.STANDARD, text: 'Standard Variable'},
            {code: _INTEREST_RATE_TYPE.FIXED, text: 'Fixed'}
        ]
    },
    initialPeriod: {
        label: 'Initial period',
        options: [
            {code: '', text: ''},
            {code: '2', text: '2 years'},
            {code: '3', text: '3 years'},
            {code: '5', text: '5 years'},
            {code: '10', text: '10 years'},
            {code: 'term', text: 'term of the mortgage'}
        ]
    },
    initialPeriodEndDate: {
        label: 'Initial period end date'
    },
    hasProductEndDate: {
        label: 'Does the product have a fixed initial period end date?',
        options: _OPTIONS_YES_NO
    },

    initialMonthlyPayment: {
        label: 'Initial monthly payment',
        designation: _POUND_AMOUNT_WITH_DECIMALS
    },
    subsequentMonthlyPayment: {
        label: 'Subsequent monthly payment',
        designation: _POUND_AMOUNT_WITH_DECIMALS
    },

    otherInitialMonthlyPayment: {
        label: 'Other Initial monthly payment',
        designation: _POUND_AMOUNT_WITH_DECIMALS
    },
    otherSubsequentMonthlyPayment: {
        label: 'Other Subsequent monthly payment',
        designation: _POUND_AMOUNT_WITH_DECIMALS
    },

    initialRate: {
        label: 'Initial Rate',
        designation: _PERCENTAGE
    },
    svr: {
        label: 'SVR',
        designation: _PERCENTAGE
    },
    aprc: {
        label: 'APRC',
        designation: _PERCENTAGE
    },
    bankOfEnglandBaseRate: {
        label: 'Bank of England Base Rate',
        designation: _PERCENTAGE
    },

    overpayment: {
        label: 'Overpayment %',
        designation: _PERCENTAGE
    },
    procFee: {
        label: 'Proc fee',
        designation: _POUND_AMOUNT_WITH_DECIMALS
    },
    regularMonthlyIncome: {
        label: 'Regular monthly income',
        designation: _POUND_AMOUNT_WITH_DECIMALS
    },
    regularMonthlyOutgoings: {
        label: 'Regular monthly outgoings',
        designation: _POUND_AMOUNT_WITH_DECIMALS
    },
    expertReviewDate: {
        label: 'Date of Expert Review'
    },

    reRequestedTC: {
        label: 'Re-requested T&C',
        options: _OPTIONS_YES_NO
    },
    reRequestedTCDate: {
        label: 'Re-requested T&C Date',
    },

    fcaRegulated: {
        label: 'Is this mortgage regulated',
        options: _OPTIONS_YES_NO
    },
    partOfLetToBuy: {
        label: 'Is this transaction part of a Let to Buy?',
        options: _OPTIONS_YES_NO
    },
    lgPaymentRoute: {
        label: 'L&G payment route?',
        options: _OPTIONS_YES_NO
    },
    productFeesCanBeAddedToLoan: {
        label: 'Are there any product fees that can be added to the loan',
        options: _OPTIONS_YES_NO
    },
    payingProductFeesUpfront: {
        label: 'Are you paying product fees up front',
        options: _OPTIONS_YES_NO
    },
    settlingCreditBeforeCompletion: {
        label: 'Are you settling any credit prior to completion',
        options: _OPTIONS_YES_NO
    },
    formalDebtConsolidationCase: {
        label: 'Is this a formal Debt Consolidation case?',
        options: _OPTIONS_YES_NO
    },

    // --------
    // Suitability correction
    // --------

    newInitialMonthlyPayment: {
        label: 'New initial monthly payment',
        designation: _POUND_AMOUNT_WITH_DECIMALS
    },
    newSubsequentMonthlyPayment: {
        label: 'New subsequent monthly payment',
        designation: _POUND_AMOUNT_WITH_DECIMALS
    },

    /** Repayment only. */
    newOtherInitialMonthlyPayment: {
        label: 'New other initial monthly payment',
        designation: _POUND_AMOUNT_WITH_DECIMALS
    },
    /** Repayment only. */
    newOtherSubsequentMonthlyPayment: {
        label: 'New other subsequent monthly payment',
        designation: _POUND_AMOUNT_WITH_DECIMALS
    },

    newProcFee: {
        label: 'New proc fee',
        designation: _POUND_AMOUNT_WITH_DECIMALS
    },
};

// Auto-assign names from key.
for (let key of Object.keys(_FIELDS.ADMIN.LETTER)) {
    _FIELDS.ADMIN.LETTER[key].name = key;
}
