import MultiBlockFormSingleApplicant from "../../../form/MultiBlockFormSingleApplicant";
import {FIELDS, MY_MORTGAGE, PROPERTY, SECTION} from "common-hypotheca";
import QuestionBlock from "../../../form/QuestionBlock";
import FieldLabel from "common-form/components/FieldLabel";
import {LABEL_TYPE} from "common-form/components/Field";
import FormButton from "../../../form/components/FormButton";
import FormImage, {IMAGE_LIMITED_COMPANY} from "../../../form/components/FormImage";
import * as React from "react";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import {CAN_SUBMIT} from "../../../constants";
import {Alert} from "../../../form/components";
import FieldInput from "common-form/components/FieldInput";
import AddressLookup from "../../../form/components/AddressLookup";
import FieldTextArea from "../../../form/components/FieldTextArea";
import {ALERTTYPE} from "../../../form/components/alert/Alert";
import {globalCleanupLimitedCompany} from "../../../util/globalCleanup";

const BLOCK_WELCOME = 1;
const BLOCK_INCORPORATED = 2;
const BLOCK_COMPANY_INFO = 3;
const BLOCK_OWNERSHIP = 4;
const BLOCK_HISTORY = 5;

class LimitedCompany extends MultiBlockFormSingleApplicant {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            sectionId: SECTION.MY_MORTGAGE,
            partId: MY_MORTGAGE.LIMITED_COMPANY,

            slideIds: [BLOCK_WELCOME],
            activeSlideId: BLOCK_WELCOME
        };
    }

    /**
     * @returns {number} of expected slides for this part.
     */
    slidesExpected = () => {
        return 5;
    };

    checkForCompleteState = data => {
        this.partContainer().isComplete = true;
        return data;
    };

    alreadyIncorporated = () => {
        return this.fieldValue(FIELDS.MM.LTD.LimitedCompanyAlreadyIncorporated) === true;
    };

    hasBankAccount = () => {
        return this.fieldValue(FIELDS.MM.LTD.LimitedCompanyHasBankAccount) === true;
    };

    previouslyOwned = () => {
        return this.fieldValue(FIELDS.MM.LTD.LimitedCompanyPreviouslyOwnedByCompany) === true;
    };

    limitedCompanyTradingCompany = () => {
        return this.fieldValue(FIELDS.MM.LTD.LimitedCompanyTradingCompany) === true;
    };

    refusedMortgage = () => {
        return this.fieldValue(FIELDS.MM.LTD.LimitedCompanyRefusedMortgage) === true;
    };

    failedPayments = () => {
        return this.fieldValue(FIELDS.MM.LTD.LimitedCompanyFailedPayments) === true
    };

    judgementForDebt = () => {
        return this.fieldValue(FIELDS.MM.LTD.LimitedCompanyJudgementForDebt) === true;
    };

    getQuestion = which => {
        switch (which) {
            case BLOCK_WELCOME:
                return {
                    block: (params)=>
                        <QuestionBlock {...params} controlButtons={null}>

                            <FieldLabel
                                label="As this application will be made by a Limited Company, we just need to gather some key information for the application"
                            />

                            <FieldLabel
                                label="If the company has not yet been incorporated, you will be able to skip this section and tell us the detail later!"
                                labelType={LABEL_TYPE.Description}
                            />

                            <FormButton
                                text="Let’s go!"
                                onClick={() => this.swiperNext()}
                            />
                            <FormImage imageType={IMAGE_LIMITED_COMPANY}/>
                        </QuestionBlock>,
                    canNext: () => true,
                    nextQuestionId: () => BLOCK_INCORPORATED
                };

            case BLOCK_INCORPORATED:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldRadioYesNo
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyAlreadyIncorporated)}
                            />

                            <Alert
                                show={this.fieldValue(FIELDS.MM.LTD.LimitedCompanyAlreadyIncorporated) === false}
                                text="We will need various details of the Limited Company when it is incorporated. Please speak to your advisor when you are ready!"
                            />
                        </QuestionBlock>,
                    validate: (context) => {
                        globalCleanupLimitedCompany(this.props.adapter);
                        context.mandatory(FIELDS.MM.LTD.LimitedCompanyAlreadyIncorporated);
                    },
                    canNext: () => true,
                    nextQuestionId: () => this.alreadyIncorporated()
                        ? BLOCK_COMPANY_INFO
                        : CAN_SUBMIT
                };

            case BLOCK_COMPANY_INFO:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldLabel
                                label="Please provide the following information for the Limited Company"
                            />

                            <FieldInput
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyRegisteredName)}
                            />

                            <FieldInput
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyRegistrationNumber)}
                            />

                            <AddressLookup
                                label="Registered Company Address"
                                labelType={LABEL_TYPE.Small}
                                fields={[
                                    this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyAddressLine1),
                                    this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyAddressLine2),
                                    this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyTown),
                                    this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyCounty),
                                    this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyPostcode)
                                ]}
                            />

                            <FieldInput
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanySic)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyHasBankAccount)}
                            />

                            <FieldTextArea
                                show={this.hasBankAccount()}
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyBankAccountProvider)}
                            />

                            <FieldInput
                                show={this.hasBankAccount()}
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyBankAccountNumber)}
                            />

                        </QuestionBlock>,
                    validate: (context) => {
                        context
                            .mandatory(FIELDS.MM.LTD.LimitedCompanyRegisteredName)
                            .mandatory(FIELDS.MM.LTD.LimitedCompanyRegistrationNumber)
                            // Address block
                            .mandatory(FIELDS.MM.LTD.LimitedCompanyAddressLine1)
                            .optional(FIELDS.MM.LTD.LimitedCompanyAddressLine2)
                            .mandatory(FIELDS.MM.LTD.LimitedCompanyTown)
                            .optional(FIELDS.MM.LTD.LimitedCompanyCounty)
                            .mandatory(FIELDS.MM.LTD.LimitedCompanyPostcode)
                            // SIC(s)
                            .mandatory(FIELDS.MM.LTD.LimitedCompanySic)
                            // Bank account
                            .mandatory(FIELDS.MM.LTD.LimitedCompanyHasBankAccount)
                            .mandatoryOrCleanup(this.hasBankAccount(), FIELDS.MM.LTD.LimitedCompanyBankAccountProvider)
                            .mandatoryOrCleanup(this.hasBankAccount(), FIELDS.MM.LTD.LimitedCompanyBankAccountNumber);

                    },
                    canNext: () => true,
                    nextQuestionId: () => BLOCK_OWNERSHIP
                };

            case BLOCK_OWNERSHIP:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyDirectorsAreApplicants)}
                            />

                            <Alert
                                type={ALERTTYPE.DANGER}
                                show={this.fieldValue(FIELDS.MM.LTD.LimitedCompanyDirectorsAreApplicants) === false}
                                text='“Lenders would normally expect to see each Director as a mortgage applicant!'
                            />

                            <FieldTextArea
                                show={this.fieldValue(FIELDS.MM.LTD.LimitedCompanyDirectorsAreApplicants) === false}
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyDirectors)}
                            />

                            <FieldTextArea
                                labelType={LABEL_TYPE.Small}
                                tooltip='The total percentage must add up to 100%'
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyShareholders)}
                            />

                            <FieldTextArea
                                show={this.props.adapter.isMortgageToBuyNewPlace()}
                                labelType={LABEL_TYPE.Small}
                                placeholder='For example: retained profits, Director’s loan, inter-company loan…'
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyDepositHow)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyDirectorsPersonalGuarantees)}
                            />

                            <Alert
                                type={ALERTTYPE.WARNING}
                                show={this.fieldValue(FIELDS.MM.LTD.LimitedCompanyDirectorsPersonalGuarantees) === false}
                                text='It is normal for lenders to require all Directors to sign Personal Guarantees. In the event of the loan falling into default, the lender will have the right to claim against these Personal Guarantees to recover their loss. You must take legal advice on this point and satisfy yourself before entering into a mortgage contract.'
                            />
                        </QuestionBlock>,
                    validate: (context) => {
                        context
                            .mandatory(FIELDS.MM.LTD.LimitedCompanyDirectorsAreApplicants)
                            .mandatoryOrCleanup(
                                this.fieldValue(FIELDS.MM.LTD.LimitedCompanyDirectorsAreApplicants) === false,
                                FIELDS.MM.LTD.LimitedCompanyDirectors
                            )
                            .mandatory(FIELDS.MM.LTD.LimitedCompanyShareholders)
                            .optionalOrCleanup(this.props.adapter.isMortgageToBuyNewPlace(), FIELDS.MM.LTD.LimitedCompanyDepositHow)
                            .mandatory(FIELDS.MM.LTD.LimitedCompanyDirectorsPersonalGuarantees);
                    },
                    canNext: () => true,
                    nextQuestionId: () => BLOCK_HISTORY
                };

            case BLOCK_HISTORY:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyPreviouslyOwnedByCompany)}
                            />

                            <FieldTextArea
                                show={this.previouslyOwned()}
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyPreviouslyOwnedByCompanyExplanation)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyTradingCompany)}
                            />

                            <FieldTextArea
                                show={this.limitedCompanyTradingCompany()}
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyTradingCompanyExplanation)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyRefusedMortgage)}
                            />

                            <FieldTextArea
                                show={this.refusedMortgage()}
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyRefusedMortgageExplanation)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyFailedPayments)}
                            />

                            <FieldTextArea
                                show={this.failedPayments()}
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyFailedPaymentsExplanation)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyJudgementForDebt)}
                            />

                            <FieldTextArea
                                show={this.judgementForDebt()}
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyJudgementForDebtExplanation)}
                            />

                            <FieldInput
                                type='number'
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyBuyToLets)}
                            />

                            <FieldInput
                                type='number'
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyHolidayLets)}
                            />

                            <FieldInput
                                type='number'
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.MM.LTD.LimitedCompanyOtherProperties)}
                            />

                        </QuestionBlock>,
                    validate: (context) => {
                        context
                            .mandatory(FIELDS.MM.LTD.LimitedCompanyPreviouslyOwnedByCompany)
                            .mandatoryOrCleanup(this.previouslyOwned(), FIELDS.MM.LTD.LimitedCompanyPreviouslyOwnedByCompanyExplanation)
                            .mandatory(FIELDS.MM.LTD.LimitedCompanyTradingCompany)
                            .mandatoryOrCleanup(this.limitedCompanyTradingCompany(), FIELDS.MM.LTD.LimitedCompanyTradingCompanyExplanation)
                            .mandatory(FIELDS.MM.LTD.LimitedCompanyRefusedMortgage)
                            .mandatoryOrCleanup(this.refusedMortgage(), FIELDS.MM.LTD.LimitedCompanyRefusedMortgageExplanation)
                            .mandatory(FIELDS.MM.LTD.LimitedCompanyFailedPayments)
                            .mandatoryOrCleanup(this.failedPayments(), FIELDS.MM.LTD.LimitedCompanyFailedPaymentsExplanation)
                            .mandatory(FIELDS.MM.LTD.LimitedCompanyJudgementForDebt)
                            .mandatoryOrCleanup(this.judgementForDebt(), FIELDS.MM.LTD.LimitedCompanyJudgementForDebtExplanation)
                            .optional(FIELDS.MM.LTD.LimitedCompanyBuyToLets)
                            .optional(FIELDS.MM.LTD.LimitedCompanyHolidayLets)
                            .optional(FIELDS.MM.LTD.LimitedCompanyOtherProperties)
                    },
                    canNext: () => true,
                    nextQuestionId: () => CAN_SUBMIT
                };
        }
    }
}

export default LimitedCompany;