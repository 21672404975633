import {Adapter} from "./models";
import {
    SECTION,
    model
} from "common-hypotheca";

export function describeSection(sectionId, data, applicantId) {
    if (sectionId === SECTION.SITUATION) {

        let adapter = new Adapter(data);
        let name = adapter.applicantName(applicantId);

        if (name === undefined)
            name = "Someone";

        return {
            applicantName: name,
            sectionName: name.endsWith("s") ? "' situation" : "'s situation"
        };

    } else {
        return model[sectionId].name;
    }
}

export function describePart(sectionId, partId) {
    partId = parseInt(partId) || partId;

    let partParams = model[sectionId].parts.find((element) => {
        return element.id === partId;
    });

    if (partParams) {
        return partParams.name;
    } else {
        return 'Other information';
    }
}
