import * as React from "react";

import {
    FIELDS,
    SECTION,
    SITUATION
} from "common-hypotheca";

import { CAN_SUBMIT } from "../../../../constants";

import MultiBlockFormSingleApplicant from "../../../../form/MultiBlockFormSingleApplicant";
import QuestionBlock from "../../../../form/QuestionBlock";
import FieldLabel from "common-form/components/FieldLabel";
import FormButton from "../../../../form/components/FormButton";
import FormImage, {IMAGE_HOUSES} from "../../../../form/components/FormImage";
import { LABEL_TYPE } from "../../../../form/components/Field";

import CurrentProperty from "./assets/CurrentProperty";
import PropertyList from "./assets/PropertyList";
import AssetList from "./assets/AssetList";
import AskForAssetList from "./assets/AskForAssetList";
import AskMoreProperties from "./assets/AskMoreProperties";
import ErrorPart from "../../../ErrorPart";
import {globalCleanupBankAccountInfo} from "../../../../util/globalCleanup";

const BLOCK_WELCOME = 1;
const BLOCK_CURRENT_PROPERTY = 2;
const BLOCK_ASK_MORE_PROPERTIES = 3;
const BLOCK_PROPERTY_LIST = 5;
//const BLOCK_BANK_ACCOUNT = 8;
const BLOCK_ASK_ASSET_LIST = 9;
const BLOCK_ASSET_LIST = 10;


class Assets extends MultiBlockFormSingleApplicant {
   
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            sectionId: SECTION.SITUATION,
            partId: SITUATION.ASSETS,

            slideIds: [BLOCK_WELCOME],
            activeSlideId: BLOCK_WELCOME
        };
    }

    /**
     * @returns {number} of expected slides for this part.
     */
    slidesExpected = () => {
        return 1 // welcome
            + (this.isHomeOwner() ? 1 : 0)
            + 1 //ask for more properties
            + (this.ownsMoreProperties() ? 1 : 0)
            // + 1  bank acc
            + 1 // additional assets question
            + (this.ownsOtherAssets() ? 1 : 0);
    };

    checkForCompleteState = data => {
        this.partContainer().isComplete = true;
        this.partContainer(null, SECTION.SITUATION, SITUATION.EMPLOYMENT).isAvailable = true;
        return data;
    };

    getField = (fieldName) => {
        return this.getFormField(null, fieldName);
    };

    getValue = (fieldName) => {
        return this.getField(fieldName).value;
    };

    isHomeOwner = () => {
        return this.props.adapter.isHomeOwner(this.props.applicantId);
    };

    ownsMoreProperties = () => {
        return !!this.getValue(FIELDS.MS.AS.MainResAndHHOwnMoreProps);
    };

    ownsOtherAssets = () => {
        return !!this.getValue(FIELDS.MS.AS.HasOtherAssets);
    };

    getOtherPropertiesQuestion = () => {
        return this.isHomeOwner() 
            ? 'Apart from your main residence and the holiday home that you wish to mortgage, do you own any other properties?'
            : 'Apart from the holiday home that you wish to mortgage, do you own any other properties?';
    };

    getCurrentAddress = () => {
        return this.props.adapter.getCurrentAddress(this.props.applicantId);
    };
    
    getQuestion = which => {
        switch(which) {
            case BLOCK_WELCOME:
                return {
                    block: (params)=>
                        <QuestionBlock {...params} controlButtons={null}>
                            <FieldLabel
                                label= {`${this.getApplicantName()}, let's now talk about the things you own`}
                            />
                            <FieldLabel
                                label= {`In this section we will ask you about any properties that you own and any mortgages that are secured against them, plus details of any other significant assets you hold.`}
                                labelType={LABEL_TYPE.Description}
                            />
                            <FormButton
                                text={`Let's go!`}
                                onClick={() => this.swiperNext()}
                            />
                            <FormImage imageType={IMAGE_HOUSES}/>
                        </QuestionBlock>,
                    validate: () => {
                        globalCleanupBankAccountInfo(this.props.adapter, this.props.applicantId);
                    },
                    nextQuestionId: () => {
                        return this.isHomeOwner() ? BLOCK_CURRENT_PROPERTY : BLOCK_ASK_MORE_PROPERTIES;
                    }
                };

            case BLOCK_CURRENT_PROPERTY:
                return CurrentProperty(this, () => BLOCK_ASK_MORE_PROPERTIES);

            case BLOCK_ASK_MORE_PROPERTIES:
                return AskMoreProperties(this, (hasMoreProperties) => hasMoreProperties ? BLOCK_PROPERTY_LIST : BLOCK_ASK_ASSET_LIST);
                
            case BLOCK_PROPERTY_LIST:
                return PropertyList(this, () => BLOCK_ASK_ASSET_LIST);

            /* Bank account info will not needed
            case BLOCK_BANK_ACCOUNT:
                return BankAccount(this, () => BLOCK_ASK_ASSET_LIST);
             */

            case BLOCK_ASK_ASSET_LIST:
                return AskForAssetList(this, (hasAssetList) => hasAssetList ? BLOCK_ASSET_LIST : CAN_SUBMIT);

            case BLOCK_ASSET_LIST:
                return AssetList(this, () => CAN_SUBMIT);

            default:
                return ErrorPart();
        }
    };

}

export default Assets;
