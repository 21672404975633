import * as React from "react";
import FieldInput from "common-form/components/FieldInput";
import {LABEL_TYPE} from "../../form/components/Field";
import FieldDropdown from "../../form/components/dropdown/FieldDropdown";
import FieldDropdownItem from "../../form/components/dropdown/FieldDropdownItem";
import LoanToValue from "../../form/components/LoanToValue";

const borrowOptions = [60, 65, 70, 75, 80].map(o => {
    return {
        value: o, label: `${o}%`
    };
});

borrowOptions.push({ value: 'custom', label: 'Custom' });
borrowOptions.unshift({ value: '', label: '' });

export function LtvBlock({form,
                          propertyValueField,
                          borrowPercentField,
                          borrowAmountField}) {

    if (!form.fieldValue(borrowPercentField) && form.fieldValue(borrowAmountField)) {
        form.props.adapter.updateField('custom', borrowPercentField, form.state.applicationId, form.state.sectionId, form.state.partId);
    }

    // (applicantId, field, value, ...)
    let handleFieldFilled = form.generateFieldFillHandler({
        applicantId: undefined,
        sectionId: form.state.sectionId,
        partId: form.state.partId
    });

    function borrowOptionChanged(i, field, value) {
        const purchasePrice = form.fieldValue(propertyValueField);

        if (purchasePrice && value && value !== 'custom') {
            handleFieldFilled(
                null,
                borrowAmountField,
                Math.round(purchasePrice * parseInt(value) / 100));
        }

        handleFieldFilled(null, borrowPercentField, value);
    }

    function borrowAmountChanged(i, field, value) {
        handleFieldFilled(null, borrowPercentField, 'custom');
        handleFieldFilled(null, borrowAmountField, value);
    }

    function purchasePriceChanged(i, field, value) {
        handleFieldFilled(null, propertyValueField, value);
        borrowOptionChanged(null, null, form.fieldValue(borrowPercentField))
    }

    return <>
        <FieldInput
            labelType={LABEL_TYPE.Small}
            {...form.fieldComponentProperties(propertyValueField)}
            handleFieldFilled={purchasePriceChanged}
        />
        <FieldDropdown
            {...form.fieldComponentProperties(borrowPercentField)}
            labelType={LABEL_TYPE.Small}
            handleFieldFilled={borrowOptionChanged}
            valid={false} // Remove green valid field enhancements around LTV in order not to confuse users that this LTV is OK
        >
            {
                borrowOptions.map((option, i) =>
                    <FieldDropdownItem key={i} code={option.value} label={option.label} />
                )
            }
        </FieldDropdown>
        <FieldInput
            labelType={LABEL_TYPE.Small}
            {...form.fieldComponentProperties(borrowAmountField)}
            handleFieldFilled={borrowAmountChanged}
        />
        <LoanToValue
            label="Loan to value calculated as {0}%"
            loan={form.fieldValue(borrowAmountField)}
            value={form.fieldValue(propertyValueField)}
        />
    </>
}