export const _COMMITMENT_TYPE = {
    CREDIT_CARDS: 'creditCards',
    PERSONAL_LOANS: 'personalLoans',
    CAR_FINANCE: 'carFinance',
    OPERATING_LEASES: 'operatingLeases',
    OTHER_PURCHASES: 'otherPurchasesUsingCredit',
    OVERDRAFT: 'overdraft',
    OTHER_LOANS: 'otherLoans',
};

_COMMITMENT_TYPE.OPTIONS = [
    {
        code: _COMMITMENT_TYPE.CREDIT_CARDS,
        text: 'Credit or store cards'
    },
    {
        code: _COMMITMENT_TYPE.PERSONAL_LOANS,
        text: 'Personal loans'
    },
    {
        code: _COMMITMENT_TYPE.CAR_FINANCE,
        text: 'Car finance'
    },
    {
        code: _COMMITMENT_TYPE.OPERATING_LEASES,
        text: 'Operating leases'
    },
    {
        code: _COMMITMENT_TYPE.OTHER_PURCHASES,
        text: 'Credit purchases'
    },
    {
        code: _COMMITMENT_TYPE.OVERDRAFT,
        text: 'Overdraft'
    },
    {
        code: _COMMITMENT_TYPE.OTHER_LOANS,
        text: 'Other loans'
    }
];
