export const _GARAGE_TYPE = {
    NO: 'no',
    SINGLE: 'single',
    DOUBLE: 'double'
};

_GARAGE_TYPE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: _GARAGE_TYPE.NO,
        text: 'No'
    },
    {
        code: _GARAGE_TYPE.SINGLE,
        text: 'Single garage'
    },
    {
        code: _GARAGE_TYPE.DOUBLE,
        text: 'Double garage'
    }
];