import React from 'react';

import FieldLabel from "common-form/components/FieldLabel";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import {LABEL_TYPE} from "common-form/components/Field";

import MultiBlockForm from '../../../form/MultiBlockForm';
import { FieldDropdown } from "../../../form/components/dropdown";
import FieldFileUpload from '../../../form/components/FieldFileUpload';
import FieldTextArea from '../../../form/components/FieldTextArea';
import FormButton from '../../../form/components/FormButton';
import QuestionBlock from '../../../form/QuestionBlock';
import FormImage, {IMAGE_SORRY} from '../../../form/components/FormImage';

import { CAN_SUBMIT } from '../../../constants';

import {
    EVENTS,
    FIELDS,
    SECTION,
    SCREENING,
    CITIZEN_TEST_OTHER
} from 'common-hypotheca';
import ErrorPart from "../../ErrorPart";

const BLOCK_CREDIT_HISTORY_MARKS = 1;
const BLOCK_CITIZENSHIP = 2;
const BLOCK_CREDIT_HISTORY_INVESTIGATION = 3;
const BLOCK_CITIZENSHIP_INVESTIGATION = 4;
const BLOCK_INVESTIGATION_REVIEW = 5;

class CreditHistoryAndResidence extends MultiBlockForm {
    constructor(props) {
        super(props);

        this.state = {
            sectionId: SECTION.SCREENING,
            partId: SCREENING.CREDIT_HISTORY_AND_RESIDENCE,

            slideIds: [BLOCK_CREDIT_HISTORY_MARKS],
            activeSlideId: BLOCK_CREDIT_HISTORY_MARKS,

            currentApplicantId: null
        };
    }

    /**
     * @returns {number} of expected slides for this part.
     */
    slidesExpected = () => {
        const NORMAL = 3;
        const FAILED_CREDIT_HISTORY = 3;
        const FAILED_CITIZENSHIP = 4;

        if (this.state.slideIds.includes(BLOCK_CREDIT_HISTORY_INVESTIGATION))
            return FAILED_CREDIT_HISTORY;
        else if (this.state.slideIds.includes(BLOCK_CITIZENSHIP_INVESTIGATION))
            return FAILED_CITIZENSHIP;
        else
            return NORMAL;
    };

    checkForCompleteState = data => {
        this.partContainer().isComplete = true;
        this.partContainer(null, SECTION.SCREENING, SCREENING.KEY_CRITERIA).isAvailable = true;
        return data;
    };

    checkSendNotification = () => {
        return this.sendNotification(EVENTS.SCREENING_FAIL)
    };

    applicantPossessive = (applicantId) => {
        if (applicantId === 0) {
            return 'your'
        } else {
            return this.getApplicantName(applicantId) + "'s"
        }
    };

    failsCreditHistory = (applicantId) => {
        return this.fieldValueTrue(FIELDS.AS.CR.CreditHistory, applicantId);
    }

    failsCitizenship = (applicantId) => {
        return this.fieldValueFalse(FIELDS.AS.CR.CitizenTest, applicantId)
            && this.fieldValue(FIELDS.AS.CR.CitizenTestOther, applicantId) === CITIZEN_TEST_OTHER.NONE
    };

    getQuestion = which => {
        switch (which) {
            case BLOCK_CREDIT_HISTORY_MARKS:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldLabel
                                label="Before getting too far into the application, let's ask you some key questions about your citizenship and credit history." />

                            {
                                //col-form-label is having margin negative, temporary fix to give positive gap here   
                            }                           
                            <div className="form-group row"/>

                            <FieldLabel
                                labelType={LABEL_TYPE.Small}
                                label="Now, or at any time during the last 6 years, have you or any of the applicants:" />

                            <div className="row">
                                <div className="col m-2">
                                    <ul>
                                        <li>been bankrupt or insolvent</li>
                                        <li>failed to keep up payments on any mortgage, loan, rent, credit card</li>
                                        <li>had a property repossessed as a result of either an Individual Voluntary
                                            Arrangement or court action
                                        </li>
                                        <li>had a County Court Judgement made against you for not making payments</li>
                                        <li>had to make special arrangements (such as IVA, Debt Relief) with creditors
                                            to repay debt
                                        </li>
                                        <li>ever been refused a mortgage, loan or a tenancy</li>
                                        <li>been charged, convicted or have prosecution(s) pending for any offense other than driving offenses</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='row'>
                                {
                                    this.props.data.applicants &&
                                    this.props.data.applicants.map((applicant, i) =>
                                        <div key={i} className="col padding-less">
                                            <FieldRadioYesNo
                                                label={
                                                    this.getApplicantCount() > 1
                                                        ? (i ? this.getApplicantName(applicant.applicantId) || `Applicant ${i}` : 'Myself')
                                                        : ''
                                                }
                                                {...this.fieldComponentProperties(FIELDS.AS.CR.CreditHistory, {applicantId: applicant.applicantId})}>
                                            </FieldRadioYesNo>
                                        </div>
                                    )
                                }

                            </div>
                        </QuestionBlock>,
                    validate: (context) => {
                        for (let applicantId of this.props.adapter.applicantIds()) {
                            context.mandatory(FIELDS.AS.CR.CreditHistory, { applicantId })

                            if (!this.failsCreditHistory(applicantId)) {
                                context.cleanupIrrelevantField(FIELDS.AS.CR.CreditHistoryExpln, { applicantId });
                                context.cleanupIrrelevantField(FIELDS.AS.CR.CreditHistoryDocs, { applicantId });
                            }
                        }
                    },
                    nextQuestionId: () => {
                        for (let applicantId of this.props.adapter.applicantIds()) {
                            if (this.failsCreditHistory(applicantId, FIELDS.AS.CR.CreditHistory)) {
                                return BLOCK_CREDIT_HISTORY_INVESTIGATION;
                            }
                        }

                        return BLOCK_CITIZENSHIP;
                    }
                };

            case BLOCK_CITIZENSHIP:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldLabel label='Thank you. Can you tell me what kind of residency you have in the UK?' />
                            {
                                this.props.data.applicants &&
                                this.props.data.applicants.map((applicant, i) =>
                                    <div key={i} className='form-group'>
                                        <div className="row align-items-center">
                                            {
                                                this.getApplicantCount() > 1 &&
                                                <div className='col-lg-4 col-sm-12 pl-sm-5'>
                                                    <FieldLabel
                                                        label={i ? this.getApplicantName(applicant.applicantId) || `Applicant ${i}` : 'Myself'} />
                                                </div>
                                            }
                                            <div className="col-lg-4 col-sm-12">
                                                <FieldRadioYesNo
                                                    labelType={LABEL_TYPE.Small}
                                                    {...this.fieldComponentProperties(FIELDS.AS.CR.CitizenTest, {applicantId: applicant.applicantId})}>
                                                </FieldRadioYesNo>
                                            </div>
                                            <div className='col-lg-4 col-sm-12'>
                                                <FieldDropdown
                                                    show={this.fieldValueFalse(FIELDS.AS.CR.CitizenTest, applicant.applicantId)}
                                                    {...this.fieldComponentProperties(FIELDS.AS.CR.CitizenTestOther, {applicantId: applicant.applicantId})}
                                                />
                                            </div>
                                        </div>
                                        <hr/>
                                    </div>
                                )
                            }

                        </QuestionBlock>,
                    validate: (context) => {
                        for (let applicantId of this.props.adapter.applicantIds()) {
                            context.mandatory(FIELDS.AS.CR.CitizenTest, { applicantId });
                            context.mandatoryOrCleanup(
                                this.fieldValueFalse(FIELDS.AS.CR.CitizenTest, applicantId),
                                FIELDS.AS.CR.CitizenTestOther,
                                { applicantId }
                            );

                            if (!this.failsCitizenship(applicantId)) {
                                context.cleanupIrrelevantField(FIELDS.AS.CR.CitizenExpln, { applicantId });
                                context.cleanupIrrelevantField(FIELDS.AS.CR.CitizenDocs, { applicantId });
                            }
                        }
                    },
                    nextQuestionId: () => {
                        for (let applicantId of this.props.adapter.applicantIds()) {
                            if (this.failsCitizenship(applicantId)) {
                                return BLOCK_CITIZENSHIP_INVESTIGATION;
                            }
                        }

                        return CAN_SUBMIT;
                    }
                };

            case BLOCK_CREDIT_HISTORY_INVESTIGATION:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldLabel
                                label={`We need to know a bit more about your credit  ${this.getApplicantCount() === 1 ? 'history' : `histories`} in order to understand if we can help!`} />
                            {
                                this.props.data.applicants &&
                                this.props.data.applicants
                                    .filter(applicant => this.fieldValueTrue(FIELDS.AS.CR.CreditHistory, applicant.applicantId))
                                    .map((applicant, i) =>
                                        <div key={i} className='row'>
                                            <div className='col'>

                                                <FieldLabel
                                                    labelType={LABEL_TYPE.Description}
                                                    label={`Can you tell us more about ${this.applicantPossessive(applicant.applicantId)} circumstances and upload ${this.applicantPossessive(applicant.applicantId)} credit report?`} />
                                                <FieldTextArea
                                                    labelType={LABEL_TYPE.Small}
                                                    label='For each type of default, please tell us more detail, including: the type of default, date first registered, amount and date satisfied'
                                                    {...this.fieldComponentProperties(FIELDS.AS.CR.CreditHistoryExpln, {applicantId: applicant.applicantId})}
                                                />
                                                <FieldFileUpload
                                                    label={`Please upload your report here (e.g. Noddle, Equifax or Experian)`}
                                                    {...this.fieldComponentProperties(FIELDS.AS.CR.CreditHistoryDocs, {applicantId: applicant.applicantId})}
                                                />
                                            </div>
                                        </div>
                                    )
                            }


                        </QuestionBlock>,
                    validate: (context) => {
                        for (let applicantId of this.props.adapter.applicantIds()) {
                            context.mandatoryOrCleanup(
                                this.fieldValueTrue(FIELDS.AS.CR.CreditHistory, applicantId),
                                FIELDS.AS.CR.CreditHistoryExpln,
                                { applicantId })
                        }
                    },
                    nextQuestionId: () => BLOCK_INVESTIGATION_REVIEW,
                };

            case BLOCK_CITIZENSHIP_INVESTIGATION:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <div className='row'>
                                <div className='col'>
                                    <FieldLabel
                                        label={`We need to know a bit more about your  ${this.getApplicantCount() === 1 ? 'citizenship' : `citizenships`} before we can help you!`} />
                                </div>
                            </div>
                            {
                                this.props.data.applicants &&
                                this.props.data.applicants
                                    .filter(applicant => this.fieldValueFalse(FIELDS.AS.CR.CitizenTest, applicant.applicantId)
                                        && this.fieldValue(FIELDS.AS.CR.CitizenTestOther, applicant.applicantId))
                                    .map((applicant, i) =>
                                        <div key={i} className='row'>
                                            <div className='col'>
                                                <FieldLabel
                                                    labelType={LABEL_TYPE.Description}
                                                    label={`Can you tell us more about ${this.applicantPossessive(applicant.applicantId)} circumstances and upload any relevant documents?`} />
                                                <FieldTextArea
                                                    labelType={LABEL_TYPE.Small}
                                                    label='Please give us more details here, including dates, events etc'
                                                    {...this.fieldComponentProperties(FIELDS.AS.CR.CitizenExpln, {applicantId: applicant.applicantId})}
                                                />
                                                <FieldFileUpload
                                                    label={`Please upload supporting documents here`}
                                                    {...this.fieldComponentProperties(FIELDS.AS.CR.CitizenDocs, {applicantId: applicant.applicantId})}
                                                />
                                            </div>
                                        </div>
                                    )
                            }

                        </QuestionBlock>,
                    validate: (context) => {
                        for (let applicantId of this.props.adapter.applicantIds()) {
                            context.mandatoryOrCleanup(
                                this.failsCitizenship(applicantId),
                                FIELDS.AS.CR.CitizenExpln,
                                { applicantId }
                            );
                        }
                    },
                    nextQuestionId: () => BLOCK_INVESTIGATION_REVIEW,
                };

            case BLOCK_INVESTIGATION_REVIEW:
                return {
                    block: (params) =>
                        <QuestionBlock {...params} controlButtons={null}>
                            <FieldLabel
                                label={`Thank you ${this.getApplicantName()} for doing that. Our team will review ${this.props.data.applicants.length > 1 ? 'the' : 'your'} details and get in touch with you once they have been able to determine if we can help!`} />
                            <FieldLabel
                                labelType={LABEL_TYPE.Description}
                                label='Or, if you would like to discuss it further, please contact us via the help menu or live chat widget on your screen.' />
                            <FormButton
                                text='Back'
                                leftMaterialIcon='chevron_left'
                                onClick={this.swiperPrevious}
                            />
                            <FormImage imageType={IMAGE_SORRY}/>
                        </QuestionBlock>,
                    canNext: () => false,
                    nextQuestionId: () => null,
                    onLoad: () => this.checkSendNotification()
                };

            default:
                return ErrorPart();

        }
    }
}

export default CreditHistoryAndResidence;
