import React, { Component } from 'react';
import PropTypes from "prop-types";
//import './ProgressBar.css'

class ProgressBar extends Component {
    static propTypes = {
        progress: PropTypes.number,
        
    };
    render() {
        let progressPercentage = Math.round(this.props.progress * 100);

        return (
            
                <div className="progress bg-success" >
                    <div className="progress-bar bg-primary"
                         role="progressbar"
                         style={{width: progressPercentage + '%'}}
                         aria-valuenow={progressPercentage}
                         aria-valuemin="0"
                         aria-valuemax="100"
                    />
                </div>
               
            
        );
    }
}


export default ProgressBar;
