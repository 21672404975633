import * as React from "react";

import FieldLabel from "common-form/components/FieldLabel";

import { CAN_SUBMIT } from "../../../constants";
import QuestionBlock from "../../../form/QuestionBlock";
import MultiBlockForm from "../../../form/MultiBlockForm";
import { LABEL_TYPE } from "../../../form/components/Field";
import FormButton from "../../../form/components/FormButton";
import FormImage, {IMAGE_KEYS} from "../../../form/components/FormImage";

import {
    FIELDS,
    SECTION,
    PROPERTY
} from "common-hypotheca";

import Address from "./propertyDetails/Address";
import Rooms from "./propertyDetails/Rooms";
import BuildDetails from "./propertyDetails/BuildDetails";
import Improvements from "./propertyDetails/Improvements";
import ExistingAsset from "./propertyDetails/ExistingAsset";
import ErrorPart from "../../ErrorPart";

const BLOCK_WELCOME = 1;
const BLOCK_ADDRESS = 2;
const BLOCK_ROOMS = 3;
const BLOCK_BUILD_DETAILS = 4;
const BLOCK_IMPROVEMENTS = 7;
const BLOCK_EXISTING_ASSET = 8;


class PropertyDetails extends MultiBlockForm {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            sectionId: SECTION.PROPERTY,
            partId: PROPERTY.DETAILS,

            slideIds: [BLOCK_WELCOME],
            activeSlideId: BLOCK_WELCOME,

            currentApplicantId: null
        };
    }

    /**
     * @returns {number} of expected slides for this part.
     */
    slidesExpected = () => {
        return 3;
    };

    checkForCompleteState = data => {
        this.partContainer().isComplete = true;
        this.partContainer(null, SECTION.PROPERTY, PROPERTY.DETAILS).isAvailable = true;
        return data;
    };
   
    updateValue = (field, value) => {
        this.props.adapter.updateField(value, field, null, this.state.sectionId, this.state.partId);
    };

    /**
     * @deprecated use fieldComponentProperties instead
     * @param fieldName
     */
    getField = (fieldName) => {
        return this.getFormField(null, fieldName);
    };

    getValue = (fieldName) => {
        return this.getField(fieldName).value;
    };
   
    isMortgageToBuyNewPlace = () => {
        return this.props.adapter.isMortgageToBuyNewPlace();
    };

    isMortgageExisting = () => {
        return this.props.adapter.isMortgageExistingLet();
    };

    isPropertyNewBuild() {
        return this.getValue(FIELDS.MP.PD.PurchaseNewBuild) === true;
    };

    updateMarketValue() {
        this.props.adapter.syncHolidayLetMarketValue();
    }

    getQuestion = which => {
        switch(which) {
            case BLOCK_WELCOME:
                return {
                    block: (params)=>
                        <QuestionBlock {...params} controlButtons={null}>

                            <FieldLabel
                                label={`In this section, ${this.getApplicantName()}, we will focus on the holiday let to be mortgaged`}
                            />

                            <FieldLabel
                                label="Lenders need to understand a bit more about the property, as it will form the main part of their security."
                                labelType={LABEL_TYPE.Description}
                            />

                            <FormButton
                                text="Get started"
                                onClick={() => this.swiperNext()}
                            />
                            <FormImage imageType={IMAGE_KEYS}/>
                        </QuestionBlock>,
                    canNext: () => true,
                    nextQuestionId: () => BLOCK_ADDRESS
                };


            case BLOCK_ADDRESS:
                return Address(this, BLOCK_ROOMS);

            case BLOCK_ROOMS:
                return Rooms(this, () => this.isMortgageToBuyNewPlace() ? BLOCK_BUILD_DETAILS : BLOCK_EXISTING_ASSET);

            case BLOCK_BUILD_DETAILS:
                return BuildDetails(this, () => this.isPropertyNewBuild() ? BLOCK_IMPROVEMENTS : BLOCK_EXISTING_ASSET);

            case BLOCK_EXISTING_ASSET:
                return ExistingAsset(this, () => this.isMortgageToBuyNewPlace() ? BLOCK_IMPROVEMENTS : CAN_SUBMIT);

            case BLOCK_IMPROVEMENTS:
                return Improvements(this, CAN_SUBMIT);

            default:
                return ErrorPart();
        }
    };
}

export default PropertyDetails;
