export const _DEPOSIT_SOURCE_TYPE = {
    SAVINGS: 'savings',
    GIFT: 'gift',
    LOAN: 'loan',
    SALE_OF_PROPERTY: 'sale_of_property',
    REMORTGAGE: 'remortgage',
    VENDOR_CONTRIBUTION: 'vendor_contribution',
    OTHER: 'other'
};

_DEPOSIT_SOURCE_TYPE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: _DEPOSIT_SOURCE_TYPE.SAVINGS,
        text: 'Savings'
    },
    {
        code: _DEPOSIT_SOURCE_TYPE.GIFT,
        text: 'Gift'
    },
    {
        code: _DEPOSIT_SOURCE_TYPE.LOAN,
        text: 'Loan'
    },
    {
        code: _DEPOSIT_SOURCE_TYPE.SALE_OF_PROPERTY,
        text: 'Sale of property'
    },
    {
        code: _DEPOSIT_SOURCE_TYPE.REMORTGAGE,
        text: 'Remortgage'
    },
    {
        code: _DEPOSIT_SOURCE_TYPE.VENDOR_CONTRIBUTION,
        text: 'Vendor or builder contribution'
    },
    {
        code: _DEPOSIT_SOURCE_TYPE.OTHER,
        text: 'Other (add)'
    }
];