import React, { Fragment, Component } from 'react';


class Step extends Component {

    static defaultProps = {
        lineAfter: false,
        lineBefore: false,
        active: false,
        completed: false,
        available:false,
    }

    constructor(props) {
        super(props)
        this.state = {

        }
      //  console.log('step constructor', props, this.state)
    }

    render() {

        let activeClass = this.props.active ? ' active '  : ' inactive ' // if you are active,. then always shw active. If not active, you are either completed or inactive.
        activeClass += this.props.completed ? ' completed ' : ''
        activeClass += this.props.available ? ' available ': ''
        return (
            <Fragment>
                <div className={activeClass}>

                    <div className='journey-step'>
                        {
                            !!this.props.lineBefore &&
                            <div className='line before'>
                            </div>
                        }
                        <div className='content' onClick={this.props.onClick}>
                            <div className='dot'>
                                <div className='outer'></div>
                            </div>
                            <div className='text'>
                                {this.props.text}
                            </div>

                        </div>
                        {
                            !!this.props.lineAfter &&
                            <div className='line after'>
                            </div>
                        }
                    </div>
                </div>
            </Fragment>

        );
    }
}

export default Step;
