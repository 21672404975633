import React from 'react';

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldInput from "common-form/components/FieldInput";
import {FieldDropdown} from "../../../../../form/components/dropdown";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";

import {
    FIELDS,
    TITLE_TYPE
} from "common-hypotheca";

export default function Names(parent, nextBlockId) {

    function hasTitleOther() {
        return parent.getValue(FIELDS.MS.PD.Title) === TITLE_TYPE.OTHER;
    }

    return {
        block: (params)=>
            <QuestionBlock {...params}>

                <FieldDropdown 
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.Title)}
                >
                </FieldDropdown>

                <FieldInput
                    show={() => hasTitleOther() }
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.TitleOther)}
                />

                <FieldInput 
                    {...parent.getFormField(null, FIELDS.MS.PD.PassportNameFirst)} 
                />

                <FieldInput 
                    {...parent.getFormField(null, FIELDS.MS.PD.PassportNameMiddle)} 
                />

                <FieldInput 
                    {...parent.getFormField(null, FIELDS.MS.PD.PassportNameSurname)} 
                />

                <FieldRadioYesNo
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.HasSurnameChanged)}
                />
                
                <FieldInput
                    show={parent.fieldHasValueTrue(null, FIELDS.MS.PD.HasSurnameChanged)}
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.FormerSurname)}
                />

                {
                    //show only to additional applicants
                    parent.state.currentApplicantId > 0 &&
                    <>
                        <FieldInput
                            type="phone"
                            {...parent.getFormField(null, FIELDS.MS.PD.AdditionalPhoneNumber)}
                        />

                        <FieldInput
                            type="email"
                            {...parent.getFormField(null, FIELDS.MS.PD.AdditionalEmailAddress)}
                        />
                    </>
                }

            </QuestionBlock>,

        canNext: () => true,

        onLoad: () => {

            // auto populate name
            const filledName = parent.getFieldValue(null, FIELDS.MS.PD.PassportNameFirst);
            if (!filledName) {
                parent.handleFilledField(null, FIELDS.MS.PD.PassportNameFirst, parent.getApplicantName());
            }

        },
        
        validate: (context) => {

            context
                .mandatory(FIELDS.MS.PD.Title)
                .mandatoryOrCleanup(hasTitleOther(), FIELDS.MS.PD.TitleOther)
                .mandatory(FIELDS.MS.PD.PassportNameFirst)
                .optional(FIELDS.MS.PD.PassportNameMiddle)
                .mandatory(FIELDS.MS.PD.PassportNameSurname)
                .mandatory(FIELDS.MS.PD.HasSurnameChanged).mandatoryOrCleanup(
                    parent.fieldHasValueTrue(null, FIELDS.MS.PD.HasSurnameChanged),
                    FIELDS.MS.PD.FormerSurname);

            
            if (parent.state.currentApplicantId > 0) {
                context
                .mandatory(FIELDS.MS.PD.AdditionalPhoneNumber)
                .mandatory(FIELDS.MS.PD.AdditionalEmailAddress)
                ;
            }

        },
        nextQuestionId: () => nextBlockId
    };
};
