import {
    FIELDS,
    PROPERTY,
    MY_MORTGAGE,
    SECTION,
    SITUATION,
    OCCUPANT_TYPE,
    PREFERRED_MORTGAGE_TYPE
} from "common-hypotheca";

/**
 * Performs global clean-uo functionality when other parts of application affect
 * @param adapter application adapter
 */
export function globalCleanupMortgageType(adapter) {
    if (adapter.isMortgageExistingLet()) {

        // We need to removeField for all irrelevant field yet we need to expectFieldPresence of at least one per
        // part

        adapter
            .forPart(undefined, SECTION.PROPERTY, PROPERTY.DETAILS)
                // PropertyDetails - Address has certain fields for mortgaging existing let
                .expectFieldPresence(FIELDS.MP.PD.HolidayLetAreLegalOwners)

                // PropertyDetails - Address has certain fields irrelevant for mortgaging existing let
                .removeField(FIELDS.MP.PD.HolidayLetPurchasersApplicants)
                .removeField(FIELDS.MP.PD.HolidayLetPurchasersApplicantsReason)

                // PropertyDetails - BuildDetails becomes irrelevant for mortgaging existing let
                .removeField(FIELDS.MP.PD.PurchaseNewBuild)
                .removeField(FIELDS.MP.PD.PurchaseNewBuildDateofComp)
                .removeField(FIELDS.MP.PD.PurchaseNewBuildLoanInstallments)
                .removeField(FIELDS.MP.PD.PurchaseNewBuildWarranty)
                .removeField(FIELDS.MP.PD.PurchaseNewBuildWarrantyDescr)
                .removeField(FIELDS.MP.PD.PurchaseNewBuildArchitectDetails)
                ;

        // PropertyDetails - Improvements becomes irrelevant for mortgaging existing let
        adapter
            .forPart(undefined, SECTION.PROPERTY, PROPERTY.DETAILS)
                .removeField(FIELDS.MP.PD.PurchaseNewBuildDoImprovements)
                .removeField(FIELDS.MP.PD.PurchaseNewBuildDoImprovementsDescr)
                .removeField(FIELDS.MP.PD.PurchaseNewBuildDoImprovementsCost)
                ;

        // Mortgage Details - BuyNewPlace becomes irrelevant for mortgaging existing let
        adapter
            .forPart(undefined, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_DETAILS)
                .removeField(FIELDS.MM.MD.PurchaseDeposits)
                .removeField(FIELDS.MM.MD.PurchasePrice)
                .removeField(FIELDS.MM.MD.TotalDeposit)
                .removeField(FIELDS.MM.MD.PurchaseBorrowingLoanToValue)
                .removeField(FIELDS.MM.MD.PurchaseBorrowing)
                .removeField(FIELDS.MM.MD.BorrowPercent)
            .forAnotherPart(undefined, SECTION.MY_MORTGAGE, MY_MORTGAGE.LIMITED_COMPANY)
                .removeField(FIELDS.MM.LTD.LimitedCompanyDepositHow);

        // Mortgage Det
    }

    if (adapter.isMortgageToBuyNewPlace()) {
        adapter
            .forPart(undefined, SECTION.PROPERTY, PROPERTY.DETAILS)
                // PropertyDetails - Address has certain fields for mortgage to buy new place
                .expectFieldPresence(FIELDS.MP.PD.HolidayLetPurchasersApplicants)

                // PropertyDetails - Address has certain fields irrelevant for mortgage to buy new place
                .removeField(FIELDS.MP.PD.HolidayLetAreLegalOwners)
                .removeField(FIELDS.MP.PD.HolidayLetNotLegalOwnersReason)
                .removeField(FIELDS.MP.PD.HolidayLetPurchaseDate)
                .removeField(FIELDS.MP.PD.HolidayLetOriginalPurchasePrice)
                .removeField(FIELDS.MP.PD.HolidayLetMarketValue)

                // PropertyDetails - BuildDetails becomes needed for mortgage to buy new place
                .expectFieldPresence(FIELDS.MP.PD.PurchaseNewBuild)

            .forAnotherPart(undefined, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_DETAILS)
                // Mortgage Details - BuyNewPlace becomes needed for mortgage to buy new place
                .expectFieldPresence(FIELDS.MM.MD.PurchasePrice)
                .removeField(FIELDS.MM.MD.HolidayLetMarketValueLink)
                ;

        globalCleanupRemortgageFields(adapter);
        globalCleanupBrandNewMortgage(adapter);

        // MortgageDetails - Remortgage and new mortgage on existing let common fields
        adapter
            .forPart(undefined, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_DETAILS)
                .removeField(FIELDS.MM.MD.BorrowAmount)
                .removeField(FIELDS.MM.MD.BorrowAmountLoanToValue)
                .removeField(FIELDS.MM.MD.BorrowPercent)
                ;
    }
}

/**
 * Cleans up application after mortgage purpose on existing let (brand new mortgage or remortgage could have changed)
 * @param adapter
 */
export function globalCleanupMortgagePurpose(adapter) {
    if (adapter.isBrandNewMortgage()) {
        globalCleanupRemortgageFields(adapter);

        // Mortgage Details - NewMortgageExistingAsset becomes needed for new mortgage on existing let
        adapter
            .expectFieldPresence(FIELDS.MM.MD.FundsPlan, undefined, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_DETAILS);
    }

    if (adapter.isReplaceExistingMortgage()) {
        globalCleanupBrandNewMortgage(adapter);

        // Mortgage Details - Remortgage becomes needed for remortgage
        adapter
            .expectFieldPresence(FIELDS.MM.MD.MonthlyMortgagePayment, undefined, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_DETAILS);
    }
}

/**
 * Cleans up all fields of replacing existing mortgage.
 * @param adapter application adapter to access read/modification of application.
 */
function globalCleanupRemortgageFields(adapter) {
    adapter
        .forPart(undefined, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_DETAILS)
            // Mortgage Details - Remortgage becomes irrelevant
            .removeField(FIELDS.MM.MD.MonthlyMortgagePayment)
            .removeField(FIELDS.MM.MD.BorrowingLevel)
            .removeField(FIELDS.MM.MD.BorrowingLevelLoanToValue)
            .removeField(FIELDS.MM.MD.FundingDifferencePlan)
            .removeField(FIELDS.MM.MD.CapitalDeficiency)
            .removeField(FIELDS.MM.MD.CapitalRaise)
            .removeField(FIELDS.MM.MD.AdditionalBorrowingPlan)
            .removeField(FIELDS.MM.MD.AdditionalBorrowingPlanOther)
            .removeField(FIELDS.MM.MD.BorrowPercent)

            // Mortgage Details - RemortgageDetails becomes irrelevant
            .removeField(FIELDS.MM.MD.ExistingRemortMortProviderName)
            .removeField(FIELDS.MM.MD.ExistingRemortMortProviderAccNo)
            .removeField(FIELDS.MM.MD.ExistingRemortMortStartDate)
            .removeField(FIELDS.MM.MD.ExistingRemortMortTermYears)
            .removeField(FIELDS.MM.MD.ExistingRemortOriginalBorrowing)
            .removeField(FIELDS.MM.MD.ExistingRemortMortType)
            .removeField(FIELDS.MM.MD.ExistingRemortMortIntRate)
            .removeField(FIELDS.MM.MD.ExistingRemortMortIntRateType)
            .removeField(FIELDS.MM.MD.ExistingRemortMortFixedPeriodLeftMonths)
            .removeField(FIELDS.MM.MD.ExistingRemortMortHaveERCs)
            .removeField(FIELDS.MM.MD.ExistingRemortMortHaveERCsCost)
            .removeField(FIELDS.MM.MD.ExistingRemortMortHaveERCsCostWillPay)
            .removeField(FIELDS.MM.MD.ExistingRemortMortHaveERCsCostWillPayDescr)
            .removeField(FIELDS.MM.MD.ExistingRemortMortPortable)
            .removeField(FIELDS.MM.MD.ExistingRemortApproachedLenderForFunds)
            .removeField(FIELDS.MM.MD.ExistingRemortApproachOutcome)
            .removeField(FIELDS.MM.MD.ExistingRemortIncludeLenderInSearch)
            .removeField(FIELDS.MM.MD.ExistingRemortSecondCharge)
            .removeField(FIELDS.MM.MD.ExistingRemortSecondProviderName)
            .removeField(FIELDS.MM.MD.ExistingRemortSecondBalance);
}

/**
 * Cleans up all fields of new mortgage on existing let.
 * @param adapter application adapter to access read/modification of application.
 */
function globalCleanupBrandNewMortgage(adapter) {
    // Mortgage Details - NewMortgageExistingAsset becomes irrelevant
    adapter
        .forPart(undefined, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_DETAILS)
            .removeField(FIELDS.MM.MD.MortgageReason)
            .removeField(FIELDS.MM.MD.FundsPlan)
            .removeField(FIELDS.MM.MD.FundsPlanOther)
}


export function globalCleanupCurrentAddressOwner(previousHomeOwnerType, adapter, applicantId) {

    const newHomeOwnerType = adapter.fieldValue(FIELDS.MS.PD.CurrentAddressOccuptType, applicantId, SECTION.SITUATION, SITUATION.PERSONAL_DETAILS);

    if (newHomeOwnerType !== previousHomeOwnerType) {

        if (previousHomeOwnerType === OCCUPANT_TYPE.HOME_OWNER) {

            adapter
            .forPart(applicantId, SECTION.SITUATION, SITUATION.ASSETS)
            .removeField(FIELDS.MS.AS.CurrentAddrDatePurchased)
            .removeField(FIELDS.MS.AS.CurrentAddrPurchasePrice)
            .removeField(FIELDS.MS.AS.CurrentAddrPctOwned)
            .removeField(FIELDS.MS.AS.CurrentAddrOwnedBy)
            .removeField(FIELDS.MS.AS.CurrentAddrMktValue)
            .removeField(FIELDS.MS.AS.CurrentAddrPropertyType)
            .removeField(FIELDS.MS.AS.CurrentAddrHaveMortgage)
            .removeField(FIELDS.MS.AS.CurrentAddrMortProviderName)
            .removeField(FIELDS.MS.AS.CurrentAddrMortProviderAccNo)
            .removeField(FIELDS.MS.AS.CurrentAddrMortStartDate)
            .removeField(FIELDS.MS.AS.CurrentAddrMortTermYears)
            .removeField(FIELDS.MS.AS.CurrentAddrMortBorrowingAmount)
            .removeField(FIELDS.MS.AS.CurrentAddrMortType)
            .removeField(FIELDS.MS.AS.CurrentAddrMortCurrentBalOS)
            .removeField(FIELDS.MS.AS.CurrentAddrMortCurrentMonthPay)
            .removeField(FIELDS.MS.AS.CurrentAddrMortSellToPayOff)
            .removeField(FIELDS.MS.AS.CurrentAddrMortSelltoPayOffSalePrice)
            .removeField(FIELDS.MS.AS.CurrentAddrMortWillBeReleased)
            .removeField(FIELDS.MS.AS.CurrentAddrMortWillBeReleasedDescr)
            .removeField(FIELDS.MS.AS.CurrentAddrMortSecondCharge)
            .removeField(FIELDS.MS.AS.CurrentAddrMortSecondChargeName)
            .removeField(FIELDS.MS.AS.CurrentAddrMortSecondChargeBalance)            
            .setToIncomplete();

        } else if (newHomeOwnerType === OCCUPANT_TYPE.HOME_OWNER) {
            adapter
            .forPart(applicantId, SECTION.SITUATION, SITUATION.ASSETS)
            .setToIncomplete();
        }

    }
}

export function globalCleanExtraAddress(adapter, applicantId) {

    const currentAddressYears = adapter.fieldValue(FIELDS.MS.PD.CurrentAddressAgeYears, applicantId, SECTION.SITUATION, SITUATION.PERSONAL_DETAILS);
    if (currentAddressYears >= 3) {
        adapter
        .forPart(applicantId, SECTION.SITUATION, SITUATION.PERSONAL_DETAILS)
        .removeField(FIELDS.MS.PD.PreviousAddresses);
    }

}

/**
 * Performs global clean-uo after user chooses preferred mortgage type
 * @param adapter application adapter
 */
export function globalCleanupAfterPreferredMortgageType(adapter) {

    const part = adapter.forPart(undefined, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_PREFERENCES);

    if(adapter.fieldValue(FIELDS.MM.MP.PreferredMortgageType, undefined, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_PREFERENCES) === PREFERRED_MORTGAGE_TYPE.INTEREST_ONLY) {

        part.setToIncomplete();

    } else {

        //clean up interest only
        part
        .removeFields([
            FIELDS.MM.MP.AcceptsInterestOnly,
            FIELDS.MM.MP.PrimaryProposedRepaymentStrategy,
            FIELDS.MM.MP.PrimaryRepaymentPlanDescription,
        ])
        ;
    }
}

/**
 * Cleans up limited company fields (if it turns out the application is not limited company application anymore) or
 * limited company not incorporated yet.
 * @param adapter
 */
export function globalCleanupLimitedCompany(adapter) {

    if (adapter.isLimitedCompany()) {
        adapter
            .forPart(undefined, SECTION.PROPERTY, PROPERTY.DETAILS)
            .removeFields([
                FIELDS.MP.PD.HolidayLetPurchasersApplicants,
                FIELDS.MP.PD.HolidayLetPurchasersApplicantsReason,
                FIELDS.MP.PD.HolidayLetAreLegalOwners,
                FIELDS.MP.PD.HolidayLetNotLegalOwnersReason
            ]);
    }

    if (!adapter.isLimitedCompany()) {
        adapter
            .forPart(undefined, SECTION.MY_MORTGAGE, MY_MORTGAGE.LIMITED_COMPANY)
            .removeField(FIELDS.MM.LTD.LimitedCompanyAlreadyIncorporated)
            .forAnotherPart(undefined, SECTION.PROPERTY, PROPERTY.DETAILS);

        if (adapter.isMortgageToBuyNewPlace()) {
            adapter
                .forPart(undefined, SECTION.PROPERTY, PROPERTY.DETAILS)
                .expectFieldPresence(FIELDS.MP.PD.HolidayLetPurchasersApplicants);
        }

        if (adapter.isMortgageExistingLet()) {
            adapter
                .forPart(undefined, SECTION.PROPERTY, PROPERTY.DETAILS)
                .expectFieldPresence(FIELDS.MP.PD.HolidayLetAreLegalOwners);
        }
    }

    if (!adapter.isLimitedCompany() || !adapter.isLimitedCompanyIncorporated()) {
        adapter
            .forPart(undefined, SECTION.MY_MORTGAGE, MY_MORTGAGE.LIMITED_COMPANY)
            .setToIncomplete()
            .removeFields([
                // Page 2 - Company info
                FIELDS.MM.LTD.LimitedCompanyRegisteredName,
                FIELDS.MM.LTD.LimitedCompanyRegistrationNumber,
                FIELDS.MM.LTD.LimitedCompanyAddressLine1,
                FIELDS.MM.LTD.LimitedCompanyAddressLine2,
                FIELDS.MM.LTD.LimitedCompanyTown,
                FIELDS.MM.LTD.LimitedCompanyCounty,
                FIELDS.MM.LTD.LimitedCompanyPostcode,
                FIELDS.MM.LTD.LimitedCompanySic,
                FIELDS.MM.LTD.LimitedCompanyHasBankAccount,
                FIELDS.MM.LTD.LimitedCompanyBankAccountProvider,
                FIELDS.MM.LTD.LimitedCompanyBankAccountNumber,
                // Page 3 - Ownership
                FIELDS.MM.LTD.LimitedCompanyDirectorsAreApplicants,
                FIELDS.MM.LTD.LimitedCompanyDirectors,
                FIELDS.MM.LTD.LimitedCompanyShareholders,
                FIELDS.MM.LTD.LimitedCompanyDepositHow,
                FIELDS.MM.LTD.LimitedCompanyDirectorsPersonalGuarantees,
                // Page 4 - History
                FIELDS.MM.LTD.LimitedCompanyPreviouslyOwnedByCompany,
                FIELDS.MM.LTD.LimitedCompanyPreviouslyOwnedByCompanyExplanation,
                FIELDS.MM.LTD.LimitedCompanyTradingCompany,
                FIELDS.MM.LTD.LimitedCompanyTradingCompanyExplanation,
                FIELDS.MM.LTD.LimitedCompanyRefusedMortgage,
                FIELDS.MM.LTD.LimitedCompanyRefusedMortgageExplanation,
                FIELDS.MM.LTD.LimitedCompanyFailedPayments,
                FIELDS.MM.LTD.LimitedCompanyFailedPaymentsExplanation,
                FIELDS.MM.LTD.LimitedCompanyJudgementForDebt,
                FIELDS.MM.LTD.LimitedCompanyJudgementForDebtExplanation,
                FIELDS.MM.LTD.LimitedCompanyBuyToLets,
                FIELDS.MM.LTD.LimitedCompanyHolidayLets,
                FIELDS.MM.LTD.LimitedCompanyOtherProperties,
            ]);
        }
}

export function globalCleanupBankAccountInfo(adapter, applicantId) {
    adapter
        .forPart(applicantId, SECTION.SITUATION, SITUATION.ASSETS)
        .removeFields([
            FIELDS.MS.AS.HaveBnkOrBSAcct,
            FIELDS.MS.AS.HaveBnkOrBSAcctName,
            FIELDS.MS.AS.HaveBnkOrBSAcctYrsHeld,
            FIELDS.MS.AS.HaveBnkOrBSAcctAddr,
            FIELDS.MS.AS.HaveBnkSortCode,
            FIELDS.MS.AS.HaveBnkAccountNumber,
            FIELDS.MS.AS.HaveBnkOthers,
            FIELDS.MS.AS.HaveBnkOthersDesc
        ])
}