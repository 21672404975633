import * as React from "react";

import QuestionBlock from "../../../../form/QuestionBlock";
import FieldTextArea from "../../../../form/components/FieldTextArea";
import FieldInput from "common-form/components/FieldInput";
import FieldLabel from "common-form/components/FieldLabel";
import {LABEL_TYPE} from "common-form/components/Field";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";


import {
    FIELDS, 
} from "common-hypotheca";


export default function Changes(parent, nextBlockId) {
    
    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldLabel
                    label="Is your income or expenditure likely to change significantly within the foreseeable future?"
                />

                <FieldRadioYesNo
                    {...parent.getField(FIELDS.MM.MP.WillingToChangeIncome)}
                    labelType={LABEL_TYPE.Small}
                />

                <FieldTextArea
                    {...parent.getField(FIELDS.MM.MP.WillingToChangeIncomeReason)}
                    show={() => parent.getValue(FIELDS.MM.MP.WillingToChangeIncome) === true}
                    labelType={LABEL_TYPE.Small}
                />

                <FieldRadioYesNo
                    {...parent.getField(FIELDS.MM.MP.WillingToChangeExpenditure)}
                    labelType={LABEL_TYPE.Small}
                    type="number"
                />

                <FieldTextArea
                    {...parent.getField(FIELDS.MM.MP.WillingToChangeExpenditureReason)}
                    labelType={LABEL_TYPE.Small}
                    show={() => parent.getValue(FIELDS.MM.MP.WillingToChangeExpenditure) === true}
                />

                <FieldRadioYesNo
                    {...parent.getField(FIELDS.MM.MP.WillingToPayPremature)}
                    labelType={LABEL_TYPE.Small}
                    type="number"
                />
                
                <FieldTextArea
                    {...parent.getField(FIELDS.MM.MP.WillingToPayPrematureReason)}
                    labelType={LABEL_TYPE.Small}
                    show={() => parent.getValue(FIELDS.MM.MP.WillingToPayPremature) === true}
                />

                <FieldRadioYesNo
                    {...parent.getField(FIELDS.MM.MP.WillingToMoveHome)}
                    labelType={LABEL_TYPE.Small}
                    type="number"
                />
                
                <FieldTextArea
                    {...parent.getField(FIELDS.MM.MP.WillingToMoveHomeReason)}
                    labelType={LABEL_TYPE.Small}
                    placeholder="Optional supporting text ..."
                    show={() => parent.getValue(FIELDS.MM.MP.WillingToMoveHome) === true}
                />


                <FieldRadioYesNo
                    {...parent.getField(FIELDS.MM.MP.HasAdditionalMoneyToReduceBorrow)}
                    labelType={LABEL_TYPE.Small}
                    type="number"
                />
                
                <FieldInput
                    {...parent.getField(FIELDS.MM.MP.HowMuchMoneyToReduceBorrow)}
                    labelType={LABEL_TYPE.Small}
                    show={() => parent.getValue(FIELDS.MM.MP.HasAdditionalMoneyToReduceBorrow) === true}
                />

                <FieldTextArea
                    {...parent.getField(FIELDS.MM.MP.HasAdditionalMoneyCircumstances)}
                    labelType={LABEL_TYPE.Small}
                    show={() => parent.getValue(FIELDS.MM.MP.HasAdditionalMoneyToReduceBorrow) === true}
                    placeholder="e.g. if a better deal is available at a lower Loan to Value %"
                />

            </QuestionBlock>,


        validate: (context) => {

            context
            .mandatory(FIELDS.MM.MP.WillingToChangeIncome)
            .mandatory(FIELDS.MM.MP.WillingToChangeExpenditure)
            .mandatory(FIELDS.MM.MP.WillingToPayPremature)
            .mandatory(FIELDS.MM.MP.WillingToMoveHome)
            .mandatory(FIELDS.MM.MP.HasAdditionalMoneyToReduceBorrow)
            ;

            context
            .mandatoryOrCleanup(parent.getValue(FIELDS.MM.MP.WillingToChangeIncome) === true, FIELDS.MM.MP.WillingToChangeIncomeReason)
            .mandatoryOrCleanup(parent.getValue(FIELDS.MM.MP.WillingToChangeExpenditure) === true, FIELDS.MM.MP.WillingToChangeExpenditureReason)
            .mandatoryOrCleanup(parent.getValue(FIELDS.MM.MP.WillingToPayPremature) === true, FIELDS.MM.MP.WillingToPayPrematureReason)
            .optionalOrCleanup(parent.getValue(FIELDS.MM.MP.WillingToMoveHome) === true, FIELDS.MM.MP.WillingToMoveHomeReason)
            .mandatoryOrCleanup(parent.getValue(FIELDS.MM.MP.HasAdditionalMoneyToReduceBorrow) === true, 
                [
                    FIELDS.MM.MP.HowMuchMoneyToReduceBorrow,
                    FIELDS.MM.MP.HasAdditionalMoneyCircumstances
                ])
            ;
        },

        nextQuestionId: () => nextBlockId
    };

    
};