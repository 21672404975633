import MultiBlockForm from "./MultiBlockForm";


class MultiBlockFormSingleApplicant extends MultiBlockForm {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            // For single-applicant we derive applicantId from url params
            currentApplicantId: props.applicantId
        };
    }
}

export default MultiBlockFormSingleApplicant;