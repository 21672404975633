import { SECTION_OBSOLETE } from '../constants';
import { atLeast3YearsAgo } from 'common-hypotheca/src/dates';

import {
    APP_STATUS,
    APPLICATION_TYPE,
    describeStatus,
    FINISH_LINE_STATUSES,
    AFTER_ADVICE_EDITABLE_STATUSES,
    FIELDS,
    MORTGAGE_PURPOSE,
    SECTION,
    SCREENING,
    SITUATION,
    PROPERTY,
    MY_MORTGAGE,
    MY_APPLICATION, extractFieldName, OCCUPANT_TYPE, PRE_ADVICE_STATUSES
} from 'common-hypotheca';
import {roundTwoDecs} from "./numbers";

export const DATE_FORMAT = 'DD/MM/YYYY';

export class PartAdapter {
    adapter;
    applicantId;
    sectionId;
    partId;

    constructor(adapter, applicantId, sectionId, partId) {
        this.adapter = adapter;
        this.applicantId = applicantId;
        this.sectionId = sectionId;
        this.partId = partId;
    }

    removeField(field) {
        this.adapter.removeField(field, this.applicantId, this.sectionId, this.partId);
        return this;
    }

    removeFields(fields) {
        fields.forEach(f => this.removeField(f));
    }

    /**
     * Checks if particular field is present marking part of specified section of specified applicant incomplete otherwise.
     *
     * @param applicantId Applicant ID to mark section part incomplete for.
     * @param sectionId Section ID containing specified part
     * @param partId Part ID to mark incomplete
     */
    expectFieldPresence(field) {
        this.adapter.expectFieldPresence(field, this.applicantId, this.sectionId, this.partId);
        return this;
    }

    forAnotherPart(applicantId, sectionId, partId) {
        return this.adapter.forPart(applicantId, sectionId, partId);
    }

    setToIncomplete() {
        const fieldsContainer = this.adapter.fieldsContainer(this.applicantId, this.sectionId, this.partId);
        fieldsContainer.isComplete = false;
        return this;
    }
}

export class Adapter {
    model;
    constructor(model) {
        this.model = model;
    }

    forPart(applicantId, sectionId, partId) {
        return new PartAdapter(this, applicantId, sectionId, partId);
    }

    fieldsContainer(applicantId, sectionId, partId) {
        // console.log('field container for ' +
        //     sectionId +
        //     ((partId === undefined || partId === null) ? '' : ' partId ' + partId) +
        //     ` Applicant ${applicantId}`);
        if (!this.model) {
            return {};
        }

        let container;
        if (applicantId === null || applicantId === undefined) {
            container = this.model;
        }
        else {
            container = this.model.applicants.find(applicant => applicant.applicantId === applicantId);
            //  console.log('found applicant container', container)
        }

        if (container === undefined) {
            console.log('ERROR: field container not found for ' +
                sectionId +
                ((partId === undefined || partId === null) ? '' : ' partId ' + partId) +
                ` Applicant ${applicantId}`, this.model);

            return undefined;
        }

        let section = container[sectionId];
        if (section === undefined)
            return undefined;

        if (partId === null || partId === undefined) {
            return section;
        } else {
            if (section.parts === undefined)
                return undefined;
            else
                return section.parts.find(partObject => partObject.id === partId);
        }
    }

    /**
     * Checks if particular field is present marking part of specified section of specified applicant incomplete otherwise.
     *
     * @param applicantId Applicant ID to mark section part incomplete for.
     * @param sectionId Section ID containing specified part
     * @param partId Part ID to mark incomplete
     */
    expectFieldPresence(field, applicantId, sectionId, partId) {
        if (!this.hasField(field, applicantId, sectionId, partId)) {
            this.fieldsContainer(applicantId, sectionId, partId).isComplete = false;
        }
        return this;
    }

    fieldValue(field, applicantId, sectionId, partId) {
        let fieldContainer = this.fieldsContainer(applicantId, sectionId, partId);
        if (fieldContainer)
            return fieldContainer.fields[extractFieldName(field)];
        else
            return undefined;
    }

    hasField(field, applicantId, sectionId, partId) {
        let value = this.fieldValue(field, applicantId, sectionId, partId);
        return value !== undefined && value !== null;
    }

    applicantName = (applicantId) => {
        let fieldsContainer = this.fieldsContainer(
            applicantId !== undefined && applicantId !== null ? applicantId : 0,
            SECTION.SCREENING,
            SCREENING.MORTGAGE_TYPE_AND_APPLICANTS);

        return fieldsContainer && fieldsContainer.fields && fieldsContainer.fields[FIELDS.AS.MTA.Nickname]
            ? fieldsContainer.fields[FIELDS.AS.MTA.Nickname]
            : "Unknown";
    };

    getApplicants = () => {
        return this.model.applicants.map(applicant => {
            return {
                ...applicant,
                applicantName: this.applicantName(applicant.applicantId)
            };
        });
    };

    getMortgageTypeAndApplicants = () => {
        return this.fieldsContainer(
            null,
            SECTION.SCREENING,
            SCREENING.MORTGAGE_TYPE_AND_APPLICANTS);
    };

    // Mortgage type - to buy new property or mortgage existing let.

    isMortgageType = (type) => {
        return this.getMortgageTypeAndApplicants().fields[extractFieldName(FIELDS.AS.MTA.ApplnMortgageType)] === type;
    };

    isMortgageToBuyNewPlace = () => {
        return this.isMortgageType(APPLICATION_TYPE.NEW_MORTGAGE_TO_BUY.code);
    };

    isMortgageExistingLet = () => {
        return this.isMortgageType(APPLICATION_TYPE.MORTGAGE_EXISTING_LET.code);
    };


    // Mortgage purpose for mortgaging existing let.

    isMortgagePurpose = (type) => {
        return this.getMortgageTypeAndApplicants().fields[extractFieldName(FIELDS.AS.MTA.MortgagePurpose)] === type;
    };

    isBrandNewMortgage = () => {
        return this.isMortgagePurpose(MORTGAGE_PURPOSE.NEW);
    };

    isReplaceExistingMortgage = () => {
        return this.isMortgagePurpose(MORTGAGE_PURPOSE.REPLACE_EXISTING);
    };


    syncHolidayLetMarketValue = () => {

        const propertyDetails = this.fieldsContainer(null, SECTION.PROPERTY, PROPERTY.DETAILS).fields;
        const mortgageDetails = this.fieldsContainer(null, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_DETAILS).fields;
    
        if (this.isReplaceExistingMortgage() || this.isBrandNewMortgage()) {
            mortgageDetails[extractFieldName(FIELDS.MM.MD.HolidayLetMarketValueLink)] = propertyDetails[extractFieldName(FIELDS.MP.PD.HolidayLetMarketValue)];
        } else {
            this.removeField(FIELDS.MM.MD.HolidayLetMarketValueLink, null, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_DETAILS);
        }
    
    };

    /**
     * @returns {boolean} this is a limited company application.
     */
    isLimitedCompany = () => {
        return this.fieldValue(FIELDS.AS.MTA.ApplicationLimitedCompany, null, SECTION.SCREENING, SCREENING.MORTGAGE_TYPE_AND_APPLICANTS) === true;
    };

     /*
     * @returns {boolean}  this is a limited company application and company is already incorporated.
     */
    isLimitedCompanyIncorporated = () => {
        return this.isLimitedCompany()
            && this.fieldValue(FIELDS.MM.LTD.LimitedCompanyAlreadyIncorporated, null, SECTION.MY_MORTGAGE, MY_MORTGAGE.LIMITED_COMPANY) === true;
    };

    isPurchaseStage = (stage) => {

        let fieldsContainer = this.fieldsContainer(
            null,
            SECTION.SCREENING,
            SCREENING.MORTGAGE_TYPE_AND_APPLICANTS);

        return fieldsContainer && fieldsContainer.fields && fieldsContainer.fields[extractFieldName(FIELDS.AS.MTA.PurchaseStage)] === stage;
    };

    isHomeOwner = (applicantId) => {

        let personalDetails = this.fieldsContainer(
            applicantId || 0,
            SECTION.SITUATION,
            SITUATION.PERSONAL_DETAILS);

        return personalDetails.fields[extractFieldName(FIELDS.MS.PD.CurrentAddressOccuptType)] === OCCUPANT_TYPE.HOME_OWNER;
    };

    occupantType = (applicantId) => {

        let personalDetails = this.fieldsContainer(
            applicantId || 0,
            SECTION.SITUATION,
            SITUATION.PERSONAL_DETAILS);

        return personalDetails.fields[extractFieldName(FIELDS.MS.PD.CurrentAddressOccuptType)];
    };

    getPersonalDetails = (applicantId) => {
        return this.fieldsContainer(
            applicantId || 0,
            SECTION.SITUATION,
            SITUATION.PERSONAL_DETAILS);
    };

    getCurrentAddress = (applicantId) => {

        let personalDetails = this.getPersonalDetails(applicantId);
        return {
            CurrentAddressAddressLine1: personalDetails.fields[extractFieldName(FIELDS.MS.PD.CurrentAddressAddressLine1)],
            CurrentAddressAddressLine2: personalDetails.fields[extractFieldName(FIELDS.MS.PD.CurrentAddressAddressLine2)],
            CurrentAddressTown: personalDetails.fields[extractFieldName(FIELDS.MS.PD.CurrentAddressTown)],
            CurrentAddressCounty: personalDetails.fields[extractFieldName(FIELDS.MS.PD.CurrentAddressCounty)],
            CurrentAddressPostcode: personalDetails.fields[extractFieldName(FIELDS.MS.PD.CurrentAddressPostcode)],
        };

    };

    getPropertyDetails = () => {
        return this.fieldsContainer(
            null,
            SECTION.PROPERTY,
            PROPERTY.DETAILS);
    };

    getPropertyValue = () => {
        return this.getPropertyDetails().fields[extractFieldName(FIELDS.MP.PD.HolidayLetMarketValue)];
    };

    getPropertyAddress = () => {

        const propertyDetails = this.getPropertyDetails();
        return {
            CurrentAddressAddressLine1: propertyDetails.fields[extractFieldName(FIELDS.MP.PD.HolidayLetAddressLine1)],
            CurrentAddressAddressLine2: propertyDetails.fields[extractFieldName(FIELDS.MP.PD.HolidayLetAddressLine2)],
            CurrentAddressTown: propertyDetails.fields[extractFieldName(FIELDS.MP.PD.HolidayLetTown)],
            CurrentAddressCounty: propertyDetails.fields[extractFieldName(FIELDS.MP.PD.HolidayLetCounty)],
            CurrentAddressPostcode: propertyDetails.fields[extractFieldName(FIELDS.MP.PD.HolidayLetPostcode)],
        };

    };

    getApplicationDateOfBirth = () => {
        return this.getPersonalDetails().fields[extractFieldName(FIELDS.MS.PD.DOB)];
    };

    getApplicationAge = () => {
        return this.getPersonalDetails().fields[extractFieldName(FIELDS.MS.PD.Age)];
    };

    getApplicationRetirementAge = () => {
        return this.getPersonalDetails().fields[extractFieldName(FIELDS.MS.PD.RetirementAge)];
    };

    applicantCount = () => {
        return this.model.applicants.length;
    };

    applicantIds = () => {
        return this.model.applicants.map(applicant => applicant.applicantId);
    };

    mainApplicantId = () => {
        return this.model.applicants[0].applicantId;
    };

    appStatus = () => {
        return describeStatus(this.model.appStatus)
    };

    
    additionalAddressesNeeded = () => {
        return !atLeast3YearsAgo(this.fieldValue(null, FIELDS.MS.PD.CurrentAddressStartDate));
    };

    partShown(applicantId, sectionId, partId) {
        /*
        if ([
                MY_MORTGAGE.BORROW_ESTIMATE_BAD_DEBUG,
                MY_MORTGAGE.BORROW_ESTIMATE
            ].includes(partId)) {
            return this.model.appStatus === APP_STATUS.TEST_EVERYTHING_OPEN;
        }
        */

        if (partId === MY_MORTGAGE.LIMITED_COMPANY) {
            return this.isLimitedCompany();
        }

        return true;
    }

    partComplete(applicantId, sectionId, partId) {
        if (sectionId === SECTION.MY_MORTGAGE && partId === MY_MORTGAGE.EXPERT_ADVICE) {
            return !PRE_ADVICE_STATUSES.includes(this.model.appStatus)
                && APP_STATUS.REQUEST_LOCKED !== this.model.appStatus;
        } else {

            const part = this.fieldsContainer(applicantId, sectionId, partId);
            if (!part) {
                console.error(`Can't find part for app ${applicantId} section ${sectionId} ${partId}  `);
                return false;
            }

            return part.isComplete;
        }
    };

    partAvailable(applicantId, sectionId, partId) {
        if (this.model.appStatus === APP_STATUS.TEST_EVERYTHING_OPEN)
            return true;
        else {
            switch (sectionId) {
                case SECTION.SCREENING:
                    switch (partId) {
                        case SCREENING.CREDIT_HISTORY_AND_RESIDENCE:
                            return this.partComplete(null, SECTION.SCREENING, SCREENING.MORTGAGE_TYPE_AND_APPLICANTS);
                        case SCREENING.KEY_CRITERIA:
                            return this.partComplete(null, SECTION.SCREENING, SCREENING.CREDIT_HISTORY_AND_RESIDENCE);
                        case SCREENING.INITIAL_ASSESSMENT:
                            return this.partComplete(null, SECTION.SCREENING, SCREENING.KEY_CRITERIA);
                        default:
                            break;
                    }
                    break;

                case SECTION.SITUATION:
                    switch (partId) {
                        case SITUATION.PERSONAL_DETAILS:
                            return this.sectionComplete(SECTION.SCREENING);
                        case SITUATION.ASSETS:
                            return this.partComplete(applicantId, SECTION.SITUATION, SITUATION.PERSONAL_DETAILS);
                        case SITUATION.EMPLOYMENT:
                            return this.partComplete(applicantId, SECTION.SITUATION, SITUATION.ASSETS);
                        case SITUATION.CREDIT:
                            return this.partComplete(applicantId, SECTION.SITUATION, SITUATION.EMPLOYMENT);
                        case SITUATION.OUTGOINGS:
                            return this.partComplete(applicantId, SECTION.SITUATION, SITUATION.CREDIT);
                        default:
                            break;
                    }
                    break;

                case SECTION.PROPERTY:
                    switch (partId) {
                        case PROPERTY.DETAILS:
                            return this.sectionComplete(SECTION.SITUATION);
                        case PROPERTY.LETTING:
                            return this.fieldsContainer(applicantId, SECTION.PROPERTY, PROPERTY.DETAILS).isComplete;
                        default:
                            break;
                    }
                    break;

                case SECTION.MY_MORTGAGE:
                    switch (partId) {
                        case MY_MORTGAGE.MORTGAGE_DETAILS:
                            return this.sectionComplete(SECTION.PROPERTY);
                        case MY_MORTGAGE.MORTGAGE_PREFERENCES:
                            return this.fieldsContainer(applicantId, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_DETAILS).isComplete;
                        case MY_MORTGAGE.LIMITED_COMPANY:
                            return this.isLimitedCompany()
                                && this.fieldsContainer(applicantId, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_PREFERENCES).isComplete
                                // Need to check MORTGAGE_DETAILS also as global reset could have made it for input as well
                                && this.fieldsContainer(applicantId, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_DETAILS).isComplete;
                        case MY_MORTGAGE.SOLICITOR:
                            return this.fieldsContainer(applicantId, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_DETAILS).isComplete
                                && (this.isLimitedCompany()
                                    ? this.partComplete(applicantId, SECTION.MY_MORTGAGE, MY_MORTGAGE.LIMITED_COMPANY)
                                    : this.partComplete(applicantId, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_PREFERENCES));
                        case MY_MORTGAGE.EXPERT_ADVICE:
                            return this.fieldsContainer(applicantId, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_DETAILS).isComplete
                                && (!this.isLimitedCompany() || this.partComplete(applicantId, SECTION.MY_MORTGAGE, MY_MORTGAGE.LIMITED_COMPANY))
                                && this.partComplete(applicantId, SECTION.MY_MORTGAGE, MY_MORTGAGE.SOLICITOR);
                        default:
                            break;
                    }
                    break;

                default:
                    break;
            }

            const part = this.fieldsContainer(applicantId, sectionId, partId);
            if (!part) {
                console.error(`Can't find part for app ${applicantId} section ${sectionId} ${partId}  `);
                return false;
            }

            return part.isAvailable;
        }
    }

    partReadonly(applicantId, sectionId, partId) {

        if (this.model.appStatus === APP_STATUS.TEST_EVERYTHING_OPEN) {
            return false;
        }

        if ([
            SECTION.SCREENING,
            SECTION.SITUATION,
            SECTION.PROPERTY,
            SECTION.MY_MORTGAGE
        ].includes(sectionId)) {
            return !PRE_ADVICE_STATUSES.includes(this.model.appStatus);
        } else {
            return !AFTER_ADVICE_EDITABLE_STATUSES.includes(this.model.appStatus);
        }
    }

    reachedStage() {
        if (PRE_ADVICE_STATUSES.includes(this.model.appStatus))
            return undefined;
        else if (AFTER_ADVICE_EDITABLE_STATUSES.includes(this.model.appStatus))
            return 'Your data is set to read only as you have requested Expert Advice.';
        else
            return 'Your data is set to read only as you have reached the Application Stage.';
    }

    sectionComplete(sectionId, applicantId) {
        switch (sectionId) {
            case SECTION.SCREENING:
                return this.fieldsContainer(null, SECTION.SCREENING, SCREENING.MORTGAGE_TYPE_AND_APPLICANTS).isComplete
                    && this.fieldsContainer(null, SECTION.SCREENING, SCREENING.CREDIT_HISTORY_AND_RESIDENCE).isComplete
                    && this.fieldsContainer(null, SECTION.SCREENING, SCREENING.KEY_CRITERIA).isComplete
                    && this.fieldsContainer(null, SECTION.SCREENING, SCREENING.INITIAL_ASSESSMENT).isComplete;

            case SECTION.SITUATION:

                if (applicantId !== undefined) {

                    return this.fieldsContainer(applicantId, SECTION.SITUATION, SITUATION.PERSONAL_DETAILS).isComplete
                        && this.fieldsContainer(applicantId, SECTION.SITUATION, SITUATION.EMPLOYMENT).isComplete
                        && this.fieldsContainer(applicantId, SECTION.SITUATION, SITUATION.ASSETS).isComplete
                        && this.fieldsContainer(applicantId, SECTION.SITUATION, SITUATION.CREDIT).isComplete
                        && this.fieldsContainer(applicantId, SECTION.SITUATION, SITUATION.OUTGOINGS).isComplete;

                }

                for (let i = 0; i < this.applicantCount(); i++) {
                    // Check for all applicants
                    if (!this.sectionComplete(SECTION.SITUATION, this.model.applicants[i].applicantId))
                        return false;
                }

                return true;

            case SECTION.PROPERTY:
                return this.partComplete(null, SECTION.PROPERTY, PROPERTY.DETAILS)
                    && this.partComplete(null, SECTION.PROPERTY, PROPERTY.LETTING);

            case SECTION.MY_MORTGAGE:
                return this.partComplete(null, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_DETAILS)
                    && this.partComplete(null, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_PREFERENCES)
                    && this.partComplete(null, SECTION.MY_MORTGAGE, MY_MORTGAGE.SOLICITOR)
                    && this.partComplete(null, SECTION.MY_MORTGAGE, MY_MORTGAGE.EXPERT_ADVICE);

            case SECTION.MY_APPLICATION:
                return this.partComplete(null, SECTION.MY_APPLICATION, MY_APPLICATION.KEY_DOCS)
                    && this.partComplete(null, SECTION.MY_APPLICATION, MY_APPLICATION.DOC_UPLOAD)
                    && this.model[SECTION.MY_APPLICATION].fields[extractFieldName(FIELDS.MA.FormalConsent)];

            default:
                return this.fieldsContainer(null, sectionId).isComplete;
        }
    }

    sectionAvailable(sectionId) {
        let status = this.model.appStatus;

        if (status === APP_STATUS.TEST_EVERYTHING_OPEN)
            return true;

        switch (sectionId) {
            case SECTION.SCREENING:
                return true;

            case SECTION.SITUATION:
                return this.sectionComplete(SECTION.SCREENING);

            case SECTION.PROPERTY:
                for (let applicantId of this.applicantIds()) {
                    if (!this.sectionComplete(SECTION.SITUATION, applicantId))
                        return false;
                }

                return this.sectionAvailable(SECTION.SITUATION);

            case SECTION.MY_MORTGAGE:
                return this.sectionAvailable(SECTION.PROPERTY)
                    && this.sectionComplete(SECTION.PROPERTY);

            case SECTION.MY_APPLICATION:
                return FINISH_LINE_STATUSES.includes((status))
                    || APP_STATUS.APP_CONFIRM_GIVE === status
                    || APP_STATUS.ADVICE_GIVEN === status;

            case SECTION.APPLICATION_STATUS:
                return FINISH_LINE_STATUSES.includes(status)
                    || APP_STATUS.APP_CONFIRM_GIVE === status;

            default:
                return false;

        }
    }

    sectionLocked(sectionId) {
        return (sectionId === SECTION.SCREENING || sectionId === SECTION.SITUATION)
            && this.model.status !== APP_STATUS.INITIAL
            && this.model.status !== APP_STATUS.STARTED
            && this.model.status !== APP_STATUS.BORROW_GOOD
            && this.model.status !== APP_STATUS.BORROW_BAD;
    }

    /**
     * Removes field value from data. As redux state isn't touched therefore no state propagation and no re-renderings.
     */
    removeField = (field, applicantId, sectionId, partId) => {
        let fieldName = extractFieldName(field);
        let fieldContainer = this.fieldsContainer(applicantId, sectionId, partId);

        if (!fieldContainer) {
            return;
        }

        delete fieldContainer.fields[fieldName];
        return this;
    };

    /**
     * Updates field value. As redux state isn't touched therefore no state propagation and no re-renderings.
     */
    updateField = (value, field, applicantId, sectionId, partId) => {
        if (value === null || value === undefined) {
            this.removeField(field, applicantId, sectionId, partId);
            return;
        }

        let fieldName = extractFieldName(field);
        let fieldContainer = this.fieldsContainer(applicantId, sectionId, partId);

        if (!fieldContainer) {
            return;
        }

        fieldContainer.fields[fieldName] = value;
    };

    updateLoanToValue = (field, borrowAmountField, propertyValue, sectionId, partId) => {
        const loanToValue = roundTwoDecs(this.fieldValue(borrowAmountField, null, sectionId, partId) / propertyValue * 100);
        if (isNaN(loanToValue)) {
            this.removeField(field, null, sectionId, partId);
        } else {
            this.updateField(loanToValue, field, null, sectionId, partId);
        }
    };

    ltvFunctions = (options) => {
        // options {
        //     partId
        //     sectionId
        //     propertyValueField
        //     borrowPercentField
        //     borrowAmountField
        // }
        //

        let adapter = this;

    };
}

function migrateAddPart(sectionData, partId) {
    if (sectionData.parts.find(p => p.id === partId) === undefined) {
        sectionData.parts.push({
            id: partId,
            isAvailable: false,
            fields: {}
        });
    }
}

export function migrateApplication(data) {
    // Sample of model evolution to support previous model

    // console.log('Migrating: ', data);

    let adapter = new Adapter(data);

    // Rename Get Started section -> Screening
    if (data[SECTION_OBSOLETE.GET_STARTED] !== undefined) {
        data[SECTION.SCREENING] = data[SECTION_OBSOLETE.GET_STARTED];
        data[SECTION_OBSOLETE.GET_STARTED] = undefined;
    }

    // Remove payday and student loans from credit types.
    data.applicants.forEach(applicant => {
        // Rename Get Started section -> Screening
        if (applicant[SECTION_OBSOLETE.GET_STARTED] !== undefined) {
            applicant[SECTION.SCREENING] = applicant[SECTION_OBSOLETE.GET_STARTED];
            applicant[SECTION_OBSOLETE.GET_STARTED] = undefined;
        }

        // copy additional applicants phone/email from MTA to PD
        if (applicant.applicantId > 0) {

            try {
                const screening = applicant.screening.parts.find(i => i.id === SCREENING.MORTGAGE_TYPE_AND_APPLICANTS).fields;
                const personalDetails = applicant.situation.parts.find(i => i.id === SITUATION.PERSONAL_DETAILS).fields;

                if (screening[extractFieldName(FIELDS.AS.MTA.PhoneNumber)] && !personalDetails[extractFieldName(FIELDS.MS.PD.AdditionalPhoneNumber)]) {
                    personalDetails[extractFieldName(FIELDS.MS.PD.AdditionalPhoneNumber)] = screening[extractFieldName(FIELDS.AS.MTA.PhoneNumber)];
                }

                if (screening[extractFieldName(FIELDS.AS.MTA.EmailAddress)] && !personalDetails[extractFieldName(FIELDS.MS.PD.AdditionalEmailAddress)]) {
                    personalDetails[extractFieldName(FIELDS.MS.PD.AdditionalEmailAddress)] = screening[extractFieldName(FIELDS.AS.MTA.EmailAddress)];
                }

                delete screening[extractFieldName(FIELDS.AS.MTA.PhoneNumber)];
                delete screening[extractFieldName(FIELDS.AS.MTA.EmailAddress)];

            } catch{ }

        }

        //
        try {
            const creditTypes = applicant.situation.parts.find(p => p.name === 'Credit').fields.MyCreditTypes;

            let idx = creditTypes.indexOf('paydayLoans');
            if (idx > -1) {
                creditTypes.splice(idx, 1);
            }

            idx = creditTypes.indexOf('studentLoans');
            if (idx > -1) {
                creditTypes.splice(idx, 1);
            }
        } catch { }

        // Need initial assessment to each applicant
        migrateAddPart(applicant[SECTION.SCREENING], SCREENING.INITIAL_ASSESSMENT);


    });


    //move solicitor from my application to my morgage
    const oldSSI = data[SECTION.MY_APPLICATION].parts.find(p => p.id === MY_MORTGAGE.SOLICITOR);
    const newSSI = data[SECTION.MY_MORTGAGE].parts.find(p => p.id === MY_MORTGAGE.SOLICITOR);

    if (oldSSI && !newSSI) {
        data[SECTION.MY_MORTGAGE].parts.push(oldSSI);
        data[SECTION.MY_APPLICATION].parts.splice(data[SECTION.MY_APPLICATION].parts.indexOf(oldSSI), 1);
    } else if (!newSSI) {
        data[SECTION.MY_MORTGAGE].parts.push({
            id: MY_MORTGAGE.SOLICITOR,
            isComplete: false,
            isAvailable: true,
            fields: {}
        });
    }


    // Add Holiday Let Criteria to screening section
    if (data[SECTION.SCREENING].parts.find(p => p.id === SCREENING.KEY_CRITERIA) === undefined) {
        data[SECTION.SCREENING].parts.push({
            id: SCREENING.KEY_CRITERIA,
            isComplete: false,
            isAvailable: true,
            fields: {}
        });
    }

    // Add Initial assessment
    if (data[SECTION.SCREENING].parts.find(p => p.id === SCREENING.INITIAL_ASSESSMENT) === undefined) {
        data[SECTION.SCREENING].parts.push({
            id: SCREENING.INITIAL_ASSESSMENT,
            isComplete: false,
            isAvailable: false,
            fields: {}
        });
    }


    // Add situation if it isn't there
    if (data[SECTION.SITUATION] === undefined) {
        data[SECTION.SITUATION] = {
            isComplete: false,
            isAvailable: data[SECTION.SCREENING].isComplete,
            parts: []
        };
    }

    // Add Parts to property - weren't initially in the model
    if (data[SECTION.PROPERTY].parts.find(p => p.id === PROPERTY.DETAILS) === undefined) {
        data[SECTION.PROPERTY].parts.push({
            id: PROPERTY.DETAILS,
            isAvailable: true,
            isComplete: false,
            fields: {}
        });
    }

    if (data[SECTION.PROPERTY].parts.find(p => p.id === PROPERTY.LETTING) === undefined) {
        data[SECTION.PROPERTY].parts.push({
            id: PROPERTY.LETTING,
            isAvailable: false,
            isComplete: false,
            fields: {}
        });
    }

    // Add Mortgage Details
    if (data[SECTION.MY_MORTGAGE].parts.find(p => p.id === MY_MORTGAGE.MORTGAGE_DETAILS) === undefined) {
        data[SECTION.MY_MORTGAGE].parts.push({
            id: MY_MORTGAGE.MORTGAGE_DETAILS,
            isComplete: false,
            isAvailable: true,
            fields: {}
        });
    }

    // Remove duplicate field name which I had to rename
    for (let applicantId of adapter.applicantIds()) {

        const container = adapter.fieldsContainer(applicantId, SECTION.SCREENING, SCREENING.KEY_CRITERIA);
        if (container) {
            let fields = container.fields;

            if (fields[extractFieldName(FIELDS.MS.EI.EmploymentType)]) {
                if (!fields[extractFieldName(FIELDS.AS.HLC.KeyCriteriaEmploymentType)]) {
                    fields[extractFieldName(FIELDS.AS.HLC.KeyCriteriaEmploymentType)] = fields[extractFieldName(FIELDS.MS.EI.EmploymentType)];
                }

                adapter.removeField(FIELDS.MS.EI.EmploymentType, applicantId, SECTION.SCREENING, SCREENING.KEY_CRITERIA);
            }
        }
    }

    // Convert field name for purchase deposit source
    let mortgageDetailsFields = adapter.fieldsContainer(null, SECTION.MY_MORTGAGE, MY_MORTGAGE.MORTGAGE_DETAILS).fields;
    if (mortgageDetailsFields[extractFieldName(FIELDS.MM.MD.PurchaseDeposits)])
        for (let source of mortgageDetailsFields[extractFieldName(FIELDS.MM.MD.PurchaseDeposits)]) {
            if (source[extractFieldName(FIELDS.MM.MD.PurchaseSource)]) {
                source[extractFieldName(FIELDS.MM.MD.PurchaseDepositSource)] = source[extractFieldName(FIELDS.MM.MD.PurchaseSource)];
                delete source[extractFieldName(FIELDS.MM.MD.PurchaseSource)];
            }
        }

    // Add limited company section
    if (data[SECTION.MY_MORTGAGE].parts.find(p => p.id === MY_MORTGAGE.LIMITED_COMPANY) === undefined) {
        data[SECTION.MY_MORTGAGE].parts.push({
            id: MY_MORTGAGE.LIMITED_COMPANY,
            isComplete: false,
            fields: {}
        });
    }

    // Move is company application to new place
    let oldLimitedCompanyApplication = adapter.fieldValue(FIELDS.AS.HLC.IsApplicationViaLimitedCompany, undefined, SECTION.SCREENING, SCREENING.INITIAL_ASSESSMENT);
    let newLimitedCompanyApplication = adapter.fieldValue(FIELDS.AS.MTA.ApplicationLimitedCompany, undefined, SECTION.SCREENING, SCREENING.MORTGAGE_TYPE_AND_APPLICANTS);

    if (oldLimitedCompanyApplication !== undefined && newLimitedCompanyApplication === undefined) {
        adapter.updateField(oldLimitedCompanyApplication, FIELDS.AS.MTA.ApplicationLimitedCompany, undefined, SECTION.SCREENING, SCREENING.MORTGAGE_TYPE_AND_APPLICANTS);
        adapter.updateField(undefined, FIELDS.AS.HLC.IsApplicationViaLimitedCompany, undefined, SECTION.SCREENING, SCREENING.INITIAL_ASSESSMENT);
    }

    // console.log('After migration: ', data);
}

export const getBlankApplicant = id => {
    let blankApplicant = {
        applicantId: id,
        included: true,
    };

    // corresponds to SECTION.SCREENING
    blankApplicant[SECTION.SCREENING] = {
        isAvailable: true,
        isComplete: false,

        parts: [
            {
                id: SCREENING.MORTGAGE_TYPE_AND_APPLICANTS,
                isComplete: false,
                isAvailable: true,
                fields: {}
            },
            {
                id: SCREENING.CREDIT_HISTORY_AND_RESIDENCE,
                isComplete: false,
                isAvailable: false,
                fields: {}
            },
            {
                id: SCREENING.KEY_CRITERIA,
                isComplete: false,
                isAvailable: false,
                fields: {}
            },
            {
                id: SCREENING.INITIAL_ASSESSMENT,
                isComplete: false,
                isAvailable: false,
                fields: {}
            }
        ]
    };

    // corresponds to SECTION.SITUATION
    blankApplicant[SECTION.SITUATION] = {
        isAvailable: false,
        isComplete: false,
        parts: [
            {
                id: SITUATION.PERSONAL_DETAILS,
                isComplete: false,
                isAvailable: false,
                fields: {}
            },
            {
                id: SITUATION.ASSETS,
                isComplete: false,
                isAvailable: false,
                fields: {}
            },
            {
                id: SITUATION.EMPLOYMENT,
                isComplete: false,
                isAvailable: false,
                fields: {}
            },
            {
                id: SITUATION.CREDIT,
                isComplete: false,
                isAvailable: false,
                fields: {}
            },
            {
                id: SITUATION.OUTGOINGS,
                isComplete: false,
                isAvailable: false,
                fields: {}
            }
        ]
    };

    return blankApplicant
};

export const getBlankApplication = (config) => {
    let blankApp = {
        active: true,
        applicationId: config.id,
        appStatus: APP_STATUS.INITIAL,
        time: {
            created: Date.now(),
            lastUpdated: Date.now()
        },
        notifications: [],
        applicants: [
            getBlankApplicant(0)
        ],

    };

    blankApp[SECTION.SCREENING] = {
        isComplete: false,
        isAvailable: true,
        parts: [
            {
                id: SCREENING.MORTGAGE_TYPE_AND_APPLICANTS,
                isComplete: false,
                isAvailable: true,
                fields: {}
            },
            {
                id: SCREENING.CREDIT_HISTORY_AND_RESIDENCE,
                isComplete: false,
                isAvailable: false,
                fields: {}
            },
            {
                id: SCREENING.KEY_CRITERIA,
                isComplete: false,
                isAvailable: false,
                fields: {}
            },
            {
                id: SCREENING.INITIAL_ASSESSMENT,
                isComplete: false,
                isAvailable: false,
                fields: {}
            }
        ],
    };

    blankApp[SECTION.SITUATION] = {
        isComplete: false,
        isAvailable: true,
        parts: []
    };

    blankApp[SECTION.PROPERTY] = {
        isComplete: false,
        isAvailable: true,
        parts: [
            {
                id: PROPERTY.DETAILS,
                isAvailable: true,
                isComplete: false,
                fields: {}
            },
            {
                id: PROPERTY.LETTING,
                isAvailable: false,
                isComplete: false,
                fields: {}
            }
        ]
    };

    blankApp[SECTION.MY_MORTGAGE] = {
        isComplete: false,
        isAvailable: true,
        parts: [
            {
                id: MY_MORTGAGE.MORTGAGE_DETAILS,
                isComplete: false,
                isAvailable: true,
                fields: {}
            },
            {
                id: MY_MORTGAGE.MORTGAGE_PREFERENCES,
                isComplete: false,
                isAvailable: true,
                fields: {}
            },
            {
                id: MY_MORTGAGE.LIMITED_COMPANY,
                isComplete: false,
                fields: {}
            },
            {
                id: MY_MORTGAGE.SOLICITOR,
                isComplete: false,
                isAvailable: true,
                fields: {}
            },
            {
                id: MY_MORTGAGE.EXPERT_ADVICE,
                isComplete: false,
                isAvailable: true,
                fields: {}
            }
        ]
    };

    blankApp[SECTION.APPLICATION_STATUS] = {
        isComplete: false,
        isAvailable: true,
        fields: {}
    };

    blankApp[SECTION.MY_APPLICATION] = {
        isComplete: false,
        isAvailable: true,
        fields: {},
        parts: [
            {
                id: MY_APPLICATION.KEY_DOCS,
                isComplete: false,
                isAvailable: true,
                documents: []
            },
            {
                id: MY_APPLICATION.DOC_UPLOAD,
                isComplete: false,
                isAvailable: true,
                documents: []
            }
        ]

    };

    return blankApp;
};

export const getFreshCustomerAccount = initial => {
    return {
        userStatus: 'active',
        security: [
            {
                question: initial.security_question,
                answer: initial.security_answer
            }
        ],

        consents: initial.consents,
        pin: initial.pin,
        nickname: initial.nickname,
        email: initial.email,
        FCAConsent: false,
        sub: initial.sub,
        createdTime: new Date().getTime(),
        lastSignInTime: new Date().getTime(),
        referralCode: initial.referralCode || undefined,
        referralCodeTime: !!initial.referralCode ? Date.now() : undefined,
        shareWithAgent: true,
    }
};


