import * as React from "react";

import QuestionBlock from "../../../../form/QuestionBlock";
import {FieldRadioPicture} from "../../../../form/components/radio";

import {FIELDS} from "common-hypotheca";
import {globalCleanupMortgagePurpose} from "../../../../util/globalCleanup";


export default function SelectMortgagePurpose(parent, nextBlockId) {


    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldRadioPicture
                    {...parent.getFormField(null, FIELDS.AS.MTA.MortgagePurpose)}
                />

            </QuestionBlock>,


        validate: (context) => {
            context.mandatory(FIELDS.AS.MTA.MortgagePurpose);

            // Cleanup other sections depending on selected mortgage purpose
            globalCleanupMortgagePurpose(parent.props.adapter);
        },

        nextQuestionId: () => nextBlockId
    };

    
};