import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";

import {extractFieldName, FIELDS, OTHER} from "common-hypotheca";
import FieldInput from "common-form/components/FieldInput";
import FieldGroupAddable from "../../../../../form/components/FieldGroupAddable";
import FieldLabel from "common-form/components/FieldLabel";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import { FieldDropdown } from "../../../../../form/components/dropdown";
import FieldDate from "common-form/components/FieldDate";
import { LABEL_TYPE } from "common-form/components/Field";

export default function CarFinanceList(parent, nextBlockId) {
    return {
        block: (params) =>
            <QuestionBlock {...params}>
                <FieldLabel
                    label="Please tell us about your car finance"
                />

                <FieldGroupAddable
                    ref={params.groupAddableRef}
                    addButtonLabel="Add car finance"
                    allowDeleteAll
                    deleteButtonLabel="Remove"
                    editButtonLabel="Edit"
                    saveButtonLabel="Save car finance"
                    itemDescriber={(fieldsContainer) => {
                        if (fieldsContainer[extractFieldName(FIELDS.MS.CC.CarFinanceName)] === undefined)
                            return 'A loan from unspecified provider';
                        else
                            return 'Loan from ' + fieldsContainer[extractFieldName(FIELDS.MS.CC.CarFinanceName)];
                    }}
                    validate={(context) => {
                        context
                            .mandatory(FIELDS.MS.CC.CarFinanceName)
                            .mandatory(FIELDS.MS.CC.CarFinancePurpose)
                            .mandatory(FIELDS.MS.CC.CarFinanceAcctNum)
                            .mandatory(FIELDS.MS.CC.CarFinanceOrigBorrowAmt)
                            .date(FIELDS.MS.CC.CarFinanceOrigStartDate)
                            .mandatory(FIELDS.MS.CC.CarFinanceCurrentBal)
                            .mandatory(FIELDS.MS.CC.CarFinancePayEachMonth)
                            .date(FIELDS.MS.CC.CarFinanceFinalRepayDate)
                            .mandatory(FIELDS.MS.CC.CarFinancePaidOffCompletion)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.CarFinancePaidOffCompletion),
                                FIELDS.MS.CC.CarFinancePaidOffCompletionHow)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.CarFinancePaidOffCompletion)
                                && context.fieldValue(FIELDS.MS.CC.CarFinancePaidOffCompletionHow) === OTHER,
                                FIELDS.MS.CC.CarFinancePaidOffCompletionHowOther)
                            // Cleanup obsolete fields (if present in data)
                            .cleanupIrrelevantField(FIELDS.MS.CC.CarFinanceCurrentIntRate)
                            .cleanupIrrelevantField(FIELDS.MS.CC.CarFinanceIsSecOnProperty)
                            .cleanupIrrelevantField(FIELDS.MS.CC.CarFinanceIsSecOnPropertyDescr)
                        ;
                    }}
                    {...parent.getFormField(null, FIELDS.MS.CC.CarFinances)}
                >

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CarFinanceName}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CarFinancePurpose}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CarFinanceAcctNum}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CarFinanceOrigBorrowAmt}
                    />

                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CarFinanceOrigStartDate}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CarFinanceCurrentBal}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CarFinancePayEachMonth}
                    />

                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CarFinanceFinalRepayDate}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CarFinancePaidOffCompletion}
                    />

                    <FieldDropdown
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.CarFinancePaidOffCompletion)]}
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CarFinancePaidOffCompletionHow}
                    />

                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.CarFinancePaidOffCompletion)]
                            && container[extractFieldName(FIELDS.MS.CC.CarFinancePaidOffCompletionHow)] === OTHER}
                        field={FIELDS.MS.CC.CarFinancePaidOffCompletionHowOther}
                        labelType={LABEL_TYPE.Small}
                        placeholder="Other method description"
                    />

                </FieldGroupAddable>


            </QuestionBlock>,

        validate: (context) => {

            if (!context.hasItems(FIELDS.MS.CC.CarFinances)) {
                context.addError(null, null, "Please enter an item or go back to the previous screen and change your selection.");
            }

            if (parent.groupAddableRef && !parent.groupAddableRef.current.valid()) {
                context.addError(FIELDS.MS.CC.CarFinances, null, '');
            }

        },

        nextQuestionId: () => parent.nextSelectedCommitmentType(nextBlockId)
    };
}