import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldLabel from "common-form/components/FieldLabel";
import FieldInput from "common-form/components/FieldInput";
import FieldDropdown from "../../../../../form/components/dropdown/FieldDropdown";
import { LABEL_TYPE } from "../../../../../form/components/Field";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldRadioGroup from "../../../../../form/components/radio/FieldRadioGroup";
import {Alert, ALERTTYPE} from "../../../../../form/components/alert/Alert";

import {
    FIELDS,
    INSURANCE_RESPONSIBLE_TYPE
} from "common-hypotheca";


export default function PropertyInsurance(parent, nextQuestionId) {

    function isResponsibleType(type) {
        return parent.getValue(FIELDS.MA.SSI.WhoIsResponsibleForInsur) === type;
    }

    function isMyselfOrOther() {
        return isResponsibleType(INSURANCE_RESPONSIBLE_TYPE.MYSELF) 
            || isResponsibleType(INSURANCE_RESPONSIBLE_TYPE.OTHER) 
        ;
    }

    function isOther() {
        return isResponsibleType(INSURANCE_RESPONSIBLE_TYPE.OTHER);
    }

    function ifHelpArrange() {
        return isMyselfOrOther() && parent.getValue(FIELDS.MA.SSI.DoYouWantHCMHelpInsur) === true;
    }

    function ifNoHelp() {
        return isMyselfOrOther() && parent.getValue(FIELDS.MA.SSI.DoYouWantHCMHelpInsur) === false;
    }

    function isArrangePersonally() {
        return ifNoHelp() &&  parent.getValue(FIELDS.MA.SSI.HowIWillArrangeInsur) === 'yourself';
    }
    
    return {
        block: (params) =>

            <QuestionBlock {...params}>

                <FieldLabel
                    label="Please confirm who is responsible for insuring the property"
                />

                <FieldLabel
                    label="It is a condition of most mortgages that the property is insured for its full reinstatement value. 
                        Whilst many lenders offer such insurance products, you don't have to go with them if you don't want to."
                    labelType={LABEL_TYPE.Description}
                />

                <FieldDropdown
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MA.SSI.WhoIsResponsibleForInsur)}
                />

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    show={isOther()}
                    {...parent.getField(FIELDS.MA.SSI.WhoIsResponsibleForInsurOther)}
                />

                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    show={isMyselfOrOther()}
                    {...parent.getField(FIELDS.MA.SSI.DoYouWantHCMHelpInsur)}
                />

                <Alert
                    text="Thank you for letting us know. Your advisor will be in touch shortly."
                    show={ifHelpArrange()}
                    type={ALERTTYPE.SUCCESS}
                />

                <FieldRadioGroup
                    labelType={LABEL_TYPE.Small}
                    inline={true}
                    show={ifNoHelp()}
                    {...parent.getField(FIELDS.MA.SSI.HowIWillArrangeInsur)}
                />

                <Alert
                    text="The lender will require a copy of the insurance policy, with the lender's interest noted on the schedule."
                    show={isArrangePersonally()}
                    type={ALERTTYPE.WARNING}
                />

            </QuestionBlock>,


        validate: (context) => {
            
            context
                .mandatory(FIELDS.MA.SSI.WhoIsResponsibleForInsur)
                .mandatoryOrCleanup(isOther(), FIELDS.MA.SSI.WhoIsResponsibleForInsurOther)
                .mandatoryOrCleanup(isMyselfOrOther(), FIELDS.MA.SSI.DoYouWantHCMHelpInsur)
                .mandatoryOrCleanup(ifNoHelp(), FIELDS.MA.SSI.HowIWillArrangeInsur)
        },

        nextQuestionId: () => nextQuestionId
    };

};