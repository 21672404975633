export const LOGIN = 'login';
export const LOGOUT = 'logout';

export const START_NEW_APPLICATION = 'start_new_application';
export const SET_DATA = 'set_Data';
export const EDIT_FIELD = 'edit_field';
export const SET_EXISTING_APPLICATIONS = 'SET_EXISTING_APPLICATIONS'

export function login(user, apiExtra) {
    return {
        type: LOGIN,
        user,
        apiExtra
    }
}

export function logout() {
    return {
        type: LOGOUT
    }
}
export const FCADISMISSED = 'FCADismissed'
export function dismissFCA(){
    return {
        type:FCADISMISSED
    }
}

export function setExistingApplications(data, nextApplicationId){
    return {
        type:SET_EXISTING_APPLICATIONS,
        data,
        nextApplicationId
    };
}

export function startNewApplication(applicationId, data) {
    return {
        type: START_NEW_APPLICATION,
        applicationId,
        data
    }
}

export const SET_APPLICATION_ID = 'set_application_id'
export function setApplicationId(applicationId){
   // console.log('actions setApplicationId',applicationId)
    return {
        type:SET_APPLICATION_ID,
        applicationId
    }
}

export function setData(data, preventPersist) {
    return {
        type:SET_DATA,
        data,
        preventPersist
    }
}

export const SET_USER_DATA = 'set_user_data'
export function setUserData(user){
    return {
        type:SET_USER_DATA,
        user
    }
}

export const UPDATE_PROGRESS = 'updateProgress';
export function updateProgress(progress) {
    return {
        type: UPDATE_PROGRESS,
        progress
    }
}


export const REQUEST_APPLICATION_DATA = 'request_application_data';
export function requestApplicationData(applicationId) {
    return {
        type: REQUEST_APPLICATION_DATA,
        applicationId
    }
}
export const RECEIVE_APPLICATION_DATA = 'receive_application_data';
export function receiveApplicationData(data) {
    return {
        type: RECEIVE_APPLICATION_DATA,
        data
    }
}

export const SAVED_APPLICATION = 'saved_application';
export function savedApplication(when, failures, error) {
    return {
        type:SAVED_APPLICATION,
        when,
        failures,
        error
    }
}

export const SAVED_USER = 'saved_user';
export function savedUser(when){
    return {
        type:SAVED_USER,
        when
    }
}