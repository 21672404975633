import { connect } from 'react-redux';
import {
    setData,
    updateProgress,
    setUserData,
    setApplicationId
} from '../redux/actions';
import Application from "./Application";
import {Adapter} from "../util/models";

const mapStateToProps = (state, ownProps) => ({
    data: state.data,
    user:state.user,
    adapter: new Adapter(state.data),
    applicationId:state.applicationId,
    lastSavedApplication:state.lastSavedApplication
});

const mapDispatchToProps = dispatch => ({
    setData: (data, preventPersist) => dispatch(setData(data, preventPersist)),
    setUserData: user => dispatch(setUserData(user)),
    updateProgress: (progress) => dispatch(updateProgress(progress)),
    setApplicationId: (applicationId) => dispatch(setApplicationId(applicationId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Application)
