import React from 'react';
import PropTypes from "prop-types";
import Field from './Field';

class FieldTextArea extends Field {
    static propTypes = {
        label: PropTypes.string,
        labelType: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        hide: PropTypes.bool,
        disabled: PropTypes.bool,
        tooltip: PropTypes.string
    };

    static defaultProps = {
        value: null,
        hide: false,
        disabled: false
    };


    render() {
        if (!this.show())
            return null;

        if (this.props.hide) { 
            return null; 
        }

        const label = this.label();

        return (
            <React.Fragment>

                <div className="form-group">

                    {
                        label != null &&
                        <label className={this.getLabelClassNameFromType()}>{label}</label>
                    }

                    <textarea
                        disabled={this.props.disabled}
                        className={"form-control" + this.validityClasses()}
                        id={this.props.field}
                        placeholder={this.props.placeholder}
                        value={this.props.value || ''}
                        onChange={this.handleChange}
                    />
                    {
                        this.renderError()
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default FieldTextArea;
