import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import {UnroutedFormButton} from "../form/components/FormButton";

export function confirmAction(what, handler) {
    confirmAlert({
        customUI: ({ onClose }) => (
            <div className='card'>
                <div className='card-body'>
                    {what}
                    <div className="confirm-buttons text-center">
                        <UnroutedFormButton text='Yes' onClick={() => { onClose(); handler(); }}/>
                        <UnroutedFormButton secondary text='No' onClick={onClose}/>
                    </div>
                </div>
            </div>
        )
    })
}