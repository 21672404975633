import React from 'react';
import PropTypes from "prop-types";
import Field from './Field';
import { validateAllFields, validateSingleField } from "../../util/validate";

import { extractDesignation, extractFieldName } from "common-hypotheca";

class FieldGroup extends Field {

    constructor(props) {
        super(props);
        this.state = {};
    }


    raiseBeforeEdit = () => {
        if (this.props.beforeEdit) {
            this.props.beforeEdit();
        }
    }


    buildProps = (field, props) => {

        let newProps = {
            value: this.getGroupFieldValue(field),
            handleFieldFilled: (applicantId, field, value) => this.handleGroupFieldFilled(field, value),
            fieldsContainer: this.groupFieldContainer(),
            error: this.state.validationContext === undefined ? undefined : this.state.validationContext.error(field),
            valid: this.state.validationContext === undefined ? false : this.state.validationContext.isValid(field),
            disabled: this.props.disabled,
            designation: extractDesignation(field)
        };

        if (typeof field === 'object') {
            if (field.options !== undefined) {
                if (props.options === undefined) {
                    newProps.options = field.options;
                }

                if (props.items === undefined) {
                    newProps.items = field.options;
                }
            }

            if (field.frontendLabel !== undefined && props.label === undefined) {
                newProps.label = field.frontendLabel;
            }

            if (field.label !== undefined && props.label === undefined) {
                newProps.label = field.label;
            }

            if (field.name) {
                newProps.field = field.name;
            }
        }

        return newProps;
    };

    valid = () => {
        return validateAllFields(this, this.props.validate, () => this.props.item);
    };

    renderChildren = () => {
        return React.Children.map(this.props.children, child => {
            if (child) {
                if (child.props.field) {
                    return React.cloneElement(child, this.buildProps(child.props.field, child.props));
                } else if (child.props.fields) {

                    let props = {
                        ...child.props,
                        fields: child.props.fields.map(field => this.buildProps(field, {}))
                    };

                    return React.cloneElement(child, props);
                }

                return child;
            }
        });
    };

    groupFieldContainer = () => {
        return this.props.item;
    };

    getGroupFieldValue = (field) => {
        if (!field) {
            debugger;
        }
        return this.groupFieldContainer()[extractFieldName(field)];
    };

    handleGroupFieldFilled = (field, value) => {
        this.raiseBeforeEdit();
        this.props.item[extractFieldName(field)] = value;
        this.props.handleFieldFilled(null, this.props.field, this.props.value);
        validateSingleField(field, this, this.props.validate, () => this.groupFieldContainer());
    };

    render() {

        if (this.props.show !== undefined && !this.props.show) {
            return null;
        }

        return <div className={this.props.className || ''}>
            {this.renderChildren()}
        </div>

    }
}

FieldGroup.propTypes = {
    className: PropTypes.string,
    field: PropTypes.string.isRequired,
    handleFieldFilled: PropTypes.func.isRequired,
    value: PropTypes.any,
    item: PropTypes.any,
    validate: PropTypes.func,
    beforeEdit: PropTypes.func,
};

export default FieldGroup;
