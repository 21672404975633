export const _REPAYMENT_PREFERENCE_TYPE = {
    NO: 'no',
    SOME: 'some',
    MUCH: 'much'
};

_REPAYMENT_PREFERENCE_TYPE.OPTIONS = [
    {
        code: _REPAYMENT_PREFERENCE_TYPE.NO,
        text: 'I don\'t plan on making any'
    },
    {
        code: _REPAYMENT_PREFERENCE_TYPE.SOME,
        text: 'I would like to make some, but not more than 10% per annum'
    },
    {
        code: _REPAYMENT_PREFERENCE_TYPE.MUCH,
        text: 'I would like to pay off as much as possible'
    },
];
