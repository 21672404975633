import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";

import {
    FIELDS,
} from "common-hypotheca";


export default function AskForAssetList(parent, nextQuestionId) {


    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldRadioYesNo
                    {...parent.getField(FIELDS.MS.AS.HasOtherAssets)}
                />

            </QuestionBlock>,
        validate: (context) => {

            context.mandatory(FIELDS.MS.AS.HasOtherAssets);

            if (parent.getValue(FIELDS.MS.AS.HasOtherAssets) === false) {
                context.cleanupIrrelevantField(FIELDS.MS.AS.OtherAssets);
            }

        },

        nextQuestionId: () => {
            return nextQuestionId(parent.getValue(FIELDS.MS.AS.HasOtherAssets) === true);
        }
    };

    
};