/**
 * Application events that could be generated by frontend.
 * @private
 */
export const _EVENTS = {
    APPLICATION_STARTED: 'application_started',

    /** Credit history or residence screening failed */
    SCREENING_FAIL: 'screening_fail',

    SCREENING_INITIAL_ASSESSMENT_FILLED_IN: 'screening_initial_assessment_filled_in',
    REQUEST_EXPERT_ADVICE: 'request_expert_advice',
    FIRST_DOCUMENT_UPLOADED: 'first_document_uploaded',

    // Unused?
    ESTIMATE_BAD: 'estimate_bad',
    SCREENING_FAIL_CREDIT: 'screening_fail_credit',
    SCREENING_FAIL_CITIZENSHIP: 'screening_fail_citizenship',
    SCREENING_FAIL_HOLIDAY_LET: 'screening_fail_holidaylet',
    CONSENT_FORMAL: 'consent_formal',
};