import {COGNITO_ATTRIBUTE_S3_PATH, SECTION} from "common-hypotheca";
import {API, Auth, Storage} from "aws-amplify";
import {APINAME, APIPATH} from "../constants";
import {migrateApplication} from "./models";
import {apiExtra} from "./wrap";

/**
 * Checks if a data-aware
 * @param props component props that will contain data
 * @returns {boolean} if component should redirect to welcome instead of history
 */
export function loadingApplication(props) {
    return props.data === undefined;
}

export function tryLoadPreviousApplicationOrWelcome(props) {
    // Everything is all right - we already have application
    if (props.data)
        return null;

    // Already loading
    if (props.applicationId !== undefined && props.applicationId !== null)
        return null;

    // No previous application recorded - redirect to welcome
    // === undefined || === null as 0 is normal id.
    if (props.user.lastApplicationId === undefined || props.user.lastApplicationId === null) {
        props.history.push('/welcome');
        return null;
    }

    props.setApplicationId(props.user.lastApplicationId);

    // Load application asynchronously
    API.get(APINAME, `${APIPATH.APPLICATION}/${props.user.lastApplicationId}`, apiExtra())
        .then(response => {
            let data = response.Item;
            migrateApplication(data);
            props.setData(data, true);
        })
        .catch(error => {
            console.log(error)
        });

    return null;
}

export function forgotCredentialsLink() {
    return '/app/forgotcredentials';
}

export function forgotCredentialsEnterCodeLink() {
    return '/app/forgotcredentials/code';
}

export function registerLink() {
    return '/app/register';
}

export function dashboardLink(sectionId, applicantId) {
    if (sectionId === SECTION.SITUATION && applicantId === undefined) {
        applicantId = 0;
    }

    return applicantId === undefined || applicantId === null
        ? '/app/dashboard/' + sectionId
        : '/app/dashboard/' + sectionId + '/' + applicantId;
}

export function sectionPartLink(sectionId, partId, applicantId) {
    if (sectionId === SECTION.SITUATION) {
        return '/app/situation/' + applicantId + '/' + sectionId + '/' + partId;
    } else {
        return '/app/application/' + sectionId + '/' + partId;
    }
}

export function welcomeLink() {
    return '/app/welcome';
}

export function myApplicationsLink() {
    return '/app/myapplications';
}

export function accountLink() {
    return '/app/account';
}

export function infoPageLink() {
    return 'https://www.holidaycottagemortgages.co.uk/';
}

export function starterGuideLink() {
    return 'https://www.holidaycottagemortgages.co.uk/starter-guide/';
}

export function informationChecklistLink() {
    return 'https://www.holidaycottagemortgages.co.uk/information-checklist/';
}

export function termsOfUseLink() {
    return 'https://www.holidaycottagemortgages.co.uk/terms-of-use/';
}

export function privacyPolicyLink() {
    return 'https://www.holidaycottagemortgages.co.uk/privacy-policy/';
}

export function cookiePolicyLink() {
    return 'https://www.holidaycottagemortgages.co.uk/cookie-policy/';
}

export function contactUsLink() {
    return 'https://www.holidaycottagemortgages.co.uk/contact-us/';
}

export function openIntercom() {
    /*
    window.Intercom('show');
     */
}

export function faqLink() {
    return 'https://www.holidaycottagemortgages.co.uk/faq/'
}

function apiExtraS3Path(user) {
    let result = user.attributes[COGNITO_ATTRIBUTE_S3_PATH];
    if (result === undefined)
        return '';
    else
        return result;
}

/**
 * @param user
 * @param key
 * @returns {string} returns s3 file path for specified cognito user and key.
 */
export function s3FilePath(user, key) {
    return apiExtraS3Path(user) + key;
}

export function s3FileOptions(user) {
    let extraPath = apiExtraS3Path(user);

    return {
        level: extraPath === '' ? 'private' : 'public',
        customPrefix: {
            public: '',
            protected: 'protected/',
            private: 'private/'
        }
    }
}

/**
 * Starts the process of getting download URLs for files in the bucket.
 *
 * @param files Array of objects having 'key' property.
 * @param fileUrls Array to put urls for file keys into.
 */
export function initializeDownloadUrls(files, fileUrls) {
    if (files === null || files === undefined || !files.length) {
        return Promise.resolve();
    }

    return Auth
        .currentAuthenticatedUser()
        .then(user => {

        return Promise.all(files.map((file) => {

            // Cleanup bogus URLs from application data which was stored there by previous implementation
            // reusing application object to store URLs also
            delete file['url'];

            return new Promise(function (resolve) {
                Storage
                    .get(s3FilePath(user, file.key),{
                        ...s3FileOptions(user),
                        expiry: 60 * 60 * 2,
                    })
                    .then((result) => {
                        fileUrls[file.key] = result;
                        resolve(undefined);
                    })
                    .catch((err) => {
                        console.log(err);
                        // Need still notification for upper code that some urls could have been resolved
                        resolve(undefined);
                    });
            });
        }))
    });
}