import { connect } from 'react-redux';
import Welcome from "./Welcome";
import {startNewApplication,setExistingApplications,dismissFCA,setUserData} from "../redux/actions";

const mapStateToProps = (state, ownProps) => ({
    user:state.user,
    FCADismissed:state.FCADismissed,
    existingApplications: state.existingApplications,
    nextApplicationId: state.nextApplicationId
});

const mapDispatchToProps = dispatch => ({
    startNewApplication: (type,id) => dispatch(startNewApplication(type,id)),
    setExistingApplications: (data, nextApplicationId) => dispatch(setExistingApplications(data, nextApplicationId)),
    dismissFCA: () => dispatch(dismissFCA()),
    setUserData: user => dispatch(setUserData(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Welcome)
