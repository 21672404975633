import { API } from 'aws-amplify';
import React from 'react';
import { APINAME, APIPATH } from '../constants';
import ExistingApplicationSummary from './ExistingApplicationSummary';
import { Adapter } from "../util/models";
import Header from '../welcome/Header'
import { FormButton } from '../form/components';
import HelpIcon from "common-form/components/HelpIcon";
import ApplicationStarter from "./ApplicationStarter";
import Loading from "../form/icons/Loading";
import {
    APP_STATUS,
    SECTION,
    model
} from "common-hypotheca";
import {dashboardLink} from "../util/links";
import {confirmAction} from "../util/confirmAction";

class MyApplications extends ApplicationStarter {
    firstNonCompleteSectionId = (adapter) => {
        for (let sectionId of model.sectionOrdering) {
            if (adapter.sectionAvailable(sectionId) && !adapter.sectionComplete(sectionId)) {
                return sectionId;
            }
        }

        return SECTION.SCREENING;
    };

    firstNonCompleteApplicantId = (adapter, sectionId) => {
        if (!model[sectionId].hasApplicants)
            return undefined;

        for (let applicantId of adapter.applicantIds()) {
            if (!adapter.sectionComplete(sectionId, applicantId))
                return applicantId;
        }
    };

    handleTableClick = application => {
        this.props.setApplicationId(application.applicationId);
        this.props.setData(application, true);

        // Cannot reuse one from redux store yet
        let adapter = new Adapter(application);

        let sectionId = this.firstNonCompleteSectionId(adapter);

        this.props.history.push(dashboardLink(sectionId, this.firstNonCompleteApplicantId(adapter, sectionId)));
    };

    handleDeleteClick = async applicationId => {
        let exApps = this.props.existingApplications;
        exApps.map(exAp => {
            if (exAp.applicationId === applicationId) {
                exAp.active = false;
                exAp.appStatus = APP_STATUS.DELETED;
                exAp.deactivateReason = 'User deleted from My Applications';
            }
            return exAp
        });
        await API.post(APINAME, `${APIPATH.APPLICATION}/${applicationId}`, {
            body: exApps.filter(exAp => exAp.applicationId === applicationId)[0]
        });

        await this.reloadApplications();
    };

    render() {

        return (
            <div className="container">
                <div className='row'>
                    <div className='col-12'>
                        <Header {...this.props}/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-10 offset-sm-1'>
                        <div className="card application-list">
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-12'>
                                        <h1 className='oos-title'>My applications</h1>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope='col'>
                                                        Applications
                                                </th>
                                                    <th scope='col'>
                                                        Started
                                                </th>
                                                    <th scope='col'>
                                                        Last Edited
                                                </th>
                                                    <th scope='col'>
                                                        Journey Stage
                                                </th>
                                                    <th scope='col'>
                                                        Postcode
                                                </th>
                                                    <th></th>
                                                    <th><HelpIcon>Due to regulations, you can only delete applications prior to requesting Expert Advice</HelpIcon></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.props.existingApplications &&
                                                    this.props.existingApplications.map((exAp, i) => {
                                                        let adapt = new Adapter(exAp);
                                                        return (
                                                            <ExistingApplicationSummary
                                                                key={i}
                                                                number={i + 1}
                                                                adapter={adapt}
                                                                appStatus={adapt.appStatus()}
                                                                application={exAp}
                                                                onClick={() => this.handleTableClick(exAp)}
                                                                onDeleteClick={() => confirmAction('Are you sure you want to remove this application?', () => this.handleDeleteClick(exAp.applicationId))}
                                                            />
                                                        )
                                                    })
                                                }
                                                {
                                                    this.props.existingApplications &&
                                                    !this.props.existingApplications.length &&
                                                    <tr >
                                                        <td colSpan='7'>
                                                            <span>You have no applications in progress.</span>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    this.props.existingApplications &&
                                                    <tr>
                                                        <td colSpan='7'>
                                                            <FormButton
                                                                text='New application'
                                                                secondary={this.props.existingApplications.length > 0}
                                                                rightMaterialIcon='add'
                                                                onClick={this.startApplication}/>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    !this.props.existingApplications &&
                                                    <tr >
                                                        <td colSpan='7'>
                                                            <Loading/>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>

                                        </table>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default MyApplications;
