import {API, Auth, Storage} from 'aws-amplify';
import React, { Fragment } from "react";

import { APINAME, APIPATH } from "../../../../constants";

import { Tick } from '../../../../form/icons'
import ControlButtons from "../../../../form/ControlButtons";

import {
    SECTION,
    MY_APPLICATION,
    EVENTS
} from "common-hypotheca";

import {
    dashboardLink,
    informationChecklistLink, initializeDownloadUrls, s3FileOptions, s3FilePath
} from "../../../../util/links";

import NonFormSectionPart from "../../../../form/NonFormSectionPart";
import Loading from "../../../../form/icons/Loading";

class DocumentUpload extends NonFormSectionPart {
    static defaultProps = {
        sectionId: SECTION.MY_APPLICATION,
        partId: MY_APPLICATION.DOC_UPLOAD
    };

    constructor(props) {
        super(props);

        const documents = props.data[SECTION.MY_APPLICATION].parts.filter(part => part.id === MY_APPLICATION.DOC_UPLOAD)[0].documents;

        // convert documents from single to array
        documents.forEach(d => {

            if (!d.documents) {
                d.documents = [];
            }

            if (d.filename) {
                d.documents.push({
                    accepted: d.accepted,
                    filename: d.filename,
                    key: d.key
                });

                delete d.filename;
                delete d.accepted;
                delete d.key;
            }

        });

        this.state = {
            documents: documents,
            fileUrls: {},
            uploading: false,
            uploadError: undefined
        };
    }

    componentDidMount = () => {
        this.loadSignedURLS();
        let data = this.props.data;
        data[SECTION.MY_APPLICATION].parts.filter(part => part.id === MY_APPLICATION.DOC_UPLOAD)[0].isComplete = false;
        if (!this.state.documents.some(doc => doc.documents.some(d => !d.filename))) {
            data[SECTION.MY_APPLICATION].parts.filter(part => part.id === MY_APPLICATION.DOC_UPLOAD)[0].isComplete = true;
        }
        this.props.setData(data);
    };

    loadSignedURLS = () => {
        let documents = this.state.documents;

        let oneDimensionalDocuments = [];

        for (let documentRoot of documents) {
            oneDimensionalDocuments = oneDimensionalDocuments.concat(documentRoot.documents);
        }

        initializeDownloadUrls(oneDimensionalDocuments, this.state.fileUrls)
            .then (() => {
                // re-render when file urls were returned by s3
                this.forceUpdate();
            });
    };

    removeDocument = (documentRoot, document) => {

        const documents = this.state.documents;
        documentRoot.documents.splice(documentRoot.documents.indexOf(document), 1);

        this.setState({
            documents: documents
        });

        this.updated(documents);

    };

    onChange(e, document) {

        this.setState({
            uploading: true,
            uploadError: undefined
        });

        // e.target.files is not an array, so we need to convert it
        const files = new Array(e.target.files.length).fill({}).map( (v, i) => e.target.files[i]);

        Auth.currentAuthenticatedUser()
            .then(user => {

                let fileNames = {};
                let storagePromises = files.map(file => {

                    let filename = file.name;
                    let d = new Date();
                    let key = `${d.getTime()}_${filename}`;

                    fileNames[key] = filename;

                    return Storage
                        .put(s3FilePath(user, key), file, {
                            ...s3FileOptions(user),
                            contentType: file.type
                        })
                        .then(async result => {

                            return {
                                key: key,
                                filename: filename,
                                uploadTime: new Date().getTime(),
                                success: true
                            }
                        })
                        .catch(err => {
                            return {
                                key: key,
                                filename: filename,
                                uploadTime: new Date().getTime(),
                                success: false,
                                error: err
                            }
                        })
                });

                Promise
                    .allSettled(storagePromises)
                    .then((results) => {

                        let documents = this.state.documents;
                        let root = documents.find(d => d.title === document.title);
                        let failed = false;
                        let err;
                        let previousDocumentCount = documents.map(d => d.documents.length).reduce((a,b) => a+b, 0);

                        results.forEach((result) => {
                            root.documents.push(result.value);

                            if (!result.value.success) {
                                failed = true;
                                err = result.value.error
                            }
                        });

                        this.setState({
                            documents
                        });

                        if (failed) {
                            if (results.length > 1) {
                                this.setState({
                                    uploadError: 'At least one file upload finished with error' +  (err && err.message ? ': ' + err.message : '')  + '. Can you please check your connection and try again.'
                                })
                            } else {
                                this.setState({
                                    uploadError: 'Unable to upload file' +  (err && err.message ? ': ' + err.message : '')  + '. Can you please check your connection and try again.'
                                })
                            }
                        } else if (previousDocumentCount === 0) {
                            this.sendFirstUploadNotification();
                        }
                        this.updated();
                    })
                    .catch(err => {
                        this.setState({
                            uploadError: 'File upload failed for unknown reason',
                            uploading: false
                        });
                    });
                    // Finally not available on MS Edge
            });

    }

    updated = docs => {
        if (!docs) {
            docs = this.state.documents
        }

        let data = this.props.data;
        data[SECTION.MY_APPLICATION].parts.filter(part => part.id === MY_APPLICATION.DOC_UPLOAD)[0].documents = docs;

        data[SECTION.MY_APPLICATION].parts.filter(part => part.id === MY_APPLICATION.DOC_UPLOAD)[0].isComplete = 
            !docs.some(d => !d.documents.some(d => d.filename));

        this.props.setData(data);
        this.loadSignedURLS()

        this.setState({
            uploading: false
        });
    };

    sendFirstUploadNotification = () => {

        let data = this.props.data;
        API.post(APINAME, APIPATH.TRIGGER, {
            body: {
                applicationId: data.applicationId,
                event: EVENTS.FIRST_DOCUMENT_UPLOADED,
                data: {},
                documents: this.state.documents
            }
        })
        .then(response => {
            // Add your code here
        })
        .catch(error => {
            console.log(error.response);
        });
    };

    nextPage = () => {
        this.props.history.push(dashboardLink(SECTION.MY_APPLICATION));
    };

    render = () => {
        return (
            <Fragment>
                <div className="row">
                    <div className={`col-sm-12 col-md-8 offset-md-2`}>
                        <div className="card document-upload">
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className='col-form-label'>Verification document upload</div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        {
                                            this.renderReadOnlyAlert()
                                        }
                                        <p>
                                            We require certain documents to verify your application, such as proof of identification, income and deposit.
                                            Your advisor has identified the following types of document for you to now upload using the function below.
                                        </p>

                                        <p>
                                            You can upload a single file, or multiple files for each document type. If you do not yet have a particular document, then don’t worry, just skip that category and your advisor will arrange the collection of this document separately.
                                        </p>

                                        <p>
                                            Apart from your passport scan, where possible, please try to upload original PDF files, for example, the type you get if you download a bank statement directly from your online bank provider.
                                        Please refer to the <a href={informationChecklistLink()} target="_blank" rel="noopener noreferrer">Information Checklist</a> for more details.
                                        </p>

                                        <hr />

                                    </div>
                                </div>
                                {
                                    this.state.documents.map((documentRoot, i) =>
                                        <div key={i} className='row upload-row'>
                                            <div className='col-8'>
                                                <div className='document-title'>{documentRoot.title}</div>
                                            </div>

                                            <div className='col-4'>
                                                {
                                                    !this.readOnly() &&
                                                    <span>
                                                        <input type="file"
                                                            key={'uploader-' + i + '-' + this.state.uploading}
                                                            className="doc-upload-file-input"
                                                            id={`uploader-${i}`}
                                                            multiple
                                                            accept='application/pdf'
                                                            onClick={() => this.value = null}
                                                            onChange={(e) => this.onChange(e, documentRoot)}
                                                        />
                                                        <label htmlFor={`uploader-${i}`} className="doc-upload-file-label"> <span>  add</span></label>
                                                    </span>
                                                }
                                            </div>

                                            {
                                                documentRoot.documents.filter((element) => element.success).map(document =>

                                                    <div className='col-12  document-row' key={document.key}>
                                                        <div className="row no-gutters">

                                                        <div className="col-8">
                                                            {
                                                                document.accepted && <Tick />
                                                            }
                                                            <div className='document-filename text-truncate'>{document.filename}</div>
                                                        </div>

                                                        <div className="col-4 d-flex justify-content-end justify-content-sm-start">
                                                            <a href={this.state.fileUrls[document.key]} target='_blank' className='btn-view mr-2' />
                                                            {
                                                                !this.readOnly() &&
                                                                <span className='btn-remove'
                                                                    onClick={() => this.removeDocument(documentRoot, document)}>
                                                                </span>
                                                            }

                                                        </div>

                                                        </div>

                                                    </div>
                                                )
                                            }

                                        </div>
                                    )
                                }

                                {
                                    this.state.uploading &&
                                    <div className='row'>
                                        <div className='col'>
                                            <Loading/>
                                        </div>
                                    </div>
                                }

                                {
                                    this.state.uploadError &&
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div key='error-message' className="invalid-feedback">{this.state.uploadError}</div>
                                        </div>
                                    </div>
                                }

                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <ControlButtons nextPage={this.nextPage} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default DocumentUpload;
