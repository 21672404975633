import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldLabel from "common-form/components/FieldLabel";
import { LABEL_TYPE } from "common-form/components/Field";
import FieldInput from "common-form/components/FieldInput";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldGroupAddable from "../../../../../form/components/FieldGroupAddable";
import {FieldDropdown} from "../../../../../form/components/dropdown";

import {
    FIELDS,
    INCOME_TYPE,
    PENSION_TYPE,
    extractFieldName
} from "common-hypotheca";


export default function AnotherIncomeList(parent) {


    function isIncomeType(container, type) {
        const ot = container[extractFieldName(FIELDS.MS.EI.OtherIncomeType)];
        return ot === type;
    }

    function getIncomeType(container) {
        const incomeType = container[extractFieldName(FIELDS.MS.EI.OtherIncomeType)];
        return incomeType && INCOME_TYPE.OPTIONS.find(i => i.code === incomeType).text || ' not set';
    }

    function isPensionTypeOther(container) {
        return isIncomeType(container, INCOME_TYPE.PENSION) && 
        container[extractFieldName(FIELDS.MS.EI.OtherIncomePensionType)] === PENSION_TYPE.OTHER;
    }

    return {
        block: (params)=>
            <QuestionBlock {...params}>

                <FieldLabel
                    label="Please tell us more about each type of other income you have"
                />

                <FieldGroupAddable
                    ref={params.groupAddableRef}
                    addButtonLabel="Add other income"
                    allowDeleteAll
                    deleteButtonLabel="Remove"
                    editButtonLabel="Edit"
                    saveButtonLabel="Save other income"
                    itemDescriber={(container) => getIncomeType(container)}
                    validate={(context) => {

                        context
                        .mandatory(FIELDS.MS.EI.OtherIncomeType)
                        .mandatory(FIELDS.MS.EI.OtherIncomeAnnualRegularAmt)
                        .mandatory(FIELDS.MS.EI.OtherIncomeGuaranteed)
                        ;

                        context.mandatoryOrCleanup(
                            context.fieldValue(FIELDS.MS.EI.OtherIncomeType) === INCOME_TYPE.PENSION,
                            FIELDS.MS.EI.OtherIncomePensionType
                        );

                        context.mandatoryOrCleanup(
                            context.fieldValue(FIELDS.MS.EI.OtherIncomeType) === INCOME_TYPE.PENSION && 
                            context.fieldValue(FIELDS.MS.EI.OtherIncomePensionType) === PENSION_TYPE.OTHER,
                            FIELDS.MS.EI.OtherIncomePensionTypeOther
                        );
                      
                        context.mandatoryOrCleanup(
                            context.fieldValue(FIELDS.MS.EI.OtherIncomeType) === INCOME_TYPE.STATE_BENEFITS,
                            FIELDS.MS.EI.OtherIncomeBenefitsDesc
                        );

                        context.mandatoryOrCleanup(
                            context.fieldValue(FIELDS.MS.EI.OtherIncomeType) === INCOME_TYPE.OTHER,
                            FIELDS.MS.EI.OtherIncomeTypeOther
                        );

                    }}
                    {...parent.getField(FIELDS.MS.EI.OtherIncomes)}
                >

                    <FieldDropdown
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.EI.OtherIncomeType}
                    >
                    </FieldDropdown>

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        show={(container) => isIncomeType(container, INCOME_TYPE.OTHER)}
                        field={FIELDS.MS.EI.OtherIncomeTypeOther}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.EI.OtherIncomeAnnualRegularAmt}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.EI.OtherIncomeGuaranteed}
                    />

                    <FieldDropdown
                        labelType={LABEL_TYPE.Small}
                        show={(container) => isIncomeType(container, INCOME_TYPE.PENSION)}
                        field={FIELDS.MS.EI.OtherIncomePensionType}
                    >
                    </FieldDropdown>

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        show={(container) => isPensionTypeOther(container)}
                        field={FIELDS.MS.EI.OtherIncomePensionTypeOther}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        show={(container) => isIncomeType(container, INCOME_TYPE.STATE_BENEFITS)}
                        field={FIELDS.MS.EI.OtherIncomeBenefitsDesc}
                    />

                </FieldGroupAddable>

            </QuestionBlock>,

        validate: (context) => {

            if (!context.hasItems(FIELDS.MS.EI.OtherIncomes)) {
                context.addError(null, null, 'Please enter an item or go back to the previous screen and change your selection.');
            }
            if (parent.groupAddableRef && !parent.groupAddableRef.current.valid()) {
                context.addError(FIELDS.MS.EI.OtherIncomes, null, '');
            }

        },
        
        nextQuestionId: parent.afterAnotherIncomeList
    };

}