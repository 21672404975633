import React from 'react';
import PropTypes from "prop-types";
import Field from './Field';

class FieldRadioYesNo extends Field {
    static propTypes = {
        label: PropTypes.string,
        field: PropTypes.any.isRequired,
        handleFieldFilled: PropTypes.func,
        value: PropTypes.any,
        show: PropTypes.any,
        moveOn: PropTypes.number,
        applicantId:PropTypes.number,
        textYes: PropTypes.string,
        textNo: PropTypes.string,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        value: null,
        moveOn: null,
        applicantId: null,
        disabled: false
    };

    render() {
        if (!this.show())
            return null;

        let label = this.label();

        let options = [
            {
                value: true,
                text: this.props.textYes === undefined ? 'Yes' : this.props.textYes
            },
            {
                value: false,
                text: this.props.textNo === undefined ? 'No' : this.props.textNo
            }
        ];

        return (
            <div className='form-group mb-4'>
                <div className={'label label-radio-yes-no' + this.validityClasses() + ' ' + this.getLabelClassNameFromType()}>{label}</div>
                <div className={'hypo-inline-radios' + this.validityClasses()}>
                    {
                        options.map(option => {
                                let name = this.props.field + '-' + this.props.applicantId;
                                let id = name + '-' + option.value;

                                // refs are used here as sometimes label click wasn't propagated to radio click, now doing
                                // it manually.
                                let ref = React.createRef();

                                return (
                                    <div key={option.value}
                                         className="form-check form-check-inline custom-radio">
                                        <input
                                            disabled={this.props.disabled}
                                            type="radio"
                                            id={id}
                                            className="form-check-input"
                                            name={name}
                                            value={option.value}
                                            onChange={() => {
                                                if (this.props.onValueChange) {
                                                    this.props.onValueChange(option.value);
                                                } else {
                                                    return this.props.handleFieldFilled(this.props.applicantId, this.props.field, option.value, this.props.moveOn)
                                                }
                                            }}
                                            checked={option.value === this.props.value}
                                            ref={ref}
                                        />
                                        <label
                                            className="form-check-label"
                                            onClick={(e) =>{ref.current.click(); e.preventDefault()}}
                                            htmlFor={id}>{option.text}</label>
                                    </div>
                                );
                            }
                        )}
                </div>
                {
                    this.renderError()
                }
            </div>
        );
    }
}

export default FieldRadioYesNo;
