import {connect} from 'react-redux';
import {Route} from 'react-router';
import * as React from "react";
import SignIn from './auth/SignIn';

const routeMapStatesToProps = (state, ownProps) => ({
    //render: !state.loggedIn ? () => (<Amplify.Authenticator/>) : undefined,
    render: !state.loggedIn ? () => (<SignIn/>) : undefined,
    component: state.loggedIn ? ownProps.component : undefined
});

const RouteRequireLogin = connect(routeMapStatesToProps)(Route);

export default RouteRequireLogin;