import  React from "react";
import ApplicationStatus from '../sections/applicationStatus/ApplicationStatus';
import SectionParts from "./SectionParts";
import MyApplicationSupplement from '../sections/myApplication/MyApplicationSupplement'
import PropTypes from "prop-types";
import {
    SECTION,
    model
} from 'common-hypotheca';

class Panel extends React.Component {
    getSupplement = (sectionId) => {
        switch (sectionId) {
            case SECTION.MY_APPLICATION:
                return <MyApplicationSupplement {...this.props} />;
            default:
                return null
        }
    };

    getSectionShownOnDashboard = sectionId => {
        switch (sectionId) {
            case SECTION.APPLICATION_STATUS:
                return <ApplicationStatus {...this.props} />;
            default:
                return null
        }
    };

    render = () => {
        let sectionModel = model[this.props.sectionId];
      
        return (
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='row'>
                        <div className='col-12'>
                            <h5 className="card-title">
                                {this.props.journeyMobileButton}
                                {sectionModel.name}
                                {/* {describeSection(this.props.section, data, this.props.applicantId)} */}
                            </h5>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="card application-journey">
                                <div className="card-body dashboard">
                                    {
                                        sectionModel.hasParts
                                            && sectionModel.parts.length ?
                                            <SectionParts {...this.props} />
                                            :
                                            this.getSectionShownOnDashboard(this.props.sectionId)

                                    }
                                    {
                                        sectionModel.hasSupplement &&
                                        this.getSupplement(this.props.sectionId)
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    };
}

Panel.propTypes = {
    journeyMobileButton: PropTypes.any.isRequired
};

export default Panel;