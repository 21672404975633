import * as React from "react";

import QuestionBlock from "../../../../form/QuestionBlock";
import FieldDate from "common-form/components/FieldDate";
import FieldInput from "common-form/components/FieldInput";
import { LABEL_TYPE } from "../../../../form/components/Field";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldTextArea from "../../../../form/components/FieldTextArea";

import { isAgoLessThan } from "common-hypotheca/src/dates";

import {FIELDS} from "common-hypotheca";


export default function ExistingAsset(parent, nextBlockId) {

    function isPropertyDateNew() {
        return isAgoLessThan(parent.getValue(FIELDS.MP.PD.HolidayLetDateOfConstruction), 'year', 10);
    }

    function isCoveredByWarranty() {
        return parent.getValue(FIELDS.MP.PD.HolidayLetLessTenYrsWarranty) === true;
    }

    function isNotCoveredByWarranty() {
        return parent.getValue(FIELDS.MP.PD.HolidayLetLessTenYrsWarranty) === false;
    }


    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <div className="date-boxes">
                <FieldDate
                    {...parent.getField(FIELDS.MP.PD.HolidayLetDateOfConstruction)}
                    showDay={false}
                />
                </div>

                {
                    isPropertyDateNew() && 
                    <>

                    <FieldRadioYesNo
                        {...parent.getField(FIELDS.MP.PD.HolidayLetLessTenYrsWarranty)}
                        labelType={LABEL_TYPE.Small}
                    />

                    <FieldTextArea
                        show={() => isCoveredByWarranty()}
                        {...parent.getField(FIELDS.MP.PD.HolidayLetLessTenYrsWarrantyDescr)}
                        labelType={LABEL_TYPE.Small}
                    />

                    <FieldInput
                        show={() => isNotCoveredByWarranty()}
                        {...parent.getField(FIELDS.MP.PD.HolidayLetLessTenYrsWarrantyArchitect)}
                        labelType={LABEL_TYPE.Small}
                    />

                    </>
                }

            </QuestionBlock>,


        validate: (context) => {

            context
            .mandatory(FIELDS.MP.PD.HolidayLetDateOfConstruction)
            .date(FIELDS.MP.PD.HolidayLetDateOfConstruction, null, null, 1500, new Date().getFullYear())
            ;

            context
            .mandatoryOrCleanup(isPropertyDateNew(), FIELDS.MP.PD.HolidayLetLessTenYrsWarranty)
            .mandatoryOrCleanup(isPropertyDateNew() && isCoveredByWarranty(), FIELDS.MP.PD.HolidayLetLessTenYrsWarrantyDescr)
            .mandatoryOrCleanup(isPropertyDateNew() && isNotCoveredByWarranty(), FIELDS.MP.PD.HolidayLetLessTenYrsWarrantyArchitect)
            ;

        },

        nextQuestionId: () => nextBlockId()
    };

    
};