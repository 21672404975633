export const _TITLE_TYPE = {
    MR: 'MR',
    MRS: 'MRS',
    MISS: 'MISS',
    MS: 'MS',
    DR: 'DR',
    OTHER: 'other'
};

_TITLE_TYPE.OPTIONS = [
    {
        code: '',
        text: '',
    },
    {
        code: _TITLE_TYPE.MR,
        text: 'Mr'
    },
    {
        code: _TITLE_TYPE.MRS,
        text: 'Mrs'
    },
    {
        code: _TITLE_TYPE.MISS,
        text: 'Miss'
    },
    {
        code: _TITLE_TYPE.MS,
        text: 'Ms'
    },
    {
        code: _TITLE_TYPE.DR,
        text: 'Dr'
    },
    {
        code: _TITLE_TYPE.OTHER,
        text: 'Other (add)'
    },
];
