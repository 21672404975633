export const _PENSION_TYPE = {
    WORKPLACE: 'workplace',
    PERSONAL: 'personal',
    STATE: 'state',
    OTHER: 'other'
};

_PENSION_TYPE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: _PENSION_TYPE.WORKPLACE,
        text: 'Workplace pension'
    },
    {
        code: _PENSION_TYPE.PERSONAL,
        text: 'Personal pension'
    },
    {
        code: _PENSION_TYPE.STATE,
        text: 'State pension'
    },
    {
        code: _PENSION_TYPE.OTHER,
        text: 'Other (add)'
    }
];
