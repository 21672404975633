import {_OTHER} from "./other";

export const _LET_MARKETING = {
    PERSONAL_WEB_SITE: 'personal_website',
    PROFESSIONAL_LETTING_AGENT: 'professional_letting_agent',
    PORTALS_OR_ONLINE_TRAVEL_AGENTS: 'portals_or_online_travel_agents'
};

_LET_MARKETING.OPTIONS = [
    { code: _LET_MARKETING.PERSONAL_WEB_SITE, text: 'Personal website' },
    { code: _LET_MARKETING.PROFESSIONAL_LETTING_AGENT, text: 'Professional letting agent' },
    { code: _LET_MARKETING.PORTALS_OR_ONLINE_TRAVEL_AGENTS, text: 'Portals and Online Travel Agents' },
    { code: _OTHER, text: 'Other' },
];

export const _LET_MARKETING_AGENTS = {
    OPTIONS: [
        { code: 'cottages_com', text: 'Cottages.com'},
        { code: 'sykes_holiday_cottages', text: 'Sykes Holiday Cottages'},
        { code: 'holiday_cottage_group', text: 'Holiday Cottage Group'},
        { code: 'lakelovers_group', text: 'Lakelovers Group'},
        { code: _OTHER, text: 'Other'}
    ]
};

export const _LET_MARKETING_ONLINE_OR_TRAVEL_AGENTS = {
    OPTIONS: [
        { code: 'airbnb', text: 'Airbnb' },
        { code: 'home_away', text: 'HomeAway' },
        { code: 'trip_advisor', text: 'TripAdvisor' },
        { code: 'booking_com', text: 'Booking.com' },
        { code: 'expedia', text: 'Expedia' },
        { code: _OTHER, text: 'Other' },
    ]
};
