import * as React from "react";

import { 
    extractFieldName, 
    FIELDS, 
    OTHER 
} from "common-hypotheca";

import {
    validateEmploymentEarning
} from './validation';


import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldInput from "common-form/components/FieldInput";
import FieldGroup from "../../../../../form/components/FieldGroup";
import FieldLabel from "common-form/components/FieldLabel";
import { LABEL_TYPE } from "common-form/components/Field";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import { FieldDropdown } from "../../../../../form/components/dropdown";


export default function EmploymentEarning(parent) {

    return {

        block: (params) =>

            <QuestionBlock {...params}>

                <FieldGroup
                    ref={params.groupAddableRef}
                    item={parent.getCurrentEmployment()}
                    {...parent.getField(FIELDS.MS.EI.Employments)}
                    validate={(context) => {

                        validateEmploymentEarning(parent, context, parent.state.employmentIndex);

                    }}
                >

                    <FieldInput
                        field={FIELDS.MS.EI.BasicAnnualSal}
                    />

                    <FieldInput
                        field={FIELDS.MS.EI.GuaranteAnnualCarAllow}
                    />

                    <FieldDropdown
                        field={FIELDS.MS.EI.PayFrequency}
                    />

                    <FieldInput
                        field={FIELDS.MS.EI.PayFrequencyOther}
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.PayFrequency)] === OTHER}
                    />

                    <FieldDropdown
                        field={FIELDS.MS.EI.HowAreYouPaid}
                    />

                    <FieldInput
                        field={FIELDS.MS.EI.HowAreYouPaidOther}
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.HowAreYouPaid)] === OTHER}
                    />

                    <FieldInput
                        field={FIELDS.MS.EI.NetMonthlyPayment}
                    />

                    <FieldLabel
                        label= {`Please tell us about any other employment income below`}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.EI.AnnualRegularOT}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.EI.RegularOTGuaranteed}
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.AnnualRegularOT)] > 0}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.EI.AnnualRegularBonus}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.EI.RegularBonusGuaranteed}
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.AnnualRegularBonus)] > 0}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.EI.AnnualRegularComm}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.EI.RegularComGuaranteed}
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.AnnualRegularComm)] > 0}
                    />

                </FieldGroup>
        
            </QuestionBlock>
        ,

        nextQuestionId: () => parent.afterEmploymentCompleted()
    };

};