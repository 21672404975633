import React from 'react';
import { BeatLoader } from 'react-spinners';
import PropTypes from "prop-types";

class Loading extends React.Component {
    render() {
        let buttonLineClass = this.props.buttonLine
            ? 'button-line '
            : '';

        return (
            <div className={buttonLineClass + 'react-loading-container'}>
                <BeatLoader
                size={15}
                color={'#00B7A8'}
                loading={true}
                />
            </div> 
        );
    }

}

Loading.propTypes = {
    buttonLine: PropTypes.bool,
};

export default Loading;