/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "api7a077e54",
            "endpoint": "https://t2ybobgotj.execute-api.eu-west-1.amazonaws.com/Prod",
            "region": "eu-west-1"
        }
    ],
    "aws_content_delivery_bucket": "hcm-20180929123417--hostingbucket",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d3kg9zo37qt7gv.cloudfront.net",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "adminUsers",
            "region": "eu-west-1"
        },
        {
            "tableName": "applicants",
            "region": "eu-west-1"
        },
        {
            "tableName": "referrals",
            "region": "eu-west-1"
        },
        {
            "tableName": "userActions",
            "region": "eu-west-1"
        },
        {
            "tableName": "users",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "frontenda2b6f41c9f7446f597ed2ca177b4d975",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
