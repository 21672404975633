import React, {Fragment} from 'react';
import Header from "../welcome/Header";
import FormButton from "../form/components/FormButton";
import {welcomeLink} from "../util/links";
import {API} from "aws-amplify";
import {APINAME, APIPATH} from "../constants";
import Loading from "../form/icons/Loading";
import Alert, {ALERTTYPE} from "../form/components/alert/Alert";
import ContactUs from "../util/ContactUs";

const EXCEPTION_UNKNOWN = 'unknown';

/**
 * Code is already expired. User took too long to click on the confirmation link.
 * Standard Cognito exception.
 * @type {string}
 */
const EXCEPTION_CODE_EXPIRED = 'ExpiredCodeException';

/**
 * Code is incorrect. Likely a verification email resend sored a new expected verification code.
 * Standard Cognito exception.
 * @type {string}
 */
const EXCEPTION_CODE_MISMATCH = 'CodeMismatchException';

/**
 * User already confirmed.
 * Customized exception code in confirm user lambda.
 * @type {string}
 */
const EXCEPTION_ALREADY_CONFIRMED = 'AlreadyConfirmedCustomException';

const KNOWN_EXCEPTION_CODES = [
    EXCEPTION_UNKNOWN,
    EXCEPTION_CODE_EXPIRED,
    EXCEPTION_CODE_MISMATCH,
    EXCEPTION_ALREADY_CONFIRMED
];

class ConfirmUser extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            activating: true,
            activationSucceeded: false,
            activationAlreadyConfirmed: false,
            activationFailed: false,
            exceptionCode: undefined
        }
    }

    errorResult(code) {
        let substitutedCode = code !== null && code !== undefined ? code : EXCEPTION_UNKNOWN;

        if (!KNOWN_EXCEPTION_CODES.includes(substitutedCode)) {
            substitutedCode = EXCEPTION_UNKNOWN;
        }

        this.setState({
            activating: false,
            activationFailed: true,
            exceptionCode: substitutedCode
        })
    }

    componentDidMount() {
        API.post(APINAME, APIPATH.CONFIRMUSER, {
                body: {
                    confirmCode: this.props.match.params.confirmCode,
                    userName: this.props.match.params.userName
                }
            })
            .then(response => {
                if (!response) {
                    this.errorResult();
                } else if (response.success) {
                    this.setState({
                        activating: false,
                        activationSucceeded: true,
                    });
                } else {
                    this.errorResult(response.code);
                }
            })
            .catch(error => {
                this.errorResult();
            });
    }

    render() {
        return <Fragment>
            <div className="container">
                <div className='row'>
                    <div className='col-12'>
                        <Header {...this.props} type={Header.TYPES.LOGIN} />
                    </div>
                </div>
                <div className="row">
                    <div className={`col-12 col-md-8 offset-md-2`}>
                        <div className="card login">
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-12 padding-bottom'>
                                        <h1 className='oos-title'>Account verification</h1>
                                        {
                                            this.state.activating &&
                                            <Loading/>
                                        }
                                        {
                                                this.state.activationFailed &&
                                                this.state.exceptionCode === EXCEPTION_ALREADY_CONFIRMED &&
                                                <Fragment>
                                                    <Alert type={ALERTTYPE.SUCCESS}>Your email is already confirmed.</Alert>
                                                    <FormButton path={welcomeLink()} text='Login'/>
                                                </Fragment>
                                        }
                                        {
                                            this.state.activationFailed &&
                                            this.state.exceptionCode !== EXCEPTION_ALREADY_CONFIRMED &&
                                            <Fragment>
                                                <Alert type={ALERTTYPE.DANGER}>There was an error activating your account.</Alert>
                                                {
                                                    this.state.exceptionCode === EXCEPTION_UNKNOWN &&
                                                    <p>Exact error cause is unknown. Please <ContactUs link/> so that we can take a look at this.</p>
                                                }
                                                {
                                                    this.state.exceptionCode === EXCEPTION_CODE_EXPIRED &&
                                                    <p>Your link has already expired. You can resend verification link here.</p>
                                                }
                                                {
                                                    this.state.exceptionCode === EXCEPTION_CODE_MISMATCH &&
                                                    <p>Your link was incorrect. If you have requested another confirmation email then use the link from later email.</p>
                                                }
                                            </Fragment>
                                        }
                                        {
                                            this.state.activationSucceeded &&
                                            <Fragment>
                                                <Alert type={ALERTTYPE.SUCCESS}>Email confirmed!</Alert>
                                                <FormButton path={welcomeLink()} text='Login'/>
                                            </Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    }
}

export default ConfirmUser;