export const _EVALUATION_TYPE = {
    BASIC: 'basic',
    HOMEBUYERS: 'homebuyers_survey',
    BUILDING_SURVEY: 'building_survey'
};

_EVALUATION_TYPE.OPTIONS = [
    {
        code: _EVALUATION_TYPE.BASIC,
        text: 'Basic',
        icon: 'local_offer'
    },
    {
        code: _EVALUATION_TYPE.HOMEBUYERS,
        text: 'Homebuyers survey',
        icon: 'vpn_key'
    },
    {
        code: _EVALUATION_TYPE.BUILDING_SURVEY,
        text: 'Building survey',
        icon: 'build'
    }
];