export const _FUNDS_USAGE = {
    REPLENISH_SAVINGS: 'replenish_savings',
    RAISE_CAPITAL: 'raise_capital',
    HOME_IMPROVEMENTS: 'home_improvements',
    DEBT_CONSOLIDATION: 'debt_consolidation',
    SCHOOL_FEES: 'school_fees',
    HOLIDAY_CARS: 'holiday_cars',
    BUY_ANOTHER_PROPERTY: 'buy_another_property',
    BUSINESS_PURPOSES: 'business_purposes',
    DIVORCE_SETTLEMENT: 'divorce_settlement',
    OTHER: 'other'
};

_FUNDS_USAGE.OPTIONS = [
    {
        code: '',
        text: '',
    },
    {
        code: _FUNDS_USAGE.RAISE_CAPITAL,
        text: 'Raise capital',
        obsolete: true
    },
    {
        code: _FUNDS_USAGE.REPLENISH_SAVINGS,
        text: 'Replenish savings'
    },
    {
        code: _FUNDS_USAGE.HOME_IMPROVEMENTS,
        text: 'Home improvements'
    },
    {
        code: _FUNDS_USAGE.DEBT_CONSOLIDATION,
        text: 'Debt consolidation'
    },
    {
        code: _FUNDS_USAGE.SCHOOL_FEES,
        text: 'School fees'
    },
    {
        code: _FUNDS_USAGE.HOLIDAY_CARS,
        text: 'Holiday or cars'
    },
    {
        code: _FUNDS_USAGE.BUY_ANOTHER_PROPERTY,
        text: 'Buy another property or land'
    },
    {
        code: _FUNDS_USAGE.BUSINESS_PURPOSES,
        text: 'Business purposes'
    },
    {
        code: _FUNDS_USAGE.DIVORCE_SETTLEMENT,
        text: 'Divorce settlement'
    },
    {
        code: _FUNDS_USAGE.OTHER,
        text: 'Other (add)'
    },
];
