import React, { Fragment } from 'react';
import welcomeImg from '../images/welcome.gif';
import { withRouter } from 'react-router';
import Header from './Header';
import {
    informationChecklistLink,
    starterGuideLink,
    myApplicationsLink
} from "../util/links";
import ApplicationStarter from "../myApplications/ApplicationStarter";
import Loading from "../form/icons/Loading";
import ContactUs from "../util/ContactUs";

class Welcome extends ApplicationStarter {


    myApplications = () => {
        this.props.history.push(myApplicationsLink());
    };

    hasApplications = () => {
        return this.props.existingApplications && this.props.existingApplications.length > 0;
    };

    hasNoApplications = () => {
        return this.props.existingApplications && this.props.existingApplications.length === 0;
    };

    render() {

        return (
            <React.Fragment>
                <div className="container page-padding-bottom welcome-page">
                    <div className='row'>
                        <div className='col-12'>
                            <Header {...this.props} type={Header.TYPES.MAIN} />
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-3 welcome-sidebar d-none d-sm-block">
                            <ul>
                                <li className="mins">5 mins to initial review</li>
                                <li className="open">Online and open 24/7</li>
                                <li className="credit-card">No credit check required</li>
                            </ul>

                        </div>

                        <div className="col-5">

                            <div className="mb-5">
                            {
                                this.hasNoApplications() &&

                                <>
                                    <h1>Nice to meet you {this.props.user.nickname}!</h1>

                                    <p>
                                        Get started now by clicking the button below to begin your personalised, initial assessment.
                                        It’s really quick, taking only 5 mins to enter some basic details, after which one of our holiday let mortgage experts will come back to you with a current, real world view of what might be possible.
                                    </p>
                                    <p>
                                        Please take a moment to read our handy <a href={starterGuideLink()} target="_blank" rel='noopener noreferrer'>Starter Guide</a> which should help you understand the process and how it all works.
                                    </p>
                                    <p>
                                        If you don’t feel ready to undertake the initial assessment and would prefer a call, no problem, just <ContactUs/> and leave your phone number and an advisor will get in touch!
                                    </p>
                                </>
                            }
                            {
                                this.hasApplications() &&

                                <>
                                    <h1>Welcome back {this.props.user.nickname}!</h1>

                                    <p>
                                        Start a new application or pick up with an existing application by clicking the appropriate button below.
                                    </p>
                                    <p>
                                        The system will lead you through a series of tailored questions to better understand your situation, before allowing you to arrange a call with an Expert Advisor.
                                    </p>

                                    <p>
                                        Take a moment to read our <a href={starterGuideLink()} target="_blank" rel='noopener noreferrer'>Starter Guide</a> and <a href={informationChecklistLink()} target="_blank" rel='noopener noreferrer'>
                                        Information Checklist</a>, which should help you understand the process and gather all the information you need in the right format.
                                    </p>

                                    <p>
                                        The information you provide us with will eventually form the basis of your mortgage application,
                                        so please try to be as accurate as you can.
                                    </p>

                                    <p>
                                        If you have a question, just <ContactUs/>.
                                    </p>
                                </>
                            }
                            </div>


                            {
                                this.props.nextApplicationId !== undefined &&
                                this.props.nextApplicationId !== null &&
                                (
                                    (this.hasApplications() &&
                                    <Fragment>
                                        <button className="btn btn-primary icon" onClick={this.myApplications}>My applications</button>
                                        <button className="btn btn-secondary plus" onClick={this.startApplication}>New application</button>
                                    </Fragment>)
                                    ||
                                    (this.hasNoApplications() &&
                                    <Fragment>
                                        <button className="btn btn-primary icon" onClick={this.startApplication}>Get started</button>
                                    </Fragment>
                                    )
                                )
                            }
                            {
                                (this.props.nextApplicationId === undefined || this.props.nextApplicationId === null) &&
                                <div className="welcome-loading-container">
                                    <Loading />
                                </div>
                            }

                        </div>

                        <div className="col-4 welcome-image">
                            <img alt='' src={welcomeImg} />
                        </div>

                    </div>
                </div>
                {
                    // Auto-accept consent
                    !this.props.user.FCAConsent &&
                    setTimeout(this.handleAccept, 1000) &&
                    ''
                    /*
                       Removed FCA Overlay due to note in styling doc by Andy.
                       To bring it back remove lines above and uncomment.

                    !this.props.user.FCAConsent &&
                    !this.props.FCADismissed &&
                    <FCAOverlay handleAccept={() => this.handleAccept()} handleDismiss={() => this.props.dismissFCA()} />
                    */
                }

            </React.Fragment>
        );
    }

    handleAccept = () => {
        let user = this.props.user;
        user.FCAConsent = true;
        this.props.setUserData(user);
    }
}

export default withRouter(Welcome);