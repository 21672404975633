import * as React from 'react';
import './styles/index.css';
import { Switch, Route } from 'react-router-dom';
import HomePage from './welcome/HomePage';
import WelcomeContainer from './welcome/WelcomeContainer';
import AffordabilityCalculator from './public/affordabilityCalculator/AffordabilityCalculator';
import AuthListener from './status/AuthListener';
import { withRouter } from "react-router";
import RouteRequireLogin from "./RouteRequireLogin";
import DashboardContainer from './dashboard/DashboardContainer'
import MyApplicationsContainer from './myApplications/MyApplicationsContainer'
import ApplicationContainer from "./application/ApplicationContainer";
import PersistApplicationService from "./service/PersistApplicationService";
import AuthenticatorContainer from './auth/AuthenticatorContainer';
import AccountContainer from './account/AccountContainer';
import PersistUserDataService from './service/PersistUserDataService';
import AgentReferralContainer from './public/agentReferral/AgentReferralContainer'
import Blank from "./welcome/Blank";
import AgentLandingPluggable from "./landing/AgentLandingPluggable";
import configureAmplify from "./amplify-configurator";
import ConfirmUser from "./account/ConfirmUser";
import GoogleAnalytics4 from "./service/GoogleAnalytics4";
import AgentLandingRedirect from "./landing/AgentLandingRedirect";

let aws_env = configureAmplify();

class App extends React.Component {
    constructor(props) {
        super(props);

        /**
        window.Intercom("boot", {
            app_id: "mkcihhac"
        });
         */
    }

    updateWasFromGet = false;
    putAPITimeout;

    render() {
        return (
            <div className="app container-fluid">
                <Switch>
                    <Route exact={true} path='/' component={HomePage} />
                    <Route exact={true} path='/app/' component={HomePage} />

                    <Route path='/blank' component={Blank} />
                    <Route path='/affordability_calculator' component={AffordabilityCalculator} />

                    {/* temp url while not sure where it is used to change, should be under /app/. */}
                    <Route path='/agent-referral/:referralcode?' component={AgentReferralContainer}/>
                    <Route path='/app/agent-referral/:referralcode?' component={AgentReferralContainer}/>

                    <Route path='/app/register/:referralcode?' component={AuthenticatorContainer} />
                    <Route path='/app/forgotcredentials/code' component={AuthenticatorContainer} />
                    <Route path='/app/forgotcredentials' component={AuthenticatorContainer} />

                    <Route path='/app/confirmuser/:confirmCode/:userName' component={ConfirmUser} />

                    <Route path='/test/agents/:referralcode?' component={AgentLandingPluggable}/>
                    <Route path='/app/redirect/:referralcode?' component={AgentLandingRedirect}/>

                    <RouteRequireLogin path='/app/account' component={AccountContainer} />
                    <RouteRequireLogin path='/app/welcome' component={WelcomeContainer} />
                    <RouteRequireLogin path='/app/myapplications' exact component={MyApplicationsContainer} />

                    <RouteRequireLogin path='/app/dashboard' exact component={DashboardContainer} />
                    <RouteRequireLogin path='/app/dashboard/:sectionId' exact component={DashboardContainer} />
                    <RouteRequireLogin path='/app/dashboard/:sectionId/:applicantId' exact component={DashboardContainer} />

                    <RouteRequireLogin path='/app/application/:sectionId/:partId?/:slideId?' exact component={ApplicationContainer} />
                    <RouteRequireLogin path='/app/situation/:applicantId/:sectionId/:partId/:slideId?' exact component={ApplicationContainer} />
                </Switch>
                <PersistApplicationService />
                <PersistUserDataService />
                <AuthListener />
                <GoogleAnalytics4 />
                {
                    aws_env === 'preprod' &&
                    <div className='env-footer'>Pre-production environment</div>
                }
            </div>
        );
    }
}

export default withRouter(App);
