import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldLabel from "common-form/components/FieldLabel";
import FieldInput from "common-form/components/FieldInput";
import FieldDropdown from "../../../../../form/components/dropdown/FieldDropdown";
import FieldGroupAddable from "../../../../../form/components/FieldGroupAddable";
import { LABEL_TYPE } from "../../../../../form/components/Field";

import { formatCurrency } from "../../../../../util/currency";

import {
    FIELDS,
    ASSET_TYPE,
    extractFieldName
} from "common-hypotheca";


export default function AssetList(parent, nextQuestionId) {


    function getAssetDescription(asset) {
        
        const assetType = asset[extractFieldName(FIELDS.MS.AS.OtherAssetType)] ? 
            asset[extractFieldName(FIELDS.MS.AS.OtherAssetType)] : '';

        const assetTypeItem = assetType ? ASSET_TYPE.OPTIONS.find(a => a.code === assetType) : null;

        return <div className="row">
        <div className="col-sm-6">
            {assetTypeItem ? assetTypeItem.text : 'Unknown'}
        </div>
        <div className="col-sm-6">
            {formatCurrency(asset[extractFieldName(FIELDS.MS.AS.OtherAssetValue)])}
        </div>
        </div>
        ;
    }

    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldLabel
                    label="Other significant assets"
                />

                <FieldLabel
                    label="Tell us about any other significant cash savings, bonds, shares, pensions or any other sizeable assets that you own. If you don’t have any, just click the Back button and change your original selection."
                    labelType={LABEL_TYPE.Description}
                />

                <FieldGroupAddable
                    ref={params.groupAddableRef}
                    addButtonLabel="Add asset"
                    deleteButtonLabel="Remove"
                    editButtonLabel="Edit"
                    saveButtonLabel="Save asset"
                    allowDeleteAll
                    itemDescriber={(asset) => {
                        return getAssetDescription(asset);
                    }}
                    validate={(context) => {
                        context
                            .mandatory(FIELDS.MS.AS.OtherAssetType)
                            .mandatory(FIELDS.MS.AS.OtherAssetDescr)
                            .mandatory(FIELDS.MS.AS.OtherAssetValue)
                            ;
                    }}
                    {...parent.getField(FIELDS.MS.AS.OtherAssets)}
                >
                    
                    <FieldDropdown
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.OtherAssetType}
                    />
                    
                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.OtherAssetDescr}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.OtherAssetValue}
                    />

                </FieldGroupAddable>

            </QuestionBlock>,
        validate: (context) => {

            if (!context.hasItems(FIELDS.MS.AS.OtherAssets)) {
                context.addError(null, null, 'Please enter an asset or go back to the previous screen and change your selection.');
            }

            if (parent.groupAddableRef && !parent.groupAddableRef.current.valid()) {
                context.addError(FIELDS.MS.AS.OtherAssets, null, '');
            }

        },
        nextQuestionId: nextQuestionId
    };

    
};