export const _OWNER_TYPE = {
    MYSELF: 'myself',
    SHARED: 'shared'
};

_OWNER_TYPE.OPTIONS = [
    {
        code: _OWNER_TYPE.MYSELF,
        text: 'Solely by myself'
    },
    {
        code: _OWNER_TYPE.SHARED,
        text: 'Owned with someone else'
    }
];
