import React, { Fragment } from "react";
import { FieldCheckbox } from "../../../form/components/checkbox";
import FormButton from "../../../form/components/FormButton";
import { APINAME, APIPATH } from "../../../constants";
import { API } from "aws-amplify";
import Alert, { ALERTTYPE } from '../../../form/components/alert/Alert'
import {
    APP_STATUS, extractFieldName,
    FIELDS,
    SECTION
} from "common-hypotheca";

class MyApplicationSupplement extends React.Component {

    componentDidMount = () => {
        let data = this.props.data;
        if (data[SECTION.MY_APPLICATION].parts.filter(part => !part.isComplete).length){
            this.setField(extractFieldName(FIELDS.MA.FormalConsent), false);
        }

    };

    confirm = () => {
        // if(!this.canConfirm()){
        //     return
        // }
        let data = this.props.data;
        data.appStatus = APP_STATUS.APP_CONFIRM_GIVE;
        data.time.confirmed = Date.now();

        this.props.setData(data);
        API.post(APINAME, APIPATH.TRIGGER, {
                body: {
                    applicationId: data.applicationId,
                    event: 'consent_formal'
                }
            })
            .then(response => {
                // Add your code here
            })
            .catch(error => {
                console.log(error.response);
            })
    };

    setField(which, value) {
        let data = this.props.data;
        data[SECTION.MY_APPLICATION].fields[which] = value

        this.props.setData(data)
    }

    isDisabled = () => {
        let data = this.props.data;
        return !!data[SECTION.MY_APPLICATION].parts.filter(part => !part.isComplete).length
    };

    renderBlurb = () => {
        let components = {
            check: {
                code: !this.props.data[SECTION.MY_APPLICATION].fields[extractFieldName(FIELDS.MA.FormalConsent)],
                label: `I give Holiday Cottage Mortgages the authority to apply for my chosen mortgage and 
                    I understand that the lender will process the application in accordance 
                    with their own privacy policy and will undertake a credit search on each applicant.
                `,
                checked: this.props.data[SECTION.MY_APPLICATION].fields[extractFieldName(FIELDS.MA.FormalConsent)],
                field: extractFieldName(FIELDS.MA.FormalConsent),
                onCheckboxClick: (FormalConsent) => this.setField(extractFieldName(FIELDS.MA.FormalConsent), !!FormalConsent),
                disabled: this.isDisabled()
            },
            confirm: {
                text: 'Confirm',
                onClick: () => this.confirm() ,
                disabled: !this.props.data[SECTION.MY_APPLICATION].fields[extractFieldName(FIELDS.MA.FormalConsent)] || this.isDisabled()
            }
        };

        switch (this.props.data.appStatus) {
            case APP_STATUS.ADVICE_GIVEN:
            case APP_STATUS.TEST_EVERYTHING_OPEN:
                return (
                    <Fragment>
                        <div className='row'>
                            <div className='col-12 dashboard-internal'>
                                <FieldCheckbox  {...components.check} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 dashboard-internal'>
                                <FormButton {...components.confirm} />
                            </div>
                        </div>
                    </Fragment>
                );

            case APP_STATUS.APP_CONFIRM_GIVE:
                return (
                    <div className='row'>
                        <div className='col-12 dashboard-internal'>
                            <Alert text='Your consent has been recorded. You may now access the Application status page.'
                                   type={ALERTTYPE.SUCCESS}
                            />
                        </div>
                    </div>
                );

            default:
                return null
        }
    };

    render = () => {
        return (
            <Fragment>
                {
                    this.renderBlurb()
                }
            </Fragment>
        )
    }
}

export default MyApplicationSupplement;
