import * as React from "react";

import FieldLabel from "common-form/components/FieldLabel";
import { LABEL_TYPE } from "common-form/components/Field";
import FieldInput from "common-form/components/FieldInput";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";

import QuestionBlock from "../../../../form/QuestionBlock";
import FieldDropdown from "../../../../form/components/dropdown/FieldDropdown";
import {Alert, ALERTTYPE} from "../../../../form/components/alert/Alert";
import FieldTextArea from "../../../../form/components/FieldTextArea";

import {
    FIELDS,
    ROOF_TYPE,
    WALL_TYPE
} from "common-hypotheca";


export default function Rooms(parent, nextBlockId) {

    function isWallTypeOther() {
        return parent.getValue(FIELDS.MP.PD.HolidayLetWallsConstruction) === WALL_TYPE.OTHER;
    }

    function isRoofTypeOther() {
        return parent.getValue(FIELDS.MP.PD.HolidayLetRoofConstruction) === ROOF_TYPE.OTHER;
    }

    function hasMoreThanOnSelfContained() {
        return parent.getValue(FIELDS.MP.PD.HolidayLetMoreThanOnSelfContained) === true;
    }

    
    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldLabel
                    label={`${parent.getApplicantName()}, can you now tell us about the property layout and its construction`}
                />

                <FieldRadioYesNo
                    {...parent.getField(FIELDS.MP.PD.HolidayLetMoreThanOnSelfContained)}
                    labelType={LABEL_TYPE.Small}
                />

                { hasMoreThanOnSelfContained() && 

                <>
                    <FieldDropdown
                        {...parent.getField(FIELDS.MP.PD.HolidayLetHowManySelfContained)}
                        labelType={LABEL_TYPE.Small}
                        type="number"
                    />

                    <FieldTextArea
                        {...parent.getField(FIELDS.MP.PD.HolidayLetMultiUnitLayoutDescription)}
                        placeholder="Optional text box. For example, the property consists of a main residential farmhouse, with two additional self-contained cottages"
                        labelType={LABEL_TYPE.Small}
                    />

                    <Alert
                        text={`When answering the next few questions, please add together all the bedrooms, kitchens, dining rooms etc`}
                        type={ALERTTYPE.INFO}
                    />

                </>
                }

                <FieldInput
                    {...parent.getField(FIELDS.MP.PD.HolidayLetNumLivingRooms)}
                    labelType={LABEL_TYPE.Small}
                    type="number"
                />

                <FieldInput
                    {...parent.getField(FIELDS.MP.PD.HolidayLetNumDiningRooms)}
                    labelType={LABEL_TYPE.Small}
                    type="number"
                />

                <FieldInput
                    {...parent.getField(FIELDS.MP.PD.HolidayLetNumBedrooms)}
                    labelType={LABEL_TYPE.Small}
                    type="number"
                />

                <FieldInput
                    {...parent.getField(FIELDS.MP.PD.HolidayLetNumKitchens)}
                    labelType={LABEL_TYPE.Small}
                    type="number"
                />

                <FieldInput
                    {...parent.getField(FIELDS.MP.PD.HolidayLetNumBathrooms)}
                    labelType={LABEL_TYPE.Small}
                    type="number"
                />

                <FieldTextArea
                    {...parent.getField(FIELDS.MP.PD.HolidayLetNumOtherRooms)}
                    labelType={LABEL_TYPE.Small}
                    placeholder="For example: a cellar, study, or playroom …"
                />

                <FieldDropdown
                    {...parent.getField(FIELDS.MP.PD.HolidayLetWallsConstruction)}
                    labelType={LABEL_TYPE.Small}
                    type="number"
                />

                <FieldInput
                    {...parent.getField(FIELDS.MP.PD.HolidayLetWallsConstructionOther)}
                    labelType={LABEL_TYPE.Small}
                    show={() => isWallTypeOther() }
                  />

                <FieldDropdown
                    {...parent.getField(FIELDS.MP.PD.HolidayLetRoofConstruction)}
                    labelType={LABEL_TYPE.Small}
                    type="number"
                />

                <FieldInput
                    {...parent.getField(FIELDS.MP.PD.HolidayLetRoofConstructionOther)}
                    labelType={LABEL_TYPE.Small}
                    show={() => isRoofTypeOther() }
                />

                <FieldDropdown
                    {...parent.getField(FIELDS.MP.PD.HolidayLetGarageType)}
                    labelType={LABEL_TYPE.Small}
                    type="number"
                />

            </QuestionBlock>,


        validate: (context) => {
            context
            .mandatory(FIELDS.MP.PD.HolidayLetNumLivingRooms)
            .mandatory(FIELDS.MP.PD.HolidayLetNumDiningRooms)
            .mandatory(FIELDS.MP.PD.HolidayLetNumBedrooms)
            .mandatory(FIELDS.MP.PD.HolidayLetNumKitchens)
            .mandatory(FIELDS.MP.PD.HolidayLetNumBathrooms)
            .optional(FIELDS.MP.PD.HolidayLetNumOtherRooms)
            .mandatory(FIELDS.MP.PD.HolidayLetWallsConstruction)            
            .mandatory(FIELDS.MP.PD.HolidayLetRoofConstruction)
            .mandatory(FIELDS.MP.PD.HolidayLetGarageType)
            .mandatory(FIELDS.MP.PD.HolidayLetMoreThanOnSelfContained)
            ;

            context.mandatoryOrCleanup(
                isWallTypeOther(),
                FIELDS.MP.PD.HolidayLetWallsConstructionOther
            );

            context.mandatoryOrCleanup(
                isRoofTypeOther(),
                FIELDS.MP.PD.HolidayLetRoofConstructionOther
            );

            context.mandatoryOrCleanup(
                hasMoreThanOnSelfContained(),
                FIELDS.MP.PD.HolidayLetHowManySelfContained
            );

            context.optionalOrCleanup(
                hasMoreThanOnSelfContained(),
                FIELDS.MP.PD.HolidayLetMultiUnitLayoutDescription
            );
        },

        nextQuestionId: () => nextBlockId()
    };

    
};