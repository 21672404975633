import React, { Fragment, Component } from 'react';

class Bin extends Component {


    render() {
        return (
            <Fragment>
                <span className='dashboard-bin'>&nbsp;</span>
            </Fragment>

        );
    }
}

export default Bin;
