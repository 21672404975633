import { Component } from "react";
import ReactGA from 'react-ga4';
import { withRouter } from "react-router";

const ID = 'G-QBDJKXLH7S';

class GoogleAnalytics4 extends Component {
    componentDidMount() {
        ReactGA.initialize(ID);
        ReactGA.send("pageview");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname){
            ReactGA.send({ hitType: "pageview", page: this.props.location.pathname });
        }
    }

    render() {
        return null;
    }

    static userLoggedIn(userId) {
        gtag('config', ID, {
            'user_id': userId
        })
    }

    static userLoggedOut() {
        gtag('config', ID, {
            'user_id': undefined
        })
    }
}

export default withRouter(GoogleAnalytics4)
