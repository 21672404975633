import {_OTHER} from "./other";

export const _PAYOFF_FUNDS_SOURCE = {
};

_PAYOFF_FUNDS_SOURCE.OPTIONS = [
    {code: '', text: ''},
    {code: 'existingSavings', text: 'Existing savings'},
    {code: 'giftedFunds', text: 'Gifted funds'},
    {code: 'assetsSale', text: 'Funds from the sale of assets'},
    {code: _OTHER, text: 'Other (add)'}
];