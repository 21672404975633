import { connect } from 'react-redux';
import {setData, setExistingApplications,setApplicationId,startNewApplication} from '../redux/actions';
import MyApplications from './MyApplications'
import {Adapter} from "../util/models";

const mapStateToProps = (state, ownProps) => ({
    existingApplications:state.existingApplications,
    nextApplicationId: state.nextApplicationId,
    adapter: new Adapter(state.data),
    user:state.user
});

const mapDispatchToProps = dispatch => ({
    setApplicationId: applicationId => dispatch(setApplicationId(applicationId)),
    setExistingApplications: (data, nextApplicationId) => dispatch(setExistingApplications(data, nextApplicationId)),
    startNewApplication: (type,id) => dispatch(startNewApplication(type,id)),
    setData: (data, preventPersist) => dispatch(setData(data, preventPersist))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyApplications)
