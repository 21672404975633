import React, { Fragment } from 'react';
import {APINAME, APIPATH, REFERRALLOCALSTORAGEKEY} from "../constants";
import {API} from "aws-amplify";
import Alert, {ALERTTYPE} from "../form/components/alert/Alert";

class ReferralBase  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            referralCode: this.getReferralCode(),
            agent: null
        };
    }

    componentDidMount = async () => {
        if (this.props && this.props.match && this.props.match.params.referralcode) {
            localStorage.setItem(REFERRALLOCALSTORAGEKEY, this.props.match.params.referralcode);
        }

        if (this.getReferralCode()) {
            let agent = await API.get(APINAME, APIPATH.AGENT + '/' + this.getReferralCode(), {})
            if (agent) {
                if (agent.error) {
                    this.setState({
                        agent: false
                    });
                    localStorage.removeItem(REFERRALLOCALSTORAGEKEY)
                } else {
                    this.setState({
                        agent
                    })
                }

            }
        }
        this.setState({
            referralCode: this.getReferralCode()
        });
    };

    getReferralCode = () => {
        let item = localStorage.getItem(REFERRALLOCALSTORAGEKEY);
        return item || null;
    };

    renderAgentInfo() {
        return this.getReferralCode() &&
        <Fragment>
            {
                !!this.state.agent ?
                    <Fragment>
                        <div className='row'>
                            <div className='col-12 padding-bottom'>
                                <Alert type={ALERTTYPE.SUCCESS} text={`You’ve been referred to us by ${this.state.agent.name}${!!this.state.agent.brand ? `, of ${this.state.agent.brand}` : ''}! Enjoy the site and let us know if we can help you.`} />
                            </div>
                        </div>
                    </Fragment>
                    :
                    this.state.agent === false ?
                        <div className='row'>
                            <div className='col-12 padding-bottom'>
                                <Alert type={ALERTTYPE.DANGER} text={`The referral code is invalid`} />
                            </div>
                        </div>
                        :
                        <div className='row'>
                            <div className='col-12 padding-bottom'>
                                <Alert type={ALERTTYPE.INFO} text={`Prodding the database...`} />
                            </div>
                        </div>

            }
        </Fragment>

    }
}

export default ReferralBase;