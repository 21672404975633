import React, { Fragment, Component } from 'react';
import { APP_STATUS, describeStatus } from 'common-hypotheca';

class ApplicationStatus extends Component {
    render() {
        if (!this.props.data) {
            return null;
        }
        let statusArr = [
            APP_STATUS.PRESUB_REVIEW_COMPLETE,
            APP_STATUS.DIP_COMPLETE,
            APP_STATUS.APP_SUBMITTED,
            APP_STATUS.LENDER_CONSULTATION,
            APP_STATUS.MORTGAGE_OFFER_ISSUED,
            APP_STATUS.MORTGAGE_COMPLETED
        ];

        let statusIcon = {};
        statusIcon[APP_STATUS.PRESUB_REVIEW_COMPLETE] = 'playlist_add_check';
        statusIcon[APP_STATUS.DIP_COMPLETE] = 'stars';
        statusIcon[APP_STATUS.APP_SUBMITTED] = 'assignment_turned_in';
        statusIcon[APP_STATUS.LENDER_CONSULTATION] = 'supervisor_account';
        statusIcon[APP_STATUS.MORTGAGE_OFFER_ISSUED] = 'thumb_up';
        statusIcon[APP_STATUS.MORTGAGE_COMPLETED] = 'account_balance';

        // console.log('statusArr',statusArr.indexOf(this.props.data.appStatus));

        let statuses = [];
        let currentStatusIndex = statusArr.indexOf(this.props.data.appStatus);

        for (let i = 0; i < statusArr.length; i++) {
            statuses.push({
                id: statusArr[i],
                name: describeStatus(statusArr[i]),
                icon: statusIcon[statusArr[i]],
                active: i <= currentStatusIndex
            });
        }

        return (
            <Fragment>
                <div className='application-status'>
                    <div className='row'>
                        <div className='col-12'>
                            <h1 className='col-form-label'>Well done {this.props.adapter.applicantName(0)}. Your hard work is over, leave the rest to us!</h1>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <p>We will keep you fully updated by email (and SMS, if you have verified a mobile phone number with us) each step of the way. If your advisor requires any further information or has any questions from the lender, they will get in touch.</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 statuses-container'>
                            {
                                statuses.map((status, i) =>
                                    <Fragment key={'fragment-' + i}>
                                        {
                                            i > 0 &&
                                            <div key={'line-' + i} className={'line ' + (status.active ? ' active' : ' inactive')}/>
                                        }
                                        <div key={'status-' + i} className={`status-display ${status.active ? ' active ' : ' inactive '} ${status.icon}`}>
                                            <div className='status-container' >
                                                <div className='contents'>
                                                    <div className='icon'>{status.icon}</div>
                                                    <div className='text'>
                                                        {status.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            }
                            <div className="clear"/>
                        </div>
                    </div>
                </div>
            </Fragment>

        );
    }
}

export default ApplicationStatus;
