import * as React from "react";
import QuestionBlock from "../../../../../form/QuestionBlock";
import {FieldRadioPicture} from "../../../../../form/components/radio";
import FieldGroup from "../../../../../form/components/FieldGroup";
import {
    FIELDS,
    extractFieldName
} from "common-hypotheca";


export default function EmploymentType(parent) {

    return {
        block: (params) =>

            <QuestionBlock {...params}>

                <FieldGroup
                    item={parent.getCurrentEmployment()}
                    {...parent.getField(FIELDS.MS.EI.Employments)}
                    beforeEdit={function() {
                        parent.setState({
                            employmentTypeChanged: true
                        });
                    }}
                >

                    <FieldRadioPicture
                        label={parent.getApplicantName() + ", which option best describes your current work situation?\n"}
                        field={FIELDS.MS.EI.EmploymentType}
                        
                    />

                </FieldGroup>

            </QuestionBlock>,


        validate: (context) => {

            const employment = parent.getCurrentEmployment();
            if (!employment[extractFieldName(FIELDS.MS.EI.EmploymentType)]) {
                context.addError('', '', 'Required');
            }
        },

        nextQuestionId: () => parent.afterEmploymentType()
    };

};