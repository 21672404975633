import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldDate from "common-form/components/FieldDate";
import FieldInput from "common-form/components/FieldInput";
import FieldGroup from "../../../../../form/components/FieldGroup";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import TimeDiff from "../../../../../form/components/TimeDiff";
import AddressLookup from "../../../../../form/components/AddressLookup";
import { LABEL_TYPE } from "../../../../../form/components/Field";

import {extractFieldName, FIELDS} from "common-hypotheca";
import { validateSoletraderBasic } from "./validation";


export default function SoletraderBasic(parent) {

    return {
        block: (params)=>
            <QuestionBlock {...params}>

                <FieldGroup
                    ref={params.groupAddableRef}
                    item={parent.getCurrentEmployment()}
                    className="date-boxes"
                    {...parent.getField(FIELDS.MS.EI.Employments)}
                    validate={(context) => {

                        validateSoletraderBasic(parent, context, parent.state.employmentIndex);


                    }}
                >

                    <FieldInput
                        label="Business name"
                        field={FIELDS.MS.EI.EmploymentName}
                    />

                    <FieldInput
                        label="Business description"
                        field={FIELDS.MS.EI.EmploymentDescription}
                    />

                    <AddressLookup
                        label="Business address"
                        labelType={LABEL_TYPE.Large}
                        fields={[
                            FIELDS.MS.EI.SoleTradTradingAddress, 
                            FIELDS.MS.EI.SoleTradTradingAddress2,
                            FIELDS.MS.EI.SoleTradTradingAddressTown,
                            FIELDS.MS.EI.SoleTradTradingAddressCounty,
                            FIELDS.MS.EI.SoleTradTradingAddressPostcode
                        ]}
                    />

                    <FieldDate
                        label="What date did you start with the business?"
                        field={FIELDS.MS.EI.EmploymentStartDate}
                    />

                    {
                        !parent.isFirstEmployment() &&
                        <FieldRadioYesNo
                            field={FIELDS.MS.EI.EmploymentIsCurrent}
                        />
                    }

                    <TimeDiff
                        applicable={
                            parent.isFirstEmployment()
                            || parent.getEmploymentField(extractFieldName(FIELDS.MS.EI.EmploymentIsCurrent)).value === true
                        }
                        updateFactFindFunction={parent.updateFactFindBeenInThisJobFor}
                        fromValue={parent.getEmploymentField(extractFieldName(FIELDS.MS.EI.EmploymentStartDate)).value}
                        toValue={new Date()}
                        format="ym"
                        label="You have been in this job for {0}."
                    />

                    <FieldDate
                        label="When did this job end?"
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.EmploymentIsCurrent)] === false}
                        field={FIELDS.MS.EI.EmploymentEndDate}
                    />

                </FieldGroup>

            </QuestionBlock>,
        nextQuestionId: () => parent.afterSoletraderBasic()
    };


    
};