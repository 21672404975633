import {_FIELDS} from "./fields";

function fieldsShallow() {
    let result = {};
    for (let inner of Object.values(_FIELDS)) {
        for (let inner2 of Object.values(inner)) {
            if (typeof inner2 === 'string') {
                result[inner2] = inner2;
            } else {
                result = Object.assign(result, inner2);
            }
        }
    }
    return result;
}

function fieldOrder() {
    let result = [];

    for (let inner of Object.values(_FIELDS)) {
        for (let inner2 of Object.values(inner)) {
            if (typeof inner2 === 'string') {
                result.push(inner2);
            } else {
                for (let inner3 of Object.values(inner2)) {
                    if (typeof inner3 == 'string')
                        result.push(inner3);
                    else
                        result.push(inner3.name);
                }
            }
        }
    }
    return result;
}

const _FIELDS_SHALLOW = fieldsShallow();

export const _FIELD_ORDER = fieldOrder();

// console.log('shallow and order', _FIELDS_SHALLOW, _FIELD_ORDER);


export function _extractFieldName(field) {
    if (typeof field === "string")
        return field;
    else
        return field.name;
}

function extractProperty(field, propertyMain, propertyBackup) {
    let fieldObject;
    if (typeof field === "string")
        fieldObject = _FIELDS_SHALLOW[field];
    else
        fieldObject = field;

    if (fieldObject === undefined || typeof fieldObject === "string")
        return undefined;
    else {
        return fieldObject[propertyMain] !== undefined
            ? fieldObject[propertyMain]
            : fieldObject[propertyBackup];
    }
}

export function _extractFrontendLabel(field)  {
    // return undefined so that frontend can specify its own label.
    return extractProperty(field, 'frontendLabel', 'label');
}

export function _extractAdminArrayElementLabel(field) {
    return extractProperty(field, 'adminArrayElementLabel');
}

export function _extractAdminHidden(field) {
    return extractProperty(field, 'adminHidden');
}

export function _extractAdminLabel(field) {
    // return field name if nothing found.
    let result = extractProperty(field, 'adminLabel', 'label');
    return result !== undefined ? result : field;
}

export function _extractAdminStyle(field) {
    return extractProperty(field, 'adminStyle');
}

export function _extractSectionTitle(field) {
    return extractProperty(field, 'sectionTitle');
}

export function _extractDesignation(field) {
    return extractProperty(field, 'designation');
}

export function _extractOptions(field) {
    return extractProperty(field, 'options');
}

export function _extractFormat(field) {
    return extractProperty(field, 'format');
}

export function _extractFileList(field) {
    return extractProperty(field, 'fileList');
}

export function _extractAdminCalculatedFrom(field) {
    return extractProperty(field, 'adminCalculatedFrom');
}

export function _extractAdminCalculation(field) {
    return extractProperty(field, 'adminCalculation');
}

export function _extractAdminLabelCalculatedFrom(field) {
    return extractProperty(field, 'adminLabelCalculatedFrom');
}

export function _extractAdminLabelCalculation(field) {
    return extractProperty(field, 'adminLabelCalculation');
}

export function _extractAdminValueText(field, value) {
    if (typeof field === "string")
        field = _FIELDS_SHALLOW[field];

    if (field === undefined || typeof field !== "object")
        return value;

    if (field.options !== undefined) {
        let result = field.options.filter((elem) => elem.code === value);

        if (result.length > 0 && result[0].adminText)
            return result[0].adminText;
        else if (result.length > 0 && result[0].text)
            return result[0].text;
        else
            return value;
    }

    if (field.fileList && value) {
        try {
            let docs = JSON.parse(value);
            return docs
                .map(singleFile => singleFile.name)
                .join('; ');
        }
        catch (e) {
            console.log('Unable to parse docs', value);
            return value;
        }
    }

    return value;
}

export function _extractValueText(field, value) {
    if (typeof field === "string")
        field = _FIELDS_SHALLOW[field];

    let result;

    if (field.options !== undefined) {
        let filtered = field.options.filter((elem) => elem.code === value);

        if (filtered.length > 0 && filtered[0].text)
            result = filtered[0].text;
        else
            result = value;
    } else {
        result = value;
    }

    if (result === null || result === undefined) {
        return '';
    } else {
        return result;
    }
}
