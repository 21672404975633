import React from 'react';
import QuestionBlock from "../../../../../form/QuestionBlock";
import { CAN_SUBMIT } from "../../../../../constants";
import FieldLabel from "common-form/components/FieldLabel";
import { LABEL_TYPE } from "common-form/components/Field";
import FieldInput from "common-form/components/FieldInput";
import { FieldDropdown } from "../../../../../form/components/dropdown";
import FieldDate from "common-form/components/FieldDate";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import TimeDiff from "../../../../../form/components/TimeDiff";
import AddressLookup from "../../../../../form/components/AddressLookup";
import CopyApplicantsData from "../../../../../form/components/CopyApplicantsData";

import { atLeast3YearsAgo } from "common-hypotheca/src/dates";
import { globalCleanupCurrentAddressOwner, globalCleanExtraAddress} from "../../../../../util/globalCleanup";

import { FIELDS, OCCUPANT_TYPE, OTHER } from "common-hypotheca";

export default function CurrentAddress(parent, additionalAddressesBlockId) {

    function additionalAddressesNeeded() {
        return !atLeast3YearsAgo(parent.getFieldValue(null, FIELDS.MS.PD.CurrentAddressStartDate));
    }

    return {

        block: (params) =>

            <QuestionBlock {...params}>

                <FieldLabel
                    label='Please tell us where you currently live' />

                <FieldLabel
                    labelType={LABEL_TYPE.Description}
                    label='Lenders need to know your address history for the last 3 years. If you have lived at your current address for less than 3 years, don’t worry, you can add a former address on the next screen.'
                />

                <FieldLabel
                    label="If the property is not located in the UK, then please continue to use the form below, but input the address in the best way you can."
                    labelType={LABEL_TYPE.Description}
                />

                <CopyApplicantsData
                    label="Copy address from:"
                    sectionId={parent.state.sectionId}
                    partId={parent.state.partId}
                    currentApplicantId={parent.state.currentApplicantId}
                    adapter={parent.props.adapter}
                    fields={[
                        parent.fieldComponentProperties(FIELDS.MS.PD.CurrentAddressAddressLine1),
                        parent.fieldComponentProperties(FIELDS.MS.PD.CurrentAddressAddressLine2),
                        parent.fieldComponentProperties(FIELDS.MS.PD.CurrentAddressTown),
                        parent.fieldComponentProperties(FIELDS.MS.PD.CurrentAddressCounty),
                        parent.fieldComponentProperties(FIELDS.MS.PD.CurrentAddressPostcode)
                    ]}
                />

                <AddressLookup
                    label="Contact address"
                    labelType={LABEL_TYPE.Small}
                    fields={[
                        parent.fieldComponentProperties(FIELDS.MS.PD.CurrentAddressAddressLine1),
                        parent.fieldComponentProperties(FIELDS.MS.PD.CurrentAddressAddressLine2),
                        parent.fieldComponentProperties(FIELDS.MS.PD.CurrentAddressTown),
                        parent.fieldComponentProperties(FIELDS.MS.PD.CurrentAddressCounty),
                        parent.fieldComponentProperties(FIELDS.MS.PD.CurrentAddressPostcode)
                    ]}
                />

                <div className='date-boxes'>
                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        {...parent.fieldComponentProperties(FIELDS.MS.PD.CurrentAddressStartDate)}
                    />
                </div>

                <TimeDiff
                    updateFactFindFunction={
                        (age) => parent.props.adapter.updateField(age, FIELDS.MS.PD.CurrentAddressAge, ...parent.defaultFieldLocation())
                    }
                    fromValue={parent.fieldValue(FIELDS.MS.PD.CurrentAddressStartDate)}
                    format="ym"
                    label="You have lived at this address for {0}."
                />

                <FieldDropdown
                    labelType={LABEL_TYPE.Small}
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.CurrentAddressOccuptType)}
                />

                <FieldInput
                    placeholder="Please describe"
                    show={parent.getFieldValue(null, FIELDS.MS.PD.CurrentAddressOccuptType) === OTHER}
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.CurrentAddressOccuptTypeOther)}
                />

                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.CurrentAddressElectRoll)}
                />

                {
                    <FieldInput
                        show={OCCUPANT_TYPE.WITH_RENT.includes(parent.getFieldValue(null, FIELDS.MS.PD.CurrentAddressOccuptType))}
                        labelType={LABEL_TYPE.Small}
                        {...parent.fieldComponentProperties(FIELDS.MS.PD.CurrentAddressRentMonthly)}
                    />
                }

            </QuestionBlock>,


        validate: (context) => {
            context
                .mandatory(FIELDS.MS.PD.CurrentAddressAddressLine1)
                .optional(FIELDS.MS.PD.CurrentAddressAddressLine2)
                .mandatory(FIELDS.MS.PD.CurrentAddressStartDate)
                .mandatory(FIELDS.MS.PD.CurrentAddressTown)
                .mandatory(FIELDS.MS.PD.CurrentAddressPostcode)
                .optional(FIELDS.MS.PD.CurrentAddressCounty)
                .mandatory(FIELDS.MS.PD.CurrentAddressOccuptType)
                .mandatory(FIELDS.MS.PD.CurrentAddressElectRoll)
                .date(FIELDS.MS.PD.CurrentAddressStartDate)
                .dateMax(FIELDS.MS.PD.CurrentAddressStartDate, new Date(), `Date can't be in the future`);

            if (context.fieldValue(FIELDS.MS.PD.CurrentAddressOccuptType) === OTHER) {
                context.mandatory(FIELDS.MS.PD.CurrentAddressOccuptTypeOther)
            } else {
                context.cleanupIrrelevantField(FIELDS.MS.PD.CurrentAddressOccuptTypeOther)
            }

            context.mandatoryOrCleanup(
                OCCUPANT_TYPE.WITH_RENT.includes(context.fieldValue(FIELDS.MS.PD.CurrentAddressOccuptType)),
                FIELDS.MS.PD.CurrentAddressRentMonthly
            );

            context.cleanupIrrelevantField(FIELDS.MS.PD.CurrentAddressAgeYears);
            context.cleanupIrrelevantField(FIELDS.MS.PD.CurrentAddressAgeMonths);

            // when occupant type changed, do data cleanup
            globalCleanupCurrentAddressOwner(parent.state.occupantType, parent.props.adapter, parent.props.applicantId);

            //clean extra addresses
            globalCleanExtraAddress(parent.props.adapter, parent.props.applicantId);

        },

        nextQuestionId: () => additionalAddressesNeeded() ? additionalAddressesBlockId : CAN_SUBMIT
    };
};
