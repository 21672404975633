export const _DEPENDANT_TYPE = {
    CHILD: 'child',
    SPOUSE: 'spouse',
    PARTNER: 'partner',
    OTHER: 'other',
};

_DEPENDANT_TYPE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: _DEPENDANT_TYPE.CHILD,
        text: 'Child'
    },
    {
        code: _DEPENDANT_TYPE.SPOUSE,
        text: 'Spouse'
    },
    {
        code: _DEPENDANT_TYPE.PARTNER,
        text: 'Partner'
    },
    {
        code: _DEPENDANT_TYPE.OTHER,
        text: 'Other relative'
    }
];