import * as React from "react";

import FieldLabel from "common-form/components/FieldLabel";
import {LABEL_TYPE} from "common-form/components/Field";

import { CAN_SUBMIT} from "../../../constants";
import QuestionBlock from "../../../form/QuestionBlock";
import MultiBlockForm from "../../../form/MultiBlockForm";
import FormButton from "../../../form/components/FormButton";
import FormImage, {IMAGE_MARKETING} from "../../../form/components/FormImage";
import {
    SECTION,
    PROPERTY,
    FIELDS,
    NEW_OR_EXISTING_LET,
    OTHER, LET_MARKETING
} from "common-hypotheca";
import {FieldRadioPicture} from "../../../form/components/radio";
import FieldInput from "common-form/components/FieldInput";
import {FieldCheckboxGroup} from "../../../form/components/checkbox";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldDropdown from "../../../form/components/dropdown/FieldDropdown";
import FieldFileUpload from "../../../form/components/FieldFileUpload";
import { Alert, ALERTTYPE } from "../../../form/components/alert/Alert";
import ErrorPart from "../../ErrorPart";

const BLOCK_WELCOME = 1;
const BLOCK_NEW_OR_EXIST = 2;
const BLOCK_EXISTING_LET_HISTORY = 3;
const BLOCK_EXISTING_LET_HISTORY_MARKETING = 4;
const BLOCK_FUTURE_EXPECTATIONS = 5;
const BLOCK_SAME_MARKETING = 6;
const BLOCK_FUTURE_MARKETING = 7;
const BLOCK_FURTHER_INFORMATION = 8;


class HolidayLetInformation extends MultiBlockForm {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            sectionId: SECTION.PROPERTY,
            partId: PROPERTY.LETTING,

            slideIds: [BLOCK_WELCOME],
            activeSlideId: BLOCK_WELCOME,

            currentApplicantId: null
        };
    }

    /**
     * @returns {number} of expected slides for this part.
     */
    slidesExpected = () => {
        return (
            1 // BLOCK_WELCOME
            + 1 // BLOCK_NEW_OR_EXIST
            + (this.existingHolidayLet() ? 3 : 0) // BLOCK_EXISTING_LET_HISTORY, BLOCK_EXISTING_LET_HISTORY_MARKETING, BLOCK_SAME_MARKETING
            + 1 // BLOCK_FUTURE_EXPECTATIONS
            + ((this.newHolidayLet() || !this.sameMarketing()) ? 1 : 0) // BLOCK_FUTURE_MARKETING
            + 1 // BLOCK_FURTHER_INFORMATION
        );
    };

    existingHolidayLet = () => {
        return this.getFieldValue(null, FIELDS.MP.HL.NewOrExistLet) === NEW_OR_EXISTING_LET.EXISTING_HOLIDAY_LET;
    };

    sameMarketing = () => {
        return this.fieldHasValueTrue(null, FIELDS.MP.HL.Next12MarketingAsExist);
    };

    newHolidayLet = () => {
        return this.getFieldValue(null, FIELDS.MP.HL.NewOrExistLet) === NEW_OR_EXISTING_LET.NEW_HOLIDAY_LET;
    };

    checkForCompleteState = data => {
        this.partContainer().isComplete = true;
        this.currentSectionObject().isComplete = true;
        return data;
    };

    hasCheckbox = (field, value) => {
        let checkboxes = this.getFieldValue(null, field);
        return !!checkboxes && checkboxes.includes(value);
    };

    getQuestion = which => {
        switch(which) {
            case BLOCK_WELCOME:
                return {
                    block: (params)=>
                        <QuestionBlock {...params} controlButtons={null}>
                            <FieldLabel
                                label={this.getApplicantName() + ", we would now like to understand more about the holiday letting aspects of your property"}
                            />
                            <FieldLabel
                                labelType={LABEL_TYPE.Description}
                                label='Lenders will look carefully at this information and may use the rental figures in their calculations when determining the amount of borrowing.'
                            />
                            <FormButton
                                text="I'm ready!"
                                onClick={() => this.swiperNext()}
                            />
                            <FormImage imageType={IMAGE_MARKETING}/>
                        </QuestionBlock>,
                    canNext: () => true,
                    nextQuestionId: () => BLOCK_NEW_OR_EXIST
                };

            case BLOCK_NEW_OR_EXIST:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldRadioPicture
                                {...this.getFormField(null, FIELDS.MP.HL.NewOrExistLet)}
                            />
                        </QuestionBlock>,
                    validate: (context) => {
                        context.mandatory(FIELDS.MP.HL.NewOrExistLet);

                        if (this.newHolidayLet()) {
                            // cleanup existing holiday let info if any
                            context
                                // BLOCK_EXISTING_LET_HISTORY
                                .cleanupIrrelevantField(FIELDS.MP.HL.ExistLetHowManyYrs)
                                .cleanupIrrelevantField(FIELDS.MP.HL.ExistLet12MGrosInc)
                                .cleanupIrrelevantField(FIELDS.MP.HL.ExistLet12MBookingNum)
                                // BLOCK_EXISTING_LET_HISTORY_MARKETING
                                .cleanupIrrelevantField(FIELDS.MP.HL.ExistLetMkting)
                                .cleanupIrrelevantField(FIELDS.MP.HL.ExistLetMktingOther)
                                .cleanupIrrelevantField(FIELDS.MP.HL.ExistLetMktingWebsite)
                                .cleanupIrrelevantField(FIELDS.MP.HL.ExistLetMktingAgent)
                                .cleanupIrrelevantField(FIELDS.MP.HL.ExistLetMktingAgentOther)
                                .cleanupIrrelevantField(FIELDS.MP.HL.ExistLetMktingOTA)
                                .cleanupIrrelevantField(FIELDS.MP.HL.ExistLetMktingOTAOther)
                                // BLOCK_SAME_MARKETING
                                .cleanupIrrelevantField(FIELDS.MP.HL.Next12MarketingAsExist);
                        }
                    },
                    nextQuestionId: () =>
                        this.existingHolidayLet()
                        ? BLOCK_EXISTING_LET_HISTORY
                        : BLOCK_FUTURE_EXPECTATIONS
                };

            case BLOCK_EXISTING_LET_HISTORY:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldInput {...this.getFormField(null, FIELDS.MP.HL.ExistLetHowManyYrs)} />
                            <FieldLabel label='Looking the last 12 months, can you tell us:'/>
                            <FieldInput labelType={LABEL_TYPE.Small} {...this.getFormField(null, FIELDS.MP.HL.ExistLet12MGrosInc)} />
                            <FieldInput labelType={LABEL_TYPE.Small} {...this.getFormField(null, FIELDS.MP.HL.ExistLet12MBookingNum)} />
                        </QuestionBlock>,
                    validate: (context) => {
                        context
                            .mandatory(FIELDS.MP.HL.ExistLetHowManyYrs)
                            .mandatory(FIELDS.MP.HL.ExistLet12MGrosInc)
                            .mandatory(FIELDS.MP.HL.ExistLet12MBookingNum);
                    },
                    nextQuestionId: () => BLOCK_EXISTING_LET_HISTORY_MARKETING
                };

            case BLOCK_EXISTING_LET_HISTORY_MARKETING:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldCheckboxGroup{...this.getFormField(null, FIELDS.MP.HL.ExistLetMkting)}/>

                            <FieldInput
                                show={this.hasCheckbox(FIELDS.MP.HL.ExistLetMkting, OTHER)}
                                {...this.getFormField(null, FIELDS.MP.HL.ExistLetMktingOther)}
                            />
                            <FieldInput
                                show={this.hasCheckbox(FIELDS.MP.HL.ExistLetMkting, LET_MARKETING.PERSONAL_WEB_SITE)}
                                {...this.getFormField(null, FIELDS.MP.HL.ExistLetMktingWebsite)}
                            />

                            <FieldCheckboxGroup
                                show={this.hasCheckbox(FIELDS.MP.HL.ExistLetMkting, LET_MARKETING.PROFESSIONAL_LETTING_AGENT)}
                                {...this.getFormField(null, FIELDS.MP.HL.ExistLetMktingAgent)}
                            />
                            <FieldInput
                                show={this.hasCheckbox(FIELDS.MP.HL.ExistLetMktingAgent, OTHER)
                                      && this.hasCheckbox(FIELDS.MP.HL.ExistLetMkting, LET_MARKETING.PROFESSIONAL_LETTING_AGENT)}
                                {...this.getFormField(null, FIELDS.MP.HL.ExistLetMktingAgentOther)}
                            />

                            <FieldCheckboxGroup
                                show={this.hasCheckbox(FIELDS.MP.HL.ExistLetMkting, LET_MARKETING.PORTALS_OR_ONLINE_TRAVEL_AGENTS)}
                                {...this.getFormField(null, FIELDS.MP.HL.ExistLetMktingOTA)}
                            />
                            <FieldInput
                                show={this.hasCheckbox(FIELDS.MP.HL.ExistLetMkting, LET_MARKETING.PORTALS_OR_ONLINE_TRAVEL_AGENTS)
                                      && this.hasCheckbox(FIELDS.MP.HL.ExistLetMktingOTA, OTHER)}
                                {...this.getFormField(null, FIELDS.MP.HL.ExistLetMktingOTAOther)}
                            />

                        </QuestionBlock>,
                    validate: (context) => {
                        context
                            .mandatory(FIELDS.MP.HL.ExistLetMkting)
                            .mandatoryOrCleanup(
                                this.hasCheckbox(FIELDS.MP.HL.ExistLetMkting, OTHER),
                                FIELDS.MP.HL.ExistLetMktingOther)
                            .mandatoryOrCleanup(
                                this.hasCheckbox(FIELDS.MP.HL.ExistLetMkting, LET_MARKETING.PERSONAL_WEB_SITE),
                                FIELDS.MP.HL.ExistLetMktingWebsite)
                            .mandatoryAtLeastOneSelectionOrCleanup(
                                this.hasCheckbox(FIELDS.MP.HL.ExistLetMkting, LET_MARKETING.PROFESSIONAL_LETTING_AGENT),
                                FIELDS.MP.HL.ExistLetMktingAgent)
                            .mandatoryOrCleanup(
                                this.hasCheckbox(FIELDS.MP.HL.ExistLetMktingAgent, OTHER)
                                && this.hasCheckbox(FIELDS.MP.HL.ExistLetMkting, LET_MARKETING.PROFESSIONAL_LETTING_AGENT),
                                FIELDS.MP.HL.ExistLetMktingAgentOther)
                            .mandatoryAtLeastOneSelectionOrCleanup(
                                this.hasCheckbox(FIELDS.MP.HL.ExistLetMkting, LET_MARKETING.PORTALS_OR_ONLINE_TRAVEL_AGENTS),
                                FIELDS.MP.HL.ExistLetMktingOTA)
                            .mandatoryOrCleanup(
                                this.hasCheckbox(FIELDS.MP.HL.ExistLetMkting, LET_MARKETING.PORTALS_OR_ONLINE_TRAVEL_AGENTS)
                                && this.hasCheckbox(FIELDS.MP.HL.ExistLetMktingOTA, OTHER),
                                FIELDS.MP.HL.ExistLetMktingOTAOther);
                    },
                    nextQuestionId: () => BLOCK_FUTURE_EXPECTATIONS
                };


            case BLOCK_FUTURE_EXPECTATIONS:
                return {
                    block: (params) =>

                        <QuestionBlock {...params}>
                            
                            <FieldLabel 
                                label='Tell us your forecast'
                            />

                            <FieldLabel 
                                labelType={LABEL_TYPE.Description} 
                                label={`We will mostly likely require a supporting letter from a professional holiday letting agency to confirm the forecast letting income. 
                                    You can use that letter to input some of the figures on this page.`}
                            />

                            <FieldInput 
                                labelType={LABEL_TYPE.Small} 
                                {...this.getFormField(null, FIELDS.MP.HL.Next12MGrossInc)}
                            />

                            <FieldInput 
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.MP.HL.Next12MBookingNum)}
                            />

                            <FieldInput 
                                labelType={LABEL_TYPE.Small} 
                                {...this.getFormField(null, FIELDS.MP.HL.Next12MWeeklyPriceHigh)}
                            />

                            <FieldInput 
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.MP.HL.Next12MWeeklyPriceMed)} 
                            />

                            <FieldInput 
                                labelType={LABEL_TYPE.Small} 
                                {...this.getFormField(null, FIELDS.MP.HL.Next12MWeeklyPriceLow)}
                            />


                            <FieldLabel 
                                labelType={LABEL_TYPE.Large} 
                                label={`Would you like us to introduce you to a holiday letting agent in your area?`}
                            />

                            <FieldLabel 
                                labelType={LABEL_TYPE.Description} 
                                label={`We have forged strategic relationships with the industry’s key holiday letting agencies throughout the UK and we are happy to assist where we can.`}
                            />

                            <FieldRadioYesNo 
                                {...this.getFormField(null, FIELDS.MP.HL.WantsIntroductionToHolidayLetAgent)} 
                            />

                            <Alert 
                                type={ALERTTYPE.SUCCESS}
                                show={() => this.fieldHasValueTrue(null, FIELDS.MP.HL.WantsIntroductionToHolidayLetAgent)}
                                text="Thank you for letting us know. Your advisor will be in touch shortly."
                            />

                       </QuestionBlock>,
                    validate: (context) => {
                        context
                            .optional(FIELDS.MP.HL.Next12MGrossInc)
                            .optional(FIELDS.MP.HL.Next12MBookingNum)
                            .optional(FIELDS.MP.HL.Next12MWeeklyPriceHigh)
                            .optional(FIELDS.MP.HL.Next12MWeeklyPriceMed)
                            .optional(FIELDS.MP.HL.Next12MWeeklyPriceLow)
                            .mandatory(FIELDS.MP.HL.WantsIntroductionToHolidayLetAgent)
                            ;
                    },
                    nextQuestionId: () => this.getFieldValue(null, FIELDS.MP.HL.NewOrExistLet) === NEW_OR_EXISTING_LET.EXISTING_HOLIDAY_LET
                        ? BLOCK_SAME_MARKETING
                        : BLOCK_FUTURE_MARKETING
                };

            case BLOCK_SAME_MARKETING:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldRadioYesNo {...this.getFormField(null, FIELDS.MP.HL.Next12MarketingAsExist)} />
                        </QuestionBlock>,
                    validate: (context) => {
                        context.mandatory(FIELDS.MP.HL.Next12MarketingAsExist);

                        if (this.fieldHasValueTrue(null, FIELDS.MP.HL.Next12MarketingAsExist)) {
                            // Need to remove future marketing fields if next 12 months are planned the same as previous
                            context
                                .cleanupIrrelevantField(FIELDS.MP.HL.Next12MMkting)
                                .cleanupIrrelevantField(FIELDS.MP.HL.Next12MMktingOther)
                                .cleanupIrrelevantField(FIELDS.MP.HL.Next12MMktingWebsite)
                                .cleanupIrrelevantField(FIELDS.MP.HL.Next12MMktingAgent)
                                .cleanupIrrelevantField(FIELDS.MP.HL.Next12MMktingAgentOther)
                                .cleanupIrrelevantField(FIELDS.MP.HL.Next12MMktingOTA)
                                .cleanupIrrelevantField(FIELDS.MP.HL.Next12MMktingOTAOther);
                        }
                    },
                    nextQuestionId: () => this.fieldHasValueTrue(null, FIELDS.MP.HL.Next12MarketingAsExist)
                        ? BLOCK_FURTHER_INFORMATION
                        : BLOCK_FUTURE_MARKETING
                };

            case BLOCK_FUTURE_MARKETING:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldCheckboxGroup{...this.getFormField(null, FIELDS.MP.HL.Next12MMkting)}/>

                            <FieldInput
                                show={this.hasCheckbox(FIELDS.MP.HL.Next12MMkting, OTHER)}
                                {...this.getFormField(null, FIELDS.MP.HL.Next12MMktingOther)}
                            />
                            <FieldInput
                                show={this.hasCheckbox(FIELDS.MP.HL.Next12MMkting, LET_MARKETING.PERSONAL_WEB_SITE)}
                                {...this.getFormField(null, FIELDS.MP.HL.Next12MMktingWebsite)}
                            />

                            <FieldCheckboxGroup
                                show={this.hasCheckbox(FIELDS.MP.HL.Next12MMkting, LET_MARKETING.PROFESSIONAL_LETTING_AGENT)}
                                {...this.getFormField(null, FIELDS.MP.HL.Next12MMktingAgent)}
                            />
                            <FieldInput
                                show={this.hasCheckbox(FIELDS.MP.HL.Next12MMktingAgent, OTHER)
                                      && this.hasCheckbox(FIELDS.MP.HL.Next12MMkting, LET_MARKETING.PROFESSIONAL_LETTING_AGENT)}
                                {...this.getFormField(null, FIELDS.MP.HL.Next12MMktingAgentOther)}
                            />

                            <FieldCheckboxGroup
                                show={this.hasCheckbox(FIELDS.MP.HL.Next12MMkting, LET_MARKETING.PORTALS_OR_ONLINE_TRAVEL_AGENTS)}
                                {...this.getFormField(null, FIELDS.MP.HL.Next12MMktingOTA)}
                            />
                            <FieldInput
                                show={this.hasCheckbox(FIELDS.MP.HL.Next12MMkting, LET_MARKETING.PORTALS_OR_ONLINE_TRAVEL_AGENTS)
                                      && this.hasCheckbox(FIELDS.MP.HL.Next12MMktingOTA, OTHER)}
                                {...this.getFormField(null, FIELDS.MP.HL.Next12MMktingOTAOther)}
                            />

                        </QuestionBlock>,
                    validate: (context) => {
                        context
                            .optional(FIELDS.MP.HL.Next12MMkting)
                            .mandatoryOrCleanup(
                                this.hasCheckbox(FIELDS.MP.HL.Next12MMkting, OTHER),
                                FIELDS.MP.HL.Next12MMktingOther)
                            .mandatoryOrCleanup(
                                this.hasCheckbox(FIELDS.MP.HL.Next12MMkting, LET_MARKETING.PERSONAL_WEB_SITE),
                                FIELDS.MP.HL.Next12MMktingWebsite)
                            .mandatoryAtLeastOneSelectionOrCleanup(
                                this.hasCheckbox(FIELDS.MP.HL.Next12MMkting, LET_MARKETING.PROFESSIONAL_LETTING_AGENT),
                                FIELDS.MP.HL.Next12MMktingAgent)
                            .mandatoryOrCleanup(
                                this.hasCheckbox(FIELDS.MP.HL.Next12MMktingAgent, OTHER)
                                && this.hasCheckbox(FIELDS.MP.HL.Next12MMkting, LET_MARKETING.PROFESSIONAL_LETTING_AGENT),
                                FIELDS.MP.HL.Next12MMktingAgentOther)
                            .mandatoryAtLeastOneSelectionOrCleanup(
                                this.hasCheckbox(FIELDS.MP.HL.Next12MMkting, LET_MARKETING.PORTALS_OR_ONLINE_TRAVEL_AGENTS),
                                FIELDS.MP.HL.Next12MMktingOTA)
                            .mandatoryOrCleanup(
                                this.hasCheckbox(FIELDS.MP.HL.Next12MMkting, LET_MARKETING.PORTALS_OR_ONLINE_TRAVEL_AGENTS)
                                && this.hasCheckbox(FIELDS.MP.HL.Next12MMktingOTA, OTHER),
                                FIELDS.MP.HL.Next12MMktingOTAOther);
                    },
                    nextQuestionId: () => BLOCK_FURTHER_INFORMATION
                };

            case BLOCK_FURTHER_INFORMATION:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldLabel label='Link to brochure or information website'/>
                            <FieldInput labelType={LABEL_TYPE.Description} {...this.getFormField(null, FIELDS.MP.HL.LinktoBrochure)} />
                            <FieldDropdown {...this.getFormField(null, FIELDS.MP.HL.StyleDescr)} />
                            <FieldInput
                                show={this.getFieldValue(null, FIELDS.MP.HL.StyleDescr) === OTHER}
                                {...this.getFormField(null, FIELDS.MP.HL.StyleDescrOther)}
                            />
                            <FieldFileUpload
                                {...this.getFormField(null, FIELDS.MP.HL.Pictures)}
                            />
                        </QuestionBlock>,
                    validate: (context) => {
                        context
                            .optional(FIELDS.MP.HL.LinktoBrochure)
                            .optional(FIELDS.MP.HL.StyleDescr)
                            .optionalOrCleanup(context.fieldValue(FIELDS.MP.HL.StyleDescr) === OTHER, FIELDS.MP.HL.StyleDescrOther)
                            .optional(FIELDS.MP.HL.Pictures)
                    },
                    nextQuestionId: () => CAN_SUBMIT
                };

            default:
                return ErrorPart();
        }
    };
}

export default HolidayLetInformation;
