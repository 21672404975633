import { Auth } from 'aws-amplify';
import React, { Component, Fragment } from 'react';
import FieldInput from 'common-form/components/FieldInput';
import FormButton from '../form/components/FormButton';
import { Alert } from '../form/components';
import { ALERTTYPE } from '../form/components/alert/Alert';
import {isAuth2} from "../amplify-configurator";


class ChangePassword extends Component {


    constructor(props) {
        super(props);
        this.state = {
            newPassword2: null,
            newPassword: null,
            oldPassword: null,
            error: null,
            updated: false,
            changing: false
        };
        //console.log('change password', props, this.state)
    }

    onConfirm = () => {
        this.setState({
            error: null,
            updated: false
        });
        if (!this.canConfirm()) {
            return
        }
        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, this.state.oldPassword, this.state.newPassword);
            })
            .then(data => {
                this.setState({
                    updated: true,
                    newPassword2: null,
                    newPassword: null,
                    oldPassword: null,
                })

            })
            .catch(err => {
                let error = err.message
                switch (error) {
                    case 'Incorrect username or password.':
                        error = 'Existing password entered incorrectly. Please try again!';
                        break
                    case 'Attempt limit exceeded, please try after some time.':
                        error = 'You have entered the wrong password too many times! Please try again later'
                        break
                    default:
                        error = 'Please ensure contains at least 8 characters including 1 uppercase and 1 lowercase letter, and 1 number.'
                }
                this.setState({ error, updated: false })
            });
    }
    canConfirm = () => {
        return this.state.oldPassword
            && this.state.newPassword2
            && this.state.newPassword2 === this.state.newPassword
    }
    render() {
        let components = {
            newPassword2: {
                label: '',
                placeholder: null,
                value: this.state.newPassword2,
                field: 'newPassword2',
                handleFieldFilled: (a, f, newPassword2) => this.setState({ newPassword2 }),
                type: 'password'
            },
            newPassword: {
                label: 'Please enter your new password twice',
                placeholder: null,
                value: this.state.newPassword,
                field: 'newPassword',
                handleFieldFilled: (a, f, newPassword) => this.setState({ newPassword }),
                type: 'password'
            },
            oldPassword: {
                label: 'Please enter your old password',
                placeholder: null,
                value: this.state.oldPassword,
                field: 'oldPassword',
                handleFieldFilled: (a, f, oldPassword) => this.setState({ oldPassword }),
                type: 'password'
            },
            confirm: {
                text: 'Confirm',
                onClick: () => this.onConfirm(),
                disabled: !this.canConfirm()
            },
            changing: {
                text: 'Update',
                onClick: () => this.setState({ changing: true }),
            }
        }
        return (
            <Fragment>
                <div className='row padding-bottom'>
                    <div className='col-12'>
                        <h2>Change password</h2>
                    </div>
                </div>
                {
                    this.state.changing ?


                        <Fragment>
                            <div className='row'>
                                <div className='col-12 small-label'>
                                    <FieldInput {...components.oldPassword} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 small-label'>
                                    <FieldInput {...components.newPassword} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <FieldInput {...components.newPassword2} />
                                </div>
                            </div>
                            <div className='row padding-bottom'>
                                <div className='col-4'>
                                    <FormButton {...components.confirm} />
                                </div>


                            </div>
                        </Fragment>
                        :
                        <div className='row padding-bottom'>
                            <div className='col-12'>
                                <FormButton {...components.changing} disabled={isAuth2()}/>
                            </div>
                        </div>
                }

                <div className='row'>
                    <div className='col-12'>
                        {
                            !!this.state.error &&
                            <Alert type={ALERTTYPE.DANGER} text={this.state.error} />
                        }
                        {
                            this.state.updated &&
                            <Alert text='Your password has been updated' type={ALERTTYPE.SUCCESS} />
                        }
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ChangePassword;

