import { createBrowserHistory } from "history";
import {createStore} from "redux";
import rootReducer from "../redux/reducers";
import {Provider} from "react-redux";
import {Router} from "react-router-dom";
import React from "react";

const store = createStore(rootReducer);
const history = createBrowserHistory();

export function wrap(toWrap) {
    return <Provider store={store}>
        <Router history={history} basename={`${process.env.PUBLIC_URL}/`}>
            <React.Fragment>
                {toWrap}
            </React.Fragment>
        </Router>
    </Provider>;
}

export function apiExtra() {
    let apiExtra = store.getState().apiExtra;
    return apiExtra === undefined ? {} : {queryStringParameters: { API_EXTRA: apiExtra }};
}