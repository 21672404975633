import * as React from "react";
import {
    CAN_SUBMIT,
} from "../../../../constants";
import MultiBlockFormSingleApplicant from "../../../../form/MultiBlockFormSingleApplicant";
import QuestionBlock from "../../../../form/QuestionBlock";
import FieldLabel from "common-form/components/FieldLabel";
import { LABEL_TYPE } from "../../../../form/components/Field";
import FormButton from "../../../../form/components/FormButton";
import FormImage, {IMAGE_WORK} from "../../../../form/components/FormImage";
import {
    FIELDS,
    SECTION,
    SITUATION,
    EMPLOYMENT_TYPE,
    SELF_EMPLOYMENT_TYPE,
    extractFieldName
} from "common-hypotheca";

import EmploymentType from "./employment/EmploymentType";
import EmploymentList from "./employment/EmploymentList";
import SelfEmployed from "./employment/SelfEmployed";

import AnotherEmployment from './employment/AnotherEmployment';

import AnotherIncome from "./employment/AnotherIncome";
import AnotherIncomeList from "./employment/AnotherIncomeList";

import EmploymentBasic from './employment/EmploymentBasic';
import EmploymentEarning from './employment/EmploymentEarning';

import DirectorBasic from "./employment/DirectorBasic";
import DirectorDetails from "./employment/DirectorDetails";

import SoletraderBasic from "./employment/SoletraderBasic";
import SoletraderDetails from "./employment/SoletraderDetails";

import PartnerBasic from "./employment/PartnerBasic";
import PartnerDetails from "./employment/PartnerDetails";

import FinalText from "./employment/FinalText";

import { validateEmployment } from "./employment/validation";
import ValidationContext from "common-form/framework/ValidationContext";
import ErrorPart from "../../../ErrorPart";


const BLOCK_WELCOME = 1;
const BLOCK_EMPLOYMENT_TYPE = 2;

const BLOCK_EMPLOYMENT_BASIC = 3;
const BLOCK_EMPLOYMENT_EARNING = 4;

const BLOCK_ANOTHER_EMPLOYMENT = 8;

const BLOCK_SELF_EMPLOYED = 10;

const BLOCK_DIRECTOR_BASIC = 15;
const BLOCK_DIRECTOR_DETAILS = 16;

const BLOCK_SOLE_TRADER_BASIC = 20;
const BLOCK_SOLE_TRADER_DETAILS = 21;

const BLOCK_PARTNER_BASIC = 25;
const BLOCK_PARTNER_DETAILS = 26;

const BLOCK_ANOTHER_INCOME = 40;
const BLOCK_ANOTHER_INCOME_LIST = 41;

const BLOCK_EMPLOYMENT_LIST = 30;

const BLOCK_FINAL_TEXT = 50;


class EmploymentAndIncome extends MultiBlockFormSingleApplicant {


    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            sectionId: SECTION.SITUATION,
            partId: SITUATION.EMPLOYMENT,
            slideIds: [BLOCK_WELCOME],
            activeSlideId: BLOCK_WELCOME,

            employmentIndex: 0,
        };

    }

    getField = (fieldName) => {
        return this.getFormField(null, fieldName);
    };

    getValue = (fieldName) => {
        return this.getFieldValue(null, fieldName);
    };

    parseDate = (d) => {

        if (!d || d.indexOf('/') < 0) {
            return null;
        }

        const tks = d.split('/');
        return new Date(parseInt(tks[2]), parseInt(tks[1]) - 1, parseInt(tks[0]));
    };

    formatDate = (d) => {
        return d ? d.toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) : '';
    };

    getEmployments = () => {
        return this.getValue(FIELDS.MS.EI.Employments);
    };

    getEmploymentField = (field) => {

        return {
            handleFieldFilled: this.handleFilledField,
            field,
            value: this.getCurrentEmployment()[field],
            fieldsContainer: this.getCurrentEmployment()
        };

    };

    resetSlides = () => {
        // reset slides
        this.setState({
            slideIds: [
                BLOCK_WELCOME,
                BLOCK_EMPLOYMENT_LIST
            ]
        });
    };

    isFirstEmployment = () => {
        return this.state.employmentIndex === 0;
    };

    getCurrentEmployment = () => {
        const employments = this.getEmployments();
        const employment = employments[this.state.employmentIndex];
        return employment;
    };

    getEmploymentDescription = (employment) => {

        const type = employment[extractFieldName(FIELDS.MS.EI.EmploymentType)];
        const name = type === EMPLOYMENT_TYPE.EMPLOYEE.code ? employment[extractFieldName(FIELDS.MS.EI.EmployerName)] : employment[extractFieldName(FIELDS.MS.EI.EmploymentName)];
        const from = `From: ${this.formatDate(this.parseDate(employment[extractFieldName(FIELDS.MS.EI.EmploymentStartDate)]))}`;
        const to = `Until: ${this.formatDate(this.parseDate(employment[extractFieldName(FIELDS.MS.EI.EmploymentEndDate)])) || 'Current'}`;

        return {
            name,
            from,
            to
        };
    };

    editEmployment = (index) => {

        this.setState({
            employmentIndex: index,
            editing: true
        });

        this.forceUpdate(() => {
            this.swiperNext(index === 0 ? BLOCK_EMPLOYMENT_TYPE : BLOCK_ANOTHER_EMPLOYMENT);
        });

    };

    deleteEmployment = (index) => {

        const employments = this.getEmployments();
        employments.splice(index, 1);

        this.saveEmployments(employments);

        this.setState({
            employmentIndex: 0
        });

        this.forceUpdate(() => {

            if (!employments.length) {
                this.resetSlides();
                this.swiperNext(this.afterWelcome());
            } else {
                this.validateAllEmployments();
            }

        });

    };

    saveEmployments = (employments) => {
        this.handleFilledField(null, FIELDS.MS.EI.Employments, employments || this.getEmployments());
    };

    addNewEmployment = () => {

        let employments = this.getEmployments();
        if (!employments) {
            employments = [];
        }

        employments.push({});
        this.saveEmployments(employments);

        this.setState({
            editing: true,
            addNewEmployment: false,
            employmentIndex: employments.length - 1
        });

        this.reopen();
    };

    validateAllEmployments = () => {

        //validate all employments
        const employments = this.getEmployments();

        let allValid = true;
        for (let i = 0; i < employments.length; i++) {

            const context = new ValidationContext(() => employments[i]);
            validateEmployment(this, context, i);
            context.finishAll();

            if (context.hasErrors()) {
                allValid = false;
                break;
            }
        }

        this.setState({
            employmentsValid: allValid
        });

        if (!allValid) {
            this.reopen();
        }

        return allValid;
    };

    afterWelcome = () => {

        const employments = this.getEmployments();
        if (!employments || !employments.length) {

            this.addNewEmployment();
            return BLOCK_EMPLOYMENT_TYPE;

        } else {

            this.setState({
                employmentIndex: 0
            });

            if (employments.length === 1) {
                return BLOCK_EMPLOYMENT_TYPE;
            }

            return BLOCK_EMPLOYMENT_LIST;
        }
    };

    afterEmploymentType = () => {

        const currentEmployment = this.getCurrentEmployment();

        // if user changed employment type, cleanup old data
        if (this.state.employmentTypeChanged) {

            this.setState({
                employmentTypeChanged: false
            });

            const employmentTemp = {};
            employmentTemp[extractFieldName(FIELDS.MS.EI.EmploymentType)] = currentEmployment[extractFieldName(FIELDS.MS.EI.EmploymentType)];

            const employments = this.getEmployments();
            employments[this.state.employmentIndex] = employmentTemp;
            this.saveEmployments(employments);
        }

        switch (currentEmployment[extractFieldName(FIELDS.MS.EI.EmploymentType)]) {

            case EMPLOYMENT_TYPE.EMPLOYEE.code:
                this.validateAllEmployments();
                return BLOCK_EMPLOYMENT_BASIC;

            case EMPLOYMENT_TYPE.SELF_EMPLOYED.code:
                this.validateAllEmployments();
                return BLOCK_SELF_EMPLOYED;

            case EMPLOYMENT_TYPE.NOT_WORKING.code:
            case EMPLOYMENT_TYPE.RETIRED.code:
            default:

                // delete all employments, and set current one to not working / retired
                const employments = this.getEmployments();
                employments.length = 0;

                const employmentTemp = {};
                employmentTemp[extractFieldName(FIELDS.MS.EI.EmploymentType)] = currentEmployment[extractFieldName(FIELDS.MS.EI.EmploymentType)];
                employments.push(employmentTemp);

                this.setState({
                    employmentIndex: 0
                });

                this.saveEmployments(employments);
                this.validateAllEmployments();
                return BLOCK_ANOTHER_INCOME;
        }
    };

    ensureNotSingleRetired = () => {

        const employments = this.getEmployments();

        if (employments.length === 1) {

            const employmentType = employments[0][extractFieldName(FIELDS.MS.EI.EmploymentType)];
            if (employmentType === EMPLOYMENT_TYPE.NOT_WORKING.code || employmentType === EMPLOYMENT_TYPE.RETIRED.code) {

                this.setState({
                    addNewEmployment: false
                });

                this.forceUpdate(() => {
                    this.swiperNext(BLOCK_WELCOME);
                });
            }

        }
    };

    afterEmploymentBasic = () => BLOCK_EMPLOYMENT_EARNING;
    afterDirectorBasic = () => BLOCK_DIRECTOR_DETAILS;
    afterPartnerBasic = () => BLOCK_PARTNER_DETAILS;
    afterSoletraderBasic = () => BLOCK_SOLE_TRADER_DETAILS;

    afterEmploymentList = () => {

        const addAnother = this.state.addNewEmployment;
        if (addAnother) {
            this.addNewEmployment();
        }

        return addAnother ? BLOCK_ANOTHER_EMPLOYMENT : BLOCK_ANOTHER_INCOME;
    };

    afterEmploymentCompleted = () => {
        return BLOCK_EMPLOYMENT_LIST;
    };

    afterAnotherEmployment = () => {

        const employment = this.getCurrentEmployment();
        const employmentType = this.getValue(FIELDS.MS.EI.AnotherEmploymentType);

        let block = null;
        if (employmentType === EMPLOYMENT_TYPE.EMPLOYEE.code) {
            employment[extractFieldName(FIELDS.MS.EI.EmploymentType)] = employmentType;
            delete employment[extractFieldName(FIELDS.MS.EI.SelfEmpType)];
            block = BLOCK_EMPLOYMENT_BASIC;
        } else {
            employment[extractFieldName(FIELDS.MS.EI.EmploymentType)] = EMPLOYMENT_TYPE.SELF_EMPLOYED.code;
            employment[extractFieldName(FIELDS.MS.EI.SelfEmpType)] = employmentType;
            block = this.afterSelfEmployed();
        }

        return block;
    };

    afterSelfEmployed = () => {

        const employmentType = this.getCurrentEmployment()[extractFieldName(FIELDS.MS.EI.SelfEmpType)];

        // if user changed employment type, cleanup old data
        if (this.state.selfTypeChanged) {

            this.setState({
                selfTypeChanged: false
            });

            const currentEmployment = this.getCurrentEmployment();
            const employmentTemp = {};
            employmentTemp[extractFieldName(FIELDS.MS.EI.EmploymentType)] = currentEmployment[extractFieldName(FIELDS.MS.EI.EmploymentType)];
            employmentTemp[extractFieldName(FIELDS.MS.EI.SelfEmpType)] = currentEmployment[extractFieldName(FIELDS.MS.EI.SelfEmpType)];

            const employments = this.getEmployments();
            employments[this.state.employmentIndex] = employmentTemp;
            this.saveEmployments(employments);
        }

        switch (employmentType) {

            case SELF_EMPLOYMENT_TYPE.DIRECTOR:
                return BLOCK_DIRECTOR_BASIC;

            case SELF_EMPLOYMENT_TYPE.SOLE_TRADER:
                return BLOCK_SOLE_TRADER_BASIC;

            case SELF_EMPLOYMENT_TYPE.PARTNER:
            default:
                return BLOCK_PARTNER_BASIC;
        }

    };

    haveOtherIncome = () => {
        return this.getValue(FIELDS.MS.EI.HaveOtherIncome);
    };

    afterAnotherIncome = () => {
        if (this.haveOtherIncome()) {
            return BLOCK_ANOTHER_INCOME_LIST;
        }

        // delete all other incomes
        this.handleFilledField(null, FIELDS.MS.EI.OtherIncomes, []);
        return BLOCK_FINAL_TEXT;
    };

    afterAnotherIncomeList = () => BLOCK_FINAL_TEXT;

    afterFinalText = () => CAN_SUBMIT;

    updateFactFindBeenInThisJobFor = (value) => {
        let currentEmployment = this.getCurrentEmployment();

        if (value === null || value === undefined) {
            delete currentEmployment[extractFieldName(FIELDS.MS.EI.EmploymentBeenInThisJobFor)];
        } else {
            currentEmployment[extractFieldName(FIELDS.MS.EI.EmploymentBeenInThisJobFor)] = value;
        }
    };

    /**
     * @returns {number} of expected slides for this part.
     */
    slidesExpected = () => {
        return (
            1 // BLOCK_WELCOME
            + 1 // BLOCK_EMPLOYMENT_TYPE / BLOCK_EMPLOYMENT_LIST
            + 1 // BLOCK_ANOTHER_INCOME
            + (this.haveOtherIncome() ? 1 : 0) // BLOCK_ANOTHER_INCOME_LIST
            + 1 // BLOCK_FINAL_TEXT
        );
    };

    checkForCompleteState = data => {
        this.partContainer().isComplete = true;
        this.partContainer(null, SECTION.SITUATION, SITUATION.CREDIT).isAvailable = true;
        return data;
    };

    reopen = () => {
        this.partContainer().isComplete = false;
        this.partContainer(null, SECTION.SITUATION, SITUATION.CREDIT).isAvailable = false;
    };

    getQuestion = which => {

        switch (which) {

            case BLOCK_WELCOME:
                return {
                    block: (params) =>
                        <QuestionBlock {...params} controlButtons={null}>
                            <FieldLabel
                                label={`OK, ${this.getApplicantName()}, let’s go through your employment history and other income`}
                            />

                            <FieldLabel
                                labelType={LABEL_TYPE.Description}
                                label={`We will now ask you some questions about your current
                                employment and any other jobs that you have had over the last 2 years, plus details
                                of any other types of income.`}
                            />

                            <FormButton
                                text="I'm ready!"
                                onClick={() => this.swiperNext()}
                            />
                            <FormImage imageType={IMAGE_WORK}/>
                        </QuestionBlock>,
                    nextQuestionId: () => this.afterWelcome()
                };

            case BLOCK_EMPLOYMENT_TYPE:
                return EmploymentType(this);

            case BLOCK_EMPLOYMENT_LIST:
                return EmploymentList(this);

            case BLOCK_EMPLOYMENT_BASIC:
                return EmploymentBasic(this);

            case BLOCK_EMPLOYMENT_EARNING:
                return EmploymentEarning(this);

            case BLOCK_ANOTHER_EMPLOYMENT:
                return AnotherEmployment(this);

            case BLOCK_ANOTHER_INCOME:
                return AnotherIncome(this);

            case BLOCK_ANOTHER_INCOME_LIST:
                return AnotherIncomeList(this);

            case BLOCK_SELF_EMPLOYED:
                return SelfEmployed(this);

            case BLOCK_DIRECTOR_BASIC:
                return DirectorBasic(this);

            case BLOCK_DIRECTOR_DETAILS:
                return DirectorDetails(this);

            case BLOCK_SOLE_TRADER_BASIC:
                return SoletraderBasic(this);

            case BLOCK_SOLE_TRADER_DETAILS:
                return SoletraderDetails(this);

            case BLOCK_PARTNER_BASIC:
                return PartnerBasic(this);

            case BLOCK_PARTNER_DETAILS:
                return PartnerDetails(this);

            case BLOCK_FINAL_TEXT:
                return FinalText(this);

            default:
                return ErrorPart();
        }

    };

}

export default EmploymentAndIncome;
