export const _WALL_TYPE = {
    BRICK: 'brick',
    STONE: 'stone',
    CONCRETE: 'concrete',
    TIMBER_FRAME: 'timber_frame',
    OTHER: 'other'
};

_WALL_TYPE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: _WALL_TYPE.BRICK,
        text: 'Brick'
    },
    {
        code: _WALL_TYPE.STONE,
        text: 'Stone'
    },
    {
        code: _WALL_TYPE.CONCRETE,
        text: 'Concrete'
    },
    {
        code: _WALL_TYPE.TIMBER_FRAME,
        text: 'Timber frame'
    },
    {
        code: _WALL_TYPE.OTHER,
        text: 'Other (add)'
    }
];