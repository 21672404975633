import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import {FIELDS} from "common-hypotheca";

export default function AnotherIncome(parent) {

    return {
        block: (params)=>
            <QuestionBlock {...params}>

                <FieldRadioYesNo
                    {...parent.getField(FIELDS.MS.EI.HaveOtherIncome)}
                />

            </QuestionBlock>,

        validate: (context) => {

            context
            .mandatory(FIELDS.MS.EI.HaveOtherIncome)
            ;

        },
        nextQuestionId: () => parent.afterAnotherIncome()
    };

}