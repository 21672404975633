export const _SOLICITOR_CHOICE_TYPE = {
    MY_OWN: "my_own",
    USE_LENDERS: "use_lenders",
    FIND_ONE: "find_one"
};

_SOLICITOR_CHOICE_TYPE.OPTIONS = [
    {
        code: _SOLICITOR_CHOICE_TYPE.MY_OWN,
        text: "Have my own",
        icon: "accessibility"
    },
    {
        code: _SOLICITOR_CHOICE_TYPE.USE_LENDERS,
        text: "Use the lender's",
        icon: "touch_app"
    },
    {
        code: _SOLICITOR_CHOICE_TYPE.FIND_ONE,
        text: "Please find me one!",
        icon: "face"
    }
];