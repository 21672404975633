import * as React from "react";

import { CAN_SUBMIT } from "../../../../constants";

import MultiBlockFormSingleApplicant from "../../../../form/MultiBlockFormSingleApplicant";
import QuestionBlock from "../../../../form/QuestionBlock";
import FieldLabel from "common-form/components/FieldLabel";
import FormButton from "../../../../form/components/FormButton";
import FormImage, {IMAGE_DRAWERS} from "../../../../form/components/FormImage";
import { LABEL_TYPE } from "../../../../form/components/Field";

import EvaluationType from "./ssi/EvaluationType";
import SurveyorContact from "./ssi/SurveyorContact";
import SolicitorChoice from "./ssi/SolicitorChoice";
import SolicitorDetails from "./ssi/SolicitorDetails";
import PropertyInsurance from "./ssi/PropertyInsurance";
import LifeInsurance from "./ssi/LifeInsurance";
import HaveWill from "./ssi/HaveWill";
import DeclarationOfTruth from "./ssi/DeclarationOfTruth";

import {
    FIELDS,
    SECTION,
    MY_MORTGAGE,
    SOLICITOR_CHOICE_TYPE
} from "common-hypotheca";
import ErrorPart from "../../../ErrorPart";


const BLOCK_WELCOME = 1;
const BLOCK_EVALUATION_TYPE = 5;
const BLOCK_SURVEYOR_CONTACT = 10;
const BLOCK_SOLICITOR_CHOICE = 14;
const BLOCK_SOLICITOR_DETAILS = 20;
const BLOCK_PROPERTY_INSURANCE = 30;
const BLOCK_LIFE_INSURANCE = 40;
const BLOCK_HAVE_WILL = 45;
const BLOCK_DECLARATION_OF_TRUTH = 50;

class Solicitor extends MultiBlockFormSingleApplicant {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            sectionId: SECTION.MY_MORTGAGE,
            partId: MY_MORTGAGE.SOLICITOR,

            slideIds: [BLOCK_WELCOME],
            activeSlideId: BLOCK_WELCOME
        };
    }


    shouldAllowUsePersonalDetailsForSurveyorContact = () => {
        return !this.props.adapter.isMortgageToBuyNewPlace();
    }

    
    componentDidMount() {

        // if it's mortage to buy new place, default surveyor contact to no use my details
        if (!this.shouldAllowUsePersonalDetailsForSurveyorContact()) {
            this.handleFilledField(null, FIELDS.MA.SSI.KeysContactUsePersonal, false);
        }
    };

    /**
     * @returns {number} of expected slides for this part.
     */
    slidesExpected = () => {
        return 3;
    };
    
    checkForCompleteState = data => {
        this.partContainer().isComplete = true;
        return data;
    };

    getField = (fieldName) => {
        return this.getFormField(null, fieldName);
    };

    getValue = (fieldName) => {
        return this.getField(fieldName).value;
    };

    getQuestion = which => {

        switch(which) {

            case BLOCK_WELCOME:

                return {
                    block: (params)=>
                        <QuestionBlock {...params} controlButtons={null}>

                            <FieldLabel
                                label={`Nearly there ${this.getApplicantName()}. Just a few more questions about surveys, solicitors and insurance`}
                            />

                            <FieldLabel
                                label="Now we need to understand what type of survey you require and contact details for a future site visit. We will also ask for the contact details of your preferred solicitor. You are nearly there!"
                                labelType={LABEL_TYPE.Description}
                            />

                            <FormButton
                                text="OK"
                                onClick={() => this.swiperNext()}
                            />

                            <FormImage imageType={IMAGE_DRAWERS}/>

                        </QuestionBlock>,
                    canNext: () => true,
                    nextQuestionId: () => BLOCK_EVALUATION_TYPE
                };

            case BLOCK_EVALUATION_TYPE:
                return EvaluationType(this, BLOCK_SURVEYOR_CONTACT);

            case BLOCK_SURVEYOR_CONTACT:
                return SurveyorContact(this, BLOCK_SOLICITOR_CHOICE);

            case BLOCK_SOLICITOR_CHOICE:
                return SolicitorChoice(this, () => 
                    this.getValue(FIELDS.MA.SSI.SolicitorChoice) === SOLICITOR_CHOICE_TYPE.MY_OWN 
                        ? BLOCK_SOLICITOR_DETAILS
                        : BLOCK_PROPERTY_INSURANCE
                );

            case BLOCK_SOLICITOR_DETAILS:
                return SolicitorDetails(this, BLOCK_PROPERTY_INSURANCE);

            case BLOCK_PROPERTY_INSURANCE:
                return PropertyInsurance(this, BLOCK_LIFE_INSURANCE);

            case BLOCK_LIFE_INSURANCE:
                return LifeInsurance(this, BLOCK_HAVE_WILL);

            case BLOCK_HAVE_WILL:
                return HaveWill(this, BLOCK_DECLARATION_OF_TRUTH);

            case BLOCK_DECLARATION_OF_TRUTH:
                return DeclarationOfTruth(this, CAN_SUBMIT);

            default:
                return ErrorPart();
        }

    };
}

export default Solicitor;
