import React, { Fragment } from "react";
import Credit from './sections/mySituation/parts/Credit'
import PersonalDetails from './sections/mySituation/parts/PersonalDetails';
import MortgageTypeAndApplicants from "./sections/screening/MortgageTypeAndApplicants";
import CreditHistoryAndResidence from './sections/screening/CreditHistoryAndResidence';
import Outgoings from "./sections/mySituation/parts/Outgoings";
import EmploymentAndIncome from "./sections/mySituation/parts/EmploymentAndIncome";
import MortgageDocuments from "./sections/myApplication/parts/MortgageDocuments";
import DocumentUpload from "./sections/myApplication/parts/DocumentUpload";
import { tryLoadPreviousApplicationOrWelcome, loadingApplication } from "../util/links";
import Solicitor from "./sections/myApplication/parts/Solicitor";
import {MortgageDetails, ExpertAdvice, MortgagePreferences, LimitedCompany} from "./sections/myMortgage";
import Error from '../dashboard/Error'
import Assets from "./sections/mySituation/parts/Assets";
import Header from "./header/Header";
import PropertyDetails from "./sections/property/PropertyDetails";

import HolidayLetInformation from "./sections/property/HolidayLetInformation";
import InitialAssessment from "./sections/screening/InitialAssessment";
import KeyLendingCriteria from "./sections/screening/KeyLendingCriteria";

import {
    SECTION,
    SCREENING,
    SITUATION,
    PROPERTY,
    MY_MORTGAGE,
    MY_APPLICATION,
    model
} from "common-hypotheca";
import Loading from "../form/icons/Loading";
import {getValuePossiblyInteger} from "../util/values";
import AutoSaveFailureOverlay from "./AutoSaveFailureOverlay";

class Application extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    defaultChildrenProps() {
        return {
            setUserData:this.props.setUserData,
            history:this.props.history,
            match:this.props.match,
            user:this.props.user,
            data: this.props.data,
            adapter: this.props.adapter,
            applicantId: getValuePossiblyInteger(this.props.match.params.applicantId),
            partId: getValuePossiblyInteger(this.props.match.params.partId),
            sectionId: getValuePossiblyInteger(this.props.match.params.sectionId),
            setData: this.props.setData,
            predictedProgress: this.predictedProgress,
            updateProgress: this.props.updateProgress,
            lastSavedApplication: this.props.lastSavedApplication
        }
    }

    /**
     * @returns {number} Progress based on answers in key areas 0..1.
     */
    predictedProgress = (slideCount, slidesExpected) => {
        /*
         progress based on parts complete - according to Andy shouldn't work this way, should be per individual part.

        let predictedParts = 0;
        let completedParts = 0;
        let applicantCount = this.props.adapter.applicantCount();

        for (let sectionId of model.sectionOrdering) {
            let hasApplicants = model[sectionId].hasApplicants;
            predictedParts += model[sectionId].parts.length * (hasApplicants ? applicantCount : 1);

            if (hasApplicants) {
                for (let applicant of this.props.data.applicants) {
                    for (let partId of model[sectionId].parts.map(part => part.id)) {
                        this.props.adapter.partComplete(applicant.applicantId, sectionId, partId) && completedParts++;
                    }
                }
            } else {
                for (let partId of model[sectionId].parts.map(part => part.id)) {
                    this.props.adapter.partComplete(null, sectionId, partId) && completedParts++;
                }
            }
        }

        let currentPartId = getValuePossiblyInteger(this.props.match.params.partId);
        let currentPartComplete = this.props.adapter.partComplete(
            getValuePossiblyInteger(this.props.match.params.applicantId),
            getValuePossiblyInteger(this.props.match.params.sectionId),
            currentPartId);
        let result = (completedParts + Math.min(slideCount / slidesExpected, currentPartComplete ? 0 : 1)) / predictedParts;

        // console.log('Predicted progress result ' + slideCount + '/' + slidesExpected + ' predicted= ' + predictedParts + 'completed=' + completedParts + ' result=' + result);
        return result;
        */

        return Math.min(slideCount / slidesExpected, 1);
    };

    renderScreening = () => {
        switch (parseInt(this.props.match.params.partId)) {
            case SCREENING.MORTGAGE_TYPE_AND_APPLICANTS:
                return <MortgageTypeAndApplicants {...this.defaultChildrenProps()} />;
            case SCREENING.CREDIT_HISTORY_AND_RESIDENCE:
                return <CreditHistoryAndResidence {...this.defaultChildrenProps()} />;
            case SCREENING.KEY_CRITERIA:
                return <KeyLendingCriteria {...this.defaultChildrenProps()} />;
            case SCREENING.INITIAL_ASSESSMENT:
                return <InitialAssessment {...this.defaultChildrenProps()} />;
            default:
                return null;
        }
    };

    renderSituation = () => {
        switch (parseInt(this.props.match.params.partId)) {
            case SITUATION.PERSONAL_DETAILS:
                return <PersonalDetails
                    {...this.defaultChildrenProps()}
                />;
            case SITUATION.ASSETS:
                return <Assets
                    {...this.defaultChildrenProps()}
                />;
            case SITUATION.EMPLOYMENT:
                return <EmploymentAndIncome
                    {...this.defaultChildrenProps()}
                />;

            case SITUATION.CREDIT:
                return <Credit
                    {...this.defaultChildrenProps()}
                />;

            case SITUATION.OUTGOINGS:
                return <Outgoings
                    {...this.defaultChildrenProps()}
                />;

            default:
                return null;
        }
    };

    renderProperty = () => {
        switch (parseInt(this.props.match.params.partId)) {
            case PROPERTY.DETAILS:
                return <PropertyDetails {...this.defaultChildrenProps()}/>;
            case PROPERTY.LETTING:
                return <HolidayLetInformation {...this.defaultChildrenProps()}/>;
            default:
                return null;
        }
    };

    renderMyMortgage = () => {
        switch (this.props.match.params.partId) {
            case MY_MORTGAGE.MORTGAGE_DETAILS:
                return <MortgageDetails {...this.defaultChildrenProps()} />;
            case MY_MORTGAGE.MORTGAGE_PREFERENCES:
                return <MortgagePreferences {...this.defaultChildrenProps()} />;
            case MY_MORTGAGE.LIMITED_COMPANY:
                return <LimitedCompany  {...this.defaultChildrenProps()} />;
            case MY_MORTGAGE.SOLICITOR:
                return <Solicitor  {...this.defaultChildrenProps()} />;
            case MY_MORTGAGE.EXPERT_ADVICE:
                return <ExpertAdvice {...this.defaultChildrenProps()} />;
            default:
                return null;
        }
    };

    renderMyApplication = () => {
        switch (parseInt(this.props.match.params.partId) || this.props.match.params.partId) {
            case MY_APPLICATION.KEY_DOCS:
                return <MortgageDocuments {...this.defaultChildrenProps()} />
            case MY_APPLICATION.DOC_UPLOAD:
                return <DocumentUpload {...this.defaultChildrenProps()} />
            default:
                return null;
        }
    };

    static getDerivedStateFromProps(props, state) {
        return tryLoadPreviousApplicationOrWelcome(props);
    }

    renderSection = () => {
        switch (this.props.match.params.sectionId) {
            case SECTION.SCREENING:
                return this.renderScreening();
            case SECTION.SITUATION:
                return this.renderSituation();
            case SECTION.PROPERTY:
                return this.renderProperty();
            case SECTION.MY_MORTGAGE:
                return this.renderMyMortgage();
            case SECTION.MY_APPLICATION:
                return this.renderMyApplication();
            default:
                return null;
        }
    };

    render = () => {
        if (loadingApplication(this.props))
            return <Loading/>;

        if (!this.props.data || this.props.applicationId === null || this.props.sectionId === null) { //find a better way for this
            return <Error />
        }
        return (
            <Fragment>
                <Header {...this.props}/>
                {
                    this.renderSection()
                }
                <AutoSaveFailureOverlay history={this.props.history}/>
            </Fragment>

        )
    };
}

export default Application;
