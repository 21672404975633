export const _PROPERTY_TYPE = {
    DETACHED: 'detached',
    SEMI_DETACHED: 'semi-detached',
    TERRACE: 'terrace',
    END_TERRACE: 'end-terrace',
    FLAT: 'flat',
    BUNGALOW: 'bungalow',
    SHARED_OWNERSHIP: 'shared-ownership'
};

_PROPERTY_TYPE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: _PROPERTY_TYPE.DETACHED,
        text: 'Detached house'
    },
    {
        code: _PROPERTY_TYPE.SEMI_DETACHED,
        text: 'Semi-detached house'
    },
    {
        code: _PROPERTY_TYPE.TERRACE,
        text: 'Terrace house'
    },
    {
        code: _PROPERTY_TYPE.END_TERRACE,
        text: 'End of terrace'
    },
    {
        code: _PROPERTY_TYPE.FLAT,
        text: 'Flat or apartment'
    },
    {
        code: _PROPERTY_TYPE.BUNGALOW,
        text: 'Bungalow'
    },
    {
        code: _PROPERTY_TYPE.SHARED_OWNERSHIP,
        text: 'Shared ownership'
    }
];
