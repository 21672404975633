import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import {FieldLabel} from "common-form/components/FieldLabel";
import {LABEL_TYPE} from "../../../../../form/components/Field";
import FieldCheckbox from "common-form/components/FieldCheckbox";
import {Alert, ALERTTYPE} from "../../../../../form/components/alert/Alert";

import {
    FIELDS
} from "common-hypotheca";


export default function DeclarationOfTruth(parent, nextQuestionId) {

    function allConfirmedTrue() {
        return parent.getValue(FIELDS.MA.SSI.ConfirmAllTrue) === true;
    }
   
    return {
        block: (params) =>

            <QuestionBlock {...params}>

                <FieldLabel
                    label={`Declaration of truthfulness`}
                />

                <FieldCheckbox
                    labelType={LABEL_TYPE.Description}
                    checked={!!parent.getValue(FIELDS.MA.SSI.ConfirmAllTrue)}
                    code={!parent.getValue(FIELDS.MA.SSI.ConfirmAllTrue)}
                    onCheckboxClick={val => {
                        parent.handleFilledField(null, FIELDS.MA.SSI.ConfirmAllTrue, val);
                    }}
                    {...parent.getField(FIELDS.MA.SSI.ConfirmAllTrue)}
                />

                <Alert
                    text={`Thanks ${parent.getApplicantName()}. That's all the form filling complete!`}
                    show={() => allConfirmedTrue()}
                    type={ALERTTYPE.SUCCESS}
                />

            </QuestionBlock>,


        validate: (context) => {

            context
            .mandatoryValue(FIELDS.MA.SSI.ConfirmAllTrue, true, 'Please tick the box')
            ;

        },

        nextQuestionId: () => nextQuestionId
    };

};