import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import { FieldCheckboxGroup } from "../../../../../form/components/checkbox";


import {FIELDS} from "common-hypotheca";

export default function CommitmentTypes(parent, nextBlockId) {

    return {

        block: (params)=>
            <QuestionBlock {...params}>
            
                <FieldCheckboxGroup
                    label="Do you have any of the following commitments? Please select each one that applies to you"
                    {...parent.getFormField(null, FIELDS.MS.CC.MyCreditTypes)}
                />
            </QuestionBlock>,

        canNext: () => true,

        validate: (context) => {
            parent.cleanupIrrelevantCommitments(context);
        },

        nextQuestionId: () => parent.nextSelectedCommitmentType(nextBlockId),

        onLoad: () => {
            if (parent.getFieldValue(null, FIELDS.MS.CC.MyCreditTypes) === undefined) {
                parent.handleFilledField(null, FIELDS.MS.CC.MyCreditTypes, []);
            }
        }
    };

}