import * as React from "react";
import { Fragment } from "react";

import FieldLabel from "common-form/components/FieldLabel";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import { LABEL_TYPE } from "common-form/components/Field";

import { CAN_SUBMIT } from "../../../constants";
import QuestionBlock from "../../../form/QuestionBlock";
import MultiBlockForm from "../../../form/MultiBlockForm";
import FieldTextArea from "../../../form/components/FieldTextArea";

import {
    FIELDS,
    SECTION,
    SCREENING
} from "common-hypotheca";
import ErrorPart from "../../ErrorPart";


const BLOCK_APPLICANTS = 1;
const BLOCK_PROPERTY = 2;

class KeyLendingCriteria extends MultiBlockForm {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            sectionId: SECTION.SCREENING,
            partId: SCREENING.KEY_CRITERIA,

            slideIds: [BLOCK_APPLICANTS],
            activeSlideId: BLOCK_APPLICANTS,

            currentApplicantId: null
        };
    }

    /**
     * @returns {number} of expected slides for this part.
     */
    slidesExpected = () => {
        return 2;
    };

    checkForCompleteState = data => {
        this.partContainer().isComplete = true;
        return data;
    };

    getQuestion = which => {

        let yesNoLabels = {
            textYes: 'True',
            textNo: 'False'
        };

        switch (which) {
            case BLOCK_APPLICANTS:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldLabel
                                label="Please tick to confirm your position for each statement"
                            />

                            <FieldLabel
                                labelType={LABEL_TYPE.Description}
                                label="Holiday let mortgages are subject to additional lending criteria and the following are considered important by most lenders, so let's run through them now."
                            />

                            {
                                this.props.adapter.applicantCount() === 1 &&
                                <Fragment>
                                    <FieldRadioYesNo
                                        labelType={LABEL_TYPE.Small}
                                        {...this.getFormField(null, FIELDS.AS.HLC.IndividualIncome)}
                                        {...yesNoLabels}
                                    />
                                    <FieldTextArea
                                        show={this.fieldHasValueFalse(null, FIELDS.AS.HLC.IndividualIncome)}
                                        labelType={LABEL_TYPE.Small}
                                        {...this.getFormField(null, FIELDS.AS.HLC.IndividualIncomeNotes)}
                                    />
                                </Fragment>
                            }
                            {
                                this.props.adapter.applicantCount() > 1 &&
                                <Fragment>
                                    <FieldRadioYesNo
                                        labelType={LABEL_TYPE.Small}
                                        {...this.getFormField(null, FIELDS.AS.HLC.JointIncome)}
                                        {...yesNoLabels}
                                    />
                                    <FieldTextArea
                                        show={this.fieldHasValueFalse(null, FIELDS.AS.HLC.JointIncome)}
                                        labelType={LABEL_TYPE.Small}
                                        {...this.getFormField(null, FIELDS.AS.HLC.JointIncomeNotes)}
                                    />
                                </Fragment>
                            }

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.OwnMainResidence)}
                                {...yesNoLabels}
                            />
                            <FieldTextArea
                                show={this.fieldHasValueFalse(null, FIELDS.AS.HLC.OwnMainResidence)}
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.OwnMainResidenceNotes)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.NotUsedAsDwelling)}
                                {...yesNoLabels}
                            />

                            <FieldTextArea
                                show={this.fieldHasValueFalse(null, FIELDS.AS.HLC.NotUsedAsDwelling)}
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.NotUsedAsDwellingNotes)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.NotPreviouslyLivedIn)}
                                {...yesNoLabels}
                            />

                            <FieldTextArea
                                show={this.fieldHasValueFalse(null, FIELDS.AS.HLC.NotPreviouslyLivedIn)}
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.NotPreviouslyLivedInNotes)}
                            />


                        </QuestionBlock>,
                    validate: (context) => {
                        if (this.props.adapter.applicantCount() === 1)
                            context.mandatory(FIELDS.AS.HLC.IndividualIncome);
                        else
                            context.mandatory(FIELDS.AS.HLC.JointIncome);

                        context
                            .cleanupIrrelevantField(FIELDS.AS.HLC.Over21)
                            .cleanupIrrelevantField(FIELDS.AS.HLC.Over21Notes)
                            .mandatory(FIELDS.AS.HLC.OwnMainResidence)
                            .mandatory(FIELDS.AS.HLC.NotUsedAsDwelling)
                            .mandatory(FIELDS.AS.HLC.NotPreviouslyLivedIn)
                            ;

                        // Single applicant - check individual income fields
                        if (this.props.adapter.applicantCount() === 1) {
                            context.mandatoryOrCleanup(
                                this.fieldHasValueFalse(null, FIELDS.AS.HLC.IndividualIncome),
                                FIELDS.AS.HLC.IndividualIncomeNotes);

                            context.cleanupIrrelevantField(FIELDS.AS.HLC.JointIncome);
                        }

                        // Multiple applicant - check joint income fields
                        if (this.props.adapter.applicantCount() > 1) {
                            context.mandatoryOrCleanup(
                                this.fieldHasValueFalse(null, FIELDS.AS.HLC.JointIncome),
                                FIELDS.AS.HLC.JointIncomeNotes);

                            context.cleanupIrrelevantField(FIELDS.AS.HLC.IndividualIncome);
                        }

                        context.mandatoryOrCleanup(
                            this.fieldHasValueFalse(null, FIELDS.AS.HLC.OwnMainResidence),
                            FIELDS.AS.HLC.OwnMainResidenceNotes);

                        context.mandatoryOrCleanup(
                            this.fieldHasValueFalse(null, FIELDS.AS.HLC.NotUsedAsDwelling),
                            FIELDS.AS.HLC.NotUsedAsDwellingNotes);

                        context.mandatoryOrCleanup(
                            this.fieldHasValueFalse(null, FIELDS.AS.HLC.NotPreviouslyLivedIn),
                            FIELDS.AS.HLC.NotPreviouslyLivedInNotes);

                    },
                    nextQuestionId: () => BLOCK_PROPERTY
                };

            case BLOCK_PROPERTY:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldLabel
                                label='Please answer true or false to the following statements'
                            />

                            <FieldLabel
                                labelType={LABEL_TYPE.Description}
                                label='Just a few more and we are done!'
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.HolidayLetOver50K)}
                                {...yesNoLabels}
                            />
                            <FieldTextArea
                                show={this.fieldHasValueFalse(null, FIELDS.AS.HLC.HolidayLetOver50K)}
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.HolidayLetOver50KNotes)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.MortgageIsInUK)}
                                {...yesNoLabels}
                            />
                            <FieldTextArea
                                show={this.fieldHasValueFalse(null, FIELDS.AS.HLC.MortgageIsInUK)}
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.MortgageIsInUKNotes)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.LetForBusinessOnly)}
                                tooltip="This question is to ensure that you are not an “accidental landlord” who has ended up holiday letting by accident, rather than by design."
                                {...yesNoLabels}
                            />
                            <FieldTextArea
                                show={this.fieldHasValueFalse(null, FIELDS.AS.HLC.LetForBusinessOnly)}
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.LetForBusinessOnlyNotes)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.OccupancyRestnAgriTie)}
                                {...yesNoLabels}
                            />
                            <FieldTextArea
                                show={this.fieldHasValueFalse(null, FIELDS.AS.HLC.OccupancyRestnAgriTie)}
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.OccupancyRestnAgriTieNotes)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.PropertyIsStandardConstruction)}
                                {...yesNoLabels}
                            />
                            <FieldTextArea
                                show={this.fieldHasValueFalse(null, FIELDS.AS.HLC.PropertyIsStandardConstruction)}
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.PropertyIsStandardConstructionNotes)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.HolidayLetIsResidentialType)}
                                {...yesNoLabels}
                            />
                            <FieldTextArea
                                show={this.fieldHasValueFalse(null, FIELDS.AS.HLC.HolidayLetIsResidentialType)}
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.HolidayLetIsResidentialTypeNotes)}
                            />

                        </QuestionBlock>,
                    validate: (context) => {

                        context
                            .mandatory(FIELDS.AS.HLC.HolidayLetOver50K)
                            .mandatory(FIELDS.AS.HLC.LetForBusinessOnly)
                            .mandatory(FIELDS.AS.HLC.OccupancyRestnAgriTie)
                            .mandatory(FIELDS.AS.HLC.PropertyIsStandardConstruction)
                            .mandatory(FIELDS.AS.HLC.HolidayLetIsResidentialType)
                            ;

                        context.mandatoryOrCleanup(
                            this.fieldHasValueFalse(null, FIELDS.AS.HLC.HolidayLetOver50K),
                            FIELDS.AS.HLC.HolidayLetOver50KNotes);

                        context.mandatoryOrCleanup(
                            this.fieldHasValueFalse(null, FIELDS.AS.HLC.MortgageIsInUK),
                            FIELDS.AS.HLC.MortgageIsInUKNotes);

                        context.mandatoryOrCleanup(
                            this.fieldHasValueFalse(null, FIELDS.AS.HLC.LetForBusinessOnly),
                            FIELDS.AS.HLC.LetForBusinessOnlyNotes);

                        context.mandatoryOrCleanup(
                            this.fieldHasValueFalse(null, FIELDS.AS.HLC.OccupancyRestnAgriTie),
                            FIELDS.AS.HLC.OccupancyRestnAgriTieNotes);

                        context.mandatoryOrCleanup(
                            this.fieldHasValueFalse(null, FIELDS.AS.HLC.PropertyIsStandardConstruction),
                            FIELDS.AS.HLC.PropertyIsStandardConstructionNotes);

                        context.mandatoryOrCleanup(
                            this.fieldHasValueFalse(null, FIELDS.AS.HLC.HolidayLetIsResidentialType),
                            FIELDS.AS.HLC.HolidayLetIsResidentialTypeNotes);


                    },
                    nextQuestionId: () => CAN_SUBMIT
                };

            default:
                return ErrorPart();

        }
    };
}

export default KeyLendingCriteria;
