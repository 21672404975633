import React from 'react';
import PropTypes from "prop-types";
import Field from "../Field"; 
import HelpIcon from "common-form/components/HelpIcon";

class FieldRadioPicture extends Field {
    static propTypes = {
        label: PropTypes.string,
        field: PropTypes.any.isRequired,
        value: PropTypes.any,
        show: PropTypes.bool,
        options: PropTypes.array,
        moveOn:PropTypes.number,
        applicantId:PropTypes.number
    };

    static defaultProps = {
        value: null,
        moveOn:null,
        applicantId:null
    };

    render() {
        if (!this.show())
            return null;

        let label = this.label();

        return (
            <div className='form-group'>
                {
                    !!label &&
                    <div className='label label-radio-picture'>{label}</div>
                }
                <div className='picture-radio-container'>
                        {
                            this.props.options.map((option, i) => {
                                let active = option.code === this.props.value ? ' active' : '';
                                let disabled = this.props.disabled ? ' disabled' : '';
                                return <div
                                        className={"picture-radio-item"  + active + disabled}
                                        key={option.code}
                                        onClick={() => this.props.disabled || this.props.handleFieldFilled(this.props.applicantId,this.props.field, option.code,this.props.moveOn)}
                                    >

                                        {
                                            option.tooltip &&
                                            <div className='help-icon-container'>
                                                <HelpIcon>{option.tooltip}</HelpIcon>
                                            </div>                                        
                                        }

                                        <div className="active-indicator"/>
                                        <div className="item-body">
                                            <div className="picture-radio-icon">{option.icon}</div>
                                            <div className="picture-radio-text">
                                                <span className="picture-radio-key">{String.fromCharCode(65 + i)}</span>
                                                {option.text}
                                            </div>
                                        </div>
                                    </div>;
                            })
                        }
                </div>
                {
                    this.renderError()
                }
            </div>
        );
    }
}

export default FieldRadioPicture;