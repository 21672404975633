import React, { Fragment } from "react";
import moment from 'moment'

class Autosave extends React.Component {

    getContent = () => {
        let last = this.props.lastSavedApplication;

        switch (true) {
            case last === null:
                return null;

            case last === true:
                return 'Saving';

            case last === false:
                return <Fragment key="autosave-failed"><span className='failed'>Autosave failed</span></Fragment>;

            case !isNaN(last):
                //return `Autosaved ${Math.ceil((new Date().getTime() - last) / 1000)}s ago`
                return <Fragment key="autosave-succeeded">Autosaved <span className='autosave-time'>at {moment(last).format('HH:mm:ss')}</span></Fragment>;

            default:
                return null;
        }
    };

    render() {

        return (
            <Fragment>
                <div className='autosave'>
                    {this.getContent()}
                </div>

            </Fragment>
        )
    }
}

export default Autosave;
