import * as React from "react";

import FieldInput from "common-form/components/FieldInput";
import FieldDate from "common-form/components/FieldDate";
import FieldLabel from "common-form/components/FieldLabel";
import { LABEL_TYPE } from "common-form/components/Field";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";

import QuestionBlock from "../../../../form/QuestionBlock";
import FieldTextArea from "../../../../form/components/FieldTextArea";
import FieldDropdown from "../../../../form/components/dropdown/FieldDropdown";
import { Alert, ALERTTYPE } from "../../../../form/components/alert/Alert";
import AddressLookup from "../../../../form/components/AddressLookup";

import {
    FIELDS,
    PROPERTY_TYPE,
    PROPERTY_TENURE, FREEHOLD_SHARE
} from "common-hypotheca";


export default function Address(parent, nextBlockId) {

    function isPropertyTenure(type) {
        return parent.getValue(FIELDS.MP.PD.HolidayLetTenure) === type;
    }
    

    function isLeaseholdTenure() {
        return isPropertyTenure(PROPERTY_TENURE.LEASEHOLD);
    }


    function isPropertyType(type) {
        return parent.getValue(FIELDS.MP.PD.HolidayLetPropertyType) === type;
    }

    function isFlatOrApartment() {
        return isPropertyType(PROPERTY_TYPE.FLAT);
    }

    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldLabel
                    label="Please provide us with the following information about the property to be mortgaged"
                />

                <AddressLookup
                    label="Property address"
                    labelType={LABEL_TYPE.Small}
                    fields={[
                        parent.getFormField(null, FIELDS.MP.PD.HolidayLetAddressLine1),
                        parent.getFormField(null, FIELDS.MP.PD.HolidayLetAddressLine2),
                        parent.getFormField(null, FIELDS.MP.PD.HolidayLetTown),
                        parent.getFormField(null, FIELDS.MP.PD.HolidayLetCounty),
                        parent.getFormField(null, FIELDS.MP.PD.HolidayLetPostcode)
                    ]}
                />

                {
                    parent.isMortgageExisting() && 

                    <>
                    <FieldRadioYesNo
                        show={!parent.props.adapter.isLimitedCompany()}
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MP.PD.HolidayLetAreLegalOwners)}
                    />

                    {
                        parent.getValue(FIELDS.MP.PD.HolidayLetAreLegalOwners) === false && 

                        <>
                        <Alert
                            type={ALERTTYPE.DANGER}
                            text="Lenders would normally expect to see each owner as a mortgage applicant!"
                        />

                        <FieldTextArea
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MP.PD.HolidayLetNotLegalOwnersReason)}
                        />
                        </>
                    }

                    </>

                }

                {
                    parent.isMortgageToBuyNewPlace() &&

                    <>
                    <FieldRadioYesNo
                        show={!parent.props.adapter.isLimitedCompany()}
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MP.PD.HolidayLetPurchasersApplicants)}
                    />

                    {
                        parent.getValue(FIELDS.MP.PD.HolidayLetPurchasersApplicants) === false && 
                        <>
                        <Alert 
                            type={ALERTTYPE.DANGER}
                            text="Lenders would normally expect to see each owner as a mortgage applicant!"
                        />

                        <FieldTextArea
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MP.PD.HolidayLetPurchasersApplicantsReason)}
                        />
                        </>
                    }

                    </>

                }

                {
                    parent.isMortgageExisting() && 

                    <>
                    <div className="date-boxes">
                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        {...parent.fieldComponentProperties(FIELDS.MP.PD.HolidayLetPurchaseDate)}
                    />
                    </div>

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        {...parent.fieldComponentProperties(FIELDS.MP.PD.HolidayLetOriginalPurchasePrice)}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        {...parent.fieldComponentProperties(FIELDS.MP.PD.HolidayLetMarketValue)}
                    />

                    <Alert 
                        type={ALERTTYPE.WARNING}
                        show={() => parseFloat(parent.getValue(FIELDS.MP.PD.HolidayLetMarketValue), 0) <= 50000}
                        text="Are you sure? You previously told us the property value was greater than £50,000!"
                    />
    
                    </>
                }

                <FieldDropdown
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MP.PD.HolidayLetPropertyType)}
                />

                {
                    isFlatOrApartment() && 

                    <>
                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MP.PD.HolidayLetFlatNumFloors)}
                        type="number"
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MP.PD.HolidayLetFlatMyFloor)}
                        type="number"
                    />

                    <FieldDropdown
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MP.PD.HolidayLetFlatTypeOf)}
                    />

                    </>
                }

                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MP.PD.HolidayLetNearShop)}
                />

                <FieldTextArea
                    labelType={LABEL_TYPE.Small}
                    show={() => parent.getValue(FIELDS.MP.PD.HolidayLetNearShop) === true}
                    {...parent.getField(FIELDS.MP.PD.HolidayLetNearShopExplanation)}
                />

                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MP.PD.HolidayLetExCouncil)}
                />

                <FieldDropdown
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MP.PD.HolidayLetEpcRating)}
                />

                <FieldDropdown
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MP.PD.HolidayLetTenure)}
                />

                {
                    isLeaseholdTenure() &&
                    <>
                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MP.PD.HolidayLetLeaseUnexpTerm)}
                            type="number"
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MP.PD.HolidayLetLeaseGrdRent)}
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MP.PD.HolidayLetLeaseServChrg)}
                        />

                        <FieldDropdown
                            labelType={LABEL_TYPE.Small}
                            {...parent.fieldComponentProperties(FIELDS.MP.PD.HolidayLetLeaseFreeholdShare)}
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            show={FREEHOLD_SHARE.showDetails(parent.fieldValue(FIELDS.MP.PD.HolidayLetLeaseFreeholdShare))}
                            {...parent.fieldComponentProperties(FIELDS.MP.PD.HolidayLetLeaseFreeholdShareDetails)}
                        />

                        <Alert
                            type={ALERTTYPE.WARNING}
                            show={FREEHOLD_SHARE.showWarning(parent.fieldValue(FIELDS.MP.PD.HolidayLetLeaseFreeholdShare))}
                            text="Most lenders require the share of freehold to be held via a management company. Please check this point with your Advisor!"
                        />
                    </>
                }


            </QuestionBlock>,


        validate: (context) => {
            context
                .mandatory(FIELDS.MP.PD.HolidayLetAddressLine1)
                .optional(FIELDS.MP.PD.HolidayLetAddressLine2)
                .mandatory(FIELDS.MP.PD.HolidayLetTown)
                .optional(FIELDS.MP.PD.HolidayLetCounty)
                .mandatory(FIELDS.MP.PD.HolidayLetPostcode)
                .mandatory(FIELDS.MP.PD.HolidayLetPropertyType)
                .mandatory(FIELDS.MP.PD.HolidayLetNearShop)
                .mandatory(FIELDS.MP.PD.HolidayLetExCouncil)
                .mandatory(FIELDS.MP.PD.HolidayLetEpcRating)
                .mandatory(FIELDS.MP.PD.HolidayLetTenure);

            context.mandatoryOrCleanup(
                parent.getValue(FIELDS.MP.PD.HolidayLetNearShop) === true,
                FIELDS.MP.PD.HolidayLetNearShopExplanation
            );


            context.mandatoryOrCleanup(
                parent.isMortgageToBuyNewPlace()
                && !parent.props.adapter.isLimitedCompany(),
                FIELDS.MP.PD.HolidayLetPurchasersApplicants
            );

            context.mandatoryOrCleanup(
                parent.isMortgageToBuyNewPlace()
                && parent.getValue(FIELDS.MP.PD.HolidayLetPurchasersApplicants) === false
                && !parent.props.adapter.isLimitedCompany(),
                FIELDS.MP.PD.HolidayLetPurchasersApplicantsReason
            );

            
            if (parent.isMortgageExisting()) {
               context
                  .date(FIELDS.MP.PD.HolidayLetPurchaseDate)
                  .greaterThan(FIELDS.MP.PD.HolidayLetMarketValue, 50000);
            }

            context.mandatoryOrCleanup(
                parent.isMortgageExisting(),
                FIELDS.MP.PD.HolidayLetOriginalPurchasePrice
            );

            context.mandatoryOrCleanup(
                parent.isMortgageExisting()
                && !parent.props.adapter.isLimitedCompany(),
                FIELDS.MP.PD.HolidayLetAreLegalOwners
            );

            context.mandatoryOrCleanup(
                parent.isMortgageExisting()
                && parent.getValue(FIELDS.MP.PD.HolidayLetAreLegalOwners) === false
                && !parent.props.adapter.isLimitedCompany(),
                FIELDS.MP.PD.HolidayLetNotLegalOwnersReason
            );

            context.mandatoryOrCleanup(
                isFlatOrApartment(),
                [
                    FIELDS.MP.PD.HolidayLetFlatNumFloors,
                    FIELDS.MP.PD.HolidayLetFlatMyFloor,
                    FIELDS.MP.PD.HolidayLetFlatTypeOf
                ]
            );

            context.mandatoryOrCleanup(
                isLeaseholdTenure(),
                [
                    FIELDS.MP.PD.HolidayLetLeaseUnexpTerm,
                    FIELDS.MP.PD.HolidayLetLeaseGrdRent,
                    FIELDS.MP.PD.HolidayLetLeaseServChrg,
                    FIELDS.MP.PD.HolidayLetLeaseFreeholdShare
                ]
            );

            context.mandatoryOrCleanup(
                FREEHOLD_SHARE.showDetails(parent.fieldValue(FIELDS.MP.PD.HolidayLetLeaseFreeholdShare)),
                FIELDS.MP.PD.HolidayLetLeaseFreeholdShareDetails
            );

            parent.updateMarketValue();

        },

        nextQuestionId: () => nextBlockId
    };

    
};