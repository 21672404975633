import {_OTHER} from "./other";

export const _PAY_FREQUENCY = {
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
    FOUR_WEEK: '4weekly'
};

_PAY_FREQUENCY.OPTIONS = [
    { code: '', text: ''},
    { code: _PAY_FREQUENCY.WEEKLY, text: 'Weekly' },
    { code: _PAY_FREQUENCY.MONTHLY, text: 'Monthly' },
    { code: _PAY_FREQUENCY.FOUR_WEEK, text: '4 Weekly' },
    { code: _OTHER, text: 'Other (add)' }
];
