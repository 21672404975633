export const _SELF_EMPLOYMENT_TYPE = {
    DIRECTOR: 'director',
    SOLE_TRADER: 'sole-trader',
    PARTNER: 'partner',

    DESCRIPTIONS: {}
};

_SELF_EMPLOYMENT_TYPE.OPTIONS = [
    {
        code: _SELF_EMPLOYMENT_TYPE.DIRECTOR,
        text: 'Director & Shareholder',
        icon: 'recent_actors'
    },
    {
        code: _SELF_EMPLOYMENT_TYPE.SOLE_TRADER,
        text: 'Sole trader',
        icon: 'assignment_ind'
    },
    {
        code: _SELF_EMPLOYMENT_TYPE.PARTNER,
        text: 'Partner',
        icon: 'share'
    }
];