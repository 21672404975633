import {_OTHER} from "./other";

export const _OCCUPANT_TYPE = {
    HOME_OWNER: 'home_owner',
    RENTER_TENANT: 'renter_tenant',
    LIVING_WITH_PARENTS: 'livingWithParents',
    LIVING_WITH_FRIENDS: 'livingWithFriends',
    LIVING_WITH_PARTNER: 'living_with_partner'
};

_OCCUPANT_TYPE.OPTIONS = [
    { code: '', text: '' },
    { code: _OCCUPANT_TYPE.HOME_OWNER, text: 'Home owner' },
    { code: _OCCUPANT_TYPE.RENTER_TENANT, text: 'Renter/Tenant' },
    { code: _OCCUPANT_TYPE.LIVING_WITH_PARENTS, text: 'Living with parents' },
    { code: _OCCUPANT_TYPE.LIVING_WITH_FRIENDS, text: 'Living with friends' },
    { code: _OCCUPANT_TYPE.LIVING_WITH_PARTNER, text: 'Living with partner' },
    { code: _OTHER, text: 'Other' }
];

/**
 * Denotes which occupant types requiire mandatory rent field.
 * @type {(string)[]}
 */
_OCCUPANT_TYPE.WITH_RENT = [
    _OCCUPANT_TYPE.RENTER_TENANT,
    _OCCUPANT_TYPE.LIVING_WITH_PARENTS,
    _OCCUPANT_TYPE.LIVING_WITH_FRIENDS,
    _OCCUPANT_TYPE.LIVING_WITH_PARTNER,
    _OTHER
];