import React, { Component } from 'react';
import PropTypes from "prop-types";


/*
    code:reqiured
    label: defaults to code, unless supplied
*/
class FieldDropdownItem extends Component {

    render() {

        return (
            <option disabled={this.props.disabled} value={this.props.code} >{this.props.label || this.props.code}</option>
        );
    }
}

FieldDropdownItem.propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    code: PropTypes.any.isRequired
};

export default FieldDropdownItem;
