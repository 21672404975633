export const _MARKETING_SOURCE = {
    GOOGLE_ADVERT: 'google_advert',
    SYKES_REFERRAL: 'sykes_referral',
    TRAVEL_CHAPTER_REFERRAL: 'travel_chapter',
    HIDEAWAYS_GROUP: 'hideaways_group',
    ANOTHER_HOLIDAY_LET_AGENT_REFERRAL: 'another_holiday_let_agent_referral',
    REAL_ESTATE_AGENT_REFERRAL:'real_estate_agent_referral',
    OTHER: 'other',
};

_MARKETING_SOURCE.AGENT_CODES = {
    'google': _MARKETING_SOURCE.GOOGLE_ADVERT,
    'sykes': _MARKETING_SOURCE.SYKES_REFERRAL,
    'hideaways': _MARKETING_SOURCE.HIDEAWAYS_GROUP,
    'travelchapter': _MARKETING_SOURCE.TRAVEL_CHAPTER_REFERRAL,
    'travelchapterowners': _MARKETING_SOURCE.TRAVEL_CHAPTER_REFERRAL
};

_MARKETING_SOURCE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: 'returning_customer',
        text: 'Returning customer!'
    },
    {
        code: 'personal_recommendation',
        text: 'Personal recommendation'
    },
    {
        code: 'google_search',
        text: 'Google search'
    },
    {
        code: _MARKETING_SOURCE.GOOGLE_ADVERT,
        text: 'Google Advert'
    },
    {
        code: _MARKETING_SOURCE.TRAVEL_CHAPTER_REFERRAL,
        text: 'Holiday Cottages / Travel Chapter referral'
    },
    {
        code: _MARKETING_SOURCE.SYKES_REFERRAL,
        text: 'Sykes Holiday Cottages referral'
    },
    {
        code: _MARKETING_SOURCE.HIDEAWAYS_GROUP,
        text: 'Hideaways Group referral'
    },
    {
        code: _MARKETING_SOURCE.ANOTHER_HOLIDAY_LET_AGENT_REFERRAL,
        text: 'Referral from another holiday let agent'
    },
    {
        code: _MARKETING_SOURCE.REAL_ESTATE_AGENT_REFERRAL,
        text: 'Referral from an estate agent'
    },
    {
        code: 'bing_search',
        text: 'Bing search',
        obsolete: true
    },
    {
        code: 'press_or_magazine',
        text: 'Press or magazine'
    },
    {
        code: 'advertisement',
        text: 'Advertisement'
    },
    {
        code: _MARKETING_SOURCE.OTHER,
        text: 'Other'
    }
];

_MARKETING_SOURCE.FILTER_OPTIONS = [
    {
        options: _MARKETING_SOURCE.OPTIONS
            .filter(element => element.code !== '')
            .map(element => {
                return {
                    value: element.code,
                    label: element.text
                };
            })
    }
];