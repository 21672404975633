import {REFERRALLOCALSTORAGEKEY} from "../constants";
import React from "react";

class AgentLandingRedirect extends React.Component {

    constructor(props) {
        super(props);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount = () => {
        localStorage.setItem(REFERRALLOCALSTORAGEKEY, this.props.match.params.referralcode);
        setTimeout(() => window.location.assign('/'), 100);
    }

    render() {
        return '';
    }
}

export default AgentLandingRedirect;