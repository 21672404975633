import * as React from "react";
import FieldRadioGroup from "../form/components/radio/FieldRadioGroup";
import FieldRadioButton from "../form/components/radio/FieldRadioButton";


class Blank extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }
    }

    handleFieldFilled = (applicantId, name, value) => {
        this.setState({
            value
        });
    };

    render() {
        return (
            <div className="container bg-grey">
                <div className="row">
                    <div className={`col-12`}>
                        <div className="card questions">
                            <div className="card-body">
                                Value: {this.state.value}
                                <FieldRadioGroup label='Radio buttons' field='test' handleFieldFilled={this.handleFieldFilled} value={this.state.value}>
                                    <FieldRadioButton code='1' label='Item 1'/>
                                    <FieldRadioButton code='2' label='Item with very very very very very very long super long text which would come around tadatadatada yada yada yada. Is it time for googling lorem ipsum already or can I get it to wrap around without that'/>
                                    <FieldRadioButton code='3' label='Item 3'/>
                                </FieldRadioGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Blank;