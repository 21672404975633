export const _PROPERTY_USAGE = {
    MAIN: 'main-residence',
    BUY_TO_LET_RENTED: 'buy-let-rented',
    HOLIDAY_LET: 'holiday-let',
    SECOND_HOME: 'second-home',
    OTHER_RESIDENTIAL: 'other-residential',
    MULTI_UNIT_BUY_TO_LET: 'multi-unit-buy-to-let',
    MULTI_UNIT_HOLIDAY_LET: 'multi-unit-holiday-let',
    HOUSE_IN_MULTIPLE_OCCUPATION: 'house-in-multiple-occupation',
    MIXED_USE: 'mixed-use',
    COMMERCIAL: 'commercial',
    EMPTY: 'empty',
    OTHER: 'other',
};

_PROPERTY_USAGE.OPTIONS = [
    { code: '', text: '' },
    { code: _PROPERTY_USAGE.MAIN, text: 'Main residence', obsolete: true },
    { code: _PROPERTY_USAGE.BUY_TO_LET_RENTED, text: 'Buy To Let' },
    { code: _PROPERTY_USAGE.HOLIDAY_LET, text: 'Holiday Let' },
    { code: _PROPERTY_USAGE.SECOND_HOME, text: 'Second Home' },
    { code: _PROPERTY_USAGE.OTHER_RESIDENTIAL, text: 'Other Residential'},
    { code: _PROPERTY_USAGE.MULTI_UNIT_BUY_TO_LET, text: 'Buy To Let (Multi Unit)' },
    { code: _PROPERTY_USAGE.MULTI_UNIT_HOLIDAY_LET, text: 'Holiday Let (Multi Unit)' },
    { code: _PROPERTY_USAGE.HOUSE_IN_MULTIPLE_OCCUPATION, text: 'House in Multiple Occupation (HMO)'},
    { code: _PROPERTY_USAGE.MIXED_USE, text: 'Mixed use' },
    { code: _PROPERTY_USAGE.COMMERCIAL, text: 'Commercial' },
    { code: _PROPERTY_USAGE.EMPTY, text: 'Empty' },
    { code: _PROPERTY_USAGE.OTHER, text: 'Other'}
];

_PROPERTY_USAGE.showDetails = (value) => value === _PROPERTY_USAGE.OTHER;