import React, { Fragment, Component } from 'react';
import { Step} from './'
class Journey extends Component {


    render() {
        let sections = this.props.sections
        let reachedActive = false
        for (var i = 0; i < sections.length; i++) {
            
            if(sections[i].isActive === true){
                reachedActive = true
            } else {
                if(reachedActive){
                    sections[i].lineBefore = true
                } else {
                    sections[i].lineAfter = true
                }
            }
           if(i === sections.length - 1 && !reachedActive){
            sections[i].lineAfter = false
           }
        }


        return (
            <Fragment> 
                <div className='journey'>
                    {
                        sections.map((section, i) =>
                            <Step
                                key={i}
                                text={section.name}
                                onClick={() => this.props.onSectionClick(section)}
                                completed={section.isComplete}
                                active={section.isActive}
                                available={section.isAvailable}
                                lineBefore={section.lineBefore}
                                lineAfter={section.lineAfter}
                            />

                        )
                    }
                </div>

            </Fragment>

        );
    }
}

export default Journey;
