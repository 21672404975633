import { connect } from 'react-redux';
import Account from "./Account";
import { setUserData } from '../redux/actions'

const mapStateToProps = (state, ownProps) => ({
    user: state.user,
    lastSavedUser:state.lastSavedUser
});

const mapDispatchToProps = dispatch => ({
    setUserData: (data) => dispatch(setUserData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Account)
