import React from 'react';
import Header from '../welcome/Header'
import RegisterBase from "./RegisterBase";
import IframeResizer from "iframe-resizer-react";

class Register extends RegisterBase {

    render() {

        let first =

            <React.Fragment>
                <div className="row">
                    <div className='col-12 col-md-6 col-xl-8'>

                        <div className='register-left-block'>

                            <h3 className='head-title d-none d-sm-block'>In just 5 minutes ...</h3>
                            <h1 className='block-title'>Request your free mortgage assessment</h1>

                            <p>
                                Discover how much you can borrow and what the best holiday let mortgage rates are by creating an account and completing our free, initial assessment.
                            </p>

                            <ul className="do-list d-none d-sm-block">
                                <li>Complete the form</li>
                                <li>Answer the assessment questions</li>
                                <li>Receive your personalised results</li>
                                <li>It's that simple!</li>
                            </ul>

                            <p>
                                It takes no more than 5 mins, after which one of our expert mortgage consultants will provide you with your own, personalised results.
                            </p>

                        </div>


                    </div>

                    <div className='col-12 col-md-6 col-xl-4'>
                        {
                            this.renderForm()
                        }
                    </div>
                </div>
                {
                    this.renderAgentInfo()
                }

            </React.Fragment>;

        let second = <React.Fragment>
            <div className='row'>
                <div className='col-12'>
                    {
                        this.renderVerification()
                    }
                </div>
            </div>
        </React.Fragment>;

        return (
            <React.Fragment>
                <div className="register-page">

                    <div className="container main-block">
                        <div className='row'>
                            <div className='col-12'>
                                <Header type={this.state.registered ? Header.TYPES.LOGIN : Header.TYPES.LOGIN_SUPPORT} />
                            </div>
                        </div>
                        <div className="row">

                            {
                                !this.state.registered &&
                                <div className='col-12'>
                                    <div className="card register">
                                        <div className="card-body">
                                            {first}
                                        </div>
                                    </div>

                                </div>
                            }

                            {
                                this.state.registered &&
                                <div className='col-12'>
                                    <div className="card register">
                                        <div className="card-body">
                                            {second}
                                        </div>
                                    </div>

                                </div>
                            }

                        </div>

                        {
                            /*
                                Iframe resizer also uses javascript in page itself, specified in Beaver
                                Builder Layout & CSS on this page.
                             */
                        }
                        <IframeResizer
                            style={{ border:0, width: '1px', minWidth: '100%'}}
                            src='https://www.holidaycottagemortgages.co.uk/include-google-reviews/'
                        />

                    </div>


                </div>

            </React.Fragment>
        );
    }
}

export default Register;

/*
signup Response
{
  "user": {
    "username": "tobin.chew89@gmail.com",
    "pool": {
      "userPoolId": "eu-west-1_7m9HhrTx8",
      "clientId": "6k6926r1e7c6pa06cnet0voc",
      "client": {
        "endpoint": "https://cognito-idp.eu-west-1.amazonaws.com/",
        "userAgent": "aws-amplify/0.1.x js"
      },
      "advancedSecurityDataCollectionFlag": true,
      "storage": {}
    },
    "Session": null,
    "client": {
      "endpoint": "https://cognito-idp.eu-west-1.amazonaws.com/",
      "userAgent": "aws-amplify/0.1.x js"
    },
    "signInUserSession": null,
    "authenticationFlowType": "USER_SRP_AUTH",
    "storage": {}
  },
  "userConfirmed": false,
  "userSub": "3c001768-0406-4c41-b194-695d27871eff"
}

*/