import { _EVALUATION_TYPE } from "./picture_evaluation_type";
import {_NEW_OR_EXISTING_LET} from "./picture_new_or_existing_let";
import {_SOLICITOR_CHOICE_TYPE} from "./picture_solicitor_choice_type";
import {_PURCHASE_STAGE} from "./picture_purchase_stage";

import { _ANOTHER_EMPLOYMENT_TYPE } from "./picture_another_employment_type";
import { _COMMITMENT_TYPE } from "./checkboxes_commitment_types";
import { _ASSET_TYPE } from "./dropdown_asset_type";
import { _DEPENDANT_TYPE} from "./dropdown_dependant_type";
import { _DEPOSIT_SOURCE_TYPE} from "./dropdown_deposit_source_type";
import { _EMPLOYMENT_TYPE } from "./radio_employment_type";
import { _FLAT_TYPE } from "./dropdown_flat_type";
import { _FUNDS_USAGE } from "./dropdown_funds_usage";
import { _GARAGE_TYPE } from "./dropdown_garage_type";
import { _GENDER_TYPE } from "./dropdown_gender_type";
import { _INCOME_TYPE } from "./dropdown_income_type";
import { _INTEREST_RATE_TYPE } from "./dropdown_interest_rate_type";
import { _MORTGAGE_PURPOSE } from "./picture_mortgage_purpose";
import { _MORTGAGE_TYPE } from "./picture_mortgage_type";
import { _PAY_FREQUENCY } from "./dropdown_pay_frequency";
import { _PAY_TYPE } from "./dropdown_pay_type"
import { _PREFERRED_MORTGAGE_TYPE } from "./radio_preferred_mortgage_type";
import { _PENSION_TYPE } from "./dropdown_pension_type";
import { _PROPERTY_TENURE } from "./dropdown_property_tenure";
import { _PROPERTY_TYPE } from "./dropdown_property_type";
import { _RELATIONSHIP_TYPE } from "./dropdown_relationship_type";
import { _REPAYMENT_PREFERENCE_TYPE } from "./radio_repayment_preference_type";
import { _REPAYMENT_STRATEGY_TYPE } from "./radio_repayment_strategy_type";
import { _ROOF_TYPE } from "./dropdown_roof_type";
import { _SELF_CONTAINED_COUNT } from "./dropdown_self_contained_count";
import { _SELF_EMPLOYMENT_TYPE } from "./picture_self_employment_type";
import { _TITLE_TYPE } from "./dropdown_title_type";
import { _WALL_TYPE } from "./dropdown_wall_type";

import {
    _LET_MARKETING,
    _LET_MARKETING_AGENTS,
    _LET_MARKETING_ONLINE_OR_TRAVEL_AGENTS
} from "./checkboxes_let_marketing";

import {_OTHER} from "./other";
import {_OCCUPANT_TYPE} from "./dropdown_occupant_type";
import {_CITIZEN_TEST_OTHER} from "./dropdown_citizen_test_other";
import { _INSURANCE_RESPONSIBLE_TYPE } from "./dropdown_insurance_responsible_type";
import {_COUNTRY} from "./dropdown_country";
import {_MARKETING_SOURCE} from "./dropdown_marketing_source";
import {_MARKETING_SOURCE_LETTING_AGENT} from "./dropdown_markering_source_letting_agent";
import {_OWNER_TYPE} from "./dropdown_owner_type";
import {_PROPERTY_USAGE} from "./dropdown_property_usage";
import {_PAYOFF_FUNDS_SOURCE} from "./dropdown_payoff_funds_source";
import {_EMPLOYEE_TYPE} from "./radio_employee_type";
import {_FULL_OR_PARTIME} from "./radio_full_or_parttime";
import {_ACCOUNTANT_QUALIFICATION_TYPE} from "./dropdnwo_accountant_qualification_type";
import {formatDateStandard, formattedTimeDiffToNow} from "./dates";
import {_EPC_RATING} from "./dropdown_epc";
import {_FREEHOLD_SHARE} from "./dropdown_freehold_share";
import {_PERSONAL_RISK_ITEMS} from "./chexkboxes_personal_risk_items";

export const _OPTIONS_TRUE_FALSE = [
    { code: true, text: 'True' },
    { code: false, text: 'False' },
];

export const _OPTIONS_YES_NO = [
    { code: true, text: 'Yes' },
    { code: false, text: 'No' },
];

const _OPTIONS_YES_NO_DEPENDS = [
    ..._OPTIONS_YES_NO,
    { code: 'depends', text: 'It depends!' },
];

const _OPTIONS_CHECKBOX_CHECKED = [
    { code: true, text: 'Checkbox checked'},
    { code: false, text: 'NOT CHECKED'}
];

// Designations

/** Pound amount decimals stripped */
export const _POUND_AMOUNT = 'pound-amount';
/** Pound amount decimals intact */
export const _POUND_AMOUNT_WITH_DECIMALS = 'pound-amount-with-decimals';
export const _PERCENTAGE = 'percentage';
export const _NUMBER = 'number';

export const _TELEPHONE = 'telephone';

export const _EMAIL = 'email';

// Styles

export const _ADMIN_STYLE_BOLD = 'fact-find-bold';
export const _ADMIN_STYLE_ITALIC = 'fact-find-italic';

/**
 * Fields structure with parameters.
 *
 * NB Fields are ordered. Order is used in admin to display fields in particular sequence.
 */
export const _FIELDS = {
    AS: {
        MTA: {

            Nickname: 'Nickname',

            PhoneNumber: {
                name: 'PhoneNumber',
                label: 'Preferred phone number'
            },

            EmailAddress: {
                name: 'EmailAddress',
                label: 'Preferred email'
            },

            ApplnMortgageType: {
                name: 'ApplnMortgageType',
                adminLabel: 'Purpose of mortgage:',
                options: _MORTGAGE_TYPE.OPTIONS
            },

            PurchaseStage: {
                name: 'PurchaseStage',
                frontendLabel: 'What stage in the purchase process are you at?',
                adminLabel: 'Stage in the purchase process:',
                options: _PURCHASE_STAGE.OPTIONS
            },

            MortgagePurpose: {
                name: 'MortgagePurpose',
                frontendLabel: 'What is the main purpose of the mortgage?',
                adminLabel: 'Main purpose of the mortgage:',
                options: _MORTGAGE_PURPOSE.OPTIONS
            },

            Telephone: {
                name: 'Telephone',
                label: 'Telephone',
                designation: _TELEPHONE
            },
            SMS: {
                name: 'SMS',
                adminLabel: 'SMS enabled:',
                options: _OPTIONS_YES_NO
            },
            PrefEmail: {
                name: 'PrefEmail',
                adminLabel: 'Email:',
                designation: _EMAIL
            },
            PhoneVerified: {
                name: 'PhoneVerified',
                adminLabel: 'Phone verified',
                options: _OPTIONS_YES_NO
            },
            PhoneVerificationRequested: {
                name: 'PhoneVerificationRequested',
                adminLabel: 'Verification code requested',
                options: _OPTIONS_YES_NO
            },
            PrefContactTime: {
                name: 'PrefContactTime',
                adminLabel: 'Contact time:'
            },
            MarketingSource: {
                name: 'MarketingSource',
                frontendLabel: 'Please tell us how you first found out about us?',
                adminLabel: 'Recorded source of enquiry:',
                options: _MARKETING_SOURCE.OPTIONS
            },
            MarketingSourceOther: {
                name: 'MarketingSourceOther',
                adminLabel: ''
            },
            MarketingSourceOtherLettingAgent: {
                name: 'MarketingSourceOtherLettingAgent',
                frontendLabel: 'Can you tell us which one?',
                adminLabel: 'Agent from the recognised list:',
                options: _MARKETING_SOURCE_LETTING_AGENT
            },
            MarketingSourceOtherLettingAgentOther: {
                name: 'MarketingSourceOtherLettingAgentOther',
                adminLabel: 'Other agent details:'
            },
            MarketingSourceEstateAgent: {
                name: 'MarketingSourceEstateAgent',
                label: 'Can you tell us which one?',
                adminLabel: 'Estate agent:'
            },
            PlansSourcingAlternativeFinance: {
                name: 'PlansSourcingAlternativeFinance',
                label: 'Are you currently, or do you plan on, working with another broker to finance this transaction?',
                options: _OPTIONS_YES_NO
            },
            PlansSourcingAlternativeFinanceDescription: {
                name: 'PlansSourcingAlternativeFinanceDescription',
                label: 'Please provide us with further details:'
            },

            ApplicationLimitedCompany: {
                name: 'ApplicationLimitedCompany',
                label: 'Is this application to be made via a Limited Company?',
                options: _OPTIONS_YES_NO
            },

            ApplicantRelationships: {
                name: 'ApplicantRelationships',
                adminLabel: 'Relationship between the applicants:'
            },

            // Obsolete
            MortgageEqualBenefit: {
                name: 'MortgageEqualBenefit',
                adminLabel: 'Will this mortgage benefit all applicants equally?'
            },
            // Obsolete
            MortgageEqualBenefitExpln: 'MortgageEqualBenefitExpln',

        },
        CR: {
            CreditHistory: {
                name: 'CreditHistory',
                adminLabel: 'Poor credit history?',
                options: _OPTIONS_YES_NO
            },
            CreditHistoryExpln: {
                name: 'CreditHistoryExpln',
                adminLabel: 'Credit history explanation'
            },
            CreditHistoryDocs: {
                name: 'CreditHistoryDocs',
                adminLabel: 'Credit history documents',
                fileList: true
            },
            CitizenTest: {
                name: 'CitizenTest',
                label: 'UK or Settled EU Citizen',
                options: _OPTIONS_YES_NO
            },
            CitizenTestOther: {
                name: 'CitizenTestOther',
                adminLabel: 'Citizenship type',
                options: _CITIZEN_TEST_OTHER.OPTIONS
            },
            CitizenExpln: {
                name: 'CitizenExpln',
                adminLabel: 'Citizenship explanation'
            },
            CitizenDocs: {
                name: 'CitizenDocs',
                adminLabel: 'Citizenship documents',
                fileList: true
            },

        },
        // HLC in fields_as_hlc.js
    },
    // My Situation
    MS: {
        // Personal details
        PD: {
            Title: {
                name: 'Title',
                label: 'Please select a title',
                options: _TITLE_TYPE.OPTIONS
            },

            TitleOther: {
                name: 'TitleOther',
                label: 'Other title'
            },

            PassportNameFirst: {
                name: 'PassportNameFirst',
                label: 'What is your first name, per your passport?'
            },

            PassportNameMiddle: {
                name: 'PassportNameMiddle',
                label: 'What are your middle names, per your passport?'
            },

            PassportNameSurname: {
                name: 'PassportNameSurname',
                label: 'What is your surname name, per your passport?'
            },

            HasSurnameChanged: {
                name: 'HasSurnameChanged',
                label: 'Has your surname changed in the past?',
                options: _OPTIONS_YES_NO
            },

            FormerSurname: {
                name: 'FormerSurname',
                label: 'What was your former surname?'
            },

            AdditionalPhoneNumber: {
                name: 'AdditionalPhoneNumber',
                label: 'Preferred phone number',
                designation: _TELEPHONE
            },

            AdditionalEmailAddress: {
                name: 'AdditionalEmailAddress',
                label: 'Preferred email',
                designation: _EMAIL
            },

            DOB: {
                name: 'DOB',
                label: 'What is your date of birth?'
            },
            Age: {
                name: 'Age',
                label: 'Age',
            },
            AgeCalcDate: {
                name: 'AgeCalcDate',
                adminHidden: true
            },
            AgeNow: {
                name: 'AgeNow',
                adminLabel: 'Age now',
                adminCalculatedFrom: 'DOB',
                adminCalculation: formattedTimeDiffToNow,
                adminLabelCalculation: () => {
                    return 'Age calculated at ' + formatDateStandard(new Date());
                },
                adminStyle: _ADMIN_STYLE_ITALIC
            },
            Gender: {
                name: 'Gender',
                label: 'What is your gender?',
                options: _GENDER_TYPE.OPTIONS
            },
            GenderOther: {
                name: 'GenderOther',
                label: 'Other gender'
            },
            Nationality: {
                name: 'Nationality',
                label: 'What is your nationality?',
                options: _COUNTRY
            },
            NINumber: {
                name: 'NINumber',
                label: 'Please tell us your National Insurance number'
            },
            RelationshipStatus: {
                name: 'RelationshipStatus',
                label: 'What is your relationship status?',
                options: _RELATIONSHIP_TYPE.OPTIONS
            },
            RelationshipStatusOther: {
                name: 'RelationshipStatusOther',
                label: 'What was your former surname?'
            },
            RetirementAge: {
                name: 'RetirementAge',
                label: 'What is your expected retirement age, in years?'
            },
            AdjustmentsConsideration: {
                name: 'AdjustmentsConsideration',
                frontendLabel: 'For example, If you would like to receive information in a particular format, have any learning difficulties etc.',
                adminLabel: 'Adjustments to be considered',
                options: _OPTIONS_YES_NO
            },
            AdjustmentsConsiderationDetails: {
                name: 'AdjustmentsConsiderationDetails',
                frontendLabel: 'Please provide details here:',
                adminLabel: 'Adjustments to be considered details'
            },
            ReligiousNeeds: {
                name: 'ReligiousNeeds',
                frontendLabel: 'Do you have ethical or religious needs to be considered when making a recommendation?',
                adminLabel: 'Ethical or religious needs to be considered when making a recommendation',
                options: _OPTIONS_YES_NO
            },
            ReligiousNeedsDetails: {
                name: 'ReligiousNeedsDetails',
                frontendLabel: 'Please provide details here:',
                adminLabel: 'Ethical or religious needs details'
            },
            PersonalRiskItems: {
                name: 'PersonalRiskItems',
                frontendLabel: 'Please tick any of the following that apply. This will help us tailor our service to you, as needed:',
                adminLabel: 'Personal risk items',
                options: _PERSONAL_RISK_ITEMS.OPTIONS,
                format: { style: 'full' }
            },
            PersonalRiskItemsDetails: {
                name: 'PersonalRiskItemsDetails',
                frontendLabel: 'Please provide details here:',
                adminLabel: 'Personal risk items details'
            },

            DoIHaveDependents: {
                name: 'DoIHaveDependents',
                label: 'Do you have any persons who are dependent on you?',
                options: _OPTIONS_YES_NO
            },
            Dependents: {
                name: 'Dependents',
                adminLabel: 'Dependent person'
            },

            // Obsolete, privacy requirements
            DependFirstName: {
                name: 'DependFirstName',
                adminLabel: 'First name'
            },
            // Obsolete, privacy requirements
            DependDOB: {
                name: 'DependDOB',
                adminLabel: 'DOB'
            },
            DependCurrentAge: {
                name: 'DependCurrentAge',
                label: 'Current age'
            },

            DependRelationtome: {
                name: 'DependRelationtome',
                label: 'What is their relationship to you?',
                adminLabel: 'Relationship to me',
                options: _DEPENDANT_TYPE.OPTIONS
            },
            DependRelationtomeOther: {
                name: 'DependRelationtomeOther',
                adminLabel: 'Other'
            },

            DependMaintenanceFeesMonthly: 'DependMaintenanceFeesMonthly',
            DependOtherFeesMonthly: 'DependOtherFeesMonthly',
            DependOtherFeesCategory: 'DependOtherFeesCategory',

            addresses: 'addresses',

            CurrentAddressAddressLine1: {
                name: 'CurrentAddressAddressLine1',
                label: 'Address 1',
                sectionTitle: 'Current Address'
            },
            CurrentAddressAddressLine2: {
                name: 'CurrentAddressAddressLine2',
                label: 'Address 2 (optional)'
            },
            CurrentAddressTown: {
                name: 'CurrentAddressTown',
                label: 'Town or City'
            },
            CurrentAddressCounty: {
                name: 'CurrentAddressCounty',
                label: 'County'
            },
            CurrentAddressPostcode: {
                name: 'CurrentAddressPostcode',
                label: 'Postcode'
            },
            CurrentAddressStartDate: {
                name: 'CurrentAddressStartDate',
                frontendLabel: 'When did you start living at this address?',
                adminLabel: 'Started living'
            },


            // stored computed values
            CurrentAddressAgeYears: {
                // deprecated, replaced by CurrentAddressAge
                name: 'CurrentAddressAgeYears',
                adminLabel: 'Started living years ago'
            },
            CurrentAddressAgeMonths: {
                // deprecated, replaced by CurrentAddressAge
                name: 'CurrentAddressAgeMonths',
                adminLabel: 'Started living months ago'
            },
            
            CurrentAddressAge: {
                name: 'CurrentAddressAge',
                adminLabel: 'Started living ago'
            },

            CurrentAddressAgeNow: {
                name: 'CurrentAddressAgeNow',
                adminCalculatedFrom: 'CurrentAddressStartDate',
                adminCalculation: formattedTimeDiffToNow,
                adminLabelCalculation: () => {
                    return 'Started living ago (as of ' + formatDateStandard(new Date()) + ')';
                },
                adminStyle: _ADMIN_STYLE_ITALIC
            },

            CurrentAddressOccuptType: {
                name: 'CurrentAddressOccuptType',
                frontendLabel: 'What type of occupant are you?',
                adminLabel: 'Type of occupant',
                options: _OCCUPANT_TYPE.OPTIONS
            },
            CurrentAddressOccuptTypeOther: {
                name: 'CurrentAddressOccuptTypeOther',
                adminLabel: 'Other notes'
            },

            CurrentAddressElectRoll: {
                name: 'CurrentAddressElectRoll',
                frontendLabel: 'Are you on the electoral roll for this address?',
                adminLabel: 'On the electoral roll for this address',
                options: _OPTIONS_YES_NO
            },
            CurrentAddressRentMonthly: {
                name: 'CurrentAddressRentMonthly',
                frontendLabel: 'What is your monthly rent?',
                adminLabel: 'Monthly rent',
                designation: _POUND_AMOUNT
            },

            CurrentAddressTimeAt: 'CurrentAddressTimeAt',
            CurrentAddressRentPaidOK: 'CurrentAddressRentPaidOK',
            CurrentAddressRentPaidDetails: 'CurrentAddressRentPaidDetails',
            CurrentAddressNameOfLandlord: 'CurrentAddressNameOfLandlord',
            CurrentAddressLandlordAddressSame: 'CurrentAddressLandlordAddressSame',
            CurrentAddressLandlordAddressOther: 'CurrentAddressLandlordAddressOther',

            PreviousAddresses: {
                name: 'PreviousAddresses',
                adminLabel: 'Previous Address'
            },

            PreviousAddressAddressLine1: {
                name: 'PreviousAddressAddressLine1',
                label: 'Address 1'
            },
            PreviousAddressAddressLine2: {
                name: 'PreviousAddressAddressLine2',
                label: 'Address 2 (optional)'
            },
            PreviousAddressTown: {
                name: 'PreviousAddressTown',
                label: 'Town or City'
            },
            PreviousAddressCounty: {
                name: 'PreviousAddressCounty',
                label: 'County'
            },
            PreviousAddressPostcode: {
                name: 'PreviousAddressPostcode',
                label: 'Postcode'
            },
            PreviousAddressStartDate: {
                name: 'PreviousAddressStartDate',
                frontendLabel: 'When did you start living at this address?',
                adminLabel: 'Started living'
            },

            // stored computed values
            PreviousAddressAgeYears: {
                name: 'PreviousAddressAgeYears',
                adminLabel: 'Started living years ago'
            },
            PreviousAddressAgeMonths: {
                name: 'PreviousAddressAgeMonths',
                adminLabel: 'Started living months ago'
            },

            PreviousAddressAge: {
                name: 'PreviousAddressAge',
                adminLabel: 'Started living months ago',
            },

            PreviousAddressAgeNow: {
                name: 'PreviousAddressAgeNow',
                adminCalculatedFrom: 'PreviousAddressStartDate',
                adminCalculation: formattedTimeDiffToNow,
                adminLabelCalculation: () => {
                    return 'Started living ago (as of ' + formatDateStandard(new Date()) + ')';
                },
                adminStyle: _ADMIN_STYLE_ITALIC
            },

            PreviousAddressOccuptType: {
                name: 'PreviousAddressOccuptType',
                frontendLabel: 'What type of occupant were you?',
                adminLabel: 'Type of occupant',
                options: _OCCUPANT_TYPE.OPTIONS
            },
            PreviousAddressOccuptTypeOther: {
                name: 'PreviousAddressOccuptTypeOther',
                adminLabel: 'Other notes'
            },
        },
        // Employment Information
        EI: {

            Employments: {
                name: 'Employments',
                adminArrayElementLabel: (i) => i === 0 ? 'Current employment' : ('Other employment ' + i)
            },

            EmploymentType: {
                name: 'EmploymentType',
                adminLabel: 'Employment basis',
                options: _EMPLOYMENT_TYPE.OPTIONS
            },
            SelfEmpType: {
                name: 'SelfEmpType',
                adminLabel: 'Self-employment',
                options: _SELF_EMPLOYMENT_TYPE.OPTIONS
            },

            // technical field, will be used to populate, hidden in admin fact find
            AnotherEmploymentType: {
                name: 'AnotherEmploymentType',
                options: _ANOTHER_EMPLOYMENT_TYPE.OPTIONS,
                adminHidden: true
            },

            // Reused in all types of employment for different things
            EmploymentName: {
                name: 'EmploymentName',
                adminLabel: 'Company/Partnership/Business name/Job Title'
            },
            EmploymentDescription: {
                name: 'EmploymentDescription',
                adminLabel: 'Company/Partnership/Business description'
            },
            // Employment type employee only
            EmployerName: {
                name: 'EmployerName',
                frontendLabel: 'What is your employer\'s name?',
                adminLabel: 'Employer\'s name'
            },
            EmployerAddress: {
                name: 'EmployerAddress',
                frontendLabel: 'Address Line 1',
                adminLabel: 'Employer\'s address'
            },
            EmployerAddress2: {
                name: 'EmployerAddress2',
                frontendLabel: 'Address Line 2',
                adminLabel: 'Address Line 2'
            },
            EmployerAddressTown: {
                name: 'EmployerAddressTown',
                frontendLabel: 'Town',
                adminLabel: 'Town'
            },
            EmployerAddressCounty: {
                name: 'EmployerAddressCounty',
                frontendLabel: 'County',
                adminLabel: 'County'
            },
            EmployerAddressPostcode: {
                name: 'EmployerAddressPostcode',
                frontendLabel: 'Postcode',
                adminLabel: 'Postcode'
            },

            // Seems unused
            DirShareBusinessDescr: 'DirShareBusinessDescr',
            DirSharePctCapitalOwned: {
                name: 'DirSharePctCapitalOwned',
                label: 'Percentage of share capital owned',
                designation: _PERCENTAGE
            },
            DirShareTradingAddress: {
                name: 'DirShareTradingAddress',
                label: 'Address line 1'
            },
            DirShareTradingAddress2: {
                name: 'DirShareTradingAddress2',
                label: 'Address line 2'
            },
            DirShareTradingAddressTown: {
                name: 'DirShareTradingAddressTown',
                label: 'Town'
            },
            DirShareTradingAddressCounty: {
                name: 'DirShareTradingAddressCounty',
                label: 'County'
            },
            DirShareTradingAddressPostcode: {
                name: 'DirShareTradingAddressPostcode',
                label: 'Postcode'
            },

            // Seems unused
            SoleTradBusinessDescr:'SoleTradBusinessDescr',
            // Seems unused
            SoleTradPctBizOwned: 'SoleTradPctBizOwned',

            SoleTradTradingAddress: {
                name: 'SoleTradTradingAddress',
                label: 'Address line 1'
            },
            SoleTradTradingAddress2: {
                name: 'SoleTradTradingAddress2',
                label: 'Address line 2'
            },
            SoleTradTradingAddressTown: {
                name: 'SoleTradTradingAddressTown',
                label: 'Town'
            },
            SoleTradTradingAddressCounty: {
                name: 'SoleTradTradingAddressCounty',
                label: 'County'
            },
            SoleTradTradingAddressPostcode: {
                name: 'SoleTradTradingAddressPostcode',
                label: 'Postcode'
            },

            // Seems unused
            PartnerBusinessDescr:'PartnerBusinessDescr',
            PartnerPctProfitShare: {
                name: 'PartnerPctProfitShare',
                label: 'Percentage of profit share',
                designation: _PERCENTAGE
            },
            PartnerTradingAddress: {
                name: 'PartnerTradingAddress',
                frontendLabel: 'Address line 1',
                adminLabel: 'Firm address'
            },
            PartnerTradingAddress2: {
                name: 'PartnerTradingAddress2',
                label: 'Address line 2'
            },
            PartnerTradingAddressTown: {
                name: 'PartnerTradingAddressTown',
                label: 'Town'
            },
            PartnerTradingAddressCounty: {
                name: 'PartnerTradingAddressCounty',
                label: 'County'
            },
            PartnerTradingAddressPostcode: {
                name: 'PartnerTradingAddressPostcode',
                label: 'Postcode'
            },

            EmploymentStartDate: {
                name: 'EmploymentStartDate',
                adminLabel: 'Start date'
            },
            EmploymentIsCurrent: {
                name: 'EmploymentIsCurrent',
                label: 'Is this a current job?',
                options: _OPTIONS_YES_NO
            },
            EmploymentEndDate: {
                name: 'EmploymentEndDate',
                adminLabel: 'End date'
            },

            // For fact find display
            EmploymentBeenInThisJobFor: {
                name: 'EmploymentBeenInThisJobFor',
                adminLabel: 'Been in this job for'
            },

            EmploymentBeenInThisJobForNow: {
                name: 'EmploymentBeenInThisJobForNow',
                adminCalculatedFrom: ['EmploymentStartDate', 'EmploymentEndDate'],
                adminCalculation: (data) => {
                    if (data['EmploymentEndDate'] !== undefined) {
                        return undefined;
                    }

                    const startDate = data['EmploymentStartDate'];
                    return formattedTimeDiffToNow(startDate);
                },
                adminLabelCalculation: () => {
                    return 'Been in this job for (as of ' + formatDateStandard(new Date()) + ')';
                },
                adminStyle: _ADMIN_STYLE_ITALIC
            },

            ContractType: {
                name: 'ContractType',
                adminLabel: 'Employment type',
                options: _EMPLOYEE_TYPE.OPTIONS
            },
            TempMonthsLeft: {
                name: 'TempMonthsLeft',
                label: 'How many months do you have left?'
            },
            FullorPartTime: {
                name: 'FullorPartTime',
                label: 'Is your job?',
                options: _FULL_OR_PARTIME.OPTIONS
            },

            InProbationPeriod: {
                name: 'InProbationPeriod',
                label: 'Are you currently in a probation period?',
                options: _OPTIONS_YES_NO
            },
            ProbationMonthsLeft: {
                name: 'ProbationMonthsLeft',
                frontendLabel: 'Please tell us how many months you have left in probation',
                adminLabel: 'Months left in probation'
            },

            // Employment (employee) earning
            BasicAnnualSal: {
                name: 'BasicAnnualSal',
                label: 'Basic annual salary',
                designation: _POUND_AMOUNT
            },
            GuaranteAnnualCarAllow: {
                name: 'GuaranteAnnualCarAllow',
                label: 'Guaranteed annual allowance (e.g. car allowance)',
                designation: _POUND_AMOUNT
            },
            PayFrequency: {
                name: 'PayFrequency',
                label: 'How frequently are you paid?',
                options: _PAY_FREQUENCY.OPTIONS
            },
            PayFrequencyOther: {
                name: 'PayFrequencyOther',
                label: 'Other payment frequency description'
            },
            HowAreYouPaid: {
                name: 'HowAreYouPaid',
                label: 'How does your employer pay you?',
                options: _PAY_TYPE.OPTIONS
            },
            HowAreYouPaidOther: {
                name: 'HowAreYouPaidOther',
                label: 'Other payment method description'
            },
            NetMonthlyPayment: {
                name: 'NetMonthlyPayment',
                label: 'Net of tax monthly payment, per your current payslip',
                designation: _POUND_AMOUNT
            },
            AnnualRegularOT: {
                name: 'AnnualRegularOT',
                label: 'Regular annual overtime',
                designation: _POUND_AMOUNT
            },
            RegularOTGuaranteed: {
                name: 'RegularOTGuaranteed',
                frontendLabel: 'Is this guaranteed?',
                adminLabel: 'Is regular annual overtime guaranteed?',
                options: _OPTIONS_YES_NO
            },
            AnnualRegularBonus: {
                name: 'AnnualRegularBonus',
                label: 'Regular annual bonus',
                designation: _POUND_AMOUNT
            },
            RegularBonusGuaranteed: {
                name: 'RegularBonusGuaranteed',
                frontendLabel: 'Is this guaranteed?',
                adminLabel: 'Is regular annual bonus guaranteed?',
                options: _OPTIONS_YES_NO
            },
            AnnualRegularComm: {
                name: 'AnnualRegularComm',
                label: 'Regular annual commission',
                designation: _POUND_AMOUNT
            },
            RegularComGuaranteed: {
                name: 'RegularComGuaranteed',
                frontendLabel: 'Is this guaranteed?',
                adminLabel: 'Is regular annual commission guaranteed?',
                options: _OPTIONS_YES_NO
            },

            // Seems unused
            NatureBusiness: 'NatureBusiness',
            // Seems unused
            PctCapitalOwned: 'PctCapitalOwned',
            // Seems unused
            PctProfitShare: 'PctProfitShare',
            // Seems unused
            PctBizOwned: 'PctBizOwned',
            // Seems unused
            TradingAddress: 'TradingAddress',
            // Seems unused
            Website: 'Website',
            // Seems unused
            HowLongBizEstab: 'HowLongBizEstab',
            // Seems unused
            HowLongPtrspEstab: 'HowLongPtrspEstab',
            // Seems unused
            HowLongWithPtrsp: 'HowLongWithPtrsp',
            // Seems unused
            HowLongWithComp: 'HowLongWithComp',
            // Seems unused
            DoYouHaveAccountant: 'DoYouHaveAccountant',
            // Seems unused
            AccountantName: 'AccountantName',
            // Seems unused
            AccountantQual: 'AccountantQual',
            // Seems unused
            DoYouProdAccts: 'DoYouProdAccts',

            DirShareDoYouHaveAccountant: {
                name: 'DirShareDoYouHaveAccountant',
                label: 'Do you have an accountant?',
                options: _OPTIONS_YES_NO
            },
            DirShareAccountantName: {
                name: 'DirShareAccountantName',
                label: 'Name of accountant'
            },
            DirShareAccountantQual: {
                name: 'DirShareAccountantQual',
                label: 'Qualification held by accountant?',
                options: _ACCOUNTANT_QUALIFICATION_TYPE.OPTIONS
            },
            DirShareAccountantQualOther: {
                name: 'DirShareAccountantQualOther',
                adminLabel: 'Other qualification description'
            },
            DirShareDoYouProdAccts: {
                name: 'DirShareDoYouProdAccts',
                label: 'Do you produce accounts?',
                options: _OPTIONS_YES_NO
            },

            // Obsolete
            HaveTakenCovid19Grants: {
                name: 'HaveTakenCovid19Grants',
                label: 'Have you taken any Covid-19 Government grants, income support or loans?',
                options: _OPTIONS_YES_NO
            },

            // Obsolete
            HaveTakenCovid19GrantsDetails: {
                name: 'HaveTakenCovid19GrantsDetails',
                label: 'Please provide further details of what was taken'
            },

            Covid19Impact: {
                name: 'Covid19Impact',
                label: 'Please describe the impact of Covid-19 on the business and if it has returned to pre-pandemic levels'
            },

            DirShareBusinessIncome: {
                name: 'DirShareBusinessIncome',
                label: 'Is the business continuing to trade normally?',
                adminLabel: 'Is the business continuing to trade normally?',
                options: _OPTIONS_YES_NO
            },
            DirShareBusinessIncomeNoReason: {
                name: 'DirShareBusinessIncomeNoReason',
                label: 'Can you please tell us more about this situation?'
            },
            DirShareBusinessIncomeChangedSignificantly: {
                name: 'DirShareBusinessIncomeChangedSignificantly',
                label: 'Has the nature of the business changed significantly since the last tax year?',
                adminLabel: 'Has the business significantly changed?',
                options: _OPTIONS_YES_NO
            },
            DirShareBusinessIncomeChangedReason: {
                name: 'DirShareBusinessIncomeChangedReason',
                label: 'Please explain the nature of the changes'
            },

            SoleTradDoYouHaveAccountant: {
                name: 'SoleTradDoYouHaveAccountant',
                label: 'Do you have an accountant?',
                options: _OPTIONS_YES_NO
            },
            SoleTradAccountantName: {
                name: 'SoleTradAccountantName',
                label: 'Name of accountant'
            },
            SoleTradAccountantQual: {
                name: 'SoleTradAccountantQual',
                label: 'Qualification held by accountant?',
                options: _ACCOUNTANT_QUALIFICATION_TYPE.OPTIONS
            },
            SoleTradAccountantQualOther: {
                name: 'SoleTradAccountantQualOther',
                adminLabel: 'Other qualification description'
            },
            SoleTradDoYouProdAccts: {
                name: 'SoleTradDoYouProdAccts',
                label: 'Do you produce accounts?',
                options: _OPTIONS_YES_NO
            },

            SoleTradBusinessIncome: {
                name: 'SoleTradBusinessIncome',
                label: 'Is the business continuing to trade normally?',
                adminLabel: 'Is the business continuing to trade normally?',
                options: _OPTIONS_YES_NO
            },
            SoleTradBusinessIncomeNoReason: {
                name: 'SoleTradBusinessIncomeNoReason',
                label: 'Can you please tell us more about this situation?'
            },
            SoleTradBusinessIncomeChangedSignificantly: {
                name: 'SoleTradBusinessIncomeChangedSignificantly',
                label: 'Has the nature of the business changed significantly since the last tax year?',
                adminLabel: 'Has the business significantly changed?',
                options: _OPTIONS_YES_NO
            },
            SoleTradBusinessIncomeChangedReason: {
                name: 'SoleTradBusinessIncomeChangedReason',
                label: 'Please explain the nature of the changes'
            },



            PartnerDoYouHaveAccountant: {
                name: 'PartnerDoYouHaveAccountant',
                label: 'Do you have an accountant?',
                options: _OPTIONS_YES_NO
            },
            PartnerAccountantName: {
                name: 'PartnerAccountantName',
                label: 'Name of accountant'
            },
            PartnerAccountantQual: {
                name: 'PartnerAccountantQual',
                label: 'Qualification held by accountant?',
                options: _ACCOUNTANT_QUALIFICATION_TYPE.OPTIONS
            },
            PartnerAccountantQualOther: {
                name: 'PartnerAccountantQualOther',
                adminLabel: 'Other qualification description'
            },
            PartnerDoYouProdAccts: {
                name: 'PartnerDoYouProdAccts',
                label: 'Do you produce accounts?',
                options: _OPTIONS_YES_NO
            },

            PartnerBusinessIncome: {
                name: 'PartnerBusinessIncome',
                label: 'Is the business continuing to trade normally?',
                adminLabel: 'Is the business continuing to trade normally?',
                options: _OPTIONS_YES_NO
            },
            PartnerBusinessIncomeNoReason: {
                name: 'PartnerBusinessIncomeNoReason',
                label: 'Can you please tell us more about this situation?'
            },
            PartnerBusinessIncomeChangedSignificantly: {
                name: 'PartnerBusinessIncomeChangedSignificantly',
                label: 'Has the nature of the business changed significantly since the last tax year?',
                adminLabel: 'Has the business significantly changed?',
                options: _OPTIONS_YES_NO
            },
            PartnerBusinessIncomeChangedReason: {
                name: 'PartnerBusinessIncomeChangedReason',
                label: 'Please explain the nature of the changes'
            },

            Sharings: {
                name: 'Sharings',
                adminLabel: 'Employment historical financial information'
            },
            SharingPeriod: {
                name: 'SharingPeriod',
                label: 'Period ending'
            },
            SharingSalary: {
                name: 'SharingSalary',
                label: 'Salary',
                designation: _POUND_AMOUNT
            },
            SharingDividends: {
                name: 'SharingDividends',
                label: 'Dividends',
                designation: _POUND_AMOUNT
            },
            ProfitAfterTax: {
                name: 'ProfitAfterTax',
                label: 'Profit after tax',
                designation: _POUND_AMOUNT
            },
            SharingProfit: {
                name: 'SharingProfit',
                label: 'Net profit before tax',
                designation: _POUND_AMOUNT
            },
            SharingTotalDrawings: {
                name: 'SharingTotalDrawings',
                label: 'Share of net profit',
                designation: _POUND_AMOUNT
            },

            DirShareNetMonthlyPayment: {
                name: 'DirShareNetMonthlyPayment',
                label: 'For any employed salary, please enter your net monthly pay, per your payslip',
                designation: _POUND_AMOUNT
            },

            // seems unused
            DirShareStartDate: 'DirShareStartDate',
            // Seems unused
            SoleTradStartDate: 'SoleTradStartDate',
            // Seems unused
            PartnerStartDate:'PartnerStartDate',

            HaveOtherIncome: {
                name: 'HaveOtherIncome',
                label: 'Do you have any other income such as a pension or rental profits?',
                sectionTitle: 'Other income',
                options: _OPTIONS_YES_NO
            },

            OtherIncomes: 'OtherIncomes',

            OtherIncomeType: {
                name: 'OtherIncomeType',
                label: 'Select other income type',
                options: _INCOME_TYPE.OPTIONS
            },
            OtherIncomeTypeOther: {
                name: 'OtherIncomeTypeOther',
                label: 'Other income type description',
            },
            OtherIncomeAnnualRegularAmt: {
                name: 'OtherIncomeAnnualRegularAmt',
                label: 'Amount of regular annual income',
                designation: _POUND_AMOUNT
            },
            OtherIncomeGuaranteed: {
                name: 'OtherIncomeGuaranteed',
                label: 'Is this amount guaranteed?',
                options: _OPTIONS_YES_NO
            },
            OtherIncomePensionType: {
                name: 'OtherIncomePensionType',
                label: 'Pension type',
                options: _PENSION_TYPE.OPTIONS
            },
            OtherIncomePensionTypeOther: {
                name: 'OtherIncomePensionTypeOther',
                label: 'Other pension type description',
            },
            OtherIncomeBenefitsDesc: {
                name: 'OtherIncomeBenefitsDesc',
                label: 'What type of benefit are you receiving?',
            },


            ChangesToIncome: {
                name: 'ChangesToIncome',
                adminLabel: 'Possible income change reasons'
            }

        },
        // Assets
        AS: {

            CurrentAddrDatePurchased: {
                name: 'CurrentAddrDatePurchased',
                label: 'Date purchased',

                sectionTitle: 'Current Address'
            },
            CurrentAddrPurchasePrice: {
                name: 'CurrentAddrPurchasePrice',
                label: 'Purchase price',
                designation: _POUND_AMOUNT
            },
            CurrentAddrOwnedBy: {
                name: 'CurrentAddrOwnedBy',
                label: 'Owned by',
                options: _OWNER_TYPE.OPTIONS
            },
            CurrentAddrPctOwned: {
                name: 'CurrentAddrPctOwned',
                label: 'What percentage of the property do you own?',
                designation: _PERCENTAGE
            },
            CurrentAddrMktValue: {
                name: 'CurrentAddrMktValue',
                label: 'What is the current market value of the property?',
                designation: _POUND_AMOUNT
            },
            CurrentAddrPropertyType: {
                name: 'CurrentAddrPropertyType',
                label: 'What type of property is it?',
                options: _PROPERTY_TYPE.OPTIONS
            },
            CurrentAddrHaveMortgage: {
                name: 'CurrentAddrHaveMortgage',
                label: 'Do you have a mortgage secured on this property?',
                options: _OPTIONS_YES_NO
            },

            CurrentAddrMortProviderName: {
                name: 'CurrentAddrMortProviderName',
                label: 'Name of the mortgage provider'
            },
            /* Unused */
            CurrentAddrMortProviderAddr: 'CurrentAddrMortProviderAddr',
            CurrentAddrMortProviderAccNo: {
                name: 'CurrentAddrMortProviderAccNo',
                label: 'Mortgage account number'
            },
            CurrentAddrMortStartDate: {
                name: 'CurrentAddrMortStartDate',
                label: 'Mortgage start date'
            },
            CurrentAddrMortTermYears: {
                name: 'CurrentAddrMortTermYears',
                label: 'Original mortgage term, in years'
            },
            CurrentAddrMortBorrowingAmount: {
                name: 'CurrentAddrMortBorrowingAmount',
                label: 'Original borrowing amount',
                designation: _POUND_AMOUNT
            },
            CurrentAddrMortType: {
                name: 'CurrentAddrMortType',
                label: 'Mortgage type',
                options: [
                    { code: 'interest', text: 'Interest only' },
                    { code: 'repayment', text: 'Repayment' }
                ]
            },
            CurrentAddrMortCurrentBalOS: {
                name: 'CurrentAddrMortCurrentBalOS',
                label: 'Current balance outstanding',
                designation: _POUND_AMOUNT
            },
            CurrentAddrMortCurrentMonthPay: {
                name: 'CurrentAddrMortCurrentMonthPay',
                label: 'Current monthly payment',
                designation: _POUND_AMOUNT
            },
            CurrentAddrMortSellToPayOff: {
                name: 'CurrentAddrMortSellToPayOff',
                label: 'I will sell this property and pay off any mortgage before completing my new mortgage',
                options: _OPTIONS_YES_NO
            },
            CurrentAddrMortSelltoPayOffSalePrice: {
                name: 'CurrentAddrMortSelltoPayOffSalePrice',
                label: 'What is the expected sale price?',
                designation: _POUND_AMOUNT
            },
            CurrentAddrMortWillBeReleased: {
                name: 'CurrentAddrMortWillBeReleased',
                label: 'Will you be released from the mortgage in any way, prior to completion?',
                options: _OPTIONS_YES_NO
            },
            CurrentAddrMortWillBeReleasedDescr: {
                name: 'CurrentAddrMortWillBeReleasedDescr',
                label: 'Please provide us with more details'
            },
            CurrentAddrMortSecondCharge: {
                name: 'CurrentAddrMortSecondCharge',
                label: 'Do you have a second charge mortgage on this property?',
                options: _OPTIONS_YES_NO
            },
            CurrentAddrMortSecondChargeName: {
                name: 'CurrentAddrMortSecondChargeName',
                label: 'Name of the mortgage provider'
            },
            CurrentAddrMortSecondChargeBalance: {
                name: 'CurrentAddrMortSecondChargeBalance',
                label: 'Current balance outstanding',
                designation: _POUND_AMOUNT
            },
            CurrentAddrMortSecondChargeMonthlyPayment: {
                name: 'CurrentAddrMortSecondChargeMonthlyPayment',
                label: 'Current monthly payment',
                designation: _POUND_AMOUNT
            },

            MainResAndHHOwnMoreProps: {
                name: 'MainResAndHHOwnMoreProps',
                adminLabel: 'Other properties owned apart from main residence and the holiday home to mortgage',
                options: _OPTIONS_YES_NO
            },

            AddProperties: {
                name: 'AddProperties',
                adminLabel: 'Additional property'
            },

            AddPropertyAddressLine1: {
                name: 'AddPropertyAddressLine1',
                label: 'Address 1'
            },
            AddPropertyAddressLine2: {
                name: 'AddPropertyAddressLine2',
                frontendLabel: 'Address 2 (optional)',
                adminLabel: 'Address 2'
            },
            AddPropertyTown: {
                name: 'AddPropertyTown',
                label: 'Town or City'
            },
            AddPropertyCounty: {
                name: 'AddPropertyCounty',
                label: 'County'
            },
            AddPropertyPostcode: {
                name: 'AddPropertyPostcode',
                label: 'Postcode'
            },
            AddPropertyDatePurchased: {
                name: 'AddPropertyDatePurchased',
                label: 'Date purchased'
            },
            AddPropertyOwnedBy: {
                name: 'AddPropertyOwnedBy',
                label: 'Owned by',
                options: _OWNER_TYPE.OPTIONS
            },
            AddPropertyPcTOwned: {
                name: 'AddPropertyPcTOwned',
                label: 'What percentage of the property do you own?',
                designation: _PERCENTAGE
            },
            AddPropertyMktValue: {
                name: 'AddPropertyMktValue',
                label: 'What is the current market value of the property?',
                designation: _POUND_AMOUNT
            },
            AddPropertyPropertyType: {
                name: 'AddPropertyPropertyType',
                label: 'What type of property is it',
                options: _PROPERTY_TYPE.OPTIONS
            },
            AddPropertyPropertyUsage: {
                name: 'AddPropertyPropertyUsage',
                label: 'How is the property used?',
                options: _PROPERTY_USAGE.OPTIONS
            },
            AddPropertyPropertyUsageDetails: {
                name: 'AddPropertyPropertyUsageDetails',
                label: 'Please tell us more'
            },
            AddPropertyBTLMonthlyIncome: {
                name: 'AddPropertyBTLMonthlyIncome',
                label: 'What is your monthly rental income?',
                designation: _POUND_AMOUNT
            },
            AddPropertyBTLCoversCosts: {
                name: 'AddPropertyBTLCoversCosts',
                label: 'I confirm that the income covers all operating and finance costs',
                options: _OPTIONS_YES_NO
            },
            AddPropertyHolLetMonthlyIncome: {
                name: 'AddPropertyHolLetMonthlyIncome',
                label: 'What is your average monthly income?',
                designation: _POUND_AMOUNT
            },
            AddPropertyHolLetCoversCosts: {
                name: 'AddPropertyHolLetCoversCosts',
                label: 'I confirm that the income covers all operating and finance costs',
                options: _OPTIONS_YES_NO
            },
            AddPropertyHaveMortgage: {
                name: 'AddPropertyHaveMortgage',
                label: 'Do you have a mortgage secured on this property?',
                options: _OPTIONS_YES_NO
            },
            AddPropertyMortProviderName: {
                name: 'AddPropertyMortProviderName',
                label: 'Name of the mortgage provider'
            },
            /* unused */
            AddPropertyMortProviderAddr: 'AddPropertyMortProviderAddr',
            AddPropertyMortProviderAccNo: {
                name: 'AddPropertyMortProviderAccNo',
                label: 'Mortgage account number'
            },
            AddPropertyMortStartDate: {
                name: 'AddPropertyMortStartDate',
                label: 'Mortgage start date'
            },
            AddPropertyMortTermYears: {
                name: 'AddPropertyMortTermYears',
                label: 'Original mortgage term, in years'
            },
            AddPropertyMortBorrowingAmount: {
                name: 'AddPropertyMortBorrowingAmount',
                label: 'Original borrowing amount',
                designation: _POUND_AMOUNT
            },
            AddPropertyMortType: {
                name: 'AddPropertyMortType',
                label: 'Mortgage type',
                options: [
                    { code: 'interest', text: 'Interest only' },
                    { code: 'repayment', text: 'Repayment' }
                ]
            },
            AddPropertyMortCurrentBalOS: {
                name: 'AddPropertyMortCurrentBalOS',
                label: 'Current balance outstanding',
                designation: _POUND_AMOUNT
            },
            AddPropertyMortCurrentMonthPay: {
                name: 'AddPropertyMortCurrentMonthPay',
                label: 'Current monthly payment',
                designation: _POUND_AMOUNT
            },
            AddPropertyMortSellToPayOff: {
                name: 'AddPropertyMortSellToPayOff',
                label: 'I will sell this property and pay off any mortgage before completing my new mortgage',
                options: _OPTIONS_YES_NO
            },
            AddPropertyMortSelltoPayOffSalePrice: {
                name: 'AddPropertyMortSelltoPayOffSalePrice',
                label: 'What is the expected sale price?',
                designation: _POUND_AMOUNT
            },
            AddPropertyMortWillBeReleased: {
                name: 'AddPropertyMortWillBeReleased',
                label: 'Will you be released from the mortgage in any way, prior to completion?',
                options: _OPTIONS_YES_NO
            },
            AddPropertyMortWillBeReleasedDescr: {
                name: 'AddPropertyMortWillBeReleasedDescr',
                label: 'Please provide us with more details'
            },

            AddPropertyMortSecondCharge: {
                name: 'AddPropertyMortSecondCharge',
                label: 'Do you have a second charge mortgage on this property?',
                options: _OPTIONS_YES_NO
            },

            AddPropertyMortSecondChargeName: {
                name: 'AddPropertyMortSecondChargeName',
                label: 'Name of the mortgage provider'
            },
            AddPropertyMortSecondChargeBalance: {
                name: 'AddPropertyMortSecondChargeBalance',
                label: 'Current balance outstanding',
                designation: _POUND_AMOUNT
            },
            AddPropertyMortSecondChargeMonthlyPayment: {
                name: 'AddPropertyMortSecondChargeMonthlyPayment',
                label: 'Current monthly payment',
                designation: _POUND_AMOUNT
            },

            HaveBnkOrBSAcct: {
                name: 'HaveBnkOrBSAcct',
                label: 'Do you have an account with a bank or building society?',
                options: _OPTIONS_YES_NO,
                sectionTitle: 'Bank or building society account'
            },

            HaveBnkOrBSAcctName: {
                label: 'Please provide the name of your main household bank or building society',
                name: 'HaveBnkOrBSAcctName',
            },

            HaveBnkOrBSAcctAddr: {
                label: 'Please provide their address',
                name: 'HaveBnkOrBSAcctAddr'
            },

            HaveBnkOrBSAcctYrsHeld: {
                label: 'How many years, approximately, have you held your account(s) for?',
                name: 'HaveBnkOrBSAcctYrsHeld'
            },

            HaveBnkSortCode: {
                label: 'Sort code',
                name: 'HaveBnkSortCode'
            },

            HaveBnkAccountNumber: {
                label: 'Account number',
                name: 'HaveBnkAccountNumber'
            },

            HaveBnkOthers: {
                name: 'HaveBnkOthers',
                frontendLabel: 'Other than the above main account, do you have any other accounts?',
                adminLabel: 'Has other bank accounts',
                options: _OPTIONS_YES_NO
            },

            HaveBnkOthersDesc: {
                name: 'HaveBnkOthersDesc',
                frontendLabel: `Please provide details of each account, including the provider's name and the purpose of the account (e.g. personal, joint, savings, buy to let ...)`,
                adminLabel: 'Other bank accounts description'
            },

            /* unused */
            HaveBnkOrBSAcctSavAcc: 'HaveBnkOrBSAcctSavAcc',
            /* unused */
            HaveBnkOrBSAcctSavAccVal: 'HaveBnkOrBSAcctSavAccVal',

            HasOtherAssets: {
                name: 'HasOtherAssets',
                label: 'Do you have any other significant assets?',
                options: _OPTIONS_YES_NO,
                sectionTitle: 'Other significant assets'
            },
            OtherAssets: {
                name: 'OtherAssets',
                label: 'Other asset'
            },

            OtherAssetType: {
                name: 'OtherAssetType',
                label: 'Asset type',
                options: _ASSET_TYPE.OPTIONS
            },
            OtherAssetDescr: {
                name: 'OtherAssetDescr',
                label: 'Asset description',
            },
            OtherAssetValue: {
                name: 'OtherAssetValue',
                label: 'Current estimated value',
                designation: _POUND_AMOUNT
            }


        },
        CC: {
            MyCreditTypes: {
                name: 'MyCreditTypes',
                adminLabel: 'Commitment types',
                options: _COMMITMENT_TYPE.OPTIONS
            },

            CreditCards:{
                name: 'CreditCards',
                adminLabel: 'Credit Card'
            },
            CreditCardName: {
                name: 'CreditCardName',
                label: 'Name of the card provider'
            },

            // Obsolete
            CreditCardAcctNum: {
                name: 'CreditCardAcctNum',
                label: 'Card or account number'
            },

            CreditCardUsage: {
                name: 'CreditCardUsage',
                label: 'What is the card normally used for?'
            },
            CreditCardCurrentBal: {
                name: 'CreditCardCurrentBal',
                label: 'Current balance',
                designation: _POUND_AMOUNT
            },
            CreditCardPayEachMonth: {
                name: 'CreditCardPayEachMonth',
                label: 'Do you pay this card off in full every month?',
                options: _OPTIONS_YES_NO
            },
            CreditCardMonthlyRepaymentAmount: {
                name: 'CreditCardMonthlyRepaymentAmount',
                label: 'Monthly repayment amount',
                designation: _POUND_AMOUNT
            },
            CreditCardPaidOffCompletion: {
                name: 'CreditCardPaidOffCompletion',
                label: 'Will this card be paid off by the time this new mortgage completes?',
                options: _OPTIONS_YES_NO
            },
            CreditCardNamePaidOffCompletionHow: {
                name: 'CreditCardNamePaidOffCompletionHow',
                label: 'How will you pay off the card?',
                options: _PAYOFF_FUNDS_SOURCE.OPTIONS
            },
            CreditCardNamePaidOffCompletionHowOther:{
                name: 'CreditCardNamePaidOffCompletionHowOther',
                adminLabel: 'Other'
            },

            PersonalLoans: {
                name: 'PersonalLoans',
                adminLabel: 'Personal Loan'
            },
            PersonalLoanName: {
                name: 'PersonalLoanName',
                label: 'Name of the loan provider'
            },
            PersonalLoanPurpose: {
                name: 'PersonalLoanPurpose',
                label: 'Describe the purpose of the loan'
            },
            PersonalLoanAcctNum: {
                name: 'PersonalLoanAcctNum',
                label: 'Account or reference number'
            },
            PersonalLoanOrigBorrowAmt: {
                name: 'PersonalLoanOrigBorrowAmt',
                label: 'Original borrowing amount',
                designation: _POUND_AMOUNT
            },
            PersonalLoanOrigStartDate: {
                name: 'PersonalLoanOrigStartDate',
                label: 'Agreement start date'
            },
            PersonalLoanCurrentBal: {
                name: 'PersonalLoanCurrentBal',
                label: 'Current balance',
                designation: _POUND_AMOUNT
            },
            PersonalLoanPayEachMonth: {
                name: 'PersonalLoanPayEachMonth',
                label: 'Monthly repayment amount',
                designation: _POUND_AMOUNT
            },
            // obsolete
            PersonalLoanCurrentIntRate: {
                name: 'PersonalLoanCurrentIntRate',
                label: 'Current interest rate',
                designation: _PERCENTAGE
            },
            PersonalLoanFinalRepayDate: {
                name: 'PersonalLoanFinalRepayDate',
                label: 'Final repayment date'
            },
            // obsolete
            PersonalLoanIsSecOnProperty: {
                name: 'PersonalLoanIsSecOnProperty',
                label: 'Is this arrangement secured on a property?',
                options: _OPTIONS_YES_NO
            },
            // obsolete
            PersonalLoanIsSecOnPropertyDescr: {
                name: 'PersonalLoanIsSecOnPropertyDescr',
                label: 'Tell us more about the security'
            },
            PersonalLoanPaidOffCompletion: {
                name: 'PersonalLoanPaidOffCompletion',
                label: 'Will this loan be paid off by the time this new mortgage completes?',
                options: _OPTIONS_YES_NO
            },
            PersonalLoanPaidOffCompletionHow: {
                name: 'PersonalLoanPaidOffCompletionHow',
                label: 'How will you pay off the loan?',
                options: _PAYOFF_FUNDS_SOURCE.OPTIONS
            },
            PersonalLoanPaidOffCompletionHowOther:{
                name: 'PersonalLoanPaidOffCompletionHowOther',
                adminLabel: 'Other method description'
            },

            CarFinances: {
                name: 'CarFinances',
                adminLabel: 'Car Finance'
            },
            CarFinanceName: {
                name: 'CarFinanceName',
                label: 'Name of the finance provider'
            },
            CarFinancePurpose: {
                name: 'CarFinancePurpose',
                label: 'Describe the purpose of the loan'
            },
            CarFinanceAcctNum: {
                name: 'CarFinanceAcctNum',
                label: 'Account or reference number'
            },
            CarFinanceOrigBorrowAmt: {
                name: 'CarFinanceOrigBorrowAmt',
                label: 'Original borrowing amount',
                designation: _POUND_AMOUNT
            },
            CarFinanceOrigStartDate: {
                name: 'CarFinanceOrigStartDate',
                label: 'Agreement start date'
            },
            CarFinanceCurrentBal: {
                name: 'CarFinanceCurrentBal',
                label: 'Current balance',
                designation: _POUND_AMOUNT
            },
            CarFinancePayEachMonth: {
                name: 'CarFinancePayEachMonth',
                label: 'Monthly repayment amount',
                designation: _POUND_AMOUNT
            },
            // obsolete
            CarFinanceCurrentIntRate: {
                name: 'CarFinanceCurrentIntRate',
                label: 'Current interest rate',
                designation: _PERCENTAGE
            },
            CarFinanceFinalRepayDate: {
                name: 'CarFinanceFinalRepayDate',
                label: 'Final repayment date'
            },
            // obsolete
            CarFinanceIsSecOnProperty: {
                name: 'CarFinanceIsSecOnProperty',
                label: 'Is this arrangement secured on a property?',
                options: _OPTIONS_YES_NO
            },
            // obsolete
            CarFinanceIsSecOnPropertyDescr: {
                name: 'CarFinanceIsSecOnPropertyDescr',
                label: 'Tell us more about the security'
            },
            CarFinancePaidOffCompletion: {
                name: 'CarFinancePaidOffCompletion',
                label: 'Will this finance be paid off by the time this new mortgage completes?',
                options: _OPTIONS_YES_NO
            },
            CarFinancePaidOffCompletionHow: {
                name: 'CarFinancePaidOffCompletionHow',
                label: 'How will you pay off the loan?',
                options: _PAYOFF_FUNDS_SOURCE.OPTIONS
            },
            CarFinancePaidOffCompletionHowOther:{
                name: 'CarFinancePaidOffCompletionHowOther',
                adminLabel: 'Other method description'
            },

            OperatingLeases: {
                name: 'OperatingLeases',
                adminLabel: 'Operating Lease'
            },
            OperatingLeaseName: {
                name: 'OperatingLeaseName',
                label: 'Name of the finance provider'
            },
            OperatingLeasePurpose: {
                name: 'OperatingLeasePurpose',
                label: 'Describe the purpose of the loan'
            },
            OperatingLeaseAcctNum: {
                name: 'OperatingLeaseAcctNum',
                label: 'Account or reference number'
            },
            // Seems unused.
            OperatingLeaseOrigBorrowAmt: {
                name: 'OperatingLeaseOrigBorrowAmt',
                label: 'Original borrowing amount',
                designation: _POUND_AMOUNT
            },
            OperatingLeaseOrigStartDate: {
                name: 'OperatingLeaseOrigStartDate',
                label: 'Agreement start date'
            },
            OperatingLeaseCurrentBal: {
                name: 'OperatingLeaseCurrentBal',
                label: 'Current balance, if applicable',
                designation: _POUND_AMOUNT
            },
            OperatingLeasePayEachMonth: {
                name: 'OperatingLeasePayEachMonth',
                label: 'Monthly repayment amount',
                designation: _POUND_AMOUNT
            },
            // Seems unused.
            OperatingLeaseCurrentIntRate: {
                name: 'OperatingLeaseCurrentIntRate',
                label: 'Current interest rate',
                designation: _PERCENTAGE
            },
            OperatingLeaseFinalRepayDate: {
                name: 'OperatingLeaseFinalRepayDate',
                label: 'Final repayment date'
            },
            // obsolete
            OperatingLeaseIsSecOnProperty: {
                name: 'OperatingLeaseIsSecOnProperty',
                label: 'Is this arrangement secured on a property?'
            },
            // obsolete
            OperatingLeaseIsSecOnPropertyDescr: {
                name: 'OperatingLeaseIsSecOnPropertyDescr',
                label: 'Tell us more about the security'
            },
            OperatingLeasePaidOffCompletion: {
                name: 'OperatingLeasePaidOffCompletion',
                label: 'Will this lease be settled by the time this new mortgage completes?',
                options: _OPTIONS_YES_NO
            },
            OperatingLeasePaidOffCompletionHow: {
                name: 'OperatingLeasePaidOffCompletionHow',
                label: 'How will you pay off the loan?',
                options: _PAYOFF_FUNDS_SOURCE.OPTIONS
            },
            OperatingLeasePaidOffCompletionHowOther: {
                name: 'OperatingLeasePaidOffCompletionHowOther',
                adminLabel: 'Other method description'
            },

            OtherCredits: {
                name: 'OtherCredits',
                adminLabel: 'Credit purchases'
            },
            OtherCreditName: {
                name: 'OtherCreditName',
                label: 'Name of the credit provider'
            },
            OtherCreditPurpose: {
                name: 'OtherCreditPurpose',
                label: 'Describe the purpose of the credit'
            },
            OtherCreditAcctNum: {
                name: 'OtherCreditAcctNum',
                label: 'Account or reference number'
            },
            OtherCreditOrigBorrowAmt: {
                name: 'OtherCreditOrigBorrowAmt',
                label: 'Original borrowing amount',
                designation: _POUND_AMOUNT
            },
            OtherCreditOrigStartDate: {
                name: 'OtherCreditOrigStartDate',
                label: 'Agreement start date'
            },
            OtherCreditCurrentBal: {
                name: 'OtherCreditCurrentBal',
                label: 'Current balance',
                designation: _POUND_AMOUNT
            },
            OtherCreditPayEachMonth: {
                name: 'OtherCreditPayEachMonth',
                label: 'Monthly repayment amount',
                designation: _POUND_AMOUNT
            },
            // obsolete
            OtherCreditCurrentIntRate: {
                name: 'OtherCreditCurrentIntRate',
                label: 'Current interest rate',
                designation: _PERCENTAGE
            },
            OtherCreditFinalRepayDate: {
                name: 'OtherCreditFinalRepayDate',
                label: 'Final repayment date'
            },
            OtherCreditIsSecOnProperty: {
                name: 'OtherCreditIsSecOnProperty',
                label: 'Is this arrangement secured on a property?',
                options: _OPTIONS_YES_NO
            },
            // obsolete
            OtherCreditIsSecOnPropertyDescr: {
                name: 'OtherCreditIsSecOnPropertyDescr',
                label: 'Tell us more about the security'
            },
            // obsolete
            OtherCreditPaidOffCompletion: {
                name: 'OtherCreditPaidOffCompletion',
                label: 'Will this credit be paid off by the time this new mortgage completes?',
                options: _OPTIONS_YES_NO
            },
            OtherCreditPaidOffCompletionHow: {
                name: 'OtherCreditPaidOffCompletionHow',
                label: 'How will you pay off the loan?',
                options: _PAYOFF_FUNDS_SOURCE.OPTIONS
            },
            OtherCreditPaidOffCompletionHowOther: {
                name: 'OtherCreditPaidOffCompletionHowOther',
                adminLabel: 'Other method description'
            },

            Overdraft: {
                name: 'Overdraft',
                adminLabel: 'Overdraft'
            },
            OverdraftName: {
                name: 'OverdraftName',
                label: 'Name of the overdraft provider'
            },
            OverdraftPurpose: {
                name: 'OverdraftPurpose',
                label: 'Why do you have this overdraft?'
            },
            OverdraftAcctNum: {
                name: 'OverdraftAcctNum',
                label: 'Account or reference number'
            },
            OverdraftLimit: {
                name: 'OverdraftLimit',
                label: 'Overdraft limit',
                designation: _POUND_AMOUNT
            },
            OverdraftGrantDate: {
                name: 'OverdraftGrantDate',
                label: 'Overdraft start date'
            },
            OverdraftCurrentBal: {
                name: 'OverdraftCurrentBal',
                label: 'Current balance',
                designation: _POUND_AMOUNT
            },
            OverdraftPayEachMonth: {
                name: 'OverdraftPayEachMonth',
                label: 'Monthly repayment amount',
                designation: _POUND_AMOUNT
            },
            // Obsolete
            OverdraftCurrentIntRate: {
                name: 'OverdraftCurrentIntRate',
                label: 'Current interest rate',
                designation: _PERCENTAGE
            },
            OverdraftFinalRepayDate: {
                name: 'OverdraftFinalRepayDate',
                label: 'Final payment date'
            },
            // Obsolete
            OverdraftIsSecOnProperty: {
                name: 'OverdraftIsSecOnProperty',
                label: 'Is this arrangement secured on a property?',
                options: _OPTIONS_YES_NO
            },
            // Obsolete
            OverdraftIsSecOnPropertyDescr: {
                name: 'OverdraftIsSecOnPropertyDescr',
                label: 'Tell us more about the security'
            },
            OverdraftPaidOffCompletion: {
                name: 'OverdraftPaidOffCompletion',
                label: 'Will this overdraft be paid off by the time this new mortgage completes?',
                options: _OPTIONS_YES_NO
            },
            OverdraftPaidOffCompletionHow: {
                name: 'OverdraftPaidOffCompletionHow',
                label: 'How will you pay off the overdraft?',
                options: _PAYOFF_FUNDS_SOURCE.OPTIONS
            },
            OverdraftPaidOffCompletionHowOther: {
                name: 'OverdraftPaidOffCompletionHowOther',
                adminLabel: 'Other method description'
            },

            OtherLoans: {
                name: 'OtherLoans',
                adminLabel: 'Other loan'
            },
            OtherLoanName: {
                name: 'OtherLoanName',
                label: 'Name of the loan provider'
            },
            OtherLoanPurpose: {
                name: 'OtherLoanPurpose',
                label: 'Describe the purpose of the loan'
            },
            OtherLoanAcctNum: {
                name: 'OtherLoanAcctNum',
                label: 'Account or reference number'
            },
            OtherLoanOrigBorrowAmt: {
                name: 'OtherLoanOrigBorrowAmt',
                label: 'Original borrowing amount',
                designation: _POUND_AMOUNT
            },
            OtherLoanOrigStartDate: {
                name: 'OtherLoanOrigStartDate',
                label: 'Agreement start date'
            },
            OtherLoanCurrentBal: {
                name: 'OtherLoanCurrentBal',
                label: 'Current balance',
                designation: _POUND_AMOUNT
            },
            OtherLoanPayEachMonth: {
                name: 'OtherLoanPayEachMonth',
                label: 'Monthly repayment amount',
                designation: _POUND_AMOUNT
            },
            // obsolete
            OtherLoanCurrentIntRate: {
                name: 'OtherLoanCurrentIntRate',
                label: 'Current interest rate',
                designation: _PERCENTAGE
            },
            OtherLoanFinalRepayDate: {
                name: 'OtherLoanFinalRepayDate',
                label: 'Final payment date'
            },
            // obsolete
            OtherLoanIsSecOnProperty: {
                name: 'OtherLoanIsSecOnProperty',
                label: 'Is this arrangement secured on a property?',
                options: _OPTIONS_YES_NO
            },
            // obsolete
            OtherLoanIsSecOnPropertyDescr: {
                name: 'OtherLoanIsSecOnPropertyDescr',
                label: 'Tell us more about the security'
            },
            OtherLoanPaidOffCompletion: {
                name: 'OtherLoanPaidOffCompletion',
                label: 'Will this loan be paid off by the time this new mortgage completes?',
                options: _OPTIONS_YES_NO
            },
            OtherLoanPaidOffCompletionHow: {
                name: 'OtherLoanPaidOffCompletionHow',
                label: 'How will you pay off the loan?',
                options: _PAYOFF_FUNDS_SOURCE.OPTIONS
            },
            OtherLoanPaidOffCompletionHowOther: {
                name: 'OtherLoanPaidOffCompletionHowOther',
                adminLabel: 'Other method description'
            },

            CreditReportDoc: {
                name: 'CreditReportDoc',
                label: 'Upload report (PDF only)',
                fileList: true
            }

        },

        // Outgoings
        OUT: {

            EssentialAlreadyRecorded: {
                name: 'EssentialAlreadyRecorded',
                label: 'My share of these costs has already been recorded by another applicant',
                options: _OPTIONS_TRUE_FALSE,
                sectionTitle: 'Essential costs'
            },

            EssentialFoodDrink: {
                name: 'EssentialFoodDrink',
                label: 'Essential food and drink',
                designation: _POUND_AMOUNT
            },

            EssentialUtilities: {
                name: 'EssentialUtilities',
                label: 'Home utilities (gas, electric, water, council tax, internet, phone, TV license)',
                designation: _POUND_AMOUNT
            },

            EssentialInsurances: {
                name: 'EssentialInsurances',
                label: 'Important insurances (car, building and contents, travel, medical)',
                designation: _POUND_AMOUNT
            },

            EssentialHomeSpend: {
                name: 'EssentialHomeSpend',
                label: 'Essential home related expenditure (repairs, cleaning, misc purchases)',
                designation: _POUND_AMOUNT
            },

            EssentialCarSpend: {
                name: 'EssentialCarSpend',
                label: 'Essential car and travel expenses (repairs, fuel, servicing, road tax)',
                designation: _POUND_AMOUNT
            },

            EssentialSavings: {
                name: 'EssentialSavings',
                label: 'Critical savings and investments',
                designation: _POUND_AMOUNT
            },

            EssentialLifeAssurance: {
                name: 'EssentialLifeAssurance',
                label: 'Life assurance, critical illness, mortgage protection or endowment payments',
                designation: _POUND_AMOUNT
            },

            EssentialSchoolFees: {
                name: 'EssentialSchoolFees',
                label: 'Committed school and care fees, maintenance costs and related expense',
                designation: _POUND_AMOUNT
            },

            EssentialOtherCosts: {
                name: 'EssentialOtherCosts',
                label: 'Other essential costs',
                designation: _POUND_AMOUNT
            },

            EssentialCostsTotal: {
                name: 'EssentialCostsTotal',
                adminLabel: 'Essential costs total',
                adminStyle: _ADMIN_STYLE_BOLD,
                designation: _POUND_AMOUNT
            },

            
            BasicAlreadyRecorded: {
                name: 'BasicAlreadyRecorded',
                label: 'My share of these costs has already been recorded by another applicant',
                options: _OPTIONS_TRUE_FALSE,
                sectionTitle: ' Quality of life costs'
            },

            BasicSocial: {
                name: 'BasicSocial',
                label: 'Social, food, drink and entertainment',
                designation: _POUND_AMOUNT
            },

            BasicHomeCost: {
                name: 'BasicHomeCost',
                label: 'Other home related expenditure (cleaning, misc purchases)',
                designation: _POUND_AMOUNT
            },

            BasicClothing: {
                name: 'BasicClothing',
                label: 'Clothing and footwear',
                designation: _POUND_AMOUNT
            },

            BasicSavings: {
                name: 'BasicSavings',
                label: 'Regular savings and investments',
                designation: _POUND_AMOUNT
            },

            BasicOther: {
                name: 'BasicOther',
                label: 'Other costs (health related, for example)',
                designation: _POUND_AMOUNT
            },

            BasicCostsTotal: {
                name: 'BasicCostsTotal',
                adminLabel: 'Quality of life costs total',
                adminStyle: _ADMIN_STYLE_BOLD,
                designation: _POUND_AMOUNT
            }

        }
    },

    // My property
    MP: {
        // Property details
        PD: {

            // Address

            HolidayLetAddressLine1: {
                name: 'HolidayLetAddressLine1',
                label: 'Address 1'
            },

            HolidayLetAddressLine2: {
                name: 'HolidayLetAddressLine2',
                label: 'Address 2 (optional)'
            },

            HolidayLetTown: {
                name: 'HolidayLetTown',
                label: 'Town or City'
            },

            HolidayLetCounty: {
                name: 'HolidayLetCounty',
                label: 'County'
            },

            HolidayLetPostcode: {
                name: 'HolidayLetPostcode',
                label: 'Postcode'
            },

            HolidayLetAreLegalOwners: {
                name: 'HolidayLetAreLegalOwners',
                label: 'Are the legal owners of the property the same as the applicants to this mortgage?',
                options: _OPTIONS_YES_NO
            },

            HolidayLetNotLegalOwnersReason: {
                name: 'HolidayLetNotLegalOwnersReason',
                label: 'Can you explain more about your situation below:',
                adminLabel: 'Explanation'
            },

            HolidayLetPurchasersApplicants: {
                name: 'HolidayLetPurchasersApplicants',
                label: 'Are the purchasers of the new property going to be the same as the applicants to this mortgage?',
                options: _OPTIONS_YES_NO
            },

            HolidayLetPurchasersApplicantsReason: {
                name: 'HolidayLetPurchasersApplicantsReason',
                label: 'Can you explain more about your situation below:',
                adminLabel: 'Explanation'
            },

            HolidayLetPurchaseDate: {
                name: 'HolidayLetPurchaseDate',
                label: 'Approximate date of purchase'
            },

            HolidayLetOriginalPurchasePrice: {
                name: 'HolidayLetOriginalPurchasePrice',
                label: 'Original purchase price',
                designation: _POUND_AMOUNT
            },

            HolidayLetMarketValue: {
                name: 'HolidayLetMarketValue',
                label: 'Estimated current market value',
                designation: _POUND_AMOUNT
            },

            HolidayLetPropertyType: {
                name: 'HolidayLetPropertyType',
                label: 'What type of property is it?',
                options: _PROPERTY_TYPE.OPTIONS
            },

            // Rooms

            HolidayLetFlatNumFloors: {
                name: 'HolidayLetFlatNumFloors',
                label: 'How many floors in the main building?'
            },

            HolidayLetFlatMyFloor: {
                name: 'HolidayLetFlatMyFloor',
                label: 'Which floor is this property on?'
            },

            HolidayLetFlatTypeOf: {
                name: 'HolidayLetFlatTypeOf',
                label: 'What best describes the property type',
                options: _FLAT_TYPE.OPTIONS
            },

            HolidayLetNearShop: {
                name: 'HolidayLetNearShop',
                label: 'Is the property adjacent to, above, below or near to a pub, shop or other type of commercial operation?',
                options: _OPTIONS_YES_NO
            },

            HolidayLetNearShopExplanation: {
                name: 'HolidayLetNearShopExplanation',
                label: 'Please can you tell us more about the situation'
            },

            HolidayLetExCouncil: {
                name: 'HolidayLetExCouncil',
                label: 'Is the property ex-council?',
                options: _OPTIONS_YES_NO
            },
            
            HolidayLetEpcRating: {
                name: 'HolidayLetEpcRating',
                label: 'What is the property\'s Energy Performance Rating (EPC)?',
                options: _EPC_RATING.OPTIONS
            },

            HolidayLetTenure: {
                name: 'HolidayLetTenure',
                label: 'What is the property\'s tenure?',
                options: _PROPERTY_TENURE.OPTIONS
            },

            HolidayLetLeaseUnexpTerm: {
                name: 'HolidayLetLeaseUnexpTerm',
                label: 'Unexpired term of lease'
            },

            HolidayLetLeaseGrdRent: {
                name: 'HolidayLetLeaseGrdRent',
                label: 'Ground rent',
                designation: _POUND_AMOUNT
            },

            HolidayLetLeaseServChrg: {
                name: 'HolidayLetLeaseServChrg',
                label: 'Service or maintenance charge',
                designation: _POUND_AMOUNT
            },

            HolidayLetLeaseFreeholdShare: {
                name: 'HolidayLetLeaseFreeholdShare',
                label: 'Does the property also have share of the freehold?',
                options: _FREEHOLD_SHARE.OPTIONS
            },

            HolidayLetLeaseFreeholdShareDetails: {
                name: 'HolidayLetLeaseFreeholdShareDetails',
                label: 'Please tell us more about the share of freehold'
            },

            HolidayLetMoreThanOnSelfContained: {
                name: 'HolidayLetMoreThanOnSelfContained',
                label: 'Does the holiday let comprise of more than one self-contained property?',
                tooltip: 'e.g. one title for a building split into two flats, or one title for a main residential home, plus converted outbuildings.',
                options: _OPTIONS_YES_NO
            },
        
            HolidayLetHowManySelfContained: {
                name: 'HolidayLetHowManySelfContained',
                label: 'How many self-contained properties are on the title?',
                options: _SELF_CONTAINED_COUNT.OPTIONS
            },
            
            HolidayLetMultiUnitLayoutDescription: {
                name: 'HolidayLetMultiUnitLayoutDescription',
                label: 'If possible, please provide a brief description of the multi-unit layout',
            },

            HolidayLetNumLivingRooms: {
                name: 'HolidayLetNumLivingRooms',
                label: 'How many living rooms does it have?'
            },

            HolidayLetNumDiningRooms: {
                name: 'HolidayLetNumDiningRooms',
                label: 'How many dining rooms does it have?'
            },

            HolidayLetNumBedrooms: {
                name: 'HolidayLetNumBedrooms',
                label: 'How many bedrooms does it have?'
            },

            HolidayLetNumKitchens: {
                name: 'HolidayLetNumKitchens',
                label: 'How many kitchens does it have?'
            },

            HolidayLetNumBathrooms: {
                name: 'HolidayLetNumBathrooms',
                label: 'How many bathrooms does it have?'
            },

            HolidayLetNumOtherRooms: {
                name: 'HolidayLetNumOtherRooms',
                label: 'Tell us about any other significant rooms (optional)'
            },

            HolidayLetWallsConstruction: {
                name: 'HolidayLetWallsConstruction',
                label: 'What are the walls made of?',
                options: _WALL_TYPE.OPTIONS
            },

            HolidayLetWallsConstructionOther: {
                name: 'HolidayLetWallsConstructionOther',
                label: 'Other wall material type'
            },

            HolidayLetRoofConstruction: {
                name: 'HolidayLetRoofConstruction',
                label: 'What is the roof covering the main building made of?',
                options: _ROOF_TYPE.OPTIONS
            },

            HolidayLetRoofConstructionOther: {
                name: 'HolidayLetRoofConstructionOther',
                label: 'Other roof material type',
            },

            HolidayLetGarageType: {
                name: 'HolidayLetGarageType',
                label: 'Does the property have a garage?',
                options: _GARAGE_TYPE.OPTIONS
            },

            // BuildDetails

            PurchaseNewBuild: {
                name: 'PurchaseNewBuild',
                label: 'Is the property in the process of being built?',
                options: _OPTIONS_YES_NO
            },

            PurchaseNewBuildDateofComp: {
                name: 'PurchaseNewBuildDateofComp',
                label: 'What is the approximate date of completion?'
            },

            PurchaseNewBuildLoanInstallments: {
                name: 'PurchaseNewBuildLoanInstallments',
                label: 'Will the loan advance be released in installments (e.g. for a self build)?',
                options: _OPTIONS_YES_NO
            },

            PurchaseNewBuildWarranty: {
                name: 'PurchaseNewBuildWarranty',
                label: 'Will the property be covered by NHBC or other warranty?',
                options: _OPTIONS_YES_NO
            },

            PurchaseNewBuildWarrantyDescr: {
                name: 'PurchaseNewBuildWarrantyDescr',
                label: 'Please summarise the warranty details'
            },

            PurchaseNewBuildArchitectDetails: {
                name: 'PurchaseNewBuildArchitectDetails',
                label: 'Please provide the details of the housebuilder and supervising architect'
            },

            // Seems unused
            ExistingRemortMortCurrentBalOS: {
                name: 'ExistingRemortMortCurrentBalOS',
                label: 'Current balance outstanding'
            },
            // Seems unused
            ExistingRemortMortCurrentMonthPay: {
                name: 'ExistingRemortMortCurrentMonthPay',
                label: 'Monthly payment'
            },
            // Seems unused
            ExistingRemortMortApprCurLender: {
                name: 'ExistingRemortMortApprCurLender',
                label: 'Have you approached your lender for the mortgage funds (re-mortgage , further advance)?'
            },
            // Seems unused
            ExistingRemortMortApprCurLenderDescr: {
                name: 'ExistingRemortMortApprCurLenderDescr',
                label: 'What was the outcome?'
            },

            // ExistingAsset
            
            HolidayLetDateOfConstruction: {
                name: 'HolidayLetDateOfConstruction',
                label: 'What is the approximate date of construction?'
            },
            HolidayLetLessTenYrsWarranty: {
                name: 'HolidayLetLessTenYrsWarranty',
                label: 'Will the property be covered by NHBC or other warranty?',
                options: _OPTIONS_YES_NO
            },
            HolidayLetLessTenYrsWarrantyDescr: {
                name: 'HolidayLetLessTenYrsWarrantyDescr',
                label: 'Please summarise the warranty details'
            },
            HolidayLetLessTenYrsWarrantyArchitect: {
                name: 'HolidayLetLessTenYrsWarrantyArchitect',
                label: 'Please provide the details of the housebuilder and supervising architect'
            },

            // Improvements

            PurchaseNewBuildDoImprovements: {
                name: 'PurchaseNewBuildDoImprovements',
                label: 'After buying the property, do you plan on carrying out any home improvements?',
                options: _OPTIONS_YES_NO
            },

            PurchaseNewBuildDoImprovementsDescr: {
                name: 'PurchaseNewBuildDoImprovementsDescr',
                label: 'Please provide details of planned improvements and their costs'
            },

            PurchaseNewBuildDoImprovementsCost: {
                name: 'PurchaseNewBuildDoImprovementsCost',
                label: 'Please tell us the level of expected improvement cost',
                designation: _POUND_AMOUNT
            },
        },

        // Holiday letting
        HL: {
            NewOrExistLet: {
                name: 'NewOrExistLet',
                label: 'Is the property an existing holiday let or will it be a new holiday let?',
                options: _NEW_OR_EXISTING_LET.OPTIONS
            },

            ExistLetHowManyYrs: {
                name: 'ExistLetHowManyYrs',
                label: 'How many years has it been a holiday let for?',
                adminLabel: 'Has been a holiday let (for years)'
            },
            ExistLet12MGrosInc: {
                name: 'ExistLet12MGrosInc',
                label: 'What was the gross rental income',
                adminLabel: 'Gross rental income was',
                designation: _POUND_AMOUNT
            },
            ExistLet12MBookingNum: {
                name: 'ExistLet12MBookingNum',
                label: 'Number of bookings',
                adminLabel: 'Bookings delivered'
            },

            ExistLetMkting: {
                name: 'ExistLetMkting',
                label: 'How was the property primarily marketed during this time?',
                adminLabel: 'Primary previous marketing methods',
                options: _LET_MARKETING.OPTIONS
            },
            ExistLetMktingOther: {
                name: 'ExistLetMktingOther',
                adminLabel: 'Other previous marketing'
            },
            ExistLetMktingWebsite: {
                name: 'ExistLetMktingWebsite',
                label: 'Please provide the URL and description',
                adminLabel: 'Personal web site URL/description'
            },
            ExistLetMktingAgent: {
                name: 'ExistLetMktingAgent',
                label: 'Please tell us which agents you used',
                adminLabel: 'Agents used',
                options: _LET_MARKETING_AGENTS.OPTIONS
            },
            ExistLetMktingAgentOther: {
                name: 'ExistLetMktingAgentOther',
                adminLabel: 'Other agent'
            },
            ExistLetMktingOTA: {
                name: 'ExistLetMktingOTA',
                label: 'Please select all those that you used',
                adminLabel: 'Portals / Online Travel Agents used',
                options: _LET_MARKETING_ONLINE_OR_TRAVEL_AGENTS.OPTIONS
            },
            ExistLetMktingOTAOther: {
                name: 'ExistLetMktingOTAOther',
                adminLabel: 'Other portal/online travel agent'
            },

            Next12MGrossInc: {
                name: 'Next12MGrossInc',
                label: 'Forecast gross rental income, per annum',
                designation: _POUND_AMOUNT
            },
            Next12MBookingNum: {
                name: 'Next12MBookingNum',
                label: 'Anticipated number of bookings per annum',
                adminLabel: 'Expected number of bookings'
            },
            Next12MWeeklyPriceHigh: {
                name: 'Next12MWeeklyPriceHigh',
                label: 'Weekly rate in high season',
                designation: _POUND_AMOUNT
            },
            Next12MWeeklyPriceMed: {
                name: 'Next12MWeeklyPriceMed',
                label: 'Weekly rate in middle season',
                designation: _POUND_AMOUNT
            },
            Next12MWeeklyPriceLow: {
                name: 'Next12MWeeklyPriceLow',
                label: 'Weekly rate in low season',
                designation: _POUND_AMOUNT
            },
            WantsIntroductionToHolidayLetAgent: {
                name: 'WantsIntroductionToHolidayLetAgent',
                adminLabel: 'Wants introduction to holiday letting agent in his area',
                frontendLabel: '',
                options: _OPTIONS_YES_NO
            },

            Next12MarketingAsExist: {
                name: 'Next12MarketingAsExist',
                label: 'Will the property continue to be marketed the same way as last year?',
                adminLabel: 'Marketing same as last year',
                options: _OPTIONS_YES_NO
            },

            Next12MMkting: {
                name: 'Next12MMkting',
                label: 'How will the property be marketed?',
                options: _LET_MARKETING.OPTIONS
            },
            Next12MMktingOther: {
                name: 'Next12MMktingOther',
                adminLabel: 'Other future marketing'
            },
            Next12MMktingWebsite: {
                name: 'Next12MMktingWebsite',
                label: 'Please provide the URL and description',
                adminLabel: 'Future personal web site URL/description'
            },
            Next12MMktingAgent: {
                name: 'Next12MMktingAgent',
                label: 'Please tell us which agents you plan to use',
                adminLabel: 'Planned agents',
                options: _LET_MARKETING_AGENTS.OPTIONS
            },
            Next12MMktingAgentOther: {
                name: 'Next12MMktingAgentOther',
                adminLabel: 'Future other agent'
            },
            Next12MMktingOTA: {
                name: 'Next12MMktingOTA',
                label: 'Please select all those that you plan to use',
                adminLabel: 'Planned portals / Online Travel Agents',
                options: _LET_MARKETING_ONLINE_OR_TRAVEL_AGENTS.OPTIONS
            },
            Next12MMktingOTAOther: {
                name: 'Next12MMktingOTAOther',
                adminLabel: 'Future other portal/online travel agent'
            },

            LinktoBrochure: {
                name: 'LinktoBrochure',
                label: 'If there is a web link to somewhere that showcases the property, please provide the details here so we can review it.'
            },
            StyleDescr: {
                name: 'StyleDescr',
                label: 'How would you best describe the style of the cottage',
                adminLabel: 'Cottage style',
                options: [
                    { code: '', text: '' },
                    { code: 'modern_contemporary', text: 'Modern/Contemporary' },
                    { code: 'classic_cottagey', text: 'Classic/Cottagey' },
                    { code: _OTHER, text: 'Other' }
                ]
            },
            StyleDescrOther: {
                name: 'StyleDescrOther',
                adminLabel: 'Other cottage style.'
            },
            Pictures: {
                name: 'Pictures',
                label: 'Would you like to upload some pictures:',
                adminLabel: 'Property pictures',
                fileList: true
            },
        }
    },

    // My mortgage
    MM: {

        MD: {

            // --- LTV block start
            PurchasePrice: {
                name: 'PurchasePrice',
                label: 'What is the expected purchase price of the holiday home?',
                designation: _POUND_AMOUNT
            },

            BorrowPercent: {
                name: 'BorrowPercent',
                label: 'How much do you want to borrow?',
                adminHidden: true,
                designation: _PERCENTAGE
            },

            PurchaseBorrowing: {
                name: 'PurchaseBorrowing',
                adminLabel: 'How much do you want to borrow',
                designation: _POUND_AMOUNT
            },

            // Value computed on frontend for fact find display
            PurchaseBorrowingLoanToValue: {
                name: 'PurchaseBorrowingLoanToValue',
                adminLabel: 'Proposed loan to value',
                designation: _PERCENTAGE,
                format: { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },
            // --- LTV block end

            TotalDeposit: {
                name: 'TotalDeposit',
                label: 'Calculated required deposit',
                designation: _POUND_AMOUNT
            },

            PurchaseDeposits: {
                name: 'PurchaseDeposits',
                adminLabel: 'Source of deposit'
            },

            // Was ingorrect name, migrated upon load into PurchaseDepositSource
            PurchaseSource: {
                name: 'PurchaseSource',
                label: 'Source',
                options: _DEPOSIT_SOURCE_TYPE.OPTIONS
            },

            PurchaseDepositSource: {
                name: 'PurchaseDepositSource',
                label: 'Source',
                options: _DEPOSIT_SOURCE_TYPE.OPTIONS
            },

            PurchaseDepositAmount: {
                name: 'PurchaseDepositAmount',
                label: 'Amount',
                designation: _POUND_AMOUNT
            },

            PurchaseDepositSourceOther: {
                name: 'PurchaseDepositSourceOther',
                label: 'Other source'
            },

            PurchaseDepositSaleofPropertyDesc: {
                name: 'PurchaseDepositSaleofPropertyDesc',
                label: 'Please tell us which property you are planning on selling'
            },
            
            PurchaseDepositRemortgageProperty: {
                name: 'PurchaseDepositRemortgageProperty',
                label: 'Please tell us which property you are remortgaging'
            },

            PurchaseDepositGiftFrom: {
                name: 'PurchaseDepositGiftFrom',
                label: 'Please tell us who the gift is from'
            },
            PurchaseDepositGiftRepayable: {
                name: 'PurchaseDepositGiftRepayable',
                label: 'Is the gift repayable?',
                options: _OPTIONS_YES_NO
            },

            HolidayLetMarketValueLink: {
                name: 'HolidayLetMarketValueLink',
                label: 'Estimated current market value',
                designation: _POUND_AMOUNT
            },

            BorrowingLevel: {
                name: 'BorrowingLevel',
                label: 'Current level of borrowing on the property',
                designation: _POUND_AMOUNT
            },
            // Value computed on frontend for fact find display
            BorrowingLevelLoanToValue: {
                name: 'BorrowingLevelLoanToValue',
                adminLabel: 'Current loan to value',
                designation: _PERCENTAGE,
                format: { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },

            BorrowAmount: {
                name: 'BorrowAmount',
                adminLabel: 'How much do you want to borrow',
                designation: _POUND_AMOUNT
            },
            // Value computed on frontend for fact find display
            BorrowAmountLoanToValue: {
                name: 'BorrowAmountLoanToValue',
                adminLabel: 'Proposed loan to value',
                designation: _PERCENTAGE,
                format: { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }
            },

           
            CapitalRaise: {
                name: 'CapitalRaise',
                adminLabel: 'Capital raise',
                designation: _POUND_AMOUNT
            },

            CapitalDeficiency: {
                name: 'CapitalDeficiency',
                adminLabel: 'Capital deficiency',
                designation: _POUND_AMOUNT
            },

            FundingDifferencePlan: {
                name: 'FundingDifferencePlan',
                label: 'Please tell us how you plan on funding the difference'
            },

            AdditionalBorrowingPlan: {
                name: 'AdditionalBorrowingPlan',
                label: 'Please tell us what you plan to do with the additional borrowing?',
                options: _FUNDS_USAGE.OPTIONS
            },

            AdditionalBorrowingPlanOther: {
                name: 'AdditionalBorrowingPlanOther',
                label: 'Other description',
            },

            MortgageReason: {
                name: 'MortgageReason',
                label: 'What is the reason for wanting to place a mortgage on your property?'
            },

            FundsPlan: {
                name: 'FundsPlan',
                label: 'Please tell us what you plan to do with the funds released',
                options: _FUNDS_USAGE.OPTIONS
            },

            FundsPlanOther: {
                name: 'FundsPlanOther',
                label: 'Other description'
            },

            PreferredMortgageTerm: {
                name: 'PreferredMortgageTerm',
                label: 'Preferred mortgage term',
            },

            HowPaymentsInRetirement:  {
                name: 'HowPaymentsInRetirement',
                label: 'Please tell us how you will maintain your mortgage payments in retirement?'
            },

            MortgageTermReason:  {
                name: 'MortgageTermReason',
                label: 'Please tell us why you picked this term and why you would not try for a shorter term'
            },

           

            ExistingRemortMortProviderName: {
                name: 'ExistingRemortMortProviderName',
                label: 'Name of the mortgage provider'
            },

            ExistingRemortMortProviderAccNo: {
                name: 'ExistingRemortMortProviderAccNo',
                label: 'Mortgage account number'
            },
            ExistingRemortMortStartDate: {
                name: 'ExistingRemortMortStartDate',
                label: 'Mortgage start date'
            },
            ExistingRemortMortTermYears: {
                name: 'ExistingRemortMortTermYears',
                label: 'Original mortgage term, in years'
            },
            ExistingRemortOriginalBorrowing: {
                name: 'ExistingRemortOriginalBorrowing',
                label: 'Original borrowing amount',
                designation: _POUND_AMOUNT
            },
            ExistingRemortMortType: {
                name: 'ExistingRemortMortType',
                label: 'Mortgage type',
                options: [
                    { code: 'interest', text: 'Interest only' },
                    { code: 'repayment', text: 'Repayment' }
                ]
            },
            ExistingRemortMortIntRate: {
                name: 'ExistingRemortMortIntRate',
                label: 'Current interest rate',
                designation: _PERCENTAGE
            },
            
            MonthlyMortgagePayment: {
                name: 'MonthlyMortgagePayment',
                label: 'Current monthly mortgage payment',
                designation: _POUND_AMOUNT
            },

            ExistingRemortMortIntRateType: {
                name: 'ExistingRemortMortIntRateType',
                label: 'Interest rate type',
                options: _INTEREST_RATE_TYPE.OPTIONS
            }, 
            ExistingRemortMortFixedPeriodLeftMonths: {
                name: 'ExistingRemortMortFixedPeriodLeftMonths',
                label: 'When does the interest rate period end?'
            },
            ExistingRemortMortHaveERCs: {
                name: 'ExistingRemortMortHaveERCs',
                label: 'Do you have any Early Redemption Charges?',
                options: _OPTIONS_YES_NO
            },
            ExistingRemortMortHaveERCsCost: {
                name: 'ExistingRemortMortHaveERCsCost',
                label: 'Approximately how much would it cost?',
                designation: _POUND_AMOUNT
            },
            ExistingRemortMortHaveERCsCostWillPay: {
                name: 'ExistingRemortMortHaveERCsCostWillPay',
                label: 'Would you be willing to pay these charges?',
                options: _OPTIONS_YES_NO
            },
            ExistingRemortMortHaveERCsCostWillPayDescr: {
                name: 'ExistingRemortMortHaveERCsCostWillPayDescr',
                label: 'How do you plan to pay the charges?'
            },
            ExistingRemortMortPortable: {
                name: 'ExistingRemortMortPortable',
                label: 'Is the mortgage portable?',
                options: _OPTIONS_YES_NO
            },

            ExistingRemortApproachedLenderForFunds: {
                name: 'ExistingRemortApproachedLenderForFunds',
                label: 'Have you approached your current lender for the mortgage funds?',
                options: _OPTIONS_YES_NO
            },

            ExistingRemortApproachOutcome: {
                name: 'ExistingRemortApproachOutcome',
                label: 'What was the outcome?'
            },

            ExistingRemortIncludeLenderInSearch: {
                name: 'ExistingRemortIncludeLenderInSearch',
                label: 'Are you happy for us to include this lender in our mortgage search?',
                options: _OPTIONS_YES_NO
            },

            ExistingRemortSecondCharge: {
                name: 'ExistingRemortSecondCharge',
                label: 'Is there a second charge on the property?',
                options: _OPTIONS_YES_NO
            },

            ExistingRemortSecondProviderName: {
                name: 'ExistingRemortSecondProviderName',
                label: 'Name of the mortgage provider'
            },

            ExistingRemortSecondBalance: {
                name: 'ExistingRemortSecondBalance',
                label: 'Current balance outstanding',
                designation: _POUND_AMOUNT
            }

        },

        // Mortgage preferences
        MP: {
           
            MortgageDescription: {
                name: 'MortgageDescription',
                label: 'In a few sentences, can you describe what sort of mortgage you are looking for and why?',
            },

            MaxMonthlyBudget: {
                name: 'MaxMonthlyBudget',
                label: 'What is your ideal maximum monthly budget for your mortgage payments?',
                designation: _POUND_AMOUNT
            },

            WillingToChangeIncome: {
                name: 'WillingToChangeIncome',
                frontendLabel: 'Income',
                adminLabel: 'Is your income likely to change significantly?',
                options: _OPTIONS_YES_NO,
            },
            WillingToChangeIncomeReason: {
                name: 'WillingToChangeIncomeReason',
                label: 'Please tell us more',
            },
            WillingToChangeExpenditure: {
                name: 'WillingToChangeExpenditure',
                frontendLabel: 'Expenditure',
                adminLabel: 'Is your expenditure like to change significantly?',
                options: _OPTIONS_YES_NO
            },
            WillingToChangeExpenditureReason: {
                name: 'WillingToChangeExpenditureReason',
                label: 'Please tell us more',
            },
            WillingToPayPremature: {
                name: 'WillingToPayPremature',
                label: 'Is it possible that you may want to pay off some, or all, of your new mortgage within the next 3 to 7 years?',
                options: _OPTIONS_YES_NO
            },
            WillingToPayPrematureReason: {
                name: 'WillingToPayPrematureReason',
                label: 'Please tell us more',
            },
            WillingToMoveHome: {
                name: 'WillingToMoveHome',
                label: 'Are you likely to move home, for any reason, within the next 3 to 7 years?',
                options: _OPTIONS_YES_NO
            },
            WillingToMoveHomeReason: {
                name: 'WillingToMoveHomeReason',
                label: 'Please tell us more',
            },
            HasAdditionalMoneyToReduceBorrow: {
                name: 'HasAdditionalMoneyToReduceBorrow',
                label: 'Do you have additional money that you could use to reduce the amount you want to borrow?',
                options: _OPTIONS_YES_NO
            },
            HowMuchMoneyToReduceBorrow: {
                name: 'HowMuchMoneyToReduceBorrow',
                label: 'How much would you be willing to use',
                designation: _POUND_AMOUNT
            },
            HasAdditionalMoneyCircumstances: {
                name: 'HasAdditionalMoneyCircumstances',
                label: 'In what circumstances would you be prepared to use it',
            },


            IsConcernedAboutFutureInterestMoves: {
                name: 'IsConcernedAboutFutureInterestMoves',
                label: 'Are you concerned about the possibility of future interest rate movements?',
                options: _OPTIONS_YES_NO
            },
            IsConcernedAboutFutureInterestMovesReason: {
                name: 'IsConcernedAboutFutureInterestMovesReason',
                label: 'Please tell us more',
            },
            WantsToFixMortgageCostsForCertainPeriod: {
                name: 'WantsToFixMortgageCostsForCertainPeriod',
                label: 'Do you want to fix your mortgage costs for a certain period of time?',
                options: _OPTIONS_YES_NO_DEPENDS
            },
            HowLongFixMorgageCosts: {
                name: 'HowLongFixMorgageCosts',
                label: 'How long for, in years',
            },
            FixMortgageCostsReason: {
                name: 'FixMortgageCostsReason',
                label: 'Please tell us more',
            },
            RequiresDiscountInMortgageCosts: {
                name: 'RequiresDiscountInMortgageCosts',
                label: 'Do you require a discount on your mortgage costs in the first few years?',
                options: _OPTIONS_YES_NO_DEPENDS
            },   
            HowLongRequiresDiscountInMortgageCosts: {
                name: 'HowLongRequiresDiscountInMortgageCosts',
                label: 'How long for, in years',
            },
            RequiresDiscountInMortgageCostsReason: {
                name: 'RequiresDiscountInMortgageCostsReason',
                label: 'Please tell us more',
            },
            HasSpecificRequirementStandardTracker: {
                name: 'HasSpecificRequirementStandardTracker',
                label: 'Do you have a specific requirement for a standard tracker mortgage?',
                options: _OPTIONS_YES_NO_DEPENDS
            },
            HasSpecificRequirementStandardTrackerReason: {
                name: 'HasSpecificRequirementStandardTrackerReason',
                label: 'Please tell us more',
            },
            HasSpecificRequirementCapInterest : {
                name: 'HasSpecificRequirementCapInterest',
                label: 'Do you have a specific requirement to cap your interest rate for a period of time?',
                options: _OPTIONS_YES_NO_DEPENDS
            },           
            HowLongHasRequirementCapInterest: {
                name: 'HowLongHasRequirementCapInterest',
                label: 'How long for, in years',
            },
            SpecificRequirementCapInterestReason: {
                name: 'SpecificRequirementCapInterestReason',
                label: 'Please tell us more',
            },
            ReadyForEarlyRepaymentCharges: {
                name: 'ReadyForEarlyRepaymentCharges',
                label: 'Are you prepared to have Early Repayment Charges on your mortgage?',
                options: _OPTIONS_YES_NO
            },
            MaximumEarlyRepaymentChargeYears: {
                name: 'MaximumEarlyRepaymentChargeYears',
                label: 'What is the maximum Early Repayment Charge period, in years, you will accept?',
            },


            WantsCertaintyMortgageRepaid: {
                name: 'WantsCertaintyMortgageRepaid',
                label: 'Do you want the certainty of the mortgage being repaid at the end of the term?',
                options: _OPTIONS_YES_NO
            },
            WantsCertaintyMortgageRepaidReason: {
                name: 'WantsCertaintyMortgageRepaidReason',
                label: 'Please tell us why',
            },
            AcceptsMortgageRepaidInvestmentPlan: {
                name: 'AcceptsMortgageRepaidInvestmentPlan',
                label: 'Are you comfortable maintaining a separate repayment plan to pay off the mortgage at the end of its term?',
                options: _OPTIONS_YES_NO
            },
            AcceptsMortgageRepaidInvestmentPlanReason: {
                name: 'AcceptsMortgageRepaidInvestmentPlanReason',
                label: 'Please tell us why',
            },
            AcceptsNotMakingCapitalRepayments: {
                name: 'AcceptsNotMakingCapitalRepayments',
                label: 'Do you feel comfortable about not making regular capital repayments to reduce your mortgage?',
                options: _OPTIONS_YES_NO
            },
            AcceptsNotMakingCapitalRepaymentsReason: {
                name: 'AcceptsNotMakingCapitalRepaymentsReason',
                label: 'Please tell us why',
            },
            PreferredMortgageType: {
                name: 'PreferredMortgageType',
                label: 'Based on the above, what would you say is your preferred mortgage type',
                options: _PREFERRED_MORTGAGE_TYPE.OPTIONS
            },

            AcceptsInterestOnly: {
                name: 'AcceptsInterestOnly',
                label: 'Please tick to confirm your understanding and acceptance of the above statement',
                options: _OPTIONS_CHECKBOX_CHECKED
            },
            PrimaryProposedRepaymentStrategy: {
                name: 'PrimaryProposedRepaymentStrategy',
                label: 'Please select one option below as your primary proposed repayment strategy to repay the mortgage',
                options: _REPAYMENT_STRATEGY_TYPE.OPTIONS
            },
            PrimaryRepaymentPlanDescription: {
                name: 'PrimaryRepaymentPlanDescription',
                label: 'Please describe your primary repayment plan to us in words'
            },


            PreferencesAdditionalRepayments: {
                name: 'PreferencesAdditionalRepayments',
                label: 'In terms of making additional capital repayments over the next few years, what are your preferences',
                options: _REPAYMENT_PREFERENCE_TYPE.OPTIONS
            },
            PreferencesAdditionalRepaymentsReason: {
                name: 'PreferencesAdditionalRepaymentsReason',
                label: 'Can you tell us more about this'
            },
            //Obsolete
            NeedMortgageUnderpayments: {
                name: 'NeedMortgageUnderpayments',
                label: 'Do you specifically need a mortgage that permits underpayments or payment holidays?',
                options: _OPTIONS_YES_NO
            },
            //Obsolete
            NeedMortgageUnderpaymentsReason: {
                name: 'NeedMortgageUnderpaymentsReason',
                label: 'Can you tell us why'
            },
            //Obsolete
            NeedMortgageVaryRepayment: {
                name: 'NeedMortgageVaryRepayment',
                label: 'Do you specifically need a mortgage that allows you to vary the repayment amount?',
                options: _OPTIONS_YES_NO
            },
            //Obsolete
            NeedMortgageVaryRepaymentReason: {
                name: 'NeedMortgageVaryRepaymentReason',
                label: 'Can you tell us why'
            },
            //Obsolete
            NeedLinkMortgageToSavings: {
                name: 'NeedLinkMortgageToSavings',
                label: 'Do you need to link your mortgage to your savings or current accounts?',
                options: _OPTIONS_YES_NO
            },
            //Obsolete
            NeedLinkMortgageToSavingsReason: {
                name: 'NeedLinkMortgageToSavingsReason',
                label: 'Can you tell us why'
            },
            RequireFreeLegalFees: {
                name: 'RequireFreeLegalFees',
                label: 'Do you specifically require a mortgage with free legal fees?',
                options: _OPTIONS_YES_NO
            },
            RequireFreeLegalFeesReason: {
                name: 'RequireFreeLegalFeesReason',
                label: 'Can you tell us why'
            },
            RequireNoValuationFees: {
                name: 'RequireNoValuationFees',
                label: 'Do you specifically require a mortgage with no valuation fees?',
                options: _OPTIONS_YES_NO
            },
            RequireNoValuationFeesReason: {
                name: 'RequireNoValuationFeesReason',
                label: 'Can you tell us why'
            },
            // Seems unused
            RequireValuationFeesRefundedReason: {
                name: 'RequireValuationFeesRefundedReason',
                label: 'Can you tell us why'
            },
            AddProductFeesToLoanAmount: {
                name: 'AddProductFeesToLoanAmount',
                label: 'If the mortgage has arrangement or product fees, would you prefer to add these to your loan amount?',
                options: _OPTIONS_YES_NO
            },
            AddProductFeesToLoanAmountReason: {
                name: 'AddProductFeesToLoanAmountReason',
                label: 'You will pay interest on any fees added to the loan. What’s your reason for wanting to do this?'
            },
            MortgageTargetCompletionDate: {
                name: 'MortgageTargetCompletionDate',
                label: 'Ideally, what is your target completion date for the new mortgage'
            },
            MortgageTargetCompletionDateReason: {
                name: 'MortgageTargetCompletionDateReason',
                label: 'Please tell us why this date is important'
            },
        },

        LTD: {

            // Page 1 (Company already incorporated)

            LimitedCompanyAlreadyIncorporated: {
                name: 'LimitedCompanyAlreadyIncorporated',
                label: 'Is the Limited Company already incorporated?',
                options: _OPTIONS_YES_NO
            },

            // Page 2 (Company info)

            LimitedCompanyRegisteredName: {
                name: 'LimitedCompanyRegisteredName',
                label: 'Registered company name'
            },

            LimitedCompanyRegistrationNumber: {
                name: 'LimitedCompanyRegistrationNumber',
                label: 'Company registration number'
            },

            LimitedCompanyAddressLine1: {
                name: 'LimitedCompanyAddressLine1',
                label: 'Address 1'
            },

            LimitedCompanyAddressLine2: {
                name: 'LimitedCompanyAddressLine2',
                label: 'Address 2 (optional)'
            },

            LimitedCompanyTown: {
                name: 'LimitedCompanyTown',
                label: 'Town or City'
            },

            LimitedCompanyCounty: {
                name: 'LimitedCompanyCounty',
                label: 'County'
            },

            LimitedCompanyPostcode: {
                name: 'LimitedCompanyPostcode',
                label: 'Postcode'
            },

            LimitedCompanySic: {
                name: 'LimitedCompanySic',
                label: 'Company SIC(s)'
            },

            LimitedCompanyHasBankAccount: {
                name: 'LimitedCompanyHasBankAccount',
                label: 'Has the Limited Company got a bank account?',
                options: _OPTIONS_YES_NO
            },

            LimitedCompanyBankAccountProvider: {
                name: 'LimitedCompanyBankAccountProvider',
                label: 'Bank account provider name and address'
            },

            LimitedCompanyBankAccountNumber: {
                name: 'LimitedCompanyBankAccountNumber',
                label: 'Bank account sort and account number'
            },

            // Page 3 (Company ownership)

            LimitedCompanyDirectorsAreApplicants: {
                name: 'LimitedCompanyDirectorsAreApplicants',
                frontendLabel: 'Are the Directors of the company the same as the applicants to this mortgage?',
                adminLabel: 'Directors of the company the same as the applicants to this mortgage',
                options: _OPTIONS_YES_NO
            },

            LimitedCompanyDirectors: {
                name: 'LimitedCompanyDirectors',
                label: 'Can you provide the full names of each Director below:'
            },

            LimitedCompanyShareholders: {
                name: 'LimitedCompanyShareholders',
                label: 'Name of all shareholder(s) and their percentage ownership',
            },

            LimitedCompanyDepositHow: {
                name: 'LimitedCompanyDepositHow',
                label: 'How will the deposit get into the company?'
            },

            LimitedCompanyDirectorsPersonalGuarantees: {
                name: 'LimitedCompanyDirectorsPersonalGuarantees',
                label: 'Are you aware the Directors will have to give Personal Guarantees?',
                options: _OPTIONS_YES_NO
            },

            // Page 4 (Company history)

            LimitedCompanyPreviouslyOwnedByCompany: {
                name: 'LimitedCompanyPreviouslyOwnedByCompany',
                label: 'Has the company ever been owned, in full or in part, by another Limited Company in the past?',
                options: _OPTIONS_YES_NO
            },

            LimitedCompanyPreviouslyOwnedByCompanyExplanation: {
                name: 'LimitedCompanyPreviouslyOwnedByCompanyExplanation',
                label: 'Please tell us more about this situation',
                adminLabel: ''
            },

            LimitedCompanyTradingCompany: {
                name: 'LimitedCompanyTradingCompany',
                label: 'Has the company ever been a trading company in the past?',
                options: _OPTIONS_YES_NO
            },

            LimitedCompanyTradingCompanyExplanation: {
                name: 'LimitedCompanyTradingCompanyExplanation',
                label: 'Please tell us more about this situation',
                adminLabel: ''
            },

            LimitedCompanyRefusedMortgage: {
                name: 'LimitedCompanyRefusedMortgage',
                label: 'Has the company ever been refused a mortgage on the property to be mortgaged or any other property?',
                options: _OPTIONS_YES_NO
            },

            LimitedCompanyRefusedMortgageExplanation: {
                name: 'LimitedCompanyRefusedMortgageExplanation',
                label: 'Please tell us more about this situation',
                adminLabel: ''
            },

            LimitedCompanyFailedPayments: {
                name: 'LimitedCompanyFailedPayments',
                label: 'Has the company ever failed to keep up payments under any present or previous mortgage/loan or rental agreement?',
                options: _OPTIONS_YES_NO
            },

            LimitedCompanyFailedPaymentsExplanation: {
                name: 'LimitedCompanyFailedPaymentsExplanation',
                label: 'Please tell us more about this situation',
                adminLabel: ''
            },

            LimitedCompanyJudgementForDebt: {
                name: 'LimitedCompanyJudgementForDebt',
                label: 'Has the company ever had a judgement for debt recorded against it?',
                options: _OPTIONS_YES_NO
            },

            LimitedCompanyJudgementForDebtExplanation: {
                name: 'LimitedCompanyJudgementForDebtExplanation',
                label: 'Please tell us more about this situation',
                adminLabel: ''
            },

            LimitedCompanyBuyToLets: {
                name: 'LimitedCompanyBuyToLets',
                label: 'Total number of buy to lets owned by the company'
            },

            LimitedCompanyHolidayLets: {
                name: 'LimitedCompanyHolidayLets',
                label: 'Total number of holiday lets owned by the company'
            },

            LimitedCompanyOtherProperties: {
                name: 'LimitedCompanyOtherProperties',
                label: 'Total number of other properties owned by the company'
            }
        }
    },

    // My Application
    MA: {
        // Surveys, solicitors and insurance
        SSI: {

            EvaluationType: {
                name: 'EvaluationType',
                adminLabel: 'Evaluation type',
                options: _EVALUATION_TYPE.OPTIONS
            },

            KeysContactUsePersonal: {
                name: 'KeysContactUsePersonal',
                label: 'Please use my personal contact details ',
                options: _OPTIONS_YES_NO
            },
            KeysContactBiz: {
                name: 'KeysContactBiz',
                label: 'Business or Estate Agent name (if applicable)'
            },
            KeysContactName: {
                name: 'KeysContactName',
                label: 'Contact name'
            },
            KeysContactNumber: {
                name: 'KeysContactNumber',
                label: 'Contact number'
            },
            KeysAddressLine1: {
                name: 'KeysAddressLine1',
                label: 'Address 1'
            },
            KeysAddressLine2: {
                name: 'KeysAddressLine2',
                label: 'Address 2'
            },
            KeysTown: {
                name: 'KeysTown',
                label: 'Town or City'
            },
            KeysCounty: {
                name: 'KeysCounty',
                label: 'County'
            },
            KeysPostcode: {
                name: 'KeysPostcode',
                label: 'Postcode'
            },

            SolicitorChoice: {
                name: 'SolicitorChoice',
                adminLabel: 'Solicitor choice',
                options: _SOLICITOR_CHOICE_TYPE.OPTIONS
            },

            SolicFirm: {
                name: 'SolicFirm',
                label: 'Name of firm'
            },
            SolicName: {
                name: 'SolicName',
                label: 'Name of solicitor'
            },
            SolicNumber: {
                name: 'SolicNumber',
                label: 'Contact number'
            },
            SolicFaxNumber: {
                name: 'SolicFaxNumber',
                label: 'Fax number (if applicable)'
            },
            SolicDXNumber: {
                name: 'SolicDXNumber',
                label: 'DX Number'
            },
            SolicEmailAddr: {
                name: 'SolicEmailAddr',
                label: 'Email address',
                designation: _EMAIL
            },
            SolicAddressLine1: {
                name: 'SolicAddressLine1',
                label: 'Address 1'
            },
            SolicAddressLine2: {
                name: 'SolicAddressLine2',
                label: 'Address 2'
            },
            SolicTown: {
                name: 'SolicTown',
                label: 'Town or City'
            },
            SolicCounty: {
                name: 'SolicCounty',
                label: 'County'
            },
            SolicPostcode: {
                name: 'SolicPostcode',
                label: 'Postcode'
            },

            WhoIsResponsibleForInsur: {
                name: 'WhoIsResponsibleForInsur',
                adminLabel: 'Insurance responsible',
                options: _INSURANCE_RESPONSIBLE_TYPE.OPTIONS
            },
            WhoIsResponsibleForInsurOther: {
                name: 'WhoIsResponsibleForInsurOther',
                label: 'Other insurance responsible description'
            },
            DoYouWantHCMHelpInsur: {
                name: 'DoYouWantHCMHelpInsur',
                label: 'Would you like us to help you arrange holiday letting insurance?',
                options: _OPTIONS_YES_NO
            },
            HowIWillArrangeInsur: {
                name: 'HowIWillArrangeInsur',
                label: 'In terms of putting insurance in place, would you prefer to arrange it:',
                options: [
                    { code: 'yourself', text: 'Yourself' },
                    { code: 'via_lender', text: 'Via your lender' }
                ]
            },

            DoIWantLifeAssurance: {
                name: 'DoIWantLifeAssurance',
                adminLabel: 'Wants to discuss arranging mortgage protection insurance',
                options: _OPTIONS_YES_NO
            },

            DoIWantLifeAssuranceWhyNot: {
                name: 'DoIWantLifeAssuranceWhyNot',
                label: 'Please tell us why not'
            },

            // Obsolete
            DoIWantLifeAssuranceHCMReferal: {
                name: 'DoIWantLifeAssuranceHCMReferal',
                label: 'Would you like us to help you arrange it?',
                options: _OPTIONS_YES_NO
            },

            HaveAWill: {
                name: 'HaveAWill',
                adminLabel: 'Have a will?',
                options: _OPTIONS_YES_NO
            },

            WantsTouchWithPartner: {
                name: 'WantsTouchWithPartner',
                label: 'Would you like us to put you in touch with a recommended partner?',
                options: _OPTIONS_YES_NO
            },

            ConfirmAllTrue: {
                name: 'ConfirmAllTrue',
                label: 'I confirm that the details input into this application, are, to the best of my knowledge, factually correct and I understand that giving false information may jeopardise the mortgage application.',
                options: _OPTIONS_CHECKBOX_CHECKED
            },
        },

        FormalConsent: 'FormalConsent'
    }
};
