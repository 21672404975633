import React, { Component } from 'react';
import PropTypes from "prop-types";
import Alert, {ALERTTYPE} from "./components/alert/Alert";
import ContactUs from "../util/ContactUs";

class QuestionBlock extends Component {
    static propTypes = {
        cols: PropTypes.number,
        controlButtons: PropTypes.object,
        error: PropTypes.string,
        readOnly: PropTypes.bool,
        reachedStage: PropTypes.string
    };

    static defaultProps = {
        cols:8
    };


    constructor(props) {
        super(props);
        this.state = {};
    }


    renderChildren() {

        return React.Children.map(this.props.children, child => {

            if (!child) {
                return child;
            }

            if (!child.type.canDisableNavigation) {
                return child;
            }

            return React.cloneElement(child, {
                ...child.props,
                disableNavigation: (disable) => {
                    this.setState({ disableNavigation : disable });
                }
            });


        })
    }

    render() {
        let offset = (12 - this.props.cols) / 2;
        return (
            <div className="row">
                <div className={`col-12 col-md-${this.props.cols} offset-md-${offset}`}>
                    <div className="card questions">
                        <div className="card-body">
                            {
                                this.props.readOnly &&
                                <Alert type={ALERTTYPE.INFO}>
                                    <div>
                                        {this.props.reachedStage} If you need to change anything, <ContactUs/>.
                                    </div>
                                </Alert>
                            }
                            {this.renderChildren()}
                            {
                                this.props.error &&
                                <div className="invalid-general invalid-feedback">{this.props.error}</div>
                            }
                            {
                                !this.state.disableNavigation &&
                                this.props.controlButtons
                            }
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default QuestionBlock;
