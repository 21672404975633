export const _REPAYMENT_STRATEGY_TYPE = {
    REGULAR_OVERPAYMENTS: 'regular_overpayments',
    ACCUMULATE_SAVINGS: 'accumulate_savings',
    SALE_MORTGAGED_PROPERTY: 'sale_mortgaged_property',
    SALE_MAIN_RESIDENCE: 'sale_main_residence',
    SALE_ANOTHER_PROPERTY: 'sale_another_property',
    LIFE_ASSURANCE: 'life_assurance',
    PENSION_LUMP: 'pension_lump',
    OTHER: 'other',
};

_REPAYMENT_STRATEGY_TYPE.OPTIONS = [
    {
        code: _REPAYMENT_STRATEGY_TYPE.SALE_MORTGAGED_PROPERTY,
        text: 'Sale of the mortgaged property itself',
        tooltip: 'Sale of the property itself is the most common option for a second home, as some of the other options invite further due diligence from the lender.'
    },
    {
        code: _REPAYMENT_STRATEGY_TYPE.REGULAR_OVERPAYMENTS,
        text: 'Make regular overpayments'
    },
    {
        code: _REPAYMENT_STRATEGY_TYPE.ACCUMULATE_SAVINGS,
        text: 'Accumulate savings, ISAs, investments'
    },
    {
        code: _REPAYMENT_STRATEGY_TYPE.SALE_MAIN_RESIDENCE,
        text: 'Sale of your main residence'
    },
    {
        code: _REPAYMENT_STRATEGY_TYPE.SALE_ANOTHER_PROPERTY,
        text: 'Sale of another property'
    },
    {
        code: _REPAYMENT_STRATEGY_TYPE.LIFE_ASSURANCE,
        text: 'Life or term assurance product'
    },
    {
        code: _REPAYMENT_STRATEGY_TYPE.PENSION_LUMP,
        text: 'Pension lump sum payment'
    },
    {
        code: _REPAYMENT_STRATEGY_TYPE.OTHER,
        text: 'Other'
    },
];
