import * as React from "react";

import QuestionBlock from "../../../../form/QuestionBlock";
import FieldInput from "common-form/components/FieldInput";
import FieldDate from "common-form/components/FieldDate";
import FieldDropdown from "../../../../form/components/dropdown/FieldDropdown";
import FieldLabel from "common-form/components/FieldLabel";
import FieldRadioGroup from "../../../../form/components/radio/FieldRadioGroup";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldTextArea from "../../../../form/components/FieldTextArea";
import {Alert, ALERTTYPE} from "../../../../form/components/alert/Alert";
import {LABEL_TYPE} from "common-form/components/Field";

import { formatCurrency } from "../../../../util/currency";

import {
    FIELDS,
    INTEREST_RATE_TYPE
} from "common-hypotheca";


export default function RemortgageDetails(parent, nextBlockId) {
   

    function isNonStandardRateType() {
        const value = parent.getValue(FIELDS.MM.MD.ExistingRemortMortIntRateType);
        return value && value !== INTEREST_RATE_TYPE.STANDARD;
    }

    function havePenalties() {
        return parent.getValue(FIELDS.MM.MD.ExistingRemortMortHaveERCs) === true;
    }

    function willingToPayPenalties() {
        return havePenalties() && parent.getValue(FIELDS.MM.MD.ExistingRemortMortHaveERCsCostWillPay) === true;
    }

    function hasApproachedLender() {
        return parent.getValue(FIELDS.MM.MD.ExistingRemortApproachedLenderForFunds) === true;
    }

    function hasSecondCharge() {
        return parent.getValue(FIELDS.MM.MD.ExistingRemortSecondCharge) === true;
    }

    function getBorrowingLevel() {
        return parent.getValue(FIELDS.MM.MD.BorrowingLevel);
    }

    function hasBorrowingLevel() {
        return getBorrowingLevel() != null;
    }

    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldLabel
                    label="Please tell us more about the existing mortgage on your holiday let"
                />

                {
                    hasBorrowingLevel() &&
                    <Alert
                        type={ALERTTYPE.INFO}
                        text={`Your current mortgage borrowing amount is ${formatCurrency(getBorrowingLevel())}`}
                    />
                }

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortMortProviderName)}
                />

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortMortProviderAccNo)}
                />

                <div className="date-boxes">
                <FieldDate
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortMortStartDate)}
                />
                </div>

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    type="number"
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortMortTermYears)}
                />

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortOriginalBorrowing)}
                />

                <FieldRadioGroup
                    inline={true}
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortMortType)}
                />

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortMortIntRate)}
                />

                <FieldDropdown
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortMortIntRateType)}
                />

                <div className="date-boxes">
                <FieldDate
                    labelType={LABEL_TYPE.Small}
                    show={() => isNonStandardRateType()}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortMortFixedPeriodLeftMonths)}
                />
                </div>

                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    type="number"
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortMortHaveERCs)}
                />

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    show={() => havePenalties()}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortMortHaveERCsCost)}
                />

                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    show={() => havePenalties()}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortMortHaveERCsCostWillPay)}
                />

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    show={() => willingToPayPenalties()}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortMortHaveERCsCostWillPayDescr)}
                />

                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortMortPortable)}
                />


                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortApproachedLenderForFunds)}
                />

                <FieldTextArea
                    labelType={LABEL_TYPE.Small}
                    show={() => hasApproachedLender()}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortApproachOutcome)}
                />

                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortIncludeLenderInSearch)}
                />

                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MD.ExistingRemortSecondCharge)}
                />

                {
                    hasSecondCharge() && 

                    <>

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MM.MD.ExistingRemortSecondProviderName)}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MM.MD.ExistingRemortSecondBalance)}
                    />

                    </>

                }

            </QuestionBlock>,

        validate: (context) => {

            context
            .mandatory(FIELDS.MM.MD.ExistingRemortMortProviderName)
            .mandatory(FIELDS.MM.MD.ExistingRemortMortProviderAccNo)
            .date(FIELDS.MM.MD.ExistingRemortMortStartDate)
            .mandatory(FIELDS.MM.MD.ExistingRemortOriginalBorrowing)
            .mandatory(FIELDS.MM.MD.ExistingRemortMortTermYears)
            .mandatory(FIELDS.MM.MD.ExistingRemortMortType)
            .mandatory(FIELDS.MM.MD.ExistingRemortMortIntRate)
            .mandatory(FIELDS.MM.MD.ExistingRemortMortIntRateType)
            .mandatory(FIELDS.MM.MD.ExistingRemortMortHaveERCs)
            .mandatory(FIELDS.MM.MD.ExistingRemortMortPortable)
            .mandatory(FIELDS.MM.MD.ExistingRemortApproachedLenderForFunds)
            .mandatory(FIELDS.MM.MD.ExistingRemortIncludeLenderInSearch)
            .mandatory(FIELDS.MM.MD.ExistingRemortSecondCharge)
            ;

            context.mandatoryOrCleanup(
                isNonStandardRateType(),
                FIELDS.MM.MD.ExistingRemortMortFixedPeriodLeftMonths
            );

            context.mandatoryOrCleanup(
                havePenalties(),
                [FIELDS.MM.MD.ExistingRemortMortHaveERCsCost, FIELDS.MM.MD.ExistingRemortMortHaveERCsCostWillPay]
            );

            context.mandatoryOrCleanup(
                havePenalties() && willingToPayPenalties(),
                FIELDS.MM.MD.ExistingRemortMortHaveERCsCostWillPayDescr
            );

            context.mandatoryOrCleanup(
                hasApproachedLender(),
                FIELDS.MM.MD.ExistingRemortApproachOutcome
            );

            context.mandatoryOrCleanup(
                hasSecondCharge(),
                [
                    FIELDS.MM.MD.ExistingRemortSecondProviderName, 
                    FIELDS.MM.MD.ExistingRemortSecondBalance
                ]
            );

        },

        nextQuestionId: () => nextBlockId
    };

    
};