import {
  FIELDS,
  EMPLOYMENT_TYPE,
  EMPLOYEE_TYPE,
  OTHER,
  SELF_EMPLOYMENT_TYPE
} from "common-hypotheca";

export function validateEmployment(parent, context, index) {

    const employeeType = context.fieldValue(FIELDS.MS.EI.EmploymentType);
    if (!employeeType) {
        context.addError(FIELDS.MS.EI.EmploymentType, null, 'Missing employment type');
        return;
    }

    switch (employeeType) {

        case EMPLOYMENT_TYPE.EMPLOYEE.code:
            validateEmploymentBasic(parent, context, index);
            validateEmploymentEarning(parent, context, index);
            return;

        case EMPLOYMENT_TYPE.SELF_EMPLOYED.code:

            const selfType = context.fieldValue(FIELDS.MS.EI.SelfEmpType);
            if (!selfType) {
              context.addError(FIELDS.MS.EI.SelfEmpType, null, 'Missing self employment type');
              return;
            }

            switch (selfType) {
                case SELF_EMPLOYMENT_TYPE.DIRECTOR:
                    validateDirectorBasic(parent, context, index);
                    validateDirectorDetails(parent, context, index);
                    return;

                case SELF_EMPLOYMENT_TYPE.SOLE_TRADER:
                    validateSoletraderBasic(parent, context, index);
                    validateSoletraderDetails(parent, context, index);
                    return;

                case SELF_EMPLOYMENT_TYPE.PARTNER:
                    validatePartnerBasic(parent, context, index);
                    validatePartnerDetails(parent, context, index);
                    return;

                default:
                    return;
            }

        case EMPLOYMENT_TYPE.NOT_WORKING.code:
        case EMPLOYMENT_TYPE.RETIRED.code:
        default:
            return;
    }
}

export function validateEmploymentBasic(parent, context, index) {
    context
        .mandatory(FIELDS.MS.EI.EmploymentName)
        .mandatory(FIELDS.MS.EI.EmployerName)
        .mandatory(FIELDS.MS.EI.EmployerAddress)
        .optional(FIELDS.MS.EI.EmployerAddress2)
        .mandatory(FIELDS.MS.EI.EmployerAddressTown)
        .optional(FIELDS.MS.EI.EmployerAddressCounty)
        .mandatory(FIELDS.MS.EI.EmployerAddressPostcode)
        .date(FIELDS.MS.EI.EmploymentStartDate)
        .mandatoryOrCleanup(index !== 0, FIELDS.MS.EI.EmploymentIsCurrent)
        .dateOrCleanup(
            index !== 0
            && !context.fieldValue(FIELDS.MS.EI.EmploymentIsCurrent),
            FIELDS.MS.EI.EmploymentEndDate)
        .mandatory(FIELDS.MS.EI.ContractType)
        .mandatory(FIELDS.MS.EI.FullorPartTime)
        .mandatory(FIELDS.MS.EI.InProbationPeriod)
        .mandatoryOrCleanup(context.fieldValue(FIELDS.MS.EI.ContractType) === EMPLOYEE_TYPE.TEMP, FIELDS.MS.EI.TempMonthsLeft)
        .mandatoryOrCleanup(context.fieldValue(FIELDS.MS.EI.InProbationPeriod) === true, FIELDS.MS.EI.ProbationMonthsLeft)
        ;
}

export function validateEmploymentEarning(parent, context, index) {
    context
        .mandatory(FIELDS.MS.EI.BasicAnnualSal)
        .optional(FIELDS.MS.EI.GuaranteAnnualCarAllow)
        .mandatory(FIELDS.MS.EI.PayFrequency)
        .mandatory(FIELDS.MS.EI.HowAreYouPaid)
        .mandatory(FIELDS.MS.EI.NetMonthlyPayment)

        .optional(FIELDS.MS.EI.AnnualRegularOT)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.AnnualRegularOT) > 0,
            FIELDS.MS.EI.RegularOTGuaranteed)

        .optional(FIELDS.MS.EI.AnnualRegularBonus)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.AnnualRegularBonus) > 0,
            FIELDS.MS.EI.RegularBonusGuaranteed)

        .optional(FIELDS.MS.EI.AnnualRegularComm)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.AnnualRegularComm) > 0,
            FIELDS.MS.EI.RegularComGuaranteed)

        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.PayFrequency) === OTHER,
            FIELDS.MS.EI.PayFrequencyOther)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.HowAreYouPaid) === OTHER,
            FIELDS.MS.EI.HowAreYouPaidOther)
        ;
}


export function validatePartnerBasic(parent, context, index) {
    context
        .mandatory(FIELDS.MS.EI.EmploymentName)
        .mandatory(FIELDS.MS.EI.PartnerPctProfitShare)
        .mandatory(FIELDS.MS.EI.EmploymentDescription)
        .mandatory(FIELDS.MS.EI.PartnerTradingAddress)
        .optional(FIELDS.MS.EI.PartnerTradingAddress2)
        .mandatory(FIELDS.MS.EI.PartnerTradingAddressTown)
        .optional(FIELDS.MS.EI.PartnerTradingAddressCounty)
        .mandatory(FIELDS.MS.EI.PartnerTradingAddressPostcode)
        .date(FIELDS.MS.EI.EmploymentStartDate)
        .mandatoryOrCleanup(index !== 0, FIELDS.MS.EI.EmploymentIsCurrent)
        .dateOrCleanup(
            index !== 0
            && !context.fieldValue(FIELDS.MS.EI.EmploymentIsCurrent),
            FIELDS.MS.EI.EmploymentEndDate);
}

export function validatePartnerDetails(parent, context, index) {
    context
        .mandatory(FIELDS.MS.EI.PartnerDoYouHaveAccountant)
        .mandatory(FIELDS.MS.EI.PartnerDoYouProdAccts)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.PartnerDoYouHaveAccountant) === true,
            [FIELDS.MS.EI.PartnerAccountantName, FIELDS.MS.EI.PartnerAccountantQual])
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.PartnerDoYouHaveAccountant) === true
            && context.fieldValue(FIELDS.MS.EI.PartnerAccountantQual) === OTHER,
            FIELDS.MS.EI.PartnerAccountantQualOther)
        .mandatory(FIELDS.MS.EI.PartnerBusinessIncome)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.PartnerBusinessIncome) === true,
            FIELDS.MS.EI.PartnerBusinessIncomeChangedSignificantly)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.PartnerBusinessIncome) === false,
            FIELDS.MS.EI.PartnerBusinessIncomeNoReason)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.PartnerBusinessIncomeChangedSignificantly) === true &&
            context.fieldValue(FIELDS.MS.EI.PartnerBusinessIncome) === true,
            FIELDS.MS.EI.PartnerBusinessIncomeChangedReason)
        ;

    cleanupCovid19Block(context);
}

export function validateDirectorBasic(parent, context, index) {
    context
        .mandatory(FIELDS.MS.EI.EmploymentName)
        .mandatory(FIELDS.MS.EI.DirSharePctCapitalOwned)
        .mandatory(FIELDS.MS.EI.EmploymentDescription)
        .mandatory(FIELDS.MS.EI.DirShareTradingAddress)
        .optional(FIELDS.MS.EI.DirShareTradingAddress2)
        .mandatory(FIELDS.MS.EI.DirShareTradingAddressTown)
        .optional(FIELDS.MS.EI.DirShareTradingAddressCounty)
        .mandatory(FIELDS.MS.EI.DirShareTradingAddressPostcode)
        .date(FIELDS.MS.EI.EmploymentStartDate)
        .mandatoryOrCleanup(index !== 0, FIELDS.MS.EI.EmploymentIsCurrent)
        .dateOrCleanup(
            index !== 0
            && !context.fieldValue(FIELDS.MS.EI.EmploymentIsCurrent),
            FIELDS.MS.EI.EmploymentEndDate);
}

export function validateDirectorDetails(parent, context, index) {
    context
        .mandatory(FIELDS.MS.EI.DirShareDoYouHaveAccountant)
        .mandatory(FIELDS.MS.EI.DirShareDoYouProdAccts)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.DirShareDoYouHaveAccountant) === true,
            [FIELDS.MS.EI.DirShareAccountantName, FIELDS.MS.EI.DirShareAccountantQual])
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.DirShareDoYouHaveAccountant) === true
            && context.fieldValue(FIELDS.MS.EI.DirShareAccountantQual) === OTHER,
            FIELDS.MS.EI.DirShareAccountantQualOther)
        .mandatory(FIELDS.MS.EI.DirShareBusinessIncome)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.DirShareBusinessIncome) === true,
            FIELDS.MS.EI.DirShareBusinessIncomeChangedSignificantly)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.DirShareBusinessIncome) === false,
            FIELDS.MS.EI.DirShareBusinessIncomeNoReason)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.DirShareBusinessIncomeChangedSignificantly) === true &&
            context.fieldValue(FIELDS.MS.EI.DirShareBusinessIncome) === true,
            FIELDS.MS.EI.DirShareBusinessIncomeChangedReason)
        ;

    cleanupCovid19Block(context);
}

export function validateSoletraderBasic(parent, context, index) {

    context
        .mandatory(FIELDS.MS.EI.EmploymentName)
        .mandatory(FIELDS.MS.EI.EmploymentDescription)
        .mandatory(FIELDS.MS.EI.SoleTradTradingAddress)
        .optional(FIELDS.MS.EI.SoleTradTradingAddress2)
        .mandatory(FIELDS.MS.EI.SoleTradTradingAddressTown)
        .optional(FIELDS.MS.EI.SoleTradTradingAddressCounty)
        .mandatory(FIELDS.MS.EI.SoleTradTradingAddressPostcode)
        .date(FIELDS.MS.EI.EmploymentStartDate)
        .mandatoryOrCleanup(index !== 0, FIELDS.MS.EI.EmploymentIsCurrent)
        .dateOrCleanup(
            index !== 0
            && !context.fieldValue(FIELDS.MS.EI.EmploymentIsCurrent),
            FIELDS.MS.EI.EmploymentEndDate);
}

export function validateSoletraderDetails(parent, context, index) {
    context
        .mandatory(FIELDS.MS.EI.SoleTradDoYouHaveAccountant)
        .mandatory(FIELDS.MS.EI.SoleTradDoYouProdAccts)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.SoleTradDoYouHaveAccountant) === true,
            [FIELDS.MS.EI.SoleTradAccountantName, FIELDS.MS.EI.SoleTradAccountantQual])
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.SoleTradDoYouHaveAccountant) === true
            && context.fieldValue(FIELDS.MS.EI.SoleTradAccountantQual) === OTHER,
            FIELDS.MS.EI.SoleTradAccountantQualOther)
        .mandatory(FIELDS.MS.EI.SoleTradBusinessIncome)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.SoleTradBusinessIncome) === true,
            FIELDS.MS.EI.SoleTradBusinessIncomeChangedSignificantly)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.SoleTradBusinessIncome) === false,
            FIELDS.MS.EI.SoleTradBusinessIncomeNoReason)
        .mandatoryOrCleanup(
            context.fieldValue(FIELDS.MS.EI.SoleTradBusinessIncomeChangedSignificantly) === true &&
            context.fieldValue(FIELDS.MS.EI.SoleTradBusinessIncome) === true,
            FIELDS.MS.EI.SoleTradBusinessIncomeChangedReason)
        ;

    cleanupCovid19Block(context);
}

function cleanupCovid19Block(context) {
    context
        .cleanupIrrelevantField(FIELDS.MS.EI.HaveTakenCovid19Grants)
        .cleanupIrrelevantField(FIELDS.MS.EI.HaveTakenCovid19GrantsDetails)
        .cleanupIrrelevantField(FIELDS.MS.EI.Covid19Impact);
}