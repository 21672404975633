import React from 'react';
import PropTypes from "prop-types";
import Field from './Field';
import {GlobalRandom} from "../util/randomString";

class FieldDate extends Field {

    static defaultProps = {
        value: null,
        type: 'text',
        show:true,
        disabled: false
    };

    showDay = () => {
        return this.props.showDay === false ? false : true;
    };

    handleChange(from, length, event, pattern) {
        if (!RegExp(pattern).test(event.target.value))
            return;

        let newValue = this.props.value;

        if (newValue === undefined || newValue ===null || newValue.length < 10) {
            newValue = this.showDay() ? '  /  /    ' : '01/  /    ';
        }

        newValue = (from > 0 ? newValue.substring(0, from) : '')
            + ('    ' + event.target.value).slice(-length)
            + (from + length < 10 ? newValue.substring(from + length, 10) : '');

        if (this.props.onValueChange) {
            this.props.onValueChange(newValue);
        } else {
            this.props.handleFieldFilled(this.props.applicantId, this.props.field, newValue);
        }
    }

    getValuePart = (from, length) => {
        return this.props.value === undefined || this.props.value === null
            ? ''
            : this.props.value.substr(from, length).trim();
    };

    render() {
        if (!this.show())
            return null;

        let label = this.label();

        return (
            <div className="form-group">
                { label &&
                <label htmlFor={this.props.field} className={this.getLabelClassNameFromType()}>{label}</label>
                }
                <div className="form-row hypo-date-row">
                    {
                        this.showDay() &&
                        <div className="hypo-date-item hypo-date-day">
                            <input autoComplete={this.props.field + '-d-' + GlobalRandom.POSTFIX}
                                   disabled={this.props.disabled}
                                   type="text"
                                   className={"form-control" + this.validityClasses()}
                                   placeholder="DD"
                                   value={this.getValuePart(0, 2)}
                                   onChange={(event) => this.handleChange(0, 2, event, "^([0-9]?|0[1-9]|1[0-9]|2[0-9]|3[01])$")}
                                   maxLength={2}
                                   pattern="\d*"
                            />
                        </div>
                    }
                    <div className="hypo-date-item hypo-date-month">
                        <input autoComplete={this.props.field + '-m-' + GlobalRandom.POSTFIX}
                               disabled={this.props.disabled}
                               type="text"
                               className={"form-control" + this.validityClasses()}
                               placeholder="MM"
                               value={this.getValuePart(3, 2)}
                               onChange={(event) => this.handleChange(3, 2, event, "^([0-9]?|0[1-9]|1[012])$")}
                               maxLength={2}
                               pattern="\d*"
                        />
                    </div>
                    <div className="hypo-date-item hypo-date-year">
                        <input autoComplete={this.props.field + '-y-' + GlobalRandom.POSTFIX}
                               disabled={this.props.disabled}
                               type="text"
                               className={"form-control" + this.validityClasses()}
                               placeholder="YYYY"
                               value={this.getValuePart(6, 4)}
                               onChange={(event) => this.handleChange(6, 4, event, "^(|[1-2][0-9]{0,3})$")}
                               maxLength={4}
                               pattern="\d*"
                        />
                    </div>
                </div>
                {
                    this.renderError()
                }
            </div>
        );
    }
}

FieldDate.propTypes = {
    label: PropTypes.any,
    labelType: PropTypes.string,
    showDay: PropTypes.bool,
    value: PropTypes.string,
    field: PropTypes.any.isRequired,
    handleFieldFilled: PropTypes.func,
    show: PropTypes.any,
    disabled: PropTypes.bool
};

export default FieldDate;
