import React from 'react';
import PropTypes from "prop-types";
import Field from "../Field";
import FieldDropdownItem from "./FieldDropdownItem";

class FieldDropdown extends Field {
    render() {
        if (!this.show())
            return null;

        let label = this.label();

        let itemsMarkup = [];

        if (this.props.items !== undefined) {
            for (let item of this.props.items) {

                // Show obsolete items only when displaying read-only drop-down
                if (item.obsolete && !this.props.disabled) {
                    continue;
                }

                itemsMarkup.push(<FieldDropdownItem disabled={item.disabled} key={this.props.field + '-' + item.code} label={item.text} code={item.code}/>);
            }
        }

        return (
            <div className="form-group mb-4">
                { label &&
                    <label htmlFor={this.props.field} className={this.getLabelClassNameFromType()}>{label}</label>
                }
                <select
                    disabled={this.props.disabled}
                    className={"form-select custom-select" + this.validityClasses()}
                    id={this.props.field}
                    value={this.props.value || undefined}
                    onChange={this.handleChange}
                >
                    {this.props.children || itemsMarkup}
                </select>
                {
                    this.renderError()
                }
            </div>
        );
    }
}

FieldDropdown.propTypes = {
    items: PropTypes.any,
    label: PropTypes.string,
    field: PropTypes.any.isRequired,
    handleFieldFilled: PropTypes.func,
    value: PropTypes.any,
    show: PropTypes.any,
    valid: PropTypes.bool,
    disabled: PropTypes.bool
};

export default FieldDropdown;
