import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";

import {extractFieldName, FIELDS, OTHER} from "common-hypotheca";
import FieldInput from "common-form/components/FieldInput";
import FieldTextArea from "../../../../../form/components/FieldTextArea";
import FieldGroupAddable from "../../../../../form/components/FieldGroupAddable";
import FieldLabel from "common-form/components/FieldLabel";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import { FieldDropdown } from "../../../../../form/components/dropdown";
import { LABEL_TYPE } from "common-form/components/Field";

export default function CreditCardList(parent, nextBlockId) {

    return {

        block: (params) =>

            <QuestionBlock {...params}>
                <FieldLabel
                    label="Please tell us about your credit or store cards"
                />

                <FieldGroupAddable
                    ref={params.groupAddableRef}
                    addButtonLabel="Add credit card"
                    allowDeleteAll
                    deleteButtonLabel="Remove"
                    editButtonLabel="Edit"
                    saveButtonLabel="Save credit card"
                    itemDescriber={(fieldsContainer) => {
                        if (fieldsContainer[extractFieldName(FIELDS.MS.CC.CreditCardName)] === undefined)
                            return 'A card from unspecified provider';
                        else
                            return 'Card from ' + fieldsContainer[extractFieldName(FIELDS.MS.CC.CreditCardName)];
                    }}
                    validate={(context) => {
                        context
                            .mandatory(FIELDS.MS.CC.CreditCardName)
                            .mandatory(FIELDS.MS.CC.CreditCardUsage)
                            .mandatory(FIELDS.MS.CC.CreditCardCurrentBal)
                            .mandatory(FIELDS.MS.CC.CreditCardPayEachMonth)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.CreditCardPayEachMonth) === false,
                                FIELDS.MS.CC.CreditCardPaidOffCompletion
                            )
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.CreditCardPaidOffCompletion),
                                FIELDS.MS.CC.CreditCardNamePaidOffCompletionHow)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.CreditCardPayEachMonth) === false,
                                FIELDS.MS.CC.CreditCardMonthlyRepaymentAmount)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.CreditCardPaidOffCompletion)
                                && context.fieldValue(FIELDS.MS.CC.CreditCardNamePaidOffCompletionHow) === OTHER,
                                FIELDS.MS.CC.CreditCardNamePaidOffCompletionHowOther);
                    }}
                    {...parent.getFormField(null, FIELDS.MS.CC.CreditCards)}
                >

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CreditCardName}
                    />

                    <FieldTextArea
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CreditCardUsage}
                        placeholder="e.g. regular household purchases, my personal purchases, used just for business ..."
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CreditCardCurrentBal}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CreditCardPayEachMonth}
                    />

                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.CreditCardPayEachMonth)] === false}
                        field={FIELDS.MS.CC.CreditCardMonthlyRepaymentAmount}
                        labelType={LABEL_TYPE.Small}
                    />

                    <FieldRadioYesNo
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.CreditCardPayEachMonth)] === false}
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CreditCardPaidOffCompletion}
                    />

                    <FieldDropdown
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.CreditCardPaidOffCompletion)]}
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.CreditCardNamePaidOffCompletionHow}
                    />

                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.CreditCardPaidOffCompletion)]
                            && container[extractFieldName(FIELDS.MS.CC.CreditCardNamePaidOffCompletionHow.name)] === OTHER}
                        field={FIELDS.MS.CC.CreditCardNamePaidOffCompletionHowOther}
                        labelType={LABEL_TYPE.Small}
                        placeholder="Other method description"
                    />

                </FieldGroupAddable>
            </QuestionBlock>,


        validate: (context) => {

            if (!context.hasItems(FIELDS.MS.CC.CreditCards)) {
                context.addError(null, null, "Please enter an item or go back to the previous screen and change your selection.");
            }

            if (parent.groupAddableRef && !parent.groupAddableRef.current.valid()) {
                context.addError(FIELDS.MS.CC.CreditCards, null, '');
            }

        },

        nextQuestionId: () => parent.nextSelectedCommitmentType(nextBlockId)
    };
}