import React, { Fragment, Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from 'react-router';

class FormButton extends Component {
    static propTypes = {
        text: PropTypes.any,
        hide: PropTypes.bool,
        path: PropTypes.string,
        /** Go outside app for URL instead of router-based navigation. Needed for public site integration */
        pathGoHard: PropTypes.bool,
        onClick: PropTypes.func,

        secondary: PropTypes.bool,
        disabled: PropTypes.bool,
        focussed: PropTypes.bool,
        icon: PropTypes.element,
        link: PropTypes.bool,
        navLink: PropTypes.bool,
        type: PropTypes.string,

        dropDownMenu: PropTypes.bool,
        dropDownItem: PropTypes.bool,
        
        block: PropTypes.bool,

        leftMaterialIcon:PropTypes.string,
        rightMaterialIcon:PropTypes.string,

        className: PropTypes.string,
        submit: PropTypes.bool
    };

    static defaultProps = {
        text: undefined,
        hide: false,
        path: null,
        pathGoHard: false,
        icon: null,
        link: false,
        //primary:true,
        secondary: false,
        disabled: false,
    };

    componentDidUpdate() {
        if (this.props.focussed) {

        }
    }

    gotoPath() {
        if (this.props.pathGoHard) {
            window.location = this.props.path;
        } else {
            this.props.history.push(this.props.path);
        }
    }

    handleClick = () => {
        if (this.props.disabled) {
            return;
        }

        this.props.path ? this.gotoPath() : this.props.onClick && this.props.onClick();
    };

    render() {
        if (this.props.hide) { return null; }


        let additionalProps = {};
        let clazz = '';

        if (this.props.link) {
            clazz += `text-link `
        } else {
            clazz += `btn
            ${this.props.secondary ? ' btn-secondary' : ' btn-primary'} 
            ${this.props.icon ? ' btn-icon' : ''}
        `;
        }
        clazz += `${this.props.disabled ? ' disabled' : ''}`;

        if (this.props.navLink) {
            clazz += ' nav-link';
        }

        if (this.props.type) {
            clazz += ' ' + this.props.type;
        }

        if (this.props.block) {
            clazz += ' w-100';
        }

        if (this.props.dropDownMenu) {
            clazz += ' dropdown-toggle';
            additionalProps = {
                ...additionalProps,
                'data-bs-toggle': 'dropdown',
                //'data-toggle': 'dropdown',
                'aria-haspopup': 'true',
                'aria-expanded': 'false'
            }
        }

        if (this.props.className) {
            clazz += ' ' + this.props.className;
        }

        if (this.props.text === undefined || this.props.text === null) {
            clazz += ' no-text';
        }

        let type;

        if (this.props.submit) {
            type = "submit";
        } else {
            type = "button";
        }

        return (
            <Fragment>
                <button type={type} {...additionalProps}
                    className={clazz}
                    onClick={this.handleClick}>
                    {
                        this.props.leftMaterialIcon &&
                        <span className="btn-left-material-icon">{this.props.leftMaterialIcon}</span>
                    }
                    {
                        !!this.props.text &&
                        <span>
                        {this.props.text}
                        </span>
                    }
                    {
                        !!this.props.icon && this.props.icon
                    }
                    {
                        this.props.rightMaterialIcon &&
                        <span className="btn-right-material-icon">{this.props.rightMaterialIcon}</span>
                    }
                </button>
            </Fragment>

        );
    }
}

export default withRouter(FormButton)
export { FormButton as UnroutedFormButton }