import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";

import FieldInput from "common-form/components/FieldInput";
import FieldLabel from "common-form/components/FieldLabel";
import { LABEL_TYPE } from "common-form/components/Field";
import FieldCheckbox from "common-form/components/FieldCheckbox";

import { formatCurrency } from "../../../../../util/currency";

import { FIELDS } from "common-hypotheca";

export default function EssentialItems(parent, nextBlockId) {

    function isSharing() {
        return parent.shouldShowSharingCheck() && parent.getValue(FIELDS.MS.OUT.EssentialAlreadyRecorded) === true;
    }

    return {
        block: (params) =>

            <QuestionBlock {...params}>

                <FieldLabel
                    label="Essential costs: how much do you spend, per month, on the following items"
                />

                <FieldLabel
                    label="If any costs are incurred jointly, record the joint cost just once and leave it blank in the other applicant's profile! If an expenditure type does not apply to you, then please just enter £0."
                    labelType={LABEL_TYPE.Description}
                />

                <FieldCheckbox 
                    label='My share of these costs has already been recorded by another applicant'
                    labelType={LABEL_TYPE.Small}
                    checked={!!parent.getValue(FIELDS.MS.OUT.EssentialAlreadyRecorded)}
                    code={!parent.getValue(FIELDS.MS.OUT.EssentialAlreadyRecorded)}
                    show={() => parent.shouldShowSharingCheck()}
                    onCheckboxClick={val => {
                        parent.handleFilledField(null, FIELDS.MS.OUT.EssentialAlreadyRecorded, val);
                    }}

                />

                {
                    isSharing() === false && 
                    <>

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.OUT.EssentialFoodDrink)}
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.OUT.EssentialUtilities)}
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.OUT.EssentialInsurances)}
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.OUT.EssentialHomeSpend)}
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.OUT.EssentialCarSpend)}
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.OUT.EssentialSavings)}
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.OUT.EssentialLifeAssurance)}
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.OUT.EssentialSchoolFees)}
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.OUT.EssentialOtherCosts)}
                        />

                        <FieldLabel
                            label={`Essential costs total: ${formatCurrency(parent.getValue(FIELDS.MS.OUT.EssentialCostsTotal) || 0)}`}
                        />

                    </>
                }


            </QuestionBlock>,

        nextQuestionId: () => nextBlockId,

        validate: (context) => {

            if (!parent.shouldShowSharingCheck()) {
                context.cleanupIrrelevantField(FIELDS.MS.OUT.EssentialAlreadyRecorded);
            }

            // This doesn't work.
            // Requires checkbox to be checked.
            // context
            // .mandatoryOrCleanup(parent.shouldShowSharingCheck(), FIELDS.MS.OUT.EssentialAlreadyRecorded)
            // ;

            context
            .mandatoryOrCleanup(isSharing() === false, [

                FIELDS.MS.OUT.EssentialFoodDrink,
                FIELDS.MS.OUT.EssentialUtilities,
                FIELDS.MS.OUT.EssentialInsurances,
                FIELDS.MS.OUT.EssentialHomeSpend,
                FIELDS.MS.OUT.EssentialCarSpend,
                FIELDS.MS.OUT.EssentialSavings,
                FIELDS.MS.OUT.EssentialLifeAssurance,
                FIELDS.MS.OUT.EssentialSchoolFees,
                FIELDS.MS.OUT.EssentialOtherCosts

            ]);



            let totalCost = 0;
            totalCost += parseFloat(parent.getValue(FIELDS.MS.OUT.EssentialFoodDrink) || 0);
            totalCost += parseFloat(parent.getValue(FIELDS.MS.OUT.EssentialUtilities) || 0);
            totalCost += parseFloat(parent.getValue(FIELDS.MS.OUT.EssentialInsurances) || 0);
            totalCost += parseFloat(parent.getValue(FIELDS.MS.OUT.EssentialHomeSpend) || 0);
            totalCost += parseFloat(parent.getValue(FIELDS.MS.OUT.EssentialCarSpend) || 0);
            totalCost += parseFloat(parent.getValue(FIELDS.MS.OUT.EssentialSavings) || 0);
            totalCost += parseFloat(parent.getValue(FIELDS.MS.OUT.EssentialLifeAssurance) || 0);
            totalCost += parseFloat(parent.getValue(FIELDS.MS.OUT.EssentialSchoolFees) || 0);
            totalCost += parseFloat(parent.getValue(FIELDS.MS.OUT.EssentialOtherCosts) || 0);

            //calculate totals - pass last param to noValidate avoid stack overflow
            parent.handleFilledField(null, FIELDS.MS.OUT.EssentialCostsTotal, totalCost, null, true);
        }
    };
}