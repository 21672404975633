import { connect } from 'react-redux';
import Authenticator from './Authenticator'
const mapStateToProps = (state, ownProps) => ({
    loggedIn:state.loggedIn
}); 

const mapDispatchToProps = dispatch => ({
 
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Authenticator)
