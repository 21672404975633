import {_OTHER} from "./other";

export const _MORTGAGE_TYPE = {
    INTEREST_ONLY: 'interest_only',
    REPAYMENT: 'repayment'
};

_MORTGAGE_TYPE.DROPDOWN_OPTIONS = [
    { code: '', text: '' },
    { code: _MORTGAGE_TYPE.INTEREST_ONLY, text: 'Interest only' },
    { code: _MORTGAGE_TYPE.REPAYMENT, text: 'Repayment' },
];