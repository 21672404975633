import React from 'react';
import PropTypes from "prop-types";
import Field from '../Field'; 
import FieldRadioButton from './FieldRadioButton';

class FieldRadioGroup extends Field {
    static defaultProps = {
        disabled: false
    };

    handleChange(event) {
        this.props.handleFieldFilled(null, this.props.field, event.target.value);
    }

    renderChildren() {
        return React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                field: this.props.field,
                inline: this.props.inline,
                onChange: this.handleChange,
                checked: child.props.code === this.props.value
            })
        })
    }

    render() {
        if (!this.show())
            return null;

        let label = this.label();

        let itemsMarkup = [];
        if (this.props.items !== undefined) {
            for (let item of this.props.items) {
                itemsMarkup.push(<FieldRadioButton
                    disabled={this.props.disabled}
                    key={this.props.field + '-' + item.code} 
                    field={this.props.field}
                    inline={this.props.inline}
                    onChange={this.handleChange}
                    checked={item.code === this.props.value}
                    code={item.code}
                    label={item.text}
                    tooltip={item.tooltip}
                />);
            }
        }


        return (
            <div className="form-group">
                <div className={'label label-radio-group' + this.validityClasses() + ' ' + this.getLabelClassNameFromType()}>{label}</div>
                <div className={'hypo-inline-radios' + this.validityClasses()}>
                    { this.props.children ? this.renderChildren() : itemsMarkup}
                </div>
                {
                    this.renderError()
                }
            </div>
        );
    }
}

FieldRadioGroup.propTypes = {
    label: PropTypes.string,
    labelType: PropTypes.string,
    field: PropTypes.any.isRequired,
    inline: PropTypes.bool,
    //handleFieldFilled: PropTypes.func.isRequired,
    value: PropTypes.any,
    show: PropTypes.any,
    disabled: PropTypes.bool
};

export default FieldRadioGroup;
