export const _FLAT_TYPE = {
    PURPOSE_BUILT: 'purpose_built',
    CONVERTED_HOUSE: 'converted_house',
    MAISONETTE: 'maisonette'
};

_FLAT_TYPE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: _FLAT_TYPE.PURPOSE_BUILT,
        text: 'Purpose built'
    },
    {
        code: _FLAT_TYPE.CONVERTED_HOUSE,
        text: 'Converted house'
    },
    {
        code: _FLAT_TYPE.MAISONETTE,
        text: 'Maisonette'
    }
];
