import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import {FieldRadioPicture} from "../../../../../form/components/radio";
import FieldGroup from "../../../../../form/components/FieldGroup";

import {extractFieldName, FIELDS} from "common-hypotheca";

export default function SelfEmployed(parent) {

    return {
        block: (params) =>

           <QuestionBlock {...params}>

                <FieldGroup
                    item={parent.getCurrentEmployment()}
                    {...parent.getField(FIELDS.MS.EI.Employments)}
                    beforeEdit={function() {
                        parent.setState({
                            selfTypeChanged: true
                        });
                    }}
                >

                    <FieldRadioPicture
                        label={parent.getApplicantName() + ", on what basis would you say you are self employed?\n"}
                        field={FIELDS.MS.EI.SelfEmpType}
                    />

                </FieldGroup>
            </QuestionBlock>,

        validate: (context) => {

            const employment = parent.getCurrentEmployment();
            if (!employment[extractFieldName(FIELDS.MS.EI.SelfEmpType)]) {
                context.addError('', '', 'Required');
            }
            
        },

        nextQuestionId: () => parent.afterSelfEmployed()
    };


};