import {_OTHER} from "./other";

export const _PAY_TYPE = {
    BANK: 'bank',
    CASH: 'cash',
    CHEQUE: 'cheque'
};

_PAY_TYPE.OPTIONS = [
    { code: '', text: '' },
    { code: _PAY_TYPE.BANK, text: 'Direct to bank account' },
    { code: _PAY_TYPE.CASH, text: 'Cash' },
    { code: _PAY_TYPE.CHEQUE, text: 'Cheque' },
    { code: _OTHER, text: 'Other (add)' }
];