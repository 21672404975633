import { connect } from 'react-redux';
import AgentReferral from './AgentReferral';

const mapStateToProps = (state, ownProps) => ({
    loggedIn:state.loggedIn
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgentReferral)
