/**
 * FRONTEND CUSTOMER CONSTANTS
 *
 */
import {
    SECTION
} from "common-hypotheca";

export const APINAME = 'api7a077e54';

const APIPREFIX = '/portal/public';
export const APIPATH = {
    CREATEAPPLICATION:APIPREFIX + '/createapplication',
    APPLICATION: APIPREFIX + '/application',
    REFERRAL: APIPREFIX + '/referral',
    ACTION: APIPREFIX + '/action',
    USER: APIPREFIX + '/user',
    NOTIFICATION: APIPREFIX + '/notification',
    TRIGGER: APIPREFIX + '/trigger',
    PHONENUMBER:{
        NEW: `${APIPREFIX}/phonenumber/new`,
        SUBMITVERIFICATION: `${APIPREFIX}/phonenumber/submitverification`
    },
    SIGNIN:  APIPREFIX + '/signin',
    AGENT: APIPREFIX + '/agent',
    CONFIRMUSER: APIPREFIX + '/confirmuser'
};

export const CAN_SUBMIT = 'can_submit';
export const REFERRALLOCALSTORAGEKEY = 'holidaycottagemortgagesreferralcode';

export const H_AUTH_CHANNEL = 'h-auth-channel';
export const H_SIGN_IN_EVENT = 'h-sign-in-event';
export const H_CONFIRM_EMAIL_EVENT = 'h-confirm-email-event';
// Previously there was an intermediate screen with security questions
export const H_CONFIRM_QUESTIONS_EVENT = 'h-confirm-questions-event';

export const UNKNOWN_NICKNAME = 'unknown';

export const SECTION_OBSOLETE = {
    GET_STARTED: 'getstarted'
};

export const AUTH_ERRORS = {
    UserNotFoundException: 'UserNotFoundException',
    InvalidParameterException: 'InvalidParameterException',
    ExpiredCodeException: 'ExpiredCodeException',
    CodeMismatchException: 'CodeMismatchException'
};

export function nextSectionInWorkflow(currentSectionId) {
    switch (currentSectionId) {
        case SECTION.SCREENING:
            return SECTION.SITUATION;
        case SECTION.SITUATION:
            return SECTION.PROPERTY;
        case SECTION.PROPERTY:
            return SECTION.MY_MORTGAGE;
        case SECTION.MY_MORTGAGE:
            return SECTION.MY_APPLICATION;
        case SECTION.MY_APPLICATION:
            return SECTION.APPLICATION_STATUS;
        default:
            return currentSectionId;
    }
}


export const QUALIFICATION_TYPE = {
    FCA: {
        code: 'FCA',
        desc: 'FCA'
    },
    ACA: {
        code: 'ACA',
        desc: 'ACA'
    },
    ACCA: {
        code: 'ACCA',
        desc: 'ACCA'
    },
    CIMA: {
        code: 'CIMA',
        desc: 'CIMA'
    },
    SCOTTISH: {
        code: 'ScottishCA',
        desc: 'Scottish CA'
    },
    OTHER: {
        code: 'other',
        desc: 'Other (add)'
    }
};


export const PROPERTY_USAGE = {
    MAIN: {
        code: 'main-residence',
        desc: 'Main residence'
    },
    BUY_TO_LET_RENTED: {
        code: 'buy-let-rented',
        desc: 'Buy to let'
    },
    HOLIDAY_LET: {
        code: 'holiday-let',
        desc: 'Holiday let'
    },
    SECOND_HOME: {
        code: 'second-home',
        desc: 'Second home'
    },
    EMPTY: {
        code: 'empty',
        desc: 'Empty'
    }
};

