import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import {FieldRadioPicture} from "../../../../../form/components/radio";
import {FieldLabel} from "common-form/components/FieldLabel";
import {LABEL_TYPE} from "../../../../../form/components/Field";

import {
    FIELDS
} from "common-hypotheca";


export default function EvaluationType(parent, nextQuestionId) {
    
    return {
        block: (params) =>

            <QuestionBlock {...params}>

                <FieldLabel
                    label="What type of valuation do you require?"
                />

                <FieldLabel
                    label="Many lenders only offer a Basic survey and so you may have to appoint your own Homebuyer or Building survey. Also, most surveys have a fee so please discuss your preferred options with your Advisor."
                    labelType={LABEL_TYPE.Description}
                />

                <FieldRadioPicture
                    {...parent.getField(FIELDS.MA.SSI.EvaluationType)}
                />

            </QuestionBlock>,


        validate: (context) => {
            context
            .mandatory(FIELDS.MA.SSI.EvaluationType)
            ;
        },

        nextQuestionId: () => nextQuestionId
    };

};