export const _NEW_OR_EXISTING_LET = {
    EXISTING_HOLIDAY_LET: 'existing_holiday_let',
    NEW_HOLIDAY_LET: 'new_holiday_let'
};

_NEW_OR_EXISTING_LET.OPTIONS = [
    {
        code: _NEW_OR_EXISTING_LET.EXISTING_HOLIDAY_LET,
        text: 'Existing holiday let',
        icon: 'business'
    },
    {
        code: _NEW_OR_EXISTING_LET.NEW_HOLIDAY_LET,
        text: 'New holiday let',
        icon: 'play_circle_outline'
    }
];