export const _INSURANCE_RESPONSIBLE_TYPE = {
    MYSELF: 'myself',
    FREEHOLDER: 'freeholder',
    OTHER: 'other'
};

_INSURANCE_RESPONSIBLE_TYPE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: _INSURANCE_RESPONSIBLE_TYPE.MYSELF,
        text: 'Myself'
    },
    {
        code: _INSURANCE_RESPONSIBLE_TYPE.FREEHOLDER,
        text: 'Freeholder'
    },
    {
        code: _INSURANCE_RESPONSIBLE_TYPE.OTHER,
        text: 'Other (add)'
    }
];
