export const _RELATIONSHIP_TYPE = {
    SINGLE: 'single',
    MARRIED: 'married',
    CIVIL_PARTNERSHIP: 'civil_partnership',
    COHABITING: 'cohabiting',
    DIVORCED: 'divorced',
    WIDOWED: 'widowed',
    SEPARATED: 'separated'
};

_RELATIONSHIP_TYPE.OPTIONS = [
    {
        code: '',
        text: '',
    },
    {
        code: _RELATIONSHIP_TYPE.SINGLE,
        text: 'Single'
    },
    {
        code: _RELATIONSHIP_TYPE.MARRIED,
        text: 'Married'
    },
    {
        code: _RELATIONSHIP_TYPE.CIVIL_PARTNERSHIP,
        text: 'Civil Partnership'
    },
    {
        code: _RELATIONSHIP_TYPE.COHABITING,
        text: 'Cohabiting'
    },
    {
        code: _RELATIONSHIP_TYPE.DIVORCED,
        text: 'Divorced'
    },
    {
        code: _RELATIONSHIP_TYPE.WIDOWED,
        text: 'Widowed'
    },
    {
        code: _RELATIONSHIP_TYPE.SEPARATED,
        text: 'Separated'
    }
];
