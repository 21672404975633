import {
  SET_EXISTING_APPLICATIONS,
  SET_DATA,
  LOGIN,
  LOGOUT,
  START_NEW_APPLICATION,
  REQUEST_APPLICATION_DATA,
  RECEIVE_APPLICATION_DATA,
  UPDATE_PROGRESS,
  FCADISMISSED,
  SET_APPLICATION_ID,
  SET_USER_DATA,
  SAVED_APPLICATION,
  SAVED_USER
} from "./actions";
import { getBlankApplication } from "../util/models";

const initialState = {
    loggedIn: false,
    apiExtra: undefined,
    applicationId: null,
    sectionId: null,
    partId: null,
    applicantId: null,
    data: undefined,
    user: undefined,
    isInAPICall: false,
    existingApplications: undefined,
    nextApplicationId: undefined,
    progress: 0,
    FCADismissed: false,
    lastSavedApplication: null,
    autoSaveFailures: 0,
    autoSaveError: undefined,
    lastSavedUser: null
};


function loginState(state, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loggedIn: true,
        user: action.user,
        apiExtra: action.apiExtra
      };

    case LOGOUT:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
function userAccount(state, action) {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        user: {
          ...action.user,
          lastChanged: new Date().getTime() //this is also a kludge!!
        }
      };
    default:
      return state;
  }
}
function popups(state, action) {
  switch (action.type) {
    case FCADISMISSED:
      return {
        ...state,
        FCADismissed: true
      };
    default:
      return state;
  }
}
function startApplication(state, action) {
  switch (action.type) {
    case SET_EXISTING_APPLICATIONS:
      return {
        ...state,
        existingApplications: action.data,
        nextApplicationId: action.nextApplicationId
      };

    case START_NEW_APPLICATION:
      return {
        ...state,
        applicationId: action.applicationId,
        data: action.data,
        preventPersistForLastChanged: action.data.lastChanged
      };

    case SET_APPLICATION_ID:
      return {
        ...state,
        applicationId: action.applicationId
      };
    // case RESUME_APPLICATION:
    //     if (state.loggedIn) {
    //         return {
    //             ...state,
    //             applicationId: action.urlData.applicationId,
    //         }
    //     } else {
    //         //TODO goto homepage
    //         return {
    //             ...state,
    //         }
    //     }

    default:
      return state;
  }
}

function userInterface(state, action) {
  switch (action.type) {
    case UPDATE_PROGRESS:
      return {
        ...state,
        progress: action.progress
      };

    default:
      return state;
  }
}

function fieldEdit(state, action) {
  switch (action.type) {
    case SET_DATA:
      let lastChanged = new Date().getTime();
      return {
        ...state,

        // Any application change should reset application list as the data is changed and next application id
        // could be taken away by new application
        existingApplications: undefined,
        nextApplicationId: undefined,

        data: {
          // this is a kludge and needs to be reconsidered.
          // specific parts should be updated, using lastChanged to ensure data is changed
          ...(action.data !== undefined ? action.data : state.data),
          lastChanged
        },
        preventPersistForLastChanged: action.preventPersist ? lastChanged : undefined
      };

    default:
      return state;
  }
}

function interactWithDB(state, action) {
  switch (action.type) {
    case REQUEST_APPLICATION_DATA:
      return {
        ...state,
        isInAPICall: true
      };
    case RECEIVE_APPLICATION_DATA:
      return {
        ...state,
        isInAPICall: false,
        applicationData: {
          status: action.data.status,
          fields: action.data.field
        },
        applicationId: action.data.applicationId
      };

    case SAVED_APPLICATION:
      return {
        ...state,
        lastSavedApplication: action.when,
        autoSaveFailures: action.failures,
        autoSaveError: action.error
      };
    case SAVED_USER:
      return {
        ...state,
        lastSavedUser: action.when
      };
    default:
      return state;
  }
}

function rootReducer(state = initialState, action) {
  let intermediaryState = loginState(state, action);
  intermediaryState = userAccount(intermediaryState, action);
  intermediaryState = popups(intermediaryState, action);
  intermediaryState = startApplication(intermediaryState, action);
  intermediaryState = fieldEdit(intermediaryState, action);
  intermediaryState = userInterface(intermediaryState, action);

  return interactWithDB(intermediaryState, action);
}

export default rootReducer;
