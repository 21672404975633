import * as React from "react";
import Alert, {ALERTTYPE} from "./components/alert/Alert";
import ContactUs from "../util/ContactUs";

class NonFormSectionPart extends React.Component {
    readOnly = () => {
        return this.props.adapter.partReadonly(null, this.props.sectionId, this.props.partId);
    };

    renderReadOnlyAlert = () => {
        return (
            this.readOnly() &&
            <Alert type={ALERTTYPE.INFO}>
                <div>
                    {this.props.adapter.reachedStage()} If you need to change anything, <ContactUs/>.
                </div>
            </Alert>
        );
    };
}

export default NonFormSectionPart;