import React, {Fragment} from 'react';

import FieldLabel from "common-form/components/FieldLabel";
import FieldInput from "common-form/components/FieldInput";
import {LABEL_TYPE} from "common-form/components/Field";

import ReferralBase from "./ReferralBase";
import {REFERRALLOCALSTORAGEKEY} from "../constants";
import {Auth} from "aws-amplify";
import {cookiePolicyLink, privacyPolicyLink, termsOfUseLink, welcomeLink} from "../util/links";
import {FieldCheckbox} from "../form/components/checkbox";
import Alert, {ALERTTYPE} from "../form/components/alert/Alert";
import FormButton from "../form/components/FormButton";
import Loading from "../form/icons/Loading";
import {configureAuth} from "../amplify-configurator";
import * as Scroll from "react-scroll";

/**
 * Base implementation of registration functionality used for registration within app and for registration landing
 * pages on public web.
 */
class RegisterBase extends ReferralBase {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            authData: null,
            error: null,
            email: null,
            password: null,
            accept: false,
            tips: false,
            registered: false,
            confirmed: false,
            nickname: null,
            loading: false
        };
    }

    canRegister = () => {
        let result = this.state.email !== null
            && this.state.password !== null
            && this.state.accept
            && !this.state.loading;

        return result;
    };

    async onSignUp(event) {

        // Can be called as submit event on form
        if (event && event.preventDefault)
            event.preventDefault();

        if (!this.canRegister()) {
            return;
        }

        this.setState({
            loading: true
        });

        let consents = {
            accept: this.state.accept,
            tips: this.state.tips,
        };

        if (this.state.agent) {
            consents.referralCode = this.state.referralCode;
            localStorage.removeItem(REFERRALLOCALSTORAGEKEY);
        }

        try {
            this.setState({ error: null });
            configureAuth();
            const response = await Auth.signUp({
                username: this.state.email.toLowerCase(),
                password: this.state.password,
                attributes: {
                    email: this.state.email.toLowerCase(),
                    //nickname: this.state.nickname,
                    'custom:consents': JSON.stringify(consents),
                    'custom:secure': 'false',

                },
            });
            this.setState({
                user: response.user,

                registered: true,
                confirmed: false,
            });

            Scroll.scroller.scrollTo('register-block', {
                duration: 300,
                smooth: true,
                offset: -50
            });


        } catch (err) {
            // console.log(`SignUp::onSignUp(): Error ${JSON.stringify(err, null, 2)}`, err);

            let error = err.message;
            switch (error) {
                case 'Incorrect username or password.':
                    error = 'Existing password entered incorrectly. Please try again!';
                    break;
                case 'Attempt limit exceeded, please try after some time.':
                    error = 'You have entered the wrong password too many times! Please try again later';
                    break;
                case 'An account with the given email already exists.':
                    error = 'That email address is already registered!';
                    break;
                default:
                    error = 'Please ensure your password contains at least 8 characters including 1 uppercase and 1 lowercase letter, and 1 number.'
            }
            this.setState({ error });
        }

        this.setState({
            loading: false
        });
    }

    renderForm(pathGoHard) {
        let components = {
            email: {
                label: 'Email',
                placeholder: null,
                labelType: LABEL_TYPE.Small,
                value: this.state.email,
                field: 'email',
                handleFieldFilled: (a, f, email) => this.setState({ email }),
                type: 'email'
            },
            nickname: {
                label: 'Name',
                placeholder: null,
                labelType: LABEL_TYPE.Small,
                value: this.state.nickname,
                field: 'name',
                handleFieldFilled: (a, f, nickname) => this.setState({ nickname }),
                type: 'text'
            },
            password: {
                label: 'Password',
                placeholder: null,
                labelType: LABEL_TYPE.Small,
                value: this.state.password,
                field: 'password',
                handleFieldFilled: (a, f, password) => this.setState({ password }),
                type: 'password'
            },
            accept: {
                code: !this.state.accept,
                label: (
                    <React.Fragment>
                        By opening an account, you accept
                        our <a href={termsOfUseLink()} target='_blank' rel='noopener noreferrer' onClick={(e) => e.stopPropagation()}>Terms of Use</a>,
                        &nbsp;<a href={privacyPolicyLink()} target='_blank' rel='noopener noreferrer' onClick={(e) => e.stopPropagation()}>Privacy Policy</a> and
                        &nbsp;<a href={cookiePolicyLink()} target='_blank' rel='noopener noreferrer' onClick={(e) => e.stopPropagation()}>Cookie Notice</a>.
                    </React.Fragment>
                ),
                field: 'accept',
                onCheckboxClick: accept => { this.setState({ accept }); },
                checked: this.state.accept
            },
            tips: {
                code: !this.state.tips,
                label: 'Send me helpful mortgage tips and occasional promotions (you can always opt-out later).',
                field: 'tips',
                onCheckboxClick: tips => this.setState({ tips }),
                checked: this.state.tips
            },
            register: {
                text: 'Register',
                disabled: !this.canRegister(),
                submit: true
            }
        };

        return (

            <Fragment key="register-form">
                <h4 className='d-none d-sm-block block-title mb-4'>Get started below:</h4>
                <form onSubmit={(event) => this.onSignUp(event)}>

                    <FieldInput {...components.email} />
                    <FieldInput {...components.password} />

                    <div className='row'>
                        <div className='col-12'>
                            <FieldCheckbox {...components.accept} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <FieldCheckbox {...components.tips} />
                        </div>
                    </div>
                    {
                        !!this.state.error &&
                        <Alert type={ALERTTYPE.DANGER} text={this.state.error} />
                    }
                    <div className='simple-form-buttons'>
                        <FormButton {...components.register} />
                        {
                            this.state.loading &&
                            <Loading buttonLine />
                        }
                    </div>
                </form>
                <FormButton
                    link
                    text='Already have an account?'
                    path={welcomeLink()}
                    pathGoHard={pathGoHard}
                />
            </Fragment>
        )
    }

    renderVerification(pathGoHard) {
        return (
            <Fragment key="register-verification">
                <FieldLabel
                    label={`A verification email has been sent to ${this.state.email}`}
                    className='register-block'
                />

                <FieldLabel
                    label="Please double check that the email address above is correct then go to your email account and click on the link within that email to confirm your account."
                    labelType={LABEL_TYPE.Description}
                />

                <FieldLabel
                    label="If the verification email does not appear in a few minutes, please check your Junk Folder."
                    labelType={LABEL_TYPE.Error}
                />

                <FieldLabel
                    label="Once verified, please return to this page and then click the button below to proceed and log in."
                    labelType={LABEL_TYPE.Description}
                />

                <FormButton
                    text="I’m ready!"
                    path={welcomeLink()}
                    pathGoHard={pathGoHard}
                    label="Home"
                />
            </Fragment>
        );
    }
}

export default RegisterBase;