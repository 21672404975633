import * as React from "react";

import QuestionBlock from "../../../../form/QuestionBlock";
import {FieldLabel} from "common-form/components/FieldLabel";
import {LABEL_TYPE} from "common-form/components/Field";
import FieldCheckbox from "common-form/components/FieldCheckbox";
import FieldRadioGroup from "../../../../form/components/radio/FieldRadioGroup";
import FieldTextArea from "../../../../form/components/FieldTextArea";

import {
    FIELDS
} from "common-hypotheca";


export default function InterestOnly(parent, nextQuestionId) {

    return {
        block: (params) =>

            <QuestionBlock {...params}>

                <FieldLabel
                    label="Interest only mortgages may provide lower monthly payments, but don't involve actually paying off any of your borrowings"
                />

                <FieldLabel
                    label="At the end of the mortgage term you will still owe the lender the full amount that you originally borrowed. You will need to have a credible strategy in place for repaying this."
                    labelType={LABEL_TYPE.Description}
                />

                <FieldCheckbox
                    labelType={LABEL_TYPE.Small}
                    checked={!!parent.getValue(FIELDS.MM.MP.AcceptsInterestOnly)}
                    code={!parent.getValue(FIELDS.MM.MP.AcceptsInterestOnly)}
                    onCheckboxClick={val => {
                        parent.handleFilledField(null, FIELDS.MM.MP.AcceptsInterestOnly, val);
                    }}
                    {...parent.getField(FIELDS.MM.MP.AcceptsInterestOnly)}
                />

                <FieldRadioGroup
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MP.PrimaryProposedRepaymentStrategy)}
                />

                <FieldTextArea
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MM.MP.PrimaryRepaymentPlanDescription)}
                />

            </QuestionBlock>,


        validate: (context) => {

            context
            .mandatoryValue(FIELDS.MM.MP.AcceptsInterestOnly, true, 'Please confirm')
            .mandatory(FIELDS.MM.MP.PrimaryProposedRepaymentStrategy)
            .mandatory(FIELDS.MM.MP.PrimaryRepaymentPlanDescription)
            ;

        },

        nextQuestionId: () => nextQuestionId
    };

};