import React from 'react';

import FieldLabel from "common-form/components/FieldLabel";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FormButton from '../../../../form/components/FormButton';
import QuestionBlock from '../../../../form/QuestionBlock';
import MultiBlockFormSingleApplicant from "../../../../form/MultiBlockFormSingleApplicant";
import FormImage, {IMAGE_GRAB_A_COFFEE} from "../../../../form/components/FormImage";
import { LABEL_TYPE } from '../../../../form/components/Field';

import {
    FIELDS,
    SECTION,
    SITUATION
} from 'common-hypotheca';

import CurrentAddress from "./personal/CurrentAddress";
import PreviousAddresses from "./personal/PreviousAddresses";
import Names from './personal/Names';
import Meta from './personal/Meta';
import Dependants from './personal/Dependants';
import ErrorPart from "../../../ErrorPart";


const BLOCK_WELCOME = 1;
const BLOCK_NAMES = 2;
const BLOCK_META = 3;
const BLOCK_DEPENDENTS = 4;
const BLOCK_DEPENDENTS_YES = 5;
const BLOCK_CURRENT_ADDRESS = 6;
const BLOCK_PREVIOUS_ADDRESSES = 7;

class PersonalDetails extends MultiBlockFormSingleApplicant {
    
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            sectionId: SECTION.SITUATION,
            partId: SITUATION.PERSONAL_DETAILS,

            slideIds: [BLOCK_WELCOME],
            activeSlideId: BLOCK_WELCOME,

            occupantType: props.adapter.occupantType(props.applicantId)
        };
    }

    checkForCompleteState = data => {
        this.partContainer().isComplete = true;
        this.partContainer(null, SECTION.SITUATION, SITUATION.ASSETS).isAvailable = true;
        return data;
    };


    /**
     * @returns {number} of expected slides for this part.
     */
    slidesExpected = () => {
        return 6 +
            (this.state.slideIds.includes(BLOCK_DEPENDENTS_YES) ? 1 : 0);
    };

    getValue = (field) => {
        return this.getFieldValue(null, field);
    }

    getQuestion = which => {
        switch (which) {
            case BLOCK_WELCOME:
                return {
                    block:(params)=>
                        <QuestionBlock {...params} controlButtons={null}>
                            <FieldLabel
                                label={`Now ${this.getApplicantName()}, maybe you can grab a coffee and tell me a bit more about yourself?`}
                            />
                            <FieldLabel
                                label="In this section we will gather key information about your personal situation, including your address history."
                                labelType={LABEL_TYPE.Description}
                            />
                            <FormButton
                                text='Do it!'
                                onClick={() => this.swiperNext()}
                            />
                            <FormImage imageType={IMAGE_GRAB_A_COFFEE}/>
                        </QuestionBlock>,
                    canNext: () => true,
                    nextQuestionId: () => BLOCK_NAMES
                };

            case BLOCK_NAMES:
                return Names(this, BLOCK_META);
                
            case BLOCK_META:
                return Meta(this, BLOCK_DEPENDENTS);
                
            case BLOCK_DEPENDENTS:
                return {
                    block: (params)=>
                        <QuestionBlock {...params}>
                            <FieldRadioYesNo
                                {...this.getFormField(null, FIELDS.MS.PD.DoIHaveDependents)}
                            />
                        </QuestionBlock>,
                    validate: (context) => {
                        context.mandatory(FIELDS.MS.PD.DoIHaveDependents);

                        if (this.fieldHasValueFalse(null, FIELDS.MS.PD.DoIHaveDependents)) {
                            context.cleanupIrrelevantField(FIELDS.MS.PD.Dependents);
                        }
                    },
                    nextQuestionId: () => {
                        if (this.fieldHasValueFalse(null, FIELDS.MS.PD.DoIHaveDependents)) {
                            return BLOCK_CURRENT_ADDRESS;
                        } else {
                            return BLOCK_DEPENDENTS_YES;
                        }
                    }
                };

            case BLOCK_DEPENDENTS_YES:
                return Dependants(this, BLOCK_CURRENT_ADDRESS);

            case BLOCK_CURRENT_ADDRESS:
                return CurrentAddress(this, BLOCK_PREVIOUS_ADDRESSES);

            case BLOCK_PREVIOUS_ADDRESSES:
                return PreviousAddresses(this);

            default:
                return ErrorPart();
        }
    };
}

export default PersonalDetails;
