import React from 'react';
import PropTypes from "prop-types";
import Field from '../Field';
import FieldCheckbox from "common-form/components/FieldCheckbox";

class FieldCheckboxGroup extends Field {
    static defaultProps = {
        disabled: false
    };

    constructor() {
        super();
        this.checkboxClicked = this.checkboxClicked.bind(this);
        this.renderChildren = this.renderChildren.bind(this);
    }

    checkboxClicked(code) {
        let newValue = this.props.value;

        if (Array.isArray(newValue)) {
            if (newValue.includes(code)) {
                newValue = newValue.filter(function (existingCode) {
                    return existingCode !==  code;
                });
            } else {
                newValue.push(code);
            }
        } else {
            newValue = [code];
        }

        this.props.handleFieldFilled(null, this.props.field, newValue);
    }

    renderChildren() {
        if (this.props.children) {
            // render checkboxes specified as children
            return React.Children.map(this.props.children, child => {
                return React.cloneElement(child, {
                    disabled: this.props.disabled,
                    field: this.props.field,
                    onCheckboxClick: this.checkboxClicked,
                    checked: Array.isArray(this.props.value) && this.props.value.includes(child.props.code)
                })
            })
        } else {
            return this.props
                .options
                .filter(item => item.code !== '' && item.code !== undefined)
                .map(item =>{
                    // Show obsolete items only when displaying read-only drop-down
                    if (item.obsolete && !this.props.disabled) {
                        return '';
                    }

                    return <FieldCheckbox
                        disabled={this.props.disabled}
                        key={item.code}
                        code={item.code}
                        label={item.text}
                        field={this.props.field}
                        onCheckboxClick={this.checkboxClicked}
                        checked={Array.isArray(this.props.value) && this.props.value.includes(item.code)}
                    />;
                });
        }
    }

    render() {
        if (!this.show()) {
            return null;
        }

        return (
            <div className={"form-group checkbox-group " + this.validityClasses()}>
                <div className={"label label-checkbox-group " + this.getLabelClassNameFromType()}>{this.props.label}</div>

                {this.renderChildren()}
                {
                    this.renderError()
                }
            </div>
        );
    }
}

FieldCheckboxGroup.propTypes = {
    label: PropTypes.string.isRequired,
    labelType: PropTypes.string,
    field: PropTypes.string.isRequired,
    handleFieldFilled: PropTypes.func.isRequired,
    value: PropTypes.any,
    show: PropTypes.any,
    options: PropTypes.array,
    disabled: PropTypes.bool
};

export default FieldCheckboxGroup;
