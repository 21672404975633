import React from "react";
import { dashboardLink } from "../../util/links";
import {
    SECTION,
    SITUATION
} from "common-hypotheca";

class ApplicantsSelection extends React.Component {
    selectSectionApplicant = applicantId => {
        this.props.history.push(dashboardLink(this.props.sectionId, applicantId));
    };

    completionPercentage = (applicantId) => {
        if (this.props.adapter.fieldsContainer(applicantId, SECTION.SITUATION, SITUATION.OUTGOINGS).isComplete)
            return '100%';
        else if (this.props.adapter.fieldsContainer(applicantId, SECTION.SITUATION, SITUATION.CREDIT).isComplete)
            return '80%';
        else if (this.props.adapter.fieldsContainer(applicantId, SECTION.SITUATION, SITUATION.EMPLOYMENT).isComplete)
            return '60%';
        else if (this.props.adapter.fieldsContainer(applicantId, SECTION.SITUATION, SITUATION.ASSETS).isComplete)
            return '40%';
        else if (this.props.adapter.fieldsContainer(applicantId, SECTION.SITUATION, SITUATION.PERSONAL_DETAILS).isComplete)
            return '20%';
        else
            return '0%';
    };

    render = () => {
        return (
            <div className='row'>
                <div className='col-12'>
                    <ul className="nav nav-pills">
                        {
                            this.props.data.applicants.map((applicant) => {
                                return (
                                    <li key={applicant.applicantId} className="nav-item">
                                        <a className={"nav-link" + (this.props.applicantId === applicant.applicantId ? " active" : "")}
                                            href="javascript:void(0)"
                                            onClick={() => this.selectSectionApplicant(applicant.applicantId)}
                                        >{this.props.adapter.applicantName(applicant.applicantId)}<span>{this.completionPercentage(applicant.applicantId)}</span></a>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    };
}

export default ApplicantsSelection;