import React from 'react';
import {openIntercom} from "./links";

export default function ContactUs({link, label}) {
    return (
        <a href="https://www.holidaycottagemortgages.co.uk/contact-us/"
           target="_blank" rel='noopener noreferrer'
           onClick={() => openIntercom()}
           className={link ? 'text-link' : ''}
        >
            {label || 'contact us'}
        </a>
    );
}