import * as React from "react";

import {
    FIELDS, 
    EMPLOYEE_TYPE, 
    extractFieldName
} from "common-hypotheca";

import {
    validateEmploymentBasic
} from './validation';


import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldDate from "common-form/components/FieldDate";
import FieldInput from "common-form/components/FieldInput";
import FieldRadioGroup from "../../../../../form/components/radio/FieldRadioGroup";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldGroup from "../../../../../form/components/FieldGroup";
import TimeDiff from '../../../../../form/components/TimeDiff';
import AddressLookup from "../../../../../form/components/AddressLookup";
import { LABEL_TYPE } from "../../../../../form/components/Field";


export default function EmploymentBasic(parent) {

    return {
        block: (params) =>

            <QuestionBlock {...params}>

                <FieldGroup
                    ref={params.groupAddableRef}
                    item={parent.getCurrentEmployment()}
                    className="date-boxes"
                    {...parent.getField(FIELDS.MS.EI.Employments)}
                    validate={(context) => {

                        validateEmploymentBasic(parent, context, parent.state.employmentIndex);

                    }}
                >

                    <FieldInput
                        label="What is your job title?"
                        placeholder="e.g. Project Manager"
                        field={FIELDS.MS.EI.EmploymentName}
                    />

                    <FieldInput
                        placeholder="e.g. Helpful Hints Ltd"
                        field={FIELDS.MS.EI.EmployerName}
                    />


                    <AddressLookup
                        label="What is your employer's address?"
                        labelType={LABEL_TYPE.Large}
                        fields={[
                            FIELDS.MS.EI.EmployerAddress, 
                            FIELDS.MS.EI.EmployerAddress2,
                            FIELDS.MS.EI.EmployerAddressTown,
                            FIELDS.MS.EI.EmployerAddressCounty,
                            FIELDS.MS.EI.EmployerAddressPostcode
                        ]}
                    />

                    <FieldDate
                        label="What date did your employment start?"
                        field={FIELDS.MS.EI.EmploymentStartDate}
                    />

                    {
                        !parent.isFirstEmployment() &&
                        <FieldRadioYesNo
                            label="Is this a current job?"
                            field={FIELDS.MS.EI.EmploymentIsCurrent}
                        />
                    }

                    <TimeDiff
                        applicable={
                            parent.isFirstEmployment()
                            || parent.getEmploymentField(extractFieldName(FIELDS.MS.EI.EmploymentIsCurrent)).value === true
                        }
                        updateFactFindFunction={parent.updateFactFindBeenInThisJobFor}
                        fromValue={parent.getEmploymentField(extractFieldName(FIELDS.MS.EI.EmploymentStartDate)).value}
                        toValue={new Date()}
                        format="ym"
                        label="You have been in this job for {0}."
                    />

                    <FieldDate
                        label="When did this employment end?"
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.EmploymentIsCurrent)] === false}
                        field={FIELDS.MS.EI.EmploymentEndDate}
                    />

                    <FieldRadioGroup 
                        label="How would you describe your employment type?" 
                        field={FIELDS.MS.EI.ContractType}
                    />

                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.ContractType)] === EMPLOYEE_TYPE.TEMP}
                        field={FIELDS.MS.EI.TempMonthsLeft}
                    />

                    <FieldRadioGroup label="Is your job?" inline={true} field={FIELDS.MS.EI.FullorPartTime}/>

                    <FieldRadioYesNo
                        field={FIELDS.MS.EI.InProbationPeriod}
                    />
 
                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.InProbationPeriod)]}
                        field={FIELDS.MS.EI.ProbationMonthsLeft}
                    />

                </FieldGroup>
        
            </QuestionBlock>
        ,
        nextQuestionId: () => parent.afterEmploymentBasic()
    };

};
