import {_INITIAL_ASSESSMENT} from "./radio_initial_assessment";
import {_EMPLOYMENT_TYPE} from "./radio_employment_type";
import {_MORTGAGE_TYPE} from "./dropdown_mortgage_type";
import {_TRANSACTION_SPEED_TYPE} from "./dropdown_transaction_speed_type";
import {_FIELDS, _OPTIONS_TRUE_FALSE, _OPTIONS_YES_NO, _PERCENTAGE, _POUND_AMOUNT, _NUMBER} from "./fields";

_FIELDS.AS.HLC = {
    JointIncome: {
        name: 'JointIncome',
        label: 'The combined income of all applicants is at least £30,000 per annum',
        options: _OPTIONS_TRUE_FALSE
    },
    JointIncomeNotes: {
        name: 'JointIncomeNotes',
        frontendLabel: 'Please tell us more about this situation',
        adminLabel: 'Joint income notes'
    },

    IndividualIncome: {
        name: 'IndividualIncome',
        frontendLabel: 'Your income is at least £20,000 per annum',
        adminLabel: 'Applicant income is at least £20,000 per annum',
        options: _OPTIONS_TRUE_FALSE
    },
    IndividualIncomeNotes: {
        name: 'IndividualIncomeNotes',
        frontendLabel: 'Please tell us more about this situation',
        adminLabel: 'Individual income notes'
    },

    // OBSOLETE
    Over21: {
        name: 'Over21',
        label: 'All applicants are 21 years of age, or older',
        options: _OPTIONS_TRUE_FALSE
    },
    // OBSOLETE
    Over21Notes: {
        name: 'Over21Notes',
        frontendLabel: 'Please tell us more about this situation',
        adminLabel: 'Applicants age notes'
    },

    // OBSOLETE
    ApplicantsAreIndividuals: {
        name: 'ApplicantsAreIndividuals',
        label: 'The applicants to the holiday let mortgage will be individuals and not a limited company or partnership',
        options: _OPTIONS_TRUE_FALSE
    },

    // OBSOLETE
    ApplicantsAreIndividualsNotes: {
        name: 'ApplicantsAreIndividualsNotes',
        frontendLabel: 'Please tell us more about this situation',
        adminLabel: 'Applicants not individuals notes'
    },


    OwnMainResidence: {
        name: 'OwnMainResidence',
        frontendLabel: 'I, the lead applicant, own or used to own within the last 12 months, my own home',
        adminLabel: 'Lead applicant owns or used own within the last 12 months residential property',
        options: _OPTIONS_TRUE_FALSE
    },
    OwnMainResidenceNotes: {
        name: 'OwnMainResidenceNotes',
        frontendLabel: 'Please tell us more about this situation',
        adminLabel: 'Residence ownership notes'
    },

    NotUsedAsDwelling: {
        name: 'NotUsedAsDwelling',
        label: 'The holiday let to be mortgaged will not be lived in as a main residence by any persons',
        options: _OPTIONS_TRUE_FALSE
    },
    NotUsedAsDwellingNotes: {
        name: 'NotUsedAsDwellingNotes',
        frontendLabel: 'Please tell us more about this situation',
        adminLabel: 'Holiday let usage notes'
    },

    NotPreviouslyLivedIn: {
        name: 'NotPreviouslyLivedIn',
        label: 'The holiday let to be mortgaged has not previously been lived in, as a main residence, by any of the applicants or their family',
        options: _OPTIONS_TRUE_FALSE
    },

    NotPreviouslyLivedInNotes: {
        name: 'NotPreviouslyLivedInNotes',
        label: 'Please tell us more about this situation',
    },

    HolidayLetOver50K: {
        name: 'HolidayLetOver50K',
        frontendLabel: 'The holiday let I wish to mortgage is worth more than £50,000',
        adminLabel: 'The holiday let to mortgage is worth more than £50,000',
        options: _OPTIONS_TRUE_FALSE
    },
    HolidayLetOver50KNotes: {
        name: 'HolidayLetOver50KNotes',
        frontendLabel: 'Please tell us more about this situation',
        adminLabel: 'Holiday let worth notes'
    },

    MortgageIsInUK: {
        name: 'MortgageIsInUK',
        label: 'The holiday let is located in England, Wales, Scotland or Northern Ireland',
        options: _OPTIONS_TRUE_FALSE
    },
    MortgageIsInUKNotes: {
        name: 'MortgageIsInUKNotes',
        frontendLabel: 'Please tell us more about this situation',
        adminLabel: 'Holiday let not located in UK notes'
    },

    LetForBusinessOnly: {
        name: 'LetForBusinessOnly',
        label: 'The property to be mortgaged will be holiday let wholly or, predominantly, for business purposes',
        options: _OPTIONS_TRUE_FALSE
    },
    LetForBusinessOnlyNotes: {
        name: 'LetForBusinessOnlyNotes',
        frontendLabel: 'Please tell us more about this situation',
        adminLabel: 'Let for business purposes notes'
    },

    OccupancyRestnAgriTie: {
        name: 'OccupancyRestnAgriTie',
        label: 'The holiday home in question is not subject to any occupancy restrictions, limitations of use or agricultural ties',
        options: _OPTIONS_TRUE_FALSE
    },
    OccupancyRestnAgriTieNotes: {
        name: 'OccupancyRestnAgriTieNotes',
        frontendLabel: 'Please tell us more about this situation',
        adminLabel: 'Restrictions notes'
    },
    
    PropertyIsStandardConstruction: {
        name: 'PropertyIsStandardConstruction',
        label: 'The property to be mortgaged is of standard construction and is not a wooden lodge, caravan or temporary/moveable home',
        options: _OPTIONS_TRUE_FALSE
    },
    PropertyIsStandardConstructionNotes: {
        name: 'PropertyIsStandardConstructionNotes',
        frontendLabel: 'Please tell us more about this situation',
        adminLabel: 'Non standard construction notes'
    },
    HolidayLetIsResidentialType: {
        name: 'HolidayLetIsResidentialType',
        label: 'The holiday let is a residential type of home and is not part of a holiday park',
        options: _OPTIONS_TRUE_FALSE
    },
    HolidayLetIsResidentialTypeNotes: {
        name: 'HolidayLetIsResidentialTypeNotes',
        frontendLabel: 'The holiday let is a residential type of home and is not part of a holiday park',
        adminLabel: 'Non residential type notes'
    },


    InitialAssessment: {
        name: 'InitialAssessment',
        adminLabel: 'Initial mortgage viability assessment',
        options: _INITIAL_ASSESSMENT.OPTIONS,

        adminCalculatedFrom: ['InitialAssessment'],
        adminCalculation: (data) => {
            if (data['InitialAssessmentBypassed']) {
                return 'Bypassed without completing the form.';
            } else {
                return data['InitialAssessment'];
            }
        }
    },

    InitialAssessmentBypassed: {
        name: 'InitialAssessmentBypassed',
        adminHidden: true,
        options: _OPTIONS_TRUE_FALSE
    },

    InitialAssessmentValid: {
        name: 'InitialAssessmentValid',
        adminHidden: true,
        options: _OPTIONS_TRUE_FALSE
    },

    LivedInUK3Years: {
        name: 'LivedInUK3Years',
        frontendLabel: 'Have you lived in the UK continuously for the last 3 years?',
        adminLabel: 'Lived in UK continuously for the last 3 years',
        options: _OPTIONS_YES_NO
    },

    AreYouAnExpat: {
        name: 'AreYouAnExpat',
        label: 'Are you an ex-pat?',
        options: _OPTIONS_YES_NO
    },

    WhereCurrentlyLive: {
        name: 'WhereCurrentlyLive',
        label: 'Where do you currently live?',
    },

    HasAnyUkAddressOrUkBank: {
        name: 'HasAnyUkAddressOrUkBank',
        label: 'Do you own any property in the UK?',
        options: _OPTIONS_YES_NO
    },

    HasMortgageInUk: {
        name: 'HasMortgageInUk',
        label: 'Do you have a mortgage on the property?',
        options: _OPTIONS_YES_NO
    },

    KeyCriteriaEmploymentType: {
        name: 'KeyCriteriaEmploymentType',
        frontendLabel: 'Which employment option best describes your work current situation?',
        adminLabel: 'Employment type',
        options: _EMPLOYMENT_TYPE.DROPDOWN_OPTIONS
    },
    BasicSalary: {
        name: 'BasicSalary',
        frontendLabel: 'Basic annual salary',
        adminLabel: 'Current basic salary',
        designation: _POUND_AMOUNT
    },
    GuaranteedEmploymentBenefits: {
        name: 'GuaranteedEmploymentBenefits',
        label: 'Guaranteed annual allowance (e.g. car allowance)',
        designation: _POUND_AMOUNT
    },
    NotGuaranteedEmploymentBenefits: {
        name: 'NotGuaranteedEmploymentBenefits',
        label: 'Other regular, but not guaranteed benefits (e.g. a bonus), per annum',
        designation: _POUND_AMOUNT
    },
    TradingResultsYearsAvailable: {
        name: 'TradingResultsYearsAvailable',
        frontendLabel: 'How many years of trading results can you provide?',
        adminLabel: 'Number of years of trading results can be provided',
        options: [
            {code: '', text: ''},
            {code: 'none', text: 'None'},
            {code: '1'},
            {code: '2'},
            {code: '3'},
            {code: '4'},
            {code: '5+'},
        ]
    },
    TradingResultsLast2Years: {
        name: 'TradingResultsLast2Years',
        frontendLabel: 'Approximately, what was your average total income from the last two trading years?',
        adminLabel: 'Average total income from the last two trading years',
        tooltip: 'Add together the last 2 years total pay to you (such as salary and dividends etc.) then divide by 2',
        designation: _POUND_AMOUNT
    },
    OtherVerifiableRegularIncome: {
        name: 'OtherVerifiableRegularIncome',
        label: 'Other verifiable, regular income, per annum',
        designation: _POUND_AMOUNT
    },

    HasAdditionalProperties: {
        name: 'HasAdditionalProperties',
        frontendLabel: 'Aside from your main residence and the holiday let you are looking to mortgage now, do you own any additional properties?',
        adminLabel: 'Owns additional properties aside main residence and holiday let',
        options: _OPTIONS_YES_NO
    },

    IsApplicationViaLimitedCompany: {
        name: 'IsApplicationViaLimitedCompany',
        frontendLabel: 'Is this application via a Limited Company?',
        adminLabel: 'Application is via Limited Company',
        options: _OPTIONS_YES_NO
    },

    NumberOfBuyToLets: {
        name: 'NumberOfBuyToLets',
        label: 'Number of buy to lets',
        designation: _NUMBER
    },
    NumberOfHolidayLets: {
        name: 'NumberOfHolidayLets',
        label: 'Number of holiday lets',
        designation: _NUMBER
    },
    NumberOtherProperties: {
        name: 'NumberOtherProperties',
        label: 'Number of other properties',
        designation: _NUMBER
    },

    

    EstimatedMonthlyCreditCommitments: {
        name: 'EstimatedMonthlyCreditCommitments',
        frontendLabel: 'What is the monthly cost of all credit commitments, for all applicants?',
        adminLabel: 'Estimated total of all monthly credit commitments for all applicants',
        tooltip: 'For example, add together your existing residential mortgage payments, car finance payments, loan payments ...',
        designation: _POUND_AMOUNT
    },

    //--- LTV block start
    PropertyToMortgageEstimatedValue: {
        name: 'PropertyToMortgageEstimatedValue',
        frontendLabel: 'What is the estimated value of the property to be mortgaged?',
        adminLabel: 'Estimated value of the property to be mortgaged',
        designation: _POUND_AMOUNT
    },
    BorrowPercentRequested: {
        name: 'BorrowPercentRequested',
        label: 'How much do you want to borrow?',
        adminHidden: true,
        designation: _PERCENTAGE
    },
    BorrowAmountRequested: {
        name: 'BorrowAmountRequested',
        adminLabel: 'Looking to borrow',
        designation: _POUND_AMOUNT
    },
    InitialAssessmentLtv: {
        name: 'InitialAssessmentLtv',
        label: 'How much are you looking to borrow?',
        adminLabel: 'Computed LTV',
        designation: _PERCENTAGE
    },
    //--- LTV block end

    DepositPurchaseWillComeFrom: {
        name: 'DepositPurchaseWillComeFrom',
        frontendLabel: 'Please tell us where the deposit for the purchase will come from',
        adminLabel: 'Deposit for the purchase will come from'
    },
    WantsReplaceExistingMortgageLikeToLike: {
        name: 'WantsReplaceExistingMortgageLikeToLike',
        frontendLabel: 'Do you wish to replace your existing mortgage, like for like, without raising any additional capital?',
        adminLabel: 'Wants to replace existing mortgage without raising any additional capital',
        options: _OPTIONS_YES_NO
    },
    WantsReplaceExistingMortgageLikeToLikeExplanation: {
        name: 'WantsReplaceExistingMortgageLikeToLikeExplanation',
        label: 'In a few words, what would you say is the main reason for wanting to raise additional capital on your holiday let?',
    },
    ReasonsForNewMortgage: {
        name: 'ReasonsForNewMortgage',
        frontendLabel: 'In a few words, what would you say is the main reason for wanting a new mortgage on your holiday let?',
        adminLabel: 'Reason for wanting a new mortgage'
    },
    MortgageTypePreference: {
        name: 'MortgageTypePreference',
        frontendLabel: 'In terms of mortgage type, what is your preference?',
        adminLabel: 'Mortgage type preference',
        options: _MORTGAGE_TYPE.DROPDOWN_OPTIONS
    },
    HolidayLetMoreThanOneSelfContainedProperty: {
        name: 'HolidayLetMoreThanOneSelfContainedProperty',
        label: 'Does the holiday let comprise of more than one self-contained property?',
        tooltip: 'e.g. one title for a building split into two flats, or one title for a main residential home, plus converted outbuildings.',
        options: _OPTIONS_YES_NO
    },
    TradingAsHolidayLet: {
        name: 'TradingAsHolidayLet',
        label: 'Is the holiday home currently trading as a holiday let?',
        options: _OPTIONS_YES_NO
    },
    Exist12MGrossRentalIncome: {
        name: 'Exist12MGrossRentalIncome',
        frontendLabel: 'What was the last 12 months\' gross rental income?',
        adminLabel: 'Last 12 months gross rental income',
        designation: _POUND_AMOUNT
    },
    Next12MEstimatedGrossRentalIncome: {
        name: 'Next12MEstimatedGrossRentalIncome',
        frontendLabel: 'What is the estimated gross rental income for the next 12 months?',
        adminLabel: 'Estimated gross rental income for the next 12 months',
        designation: _POUND_AMOUNT
    },
    PropertyMortgageDetails: {
        name: 'PropertyMortgageDetails',
        frontendLabel: 'Please tell us about the holiday property to be mortgaged, including its address and, if available, a weblink to further details',
        adminLabel: 'Holiday property to be mortgaged details'
    },
    DesiredTransactionSpeed: {
        name: 'DesiredTransactionSpeed',
        label: 'Ideally, how quickly do you want complete this transaction?',
        options: _TRANSACTION_SPEED_TYPE.DROPDOWN_OPTIONS
    },

    IsThereDeadline: {
        name: 'IsThereDeadline',
        label: 'Is there an important deadline to meet, regarding this remortgage?',
        options: _OPTIONS_YES_NO
    },
    IsThereDeadlineExplanation: {
        name: 'IsThereDeadlineExplanation',
        adminLabel: 'Explanation of deadline',
        options: _TRANSACTION_SPEED_TYPE.DROPDOWN_OPTIONS
    },

    AssessmentHelpfulDetails: {
        name: 'AssessmentHelpfulDetails',
        frontendLabel: 'Please tell us anything else that you think would be helpful when assessing your situation',
        adminLabel: 'Assessment helpful details'
    },

};