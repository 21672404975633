export const _INITIAL_ASSESSMENT = {
    INITIAL_ASSESSMENT: 'initial_assessment',
    ALREADY_DONE: 'no_thanks'
};

_INITIAL_ASSESSMENT.OPTIONS=[
    {
        code: _INITIAL_ASSESSMENT.INITIAL_ASSESSMENT,
        text: 'Initial assessment',
        icon: 'assessment'
    },
    {
        code: _INITIAL_ASSESSMENT.ALREADY_DONE,
        text: 'Already done!',
        icon: 'skip_next'
    }
];