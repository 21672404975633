import RangeSlider from "./RangeSlider";
import * as React from "react";

export default class RangeSliderInterest extends React.Component {
    static MIN_VALUE = 0.5;
    static MAX_VALUE = 10;
    static DEFAULT = 3;

    static allowed(rate) {
        return rate >= RangeSliderInterest.MIN_VALUE && rate <= RangeSliderInterest.MAX_VALUE;
    }

    static text(borrowable, rate) {
        let monthly = Math.round(borrowable * rate / 100 / 12);

        return (
            <p className="padding-top">
            <React.Fragment>
                Assuming a {rate}% interest only mortgage, this would mean monthly interest payments
                of &#163;{monthly.toLocaleString('en')}
            </React.Fragment>
            </p>
        );
    }

    render() {
        return (
        <div className="range-slider">
            <RangeSlider
                id="rate"
                text="Interest rate:"
                min={RangeSliderInterest.MIN_VALUE}
                max={RangeSliderInterest.MAX_VALUE}
                step={0.05}
                handleRangeChange={this.props.handleRangeChange}
                value={this.props.value}
                symbol='%'
                decimals={true}
            />
        </div>
        );
    }
}