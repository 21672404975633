import * as React from "react";

import QuestionBlock from "../../../../form/QuestionBlock";
import FieldTextArea from "../../../../form/components/FieldTextArea";
import FieldInput from "common-form/components/FieldInput";
import {LABEL_TYPE} from "../../../../form/components/Field";


import {
    FIELDS, 
} from "common-hypotheca";


export default function MortgageSort(parent, nextBlockId) {
    
    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldTextArea
                    {...parent.getField(FIELDS.MM.MP.MortgageDescription)}
                />

                <FieldInput
                    {...parent.getField(FIELDS.MM.MP.MaxMonthlyBudget)}
                    labelType={LABEL_TYPE.Small}
                />

            </QuestionBlock>,


        validate: (context) => {
            context
            .mandatory(FIELDS.MM.MP.MortgageDescription)
            .mandatory(FIELDS.MM.MP.MaxMonthlyBudget)
            ;
        },

        nextQuestionId: () => nextBlockId
    };

    
};