import { Auth } from 'aws-amplify';
import React from 'react';
import FieldInput from 'common-form/components/FieldInput';
import FormButton from '../form/components/FormButton';
import Header from '../welcome/Header'
import Alert, { ALERTTYPE } from '../form/components/alert/Alert';
import {forgotCredentialsEnterCodeLink, welcomeLink} from "../util/links";
import HtmlUtil from "../util/HtmlUtil";
import {AUTH_ERRORS} from "../constants";
import Loading from "../form/icons/Loading";

const PROCESS = {
    ENTER_EMAIL: 1,
    ENTER_VERIF: 2,
    SUCCESS: 3,
    SUCCESS_RESEND_VERIFICATION: 4
};

class ForgotCredentials extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            process: PROCESS.ENTER_EMAIL,
            verificationCode: null,
            password: null,
            error: null,
            currentlySending: false
        };
        if (this.props.location.search) {
            let search = this.props.location.search;
            let params = new URLSearchParams(search);
            if (params.get('email') && params.get('code')) {
                this.state = {
                    ...this.state,
                    process: PROCESS.ENTER_VERIF,
                    email: params.get('email'),
                    verificationCode: params.get('code')
                }
            }

        } else if (this.props.match.path.includes('code')) {
            this.state = {
                ...this.state,
                process: PROCESS.ENTER_VERIF,
            }
        }
    }

    /**
     * Try Cognito operation with supplied email, if we get user not found try to lowercase email.
     * @param email
     * @param lambda Some Cognito operation.
     * @returns {Promise<void>}
     */
    async cascaded(email, lambda) {
        this.setState({ currentlySending: true });
        try {
            await lambda(email);
        } catch (err) {
            if (email !== email.toLowerCase() && err && err.code === AUTH_ERRORS.UserNotFoundException) {
                await lambda(email.toLowerCase());
            } else {
                throw err;
            }
        } finally {
            this.setState({ currentlySending: false });
        }
    }

    resendVerif = () => {
        this.setState({
            error: null
        });
        if (this.state.email) {
            this.cascaded(this.state.email, email => Auth.resendSignUp(email))
                .then(() => {
                    this.setState({
                        process: PROCESS.SUCCESS_RESEND_VERIFICATION,
                    });
                })
                .catch(() => {
                    this.setState({
                        error: "Error resending verification email. Please contact us."
                    });
                });
        }
    };

    onForgot = () => {
        this.setState({
            error: null
        });

        this.cascaded(this.state.email, email => Auth.forgotPassword(email))
            .then(() => {
                this.setState({
                    process: PROCESS.ENTER_VERIF,
                })
            })
            .catch(err => {
                let error;
                switch (err.code) {
                    case AUTH_ERRORS.UserNotFoundException:
                        error = 'That email address is not registered. Please check and try again!';
                        break;
                    case AUTH_ERRORS.InvalidParameterException:
                        this.resendVerif();
                        return;
                    default:
                        error = err.message;
                        break;
                }

                this.setState({
                    error
                })
            });
    };

    onVerif = () => {
        this.setState({
            error: null
        });
        let verificationCode = this.state.verificationCode;
        let password = this.state.password;
        this.cascaded(this.state.email, email => Auth.forgotPasswordSubmit(email, verificationCode, password))
            .then(data => {
                this.setState({
                    process: PROCESS.SUCCESS
                });
            })
            .catch(err => {
                let error;
                switch (err.code) {
                    case AUTH_ERRORS.UserNotFoundException:
                        error = 'That email address is not registered. Please check and try again!';
                        break;
//                    case AUTH_ERRORS.InvalidParameterException:
//                        error = 'Unable to reset password as this email address has not yet been verified!';
                        break;
                    case AUTH_ERRORS.ExpiredCodeException:
                    case AUTH_ERRORS.CodeMismatchException:
                        error = err.message;
                        break;
                    default:
                        error = 'Please ensure your password contains at least 8 characters including 1 uppercase and 1 lowercase letter, and 1 number.';
                }

                this.setState({
                    error
                });
            });
    };

    render() {
        let components = {
            email: {
                label: 'Account email',
                placeholder: null,
                value: this.state.email,
                field: 'email',
                handleFieldFilled: (a, f, email) => this.setState({ email }),
                type: 'email',
                //disabled: this.state.process === PROCESS.ENTER_VERIF,

            },
            submitemail: {
                text: 'Submit',
                onClick: () => this.onForgot(),
                // class: `btn btn-success ${this.state.process === PROCESS.ENTER_EMAIL ? '' : 'disabled'}`,
                class: `btn btn-success`,
                disabled: this.state.currentlySending
            },
            verificationCode: {
                label: 'Enter verification code',
                placeholder: null,
                value: this.state.verificationCode,
                field: 'verificationCode',
                handleFieldFilled: (a, f, verificationCode) => this.setState({ verificationCode }),
                type: 'text',
            },
            password: {
                label: 'Enter new password',
                placeholder: null,
                value: this.state.password,
                field: 'password',
                handleFieldFilled: (a, f, password) => this.setState({ password }),
                type: 'password',
            },
            submitverif: {
                text: 'Submit',
                onClick: () => this.onVerif(),
                class: `btn btn-success`,
                disabled: this.state.currentlySending
            },
            gotowelcome: {
                text: 'Login',
                path: welcomeLink(),
                class: `btn btn-success`,
            },
            entercode: {
                text: 'Enter an emailed verification code',
                onClick: () => {
                    this.setState({
                        process: PROCESS.ENTER_VERIF
                    });
                    this.props.history.push(forgotCredentialsEnterCodeLink());
                },
                link: true
            },
            resendVerif: {
                text: 'Resend verification link',
                onClick: () => this.resendVerif(),
            }
        };

        return (
            <React.Fragment>
                <div className="container">
                    <div className='row'>
                        <div className='col-12'>
                            <Header {...this.props} type={Header.TYPES.LOGIN_SUPPORT} />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-12 col-md-8 offset-md-2`}>
                            <div className="card register">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h1 className='oos-title'>Recover your account</h1>
                                        </div>
                                    </div>
                                    {
                                        this.state.process !== PROCESS.SUCCESS &&
                                        this.state.process !== PROCESS.SUCCESS_RESEND_VERIFICATION &&
                                        HtmlUtil.renderFullRows(
                                            <p>If you have forgot your account password or you need to re-send the verification email then enter your current email address below</p>,
                                            <FieldInput {...components.email}/>
                                        )
                                    }
                                    {
                                        this.state.process === PROCESS.ENTER_EMAIL &&
                                        HtmlUtil.renderFullRows(
                                            <div className='simple-form-buttons'>
                                                <FormButton {...components.submitemail} />
                                                {
                                                    this.state.currentlySending &&
                                                    <Loading buttonLine/>
                                                }
                                            </div>,
                                            <FormButton {...components.entercode} />
                                        )
                                    }
                                    {
                                        this.state.process === PROCESS.ENTER_VERIF &&
                                        HtmlUtil.renderFullRows(
                                            <FieldInput {...components.verificationCode}/>,
                                            <FieldInput {...components.password}/>,
                                            <div className='simple-form-buttons'>
                                                <FormButton {...components.submitverif} />
                                                {
                                                    this.state.currentlySending &&
                                                    <Loading buttonLine/>
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        this.state.process === PROCESS.SUCCESS &&
                                        HtmlUtil.renderFullRows(
                                            <div className='form-group'>
                                                Success! Please login
                                            </div>,
                                            <FormButton {...components.gotowelcome}/>
                                        )
                                    }
                                    {
                                        this.state.process === PROCESS.SUCCESS_RESEND_VERIFICATION &&
                                        HtmlUtil.renderFullRows(
                                            "New verification email was sent to you. Please use the link in email to complete email verification step."
                                        )
                                    }
                                    {
                                        !!this.state.error &&
                                        HtmlUtil.renderFullRows(
                                            <Alert text={this.state.error} type={ALERTTYPE.DANGER} />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ForgotCredentials;