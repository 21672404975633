import React from "react";
import PropTypes from "prop-types";

class CloseIcon extends React.Component {
    static SIZES = {
        LARGE: 'large'
    };

    render() {
        return (
            <div className={`material-icon material-icon-${this.props.size}`} onClick={this.props.onClick}>close</div>
        );
    }
}

CloseIcon.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired
};

export default CloseIcon;