import * as React from "react";

import QuestionBlock from "../../../../form/QuestionBlock";
import FieldInput from "common-form/components/FieldInput";
import FieldTextArea from "../../../../form/components/FieldTextArea";
import FieldDropdown from "../../../../form/components/dropdown/FieldDropdown";
import FieldDropdownItem from "../../../../form/components/dropdown/FieldDropdownItem";
import FieldLabel from "common-form/components/FieldLabel";
import { LABEL_TYPE } from "common-form/components/Field";
import { ALERTTYPE, Alert } from "../../../../form/components/alert/Alert";
import LoanToValue from "../../../../form/components/LoanToValue";

import { formatCurrency } from "../../../../util/currency";

import {
    FIELDS,
    FUNDS_USAGE
} from "common-hypotheca";


export default function Remortgage(parent, nextBlockId) {


    function isNewLessThanExisting() {
        return parseFloat(parent.getValue(FIELDS.MM.MD.BorrowAmount)) < parseFloat(parent.getValue(FIELDS.MM.MD.BorrowingLevel));
    }

    function isNewMoreThanExisting() {
        return parseFloat(parent.getValue(FIELDS.MM.MD.BorrowAmount)) > parseFloat(parent.getValue(FIELDS.MM.MD.BorrowingLevel));
    }

    function isEqual() {
        return parseFloat(parent.getValue(FIELDS.MM.MD.BorrowAmount)) === parseFloat(parent.getValue(FIELDS.MM.MD.BorrowingLevel));
    }

    function getDifference() {
        return parseFloat(parent.getValue(FIELDS.MM.MD.BorrowAmount)) - parseFloat(parent.getValue(FIELDS.MM.MD.BorrowingLevel));
    }

    function getDifferenceAbs() {
        return Math.abs(getDifference());
    }


    function borrowOptionChanged(i, field, option) {

        const baseValue = option == 100 ? parent.getValue(FIELDS.MM.MD.BorrowingLevel) : parent.getPropertyValue();
        const borrowAmount = baseValue && option && option !== 'custom'
            ? (baseValue * parseInt(option) / 100)
            : null;

        parent.handleFilledField(null, FIELDS.MM.MD.BorrowAmount, borrowAmount);
        parent.handleFilledField(null, FIELDS.MM.MD.BorrowPercent, option);
    }

    function borrowAmountChanged(i, field, value) {
        parent.handleFilledField(null, FIELDS.MM.MD.BorrowPercent, 'custom');
        parent.handleFilledField(null, FIELDS.MM.MD.BorrowAmount, value);
    }


    const borrowOptions = [60, 65, 70, 75, 80].map(o => {
        return {
            value: o, label: `${o}%`
        };
    });

    borrowOptions.push({ value: 'custom', label: 'Custom' });

    borrowOptions.unshift({ value: 100, label: 'Like for like remortgage' });
    borrowOptions.unshift({ value: '', label: '' });

    if (!parent.getValue(FIELDS.MM.MD.BorrowPercent) && parent.getValue(FIELDS.MM.MD.BorrowAmount)) {
        parent.updateValue(FIELDS.MM.MD.BorrowPercent, 'custom');
    }

    return {

        block: (params) =>

            <QuestionBlock {...params}>

                <FieldLabel
                    label="You wish to remortgage your existing holiday let with a new mortgage"
                />

                <FieldLabel
                    label="Please tell us a little bit more about your existing mortgage and your plans for the remortgage including how you will use the funds."
                    labelType={LABEL_TYPE.Description}
                />

                <Alert
                    text={`Current property value : ${formatCurrency(parent.getPropertyValue())}`}
                    type={ALERTTYPE.SUCCESS}
                />

                <FieldInput
                    {...parent.getField(FIELDS.MM.MD.MonthlyMortgagePayment)}
                    labelType={LABEL_TYPE.Small}
                />

                <FieldInput
                    {...parent.getField(FIELDS.MM.MD.BorrowingLevel)}
                    labelType={LABEL_TYPE.Small}
                />

                <LoanToValue
                    label="Current loan to value of {0}%"
                    loan={parent.getValue(FIELDS.MM.MD.BorrowingLevel)}
                    value={parent.getPropertyValue()}
                    useLabel={true}
                    current={true}
                />


                <FieldDropdown
                    {...parent.getField(FIELDS.MM.MD.BorrowPercent)}
                    labelType={LABEL_TYPE.Small}
                    handleFieldFilled={borrowOptionChanged}
                    valid={false} // Remove green valid field enhancements around LTV in order not to confuse users that this LTV is OK
                >
                    {
                        borrowOptions.map((option, i) =>
                            <FieldDropdownItem key={i} code={option.value} label={option.label} />
                        )
                    }
                </FieldDropdown>

                <FieldInput
                    {...parent.getField(FIELDS.MM.MD.BorrowAmount)}
                    show={() => parent.getValue(FIELDS.MM.MD.BorrowPercent)}
                    handleFieldFilled={borrowAmountChanged}
                    labelType={LABEL_TYPE.Small}
                />

                <LoanToValue
                    label="Proposed loan to value of {0}%"
                    loan={parent.getValue(FIELDS.MM.MD.BorrowAmount)}
                    value={parent.getPropertyValue()}
                    useLabel={true}
                />

                {
                    isNewLessThanExisting() &&
                    <>

                        <Alert
                            type={ALERTTYPE.WARNING}
                            text={`Additional capital of ${formatCurrency(getDifferenceAbs())} is required to complete the remortgage!`}
                        />

                        <FieldTextArea
                            {...parent.getField(FIELDS.MM.MD.FundingDifferencePlan)}
                            labelType={LABEL_TYPE.Small}
                        />

                    </>
                }

                {
                    isNewMoreThanExisting() &&
                    <>

                        <Alert
                            type={ALERTTYPE.INFO}
                            text={`Proposed additional capital raising of ${formatCurrency(getDifferenceAbs())}.`}
                        />

                        <FieldDropdown
                            {...parent.getField(FIELDS.MM.MD.AdditionalBorrowingPlan)}
                            labelType={LABEL_TYPE.Small}
                        />

                        <FieldInput
                            {...parent.getField(FIELDS.MM.MD.AdditionalBorrowingPlanOther)}
                            labelType={LABEL_TYPE.Small}
                            show={() => parent.getValue(FIELDS.MM.MD.AdditionalBorrowingPlan) === FUNDS_USAGE.OTHER}
                        />
                    </>
                }

                {
                    isEqual() &&
                    <Alert
                        type={ALERTTYPE.SUCCESS}
                        text="This is a like-for-like remortgage."
                    />
                }


            </QuestionBlock>,

        validate: (context) => {

            context
                .mandatory(FIELDS.MM.MD.MonthlyMortgagePayment)
                .mandatory(FIELDS.MM.MD.BorrowingLevel)
                .mandatory(FIELDS.MM.MD.BorrowAmount)
                .mandatory(FIELDS.MM.MD.BorrowPercent)
                .mandatoryOrCleanup(isNewLessThanExisting(), FIELDS.MM.MD.FundingDifferencePlan)
                .mandatoryOrCleanup(isNewMoreThanExisting(), FIELDS.MM.MD.AdditionalBorrowingPlan)
                .mandatoryOrCleanup(isNewMoreThanExisting()
                    && parent.fieldValue(FIELDS.MM.MD.AdditionalBorrowingPlan) === FUNDS_USAGE.OTHER,
                    FIELDS.MM.MD.AdditionalBorrowingPlanOther);

            parent.updateLoanToValue(
                FIELDS.MM.MD.BorrowingLevelLoanToValue,
                FIELDS.MM.MD.BorrowingLevel,
                parent.getPropertyValue());

            parent.updateLoanToValue(
                FIELDS.MM.MD.BorrowAmountLoanToValue,
                FIELDS.MM.MD.BorrowAmount,
                parent.getPropertyValue());

            const difference = getDifference();

            if (difference < 0) {
                parent.updateValue(FIELDS.MM.MD.CapitalDeficiency, Math.abs(difference));
                parent.updateValue(FIELDS.MM.MD.CapitalRaise, null);
            } else if (difference > 0) {
                parent.updateValue(FIELDS.MM.MD.CapitalRaise, difference);
                parent.updateValue(FIELDS.MM.MD.CapitalDeficiency, null);
            } else {
                parent.updateValue(FIELDS.MM.MD.CapitalRaise, null);
                parent.updateValue(FIELDS.MM.MD.CapitalDeficiency, null);
            }

        },

        nextQuestionId: () => nextBlockId
    };


};