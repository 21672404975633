import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormButton } from '../form/components';
import {
    faqLink,
    infoPageLink,
    informationChecklistLink,
    starterGuideLink,
    welcomeLink,
    accountLink
} from "../util/links";
import HomeIcon from "../form/icons/HomeIcon";
import SignIn from "../auth/SignIn";
import ContactUs from "../util/ContactUs";


class Header extends React.Component {
    static propTypes = {
        type: PropTypes.any
    };

    static TYPES = {
        LOGIN: {
            showBrand: true
        },
        LOGIN_SUPPORT: {
            showBrand: true,
            showLogin: true
        },
        MAIN: {
            showBrand: true,
            showHome: true,
            showUser: true,
            showHelp: true,
        },
        DASHBOARD: {
            showBrand: false,
            showBrandOnlyOnMobile: true,
            showHome: true,
            showUser: true,
            showHelp: true,
        },
        MY_ACCOUNT: {
            showBack: true
        }
    };

    onSignOutClick = async () => {
        await SignIn.handleLogout(this.props.history);
    };

    openExternalLink = (url) => {
        window.open(url, '_blank');
    };

    render() {
        let {
            showBrand,
            showBrandOnlyOnMobile,
            showLogin,
            showHome,
            showUser,
            showHelp,
            showBack
        } = this.props.type || Header.TYPES.MAIN;

        let showToggler = false; // Use showUser || showHelp to show toggler on welcome and dash

        return (
            <Fragment>
                <div className={
                        'navbar' +
                        (this.props.type === Header.TYPES.DASHBOARD ? ' dashboard-header' : '') +
                        (showToggler ? ' navbar-expand-md navbar-light' : ' navbar-expand navbar-light')
                    }
                >
                    {
                        (showBrandOnlyOnMobile || showBrand) &&
                        <div className={'navbar-brand' +
                            (showBrandOnlyOnMobile ? ' d-block d-md-none' : '')}
                        >
                            <a href={infoPageLink()}/>
                        </div>
                    }
                    {
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"/>
                        </button>
                    }
                    <div className={'header-links collapse navbar-collapse'} id="navbarSupportedContent">
                        <div className='navbar-nav ms-auto'>
                            {
                                showBack &&
                                <FormButton link text={`Back`} onClick={() => this.props.history.goBack()}/>
                            }
                            {
                                showLogin &&
                                <FormButton link text={`Login`} path={welcomeLink()}/>
                            }
                            {
                                showHome &&
                                <span className="icon-link nav-link"
                                      onClick={() => this.props.history.push(welcomeLink())}><HomeIcon/></span>
                            }
                            {
                                showUser &&
                                <div className="dropdown nav-item">
                                    <FormButton dropDownMenu link navLink text={this.props.user && this.props.user.nickname ? this.props.user.nickname : 'User'}/>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <div><FormButton dropDownItem link text='My Account' path={accountLink()}/></div>
                                        <div><FormButton dropDownItem link text='Logout' onClick={() => this.onSignOutClick()}/></div>
                                    </div>
                                </div>
                            }
                            {
                                showHelp &&
                                <div className="dropdown nav-item">
                                    <FormButton dropDownMenu link navLink text='Help'/>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <div><FormButton dropDownItem
                                                         link
                                                         text='Information Checklist'
                                                         onClick={()=>this.openExternalLink(informationChecklistLink())}
                                        /></div>
                                        <div><FormButton dropDownItem
                                                         link
                                                         text='Starter guide'
                                                         onClick={()=>this.openExternalLink(starterGuideLink())}
                                        /></div>
                                        <div><FormButton dropDownItem
                                                         link
                                                         text='FAQ'
                                                         onClick={()=>this.openExternalLink(faqLink())}
                                        /></div>
                                        <div><ContactUs link label='Contact us'/></div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Header;