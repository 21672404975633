export const _INTEREST_RATE_TYPE = {
    STANDARD: 'standard',
    DISCOUNTED: 'discounted',
    CAPPED: 'caped',
    FIXED: 'fixed',
};

_INTEREST_RATE_TYPE.OPTIONS = [
    {
        code: '',
        text: '',
    },
    {
        code: _INTEREST_RATE_TYPE.STANDARD,
        text: 'Standard variable'
    },
    {
        code: _INTEREST_RATE_TYPE.DISCOUNTED,
        text: 'Discounted variable'
    },
    {
        code: _INTEREST_RATE_TYPE.CAPPED,
        text: 'Capped'
    },
    {
        code: _INTEREST_RATE_TYPE.FIXED,
        text: 'Fixed'
    }
];
