import ValidationContext from "common-form/framework/ValidationContext";

/**
 * Validates single field on a screen (e.g. slide or group addable)
 * @param field
 * @param screen
 * @param validationFunction
 */
export function validateSingleField(field, screen, validationFunction, partsContainerFinder, partParams) {
    if (validationFunction === null || validationFunction === undefined)
        return true;

    let validationContext;
    if (screen.state.validationContext === undefined) {
        validationContext = new ValidationContext(partsContainerFinder);
        screen.setState({
            validationContext
        });
    } else {
        validationContext = screen.state.validationContext;
    }

    validationContext.startOneField(field, partParams);
    validationFunction(validationContext);
    validationContext.finishOneField();
    return !validationContext.hasErrors();
}

export function validateAllFields(screen, validationFunction, partsContainerFinder, fieldGroupAddableRef) {
    let result = true;

    // Call validation function to determine if screen is valid.
    if (validationFunction) {
        let validationContext = new ValidationContext(partsContainerFinder);
        validationFunction(validationContext);
        validationContext.finishAll();

        if (screen) {
            screen.setState({
                validationContext
            });
        }

        result = !validationContext.hasErrors();
    }

    // Check if field group (addable) is valid on the screen.
    if (fieldGroupAddableRef && fieldGroupAddableRef.current) {
        result = result && fieldGroupAddableRef.current.valid();
    }

    return result;
}

export function refreshValidity(screen, validationFunction, partsContainer) {
    if (validationFunction === null || validationFunction === undefined)
        return;

    let validationContext = new ValidationContext(partsContainer);
    validationFunction(validationContext);
    validationContext.finishAllValid();
    screen.setState({
        validationContext
    });
}