import React,{Fragment} from "react";
import Tick from '../../form/icons/Tick'
import FormButton from "../../form/components/FormButton";
import { ChevronRight } from "../../form/icons";
import ApplicantsSelection from "./ApplicantsSelection";
import {
    model
} from 'common-hypotheca';
import {sectionPartLink} from "../../util/links";

class SectionParts extends React.Component {
    selectPart = (partId) => {
        this.props.history.push(sectionPartLink(this.props.sectionId, partId, this.props.applicantId));
    };

    render = () => {
        let data = this.props.data;
        if (!data) {
            return null;
        }
     //   console.log('Data: ', data, this.props);

        let partsData =
            (this.props.applicantId === null || this.props.applicantId === undefined)
                ? data[this.props.sectionId]
                : data.applicants.filter(applicant => applicant.applicantId === this.props.applicantId)[0][this.props.sectionId];

      //  console.log('Parts data: ', partsData);

        let userParts = partsData && partsData.parts && partsData.parts.length ? partsData.parts : [];
        //get the section from the model
        let modelSection = model[this.props.sectionId];
        //console.log('section 1', modelSection.parts, userParts)
        let parts = [];
        // if it has parts ( eg the applicatino status does not have parts)
        if (modelSection.hasParts) {
            //merge the user's parts data from db with the parts model based on the part id.
            parts = modelSection.parts.map(part => ({ ...userParts.find(userPart => userPart.id === part.id), ...part }))
        }

       // console.log('section 2', parts, modelSection)

        return (
            <Fragment>
                {
                    modelSection.hasApplicants &&
                    data.applicants.length > 1 &&
                    <ApplicantsSelection {...this.props} />
                }
                {
                    modelSection.hasParts &&
                    <div className='row'>
                        <div className='col-12'>

                            <table className="table">
                                <tbody>
                                    {
                                        parts.map((part, i) => {
                                            if (!this.props.adapter.partShown(
                                                    this.props.applicantId,
                                                    this.props.sectionId,
                                                    part.id)) {
                                                return null;
                                            }

                                            let partAvailable = this.props.adapter.partAvailable(
                                                this.props.applicantId,
                                                this.props.sectionId,
                                                part.id);

                                            let partComplete = this.props.adapter.partComplete(
                                                this.props.applicantId,
                                                this.props.sectionId,
                                                part.id);

                                            let clazz =
                                                (partAvailable ? ' available' : '') +
                                                (partComplete ? ' complete' : '');

                                            return <tr key={i}>
                                                <td className="tablecell_1of3">
                                                    <span className={clazz}>
                                                        {partComplete ? <Tick/> : <ChevronRight/>}
                                                    </span>
                                                </td>
                                                <td className="tablecell_2of3">
                                                    <span className={'section-name ' + clazz}>{part.name}</span>
                                                </td>
                                                <td className="tablecell_3of3">
                                                    {
                                                        partAvailable &&
                                                        <span className={clazz}>
                                                            <FormButton
                                                                secondary={partComplete}
                                                                text={partComplete ? 'Done' : 'Go'}
                                                                onClick={() => this.selectPart(part.id)}
                                                                icon={<ChevronRight/>}/>
                                                        </span>
                                                    }
                                                </td>
                                            </tr>
                                        }).filter(d => d != null)
                                    }
                                </tbody>

                            </table>

                        </div>

                    </div>
                }
            </Fragment>

        )
    };
}

export default SectionParts;