import React from 'react';

import FieldInput from "common-form/components/FieldInput";
import FieldLabel from "common-form/components/FieldLabel";
import { LABEL_TYPE } from "common-form/components/Field";

import QuestionBlock from "../../../../../form/QuestionBlock";
import {FieldDropdown} from "../../../../../form/components/dropdown";
import FieldGroupAddable from "../../../../../form/components/FieldGroupAddable";
import CopyApplicantsData from "../../../../../form/components/CopyApplicantsData";

import {
    extractFieldName,
    FIELDS,
    DEPENDANT_TYPE, extractValueText
} from "common-hypotheca";


export default function Dependants(parent, nextBlockId) {

    return {
        block: (params)=>

            <QuestionBlock {...params}>

                <FieldLabel
                    label='Please tell us a bit more about each dependant'
                />

                <FieldLabel
                    label='Use the add dependant button below to add each person and complete the details.'
                    labelType={LABEL_TYPE.Description}
                />

                <CopyApplicantsData 
                    label="Copy dependants from:"
                    sectionId={parent.state.sectionId}
                    partId={parent.state.partId}
                    currentApplicantId={parent.state.currentApplicantId}
                    adapter={parent.props.adapter}
                    field={parent.fieldComponentProperties(FIELDS.MS.PD.Dependents)}
                />
                
                <hr />


                <FieldGroupAddable
                    ref={params.groupAddableRef}
                    addButtonLabel="Add dependant"
                    allowDeleteAll
                    deleteButtonLabel="Remove"
                    editButtonLabel="Edit"
                    saveButtonLabel="Save dependant"
                    itemDescriber={(fieldsContainer) => {
                        let currentAge = fieldsContainer[extractFieldName(FIELDS.MS.PD.DependCurrentAge)];
                        let relationship = fieldsContainer[extractFieldName(FIELDS.MS.PD.DependRelationtome)];
                        let relationshipOther = fieldsContainer[extractFieldName(FIELDS.MS.PD.DependRelationtomeOther)];

                        let result;

                        if (relationship === DEPENDANT_TYPE.OTHER) {
                            result = relationshipOther
                        } else {
                            result = extractValueText(FIELDS.MS.PD.DependRelationtome, relationship);
                        }

                        if (result === undefined || result === '') {
                            result = 'Unknown'
                        }

                        if (currentAge !== undefined && currentAge !== '') {
                            result += ', ' + currentAge;
                        }

                        return result;
                    }}
                    validate={(context) => {

                        context
                            .cleanupIrrelevantField(FIELDS.MS.PD.DependFirstName)
                            .cleanupIrrelevantField(FIELDS.MS.PD.DependDOB)
                            .mandatory(FIELDS.MS.PD.DependCurrentAge)
                            .mandatory(FIELDS.MS.PD.DependRelationtome)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.PD.DependRelationtome) === DEPENDANT_TYPE.OTHER,
                                FIELDS.MS.PD.DependRelationtomeOther);
                    }}
                    {...parent.fieldComponentProperties(FIELDS.MS.PD.Dependents)}
                >

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.PD.DependCurrentAge}
                        type='number'
                    />

                    <FieldDropdown
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.PD.DependRelationtome}
                    >
                    </FieldDropdown>

                    <FieldInput
                        placeholder="Please describe relationship"
                        labelType={LABEL_TYPE.Small}
                        show={(container) => container[FIELDS.MS.PD.DependRelationtome.name] === DEPENDANT_TYPE.OTHER}
                        field={FIELDS.MS.PD.DependRelationtomeOther}
                    />

                </FieldGroupAddable>

            </QuestionBlock>,

        validate: (context) => {

            if (!context.hasItems(FIELDS.MS.PD.Dependents)) {
                context.addError(null, null, 'Please enter an item or go back to the previous screen and change your selection.');
            }

            if (parent.groupAddableRef && !parent.groupAddableRef.current.valid()) {
                context.addError(FIELDS.MS.PD.Dependents, null, '');
            }
        },
        
        nextQuestionId: () => nextBlockId
    };
};
