import React from 'react';
import PropTypes from "prop-types";

import Field from './Field';
import CurrencyInput from 'react-currency-input-field';

import {PERCENTAGE, POUND_AMOUNT, NUMBER, POUND_AMOUNT_WITH_DECIMALS} from "common-hypotheca";
import {GlobalRandom} from "../util/randomString";

export class FieldInput extends Field {
    static defaultProps = {
        value: null,
        type: null,
        show:true,
        native:{},
        maxCharacters:null,
        autoFill:null,
        description:null,
        disabled: false
    };

    keyPressed = (event) => {
        if (this.props.onEnterPress && event.key === "Enter") {
            this.props.onEnterPress();
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            focus: false
        }
    }

    onFocus = () => {
        this.setState({
            focus: true
        });
    };

    onBlur = () => {
        this.setState({
            focus: false
        })
    };

    onMouseWheel = (evt) => {
        if (this.computedType() === 'number' && this.state.focus) {
            evt.target.blur();
        }
    };

    computedPrepend = () => {
        return this.props.prepend
            || (this.props.designation === POUND_AMOUNT ? '£' : undefined)
            || (this.props.designation === POUND_AMOUNT_WITH_DECIMALS ? '£' : undefined)
            || (this.props.designation === PERCENTAGE ? '%' : undefined);
    };

    computedType = () => {
        return this.props.type
            || ([POUND_AMOUNT, POUND_AMOUNT_WITH_DECIMALS, PERCENTAGE, NUMBER].includes(this.props.designation) ? 'number' : undefined)
            || 'text';
    };


    handleCurrencyChange = (value) => {
        // We only need to handle value change event in currency exchange 3
        if (value && value.nativeEvent) {
            return;
        }

        this.handleChange({
            target: {
                value: value
            }
        });
    };

    handleCurrencyBlur = (evt) => {

        const valueStr = evt.target.value;
        let value = '';

        if (valueStr) {
            value = this.currencyValue(evt);
        }

        this.handleChange({
            target: {
                value: value
            }
        });
    };

    currencyValue = (event) => {
        if (this.props.designation === POUND_AMOUNT) {
            return Math.round(parseFloat(event.target.value.replaceAll(',', '')))
        } else {
            return event.target.value.replaceAll(',', '');
        }
    };

    /**
     * Disable irrelevant character input in CurrencyInput. Since it uses peculiar connected-unconnected
     * management mechanism (e.g. can suddenly go unconnected if passed value converts to false).
     *
     * @param event
     */
    handleCurrencyKeyPress = (event) => {
        let key = String.fromCharCode(event.which || event.charCode);

        // Strip any non-relevant character
        if(/[0-9\.km]/i.test(key) === false) {
            event.preventDefault();
        }

        // Allow k or m shortcuts only if letter is entered in the end ot the input and there is currently non-0 value.
        if (/[km]/i.test(key)
            && (! event.target.value
                || Math.round(parseFloat(event.target.value.replaceAll(',', ''))) === 0
                || event.target.selectionStart !== event.target.value.length
                || event.target.selectionEnd !== event.target.value.length)) {

            event.preventDefault();
        }

        if (!event.target.value) {
            return;
        }

        // Check it isn't too long
        if (key === 'k'
            && Math.round(parseFloat(event.target.value.replaceAll(',', ''))) > 999999) {

            event.preventDefault();
        }

        if (key === 'm'
            && Math.round(parseFloat(event.target.value.replaceAll(',', ''))) > 999) {

            event.preventDefault();
        }

        if (Math.round(parseFloat(event.target.value.replaceAll(',', ''))) > 999999999) {
            event.preventDefault();
        }
    };



    renderInput(optionalProps) {

        if (this.props.designation === POUND_AMOUNT || this.props.designation === POUND_AMOUNT_WITH_DECIMALS) {
            return <CurrencyInput
                field={this.props.field}
                className={"form-control" + this.validityClasses()}
                value={this.props.value || ''}
                disabled={this.props.disabled}
                allowDecimals={true}
                decimalsLimit={0}
                // Event change from currency exchange 2 to currency exchange 3
                onValueChange={this.handleCurrencyChange}
                onBlur={this.handleCurrencyBlur.bind(this)}
                onKeyPress={this.handleCurrencyKeyPress}
                autoFocus={this.props.autoFocus}
            />;
        }

        return <input
            disabled={this.props.disabled}
            type={this.computedType()}
            className={"form-control" + this.validityClasses()}
            placeholder={this.props.placeholder}
            value={this.props.value || ''}
            onChange={this.handleChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onWheel={this.onMouseWheel}
            onKeyPress={this.keyPressed}
            autoFocus={this.props.autoFocus}
            {...this.props.native}
            {...optionalProps}
        />

    }

    render() {
        if (!this.show())
            return null;

        let label = this.label();
        let optionalProps = {};

        if (this.props.maxLength !== null) {
            optionalProps.maxLength = this.props.maxCharacters
        }
        if (this.props.autoFill) {
            optionalProps.id = this.props.field;
        } else {
            optionalProps.autoComplete = this.props.field + GlobalRandom.POSTFIX;
        }

        const containerClassName = `form-group mb-4 ${this.props.className || ""}`;

        return (
            <React.Fragment>

                <div className={containerClassName}>
                    {
                        label &&
                        <label htmlFor={this.props.field} className={this.getLabelClassNameFromType()}>{label}</label>
                    }
                    {
                        this.props.description &&
                        <legend className='col-form-description'>{this.props.description}</legend>
                    }
                    {
                        !this.computedPrepend() &&
                        this.renderInput(optionalProps)
                    }
                    {
                        this.computedPrepend() &&
                        <div className={"input-group mb-3 with-prepend" + this.validityClasses()}>
                            <div className="input-group-prepend">
                                <span className={"input-group-text" + (this.state.focus ? ' focused' : '')}>{this.computedPrepend()}</span>
                            </div>
                            {
                                this.renderInput(optionalProps)
                            }
                        </div>
                    }
                    {
                        this.renderError()
                    }
                </div>
            </React.Fragment>
        );
    }
}

FieldInput.propTypes = {
    label: PropTypes.any,
    labelType: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    field: PropTypes.any,
    handleFieldFilled: PropTypes.func,
    onValueChange: PropTypes.func,
    show: PropTypes.any,
    type: PropTypes.string,
    error: PropTypes.string,
    valid: PropTypes.bool,
    native:PropTypes.object,
    prepend: PropTypes.string,
    maxCharacters:PropTypes.number,
    autoFill: PropTypes.bool,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    designation: PropTypes.string,
    allowNegative: PropTypes.bool,
    className: PropTypes.string,

    autoFocus: PropTypes.bool,
    // Not supported for currency amounts
    onEnterPress: PropTypes.func
};

export default FieldInput;
