import React from 'react';
import { API } from "aws-amplify";
import {APINAME, APIPATH } from "../constants";
import {getBlankApplication, migrateApplication} from "../util/models";
import {
    SECTION
} from 'common-hypotheca';
import {dashboardLink} from "../util/links";
import {apiExtra} from "../util/wrap";

/**
 * Has support methods to start an application.
 * N.B. Would prefer to have enough support in redux part to eliminate the need for this in the long term.
 */
class ApplicationStarter extends React.Component {

    async componentDidMount() {
        // if no existing applicaitons, GET them.

        if (this.props.existingApplications) {
            return;
        }

        await this.reloadApplications();
    }

    async reloadApplications() {
        let response = await API.get(APINAME, APIPATH.APPLICATION, apiExtra());
        let applications = response.liveApplications || [];
        let nextApplicationId = response.nextApplicationId;

        if (nextApplicationId === undefined) {
            nextApplicationId = 0;
        }

        this.props.setExistingApplications(applications, nextApplicationId);

        applications.forEach(element => {
            migrateApplication(element);
        });
    }

    startApplication = async () => {
        // If you are looking here you are wondering why there is now a pause.
        // The backend is doing a few things on the new application command.

        let applicationId = this.props.nextApplicationId;
        let data = getBlankApplication({
            id: applicationId,
        });
        this.props.startNewApplication(applicationId, data);
        this.props.history.push(dashboardLink(SECTION.SCREENING));
        this.props.setExistingApplications(undefined, undefined);

        try {
            await API.post(APINAME, APIPATH.CREATEAPPLICATION, {
                body: data
            })
        } catch (e) {
            console.log(e)
        }
    };
}

export default ApplicationStarter;