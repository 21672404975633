import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {describePart, describeSection} from "../util/describe";
import {dashboardLink, welcomeLink} from "../util/links";
import helpIcon from "../images/svg/home.svg";


class Breadcrumbs extends Component {

    static propTypes = {
        applicationId: PropTypes.number,
        sectionId: PropTypes.string
    };

    render() {
        // if (!this.props.loggedIn)
        //     return null;

        let urlParams = this.props.match.params;
        let history = this.props.history;

        const Link = ( props ) => {
            let link;
            if (props.nextAvailable)
                link = (
                    <li className={`breadcrumb-item breadcrumb-${props.type}`}>
                        <a href="#" onClick={() => history.push(props.path)}>{props.name}</a>
                    </li>
                );
            else
                link = (
                    <li className={`breadcrumb-item breadcrumb-${props.type} active`} aria-current="page">{props.name}</li>
                );

            return link;
        };

        Link.propTypes = {
            nextAvailable: PropTypes.bool.isRequired,
            type: PropTypes.string.isRequired,
            name: PropTypes.any.isRequired,
            path: PropTypes.string
        };

        let partAvailable = urlParams.partId !== undefined;
        let sectionAvailable = urlParams.sectionId !== undefined;

        let result = [];

        let homeIconImage = (
            <img
                src={helpIcon}
                width={22}
                height={20}
            />
        );

        result.push(
            <Link
                key='home'
                nextAvailable={true}
                name={homeIconImage}
                path={welcomeLink()}
                type='home'
            />
        );

        if (sectionAvailable) {
            let described = describeSection(urlParams.sectionId, this.props.data, parseInt(urlParams.applicantId));

            let name;
            let type;
            if ( (typeof described === "object") && (described !== null) ){
                name = <Fragment>
                    <span className="breadcrumb-section-applicant">{described.applicantName}</span>
                    <span className="breadcrumb-section-name">{described.sectionName}</span>
                </Fragment>;
                type = 'section-with-applicant';
            } else {
                name = <span className="breadcrumb-section-name">{described}</span>;
                type = 'section';
            }

            result.push(
                <Link
                    key='section'
                    nextAvailable={partAvailable}
                    name={name}
                    path={dashboardLink(urlParams.sectionId, urlParams.applicantId)}
                    type={type}
                />
            );
        }

        if (partAvailable) {
            result.push(
                <Link
                    key='part'
                    nextAvailable={false}
                    name={describePart(urlParams.sectionId, urlParams.partId)}
                    type='part'
                />
            );
        }

        return <ol className="breadcrumb">{result}</ol>;
    }
}


export default Breadcrumbs;