import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldRadioPicture from "../../../../../form/components/radio/FieldRadioPicture";

import {
    FIELDS,
} from "common-hypotheca";

export default function AnotherEmployment(parent) {

    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldRadioPicture
                    label={`Please select job type`}
                    {...parent.getField(FIELDS.MS.EI.AnotherEmploymentType)}
                />

            </QuestionBlock>,


        validate: (context) => {

            const anotherType = parent.getValue(FIELDS.MS.EI.AnotherEmploymentType);
            if (!anotherType) {
                context.addError(FIELDS.MS.EI.AnotherEmploymentType, '', 'Required');
            }
            
        },

        nextQuestionId: () => parent.afterAnotherEmployment()
    };

    
};