import { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import React from "react";
import HelpIcon from "common-form/components/HelpIcon";

export const LABEL_TYPE = {
    Large: 'large',
    Small: 'small',
    Description: 'description',
    Error: 'error'
};

class Field extends Component {

    static defaultProps = {
        show: true,
    };

    getLabelClassNameFromType = () => {

        let clsName = this.props.tooltip != null ? 'with-tooltip ' : '';
        switch (this.props.labelType) {

            case LABEL_TYPE.Small:
                clsName += 'label-small';
                break;

            case LABEL_TYPE.Description:
                clsName += 'label-description';
                break;

            case LABEL_TYPE.Error:
                clsName += 'label-error';
                break;

            case LABEL_TYPE.Large:
            default:
                break;
        }

        return clsName;
    };


    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.handleFieldFilled(this.props.applicantId, this.props.field, event.target.value);
    }

    show = () => {
        if (this.props.show === undefined)
            return true;

        if (typeof this.props.show === 'boolean' && !this.props.show) {
            return false;
        }

        if (typeof this.props.show === 'function'
            && !this.props.show(this.props.fieldsContainer)) {
            return false;
        }

        return true;
    };

    label = () => {
        let result;

        if (this.props.label === undefined)
            return undefined;

        if (typeof this.props.label === 'string')
            result = this.props.label;

        if (typeof this.props.label === 'function')
            result = this.props.label(this.props.fieldsContainer);

        if (result !== undefined && this.props.tooltip != null)
            return (
                <Fragment>
                    <span>
                        {result}
                    </span>
                    <div className='help-icon-container'>
                        <HelpIcon place={this.props.tooltipPlace}>{this.props.tooltip}</HelpIcon>
                    </div>
                </Fragment>
            );

        return result;
    };

    validityClasses = () => {
        return (this.props.error ? " is-invalid" : "") + (this.props.valid ? " is-valid" : "");
    };

    renderError = () => {
        return this.props.error &&
            <div key='error-message' className="invalid-feedback">{this.props.error}</div>;
    };
}

Field.propTypes = {
    field: PropTypes.string.isRequired,
    fieldsContainer: PropTypes.object,
    handleFieldFilled: PropTypes.func.isRequired,
    applicantId: PropTypes.number,
    show: PropTypes.any,
    type: PropTypes.string,
    tooltip: PropTypes.string,
    tooltipPlace: PropTypes.string
};

export default Field;
