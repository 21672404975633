export const _MORTGAGE_TYPE = {
  NEW_MORTGAGE: 'new_mortgage_to_buy',
  MORTGAGE_EXISTING: 'mortgage_existing_let'
};

_MORTGAGE_TYPE.OPTIONS = [
  {
    code: _MORTGAGE_TYPE.NEW_MORTGAGE,
    text: 'Mortgage to buy a new holiday home',
    icon: 'home',
    tooltip: 'Select this option if the mortgage will be secured against a property that you intend to purchase.'
  },
  {
    code: _MORTGAGE_TYPE.MORTGAGE_EXISTING,
    text: 'Mortgage my existing holiday home',
    icon: 'weekend',
    tooltip: 'Select this option if the mortgage will be secured against a property that you already own.'
  }
];