import * as React from 'react';
import Register from './Register';
import ForgotCredentials from './ForgotCredentials';
import {welcomeLink} from "../util/links";
import Header from "../welcome/Header";
import FormButton from "../form/components/FormButton";

class Authenticator extends React.Component {
    onAuthStateChange(newState, newData) {
        const data = Object.assign({}, this.state.authData, newData);
        this.setState({ authState: newState, authData: data });
        if (newState === 'authenticated') {
            this.props.onAuthenticated(data);
        }
    }

    render() {
        if (this.props.loggedIn){
            return (
                <div className="container">
                    <div className='row'>
                        <div className='col-12'>
                            <Header {...this.props} type={Header.TYPES.LOGIN} />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-12 col-md-8 offset-md-2`}>
                            <div className="card login">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12 padding-bottom'>
                                            <h1 className='oos-title'>You are already logged in!</h1>
                                            <FormButton link path={welcomeLink()} text='Return to Welcome'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        switch (true) {
            case this.props.match.path.includes('register'):
                return <Register {...this.props} />;

            case this.props.match.path.includes('forgotcredentials'):
                return <ForgotCredentials {...this.props} />

            default:
                return '';
        }
    }
}

export default Authenticator;