import React from "react";
import PropTypes from "prop-types";

export default class AffordabilityMortgageTypeRadios extends React.Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    };

    static VALUE_INTEREST_ONLY = 'interest-only';
    static VALUE_REPAYMENT = 'repayment';

    render() {
        let interestOnlyRef = React.createRef(),
            repaymentRef = React.createRef();

        // Bootstrap 4:
        // custom-control custom-radio custom-control-inline
        // custom-control-input
        // custom-control-label
        //
        // Bootstrap 5:
        // form-check form-check-inline custom-radio
        // form-check-input
        // form-check-label

        return (
            <div className="form-group">
                <div className="row">
                    <div className="col-12">
                        <label>{this.props.text}</label>
                        <div className='form-group hypo-inline-radios'>
                            <div className='form-check form-check-inline custom-radio  custom-control custom-radio custom-control-inline'>
                                <input
                                    type="radio"
                                    className="form-check-input  custom-control-input"
                                    id={AffordabilityMortgageTypeRadios.VALUE_INTEREST_ONLY}
                                    value={AffordabilityMortgageTypeRadios.VALUE_INTEREST_ONLY}
                                    onChange={() => {
                                        return this.props.onChange(AffordabilityMortgageTypeRadios.VALUE_INTEREST_ONLY)
                                    }}
                                    checked={AffordabilityMortgageTypeRadios.VALUE_INTEREST_ONLY === this.props.value}
                                    ref={interestOnlyRef}
                                />
                                <label
                                    className="form-check-label  custom-control-label"
                                    onClick={(e) =>{interestOnlyRef.current.click(); e.preventDefault()}}
                                    htmlFor={AffordabilityMortgageTypeRadios.VALUE_INTEREST_ONLY}>Interest Only</label>
                            </div>
                            <div className='form-check form-check-inline custom-radio  custom-control custom-radio custom-control-inline'>
                                <input
                                    type="radio"
                                    className="form-check-input  custom-control-input"
                                    id={AffordabilityMortgageTypeRadios.VALUE_REPAYMENT}
                                    value={AffordabilityMortgageTypeRadios.VALUE_REPAYMENT}
                                    onChange={() => {
                                        return this.props.onChange(AffordabilityMortgageTypeRadios.VALUE_REPAYMENT)
                                    }}
                                    checked={AffordabilityMortgageTypeRadios.VALUE_REPAYMENT === this.props.value}
                                    ref={repaymentRef}
                                />
                                <label
                                    className="form-check-label  custom-control-label"
                                    onClick={(e) =>{repaymentRef.current.click(); e.preventDefault()}}
                                    htmlFor={AffordabilityMortgageTypeRadios.VALUE_REPAYMENT}>Repayment</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}