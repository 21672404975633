import React from "react";
import PropTypes from "prop-types";

import { Alert, ALERTTYPE } from "./alert/Alert";
import {formatDateStandard, formatTimeDiff, getTimeDiff} from "common-hypotheca/src/dates";

export default class TimeDiff extends React.Component {
    static defaultProps = {
        applicable: true
    };

    render() {

        const fromValue = this.props.fromValue || this.props.value;

        const timeDiff = fromValue && this.props.format ? getTimeDiff(fromValue, this.props.toValue) : null;

        const formattedValue =
            timeDiff && (timeDiff.days > 0 || timeDiff.months > 0 || timeDiff.years > 0)
            ? formatTimeDiff(timeDiff, this.props.format)
            : null;

        // Applicable parameter is used here so that empty values are also reflected in application data
        if (!this.props.applicable) {
            if (this.props.updateFactFindFunction) {
                this.props.updateFactFindFunction(null, null)
            }
            return null;
        }

        // Store value displayed to the user for fact find admin display
        // NB: this function shouldn't trigger re-rendering (should not trigger redux action) but should update data directly
        if (this.props.updateFactFindFunction) {
            this.props.updateFactFindFunction(formattedValue, formatDateStandard(new Date()))
        }

        return <React.Fragment>

            {
                formattedValue &&
                <Alert
                    type={ALERTTYPE.INFO} 
                    text={this.props.label.replace('{0}', formattedValue)}
                />
            }


        </React.Fragment>
        ;

    }
}

TimeDiff.propTypes = {
    label: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    field: PropTypes.any,
    fromValue: PropTypes.any,
    toValue: PropTypes.any,
    applicable: PropTypes.bool,
    updateFactFindFunction: PropTypes.func
};
