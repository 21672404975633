import React, {Fragment} from "react";
import expertImg from '../../../images/expert-advice.gif';
import { FormButton, Alert } from "../../../form/components";
import { API } from "aws-amplify";
import { APINAME, APIPATH } from '../../../constants';
import ControlButtons from "../../../form/ControlButtons";
import {
    APP_STATUS,
    SECTION,
    MY_MORTGAGE
} from "common-hypotheca";
import {dashboardLink} from "../../../util/links";
import Loading from "../../../form/icons/Loading";
import {ALERTTYPE} from "../../../form/components/alert/Alert";

class ExpertAdvice extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            requesting: false
        };
    }

    requestExpert = async () => {
        this.setState({
            requesting: true
        });

        let response = await API.post(APINAME, `${APIPATH.TRIGGER}`, {
            body: {
                applicationId: this.props.data.applicationId,
                event: 'request_expert_advice'
            }
        });

        this.setState({
            requesting: false
        });

        let data = this.props.data;
        data.appStatus = APP_STATUS.REQUEST_LOCKED;
        this.props.setData(data);
    };

    getAction = () => {

        if ([APP_STATUS.STARTED, APP_STATUS.BORROW_GOOD,APP_STATUS.REQUEST_UNLOCKED].includes(this.props.data.appStatus)) {
            // try to handle unfinished/failed autosave if user hasn't advanced the app to the next stage yet.
            if (this.props.lastSavedApplication === false)
                return <Alert type={ALERTTYPE.DANGER}>There seems to be an internet connection problem. Please check your connection then wait for autosave to complete!</Alert>;
            else if (this.props.lastSavedApplication === true)
                return "Waiting for autosave to complete...";
            else if (!this.props.adapter.partComplete(null, SECTION.MY_MORTGAGE, MY_MORTGAGE.SOLICITOR)) {
                return (
                    <Fragment>
                        <Alert type={ALERTTYPE.DANGER}>Application not fully filled in, please check missing sections.</Alert>
                        <FormButton
                            text={`Review application`}
                            path={dashboardLink(SECTION.MY_MORTGAGE)}
                        />
                    </Fragment>
                );
            }
        }

        if (this.state.requesting)
            return <Loading/>;

        switch (this.props.data.appStatus) {
            case APP_STATUS.STARTED:
            case APP_STATUS.BORROW_GOOD:
                return <FormButton
                    text={`Request expert advice`}
                    onClick={() => this.requestExpert()}
                />;

            case APP_STATUS.REQUEST_LOCKED:
                return <Alert text='Your request has been submitted. Please wait for an email from one of our advisors.' />;

            case APP_STATUS.REQUEST_UNLOCKED:
                return <FormButton
                    text={`Re-request expert advice`}
                    onClick={() => this.requestExpert()}
                />;

            case APP_STATUS.ADVICE_GIVEN:
                return null;

            case APP_STATUS.PROCEED_DOC_REVIEW:
                return <FormButton
                    text={`Please review your documents`}
                    path={dashboardLink(SECTION.MY_APPLICATION)}
                />;

            default:
                return null
        }

    };

    render() {
        return (
            <div className='row'>
                <div className='col-sm-8 offset-sm-2'>
                    <div className="card expert-advice">
                        <div className="card-body">
                            <div className='row reverse-column'>
                              <div className='col-12 col-md-8'>
                              	<div className="col-form-label">You are now ready to have a quick chat with one of our expert mortgage advisers! </div>
                                <p>This should take no longer than 20 mins and is done via a simple phone call. </p>
                                <p>The purpose of the call is to make sure that a holiday let mortgage is the right solution for you. Our advisor will run through your situation, clarify any outstanding points, then discuss what mortgage options are best suited to your needs.</p>
                                <p>Simply click the button below to send a message to our team and one of our advisors will email you to arrange a convenient time to call!</p>
                                <ul>
                                	<li>No credit check!</li>
                                    <li>Your information will be set to read only to ensure the advice is given on a fixed set of data</li>
                                    <li>One of our advisors will arrange a call between 9-5pm office hours</li>
                                </ul>
                                {this.getAction()}
                                  {
                                      this.props.data.appStatus === APP_STATUS.ADVICE_GIVEN &&
                                      <ControlButtons nextPath={dashboardLink(SECTION.MY_APPLICATION)}/>
                                  }
							  </div>
							  <div className='col-6 col-md-4 expert-advice-img'>
                              	<img src={expertImg} />
							  </div>
							</div>
                              
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ExpertAdvice;