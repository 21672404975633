export const _APP_STATUS = {
    INITIAL: 'initial', // when first created - will not be a valid application in MyApplications
    STARTED: 'started', // when first edited

    BORROW_BAD: 'borrow_bad',
    BORROW_GOOD: 'borrow_good',

    /** Application returned back to pre-expert advice */
    REQUEST_UNLOCKED: 'sent_unlocked',

    /** Expert advice requested */
    REQUEST_LOCKED: 'sent_locked',

    /** Application receives this status after advisor calls person and discusses the application */
    ADVICE_GIVEN: 'advice_given',

    /** Application receives this status when  */
    APP_CONFIRM_GIVE: 'app_confirm_given',

    PRESUB_REVIEW_COMPLETE: 'presub_review_complete',
    DIP_COMPLETE:'dip_complete',
    APP_SUBMITTED: 'app_submitted',
    LENDER_CONSULTATION: 'lender_consultation',
    MORTGAGE_OFFER_ISSUED: 'mortgage_offer_issued',

    MORTGAGE_COMPLETED: 'mortgage_complete',

    DELETED: 'deleted',

    /** For testing purposes only, open everything for testing. */
    TEST_EVERYTHING_OPEN: 'test_everything_open'
};

export const _HIDDEN_STATUSES = [
    _APP_STATUS.INITIAL,
    _APP_STATUS.DELETED
];

export const _FINISH_LINE_STATUSES = [
    _APP_STATUS.PRESUB_REVIEW_COMPLETE,
    _APP_STATUS.DIP_COMPLETE,
    _APP_STATUS.APP_SUBMITTED,
    _APP_STATUS.LENDER_CONSULTATION,
    _APP_STATUS.MORTGAGE_OFFER_ISSUED,
    _APP_STATUS.MORTGAGE_COMPLETED
];

export const _PRE_ADVICE_STATUSES = [
    _APP_STATUS.INITIAL,
    _APP_STATUS.STARTED,
    _APP_STATUS.BORROW_BAD,
    _APP_STATUS.BORROW_GOOD,
    _APP_STATUS.REQUEST_UNLOCKED,
];

export const _AFTER_ADVICE_EDITABLE_STATUSES = [
    _APP_STATUS.REQUEST_LOCKED,
    _APP_STATUS.ADVICE_GIVEN,
    _APP_STATUS.APP_CONFIRM_GIVE
];

export function _describeStatus(statusId) {
    switch (statusId) {
        case _APP_STATUS.INITIAL:
            return 'Created';
        case _APP_STATUS.STARTED:
            return 'In progress';
        case _APP_STATUS.BORROW_BAD:
            return 'Failed borrowing estimate';
        case _APP_STATUS.BORROW_GOOD:
            return 'Borrowing estimate';
        case _APP_STATUS.REQUEST_LOCKED:
            return 'Expert advice requested';
        case _APP_STATUS.REQUEST_UNLOCKED:
            return 'Application re-opened';
        case _APP_STATUS.ADVICE_GIVEN:
            return 'Expert advice given';
        case _APP_STATUS.APP_CONFIRM_GIVE:
            return 'Started formal application';
        case _APP_STATUS.PRESUB_REVIEW_COMPLETE:
            return 'Pre-submission review';
        case _APP_STATUS.DIP_COMPLETE:
            return 'Decision in principle';
        case _APP_STATUS.APP_SUBMITTED:
            return 'Application submitted';
        case _APP_STATUS.LENDER_CONSULTATION:
            return 'Lender consultation';
        case _APP_STATUS.MORTGAGE_OFFER_ISSUED:
            return 'Mortgage offer issued';
        case _APP_STATUS.MORTGAGE_COMPLETED:
            return 'Mortgage completed';
        case _APP_STATUS.DELETED:
            return 'Deleted';
        case _APP_STATUS.TEST_EVERYTHING_OPEN:
            return 'Test mode';
        default:
            return statusId;
    }
}

const FILTER_STATUSES = [
    _APP_STATUS.STARTED,
    _APP_STATUS.REQUEST_LOCKED,
    _APP_STATUS.REQUEST_UNLOCKED,
    _APP_STATUS.ADVICE_GIVEN,
    _APP_STATUS.APP_CONFIRM_GIVE,
    _APP_STATUS.PRESUB_REVIEW_COMPLETE,
    _APP_STATUS.DIP_COMPLETE,
    _APP_STATUS.APP_SUBMITTED,
    _APP_STATUS.LENDER_CONSULTATION,
    _APP_STATUS.MORTGAGE_OFFER_ISSUED,
    _APP_STATUS.MORTGAGE_COMPLETED
];

_APP_STATUS.FILTER_OPTIONS = FILTER_STATUSES.map(status => {
    return {
        value: status,
        label: _describeStatus(status)
    };
});

const CHANGE_STATUSES = [
    _APP_STATUS.REQUEST_UNLOCKED,
    _APP_STATUS.REQUEST_LOCKED,
    _APP_STATUS.ADVICE_GIVEN,
    _APP_STATUS.PRESUB_REVIEW_COMPLETE,
    _APP_STATUS.DIP_COMPLETE,
    _APP_STATUS.APP_SUBMITTED,
    _APP_STATUS.LENDER_CONSULTATION,
    _APP_STATUS.MORTGAGE_OFFER_ISSUED,
    _APP_STATUS.MORTGAGE_COMPLETED
];

_APP_STATUS.CHANGE_OPTIONS = [
    {
        code: '',
        text: 'Select new status'
    }
].concat(CHANGE_STATUSES.map(status => {
    return {
        code: status,
        text: _describeStatus(status)
    };
}));