import aws_exports from "./aws-exports";
import Amplify, { Auth } from "aws-amplify";

let auth2 = false;

function configureAmplify() {
    let aws_env;

    let modified_aws_exports = {
        ...aws_exports,
        // Manually added sections
        "aws_cognito_identity_pool_id": "eu-west-1:890c70a3-a236-450e-b39b-ff31e069990d",
        "aws_cognito_region": "eu-west-1",
        "aws_user_pools_id": "eu-west-1_sJf77pvPh",
        "aws_user_pools_web_client_id": "63k7esou9on29l046u2okp5gc8",
    };

    // Link to production s3 for pre-production
    if (aws_exports.aws_content_delivery_bucket.includes('preprod')) {

        modified_aws_exports.aws_user_files_s3_bucket = "frontenda2b6f41c9f7446f597ed2ca177b4d975";
        aws_env = 'preprod';

        // modified_aws_exports["aws_cognito_identity_pool_id"] = "eu-west-1:32aeedbb-7f5c-4d9a-984b-bca1dfb687c0";
    }

    Amplify.configure(modified_aws_exports);

    return aws_env;
}

export function configureAuth()
{
    auth2 = false;
    Auth.configure({
        Auth: {
            identityPoolId: "eu-west-1:890c70a3-a236-450e-b39b-ff31e069990d",
            region: "eu-west-1",
            userPoolId: "eu-west-1_sJf77pvPh",
            userPoolWebClientId: "63k7esou9on29l046u2okp5gc8"
        }
    });
}

export const USER_POOL_ID_AUTH2 = "eu-west-1_FdBVqx4n3";

export function configureAuth2()
{
    auth2 = true;
    Auth.configure({
        Auth: {
            identityPoolId: "eu-west-1:7e908f41-2c4e-4adc-aa1c-2f9bc54b778f",
            region: "eu-west-1",
            userPoolId: USER_POOL_ID_AUTH2,
            userPoolWebClientId: "tj9c5q930724g0ml3664n1rre"
        }
    });
}

export function isAuth2()
{
    return auth2;
}

export default configureAmplify;
