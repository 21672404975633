import { Component } from 'react';
import { Hub, Auth,API } from 'aws-amplify';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {login, logout} from "../redux/actions";
import {APINAME, APIPATH, H_AUTH_CHANNEL, H_CONFIRM_EMAIL_EVENT, H_CONFIRM_QUESTIONS_EVENT} from '../constants';
import {intercomLogin, intercomLogout, intercomShutdown} from "../util/intercom";
import {USER_POOL_ID_AUTH2} from "../amplify-configurator";
import GoogleAnalytics4 from "../service/GoogleAnalytics4";

/**
 * This class listens to Amplify Hub and dispatches authentication status changes to redux store.
 */
class AuthListener extends Component {
    constructor(props) {
        super(props);

        this.dispatchUserInfo.bind(this);

        let listener = this;

        Hub.listen(
            'auth',
            (event) => { listener.dispatchUserInfo(event) },
            'navigator-auth'); // Add this component as listener of auth event.

        Hub.listen(
            H_AUTH_CHANNEL,
            (event) => { listener.dispatchUserInfo(event) },
            'navigator-'); // Add this component as listener of auth event.
    }

    dispatchUserInfo(event) {

        if (event && event.payload && event.payload.event === 'configured') {
            // Ignore auth configuration
            return;
        }

        Auth.currentAuthenticatedUser({
            // Force the user info download from Cognito, if it is taken from local storage it might contain
            // outdated data
            // But only on object creation when we try to find out current logged in status or after SignIn changed
            // something in the user.
            bypassCache: event === undefined
                || event.payload === undefined
                || event.payload.event === undefined
                || event.payload.event === H_CONFIRM_EMAIL_EVENT
                || event.payload.event === H_CONFIRM_QUESTIONS_EVENT
        })
            .then(user => {
                let auth2 = user.pool && user.pool.userPoolId === USER_POOL_ID_AUTH2;

                // Seems true/'true' is different between AWS version
                if (user.attributes['custom:secure'] === true || user.attributes['custom:secure'] === 'true' || auth2) {
                    API.get(APINAME, APIPATH.USER, {queryStringParameters: { API_EXTRA: user.attributes['custom:apiExtra']}})
                        .then(userAccount => {
                            if (userAccount.error) {
                                console.log('Failed user load');
                                return;
                            }

                            let userData = {
                                ...userAccount
                            };

                            if (!auth2) {
                                userData.email = user.attributes.email;
                                userData.emailVerified = user.attributes.email_verified
                            }

                            if (user.attributes.email_verified) {
                                this.props.login(userData, user.attributes['custom:apiExtra'])
                            } // signing screen alos catches this

                            if (auth2) {
                                intercomShutdown();
                                GoogleAnalytics4.userLoggedOut();
                            } else {
                                intercomLogin(userData.email, user.username, userData.nickname);
                                GoogleAnalytics4.userLoggedIn(user.username);
                            }

                            API.get(APINAME, APIPATH.SIGNIN, {})
                                .then(response => {
                                    if (response.success)
                                        userData.lastSignInTime = response.lastSignInTime;
                                })
                                .catch(error => {
                                    console.log(error.response)
                                });
                        })
                        .catch(e => {
                            console.log(e)
                        });

                } // signin screen takes care otherwise
                
            })
            .catch(err => {
                // console.log('dispatchUserInfo err',err);
                if (this.props.loggedIn)
                {
                    // console.log('dispatchUserInfo logout',err);
                    this.props.logout();
                    intercomLogout();
                    GoogleAnalytics4.userLoggedOut();
                }
                else
                {
                    // console.log('dispatchUserInfo logout ignored',err);
                }
            });
    }

    componentDidMount() {
       // console.log('AuthListener did mount',this.props);
        this.dispatchUserInfo();
    }

    render() {
        return null;
    }
}

AuthListener.propTypes = {
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    loggedIn: state.loggedIn
});

const mapDispatchToProps = dispatch => ({
    login: (email, apiExtra) => dispatch(login(email, apiExtra)),
    logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthListener);