export const _SECTION = {
    SCREENING: 'screening',
    SITUATION: 'situation',
    PROPERTY: 'property',
    MY_MORTGAGE: 'mymortgage',
    MY_APPLICATION: 'myapplication',
    APPLICATION_STATUS: 'applicationstatus'
};

export const _SCREENING = {
    MORTGAGE_TYPE_AND_APPLICANTS: 1,
    CREDIT_HISTORY_AND_RESIDENCE: 2,
    KEY_CRITERIA: 3,
    INITIAL_ASSESSMENT: 4
};

export const _SITUATION = {
    PERSONAL_DETAILS: 1,
    EMPLOYMENT: 2,
    ASSETS: 3,
    CREDIT: 4,
    OUTGOINGS: 5,
};

export const _PROPERTY = {
    DETAILS: 1,
    LETTING: 2
};

export const _MY_MORTGAGE = {
    MORTGAGE_DETAILS: 'mortgagedetails',
    MORTGAGE_PREFERENCES: 'mortgagepreferences',
    LIMITED_COMPANY: 'limitedcompany',
    EXPERT_ADVICE: 'expertadvice',
    SOLICITOR:'solicitor'
};

export const _MY_APPLICATION = {
    KEY_DOCS: 'docskey',
    DOC_UPLOAD: 'docsrequired'
};

export const _model = {
    sectionOrdering: [
        _SECTION.SCREENING,
        _SECTION.SITUATION,
        _SECTION.PROPERTY,
        _SECTION.MY_MORTGAGE,
        _SECTION.MY_APPLICATION,
        _SECTION.APPLICATION_STATUS
    ],
};

_model[_SECTION.SCREENING] = {
    id: _SECTION.SCREENING,
    short: 'AS',
    order:0,
    name: "Get started",
    hasParts: true,
    hasApplicants: false,
    hasSupplement: false,
    isShownOnDashboard: false,
    parts: [
        {
            id: _SCREENING.MORTGAGE_TYPE_AND_APPLICANTS,
            name: "Mortgage type and applicants",
            short: 'MTA'
        },
        {
            id: _SCREENING.CREDIT_HISTORY_AND_RESIDENCE,
            name: "Credit and residency checks",
            short: 'CR'
        },
        {
            id: _SCREENING.KEY_CRITERIA,
            name: 'Key lending criteria',
            short: 'HLC'
        },
        {
            id: _SCREENING.INITIAL_ASSESSMENT,
            name: 'Initial assessment',
            short: 'HLC'
        }
    ]
};

_model[_SECTION.SITUATION] = {
    id: _SECTION.SITUATION,
    short: 'MS',
    order:1,
    name: "My situation",
    hasParts: true,
    hasApplicants: true,
    hasSupplement: false,
    isShownOnDashboard: false,
    parts: [
        {
            id: _SITUATION.PERSONAL_DETAILS,
            name: 'Personal details',
            short: 'PD'
        },
        {
            id: _SITUATION.ASSETS,
            name: 'Assets',
            short: 'AS'
        },
        {
            id: _SITUATION.EMPLOYMENT,
            name: 'Employment and other income',
            short: 'EI'
        },
        {
            id: _SITUATION.CREDIT,
            name: 'Credit agreements',
            short: 'CC'
        },
        {
            id: _SITUATION.OUTGOINGS,
            name: 'Outgoings',
            short: 'OUT'
        },

    ]
};

_model[_SECTION.PROPERTY] = {
    id: _SECTION.PROPERTY,
    short: 'MP',
    order: 2,
    name: "My property",
    hasParts: true,
    hasApplicants: false,
    hasSupplement: false,
    isShownOnDashboard: false,
    parts: [
        {
            id: _PROPERTY.DETAILS,
            name: 'Property details',
            short: 'PD'
        },
        {
            id: _PROPERTY.LETTING,
            name: 'Holiday let information',
            short: 'HL'
        }
    ]
};

_model[_SECTION.MY_MORTGAGE] = {
    id: _SECTION.MY_MORTGAGE,
    short: 'MM',
    order:3,
    name: "My mortgage",
    hasParts: true,
    hasApplicants: false,
    hasSupplement: false,
    isShownOnDashboard: false,
    parts: [
        /* Borrow estimate removed for MVP.
        {
            id: _MY_MORTGAGE.BORROW_ESTIMATE,
            name: 'Borrowing estimate'
        },
        {
            id: _MY_MORTGAGE.BORROW_ESTIMATE_BAD_DEBUG,
            name: 'Borrowing estimate (bad)',
            isAvailable: true
        },
        */
        {
            id: _MY_MORTGAGE.MORTGAGE_DETAILS,
            name: 'Mortgage details',
            short: 'MD'
        },
        {
            id: _MY_MORTGAGE.MORTGAGE_PREFERENCES,
            name: 'Mortgage preferences',
            short: 'MP'
        },
        {
            id: _MY_MORTGAGE.LIMITED_COMPANY,
            name: 'Limited Company details',
            short: 'LTD'
        },
        {
            id: _MY_MORTGAGE.SOLICITOR,
            name: 'Survey, solicitor and insurance',
            short: 'SSI'
        },
        {
            id: _MY_MORTGAGE.EXPERT_ADVICE,
            name: 'Expert advice'
        }
    ]
};

_model[_SECTION.MY_APPLICATION] = {
    id: _SECTION.MY_APPLICATION,
    short: 'MA',
    order:4,
    name: "My application",
    hasParts: true,
    hasApplicants: false,
    hasSupplement: true,
    isShownOnDashboard: false,
    parts: [
        {
            id: _MY_APPLICATION.KEY_DOCS,
            name: 'Review key documents'
        },
        {
            id: _MY_APPLICATION.DOC_UPLOAD,
            name: 'Document upload centre'
        }
    ]
};

_model[_SECTION.APPLICATION_STATUS] = {
    id: _SECTION.APPLICATION_STATUS,
    order:5,
    name: "Application status",
    hasParts: false,
    hasApplicants: false,
    hasSupplement: false,
    isShownOnDashboard: true,
    parts: [
    ]
};
