import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";

import {extractFieldName, FIELDS, OTHER} from "common-hypotheca";
import FieldInput from "common-form/components/FieldInput";
import FieldGroupAddable from "../../../../../form/components/FieldGroupAddable";
import FieldLabel from "common-form/components/FieldLabel";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldDate from "common-form/components/FieldDate";
import { FieldDropdown } from "../../../../../form/components/dropdown";
import { LABEL_TYPE } from "common-form/components/Field";

export default function PersonalLoanList(parent, nextBlockId) {

    return {
        block: (params)=>

            <QuestionBlock {...params}>

                <FieldLabel
                    label="Please tell us about your personal loans"
                />

                <FieldGroupAddable
                    ref={params.groupAddableRef}
                    addButtonLabel="Add personal loan"
                    allowDeleteAll
                    deleteButtonLabel="Remove"
                    editButtonLabel="Edit"
                    saveButtonLabel="Save personal loan"
                    itemDescriber={(fieldsContainer) => {
                        if (fieldsContainer[extractFieldName(FIELDS.MS.CC.PersonalLoanName)] === undefined)
                            return 'A loan from unspecified provider';
                        else
                            return 'Loan from ' + fieldsContainer[extractFieldName(FIELDS.MS.CC.PersonalLoanName)];
                    }}
                    validate={(context) => {
                        context
                            .mandatory(FIELDS.MS.CC.PersonalLoanName)
                            .mandatory(FIELDS.MS.CC.PersonalLoanPurpose)
                            .mandatory(FIELDS.MS.CC.PersonalLoanAcctNum)
                            .mandatory(FIELDS.MS.CC.PersonalLoanOrigBorrowAmt)
                            .date(FIELDS.MS.CC.PersonalLoanOrigStartDate)
                            .mandatory(FIELDS.MS.CC.PersonalLoanCurrentBal)
                            .mandatory(FIELDS.MS.CC.PersonalLoanPayEachMonth)
                            .date(FIELDS.MS.CC.PersonalLoanFinalRepayDate)
                            .mandatory(FIELDS.MS.CC.PersonalLoanPaidOffCompletion)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.PersonalLoanPaidOffCompletion),
                                FIELDS.MS.CC.PersonalLoanPaidOffCompletionHow)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.PersonalLoanPaidOffCompletion)
                                && context.fieldValue(FIELDS.MS.CC.PersonalLoanPaidOffCompletionHow) === OTHER,
                                FIELDS.MS.CC.PersonalLoanPaidOffCompletionHowOther)
                            // Cleanup obsolete fields (if present in data)
                            .cleanupIrrelevantField(FIELDS.MS.CC.PersonalLoanCurrentIntRate)
                            .cleanupIrrelevantField(FIELDS.MS.CC.PersonalLoanIsSecOnProperty)
                            .cleanupIrrelevantField(FIELDS.MS.CC.PersonalLoanIsSecOnPropertyDescr);
                    }}
                    {...parent.getFormField(null, FIELDS.MS.CC.PersonalLoans)}
                >

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.PersonalLoanName}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.PersonalLoanPurpose}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.PersonalLoanAcctNum}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.PersonalLoanOrigBorrowAmt}
                    />

                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.PersonalLoanOrigStartDate}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.PersonalLoanCurrentBal}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.PersonalLoanPayEachMonth}
                    />

                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.PersonalLoanFinalRepayDate}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.PersonalLoanPaidOffCompletion}
                    />

                    <FieldDropdown
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.PersonalLoanPaidOffCompletion)] }
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.PersonalLoanPaidOffCompletionHow}
                    />

                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.PersonalLoanPaidOffCompletion)]
                            && container[extractFieldName(FIELDS.MS.CC.PersonalLoanPaidOffCompletionHow)] === OTHER }
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.PersonalLoanPaidOffCompletionHowOther}
                        placeholder="Other method description"
                    />

                </FieldGroupAddable>

            </QuestionBlock>,
            
        validate: (context) => {

            if (!context.hasItems(FIELDS.MS.CC.PersonalLoans)) {
                context.addError(null, null, "Please enter an item or go back to the previous screen and change your selection.");
            }

            if (parent.groupAddableRef && !parent.groupAddableRef.current.valid()) {
                context.addError(FIELDS.MS.CC.PersonalLoans, null, '');
            }

        },

        nextQuestionId: () => parent.nextSelectedCommitmentType(nextBlockId)

    };
}