import React from 'react';
import PropTypes from "prop-types";
import Field from "./Field";


export class FieldLabel extends Field {
    static propTypes = {
        label: PropTypes.any.isRequired,
        className: PropTypes.string,
        labelType: PropTypes.string,
        hide: PropTypes.bool,
        note: PropTypes.bool
    };

    render() {
        if (!this.show())
            return null;

        let label = this.label();

        let clazz = this.getLabelClassNameFromType() || (this.props.note ? 'col-form-note' : 'col-form-label');
        if (clazz) {
            clazz += ` ${this.props.className}`;
        }

        return (
            <div className="form-group mb-4">
                <span className={clazz}>{label}</span>
            </div>
        );
    }
}


export default FieldLabel;