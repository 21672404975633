import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldLabel from "common-form/components/FieldLabel";
import { LABEL_TYPE } from "common-form/components/Field";
import FieldTextArea from "../../../../../form/components/FieldTextArea";

import {FIELDS} from "common-hypotheca";


export default function FinalText(parent) {
    
    return {
        block:(params)=>

            <QuestionBlock {...params}>

                <FieldLabel
                    label= {`Finally, ${parent.getApplicantName()}, please tell us about anything that you are aware of that will, or is likely 
                    to, change your income during the term of the mortgage`}
                />

                <FieldLabel
                    labelType={ LABEL_TYPE.Description }
                    label= {`For example: redundancy that you are already aware of, having a new baby, job
                    promotion and pay rise, sale of assets, inheritance, new dependants, etc.`}
                />

                <FieldTextArea
                    {...parent.getField(FIELDS.MS.EI.ChangesToIncome)}
                />

            </QuestionBlock>,
        validate: (context) => {
            context.optional(FIELDS.MS.EI.ChangesToIncome)
        },
        nextQuestionId: () => parent.afterFinalText()
    };



}