export const _CITIZEN_TEST_OTHER = {
    NONE: 'none'
};

_CITIZEN_TEST_OTHER.OPTIONS = [
    { code: '', text: '' },
    { code: 'indefinite_leave_to_remain',  text: 'Indefinite leave to remain' },
    { code: 'tier_1_visa', text: 'Tier 1 visa' },
    { code: 'tier_2_visa', text: 'Tier 2 visa' },
    { code: 'family_visa', text: 'Family visa' },
    { code: _CITIZEN_TEST_OTHER.NONE, text: 'None of the above' }
];