import React from 'react';
import * as Scroll from "react-scroll";

import { extractDesignation, extractFieldName } from "common-hypotheca";

import PropTypes from "prop-types";
import Field from './Field';
import FormButton from "./FormButton";
import { validateAllFields, validateSingleField } from "../../util/validate";
import FieldLabel from "common-form/components/FieldLabel";
import { Alert, ALERTTYPE } from './alert/Alert';

class FieldGroupAddable extends Field {

    constructor(props) {
        super(props);

        if (Array.isArray(this.props.value) && this.props.value.length > 0) {
            this.state = {
                editing: this.props.editIndex != null && this.props.value.length > this.props.editIndex ? this.props.editIndex : undefined
            };
        } else {
            this.state = {
                isNewRow: this.props.disableAutoEdit ? undefined : true,
                editing: this.props.disableAutoEdit ? undefined : 0
            };
        }

        if (this.state.editing != null) {
            this.raiseBeforeEdit();
        }
    }

    raiseBeforeEdit = () => {
        if (this.props.beforeEdit) {
            this.props.beforeEdit();
        }

        if (this.props.disableNavigation) {
            this.props.disableNavigation(true);
        }
    }

    buildProps = (field, index, props) => {

        let newProps = {
            confirmNewRow: this.confirmNewRow,
            value: this.getGroupFieldValue(index, field),
            handleFieldFilled: (applicantId, field, value) => this.handleGroupFieldFilled(index, field, value),
            fieldsContainer: this.groupFieldContainer(index),
            error: this.state.validationContext === undefined ? undefined : this.state.validationContext.error(field),
            valid: this.state.validationContext === undefined ? false : this.state.validationContext.isValid(field),
            disabled: this.props.disabled,
            designation: extractDesignation(field)
        };

        if (typeof field === 'object') {
            if (field.options !== undefined) {
                if (props.options === undefined) {
                    newProps.options = field.options;
                }

                if (props.items === undefined) {
                    newProps.items = field.options;
                }
            }

            if (field.frontendLabel !== undefined && props.label === undefined) {
                newProps.label = field.frontendLabel;
            }

            if (field.label !== undefined && props.label === undefined) {
                newProps.label = field.label;
            }

            if (field.name) {
                newProps.field = field.name;
            }
        }

        return newProps;
    }

    renderChildren = (index) => {

        if (index === this.state.editing) {

            return React.Children.map(this.props.children, child => {

                if (child.props.field) {
                    return React.cloneElement(child, this.buildProps(child.props.field, index, child.props));
                } else if (child.props.fields) {

                    let props = {
                        ...child.props,
                        confirmNewRow: this.confirmNewRow,
                        fields: child.props.fields.map(field => this.buildProps(field, index, {}))
                    };

                    return React.cloneElement(child, props);
                }

                return child;
            });

        } else {
            return this.props.itemDescriber(this.groupFieldContainer(index));
        }
    };

    groupFieldContainer = (index) => {
        if (Array.isArray(this.props.value) && this.props.value[index] !== undefined)
            return this.props.value[index];
        else
            return {};
    };

    getGroupFieldValue = (index, field) => {
        return this.groupFieldContainer(index)[extractFieldName(field)];
    };

    handleGroupFieldFilled = (index, field, value) => {
        this.confirmNewRow();

        let newArrayValue;

        if (Array.isArray(this.props.value))
            newArrayValue = this.props.value.slice();
        else
            newArrayValue = [];

        let newGroupValue = newArrayValue[index];

        if (newGroupValue === undefined) {
            newGroupValue = {};
            newArrayValue[index] = newGroupValue;
        }

        newGroupValue[extractFieldName(field)] = value;
        newArrayValue[index] = newGroupValue;

        this.props.handleFieldFilled(null, this.props.field, newArrayValue);

        validateSingleField(field, this, this.props.validate, () => this.groupFieldContainer(index));
    };

    /**
     * If operating on a new row, marks that new row has input and reserves array entry for its contents.
     * Otherwise, does nothing.
     */
    confirmNewRow = () => {
        if (!this.state.isNewRow) {
            return;
        }

        if (Array.isArray(this.props.value)) {
            this.props.value.push({});
            this.props.handleFieldFilled(null, this.props.field, this.props.value);
        } else {
            this.props.handleFieldFilled(null, this.props.field, [{}]);
        }

        this.setState({
            isNewRow: false,
            editing: Array.isArray(this.props.value) ? this.props.value.length - 1 : 0
        })
    };

    addGroupInstance = () => {

        this.setState({
            isNewRow: true,
            editing: Array.isArray(this.props.value) ? this.props.value.length : 0
        });

        this.raiseBeforeEdit();
    };

    editGroupInstance = (index) => {
        this.setState({
            editing: index
        });

        this.raiseBeforeEdit();
    };

    isValidGroup = (stateOwner, index) => {
        return validateAllFields(stateOwner, this.props.validate, () => this.groupFieldContainer(index));
    };

    valid = () => {

        if (!this.props.value || !this.props.value.length) {
            return true;
        }

        for (let i = 0; i < this.props.value.length; i++) {
            const valid = this.isValidGroup(null, i);
            if (!valid) {
                return false;
            }
        }

        return true;
    };

    raiseAfterEdit = () => {

        if (this.props.afterEdit) {
            this.props.afterEdit();
        }

        if (this.props.disableNavigation) {
            this.props.disableNavigation(false);
        }
    };

    saveGroupInstance = () => {

        // if auto edit is disabled, we just have + button and no edit index, so set it now
        if (this.state.editing === undefined) {
            this.setState({
                editing: 0
            });

            this.raiseBeforeEdit();
            return;
        }

        if (!this.isValidGroup(this, this.state.editing)) {

            // Scroll to first invalid field when
            setTimeout(() => {
                Scroll.scroller.scrollTo('is-invalid', {
                    duration: 300,
                    smooth: true,
                    offset: -100
                });
            }, 300);

            return;
        }

        // Auto-save empty first instance if no field was entered
        if (!Array.isArray(this.props.value) || this.props.value.length === 0) {
            this.props.handleFieldFilled(null, this.props.field, [{}]);
        }

        this.raiseAfterEdit(false);

        const prevEditingIndex = this.state.editing;
        this.setState({
            editing: undefined,
            validationContext: undefined
        });

        setTimeout(() => {

            if (this.props.scrollToElement) {
                Scroll.scroller.scrollTo(this.getGroupId(prevEditingIndex));
            } else {
                Scroll.animateScroll.scrollTo({
                    duration: 300,
                    smooth: true,
                });
            }

        }, 1);

    };

    deleteGroupInstance = (index) => {

        if (!this.state.isNewRow && Array.isArray(this.props.value)) {
            let newValue = this.props.value.slice();
            newValue.splice(index, 1);
            this.props.handleFieldFilled(null, this.props.field, newValue);
        }

        this.raiseAfterEdit();

        this.setState({
            isNewRow: undefined,
            editing: undefined,
            validationContext: undefined
        });

    };

    getGroupId(index) {
        return extractFieldName(this.props.field) + index;
    };

    render() {

        if (this.props.show !== undefined && !this.props.show) {
            return null;
        }

        // if the collection has been modified outside the component, check if new row does not apply anymore
        if (this.state.isNewRow && Array.isArray(this.props.value) && this.props.value.length > this.state.editing) {

            delete this.state.isNewRow;
            delete this.state.editing;

            if (this.props.disableNavigation) {
                this.props.disableNavigation(false);
            }
        }

        let groupsCount;
        if (Array.isArray(this.props.value) && this.props.value.length > 0) {

            groupsCount = this.props.value.length;

            if (this.state.isNewRow) {
                groupsCount++;
            }

        } else {
            groupsCount = this.state.editing === undefined ? 0 : 1;
        }

        let groups = [];

        for (let i = 0; i < groupsCount; i++) {
            groups.push(
                <div key={i} name={this.getGroupId(i)} className={`row ${this.state.editing === i ? 'editing' : 'align-items-center'} ${!this.isValidGroup(null, i) ? 'row-group-invalid' : ''}`}>
                    <div className="col-12 col-sm-7">
                        {
                            this.renderChildren(i)
                        }
                        {
                            this.state.editing === i &&
                            <FormButton
                                type='group-addable-save'
                                onClick={() => this.saveGroupInstance()}
                                text={this.props.saveButtonLabel}
                            />
                        }
                    </div>
                    <div className="col-12 col-sm-5 d-flex justify-content-sm-end">
                        {
                            !this.props.disabled &&
                            this.state.editing !== i &&
                            <FormButton
                                disabled={this.state.editing !== undefined}
                                type='group-addable-edit'
                                onClick={() => this.editGroupInstance(i)}
                                text={this.props.editButtonLabel}
                            />
                        }
                        {
                            !this.props.disabled &&
                            (i > 0 || this.props.allowDeleteAll) &&
                            <FormButton
                                type='group-addable-delete'
                                onClick={() => this.deleteGroupInstance(i)}
                                text={this.props.deleteButtonLabel}
                            />
                        }
                    </div>
                </div>
            );

            if (i === 0
                && this.props.labelAfterFirst !== undefined
                && (this.props.disableAdding === undefined
                    || groupsCount > 1
                    || !this.props.disableAdding())) {

                groups.push(<FieldLabel
                    key='label-after-first'
                    label={this.props.labelAfterFirst}
                />);
            } else if (i < groupsCount - 1) {
                groups.push(<hr key={'hr' + i} />);
            }
        }

        return (
            <React.Fragment>
                {groups}
                <div className="row">
                    <div className="col-12">
                        {
                            this.state.editing === undefined &&
                            !this.props.disabled &&
                            (this.props.disableAdding === undefined || !this.props.disableAdding()) &&
                            <FormButton
                                type='group-addable-add'
                                onClick={() => this.addGroupInstance()}
                                text={this.props.addButtonLabel}
                            />
                        }
                    </div>
                </div>
                {

                    this.state.editing === undefined && !this.valid() &&

                    <Alert
                        type={ALERTTYPE.DANGER}
                        text={`Please edit the red coloured items above and complete all missing information.`}
                    />
                }

            </React.Fragment>
        );
    }
}

FieldGroupAddable.canDisableNavigation = true;

FieldGroupAddable.propTypes = {
    field: PropTypes.any.isRequired,
    handleFieldFilled: PropTypes.func.isRequired,
    editIndex: PropTypes.number,
    value: PropTypes.any,
    show: PropTypes.bool,
    addButtonLabel: PropTypes.string.isRequired,
    deleteButtonLabel: PropTypes.string.isRequired,
    editButtonLabel: PropTypes.string.isRequired,
    saveButtonLabel: PropTypes.string.isRequired,
    itemDescriber: PropTypes.func.isRequired,
    disableAdding: PropTypes.func,
    validate: PropTypes.func,
    labelAfterFirst: PropTypes.string,
    allowDeleteAll: PropTypes.bool,
    disableAutoEdit: PropTypes.bool,  // disable auto edit first element
    beforeEdit: PropTypes.func,
    afterEdit: PropTypes.func,
    scrollToElement: PropTypes.bool,
    disableNavigation: PropTypes.func
};

export default FieldGroupAddable;
