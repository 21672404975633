import * as React from "react";

import QuestionBlock from "../../../../form/QuestionBlock";
import FieldInput from "common-form/components/FieldInput";
import FieldTextArea from "../../../../form/components/FieldTextArea";
import FieldLabel from "common-form/components/FieldLabel";
import {LABEL_TYPE} from "common-form/components/Field";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldRadioGroup from "../../../../form/components/radio/FieldRadioGroup";

import {
    FIELDS, 
} from "common-hypotheca";


export default function Interest(parent, nextBlockId) {

    function isYesOrDepends(field) {
        const val = parent.getValue(field);
        return val === true || val === 'depends'
    }

    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldLabel
                    label="In order to assess the right type of interest rate for your situation, tell us your view on the following"
                />

                <FieldRadioYesNo
                    {...parent.getField(FIELDS.MM.MP.IsConcernedAboutFutureInterestMoves)}
                    labelType={LABEL_TYPE.Small}
                />

                <FieldTextArea
                    {...parent.getField(FIELDS.MM.MP.IsConcernedAboutFutureInterestMovesReason)}
                    show={() => parent.getValue(FIELDS.MM.MP.IsConcernedAboutFutureInterestMoves) === true}
                    labelType={LABEL_TYPE.Small}
                />



                <FieldRadioGroup
                    {...parent.getField(FIELDS.MM.MP.WantsToFixMortgageCostsForCertainPeriod)}
                    inline={true}
                    labelType={LABEL_TYPE.Small}
                />
                <FieldInput
                    {...parent.getField(FIELDS.MM.MP.HowLongFixMorgageCosts)}
                    labelType={LABEL_TYPE.Small}
                    show={() => parent.getValue(FIELDS.MM.MP.WantsToFixMortgageCostsForCertainPeriod) === true}
                    type="number"
                />
                <FieldTextArea
                    {...parent.getField(FIELDS.MM.MP.FixMortgageCostsReason)}
                    show={() => isYesOrDepends(FIELDS.MM.MP.WantsToFixMortgageCostsForCertainPeriod)}
                    labelType={LABEL_TYPE.Small}
                />



                <FieldRadioGroup
                    {...parent.getField(FIELDS.MM.MP.RequiresDiscountInMortgageCosts)}
                    inline={true}
                    labelType={LABEL_TYPE.Small}
                />
                <FieldInput
                    {...parent.getField(FIELDS.MM.MP.HowLongRequiresDiscountInMortgageCosts)}
                    labelType={LABEL_TYPE.Small}
                    show={() => parent.getValue(FIELDS.MM.MP.RequiresDiscountInMortgageCosts) === true}
                    type="number"
                />
                <FieldTextArea
                    {...parent.getField(FIELDS.MM.MP.RequiresDiscountInMortgageCostsReason)}
                    show={() => isYesOrDepends(FIELDS.MM.MP.RequiresDiscountInMortgageCosts)}
                    labelType={LABEL_TYPE.Small}
                />



                <FieldRadioGroup
                    {...parent.getField(FIELDS.MM.MP.HasSpecificRequirementStandardTracker)}
                    inline={true}
                    labelType={LABEL_TYPE.Small}
                />
                <FieldTextArea
                    {...parent.getField(FIELDS.MM.MP.HasSpecificRequirementStandardTrackerReason)}
                    show={() => isYesOrDepends(FIELDS.MM.MP.HasSpecificRequirementStandardTracker)}
                    labelType={LABEL_TYPE.Small}
                />



                <FieldRadioGroup
                    {...parent.getField(FIELDS.MM.MP.HasSpecificRequirementCapInterest)}
                    inline={true}
                    labelType={LABEL_TYPE.Small}
                />
                <FieldInput
                    {...parent.getField(FIELDS.MM.MP.HowLongHasRequirementCapInterest)}
                    labelType={LABEL_TYPE.Small}
                    show={() => parent.getValue(FIELDS.MM.MP.HasSpecificRequirementCapInterest) === true}
                    type="number"
                />
                <FieldTextArea
                    {...parent.getField(FIELDS.MM.MP.SpecificRequirementCapInterestReason)}
                    show={() => isYesOrDepends(FIELDS.MM.MP.HasSpecificRequirementCapInterest)}
                    labelType={LABEL_TYPE.Small}
                />



                <FieldRadioYesNo
                    {...parent.getField(FIELDS.MM.MP.ReadyForEarlyRepaymentCharges)}
                    labelType={LABEL_TYPE.Small}
                />
                
                <FieldInput
                    {...parent.getField(FIELDS.MM.MP.MaximumEarlyRepaymentChargeYears)}
                    show={() => parent.getValue(FIELDS.MM.MP.ReadyForEarlyRepaymentCharges) === true}
                    labelType={LABEL_TYPE.Small}
                    type="number"
                />

            </QuestionBlock>,


        validate: (context) => {
            context
            .mandatory(FIELDS.MM.MP.IsConcernedAboutFutureInterestMoves)
            .mandatory(FIELDS.MM.MP.WantsToFixMortgageCostsForCertainPeriod)
            .mandatory(FIELDS.MM.MP.RequiresDiscountInMortgageCosts)
            .mandatory(FIELDS.MM.MP.HasSpecificRequirementStandardTracker)
            .mandatory(FIELDS.MM.MP.HasSpecificRequirementCapInterest)
            .mandatory(FIELDS.MM.MP.ReadyForEarlyRepaymentCharges)
            ;

            context
            .mandatoryOrCleanup(parent.getValue(FIELDS.MM.MP.WantsToFixMortgageCostsForCertainPeriod) === true, FIELDS.MM.MP.HowLongFixMorgageCosts)
            .mandatoryOrCleanup(parent.getValue(FIELDS.MM.MP.RequiresDiscountInMortgageCosts) === true, FIELDS.MM.MP.HowLongRequiresDiscountInMortgageCosts)
            .mandatoryOrCleanup(parent.getValue(FIELDS.MM.MP.HasSpecificRequirementCapInterest) === true, FIELDS.MM.MP.HowLongHasRequirementCapInterest)
            .mandatoryOrCleanup(parent.getValue(FIELDS.MM.MP.ReadyForEarlyRepaymentCharges) === true, FIELDS.MM.MP.MaximumEarlyRepaymentChargeYears)
            ;

            context
            .mandatoryOrCleanup(isYesOrDepends(FIELDS.MM.MP.IsConcernedAboutFutureInterestMoves), FIELDS.MM.MP.IsConcernedAboutFutureInterestMovesReason)
            .mandatoryOrCleanup(isYesOrDepends(FIELDS.MM.MP.WantsToFixMortgageCostsForCertainPeriod), FIELDS.MM.MP.FixMortgageCostsReason)
            .mandatoryOrCleanup(isYesOrDepends(FIELDS.MM.MP.RequiresDiscountInMortgageCosts), FIELDS.MM.MP.RequiresDiscountInMortgageCostsReason)
            .mandatoryOrCleanup(isYesOrDepends(FIELDS.MM.MP.HasSpecificRequirementStandardTracker), FIELDS.MM.MP.HasSpecificRequirementStandardTrackerReason)
            .mandatoryOrCleanup(isYesOrDepends(FIELDS.MM.MP.HasSpecificRequirementCapInterest), FIELDS.MM.MP.SpecificRequirementCapInterestReason)
            ;

        },

        nextQuestionId: () => nextBlockId
    };

    
};