import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";

import {extractFieldName, FIELDS, OTHER} from "common-hypotheca";
import FieldInput from "common-form/components/FieldInput";
import FieldGroupAddable from "../../../../../form/components/FieldGroupAddable";
import FieldLabel from "common-form/components/FieldLabel";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import { FieldDropdown } from "../../../../../form/components/dropdown";
import FieldDate from "common-form/components/FieldDate";
import { LABEL_TYPE } from "../../../../../form/components/Field";

export default function OperatingLeasesList(parent, nextBlockId) {
    return {
        block: (params) =>
            <QuestionBlock {...params}>
                <FieldLabel
                    label="Please tell us about your operating lease"
                />

                <FieldGroupAddable
                    ref={params.groupAddableRef}
                    addButtonLabel="Add operating lease"
                    allowDeleteAll
                    deleteButtonLabel="Remove"
                    editButtonLabel="Edit"
                    saveButtonLabel="Save operating lease"
                    itemDescriber={(fieldsContainer) => {
                        if (fieldsContainer[extractFieldName(FIELDS.MS.CC.OperatingLeaseName)] === undefined)
                            return 'A finance from unspecified provider';
                        else
                            return 'Finance from ' + fieldsContainer[extractFieldName(FIELDS.MS.CC.OperatingLeaseName)];
                    }}
                    validate={(context) => {
                        context
                            .mandatory(FIELDS.MS.CC.OperatingLeaseName)
                            .mandatory(FIELDS.MS.CC.OperatingLeasePurpose)
                            .mandatory(FIELDS.MS.CC.OperatingLeaseAcctNum)
                            .date(FIELDS.MS.CC.OperatingLeaseOrigStartDate)
                            .optional(FIELDS.MS.CC.OperatingLeaseCurrentBal)
                            .mandatory(FIELDS.MS.CC.OperatingLeasePayEachMonth)
                            .date(FIELDS.MS.CC.OperatingLeaseFinalRepayDate)
                            .mandatory(FIELDS.MS.CC.OperatingLeasePaidOffCompletion)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.OperatingLeasePaidOffCompletion),
                                FIELDS.MS.CC.OperatingLeasePaidOffCompletionHow)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.OperatingLeasePaidOffCompletion)
                                && context.fieldValue(FIELDS.MS.CC.OperatingLeasePaidOffCompletionHow) === OTHER,
                                FIELDS.MS.CC.OperatingLeasePaidOffCompletionHowOther)
                            // Cleanup obsolete fields (if present in data)
                            .cleanupIrrelevantField(FIELDS.MS.CC.OperatingLeaseIsSecOnProperty)
                            .cleanupIrrelevantField(FIELDS.MS.CC.OperatingLeaseIsSecOnPropertyDescr);
                    }}
                    {...parent.getFormField(null, FIELDS.MS.CC.OperatingLeases)}
                >

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OperatingLeaseName}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OperatingLeasePurpose}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OperatingLeaseAcctNum}
                    />

                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OperatingLeaseOrigStartDate}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OperatingLeaseCurrentBal}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OperatingLeasePayEachMonth}
                    />

                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OperatingLeaseFinalRepayDate}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OperatingLeasePaidOffCompletion}
                    />

                    <FieldDropdown
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.OperatingLeasePaidOffCompletion)]}
                        label="How will you pay off the loan?"
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OperatingLeasePaidOffCompletionHow}
                    />

                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.OperatingLeasePaidOffCompletion)]
                            && container[extractFieldName(FIELDS.MS.CC.OperatingLeasePaidOffCompletionHow)] === OTHER}
                        field={FIELDS.MS.CC.OperatingLeasePaidOffCompletionHowOther}
                        labelType={LABEL_TYPE.Small}
                        placeholder="Other method description"
                    />

                </FieldGroupAddable>


            </QuestionBlock>,

        validate: (context) => {

            if (!context.hasItems(FIELDS.MS.CC.OperatingLeases)) {
                context.addError(null, null, "Please enter an item or go back to the previous screen and change your selection.");
            }

            if (parent.groupAddableRef && !parent.groupAddableRef.current.valid()) {
                context.addError(FIELDS.MS.CC.OperatingLeases, null, '');
            }

        },

        nextQuestionId: () => parent.nextSelectedCommitmentType(nextBlockId)
    };
}