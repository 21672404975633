export const _COUNTRY = [
    {code:'GBR',text:'United Kingdom'},
    {code:'separator', text:'------', disabled: true},
    {code:'AFG',text:'Afghanistan'},
    {code:'ALA',text:'Åland Islands'},
    {code:'ALB',text:'Albania'},
    {code:'DZA',text:'Algeria'},
    {code:'ASM',text:'American Samoa'},
    {code:'AND',text:'Andorra'},
    {code:'AGO',text:'Angola'},
    {code:'AIA',text:'Anguilla'},
    {code:'ATA',text:'Antarctica'},
    {code:'ATG',text:'Antigua and Barbuda'},
    {code:'ARG',text:'Argentina'},
    {code:'ARM',text:'Armenia'},
    {code:'ABW',text:'Aruba'},
    {code:'AUS',text:'Australia'},
    {code:'AUT',text:'Austria'},
    {code:'AZE',text:'Azerbaijan'},
    {code:'BHS',text:'Bahamas'},
    {code:'BHR',text:'Bahrain'},
    {code:'BGD',text:'Bangladesh'},
    {code:'BRB',text:'Barbados'},
    {code:'BLR',text:'Belarus'},
    {code:'BEL',text:'Belgium'},
    {code:'BLZ',text:'Belize'},
    {code:'BEN',text:'Benin'},
    {code:'BMU',text:'Bermuda'},
    {code:'BTN',text:'Bhutan'},
    {code:'BOL',text:'Bolivia, Plurinational State of'},
    {code:'BES',text:'Bonaire, Sint Eustatius and Saba'},
    {code:'BIH',text:'Bosnia and Herzegovina'},
    {code:'BWA',text:'Botswana'},
    {code:'BVT',text:'Bouvet Island'},
    {code:'BRA',text:'Brazil'},
    {code:'IOT',text:'British Indian Ocean Territory'},
    {code:'BRN',text:'Brunei Darussalam'},
    {code:'BGR',text:'Bulgaria'},
    {code:'BFA',text:'Burkina Faso'},
    {code:'BDI',text:'Burundi'},
    {code:'KHM',text:'Cambodia'},
    {code:'CMR',text:'Cameroon'},
    {code:'CAN',text:'Canada'},
    {code:'CPV',text:'Cape Verde'},
    {code:'CYM',text:'Cayman Islands'},
    {code:'CAF',text:'Central African Republic'},
    {code:'TCD',text:'Chad'},
    {code:'CHL',text:'Chile'},
    {code:'CHN',text:'China'},
    {code:'CXR',text:'Christmas Island'},
    {code:'CCK',text:'Cocos (Keeling) Islands'},
    {code:'COL',text:'Colombia'},
    {code:'COM',text:'Comoros'},
    {code:'COG',text:'Congo'},
    {code:'COD',text:'Congo, the Democratic Republic of the'},
    {code:'COK',text:'Cook Islands'},
    {code:'CRI',text:'Costa Rica'},
    {code:'CIV',text:"Côte d'Ivoire"},
    {code:'HRV',text:'Croatia'},
    {code:'CUB',text:'Cuba'},
    {code:'CUW',text:'Curaçao'},
    {code:'CYP',text:'Cyprus'},
    {code:'CZE',text:'Czech Republic'},
    {code:'DNK',text:'Denmark'},
    {code:'DJI',text:'Djibouti'},
    {code:'DMA',text:'Dominica'},
    {code:'DOM',text:'Dominican Republic'},
    {code:'ECU',text:'Ecuador'},
    {code:'EGY',text:'Egypt'},
    {code:'SLV',text:'El Salvador'},
    {code:'GNQ',text:'Equatorial Guinea'},
    {code:'ERI',text:'Eritrea'},
    {code:'EST',text:'Estonia'},
    {code:'ETH',text:'Ethiopia'},
    {code:'FLK',text:'Falkland Islands (Malvinas)'},
    {code:'FRO',text:'Faroe Islands'},
    {code:'FJI',text:'Fiji'},
    {code:'FIN',text:'Finland'},
    {code:'FRA',text:'France'},
    {code:'GUF',text:'French Guiana'},
    {code:'PYF',text:'French Polynesia'},
    {code:'ATF',text:'French Southern Territories'},
    {code:'GAB',text:'Gabon'},
    {code:'GMB',text:'Gambia'},
    {code:'GEO',text:'Georgia'},
    {code:'DEU',text:'Germany'},
    {code:'GHA',text:'Ghana'},
    {code:'GIB',text:'Gibraltar'},
    {code:'GRC',text:'Greece'},
    {code:'GRL',text:'Greenland'},
    {code:'GRD',text:'Grenada'},
    {code:'GLP',text:'Guadeloupe'},
    {code:'GUM',text:'Guam'},
    {code:'GTM',text:'Guatemala'},
    {code:'GGY',text:'Guernsey'},
    {code:'GIN',text:'Guinea'},
    {code:'GNB',text:'Guinea-Bissau'},
    {code:'GUY',text:'Guyana'},
    {code:'HTI',text:'Haiti'},
    {code:'HMD',text:'Heard Island and McDonald Islands'},
    {code:'VAT',text:'Holy See (Vatican City State)'},
    {code:'HND',text:'Honduras'},
    {code:'HKG',text:'Hong Kong'},
    {code:'HUN',text:'Hungary'},
    {code:'ISL',text:'Iceland'},
    {code:'IND',text:'India'},
    {code:'IDN',text:'Indonesia'},
    {code:'IRN',text:'Iran, Islamic Republic of'},
    {code:'IRQ',text:'Iraq'},
    {code:'IRL',text:'Ireland'},
    {code:'IMN',text:'Isle of Man'},
    {code:'ISR',text:'Israel'},
    {code:'ITA',text:'Italy'},
    {code:'JAM',text:'Jamaica'},
    {code:'JPN',text:'Japan'},
    {code:'JEY',text:'Jersey'},
    {code:'JOR',text:'Jordan'},
    {code:'KAZ',text:'Kazakhstan'},
    {code:'KEN',text:'Kenya'},
    {code:'KIR',text:'Kiribati'},
    {code:'PRK',text:"Korea, Democratic People's Republic of"},
    {code:'KOR',text:'Korea, Republic of'},
    {code:'KWT',text:'Kuwait'},
    {code:'KGZ',text:'Kyrgyzstan'},
    {code:'LAO',text:"Lao People's Democratic Republic"},
    {code:'LVA',text:'Latvia'},
    {code:'LBN',text:'Lebanon'},
    {code:'LSO',text:'Lesotho'},
    {code:'LBR',text:'Liberia'},
    {code:'LBY',text:'Libya'},
    {code:'LIE',text:'Liechtenstein'},
    {code:'LTU',text:'Lithuania'},
    {code:'LUX',text:'Luxembourg'},
    {code:'MAC',text:'Macao'},
    {code:'MKD',text:'Macedonia, the former Yugoslav Republic of'},
    {code:'MDG',text:'Madagascar'},
    {code:'MWI',text:'Malawi'},
    {code:'MYS',text:'Malaysia'},
    {code:'MDV',text:'Maldives'},
    {code:'MLI',text:'Mali'},
    {code:'MLT',text:'Malta'},
    {code:'MHL',text:'Marshall Islands'},
    {code:'MTQ',text:'Martinique'},
    {code:'MRT',text:'Mauritania'},
    {code:'MUS',text:'Mauritius'},
    {code:'MYT',text:'Mayotte'},
    {code:'MEX',text:'Mexico'},
    {code:'FSM',text:'Micronesia, Federated States of'},
    {code:'MDA',text:'Moldova, Republic of'},
    {code:'MCO',text:'Monaco'},
    {code:'MNG',text:'Mongolia'},
    {code:'MNE',text:'Montenegro'},
    {code:'MSR',text:'Montserrat'},
    {code:'MAR',text:'Morocco'},
    {code:'MOZ',text:'Mozambique'},
    {code:'MMR',text:'Myanmar'},
    {code:'NAM',text:'Namibia'},
    {code:'NRU',text:'Nauru'},
    {code:'NPL',text:'Nepal'},
    {code:'NLD',text:'Netherlands'},
    {code:'NCL',text:'New Caledonia'},
    {code:'NZL',text:'New Zealand'},
    {code:'NIC',text:'Nicaragua'},
    {code:'NER',text:'Niger'},
    {code:'NGA',text:'Nigeria'},
    {code:'NIU',text:'Niue'},
    {code:'NFK',text:'Norfolk Island'},
    {code:'MNP',text:'Northern Mariana Islands'},
    {code:'NOR',text:'Norway'},
    {code:'OMN',text:'Oman'},
    {code:'PAK',text:'Pakistan'},
    {code:'PLW',text:'Palau'},
    {code:'PSE',text:'Palestinian Territory, Occupied'},
    {code:'PAN',text:'Panama'},
    {code:'PNG',text:'Papua New Guinea'},
    {code:'PRY',text:'Paraguay'},
    {code:'PER',text:'Peru'},
    {code:'PHL',text:'Philippines'},
    {code:'PCN',text:'Pitcairn'},
    {code:'POL',text:'Poland'},
    {code:'PRT',text:'Portugal'},
    {code:'PRI',text:'Puerto Rico'},
    {code:'QAT',text:'Qatar'},
    {code:'REU',text:'Réunion'},
    {code:'ROU',text:'Romania'},
    {code:'RUS',text:'Russian Federation'},
    {code:'RWA',text:'Rwanda'},
    {code:'BLM',text:'Saint Barthélemy'},
    {code:'SHN',text:'Saint Helena, Ascension and Tristan da Cunha'},
    {code:'KNA',text:'Saint Kitts and Nevis'},
    {code:'LCA',text:'Saint Lucia'},
    {code:'MAF',text:'Saint Martin (French part)'},
    {code:'SPM',text:'Saint Pierre and Miquelon'},
    {code:'VCT',text:'Saint Vincent and the Grenadines'},
    {code:'WSM',text:'Samoa'},
    {code:'SMR',text:'San Marino'},
    {code:'STP',text:'Sao Tome and Principe'},
    {code:'SAU',text:'Saudi Arabia'},
    {code:'SEN',text:'Senegal'},
    {code:'SRB',text:'Serbia'},
    {code:'SYC',text:'Seychelles'},
    {code:'SLE',text:'Sierra Leone'},
    {code:'SGP',text:'Singapore'},
    {code:'SXM',text:'Sint Maarten (Dutch part)'},
    {code:'SVK',text:'Slovakia'},
    {code:'SVN',text:'Slovenia'},
    {code:'SLB',text:'Solomon Islands'},
    {code:'SOM',text:'Somalia'},
    {code:'ZAF',text:'South Africa'},
    {code:'SGS',text:'South Georgia and the South Sandwich Islands'},
    {code:'SSD',text:'South Sudan'},
    {code:'ESP',text:'Spain'},
    {code:'LKA',text:'Sri Lanka'},
    {code:'SDN',text:'Sudan'},
    {code:'SUR',text:'Suriname'},
    {code:'SJM',text:'Svalbard and Jan Mayen'},
    {code:'SWZ',text:'Swaziland'},
    {code:'SWE',text:'Sweden'},
    {code:'CHE',text:'Switzerland'},
    {code:'SYR',text:'Syrian Arab Republic'},
    {code:'TWN',text:'Taiwan, Province of China'},
    {code:'TJK',text:'Tajikistan'},
    {code:'TZA',text:'Tanzania, United Republic of'},
    {code:'THA',text:'Thailand'},
    {code:'TLS',text:'Timor-Leste'},
    {code:'TGO',text:'Togo'},
    {code:'TKL',text:'Tokelau'},
    {code:'TON',text:'Tonga'},
    {code:'TTO',text:'Trinidad and Tobago'},
    {code:'TUN',text:'Tunisia'},
    {code:'TUR',text:'Turkey'},
    {code:'TKM',text:'Turkmenistan'},
    {code:'TCA',text:'Turks and Caicos Islands'},
    {code:'TUV',text:'Tuvalu'},
    {code:'UGA',text:'Uganda'},
    {code:'UKR',text:'Ukraine'},
    {code:'ARE',text:'United Arab Emirates'},
    {code:'UMI',text:'United States Minor Outlying Islands (the)'},
    {code:'USA',text:'United States of America (the)'},
    {code:'URY',text:'Uruguay'},
    {code:'UZB',text:'Uzbekistan'},
    {code:'VUT',text:'Vanuatu'},
    {code:'VEN',text:'Venezuela (Bolivarian Republic of)'},
    {code:'VNM',text:'Viet Nam'},
    {code:'VGB',text:'Virgin Islands (British)'},
    {code:'VIR',text:'Virgin Islands (U.S.)'},
    {code:'WLF',text:'Wallis and Futuna'},
    {code:'ESH',text:'Western Sahara'},
    {code:'YEM',text:'Yemen'},
    {code:'ZMB',text:'Zambia'},
    {code:'ZWE',text:'Zimbabwe'},
];