export function getValuePossiblyInteger(what) {
    if (what === undefined || what === null) {
        return null;
    }
    return !isNaN(what) ? parseInt(what) : what;
}

export function possessive(what) {
    if (what.endsWith('s')) {
        return what + "'";
    } else {
        return what + "'s";
    }
}