import * as React from "react";

import QuestionBlock from "../../../../form/QuestionBlock";
import FieldInput from "common-form/components/FieldInput";
import FieldDropdown from "../../../../form/components/dropdown/FieldDropdown";
import FieldDropdownItem from "../../../../form/components/dropdown/FieldDropdownItem";
import FieldLabel from "common-form/components/FieldLabel";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldGroupAddable from "../../../../form/components/FieldGroupAddable";
import {LABEL_TYPE} from "../../../../form/components/Field";
import { ALERTTYPE, Alert } from "../../../../form/components/alert/Alert";
import LoanToValue from "../../../../form/components/LoanToValue";


import { formatCurrency } from "../../../../util/currency";


import {
    extractFieldName,
    FIELDS, 
    DEPOSIT_SOURCE_TYPE, 
    PURCHASE_STAGE
} from "common-hypotheca";


export default function BuyNewPlace(parent, nextBlockId) {
    
    const sourceTypesOptions = DEPOSIT_SOURCE_TYPE.OPTIONS
    .map(type => <FieldDropdownItem key={type.code} label={type.text} code={type.code}/>);


    function isSourceType(source, typeId) {

        if (typeof(source) === 'function') {
            return source(FIELDS.MM.MD.PurchaseDepositSource.name) === typeId;
        }

        return source[FIELDS.MM.MD.PurchaseDepositSource.name] === typeId;
    }

    function getSourceTypeDescription(source) {

        const sourceTypeId = source[FIELDS.MM.MD.PurchaseDepositSource.name];

        return sourceTypeId ? sourceTypeId === DEPOSIT_SOURCE_TYPE.OTHER 
            ? source[FIELDS.MM.MD.PurchaseDepositSourceOther.name]
            : DEPOSIT_SOURCE_TYPE.OPTIONS.find(o => o.code === sourceTypeId).text : '';
    }

    function getSourceAmountDescription(source) {
        return `${formatCurrency(source[FIELDS.MM.MD.PurchaseDepositAmount.name], 0)}`;
    }


    function getSectionDescription() {
        if (parent.isPurchaseStage(PURCHASE_STAGE.STILL_BROWSING)) {
            return 'I know you are just browsing, but please tell us a little bit more about your situation.';
        } else if (parent.isPurchaseStage(PURCHASE_STAGE.ABOUT_TO_OFFER)) {
            return 'As you are about to make an offer, please tell us more about your situation.';
        } else if (parent.isPurchaseStage(PURCHASE_STAGE.OFFER_ACCEPTED)) {
            return `Now that your offer has been accepted, it's time to tell us more about your situation.`;
        } else if (parent.isPurchaseStage(PURCHASE_STAGE.NEED_MORTGAGE)) {
            return 'Given you need a mortgage quickly, please tell us about your situation.';
        }

    }

    function getDepositSum() {
        let sum = 0;
        const arr = parent.getValue(FIELDS.MM.MD.PurchaseDeposits);
        if (arr && arr.length) {
            for(let i = 0; i < arr.length; i++) {
                const val = parseInt(arr[i][extractFieldName(FIELDS.MM.MD.PurchaseDepositAmount)], 0) || 0;
                sum += val;
            }
        }

        return sum;
    }

    function getTotalDeposit() {
        return parent.getValue(FIELDS.MM.MD.PurchasePrice) - parent.getValue(FIELDS.MM.MD.PurchaseBorrowing);
    }

    function getRemaining() {
        return getTotalDeposit() - getDepositSum();
    }

    function isValid() {
        return parent.getValue(FIELDS.MM.MD.PurchasePrice) > 0 && parent.getValue(FIELDS.MM.MD.PurchaseBorrowing) > 0;
    }

    function borrowOptionChanged(i, field, option) {
        const purchasePrice = parent.getValue(FIELDS.MM.MD.PurchasePrice);       

        if (purchasePrice && option && option !== 'custom') {
            parent.handleFilledField(null, FIELDS.MM.MD.PurchaseBorrowing, Math.round(purchasePrice * parseInt(option) / 100));
        }

        parent.handleFilledField(null, FIELDS.MM.MD.BorrowPercent, option);
    }

    function borrowAmountChanged(i, field, value) {
        parent.handleFilledField(null, FIELDS.MM.MD.BorrowPercent, 'custom');
        parent.handleFilledField(null, FIELDS.MM.MD.PurchaseBorrowing, value);
    }

    function purchasePriceChanged(i, field, value) {
        parent.handleFilledField(null, FIELDS.MM.MD.PurchasePrice, value);
        borrowOptionChanged(null, null, parent.getValue(FIELDS.MM.MD.BorrowPercent))
    }

    const borrowOptions = [60, 65, 70, 75, 80].map(o => { 
        return {
            value: o, label: `${o}%` 
        };
    });

    borrowOptions.push({ value: 'custom', label: 'Custom' });
    borrowOptions.unshift({ value: '', label: '' });

    
    if (!parent.getValue(FIELDS.MM.MD.BorrowPercent) && parent.getValue(FIELDS.MM.MD.PurchaseBorrowing)) {
        parent.updateValue(FIELDS.MM.MD.BorrowPercent, 'custom');
    }

    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldLabel
                    label="You are buying a new holiday let and need a mortgage to fund the purchase"
                />

                <FieldLabel
                    label={getSectionDescription()}
                    labelType={LABEL_TYPE.Description}
                />

                <FieldInput
                    {...parent.getField(FIELDS.MM.MD.PurchasePrice)}
                    labelType={LABEL_TYPE.Small}
                    handleFieldFilled={purchasePriceChanged}
                />

                <Alert 
                    type={ALERTTYPE.WARNING}
                    show={() => parseFloat(parent.getValue(FIELDS.MM.MD.PurchasePrice), 0) <= 50000}
                    text="Are you sure? You previously told us the property value was greater than £50,000!"
                />
    
                <FieldDropdown 
                    {...parent.getField(FIELDS.MM.MD.BorrowPercent)}
                    labelType={LABEL_TYPE.Small}
                    handleFieldFilled={borrowOptionChanged}
                    valid={false} // Remove green valid field enhancements around LTV in order not to confuse users that this LTV is OK
                >
                    {
                        borrowOptions.map((option, i) =>
                            <FieldDropdownItem key={i} code={option.value} label={option.label} />
                        )
                    }
                </FieldDropdown>

                <FieldInput
                    {...parent.getField(FIELDS.MM.MD.PurchaseBorrowing)}
                    handleFieldFilled={borrowAmountChanged}
                    show={() => parent.getValue(FIELDS.MM.MD.BorrowPercent)}
                    labelType={LABEL_TYPE.Small}
                />

                <LoanToValue 
                    label="Loan to value calculated as {0}%"
                    loan={parent.getValue(FIELDS.MM.MD.PurchaseBorrowing)}
                    value={parent.getValue(FIELDS.MM.MD.PurchasePrice)}
                />

                {
                    isValid() && 

                    <>

                        {
                            getTotalDeposit() !== 0 &&
                            
                            <>
                            <FieldLabel
                            label={`What is the source of ${formatCurrency(getTotalDeposit(), 0)} deposit?`}
                            />

                            <FieldLabel
                                label="Use the add source button below to tell us how your deposit will be made up. 
                                    Continue to add sources until you have accounted for the entire deposit amount."
                                labelType={LABEL_TYPE.Description}
                            />
                            </>
                        }

                        {

                            getRemaining() > 0 &&

                            <div className="is-invalid">
                            <Alert 
                                type={ALERTTYPE.INFO} 
                                text={`Unmatched amount of ${formatCurrency(getRemaining(), 0)}, please continue to add sources.`} 
                            />
                            </div>
                        }

                        {
                            getRemaining() < 0 &&
                        
                            <div className="is-invalid">
                            <Alert 
                                type={ALERTTYPE.DANGER} 
                                text={`Source amount now exceeds deposit by ${formatCurrency(Math.abs(getRemaining()), 0)}! Please amend.`} 
                            />
                            </div>

                        }

                        { 
                            getRemaining() === 0 &&

                            <Alert 
                                type={ALERTTYPE.SUCCESS} 
                                text={`Source of deposit is now fully matched.`} 
                            />

                        }

                        {

                            getTotalDeposit() !== 0 &&

                        <FieldGroupAddable
                            ref={params.groupAddableRef}
                            allowDeleteAll={true}
                            addButtonLabel="Add source"
                            deleteButtonLabel="Remove"
                            editButtonLabel="Edit"
                            saveButtonLabel="Save"
                            scrollToElement={true}
                            itemDescriber={(source) => {
                                return <React.Fragment>

                                    <div className="row">
                                        <div className="col-12 col-sm-6 d-flex justify-content-sm-end">
                                            {getSourceAmountDescription(source)}
                                        </div>

                                        <div className="col-12 col-sm-6 text-truncate">
                                            {getSourceTypeDescription(source)}
                                        </div>
                                    </div>

                                </React.Fragment>                        
                            }}
                            validate={(context) => {
                                context
                                    .mandatory(FIELDS.MM.MD.PurchaseDepositSource)
                                    .mandatory(FIELDS.MM.MD.PurchaseDepositAmount)
                                    .mandatoryOrCleanup(isSourceType(context.fieldValue, DEPOSIT_SOURCE_TYPE.OTHER),
                                        FIELDS.MM.MD.PurchaseDepositSourceOther)
                                    .mandatoryOrCleanup(isSourceType(context.fieldValue, DEPOSIT_SOURCE_TYPE.SALE_OF_PROPERTY),
                                        FIELDS.MM.MD.PurchaseDepositSaleofPropertyDesc)
                                    .mandatoryOrCleanup(isSourceType(context.fieldValue, DEPOSIT_SOURCE_TYPE.REMORTGAGE),
                                        FIELDS.MM.MD.PurchaseDepositRemortgageProperty)
                                    .mandatoryOrCleanup(isSourceType(context.fieldValue, DEPOSIT_SOURCE_TYPE.GIFT),
                                        [FIELDS.MM.MD.PurchaseDepositGiftFrom, FIELDS.MM.MD.PurchaseDepositGiftRepayable]);
                            }}
                            {...parent.getField(FIELDS.MM.MD.PurchaseDeposits)}
                        >

                            <FieldDropdown
                                labelType={LABEL_TYPE.Small}
                                field={FIELDS.MM.MD.PurchaseDepositSource}
                            >
                                {sourceTypesOptions}
                            </FieldDropdown>

                            <FieldInput
                                labelType={LABEL_TYPE.Small}
                                field={FIELDS.MM.MD.PurchaseDepositSourceOther}
                                show={(source) => isSourceType(source, DEPOSIT_SOURCE_TYPE.OTHER) }
                            />

                            <FieldInput
                                labelType={LABEL_TYPE.Small}
                                field={FIELDS.MM.MD.PurchaseDepositAmount}
                            />

                            <FieldInput
                                labelType={LABEL_TYPE.Small}
                                field={FIELDS.MM.MD.PurchaseDepositSaleofPropertyDesc}
                                show={(source) => isSourceType(source, DEPOSIT_SOURCE_TYPE.SALE_OF_PROPERTY) }
                            />

                            <FieldInput
                                labelType={LABEL_TYPE.Small}
                                field={FIELDS.MM.MD.PurchaseDepositRemortgageProperty}
                                show={(source) => isSourceType(source, DEPOSIT_SOURCE_TYPE.REMORTGAGE) }
                            />

                            <FieldInput
                                labelType={LABEL_TYPE.Small}
                                field={FIELDS.MM.MD.PurchaseDepositGiftFrom}
                                show={(source) => isSourceType(source, DEPOSIT_SOURCE_TYPE.GIFT) }
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                field={FIELDS.MM.MD.PurchaseDepositGiftRepayable}
                                show={(source) => isSourceType(source, DEPOSIT_SOURCE_TYPE.GIFT) }
                            />

                        </FieldGroupAddable>

                        }

                    </>
                }


            </QuestionBlock>,


        validate: (context) => {

            context
            .greaterThan(FIELDS.MM.MD.PurchasePrice, 50000, "Must be greater than £50,000")
            .greaterThan(FIELDS.MM.MD.PurchaseBorrowing, 0, "Please enter a loan amount greater than £0")
            .mandatory(FIELDS.MM.MD.PurchaseBorrowing)
            .mandatory(FIELDS.MM.MD.BorrowPercent)
            ;

            if (parent.groupAddableRef && parent.groupAddableRef.current && !parent.groupAddableRef.current.valid()) {
                context.addError(FIELDS.MM.MD.PurchaseDeposits, null, '');
            }

            if (!context.hasErrors()) {
                parent.handleFilledField(null, FIELDS.MM.MD.TotalDeposit, getTotalDeposit(), null, true);
            }

            const remaining = getRemaining();

            if (isValid()) {
                if (remaining > 0) {
                    context.addError(null, null, 'Please tell us the complete source of your deposit');
                } else if (remaining < 0) {
                    context.addError(null, null, 'You have exceeded your deposit total, please check!');
                }
            }

            parent.updateLoanToValue(
                FIELDS.MM.MD.PurchaseBorrowingLoanToValue,
                FIELDS.MM.MD.PurchaseBorrowing,
                parent.getValue(FIELDS.MM.MD.PurchasePrice));
        },

        nextQuestionId: () => nextBlockId
    };

    
};