import React from 'react';
import PropTypes from "prop-types";
import {Auth, Storage} from 'aws-amplify'
import {initializeDownloadUrls, s3FileOptions, s3FilePath} from "../../util/links";
import Loading from "../icons/Loading";


class FieldFileUpload extends React.Component {
    static propTypes = {
        field: PropTypes.string.isRequired,
        handleFieldFilled: PropTypes.func.isRequired,
        hide: PropTypes.bool,
        multi: PropTypes.bool,
        value: PropTypes.string,
        label: PropTypes.string,
        error: PropTypes.string,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        value: null,
        multi: true,
        label: 'Choose a file to upload',
        hide: false,
        disabled: false
    };

    constructor(props) {
        super(props);
        // let files = [] 
        // let filesArr = []
        // if (this.props.value) {
        //     try {
        //         filesArr = JSON.parse(this.props.value)
        //     } catch (e) {
        //         console.error(e)
        //     }
        // }
        // for(var i in filesArr){
        //     files.push({
        //         name: filesArr[i],
        //         success: true
        //     })
        // }
        let files;
        try {
            files = JSON.parse(props.value) || []
        } catch (e) {

        }
        this.state = {
            files,
            fileUrls: {},
            uploading: false,
            uploadError: undefined
        }

        // console.log('constructor field file upload', props, this.state)
    }

    componentDidMount() {
        this.loadSignedURLS(this.state.files, this.state.fileUrls);
    }

    loadSignedURLS = (files, fileUrls) => {

        initializeDownloadUrls(files, fileUrls)
            .then (() => {
                // re-render when file urls were returned by s3
                this.forceUpdate();
            });

        this.setState({
            uploading:false
        })
    };

    componentDidUpdate() {
        // console.log('update field file upload', this.props, this.state)
    }

    handleRemoveClick = (file) => {

        let files = this.state.files
        files = files.filter(fileMap => fileMap.key !== file.key);

        this.setState({
            files: files
        });

        this.updated(files)

    };

    validityClasses = () => {
        return this.props.error ? " is-invalid" : "";
    };

    onChange(e) {
        this.setState({
            uploadError: undefined
        });

        const file = e.target.files[0];
        if (!file) {
            return;
        }
        this.setState({
            uploading:true
        });
        let filename = file.name;
        let d = new Date();
        let key = `${d.getTime()}_${filename}`;

        Auth.currentAuthenticatedUser()
            .then(user => {
                return Storage
                    .put(s3FilePath(user, key), file, {
                        ...s3FileOptions(user),
                        contentType: file.type
                    })
            })
            .then(result => {
                let files = this.state.files;
                files.push({
                    key,
                    name: filename,
                    success: true,
                    uploadTime: d.getTime()
                });
                this.setState({
                    files
                });
                this.updated();
            })
            .catch(err => {
                let files = this.state.files;
                files.push({
                    key,
                    name: filename,
                    success: false,
                    error: err
                });
                this.setState({
                    uploadError: 'Unable to upload file' + (err && err.message ? ': ' + err.message : '') + '. Can you please check your connection and try again.',
                    files
                });
                this.updated();
            });
            // Finally not available on MS Edge
    }

    updated(files) {
        //let filesArr = this.state.files.map(file => file.name)
        if (!files) {
            files = this.state.files
        }

        this.props.handleFieldFilled(this.props.applicantId, this.props.field, JSON.stringify(files));

        this.loadSignedURLS(files, this.state.fileUrls);
    }

    
    renderError = () => {
        return this.props.error &&
            <div key='error-message' className="invalid-feedback">{this.props.error}</div>;
    };

    render() {
        if (this.props.hide) { return null; }

        let ref = React.createRef();

        return (
            <React.Fragment>

                <div className={`form-group document-upload ${this.validityClasses()}`}>

                    {this.state.files.filter((element) => element.success).map((file, i) =>
                        <div key={i} className='row form-group upload-row'>
                            <div className='col text-truncate'>
                                <a href={this.state.fileUrls[file.key]}
                                   target='_blank'
                                   className='document-icon uploaded'>&nbsp;</a>
                                <span className='document-filename'>{file.name}</span>
                            </div>
                            <div className='col-auto no-padding'>
                                <a href={this.state.fileUrls[file.key]} target='_blank'
                                    className='btn-view'/>
                                {
                                    !this.props.disabled &&
                                    <span className='btn-remove'
                                            onClick={() => this.handleRemoveClick(file)}>&nbsp;</span>
                                }
                            </div>

                            {
                            /*
                            <div className='col-8 '>
                                <a className='btn btn-secondary w-100' target='_blank' href={file.url}>
                                    Preview {file.name}
                                </a>

                            </div>
                            <div className='col-4'>
                                <div className='btn btn-primary w-100' onClick={() => this.handleRemoveClick(file)}>
                                    Remove
                                </div>
                            </div>
                            */
                            }
                        </div>
                    )}
                    {
                        !this.state.uploading && (this.props.multi || !this.state.files.filter((element) => element.success).length) &&
                        <div className='row upload-row file-upload-border'>
                            <div className='col less-padding'>
                                <span className="document-title">{this.props.label}</span>
                                <input disabled={this.props.disabled}
                                       type="file"
                                       className="doc-upload-file-input"
                                       id={this.props.field + this.props.applicantId}
                                       accept='application/pdf'
                                       onChange={(e) => this.onChange(e)}
                                       ref={ref}
                                />
                                {
                                    !this.props.disabled &&
                                    <label className="doc-upload-file-label"
                                           htmlFor={this.props.field + this.props.applicantId}
                                           onClick={(e) =>{ref.current.click(); e.preventDefault();}}><span>  add</span></label>
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.uploading &&
                        <div className='row upload-row'>
                            <div className='col'>
                                <Loading/>
                            </div>
                        </div>
                    }
                    {
                        this.renderError()
                    }
                    {
                        this.state.uploadError &&
                        <div key='error-message' className="invalid-feedback">{this.state.uploadError}</div>
                    }

                </div>
            </React.Fragment>
        );
    }
}



export default FieldFileUpload;
