import * as React from "react";
import QuestionBlock from "../form/QuestionBlock";
import ContactUs from "../util/ContactUs";

export default function ErrorPart(parent, nextQuestionId) {
    return {
        block: () =>
            <QuestionBlock>
                {/*
                  * we do not pass QuestionBlock params here so that no control buttons display
                  *
                  * ... at least one div is needed here as question block children are processed.
                  */}

                <div>
                    Oops.. some error has happened. Please <ContactUs link/> so that we can take a look at
                    this.
                </div>
            </QuestionBlock>
    };
};