import { connect } from 'react-redux';
import ProgressBar from "./ProgressBar";

const mapStateToProps = (state, ownProps) => ({
    progress: state.progress
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProgressBar)
