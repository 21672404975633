import React from "react";
import PropTypes from "prop-types";

const NONLINEAR_STEP = 0.0001;
const NONLINEAR_ROUNDING = 10000;
const NONLINEAR_LIMIT = 2.059;

export default class RangeSlider extends React.Component {
    static propTypes = {
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        step: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
        handleRangeChange: PropTypes.func.isRequired,
        decimals: PropTypes.bool,
        exponential: PropTypes.bool,
        exponentialSmallStepBelow: PropTypes.number
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromProps(props, state) {
        return {
            sliderMin: props.exponential ? 0 : props.min,
            sliderMax: props.exponential ? NONLINEAR_LIMIT : props.max,
            sliderStep: props.exponential ? NONLINEAR_STEP : props.step
        };
    }

    inputToSlider(value) {
        if (this.props.exponential) {
            return Math.round(Math.log1p((value - this.props.min) / (this.props.max - this.props.min) * Math.expm1(NONLINEAR_LIMIT)) * NONLINEAR_ROUNDING) / NONLINEAR_ROUNDING;
        } else {
            return value;
        }
    }

    sliderToInput(value) {
        if (this.props.exponential) {

            let nextValue = (Math.expm1(value) / Math.expm1(NONLINEAR_LIMIT) * (this.props.max - this.props.min) + this.props.min);

            let step = this.props.step;
            if (this.props.exponentialSmallStepBelow && nextValue < this.props.exponentialSmallStepBelow) {
                step = step / 2;
            }

            nextValue = Math.round(nextValue / step) * step;

            // Keyboard movement handling
            if (nextValue === this.props.value) {
                if (value > this.inputToSlider(this.props.value) && this.props.value < this.props.max) {
                    nextValue += step;
                } else if (value < this.inputToSlider(this.props.value) && this.props.value > this.props.min) {
                    nextValue -= step;
                }
            }
            return nextValue;
        } else {
            return value;
        }
    }

    onChange = (e, rangeChanged) => {
        let value = e.target.value;

        if (rangeChanged) {
            value = this.sliderToInput(value);
        } else {
            if (this.props.decimals) {
                value = parseFloat(e.target.value.replace(/,/g, ''));

                if (e.target.value.endsWith('.'))
                    value += '.';
            } else
                value = parseInt(e.target.value.replace(/,/g, ''));
        }

        if (isNaN(value))
            value = 0;

        this.props.handleRangeChange(this.props.id, value, rangeChanged);
    };

    render()
    {
        let symbol = this.props.symbol || '£';

        let value = this.inputToSlider(this.props.value);

        // Bootstrap 4: custom-range
        // Bootstrap 5: form-range

        return (
            <div className="form-group">
                <div className="row">
                    <div className="col-6 col-md-8 col-lg-7 col-xl-8 range-slider-left">
                        <label htmlFor={this.props.id}>{this.props.text}</label>
                        <input id={this.props.id}
                               type="range"
                               className="custom-range form-range"
                               onChange={(e) => this.onChange(e, true)}
                               min={this.state.sliderMin}
                               max={this.state.sliderMax}
                               step={this.state.sliderStep}
                               value={value}
                        />
                    </div>
                    <div className="col-6 col-md-4 col-lg-5 col-xl-4 range-slider-right">
                        <div className="input-group input-group-lg">
                            {
                                /* true means empty symbol */
                                this.props.symbol !== true &&
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-lg">{symbol}</span>
                                </div>
                            }
                            <input type="text"
                                   className="form-control"
                                   onChange={(e) => this.onChange(e, false)}
                                   value={this.props.value.toLocaleString('en')}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};