export const _INCOME_TYPE = {
    DIVIDENDS: 'dividends',
    MAINTENANCE: 'maintenance',
    PROFITSBUY: 'profitsBuy',
    PROFITSCOMM: 'profitsCommercial',
    PROFITSHOLIDAY: 'profitsHoliday',
    PENSION: 'pension',
    SAVINGS: 'savings',
    STATE_BENEFITS: 'stateBenefits',
    OTHER: 'other'
};

_INCOME_TYPE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: 'dividends',
        text: 'Share dividends'
    },

    {
        code: 'maintenance',
        text: 'Maintenance payments'
    },

    {
        code: 'profitsBuy',
        text: 'Pre-Tax Profits from Buy to Let'
    },

    {
        code: 'profitsCommercial',
        text: 'Pre-Tax Profits from Commercial Let'
    },

    {
        code: 'profitsHoliday',
        text: 'Pre-Tax Profits from other Holiday Lets'
    },
    
    {
        code: 'pension',
        text: 'Pension incomes'
    },

    {
        code: 'savings',
        text: 'Savings or investment Interest received'
    },

    {
        code: 'stateBenefits',
        text: 'State benefits'
    },

    {
        code: 'other',
        text: 'Other (add)'
    }
];

