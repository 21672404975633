// Aggregation of employee and self-employment subtypes for faster selection
// On another employment page
import {_SELF_EMPLOYMENT_TYPE } from "./picture_self_employment_type";
import {_EMPLOYMENT_TYPE} from "./radio_employment_type";

export const _ANOTHER_EMPLOYMENT_TYPE = {

};

_ANOTHER_EMPLOYMENT_TYPE.OPTIONS = [
    {
        code: _EMPLOYMENT_TYPE.EMPLOYEE.code,
        text: _EMPLOYMENT_TYPE.EMPLOYEE.desc,
        icon: 'supervisor_account'
    },
    {
        code: _SELF_EMPLOYMENT_TYPE.DIRECTOR,
        text: _SELF_EMPLOYMENT_TYPE.OPTIONS.filter((element) => element.code === _SELF_EMPLOYMENT_TYPE.DIRECTOR)[0].text,
        icon: 'recent_actors'
    },
    {
        code: _SELF_EMPLOYMENT_TYPE.SOLE_TRADER,
        text: _SELF_EMPLOYMENT_TYPE.OPTIONS.filter((element) => element.code === _SELF_EMPLOYMENT_TYPE.SOLE_TRADER)[0].text,
        icon: 'assignment_ind'
    },
    {
        code: _SELF_EMPLOYMENT_TYPE.PARTNER,
        text: _SELF_EMPLOYMENT_TYPE.OPTIONS.filter((element) => element.code === _SELF_EMPLOYMENT_TYPE.PARTNER)[0].text,
        icon: 'share'
    }
];