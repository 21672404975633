import React, { Fragment } from 'react';
import { API } from 'aws-amplify';

import FieldLabel from "common-form/components/FieldLabel";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldInput from 'common-form/components/FieldInput';
import { LABEL_TYPE } from 'common-form/components/Field';

import MultiBlockForm from '../../../form/MultiBlockForm';
import { FieldDropdown, FieldDropdownItem } from "../../../form/components/dropdown";
import FormButton from '../../../form/components/FormButton';
import QuestionBlock from '../../../form/QuestionBlock';
import {FieldRadioPicture} from "../../../form/components/radio";
import FormImage, {IMAGE_BEGIN} from "../../../form/components/FormImage";
import { Alert } from '../../../form/components';
import { ALERTTYPE } from '../../../form/components/alert/Alert';
import { FieldCheckbox } from '../../../form/components/checkbox'

import { getBlankApplicant } from '../../../util/models'
import { CAN_SUBMIT, APINAME, APIPATH } from '../../../constants';


import {
    APP_STATUS,
    APPLICATION_TYPE,
    FIELDS,
    SECTION,
    SCREENING,
    MARKETING_SOURCE,
    MARKETING_SOURCE_LETTING_AGENT_OTHER,
    SITUATION,
    EVENTS
} from "common-hypotheca";


import SelectPurchaseStage from './mortgagetype/SelectPurchaseStage';
import SelectMortgagePurpose from './mortgagetype/SelectMortgagePurpose';

import {intercomUpdate} from "../../../util/intercom";
import {confirmAction} from "../../../util/confirmAction";
import {globalCleanupLimitedCompany, globalCleanupMortgageType} from "../../../util/globalCleanup";
import ErrorPart from "../../ErrorPart";
import {formatMonthSortable} from "common-hypotheca/src/dates";

const BLOCK_WELCOME = 0;
const BLOCK_NAME = 1;
const BLOCK_APPLICATION_TYPE = 2;
const BLOCK_SELECT_PURCHASE_STAGE = 3;
const BLOCK_MORTGAGE_PURPOSE = 4;
const BLOCK_CONTACT = 5;
const BLOCK_LTD = 6;
const BLOCK_ADDITIONAL_APPLICANTS = 7;

class MortgageTypeAndApplicants extends MultiBlockForm {
    constructor(props) {
        super(props);

        this.state = {
            sectionId: SECTION.SCREENING,
            partId: SCREENING.MORTGAGE_TYPE_AND_APPLICANTS,

            currentApplicantId: null,

            slideIds: [BLOCK_WELCOME],
            activeSlideId: BLOCK_WELCOME

        };
    }

    componentDidMount() {
        if (!this.fieldHasValueAny(null, FIELDS.AS.MTA.PrefEmail)) {
            this.handleFilledField(null, FIELDS.AS.MTA.PrefEmail, this.props.user.email);
        }
        if (!this.fieldHasValueAny(null, FIELDS.AS.MTA.Telephone)) {
            this.handleFilledField(null, FIELDS.AS.MTA.Telephone, this.props.user.phone_number);
        }

        if (!this.fieldHasValueAny(null, FIELDS.AS.MTA.SMS)) {
            this.handleFilledField(null, FIELDS.AS.MTA.SMS, this.props.user.phone_sms || false);
        }

        if (!this.fieldHasValueAny(0, FIELDS.AS.MTA.Nickname)) {
            this.handleFilledField(0, FIELDS.AS.MTA.Nickname, this.props.user.nickname);
        }

        // default preferred contact time to anytime
        if (!this.fieldHasValueAny(null, FIELDS.AS.MTA.PrefContactTime)) {
            this.handleFilledField(null, FIELDS.AS.MTA.PrefContactTime, 'anytime');
        }

        if (!this.fieldHasValueAny(null, FIELDS.AS.MTA.MarketingSource)
            && this.props.user.referralCode !== undefined
            && this.props.user.referralCode !== null
            && MARKETING_SOURCE.AGENT_CODES[this.props.user.referralCode] !== undefined
        ) {
            this.handleFilledField(null, FIELDS.AS.MTA.MarketingSource, MARKETING_SOURCE.AGENT_CODES[this.props.user.referralCode]);
        }
    }

    /**
     * Explicitly sets application as started now we have entered the first section
     */
    setApplicationStarted = () => {
        let data = this.getData();
        if (data.appStatus === APP_STATUS.INITIAL) {
            data.appStatus = APP_STATUS.STARTED;

            // Set up application's Enquiry Management Sheet date.
            let now = Date.now();
            data.emsTimestamp = now;
            data.emsMonth = formatMonthSortable(now);

            this.setData(data);

            this.sendNotification(EVENTS.APPLICATION_STARTED, this.fieldValue(FIELDS.AS.MTA.Nickname, 0));
        }
        return true;
    };

    /**
     * @returns {number} of expected slides for this part.
     */
    slidesExpected = () => {
        return 8;
    };

    checkForCompleteState = data => {
        if (this.activeSlideId() !== BLOCK_ADDITIONAL_APPLICANTS) {
            return data;
        }

        let allApplicantsHaveNames = true;

        data.applicants.map(applicant => {
            if (!this.fieldHasValueAny(applicant.applicantId, FIELDS.AS.MTA.Nickname)) {
                allApplicantsHaveNames = false;
            }
            return applicant;
        });

        this.partContainer().isComplete = allApplicantsHaveNames;
        this.partContainer(null, SECTION.SCREENING, SCREENING.CREDIT_HISTORY_AND_RESIDENCE).isAvailable = allApplicantsHaveNames;

        // clean fields that correspond to specific applicant count
        if (this.props.adapter.applicantCount() === 1) {
            this.props.adapter.removeField(FIELDS.AS.HLC.JointIncome, null, SECTION.SCREENING, SCREENING.KEY_CRITERIA);
            this.props.adapter.removeField(FIELDS.AS.HLC.JointIncomeNotes, null, SECTION.SCREENING, SCREENING.KEY_CRITERIA);
        } else {
            this.props.adapter.removeField(FIELDS.AS.HLC.IndividualIncome, null, SECTION.SCREENING, SCREENING.KEY_CRITERIA);
            this.props.adapter.removeField(FIELDS.AS.HLC.IndividualIncomeNotes, null, SECTION.SCREENING, SCREENING.KEY_CRITERIA);
        }

        return data;
    };

    handleAddApplicant = () => {

        let data = this.getData();
        let nextApplicantId = Math.max(...data.applicants.map(applicant => applicant.applicantId)) + 1;
        let app = getBlankApplicant(nextApplicantId);
        data.applicants.push(app);

        // Add applicant will require credit history/citizenship check to be passed again
        this.partContainer(null, SECTION.SCREENING, SCREENING.CREDIT_HISTORY_AND_RESIDENCE).isComplete = false;

        // Individual income question changes to joint income.
        // Initial assessment presence means have to revisit key criteria upon delete
        if (data.applicants.length === 2
            || this.props.adapter.fieldValue(FIELDS.AS.HLC.InitialAssessment) === true) {
            this.partContainer(null, SECTION.SCREENING, SCREENING.KEY_CRITERIA).isComplete = false;
        }

        this.partContainer(null, SECTION.SCREENING, SCREENING.INITIAL_ASSESSMENT).isComplete = false;

        this.setData(data);
    };

    handleConfirmRemoveApplicant = applicantId => {
        if (this.props.adapter.fieldValue(FIELDS.MS.PD.Title, applicantId, SECTION.SITUATION, SITUATION.PERSONAL_DETAILS) === undefined) {
            // no personal details for this applicant - remove immediately
            this.handleRemoveApplicant(applicantId)
        } else {
            // personal details for this applicant - remove after confirmation
            confirmAction('Are you sure you want to remove applicant ' +
                this.props.adapter.applicantName(applicantId) +
                '? All data in personal details section will be removed for this applicant.',
                () => this.handleRemoveApplicant(applicantId));
        }
    };

    handleRemoveApplicant = applicantId => {
        let data = this.getData();
        let applicants = data.applicants.filter(applicant => applicant.applicantId !== applicantId);
        data.applicants = applicants;

        if (applicants.length < 2) {
            this.handleFilledField(null, FIELDS.AS.MTA.MortgageEqualBenefit, undefined);

            // Joint income question changes to individual income question
            this.partContainer(null, SECTION.SCREENING, SCREENING.KEY_CRITERIA).isComplete = false;
        }

        // Initial assessment presence means have to revisit key criteria upon delete
        if (this.props.adapter.fieldValue(FIELDS.AS.HLC.InitialAssessment) === true)
            this.partContainer(null, SECTION.SCREENING, SCREENING.KEY_CRITERIA).isComplete = false;

        this.setData(data)
    };

    isMobile = () => {
        let phone = this.getFieldValue(null, FIELDS.AS.MTA.Telephone) || '';
        if (phone.substring(0, 1) === '0') {
            phone = '+44' + phone.substring(1)
        }

        return phone.includes('+447');
    };

    enforcePhoneUpdate = phone => { 

        let user = this.props.user;
        user.phone_verified = false;
        user.phone_number = phone;
        user.verification_requested = false;
        this.props.setUserData(user);

        this.handleFilledField(null, FIELDS.AS.MTA.SMS, false);
        this.handleFilledField(null, FIELDS.AS.MTA.PhoneVerified, false);
        this.handleFilledField(null, FIELDS.AS.MTA.PhoneVerificationRequested, false);

        this.setState({
            verificationCode: undefined
        });

    };

    
    setStopSMS = val => {
        if (val) {

            this.setState({
                stopSms: true,
            });

            let user = this.props.user;
            user.phone_sms = true;
            this.props.setUserData(user);

        } else {

            let user = this.props.user;
            user.phone_sms = false;
            user.verification_requested = false;
            this.props.setUserData(user);

            this.setState({
                stopSms: false,
                sms: false,
                phoneVerificationError: null   
            });
        }
    };

    sendCode = async () => {

        this.setState({
            phoneVerificationError: null
        });

        let phone = this.getFieldValue(null, FIELDS.AS.MTA.Telephone) || '';
        let sms = this.getFieldValue(null, FIELDS.AS.MTA.SMS) || false;

        if (phone) {

            let user = this.props.user;
            user.verification_requested = true;
            this.props.setUserData(user);

            this.handleFilledField(null, FIELDS.AS.MTA.PhoneVerificationRequested, true);

            if (phone.substring(0, 1) === '0') {
                phone = '+44' + phone.substring(1)
            }

            await API.post(APINAME, APIPATH.PHONENUMBER.NEW, {
                body: {
                    phone: phone,
                    sms: sms
                }
            });

        }

    };

    canSubmitVerifConfirm = () => {
        return !!this.state.verificationCode
    };

    submitVerif = async () => {
        
        this.setState({
            phoneVerificationError: null
        });

        try {
            
            let resp = await API.post(APINAME, APIPATH.PHONENUMBER.SUBMITVERIFICATION, {
                body: {
                    code: parseInt(this.state.verificationCode)
                }
            });

            if (resp.success) {

                let user = this.props.user;
                user.phone_verified = true;
                this.props.setUserData(user);

                this.handleFilledField(null, FIELDS.AS.MTA.PhoneVerified, true);

            } else {

                let phoneVerificationError = resp.error;
                if (resp.error === 'code does not match') {
                    phoneVerificationError = 'That verification code does not match. Please check and try again!';
                }

                this.setState({
                    phoneVerificationError,
                    verificationCode: undefined
                });
            }

        } catch (e) {
            console.log(e)
        }
    };

    setNameUp = () => {
        let user = this.props.user;
        user.nickname = this.getFieldValue(0, FIELDS.AS.MTA.Nickname);
        this.props.setUserData(user);
        intercomUpdate(user.nickname);
    };

    getQuestion = which => {
        switch (which) {
            case BLOCK_WELCOME:
                return {
                    block:(params)=>
                        <QuestionBlock {...params} controlButtons={null}>

                            <FieldLabel
                                label='We need to ask a few questions to work out the best mortgage deal for you. It takes 5 mins, are you ready?'
                            />

                            <FieldLabel
                                label="Your Expert Advisor needs to consider several different information points in order to work out the best mortgage solution for you. By providing us with this information, we can quickly get you the answers that you want."
                                labelType={LABEL_TYPE.Small} 
                            />

                            <FormButton
                                text="I'm ready!"
                                onClick={() => this.swiperNext()}
                            />
                            <FormImage imageType={IMAGE_BEGIN}/>
                        </QuestionBlock>,
                    canNext: () => true,
                    nextQuestionId: () => BLOCK_NAME
                };

            case BLOCK_NAME:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldInput
                                label="Please provide the name by which we may refer to you during this process"
                                placeholder="e.g. John"
                                maxCharacters={15}
                                {...this.getFormField(0, FIELDS.AS.MTA.Nickname)}

                            />
                        </QuestionBlock>,
                    validate: (context) => {
                        context.mandatory(FIELDS.AS.MTA.Nickname, { applicantId: 0 });

                        if (!context.isOneFieldValidation() && !context.hasErrorsAccrued()) {
                            this.setApplicationStarted();
                            this.setNameUp();
                        }
                    },
                    nextQuestionId: () => BLOCK_APPLICATION_TYPE
                };

            case BLOCK_APPLICATION_TYPE:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldRadioPicture
                                label="Which type of mortgage you are looking for?"
                                {...this.getFormField(null, FIELDS.AS.MTA.ApplnMortgageType)}
                            />
                        </QuestionBlock>,
                    validate: (context) => {
                        context.mandatory(FIELDS.AS.MTA.ApplnMortgageType);

                        if (this.getFieldValue(null, FIELDS.AS.MTA.ApplnMortgageType) === APPLICATION_TYPE.NEW_MORTGAGE_TO_BUY.code) {
                            context.cleanupIrrelevantField(FIELDS.AS.MTA.MortgagePurpose);
                        }

                        if (this.getFieldValue(null, FIELDS.AS.MTA.ApplnMortgageType) === APPLICATION_TYPE.MORTGAGE_EXISTING_LET.code) {
                            context.cleanupIrrelevantField(FIELDS.AS.MTA.PurchaseStage);
                        }

                        // Cleanup other sections depending on selected mortgage type
                        globalCleanupMortgageType(this.props.adapter);
                    },
                    nextQuestionId: () => {
                        return this.getFieldValue(null, FIELDS.AS.MTA.ApplnMortgageType) === APPLICATION_TYPE.NEW_MORTGAGE_TO_BUY.code 
                            ? BLOCK_SELECT_PURCHASE_STAGE : BLOCK_MORTGAGE_PURPOSE;
                    }
                };

            case BLOCK_SELECT_PURCHASE_STAGE:
                return SelectPurchaseStage(this, BLOCK_CONTACT);

            case BLOCK_MORTGAGE_PURPOSE:
                return SelectMortgagePurpose(this, BLOCK_CONTACT);

            case BLOCK_CONTACT:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldLabel
                                label="As the lead applicant, can you please provide us with some contact details"
                            />

                            <FieldInput 
                                label="Your preferred UK telephone number"
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.MTA.Telephone)} 
                                handleFieldFilled={(a,f,v) => { 
                                    this.handleFilledField(a,f, v);
                                    this.enforcePhoneUpdate(v);
                                }} 
                            />

                            {
                                this.isMobile() && 
                                this.props.user.phone_sms &&
                                <Fragment> 

                                    <div className="form-group mb-2">
                                    {
                                        this.props.user.phone_verified 
                                        ? <span className="badge bg-primary">Verified</span>
                                        : <span className="badge bg-warning text-primary-emphasis">Not Verified</span>
                                    }
                                    </div>
                                </Fragment>
                            }

                            { 
                                this.isMobile() && 
                                <Fragment> 

                                    <FieldCheckbox label='Tick to verify your mobile number' 
                                        labelType={LABEL_TYPE.Small} 
                                        checked={!!this.getFieldValue(null, FIELDS.AS.MTA.SMS)} 
                                        code={!this.getFieldValue(null, FIELDS.AS.MTA.SMS)} 
                                        onCheckboxClick={val => { 

                                            this.handleFilledField(null, FIELDS.AS.MTA.SMS.name, val);
                                            this.setStopSMS(val);

                                        }} 

                                    />
                                    {
                                        this.props.user.phone_sms &&
                                        !this.props.user.verification_requested &&
                                        !this.props.user.phone_verified && 
                                        <div className="form-group"> 
                                            <FormButton text='Send verification code' onClick={() => this.sendCode()} /> 
                                        </div>
                                    }

                                    {

                                        this.props.user.phone_sms &&
                                        this.props.user.verification_requested &&
                                        !this.props.user.phone_verified && 

                                        <Fragment>
                                        
                                        <Alert text='We have sent you a verification text containing a code, please enter it below.' /> 

                                        <div className='row'>
                                            <div className='col-12'>
                                                <FieldInput 
                                                    label='Verification code for phone number'
                                                    labelType={LABEL_TYPE.Small}
                                                    value={this.state.verificationCode}
                                                    field='verificationCode'
                                                    handleFieldFilled={(a, f, verificationCode) => this.setState({ verificationCode })}
                                                />
                                            </div>
                                        </div>            
                                        <div className='form-group row'>
                                            <div className='col-lg-6 col-sm-12 mb-3'>
                                                <FormButton 
                                                    text='Submit verification code'
                                                    onClick={() => this.submitVerif()}
                                                    disabled={!this.canSubmitVerifConfirm()}
                                                    block={true} 
                                                />
                                            </div>
                                            <div className='col-lg-6 col-sm-12'>
                                                <FormButton 
                                                    block={true} 
                                                    text='Re-request verification code'
                                                    onClick={() => this.sendCode()}
                                                    secondary={true}
                                                />
                                            </div>
                                        </div>
                                        </Fragment>
                                    }

                                    {
                                        this.state.phoneVerificationError && 
                                        <Alert 
                                            text={this.state.phoneVerificationError}
                                            type={ALERTTYPE.DANGER}
                                        /> 
                                    }

                                </Fragment> 
                            } 

                            <FieldInput
                                label="Your preferred email"
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.AS.MTA.PrefEmail)}
                            />

                            <FieldDropdown
                                label="For calls, do you have a preferred contact time?"
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.AS.MTA.PrefContactTime)}
                            >
                                <FieldDropdownItem label="No, anytime" code="anytime" />
                                <FieldDropdownItem label="Morning" code="morning" />
                                <FieldDropdownItem label="Afternoon" code="afternoon" />
                            </FieldDropdown>

                            <FieldDropdown
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.AS.MTA.MarketingSource)}
                            />

                            <FieldInput
                                show={this.fieldValue(FIELDS.AS.MTA.MarketingSource) === MARKETING_SOURCE.OTHER}
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.AS.MTA.MarketingSourceOther)}
                            />

                            <FieldDropdown
                                show={this.fieldValue(FIELDS.AS.MTA.MarketingSource) === MARKETING_SOURCE.ANOTHER_HOLIDAY_LET_AGENT_REFERRAL}
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.AS.MTA.MarketingSourceOtherLettingAgent)}
                            />

                            <FieldInput
                                show={
                                    this.fieldValue(FIELDS.AS.MTA.MarketingSource) === MARKETING_SOURCE.ANOTHER_HOLIDAY_LET_AGENT_REFERRAL
                                    && this.fieldValue(FIELDS.AS.MTA.MarketingSourceOtherLettingAgent) === MARKETING_SOURCE_LETTING_AGENT_OTHER
                                }
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.AS.MTA.MarketingSourceOtherLettingAgentOther)}
                            />

                            <FieldInput
                                show={this.fieldValue(FIELDS.AS.MTA.MarketingSource) === MARKETING_SOURCE.REAL_ESTATE_AGENT_REFERRAL}
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.AS.MTA.MarketingSourceEstateAgent)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.AS.MTA.PlansSourcingAlternativeFinance)}
                            />
                            <FieldInput
                                show={this.fieldValue(FIELDS.AS.MTA.PlansSourcingAlternativeFinance) === true}
                                labelType={LABEL_TYPE.Small}
                                {...this.fieldComponentProperties(FIELDS.AS.MTA.PlansSourcingAlternativeFinanceDescription)}
                            />

                        </QuestionBlock>,
                    validate: (context) => {
                        context
                            .mandatory(FIELDS.AS.MTA.Telephone)
                            .mandatory(FIELDS.AS.MTA.PrefEmail)
                            .mandatory(FIELDS.AS.MTA.PrefContactTime)
                            .mandatory(FIELDS.AS.MTA.MarketingSource)
                            .mandatoryOrCleanup(
                                this.fieldValue(FIELDS.AS.MTA.MarketingSource) === MARKETING_SOURCE.OTHER,
                                FIELDS.AS.MTA.MarketingSourceOther
                            )
                            .mandatoryOrCleanup(
                                this.fieldValue(FIELDS.AS.MTA.MarketingSource) === MARKETING_SOURCE.ANOTHER_HOLIDAY_LET_AGENT_REFERRAL,
                                FIELDS.AS.MTA.MarketingSourceOtherLettingAgent
                            )
                            .mandatoryOrCleanup(
                                this.fieldValue(FIELDS.AS.MTA.MarketingSourceOtherLettingAgent) === MARKETING_SOURCE_LETTING_AGENT_OTHER,
                                FIELDS.AS.MTA.MarketingSourceOtherLettingAgentOther
                            )
                            .mandatoryOrCleanup(
                                this.fieldValue(FIELDS.AS.MTA.MarketingSource) === MARKETING_SOURCE.REAL_ESTATE_AGENT_REFERRAL,
                                FIELDS.AS.MTA.MarketingSourceEstateAgent
                            )
                            .mandatory(FIELDS.AS.MTA.PlansSourcingAlternativeFinance)
                            .mandatoryOrCleanup(
                                this.fieldValue(FIELDS.AS.MTA.PlansSourcingAlternativeFinance) === true,
                                FIELDS.AS.MTA.PlansSourcingAlternativeFinanceDescription
                            )
                            ;
   
                        if (this.isMobile() && this.fieldHasValueTrue(null, FIELDS.AS.MTA.SMS) && !this.props.user.phone_verified) {
                            context.addError(null, null, 'Please verify your mobile number, or untick the SMS box');
                        }
                    },

                    nextQuestionId: () => BLOCK_LTD,
                };

            case BLOCK_LTD:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldRadioYesNo
                                tooltip='Answer yes if the property to be mortgaged will be owned by a Limited Company.'
                                {...this.fieldComponentProperties(FIELDS.AS.MTA.ApplicationLimitedCompany)}
                            />

                        </QuestionBlock>,
                    validate: (context) => {
                        context.mandatory(FIELDS.AS.MTA.ApplicationLimitedCompany);
                        globalCleanupLimitedCompany(this.props.adapter);
                    },
                    nextQuestionId: () => BLOCK_ADDITIONAL_APPLICANTS
                };

            case BLOCK_ADDITIONAL_APPLICANTS:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>

                            <FieldLabel 
                                label={this.getApplicantName() + ', please tell us about any other applicants to this mortgage'}
                            />

                            <FieldLabel
                                show={this.fieldValue(FIELDS.AS.MTA.ApplicationLimitedCompany) === false}
                                labelType={LABEL_TYPE.Description}
                                label='Use the + Add applicant button below to add up to 3 additional applicants. We will contact each them, via email, to confirm their agreement to our terms.'
                            />

                            <FieldLabel
                                show={this.fieldValue(FIELDS.AS.MTA.ApplicationLimitedCompany) === true}
                                labelType={LABEL_TYPE.Description}
                                label='With Limited Company applications, Directors are still assessed as individuals. Please use the + Add applicant button below to add any additional Directors up to a maximum of 3. We will contact each of them, via email, to confirm their agreement to our terms. We will gather details of the Limited Company later on in the process.'
                            />

                            {this.props.data.applicants.map((applicant, i) =>
                                i > 0 &&
                                <div key={applicant.applicantId} className="row">
                                    <div className="col-12 col-sm-8">

                                        <div className="form-group">
                                            <FieldInput
                                                labelType={LABEL_TYPE.Small}
                                                label={`Applicant ${i+1} nickname`}
                                                placeholder="e.g. John"
                                                {...this.getFormField(applicant.applicantId, FIELDS.AS.MTA.Nickname)}
                                            />
                                        </div>

                                    </div>
                                    <div className="col-12 col-sm-4 text-right">
                                        {
                                            !this.getReadOnly() &&
                                            <FormButton
                                                type='applicant-add-remove'
                                                className='p-0'
                                                text='Remove'
                                                onClick={() => this.handleConfirmRemoveApplicant(applicant.applicantId)}
                                            />
                                        }
                                    </div>
                                </div>
                            )}
                            {
                                this.fieldValue(FIELDS.AS.MTA.ApplicationLimitedCompany) !== undefined &&
                                !this.getReadOnly() &&
                                this.props.data.applicants.length < 4 &&
                                <div className="form-group mb-5">
                                    <FormButton
                                        type='applicant-add-add'
                                        onClick={() => this.handleAddApplicant()}
                                        text='Add applicant'
                                    />
                                </div>
                            }
                            <FieldInput
                                show={this.props.data.applicants.length > 1}
                                labelType={LABEL_TYPE.Small}
                                label='What is the relationship between the applicants?'
                                {...this.getFormField(null, FIELDS.AS.MTA.ApplicantRelationships)}
                            />

                            
                        </QuestionBlock>,

                    validate: (context) => {
                        context.mandatoryOrCleanup(
                            this.props.adapter.applicantCount() > 1,
                            FIELDS.AS.MTA.ApplicantRelationships);

                        // we want to validate additional applicants
                        for (let i = 1; i < this.props.data.applicants.length; i++) {

                            const applicant = this.props.data.applicants[i];

                            context
                            .mandatory(FIELDS.AS.MTA.Nickname, { applicantId: applicant.applicantId })
                            ;
                        }

                        // Remove obsolete fields
                        context
                            .cleanupIrrelevantField(FIELDS.AS.MTA.MortgageEqualBenefit)
                            .cleanupIrrelevantField(FIELDS.AS.MTA.MortgageEqualBenefitExpln);
                    },
                    nextQuestionId: () => CAN_SUBMIT
                };

            default:
                return ErrorPart();
        }
    }
}

export default MortgageTypeAndApplicants;
