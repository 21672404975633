import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";

import {extractFieldName, FIELDS, OTHER} from "common-hypotheca";
import FieldInput from "common-form/components/FieldInput";
import FieldGroupAddable from "../../../../../form/components/FieldGroupAddable";
import FieldLabel from "common-form/components/FieldLabel";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldDate from "common-form/components/FieldDate";
import { FieldDropdown } from "../../../../../form/components/dropdown";
import { LABEL_TYPE } from "common-form/components/Field";

export default function OtherPurchaseList(parent, nextBlockId) {

    return {
        block: (params)=>
            <QuestionBlock {...params}>
                <FieldLabel
                    label="Please tell us about your credit purchases"
                />

                <FieldGroupAddable
                    ref={params.groupAddableRef}
                    addButtonLabel="Add other credit purchase"
                    allowDeleteAll
                    deleteButtonLabel="Remove"
                    editButtonLabel="Edit"
                    saveButtonLabel="Save credit purchase"
                    itemDescriber={(fieldsContainer) => {
                        if (fieldsContainer[extractFieldName(FIELDS.MS.CC.OtherCreditName)] === undefined)
                            return 'A credit from unspecified provider';
                        else
                            return 'Credit from ' + fieldsContainer[extractFieldName(FIELDS.MS.CC.OtherCreditName)];
                    }}
                    validate={(context) => {
                        context
                            .mandatory(FIELDS.MS.CC.OtherCreditName)
                            .mandatory(FIELDS.MS.CC.OtherCreditPurpose)
                            .mandatory(FIELDS.MS.CC.OtherCreditAcctNum)
                            .mandatory(FIELDS.MS.CC.OtherCreditOrigBorrowAmt)
                            .date(FIELDS.MS.CC.OtherCreditOrigStartDate)
                            .mandatory(FIELDS.MS.CC.OtherCreditCurrentBal)
                            .mandatory(FIELDS.MS.CC.OtherCreditPayEachMonth)
                            .date(FIELDS.MS.CC.OtherCreditFinalRepayDate)
                            .mandatory(FIELDS.MS.CC.OtherCreditPaidOffCompletion)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.OtherCreditPaidOffCompletion),
                                FIELDS.MS.CC.OtherCreditPaidOffCompletionHow)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.OtherCreditPaidOffCompletion)
                                && context.fieldValue(FIELDS.MS.CC.OtherCreditPaidOffCompletionHow) === OTHER,
                                FIELDS.MS.CC.OtherCreditPaidOffCompletionHowOther
                            )
                            // Cleanup obsolete fields (if present in data)
                            .cleanupIrrelevantField(FIELDS.MS.CC.OtherCreditCurrentIntRate)
                            .cleanupIrrelevantField(FIELDS.MS.CC.OtherCreditIsSecOnProperty)
                            .cleanupIrrelevantField(FIELDS.MS.CC.OtherCreditIsSecOnPropertyDescr)
                        ;
                    }}
                    {...parent.getFormField(null, FIELDS.MS.CC.OtherCredits)}
                >

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherCreditName}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherCreditPurpose}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherCreditAcctNum}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherCreditOrigBorrowAmt}
                    />

                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherCreditOrigStartDate}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherCreditCurrentBal}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherCreditPayEachMonth}
                    />

                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherCreditFinalRepayDate}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherCreditPaidOffCompletion}
                    />

                    <FieldDropdown
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.OtherCreditPaidOffCompletion)] }
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherCreditPaidOffCompletionHow}
                    />

                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.OtherCreditPaidOffCompletion)]
                            && container[extractFieldName(FIELDS.MS.CC.OtherCreditPaidOffCompletionHow)] === OTHER }
                        field={FIELDS.MS.CC.OtherCreditPaidOffCompletionHowOther}
                        labelType={LABEL_TYPE.Small}
                        placeholder="Other method description"
                    />

                </FieldGroupAddable>


            </QuestionBlock>,

        validate: (context) => {

            if (!context.hasItems(FIELDS.MS.CC.OtherCredits)) {
                context.addError(null, null, "Please enter an item or go back to the previous screen and change your selection.");
            }

            if (parent.groupAddableRef && !parent.groupAddableRef.current.valid()) {
                context.addError(FIELDS.MS.CC.OtherCredits, null, '');
            }

        },

        nextQuestionId: () => parent.nextSelectedCommitmentType(nextBlockId)
    };

}