export const _ASSET_TYPE = {
    SHARES: 'shares',
    BONDS: 'bonds',
    OTHER_INVESTMENT: 'other-investment',
    LOANS_OWNED: 'loans-owned',
    PENSION: 'pension',
    PERSONAL_ASSETS: 'personal-assets',
    SAVINGS: 'savings',
    OTHER: 'other',
};

_ASSET_TYPE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: _ASSET_TYPE.SHARES,
        text: 'Shares'
    },
    {
        code: _ASSET_TYPE.BONDS,
        text: 'Bonds'
    },
    {
        code: _ASSET_TYPE.OTHER_INVESTMENT,
        text: 'Other investment types'
    },
    {
        code: _ASSET_TYPE.LOANS_OWNED,
        text: 'Loans owned to you'
    },
    {
        code: _ASSET_TYPE.PENSION,
        text: 'Pension'
    },
    {
        code: _ASSET_TYPE.PERSONAL_ASSETS,
        text: 'Significant personal assets'
    },
    {
        code: _ASSET_TYPE.SAVINGS,
        text: 'Savings'
    },
    {
        code: _ASSET_TYPE.OTHER,
        text: 'Other (add)'
    }
];