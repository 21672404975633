import * as React from "react";
import {Fragment} from "react";

import FieldLabel from "common-form/components/FieldLabel";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import {LABEL_TYPE} from "common-form/components/Field";
import FieldInput from "common-form/components/FieldInput";

import QuestionBlock from "../../../form/QuestionBlock";
import MultiBlockForm from "../../../form/MultiBlockForm";
import FieldTextArea from "../../../form/components/FieldTextArea";
import FormImage, {IMAGE_SOMETHING_GREAT} from "../../../form/components/FormImage";
import FieldRadioPicture from "../../../form/components/radio/FieldRadioPicture";
import FieldDropdown from "../../../form/components/dropdown/FieldDropdown";

import { CAN_SUBMIT } from "../../../constants";
import {confirmAction} from "../../../util/confirmAction";

import {
    EVENTS,
    FIELDS,
    SECTION,
    SCREENING,
    INITIAL_ASSESSMENT, EMPLOYMENT_TYPE
} from "common-hypotheca";
import ErrorPart from "../../ErrorPart";
import {LtvBlock} from "../../assembly/LtvBlock";


const BLOCK_INITIAL_VIABILITY_ASSESSMENT_PROPOSAL = 1;
const BLOCK_INITIAL_VIABILITY_ASSESSMENT = 2;
const BLOCK_INITIAL_VIABILITY_ASSESSMENT_DONE = 3;

class InitialAssessment extends MultiBlockForm {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            sectionId: SECTION.SCREENING,
            partId: SCREENING.INITIAL_ASSESSMENT,

            slideIds: [BLOCK_INITIAL_VIABILITY_ASSESSMENT_PROPOSAL],
            activeSlideId: BLOCK_INITIAL_VIABILITY_ASSESSMENT_PROPOSAL,

            currentApplicantId: null,

            ltvFunctions: props.adapter.ltvFunctions({
                sectionId: SECTION.SCREENING,
                partId: SCREENING.INITIAL_ASSESSMENT,
                propertyValueField: FIELDS.AS.HLC.PropertyToMortgageEstimatedValue,
                borrowPercentField: FIELDS.AS.HLC.InitialAssessmentLtv,
                borrowAmountField: FIELDS.AS.HLC.BorrowAmountRequested
            })
        };
    }

    /**
     * @returns {number} of expected slides for this part.
     */
    slidesExpected = () => {
        return 3;
    };

    checkForCompleteState = data => {
        this.partContainer().isComplete = true;

        // assessment copy temporarily disabled per business decission
        //
        // NB. if this is ever brought back then reconsider/fix globalCleanup on mortgage type/purpose change
        //
        // doAssessmentCopy(this.props.adapter);

        return data;
    };

    getNextLabel = () => {
        return this.activeSlideId() === BLOCK_INITIAL_VIABILITY_ASSESSMENT_DONE ? 'Done' : null;
    };

    getQuestion = which => {

        switch(which) {

            case BLOCK_INITIAL_VIABILITY_ASSESSMENT_PROPOSAL:
                return {

                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldLabel
                                label={`${this.getApplicantName()}, let's do a quick initial mortgage assessment`}
                            />
                            <FieldLabel
                                labelType={LABEL_TYPE.Description}
                                label="Select initial assessment below and answer a few key questions on the next screen, 
                                then one of our Expert Advisors will take a quick look at your situation and give you an early opinion on the viability of your application."
                            />

                            <FieldRadioPicture {...this.getFormField(null, FIELDS.AS.HLC.InitialAssessment)}/>

                        </QuestionBlock>,
                    validate: (context) => {
                        context.mandatory(FIELDS.AS.HLC.InitialAssessment);

                        if (this.getFieldValue(null, FIELDS.AS.HLC.InitialAssessment) === INITIAL_ASSESSMENT.INITIAL_ASSESSMENT
                            && !this.fieldHasValueAny(null, FIELDS.AS.HLC.BorrowAmountRequested)) {

                            this.props.adapter.updateField(
                                false,
                                FIELDS.AS.HLC.InitialAssessmentBypassed,
                                undefined,
                                this.getCurrentSectionId(),
                                this.getCurrentPartId());

                            // User has selected initial assessment but data doesn't contain next page's mandatory field
                            this.partContainer().isComplete = false;
                        }

                        if (this.getFieldValue(null, FIELDS.AS.HLC.InitialAssessment) === INITIAL_ASSESSMENT.ALREADY_DONE) {
                            
                            //mark part as complete
                            this.partContainer().isComplete = true;

                            // always move to next section after clicking on already done
                            this.state.moveToNext = true;
                        }
                    },

                    beforeNext: (next) => {

                        // if no data, ask and prevent going next if 'No'
                        if (this.getFieldValue(null, FIELDS.AS.HLC.InitialAssessment) === INITIAL_ASSESSMENT.ALREADY_DONE 
                            && !this.fieldHasValueTrue(null, FIELDS.AS.HLC.InitialAssessmentValid)) {

                            confirmAction('It looks like the Initial Assessment page is incomplete. Are you sure you want to proceed?', 
                            () => {

                                this.props.adapter.updateField(
                                    true,
                                    FIELDS.AS.HLC.InitialAssessmentBypassed,
                                    undefined,
                                    this.getCurrentSectionId(),
                                    this.getCurrentPartId());
                                next();
                            });

                        } else {
                            next();
                        }

                    },

                    nextQuestionId: () => {
                        return this.getFieldValue(null, FIELDS.AS.HLC.InitialAssessment) === INITIAL_ASSESSMENT.INITIAL_ASSESSMENT
                            ? BLOCK_INITIAL_VIABILITY_ASSESSMENT
                            : CAN_SUBMIT;
                    }
                };

            case BLOCK_INITIAL_VIABILITY_ASSESSMENT:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldLabel
                                label='So we can undertake an initial assessment for you, please complete the following'
                            />
                            <FieldLabel
                                labelType={LABEL_TYPE.Description}
                                label="This information will allow an expert advisor to sense check your situation so we can quickly give you feedback as to the likely success of your proposed application. The information can be approximate, but try to be as accurate as you can."
                            />

                            {
                                this.props.data.applicants &&
                                this.props.data.applicants.map((applicant, i) => {

                                    return <Fragment key={'applicant-' + i}>

                                        <FieldLabel 
                                            label={this.props.adapter.applicantName(applicant.applicantId)}
                                        />

                                        <FieldRadioYesNo
                                            labelType={LABEL_TYPE.Small}
                                            {...this.getFormField(applicant.applicantId, FIELDS.AS.HLC.LivedInUK3Years)}
                                        />

                                        <FieldRadioYesNo
                                            labelType={LABEL_TYPE.Small}
                                            show={this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.LivedInUK3Years) === false}
                                            {...this.getFormField(applicant.applicantId, FIELDS.AS.HLC.AreYouAnExpat)}
                                        />

                                        <FieldInput
                                            labelType={LABEL_TYPE.Small}
                                            show={
                                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.LivedInUK3Years) === false && 
                                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.AreYouAnExpat) === true
                                            }
                                            {...this.getFormField(applicant.applicantId, FIELDS.AS.HLC.WhereCurrentlyLive)}
                                        />
                                        
                                        <FieldRadioYesNo
                                            labelType={LABEL_TYPE.Small}
                                            show={
                                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.LivedInUK3Years) === false && 
                                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.AreYouAnExpat) === true
                                            }
                                            {...this.getFormField(applicant.applicantId, FIELDS.AS.HLC.HasAnyUkAddressOrUkBank)}
                                        />

                                        <FieldRadioYesNo
                                            labelType={LABEL_TYPE.Small}
                                            show={
                                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.LivedInUK3Years) === false && 
                                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.AreYouAnExpat) === true && 
                                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.HasAnyUkAddressOrUkBank) === true
                                            }
                                            {...this.getFormField(applicant.applicantId, FIELDS.AS.HLC.HasMortgageInUk)}
                                        />

                                        <FieldDropdown
                                            labelType={LABEL_TYPE.Small}
                                            tooltip="If you operate via a Limited Company and own 20% or more of its shares, then please select Self-employed."
                                            {...this.getFormField(applicant.applicantId, FIELDS.AS.HLC.KeyCriteriaEmploymentType)}
                                        />

                                        <FieldInput
                                            labelType={LABEL_TYPE.Small}
                                            show={this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.KeyCriteriaEmploymentType) === EMPLOYMENT_TYPE.EMPLOYEE.code}
                                            {...this.getFormField(applicant.applicantId, FIELDS.AS.HLC.BasicSalary)}
                                        />
                                        <FieldInput
                                            labelType={LABEL_TYPE.Small}
                                            show={this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.KeyCriteriaEmploymentType) === EMPLOYMENT_TYPE.EMPLOYEE.code}
                                            {...this.getFormField(applicant.applicantId, FIELDS.AS.HLC.GuaranteedEmploymentBenefits)}
                                        />
                                        <FieldInput
                                            labelType={LABEL_TYPE.Small}
                                            show={this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.KeyCriteriaEmploymentType) === EMPLOYMENT_TYPE.EMPLOYEE.code}
                                            {...this.getFormField(applicant.applicantId, FIELDS.AS.HLC.NotGuaranteedEmploymentBenefits)}
                                        />

                                        <FieldDropdown
                                            labelType={LABEL_TYPE.Small}
                                            show={this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.KeyCriteriaEmploymentType) === EMPLOYMENT_TYPE.SELF_EMPLOYED.code}
                                            {...this.getFormField(applicant.applicantId, FIELDS.AS.HLC.TradingResultsYearsAvailable)}
                                        />
                                        <FieldInput
                                            labelType={LABEL_TYPE.Small}
                                            show={this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.KeyCriteriaEmploymentType) === EMPLOYMENT_TYPE.SELF_EMPLOYED.code}
                                            {...this.getFormField(applicant.applicantId, FIELDS.AS.HLC.TradingResultsLast2Years)}
                                        />

                                        <FieldInput
                                            labelType={LABEL_TYPE.Small}
                                            {...this.getFormField(applicant.applicantId, FIELDS.AS.HLC.OtherVerifiableRegularIncome)}
                                        />

                                    </Fragment>;
                                })
                            }

                            <FieldLabel label='Key mortgage questions'/>

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.HasAdditionalProperties)}
                            />
                            
                            {
                                this.fieldHasValueTrue(null, FIELDS.AS.HLC.HasAdditionalProperties) &&
                                <>
                                <FieldInput
                                    labelType={LABEL_TYPE.Small}
                                    {...this.getFormField(null, FIELDS.AS.HLC.NumberOfBuyToLets)}
                                />
                                <FieldInput
                                    labelType={LABEL_TYPE.Small}
                                    {...this.getFormField(null, FIELDS.AS.HLC.NumberOfHolidayLets)}
                                />
                                <FieldInput
                                    labelType={LABEL_TYPE.Small}
                                    {...this.getFormField(null, FIELDS.AS.HLC.NumberOtherProperties)}
                                />
                                </>
                            }

                            <FieldInput
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.EstimatedMonthlyCreditCommitments)}
                            />

                            <LtvBlock
                                form={this}
                                propertyValueField={FIELDS.AS.HLC.PropertyToMortgageEstimatedValue}
                                borrowPercentField={FIELDS.AS.HLC.BorrowPercentRequested}
                                borrowAmountField={FIELDS.AS.HLC.BorrowAmountRequested}
                            />

                            <FieldTextArea
                                labelType={LABEL_TYPE.Small}
                                show={this.props.adapter.isMortgageToBuyNewPlace()}
                                {...this.getFormField(null, FIELDS.AS.HLC.DepositPurchaseWillComeFrom)}
                            />

                            {
                                this.props.adapter.isReplaceExistingMortgage() && 

                                <>
                                <FieldRadioYesNo
                                    labelType={LABEL_TYPE.Small}
                                    {...this.getFormField(null, FIELDS.AS.HLC.WantsReplaceExistingMortgageLikeToLike)}
                                />

                                <FieldTextArea
                                    labelType={LABEL_TYPE.Small}
                                    show={this.getFieldValue(null, FIELDS.AS.HLC.WantsReplaceExistingMortgageLikeToLike) === false}
                                    {...this.getFormField(null, FIELDS.AS.HLC.WantsReplaceExistingMortgageLikeToLikeExplanation)}
                                />
                                </>

                            }

                            <FieldTextArea
                                labelType={LABEL_TYPE.Small}
                                show={this.props.adapter.isBrandNewMortgage()}
                                {...this.getFormField(null, FIELDS.AS.HLC.ReasonsForNewMortgage)}
                            />

                            <FieldDropdown
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.MortgageTypePreference)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.HolidayLetMoreThanOneSelfContainedProperty)}
                            />

                            <FieldRadioYesNo
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.TradingAsHolidayLet)}
                            />

                            <FieldInput
                                labelType={LABEL_TYPE.Small}
                                show={this.fieldHasValueTrue(null, FIELDS.AS.HLC.TradingAsHolidayLet)}
                                {...this.getFormField(null, FIELDS.AS.HLC.Exist12MGrossRentalIncome)}
                            />

                            <FieldInput
                                labelType={LABEL_TYPE.Small}
                                show={this.fieldHasValueFalse(null, FIELDS.AS.HLC.TradingAsHolidayLet)}
                                {...this.getFormField(null, FIELDS.AS.HLC.Next12MEstimatedGrossRentalIncome)}
                            />
                            
                            <FieldTextArea
                                labelType={LABEL_TYPE.Small}
                                {...this.getFormField(null, FIELDS.AS.HLC.PropertyMortgageDetails)}
                            />

                            {
                                this.props.adapter.isBrandNewMortgage() &&
                                <>
                                    <FieldDropdown
                                        labelType={LABEL_TYPE.Small}
                                        {...this.getFormField(null, FIELDS.AS.HLC.DesiredTransactionSpeed)}
                                    />
                                </>
                            }

                            {
                                this.props.adapter.isReplaceExistingMortgage() &&
                                <>
                                    <FieldRadioYesNo
                                        labelType={LABEL_TYPE.Small}
                                        {...this.getFormField(null, FIELDS.AS.HLC.IsThereDeadline)}
                                    />

                                    <FieldTextArea
                                        show={this.fieldHasValueTrue(null, FIELDS.AS.HLC.IsThereDeadline)}
                                        placeholder="e.g. I will move on to the standard variable rate of 5% at the end of the August!"
                                        {...this.getFormField(null, FIELDS.AS.HLC.IsThereDeadlineExplanation)}
                                    />
                                </>
                            }

                            <FieldTextArea
                                labelType={LABEL_TYPE.Small}
                                tooltip="e.g. lenders that you have already approached, unusual circumstances, gifted deposit, non-standard construction ..."
                                {...this.getFormField(null, FIELDS.AS.HLC.AssessmentHelpfulDetails)}
                            />

                        </QuestionBlock>,
                    validate: (context) => {

                        for (let applicant of this.props.data.applicants) {
                            let params = {
                                applicantId: applicant.applicantId
                            };

                            context.mandatory(FIELDS.AS.HLC.LivedInUK3Years, params);

                            context.mandatoryOrCleanup(
                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.LivedInUK3Years) === false,
                                FIELDS.AS.HLC.AreYouAnExpat,
                                params
                            );

                            
                            context.mandatoryOrCleanup(
                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.LivedInUK3Years) === false && 
                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.AreYouAnExpat) === true,
                                FIELDS.AS.HLC.WhereCurrentlyLive,
                                params
                            );
                            
                            context.mandatoryOrCleanup(
                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.LivedInUK3Years) === false && 
                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.AreYouAnExpat) === true,
                                FIELDS.AS.HLC.HasAnyUkAddressOrUkBank,
                                params
                            );
                            
                            context.mandatoryOrCleanup(
                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.LivedInUK3Years) === false && 
                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.AreYouAnExpat) === true && 
                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.HasAnyUkAddressOrUkBank) === true,
                                FIELDS.AS.HLC.HasMortgageInUk,
                                params
                            );

                            context.mandatory(FIELDS.AS.HLC.KeyCriteriaEmploymentType, params);

                            context.mandatoryOrCleanup(
                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.KeyCriteriaEmploymentType) === EMPLOYMENT_TYPE.EMPLOYEE.code,
                                FIELDS.AS.HLC.BasicSalary,
                                params);

                            context.mandatoryOrCleanup(
                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.KeyCriteriaEmploymentType) === EMPLOYMENT_TYPE.SELF_EMPLOYED.code,
                                FIELDS.AS.HLC.TradingResultsYearsAvailable,
                                params);

                            context.mandatoryOrCleanup(
                                this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.KeyCriteriaEmploymentType) === EMPLOYMENT_TYPE.SELF_EMPLOYED.code,
                                FIELDS.AS.HLC.TradingResultsLast2Years,
                                params);

                            if (this.getFieldValue(applicant.applicantId, FIELDS.AS.HLC.KeyCriteriaEmploymentType) === EMPLOYMENT_TYPE.SELF_EMPLOYED.code) {
                                context.cleanupIrrelevantField(FIELDS.AS.HLC.GuaranteedEmploymentBenefits, params);
                                context.cleanupIrrelevantField(FIELDS.AS.HLC.NotGuaranteedEmploymentBenefits, params);
                            }

                            context.optional(FIELDS.AS.HLC.OtherVerifiableRegularIncome, params);
                        }

                        context
                            .optional(FIELDS.AS.HLC.EstimatedMonthlyCreditCommitments)
                            .mandatory(FIELDS.AS.HLC.PropertyToMortgageEstimatedValue)
                            .mandatory(FIELDS.AS.HLC.BorrowAmountRequested)
                            .mandatory(FIELDS.AS.HLC.HolidayLetMoreThanOneSelfContainedProperty)
                            .mandatory(FIELDS.AS.HLC.TradingAsHolidayLet)
                            ;


                        this.props.adapter.updateLoanToValue(
                            FIELDS.AS.HLC.InitialAssessmentLtv,
                            FIELDS.AS.HLC.BorrowAmountRequested,
                            this.fieldValue(FIELDS.AS.HLC.PropertyToMortgageEstimatedValue),
                            this.state.sectionId,
                            this.state.partId
                        );

                        if (this.fieldHasValueTrue(this.props.applicantId, FIELDS.AS.HLC.TradingAsHolidayLet)) {
                            context
                                .mandatory(FIELDS.AS.HLC.Exist12MGrossRentalIncome)
                                .cleanupIrrelevantField(FIELDS.AS.HLC.Next12MEstimatedGrossRentalIncome);
                        }

                        if (this.fieldHasValueFalse(this.props.applicantId, FIELDS.AS.HLC.TradingAsHolidayLet)) {
                            context
                                .mandatory(FIELDS.AS.HLC.Next12MEstimatedGrossRentalIncome)
                                .cleanupIrrelevantField(FIELDS.AS.HLC.Exist12MGrossRentalIncome);
                        }

                        context
                            .mandatoryOrCleanup(this.fieldValueTrue(FIELDS.AS.HLC.HasAdditionalProperties), [
                                FIELDS.AS.HLC.NumberOfBuyToLets,
                                FIELDS.AS.HLC.NumberOfHolidayLets,
                            ])
                            .optionalOrCleanup(this.fieldValueTrue(FIELDS.AS.HLC.HasAdditionalProperties),
                                FIELDS.AS.HLC.NumberOtherProperties);

                        context
                            .mandatory(FIELDS.AS.HLC.HasAdditionalProperties)
                            .cleanupIrrelevantField(FIELDS.AS.HLC.IsApplicationViaLimitedCompany)
                            .mandatory(FIELDS.AS.HLC.PropertyMortgageDetails)
                            .mandatory(FIELDS.AS.HLC.MortgageTypePreference)
                            .optional(FIELDS.AS.HLC.AssessmentHelpfulDetails)
                        ;

                        context
                        .mandatoryOrCleanup(this.props.adapter.isMortgageToBuyNewPlace(), FIELDS.AS.HLC.DepositPurchaseWillComeFrom)
                        .mandatoryOrCleanup(this.props.adapter.isReplaceExistingMortgage(), FIELDS.AS.HLC.WantsReplaceExistingMortgageLikeToLike)
                        .mandatoryOrCleanup(this.fieldHasValueFalse(null, FIELDS.AS.HLC.WantsReplaceExistingMortgageLikeToLike), FIELDS.AS.HLC.WantsReplaceExistingMortgageLikeToLikeExplanation)
                        .mandatoryOrCleanup(this.props.adapter.isBrandNewMortgage(), FIELDS.AS.HLC.ReasonsForNewMortgage)
                        .mandatoryOrCleanup(this.props.adapter.isBrandNewMortgage(), FIELDS.AS.HLC.DesiredTransactionSpeed)

                        .mandatoryOrCleanup(this.props.adapter.isReplaceExistingMortgage(), FIELDS.AS.HLC.IsThereDeadline)
                        .mandatoryOrCleanup(this.props.adapter.isReplaceExistingMortgage() 
                            && this.fieldHasValueTrue(null, FIELDS.AS.HLC.IsThereDeadline), 
                            FIELDS.AS.HLC.IsThereDeadlineExplanation)
                        ;

                        if (!context.isOneFieldValidation() || context.hasErrorsAccrued()) {
                            // Store initial assessment validation success into field to be able to check if it is
                            // bypass or legitimate already filled in if the user revisits section
                            this.props.adapter.updateField(
                                !context.hasErrorsAccrued(),
                                FIELDS.AS.HLC.InitialAssessmentValid,
                                undefined,
                                this.getCurrentSectionId(),
                                this.getCurrentPartId());
                        }
                    },
                    nextQuestionId: () => BLOCK_INITIAL_VIABILITY_ASSESSMENT_DONE
                };

            case BLOCK_INITIAL_VIABILITY_ASSESSMENT_DONE:
                return {
                    block: (params)=>
                        <QuestionBlock {...params} controlButtons={null}>
                            <FieldLabel
                                label={`Thanks ${this.getApplicantName()}, an expert advisor has been requested to review your application!`}
                            />
                            <FieldLabel
                                labelType={LABEL_TYPE.Description}
                                label="Once an expert has reviewed your situation, they will contact you to discuss the best way forward. The following sections after this page mark the start of the main application process, but we recommend that you wait until you hear back from your advisor before entering any further information."
                            />

                            {params.controlButtons}

                            <FormImage imageType={IMAGE_SOMETHING_GREAT}/>
                        </QuestionBlock>,
                    nextQuestionId: () => CAN_SUBMIT,

                    validate: () => {

                        // keep always user in Screening after completing Initial Assessment
                        this.state.keepInSection = true;

                    },

                    onLoad: () => this.sendNotification(EVENTS.SCREENING_INITIAL_ASSESSMENT_FILLED_IN)
                };

            default:
                return ErrorPart();
        }
    };
}

export default InitialAssessment;
