import { Component } from 'react';
import {welcomeLink} from "../util/links";

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromProps(props, state) {
        props.history.push(welcomeLink());
        return null;
    }

    render() {
        return '';
    }
}

export default HomePage;