import * as React from "react";
import { Journey } from "../../form/components/journey";
import { model } from "common-hypotheca";
import {dashboardLink, infoPageLink} from "../../util/links";
import ContactUs from "../../util/ContactUs";

class SideMenu extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            previousApplicantId:0
        }
    }

    componentDidUpdate() {
        if(this.props.applicantId && this.state.previousApplicantId !== this.props.applicantId){
            this.setState({
                previousApplicantId:this.props.applicantId
            })
        }
    }

    getIsActive = sectionId => {
        return this.props.sectionId === sectionId
    };

    getSections = () => {
        let sections = [];

        if (!this.props.data) {
            return sections;
        }

        for (let i = 0; i < model.sectionOrdering.length; i++) {
            let sectionId = model.sectionOrdering[i];
            let modelSection = model[sectionId];

            let section = {
                id: modelSection.id,
                name: modelSection.name,
                isComplete: this.props.adapter.sectionComplete(sectionId),
                isActive: this.getIsActive(modelSection.id),
                isAvailable: this.props.adapter.sectionAvailable(modelSection.id),
                path: dashboardLink(modelSection.id, modelSection.hasApplicants ? this.state.previousApplicantId : null),
                applicantId: modelSection.hasApplicants ? this.state.previousApplicantId : null
            };
            sections.push(section);
        }
        return sections;
    };

    handleSectionClick = section => {
        // console.log('handleSectionClick', section)
        if (!section.isAvailable) {
            return;
        }
        this.props.gotoSection(section)
    };

    render() {
        return (
            <div className="bg-white sidemenu">
                <div className="navbar-brand"><a href={infoPageLink()}/></div>
                <div className="card-body">
                    <Journey sections={this.getSections()}
                        onSectionClick={section => this.handleSectionClick(section)}>
                    </Journey>
                    <ContactUs link label='Contact Us'/>
                </div>
            </div>
        )
    }
}




export default SideMenu;
