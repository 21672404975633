import React from 'react';

import PropTypes from "prop-types";
import CurrencyInput from './CurrencyInput';

import Field from './Field';







export class FieldCurrency extends Field {

    static defaultProps = {
        value: null,
        type: null,
        show: true,
        description: null,
        disabled: false
    };

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            focus: false,
            value: this.props.value
        }
    }

    handleCurrencyChange(event, maskedValue, floatValue) {

        const value = maskedValue === '' ? null : floatValue;

        // if minus sign, don't save field yet
        if (maskedValue === '-') {
            this.setState({
                value: maskedValue
            });
            return;
        }

        this.handleChange({
            target: {
                value: value
            }
        });

        this.setState({
            value: value
        });
    }


    render() {
        if (!this.show())
            return null;

        let label = this.label();

        return (
            <React.Fragment>

                <div className="form-group">
                    {
                        label &&
                        <label htmlFor={this.props.field} className={this.getLabelClassNameFromType()}>{label}</label>
                    }
                    {
                        <div className={"input-group mb-3 with-prepend" + this.validityClasses()}>

                            <div className="input-group-prepend">
                                <span className={"input-group-text" + (this.state.focus ? ' focused' : '')}>£</span>
                            </div>

                            <CurrencyInput
                                field={this.props.field}
                                className="form-control"
                                value={this.state.value}
                                precision="0"
                                autoFocus={false}
                                allowEmpty={true}
                                allowNegative={this.props.allowNegative}
                                onChangeEvent={this.handleCurrencyChange.bind(this)}
                                pattern={this.props.allowNegative ? '' : '[0-9]*'}
                                inputMode={this.props.allowNegative ? '' : 'numeric'}
                            />
                        </div>
                    }
                    {
                        this.renderError()
                    }
                </div>
            </React.Fragment>
        );
    }
}

FieldCurrency.propTypes = {
    label: PropTypes.any,
    labelType: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    field: PropTypes.any.isRequired,
    handleFieldFilled: PropTypes.func,
    show: PropTypes.any,
    error: PropTypes.string,
    valid: PropTypes.bool,
    disabled: PropTypes.bool,
    allowNegative: PropTypes.bool,
    tooltip: PropTypes.string
};

export default FieldCurrency;
