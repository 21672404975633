import React, { Fragment } from 'react';
import { Hub, Auth, API } from 'aws-amplify';
import FieldInput from 'common-form/components/FieldInput';
import FormButton from '../form/components/FormButton'
import {
    APINAME,
    APIPATH,
    H_AUTH_CHANNEL,
    H_CONFIRM_EMAIL_EVENT,
    H_CONFIRM_QUESTIONS_EVENT,
    H_SIGN_IN_EVENT,
    UNKNOWN_NICKNAME
} from '../constants';
import { getFreshCustomerAccount } from '../util/models';
import Header from '../welcome/Header'
import { ALERTTYPE } from '../form/components/alert/Alert';
import Alert from '../form/components/alert/Alert'
import Loading from "../form/icons/Loading";
import {
    cookiePolicyLink,
    forgotCredentialsLink,
    privacyPolicyLink,
    registerLink,
    termsOfUseLink,
    welcomeLink
} from "../util/links";
import HtmlUtil from "../util/HtmlUtil";
import {gtagConversion} from "../util/gtag";
import {configureAuth, configureAuth2} from "../amplify-configurator";
import ContactUs from "../util/ContactUs";

class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            error: null,
            email: null,
            password: null,
            verifyEmail: false,
            emailVerificationCode: null,
            currentlyConfirming: false,
            currentlySigningIn: false
        };
    }

    static handleLogout = async (history) => {
        await Auth.signOut();
        history.push(welcomeLink());
    };

    canSignIn = () => {
        return this.state.email && this.state.password && !this.state.currentlySigningIn
    };

    /**
     * Tries to sign in with specified email if user not found - with lowercased email as new logins will be created
     * in lowercase only to avoid user errors of not using the same case when logging in.
     *
     * @param email
     * @param password
     * @param clientMetadata
     * @returns {Promise<void>}
     */
    async cascadedSignIn(email, password, clientMetadata) {
        try {
            await Auth.signIn(email, password, clientMetadata);
        } catch (err) {
            if (email !== email.toLowerCase() && err && err.code === "UserNotFoundException") {
                await Auth.signIn(email.toLowerCase(), password, clientMetadata);
            } else {
                throw err;
            }
        }
    }

    async onSignIn(event) {
        // Can be called as submit event on form
        if (event && event.preventDefault)
            event.preventDefault();

        // this.setState({ loading: true });
        if (!this.canSignIn()) {
            return;
        }
        this.setState({ error: null, currentlySigningIn: true });
        try {
            let email, auth2, extra;
            if (this.state.email.includes('/')) {
                let split = this.state.email.split('/');
                extra = split[0].trim();
                email = split[1].trim();
                auth2 = true;
                configureAuth2();
                await Auth.signOut();
            } else {
                extra = undefined;
                email = this.state.email.trim();
                auth2 = false;
                configureAuth();
            }

            await this.cascadedSignIn(email, this.state.password, {extra: extra});

            const user = await Auth.currentAuthenticatedUser({
                // Force the user info download from Cognito, if it is taken from local storage it might contain
                // outdated data
                bypassCache : true
            });

            Hub.dispatch(H_AUTH_CHANNEL, {
                event: H_SIGN_IN_EVENT
            });

            if (user.attributes['custom:secure'] === true || user.attributes['custom:secure'] === 'true' || auth2) {
                await API.post(APINAME, APIPATH.ACTION, {
                    body: {
                        event: 'signin'
                    }
                });
                if (!user.attributes.email_verified) {
                    this.setState({
                        user,
                        verifyEmail: true
                    })
                }
            } else {

                this.setState({
                    user,
                    currentlyConfirming: true,
                    currentlySigningIn: false
                });

                // Pass work to creating user record in db
                return this.finishUserCreation(user);
            }

        } catch (err) {

            let error;
            switch (err.message) {
                case 'User does not exist.':
                case 'Incorrect username or password.':
                    error = 'Either your username or password is incorrect. Please try again!';
                    break;
                case 'Password attempts exceeded':
                    error = 'You have entered the wrong password too many times! Please try again later';
                    break;
                case 'User is not confirmed.':
                    error = 'This user has not yet verified their account!';
                    break;
                case 'Network error':
                    error = 'There appears to be a problem with our network, please call us or try again later';
                    break;
                default:
                    error = err.message;
                    break;
            }

            this.setState({
                error,
                currentlySigningIn: false
            });
            await API.post(APINAME, APIPATH.ACTION, {
                body: {
                    event: 'error',
                    data: `Signin; ${this.state.email}, ${JSON.stringify(err, null, 2)}`
                }
            })
        }
    }

    canConfirmEmailVerification = () => {
        return !!this.state.emailVerificationCode
    };

    onConfirmEmailVerification = async () => {
        if (!this.canConfirmEmailVerification()) {
            return
        }
        try {
            await Auth.verifyUserAttributeSubmit(this.state.user, 'email', this.state.emailVerificationCode)
        } catch (e) {
            this.setState({
                error: e.message
            })
        }

        let userAccount = await API.get(APINAME, APIPATH.USER, {})
        let user = {
            ...userAccount,
            email: this.state.user.attributes.email,
            emailVerified: true,
        };

        await API.post(APINAME, APIPATH.USER, {
            body: user
        });

        await this.cascadedSignIn(this.state.email, this.state.password);

        Hub.dispatch(H_AUTH_CHANNEL, {
            event: H_CONFIRM_EMAIL_EVENT
        });
    };

    finishUserCreation = async (user) => {
        this.setState({
            currentlyConfirming: true
        });

        let consents = {};
        try {
            consents = JSON.parse(user.attributes['custom:consents'])
        } catch (e) {
            console.log(e);
        }

        try {
            let referralCode = null;
            if (consents !== null && consents.referralCode) {
                referralCode = consents.referralCode;
                delete consents.referralCode;
            }

            await API.post(APINAME, APIPATH.USER, {
                body: getFreshCustomerAccount({
                    email: this.state.email,
                    consents: consents,
                    sub: user.username,
                    referralCode: referralCode
                })
            });

            await Auth.updateUserAttributes(user, {
                nickname: UNKNOWN_NICKNAME,
                'custom:secure': 'true',
                'custom:consents': 'null'
            });

            await API.post(APINAME, APIPATH.ACTION, {
                body: {
                    event: 'register'
                }
            });

            gtagConversion();

            setTimeout(async () => {
                await this.cascadedSignIn(this.state.email, this.state.password);

                Hub.dispatch(H_AUTH_CHANNEL, {
                    event: H_CONFIRM_QUESTIONS_EVENT
                });
            }, 500);
        } catch (e) {
            console.log('User creation error', this.state, e);

            this.setState({
                error: <>
                    User creation failed, please try to&nbsp;
                    <FormButton
                        link
                        text='login'
                        path={welcomeLink()}
                        pathGoHard={true}
                    />
                    &nbsp;again. If it doesn't help, please&nbsp;
                    <ContactUs link/>
                    .
                </>
            });
        }
    };

    render() {
        let components = {
            email: {
                label: 'Email',
                placeholder: null,
                value: this.state.email,
                field: 'email',
                handleFieldFilled: (a, f, email) => this.setState({ email }),
                // type: 'email' - cannot validate email for impersonate function to work.
            },
            emailVerificationCode: {
                label: 'Email Verification Code',
                placeholder: null,
                value: this.state.emailVerificationCode,
                field: 'emailVerificationCode',
                handleFieldFilled: (a, f, emailVerificationCode) => this.setState({ emailVerificationCode }),
                type: 'string',
                noAutoFill:true
            },
            password: {
                label: 'Password',
                placeholder: null,
                value: this.state.password,
                field: 'password',
                handleFieldFilled: (a, f, password) => this.setState({ password }),
                type: 'password'
            },
            signin: {
                text: 'Sign In',
                class: `btn btn-success ${this.canSignIn() ? '' : 'disabled'}`,
                disabled: !this.canSignIn(),
                submit: true
            },

            submitEmailVerification: {
                text: 'Submit',
                onClick: () => this.onConfirmEmailVerification(),
                class: `btn btn-success ${this.canConfirmEmailVerification() ? '' : 'disabled'}`,
            },
            forgot: {
                text: 'Recover your account',
                path: forgotCredentialsLink()
            },
            register: {
                text: "Don't yet have an account?",
                path: registerLink()
            }
        };

        return (
            <React.Fragment>
                <div className="container">
                    <div className='row'>
                        <div className='col-12'>
                            <Header {...this.props} type={Header.TYPES.LOGIN} />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-12 col-md-8 offset-md-2`}>
                            <div className="card login">
                                <div className="card-body">
                                    {
                                        this.state.currentlyConfirming &&
                                        !this.state.error &&
                                        <>
                                            <Alert type={ALERTTYPE.SUCCESS} text='Just setting up your account and securing your information. Won’t take a moment!' />
                                            <Loading/>
                                        </>
                                    }
                                    {
                                        this.state.currentlyConfirming &&
                                        !!this.state.error &&
                                        <Alert text={this.state.error} type={ALERTTYPE.DANGER}/>
                                    }
                                    {
                                        !this.state.currentlyConfirming &&
                                        !this.state.verifyEmail &&
                                        <React.Fragment>
                                            <div className='row'>
                                                <div className='col-12 padding-bottom'>
                                                    <h1 className='oos-title'>Welcome back!</h1>
                                                    <p>Please enter your email address and password to access your secure mortgage applications.</p>
                                                </div>

                                            </div>
                                            <form onSubmit={(event) => this.onSignIn(event)}>
                                                <div className='inputs'>
                                                    <FieldInput {...components.email} />
                                                    <FieldInput {...components.password} />
                                                    {
                                                        !!this.state.error &&
                                                        <Alert text={this.state.error} type={ALERTTYPE.DANGER} />
                                                    }
                                                    <div className='simple-form-buttons'>
                                                        <FormButton {...components.signin} />
                                                        {
                                                            this.state.currentlySigningIn &&
                                                            <Loading buttonLine/>
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                            <div className='line1 btn-register'>
                                                <FormButton {...components.register} />
                                            </div>
                                            <div className='line1 btn-forgot'>
                                                <FormButton {...components.forgot} />
                                            </div>

                                            <div className='line2' style={{fontSize:'14px'}}>
                                                Your use of this site signifies that you accept
                                                our <a href={termsOfUseLink()} target='_blank' rel='noopener noreferrer' onClick={(e) => e.stopPropagation()}>Terms of Use</a>,
                                                &nbsp;<a href={privacyPolicyLink()} target='_blank' rel='noopener noreferrer' onClick={(e) => e.stopPropagation()}>Privacy Policy</a> and
                                                &nbsp;<a href={cookiePolicyLink()} target='_blank' rel='noopener noreferrer' onClick={(e) => e.stopPropagation()}>Cookie Notice</a>.
                                            </div>

                                        </React.Fragment>
                                    }
                                    {
                                        /**
                                        !this.state.currentlyConfirming &&
                                        !!this.state.setSecurity &&
                                        this.renderSecurityForm()
                                         */
                                    }
                                    {
                                        !this.state.currentlyConfirming &&
                                        !!this.state.verifyEmail &&
                                        <Fragment>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <p>You have changed your email address. Please input your verification code to continue.</p>
                                                    <p>If you are unable to access the new email address containing the code, then
                                                        please <ContactUs link/> to
                                                        request an email reset.</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <FieldInput
                                                        {...components.emailVerificationCode}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                !!this.state.error &&
                                                HtmlUtil.renderFullRows(
                                                    <Alert text={this.state.error} type={ALERTTYPE.DANGER} />
                                                )
                                            }
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <FormButton {...components.submitEmailVerification} />
                                                </div>
                                            </div>

                                        </Fragment>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default SignIn;
