import * as React from "react";


import { CAN_SUBMIT } from "../../../constants";

import MultiBlockFormSingleApplicant from "../../../form/MultiBlockFormSingleApplicant";
import QuestionBlock from "../../../form/QuestionBlock";
import FieldLabel from "common-form/components/FieldLabel";
import FormButton from "../../../form/components/FormButton";
import FormImage, {IMAGE_MORTGAGE_DIRECTION} from "../../../form/components/FormImage";
import { LABEL_TYPE } from "../../../form/components/Field";

import { globalCleanupAfterPreferredMortgageType } from "../../../util/globalCleanup";

import MortgageSort from "./preferences/MortgageSort";
import Changes from "./preferences/Changes";
import Interest from "./preferences/Interest";
import Repayments from "./preferences/Repayments";
import InterestOnly from "./preferences/InterestOnly";
import MortgageFeatures from "./preferences/MortgageFeatures";

import {
    FIELDS,
    SECTION,
    MY_MORTGAGE,
    PREFERRED_MORTGAGE_TYPE
} from "common-hypotheca";
import ErrorPart from "../../ErrorPart";


const BLOCK_WELCOME = 1;
const BLOCK_MORTGAGE_SORT = 5;
const BLOCK_CHANGES = 8;
const BLOCK_INTEREST = 10;
const BLOCK_REPAYMENTS = 14;
const BLOCK_INTEREST_ONLY = 18;
const BLOCK_FEATURES = 20;

export default class MortgagePreferences extends MultiBlockFormSingleApplicant {

    mortgageType = null;
    
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            sectionId: SECTION.MY_MORTGAGE,
            partId: MY_MORTGAGE.MORTGAGE_PREFERENCES,

            slideIds: [BLOCK_WELCOME],
            activeSlideId: BLOCK_WELCOME
        };

        this.mortgageType = this.getValue(FIELDS.MM.MP.PreferredMortgageType);
    }
    
    /**
     * @returns {number} of expected slides for this part.
     */
    slidesExpected = () => {
        return 3;
    };

    checkForCompleteState = data => {
        this.partContainer().isComplete = true;
        this.currentSectionObject().isComplete = true;
        return data;
    };

    getField = (fieldName) => {
        return this.getFormField(null, fieldName);
    };

    getValue = (fieldName) => {
        return this.getField(fieldName).value;
    };
    
    getPropertyValue = () => {
        return this.props.adapter.getPropertyValue();
    };

    getQuestion = which => {
        switch(which) {

            case BLOCK_WELCOME:
                return {
                    block: (params)=>
                        <QuestionBlock {...params} controlButtons={null}>

                            <FieldLabel
                                label={`OK ${this.getApplicantName()}, let's get a feel for your mortgage preferences`}
                            />

                            <FieldLabel
                                label="This short section documents any specific mortgage requirements that you might have and tests a few assumptions about your attitude to risk."
                                labelType={LABEL_TYPE.Description}
                            />

                            <FormButton
                                text="OK"
                                onClick={() => this.swiperNext()}
                            />
                            <FormImage imageType={IMAGE_MORTGAGE_DIRECTION}/>
                        </QuestionBlock>,
                    canNext: () => true,
                    nextQuestionId: () => BLOCK_MORTGAGE_SORT
                };

            case BLOCK_MORTGAGE_SORT:
                return MortgageSort(this, BLOCK_CHANGES);

            case BLOCK_CHANGES:
                return Changes(this, BLOCK_INTEREST);

            case BLOCK_INTEREST:
                return Interest(this, BLOCK_REPAYMENTS);

            case BLOCK_REPAYMENTS:
                return Repayments(this, () => {

                    //perform cleanup if user has changed mortgage type
                    if (this.mortgageType !== this.getValue(FIELDS.MM.MP.PreferredMortgageType)) {
                        globalCleanupAfterPreferredMortgageType(this.props.adapter);
                    }

                    return this.getValue(FIELDS.MM.MP.PreferredMortgageType) === PREFERRED_MORTGAGE_TYPE.INTEREST_ONLY 
                        ? BLOCK_INTEREST_ONLY 
                        : BLOCK_FEATURES
                    ;
                });

            case BLOCK_INTEREST_ONLY:
                return InterestOnly(this, BLOCK_FEATURES);

            case BLOCK_FEATURES:
                return MortgageFeatures(this, CAN_SUBMIT);

            default:
                return ErrorPart();
        }
    };

}

