export const _EMPLOYMENT_TYPE = {
    EMPLOYEE: {
        code: 'employee',
        desc: 'Employee'
    },
    SELF_EMPLOYED: {
        code: 'self-employed',
        desc: 'Self-employed'
    },
    RETIRED: {
        code: 'retired',
        desc: 'Retired',
    },
    NOT_WORKING: {
        code: 'not-working',
        desc: 'Not Working'
    },
};

_EMPLOYMENT_TYPE.OPTIONS = [
    {
        code: _EMPLOYMENT_TYPE.EMPLOYEE.code,
        text: _EMPLOYMENT_TYPE.EMPLOYEE.desc,
        icon: 'supervisor_account',
        tooltip: "Normal employment by a company in which you own less than 20% of the shares."
    },
    {
        code: _EMPLOYMENT_TYPE.SELF_EMPLOYED.code,
        text: _EMPLOYMENT_TYPE.SELF_EMPLOYED.desc,
        icon: 'person',
        tooltip: "Director & Shareholder owning 20% or more of the shares, Partners or Sole traders."
    },
    {
        code: _EMPLOYMENT_TYPE.RETIRED.code,
        text: _EMPLOYMENT_TYPE.RETIRED.desc,
        icon: 'beach_access'
    },
    {
        code: _EMPLOYMENT_TYPE.NOT_WORKING.code,
        text: _EMPLOYMENT_TYPE.NOT_WORKING.desc,
        icon: 'highlight_off'
    }
];

_EMPLOYMENT_TYPE.DROPDOWN_OPTIONS = [
    { code: '', text: '' },
    ..._EMPLOYMENT_TYPE.OPTIONS
];