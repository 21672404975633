import React from "react";
import PropTypes from "prop-types";

// Getting started - import
import Begin from "../../images/statement/Begin.jpg";
import Sorry from "../../images/screening/Sorry.jpg";
import SomethingGreat from "../../images/screening/Something great.jpg";

// My situation - import
import GrabACofee from "../../images/situation/Grab a coffee.jpg";
import Houses from "../../images/situation/Houses.jpg";
import Work from "../../images/situation/Work.jpg";
import PiggyBank from "../../images/situation/Piggy bank.jpg"
import Budget from "../../images/situation/Budget.jpg"

// My property - import
import Keys from "../../images/property/Keys.jpg"
import Marketing from "../../images/property/Marketing.jpg";

// My mortgage - import
import Drawers from "../../images/application/drawers.jpg";
import LimitedCompanyImage from "../../images/mortgage/limited_company.jpg";
import Mortgage from "../../images/mortgage/mortgage.jpg";
import MortgageDirection from "../../images/mortgage/mortgage_direction.jpg";

// Getting started - const
export const IMAGE_BEGIN = Begin;
export const IMAGE_SORRY = Sorry;
export const IMAGE_SOMETHING_GREAT = SomethingGreat;

// My situation - const
export const IMAGE_GRAB_A_COFFEE = GrabACofee;
export const IMAGE_HOUSES = Houses;
export const IMAGE_WORK = Work;
export const IMAGE_PIGGY_BANK = PiggyBank;
export const IMAGE_BUDGET = Budget;

// My property - const
export const IMAGE_KEYS = Keys;
export const IMAGE_MARKETING = Marketing;

// My mortgage - const
export const IMAGE_DRAWERS = Drawers;
export const IMAGE_LIMITED_COMPANY = LimitedCompanyImage;
export const IMAGE_MORTGAGE = Mortgage;
export const IMAGE_MORTGAGE_DIRECTION = MortgageDirection;

class FormImage extends React.Component {
    render() {
        return <img alt='Section' src={this.props.imageType} className="img-fluid form-image"/>
    }
}

FormImage.propTypes = {
    imageType: PropTypes.any.isRequired
};

export default FormImage;