import moment from 'moment';

const DATE_FORMAT = 'DD/MM/YYYY';

export function momentValue(value) {

    if (typeof(value) !== 'string') {
        return moment(value);
    }

    const str = (value || '').replace(new RegExp(' ', 'g'), '0');

    if (str.split('/').some(t => t.trim().length === 0)) {
        return {
            isValid: () => false
        };
    }

    return moment(str, DATE_FORMAT);
}

export function isDateValid(value, minYear, maxYear) {

    if (value === null || value === undefined || value.trim && value.trim() === '') {
        return false;
    }

    let mValue = momentValue(value);
    return mValue.isValid()
        && mValue.year() >= (minYear || 1900)
        && mValue.year() <= (maxYear || 2050);
}

export function getNiceDate(val) {
    return moment(val).format();
}

export function formatDateStandard(val) {
    return val === undefined || val === null || val === '' ? '' : moment(val).format("DD/MM/YYYY");
}

export function formatMonthSortable(val) {
    return val === undefined || val === null || val === '' ? '' : moment(val).format("YYYYMM");
}

export function formatTimestamp(val) {
    return val === undefined || val === null || val === '' ? '' : moment(val).format("DD/MM/YYYY HH:mm:ss");
}

export function atLeast3YearsAgo(sinceValue) {
    if (!isDateValid(sinceValue))
        return false;

    let mValue = momentValue(sinceValue);
    return moment().diff(mValue, 'years') >= 3;
}


export function isAgoLessThan(sinceValue, unit, amount) {
    if (!isDateValid(sinceValue))
        return false;

    let mValue = momentValue(sinceValue);
    return moment().diff(mValue, unit) < amount;
}

export function isWithinOfToday(futureDate, unit, amount) {
    if (!isDateValid(futureDate))
        return false;

    let mValue = momentValue(futureDate);
    return mValue.diff(moment(), unit) < amount;

}


export function getAgoDate(amount, unit) {
    return moment().subtract(amount, unit).get();
}

export function getAgoValue(sinceValue, unit) {

    if (!isDateValid(sinceValue)) {
        return null;
    }

    const mValue = momentValue(sinceValue);
    return moment().diff(mValue.get(), unit);
}


export function isDateAfter(fromDate, toDate) {
    let date = momentValue(fromDate);
    return date.isAfter(toDate);
}

export function isDateBefore(fromDate, toDate) {
    let date = momentValue(fromDate);
    return date.isBefore(toDate);
}

export function addYears(date, value) {
    return momentValue(date).add(value, 'years').toDate();
}

export function addMonths(date, value) {
    return momentValue(date).add(value, 'months').toDate();
}

export function formatTimeDiff(timeDiff, format) {

    if (!timeDiff || !format) {
        throw Error("timeDiff and format are required");
    }

    let t = ['y', 'm', 'd'];
    let tt = ['years', 'months', 'days'];

    let result = '';
    format.split('').forEach(f => {

        const idx = t.indexOf(f);
        const unit = tt[idx];
        const value = timeDiff[unit];

        if (value > 0) {

            if (result.length) {
                result += ' and ';
            }

            result += value + ' ' + unit.substring(0, unit.length - 1);

            if (value > 1) {
                result += 's';
            }
        }

    });

    return result;
}

export function getTimeDiff(fromValue, toValue, minYear, maxYear) {

    if (!toValue) {
        toValue = new Date();
    }

    if (!fromValue) {
        throw Error("fromValue is required");
    }

    if (!isDateValid(fromValue, minYear, maxYear) || !isDateValid(toValue, minYear, maxYear))
        return null;

    const fromMoment = momentValue(fromValue);
    const toMoment = momentValue(toValue);

    let durationYears = toMoment.diff(fromMoment, 'year');
    fromMoment.add(durationYears, 'year');
    let durationMonths = toMoment.diff(fromMoment, 'month');
    fromMoment.add(durationMonths, 'month');
    let durationDays = toMoment.diff(fromMoment, 'day');

    return {
        years: durationYears,
        months: durationMonths,
        days: durationDays
    };
}

export function formattedTimeDiffToNow(from) {
    if (from === undefined || from === null || !isDateValid(from)) {
        return undefined;
    }

    const timeDiff = getTimeDiff(from, new Date());
    const formatted = formatTimeDiff(timeDiff, 'ym');
    return formatted;
}

