import React from "react";
import PropTypes from "prop-types";

import FieldLabel from "common-form/components/FieldLabel";
import { LABEL_TYPE } from "common-form/components/Field";

import { Alert, ALERTTYPE } from "./alert/Alert";

export default function LoanToValue({label, useLabel, value, loan, current}) {
    
    const percentage = Math.round(loan / value * 10000)/100;
    if (isNaN(percentage)) {
        return null;
    }

    const percentageStr = percentage.toLocaleString('en-GB', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });

    let type;
    let additionalText = '';
    if (current) {
        type = ALERTTYPE.INFO;
    } else if (percentage <= 75) {
        type = ALERTTYPE.SUCCESS;
    } else if (percentage <= 80) {
        type = ALERTTYPE.WARNING;
        additionalText = '. Maximum LTV is normally 75%, although a few lenders allow up to 80%.';
    } else {
        type = ALERTTYPE.DANGER;
        additionalText = '. This is too high for current holiday let lenders, please reduce amount.';
    }

    return <React.Fragment>

        {
            useLabel ?

            <FieldLabel
                label={label.replace('{0}', percentageStr) + additionalText}
                labelType={LABEL_TYPE.Description}
            />

            :

            <Alert
                type={type}
                text={label.replace('{0}', percentageStr)  + additionalText}
            />

        }


    </React.Fragment>
    ;
}

LoanToValue.propTypes = {
    label: PropTypes.string.isRequired,
    useLabel: PropTypes.bool,
    loan: PropTypes.any,
    value: PropTypes.any,
    current: PropTypes.bool
};
