import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldInput from "common-form/components/FieldInput";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldLabel from "common-form/components/FieldLabel";
import {LABEL_TYPE} from "common-form/components/Field";
import AddressLookup from "../../../../../form/components/AddressLookup";

import {
    FIELDS
} from "common-hypotheca";


export default function SurveyorContact(parent, nextQuestionId) {

    function dontUseMyContactDetails() {
        return parent.getValue(FIELDS.MA.SSI.KeysContactUsePersonal) === false;
    }

    return {
        block: (params) =>

            <QuestionBlock {...params}>

                <FieldLabel
                    label="Who can the surveyor contact to arrange a visit?"
                />

                <FieldLabel
                    label="Please provide us with the contact details for the lender's surveyor, so they can arrange a time to conduct a property visit."
                    labelType={LABEL_TYPE.Description}
                />

                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    show={() => parent.shouldAllowUsePersonalDetailsForSurveyorContact()}
                    {...parent.getField(FIELDS.MA.SSI.KeysContactUsePersonal)}
                />

                {
                    dontUseMyContactDetails() && 

                    <>
                    
                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MA.SSI.KeysContactBiz)}
                    />
                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MA.SSI.KeysContactName)}
                    />
                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MA.SSI.KeysContactNumber)}
                    />

                    <AddressLookup
                        label="Contact address"
                        labelType={LABEL_TYPE.Small}
                        fields={[
                            parent.getFormField(null, FIELDS.MA.SSI.KeysAddressLine1),
                            parent.getFormField(null, FIELDS.MA.SSI.KeysAddressLine2),
                            parent.getFormField(null, FIELDS.MA.SSI.KeysTown),
                            parent.getFormField(null, FIELDS.MA.SSI.KeysCounty),
                            parent.getFormField(null, FIELDS.MA.SSI.KeysPostcode)
                        ]}
                    />

                    </>
                }


            </QuestionBlock>,


        validate: (context) => {
            context
                .mandatory(FIELDS.MA.SSI.KeysContactUsePersonal)
                .mandatoryOrCleanup(dontUseMyContactDetails(),[
                    FIELDS.MA.SSI.KeysContactBiz,
                    FIELDS.MA.SSI.KeysContactName,
                    FIELDS.MA.SSI.KeysContactNumber,
                    FIELDS.MA.SSI.KeysAddressLine1
                ])
                .optionalOrCleanup(dontUseMyContactDetails(), FIELDS.MA.SSI.KeysAddressLine2)
                .mandatoryOrCleanup(dontUseMyContactDetails(), FIELDS.MA.SSI.KeysTown)
                .optionalOrCleanup(dontUseMyContactDetails(), FIELDS.MA.SSI.KeysCounty)
                .mandatoryOrCleanup(dontUseMyContactDetails(), FIELDS.MA.SSI.KeysPostcode);
        },

        nextQuestionId: () => nextQuestionId
    };
};