import React, { Component } from "react";
import { CAN_SUBMIT, nextSectionInWorkflow , APIPATH,APINAME} from '../constants';
import { refreshValidity, validateAllFields, validateSingleField } from "../util/validate";
import ControlButtons from "./ControlButtons";
import { dashboardLink } from "../util/links";
import { API } from 'aws-amplify';
import * as Scroll from "react-scroll";
import {
    extractDesignation,
    extractFieldName,
    extractFrontendLabel,
    model
} from "common-hypotheca";
import {AnimatePresence, motion} from "framer-motion";
import PropTypes from "prop-types";

const variants = {
    enter: (direction) => {
        return {
            zIndex: 1,
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction) => {
        return {
            zIndex: 1,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        };
    }
};

class MultiBlockForm extends Component {
    static propTypes = {
        data: PropTypes.object,
        adapter: PropTypes.object
    };

    constructor(props) {
        super(props);
        //this.nextPage = this.nextPage.bind(this);
        //this.previousPage = this.previousPage.bind(this);
        // this.submit = this.submit.bind(this)
        this.state = {
            canSubmit: false
        };

        this.getApplicantName = (applicantId) => props.adapter.applicantName(applicantId === undefined ? this.state.currentApplicantId : applicantId);
        this.getApplicantCount = props.adapter.applicantCount;

        this.getApplicants = () => props.adapter.getApplicants();

    }

    // TODO: refactor
    UNSAFE_componentWillMount() {
        this.showValidityAfterNavigation(this.activeSlideId());

        this.props.updateProgress(this.props.predictedProgress(this.state.slideIds.length - 1, this.slidesExpected() - 1));
        this.setState({
            wasComplete: this.props.adapter.partComplete(this.state.currentApplicantId, this.getCurrentSectionId(), this.state.partId)
        });
    }


    componentDidMount() {

        if (this.activeSlideId()) {
            this.handleOnLoads(this.activeSlideId());
        }
    };

    activeSlideId = () => {
        return this.state.slideIds.length > 0 ? this.state.slideIds[this.state.slideIds.length - 1] : undefined;
    };

    sendNotification = async (what, nickname) => {
        let data = this.getData();

        if (!data.notifications) {
            data.notifications = []
        }
        if (!data.notifications.filter(notif => notif.event === what).length){
            //only do this if we have not had any of this event yet
            try {
                await API.post(APINAME, APIPATH.TRIGGER, {
                    body: {
                        applicationId: this.props.data.applicationId,
                        event: what,
                        nickname
                    }
                });
                data.notifications.push({
                    event:what,
                    time:new Date().getTime()
                });
                this.setData(data)
            } catch (e) {
                console.log(e)
            }
        }
    };

    handleOnLoads(id) {
        let question = this.getQuestion(id)
        // console.log('handleonloads',question.hasOwnProperty('onLoad'),question)
        if (question.hasOwnProperty('onLoad')) {
            question.onLoad()
        }
    }

    slideActive(i) {
        this.props.updateProgress(this.props.predictedProgress(this.state.slideIds.indexOf(i), this.slidesExpected() - 1));

        this.showValidityAfterNavigation(i);
        this.handleOnLoads(i);
    }

    getQuestion = which => {
        console.log('getQuestion MUST BE OVERRIDDEN');
        return [];
    };

    showValidityAfterNavigation = (next) => {
        refreshValidity(this.getQuestion(next).validate);
    };

    swiperNext = (id = null, skipBeforeNext = null) => {
        let question = this.getQuestion(this.activeSlideId());

        this.setState({
            nextTriggered: true
        });

        if(!skipBeforeNext && question.beforeNext) {
            question.beforeNext(() => {
                this.swiperNext(id, true);
            });
            return;
        }


        if (!this.getReadOnly()) {
            if (validateAllFields(
                this,
                question.validate,
                (applicantId, sectionId, partId) => this.partContainer(applicantId, sectionId, partId),
                this.groupAddableRef)) {

                //Scroll to top when going to next slide
                setTimeout(() => {
                    window.scrollTo({top: 0, left: 0, behavior: 'instant'});
                }, 1);

            } else {

                // Any validation error should reset the state of current part to not complete.
                this.partContainer().isComplete = false;

                // Scroll to first invalid field when
                setTimeout(() => {
                    Scroll.scroller.scrollTo('is-invalid', {
                        duration: 300,
                        smooth: true,
                        offset: -100
                    });
                }, 300);

                // Trigger auto-save as validation could have changed the application.
                this.triggerAutosave();

                return;
            }
        }

        // Trigger auto-save as validation could have changed the application.
        this.triggerAutosave();

        this.setState({
            validationContext: undefined
        });

        let next = id || question.nextQuestionId();
        if (next === null) {
            return;
        }
        if (next === CAN_SUBMIT) {

            this.setState({
                canSubmit: true,
                nextTriggered: undefined
            });

            this.submit();

            return;
        }

        let slideIds = this.state.slideIds;
        let slideIndex = slideIds.indexOf(next);
        if (slideIndex === -1) {
            slideIds.push(next);
            slideIndex = slideIds.length - 1;
        }

        this.setState({
            slideIds,
            nextTriggered: undefined,
            direction: 1
        });

        this.slideActive(next);
    };

    swiperPrevious = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
        let slideIds = this.state.slideIds;

        //clear validation when going previous
        if (this.state.validationContext) {
            this.state.validationContext.finishAllValid();
        }

        if (slideIds.length <= 1) {
            this.props.history.push(dashboardLink(this.props.sectionId, this.props.currentApplicantId));
            return;
        }

        slideIds.pop();
        this.setState({
            slideIds,
            direction: -1
        });

        this.slideActive(this.activeSlideId());
    };

    currentSectionObject() {
        let applicantId = this.state.currentApplicantId;
        let sectionId = this.getCurrentSectionId();

        if (applicantId === null || applicantId === undefined)
            return this.props.data[sectionId];
        else
            return this.props.data.applicants.find(applicant => applicant.applicantId === applicantId)[sectionId];
    }

    partContainer(applicantId, sectionId, partId) {
        if ((applicantId === null || applicantId === undefined) && this.state.currentApplicantId !== null) {
            applicantId = parseInt(this.state.currentApplicantId);
        }

        if (applicantId !== null && applicantId !== undefined) {
            applicantId = parseInt(applicantId);
        }

        if (sectionId === null || sectionId === undefined) {
            sectionId = this.getCurrentSectionId();
        }

        if (partId === null || partId === undefined) {
            partId = this.state.partId;
        }

        return this.props.adapter.fieldsContainer(applicantId, sectionId, partId);
    }

    fieldValue(field, applicantId, sectionId, partId) {
        let fieldName = extractFieldName(field);
        let partContainer = this.partContainer(applicantId, sectionId, partId);
        let value;
        if (partContainer === undefined) {
            value = undefined;
        } else {
            value = partContainer.fields[fieldName];
        }
        return value;
    }

    /**
     * @deprecated use fieldValue instead.
     * @param applicantId
     * @param field
     * @returns {undefined}
     */
    getFieldValue(applicantId, field) {
        let fieldName = extractFieldName(field);
        let partContainer = this.partContainer(applicantId);
        let value;
        if (partContainer === undefined) {
            value = undefined;
        } else {
            value = partContainer.fields[fieldName];
        }
        return value;
    }

    getReadOnly = () => {
        return this.props.adapter.partReadonly(this.state.currentApplicantId, this.getCurrentSectionId(), this.state.partId);
    };

    /**
     * Field component properties for specified field.
     */
    fieldComponentProperties(field, partParams) {
        let modifiedPartParams = {
        };

        if (partParams && partParams.applicantId !== undefined && partParams.applicantId !== null) {
            modifiedPartParams.applicantId = partParams.applicantId;
        }

        if (modifiedPartParams.applicantId === undefined && this.props.applicantId !== undefined && this.props.applicantId !== null) {
            modifiedPartParams.applicantId = this.props.applicantId;
        }

        if (modifiedPartParams.applicantId !== undefined && modifiedPartParams.applicantId !== null) {
            modifiedPartParams.applicantId = parseInt(modifiedPartParams.applicantId);
        }

        if (partParams === undefined
            || partParams === null
            || partParams.sectionId === undefined
            || partParams.sectionId === null) {

            modifiedPartParams.sectionId = this.state.sectionId;

        } else {
            modifiedPartParams.sectionId = partParams.sectionId;
        }

        if (partParams === undefined
            || partParams.partId === undefined
            || partParams.partId === null) {
            modifiedPartParams.partId = this.state.partId;
        } else {
            modifiedPartParams.partId = partParams.partId;
        }

        let result = {
            handleFieldFilled: this.generateFieldFillHandler(modifiedPartParams),
            field: extractFieldName(field),
            applicantId: modifiedPartParams.applicantId,
            value: this.props.adapter.fieldValue(field, modifiedPartParams.applicantId, modifiedPartParams.sectionId, modifiedPartParams.partId),
            fieldsContainer: this.props.adapter.fieldsContainer(modifiedPartParams.applicantId, modifiedPartParams.sectionId, modifiedPartParams.partId).fields,

            // Validation result properties use unmodified partParams as validation context doesn't make any difference between sections assuming
            // field name is unique anyway. Also applicantId is expected to be undefined unless screen modifies other section/part data
            error: this.state.validationContext === undefined ? undefined : this.state.validationContext.error(field, partParams),
            valid: this.state.validationContext === undefined ? false : this.state.validationContext.isValid(field, partParams),

            disabled: this.getReadOnly(),
            designation: extractDesignation(field)
        };

        let label = extractFrontendLabel(field);
        if (label)
            result.label = label;

        let options = typeof field === 'object' ? field.options : undefined;
        if (options) {
            // TODO: Fix some form components using options and items, use one instead.
            result.items = options;
            result.options = options;
        }

        let tooltip = typeof field === 'object' ? field.tooltip : undefined;
        if (tooltip) {
            result.tooltip = tooltip;
        }

        return result;

    }

    /**
     * @deprecated Use fieldComponentProperties instead.
     */
    getFormField(applicantId, field) {
        let partParams;
        partParams = applicantId === null || applicantId === undefined
            ? {}
            : { applicantId };

        return this.fieldComponentProperties(field, partParams);
    }

    /**
     * Gets section name for application data manipulation.
     * @returns string Section name
     */
    getCurrentSectionId = () => {
        return this.state.sectionId;
    };

    getCurrentPartId = () => {
        return this.state.partId;
    };

    /**
     * This function is meant to be overridden within the SECTION.
     * It allows the section to determine if it has now been completed before updating the model.
     */
    checkForCompleteState = data => data;

    defaultFieldLocation = () => {
        return [this.state.currentApplicantId, this.getCurrentSectionId(), this.state.partId ];
    };

    generateFieldFillHandler = (partParams) => {
        let lambdaPartParams = partParams;

        return (applicantId, field, value, moveOn = null, noValidate = null) => {

            let fieldName = extractFieldName(field);

            if (value === 'true') { value = true; }
            else if (value === 'false') { value = false; }

            let partContainer = this.partContainer(lambdaPartParams.applicantId, lambdaPartParams.sectionId, lambdaPartParams.partId);

            if (partContainer == null) {
                console.log('UNABLE TO FIND FIELDS TO UPDATE')
            } else {
                partContainer.fields[fieldName] = value;
            }

            if (!noValidate) {
                if (!validateSingleField(
                    fieldName,
                    this,
                    this.getQuestion(this.activeSlideId()).validate,
                    (applicantId, sectionId, partId) => this.partContainer(applicantId, sectionId, partId),
                    { applicantId })) {
                    this.partContainer().isComplete = false;
                }
            }

            let data = this.getData();

            if (moveOn !== null) {
                if (moveOn === 0) {
                    this.swiperNext()
                } else {
                    setTimeout(() => {
                        this.swiperNext()
                    }, moveOn);
                }
            }
            this.setData(data)
        }
    };

    handleFilledField = (applicantId, field, value, moveOn = null, noValidate = null) => {
        this.generateFieldFillHandler({
            applicantId,
            sectionId: this.state.sectionId,
            partId: this.state.partId
        })(applicantId, field, value, moveOn, noValidate);
    };

    getData() {
        return this.props.data
    };

    setData = data => {
        this.props.setData(data)
    };

    triggerAutosave = () => {
        this.props.setData(this.getData());
    };

    // BOOLEAN CHECKS

    fieldHasValueAny(applicantId, field) {
        let value = this.getFieldValue(applicantId, field);
        return typeof value !== 'undefined'
            && value !== '';
    }

    /**
     * @deprecated Use fieldValueTrue
     */
    fieldHasValueTrue(applicantId, field) {
        return this.fieldValue(field, applicantId) === true;
    }
    /**
     * @deprecated Use fieldValueFalse
     */
    fieldHasValueFalse(applicantId, field) {
        return this.fieldValue(field, applicantId) === false;
    }

    fieldValueTrue(field, applicantId) {
        return this.fieldValue(field, applicantId) === true;
    }
    fieldValueFalse(field, applicantId) {
        return this.fieldValue(field, applicantId) === false;
    }


    submit = () => {
        this.setData(this.checkForCompleteState(this.props.data));

        // console.log(this.state);
        
        if ( (this.state.wasComplete && !this.state.moveToNext) || this.state.keepInSection) {
            this.props.history.push(dashboardLink(this.getCurrentSectionId(), this.state.currentApplicantId));
            return;
        }

        let nextSectionId;
        let nextApplicantId = this.state.currentApplicantId;
        let applicantIds = this.props.adapter.applicantIds();

        if (this.props.adapter.sectionComplete(this.getCurrentSectionId())) {
            nextSectionId = nextSectionInWorkflow(this.getCurrentSectionId());
        } else {
            // Don't go anywhere if current section is not complete
            nextSectionId = this.getCurrentSectionId();
        }

        // Applicant id is needed only for SITUATION.
        if (model[nextSectionId].hasApplicants) {
            if (nextSectionId !== this.getCurrentSectionId()
                || this.props.adapter.sectionComplete(nextSectionId, this.state.currentApplicantId)) {

                for (let applicantId of applicantIds) {
                    if (!this.props.adapter.sectionComplete(nextSectionId, applicantId)) {
                        nextApplicantId = applicantId;
                        break;
                    }
                }

                // Means we're supposed to show situation but somehow situation is already complete for all
                // applicants. It could happen if there were changes in screening and there were validation errors
                if (nextApplicantId === null || nextApplicantId === undefined) {
                    nextApplicantId = applicantIds[0];
                }
            }
        } else {
            nextApplicantId = null;
        }

        this.props.history.push(dashboardLink(nextSectionId, nextApplicantId));
    };


    getNextLabel() {
        return null;
    };

    render() {
        let controlButtons = (
            <ControlButtons
                nextPage={() => this.swiperNext()}
                previousPage={() => this.swiperPrevious()}
                nextLabel={this.getNextLabel()}
            />
        );

        this.groupAddableRef = React.createRef();

        return (
            <React.Fragment>
                <div className="container bg-grey">
                    <AnimatePresence
                        initial={false}
                        custom={this.state.direction}
                    >
                        <motion.div
                            key={this.state.slideIds.length}
                            custom={this.state.direction}
                            variants={variants}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            transition={{
                                y: { duration: 0 },
                                x: { type: "spring", duration: 0.5, bounce:0 /*stiffness: 300, damping: 200 */ },
                                opacity: { duration: 0.2 }
                            }}
                            style={{
                                width: "1350px",
                                position: "absolute",
                                maxWidth: "90%"
                            }}
                        >
                            {
                                this.getQuestion(this.activeSlideId())
                                    .block({
                                        controlButtons,
                                        error: this.state.validationContext ? this.state.validationContext.error() : undefined,
                                        groupAddableRef: this.groupAddableRef,
                                        readOnly: this.getReadOnly(),
                                        reachedStage: this.props.adapter.reachedStage()
                                    })
                            }
                        </motion.div>
                    </AnimatePresence>
                </div>
            </React.Fragment>
        );
    }
}

export default MultiBlockForm;