import React, { Component } from 'react';
import PropTypes from "prop-types";

class FieldCheckbox extends Component {
    static defaultProps = {
        disabled:false,
        show: true
    };

    show = () => {
        if (this.props.show === undefined)
            return true;

        if (typeof this.props.show === 'boolean' && !this.props.show) {
            return false;
        }

        if (typeof this.props.show === 'function'
            && !this.props.show(this.props.fieldsContainer)) {
            return false;
        }

        return true;
    };

    // TODO: Make field checkbox inherit from Field, remove this
    validityClasses = () => {
        return (this.props.error ? " is-invalid" : "") + (this.props.valid ? " is-valid" : "");
    };

    // TODO: Make field checkbox inherit from Field, remove this
    renderError = () => {
        return this.props.error &&
            <div key='error-message' className="invalid-feedback">{this.props.error}</div>;
    };

    click = () => {
        if (!this.props.disabled) {
            this.props.onCheckboxClick(this.props.code)
        }
    }

    render() {

        if (!this.show()) {
            return null;
        }

        let clazz = "form-check custom-control custom-checkbox";
        clazz += this.props.checked ? ' active ' : ' inactive ';
        clazz += this.props.disabled ? ' disabled ': ' ' ;

        clazz += this.validityClasses();

        return (
            <React.Fragment>
            <div className={clazz}>
                <input type="checkbox"
                    className="form-check-input custom-control-input"
                    id={this.props.field + '-' + this.props.code}
                    name={this.props.field}
                    value={this.props.code}
                    checked={this.props.checked}
                    readOnly={true}
                    disabled={this.props.disabled}
                    onClick={this.click}
                />
                {
                    !!this.props.label &&
                    <label className="form-check-label custom-control-label "
                        htmlFor={this.props.field + '-' + this.props.code}
                    >
                        {this.props.label}
                    </label>
                }
            </div>
            {
                this.renderError()
            }
            </React.Fragment>
        );
    }
}

FieldCheckbox.propTypes = {
    code: PropTypes.any.isRequired,
    show: PropTypes.any,
    label: PropTypes.any,
    field: PropTypes.string,
    onCheckboxClick: PropTypes.func,
    checked: PropTypes.bool,
    disabled:PropTypes.bool,
    error: PropTypes.string,
    valid: PropTypes.bool,
};

export default FieldCheckbox;