import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldInput from "common-form/components/FieldInput";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldGroup from "../../../../../form/components/FieldGroup";
import FieldTextArea from "../../../../../form/components/FieldTextArea";
import {FieldDropdown} from "../../../../../form/components/dropdown";
import { LABEL_TYPE } from "../../../../../form/components/Field";
import {extractFieldName, FIELDS, OTHER} from "common-hypotheca";
import FinancialTable from "./FinancialTable";
import { validatePartnerDetails } from "./validation";

export default function PartnerBasic(parent) {

    return {
        block: (params)=>
            <QuestionBlock {...params}>

                <FieldGroup
                    ref={params.groupAddableRef}
                    item={parent.getCurrentEmployment()}
                    {...parent.getField(FIELDS.MS.EI.Employments)}
                    validate={(context) => {

                        validatePartnerDetails(parent, context, parent.state.employmentIndex);

                    }}

                >

                    <FieldRadioYesNo
                        field={FIELDS.MS.EI.PartnerDoYouHaveAccountant}
                    />

                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.PartnerDoYouHaveAccountant)] === true}
                        field={FIELDS.MS.EI.PartnerAccountantName}
                    />

                    <FieldDropdown
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.PartnerDoYouHaveAccountant)] === true}
                        field={FIELDS.MS.EI.PartnerAccountantQual}
                    />

                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.PartnerDoYouHaveAccountant)] === true &&
                            container[extractFieldName(FIELDS.MS.EI.PartnerAccountantQual)] === OTHER}
                        placeholder="Other qualification description"
                        field={FIELDS.MS.EI.PartnerAccountantQualOther}
                    />

                    <FieldRadioYesNo
                        field={FIELDS.MS.EI.PartnerDoYouProdAccts}
                    />

                    <FinancialTable
                        container={parent.getCurrentEmployment()}
                        field={FIELDS.MS.EI.Sharings}
                        periodField={FIELDS.MS.EI.SharingPeriod}
                        currencyFields={[
                            {
                                ...FIELDS.MS.EI.SharingTotalDrawings,
                                allowNegative: true,
                                tooltip: "Enter your share of profit figure here. This will most likely be the same as profit figure declared on your tax return."
                            }
                        ]}
                        validate={parent.state.nextTriggered}
                        onChange={parent.saveEmployments}
                        onValidity={(context) => {
                            parent.setState({
                                financialValid: context.isValid
                            });
                        }}

                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.EI.PartnerBusinessIncome}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.PartnerBusinessIncome)] === true}
                        field={FIELDS.MS.EI.PartnerBusinessIncomeChangedSignificantly}
                    />

                    <FieldTextArea
                        labelType={LABEL_TYPE.Small}
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.PartnerBusinessIncome)] === false}
                        placeholder="For example, has the business ceased trading ..."
                        field={FIELDS.MS.EI.PartnerBusinessIncomeNoReason}
                    />

                    <FieldTextArea
                        labelType={LABEL_TYPE.Small}
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.PartnerBusinessIncomeChangedSignificantly)] === true && 
                            container[extractFieldName(FIELDS.MS.EI.PartnerBusinessIncome)] === true }
                        placeholder="For example, we sold the main division ..."
                        field={FIELDS.MS.EI.PartnerBusinessIncomeChangedReason}
                    />


                </FieldGroup>

            </QuestionBlock>,

        validate: (context) => {
                    
            if (!parent.state.financialValid) {
                context.addError('financials', null, '');
            }

        },

        nextQuestionId: () => parent.afterEmploymentCompleted()
    };


};