import React, {Fragment} from 'react';
import {tryLoadPreviousApplicationOrWelcome, loadingApplication, dashboardLink} from "../util/links";
import Error from './Error';
import Panel from './panel/Panel';
import SideMenu from './sideMenu/SideMenu';
import Header from "../welcome/Header";
import CloseIcon from "../form/icons/CloseIcon";
import FormButton from "../form/components/FormButton";
import Loading from "../form/icons/Loading";
import {
    SECTION
} from "common-hypotheca";
import {getValuePossiblyInteger} from "../util/values";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayJourneyMobileMenu: false
        };
    }

    componentDidMount() {
        if (this.props.applicationId !== null && this.props.applicationId !== undefined) {
            let user = this.props.user;
            user.lastApplicationId = this.props.applicationId;
            this.props.setUserData(user);
        }
    }

    componentDidUpdate(prevProps) {
        // console.log('dashboard update', prevProps, this.props, this.state)

        //extract display info like names.
    }

    handleGotoSection = section => {
        this.props.history.push(dashboardLink(section.id, section.applicantId));
    };

    static getDerivedStateFromProps(props, state) {
        tryLoadPreviousApplicationOrWelcome(props);

        // Redirect to screening if trying to access
        if (props.data && !props.adapter.sectionAvailable(props.match.params.sectionId)) {
            props.history.push(dashboardLink(SECTION.SCREENING));
        }

        return null;
    }

    displayJourneyMobileMenu = (value) => {
        this.setState({
            displayJourneyMobileMenu: value
        })
    };

    render() {
        if (loadingApplication(this.props))
            return <Loading/>;

        if (!this.props.data || this.props.applicationId === null || this.props.sectionId === null) { //find a better way for this
            return <Error />
        }

        let journeyMobileButton = (
            <span className='journey-mobile-menu-button d-inline-block d-md-none'>
                <FormButton link secondary leftMaterialIcon='format_list_bulleted' onClick={() => this.displayJourneyMobileMenu(true)}/><span className='material-icon'>chevron_right</span>
            </span>
        );

        let combinedProps = {
            ...this.props,
            applicantId: getValuePossiblyInteger(this.props.match.params.applicantId),
            sectionId: getValuePossiblyInteger(this.props.match.params.sectionId)
        };

        return (
            <Fragment>
                <div className="container full-height">
                    <div className="row full-height">
                        <div className={'d-none d-md-block col-md-3 full-height p-md-1 p-lg-4'}>
                            <SideMenu
                                {...combinedProps}
                                gotoSection={section => this.handleGotoSection(section)}
                            />
                        </div>
                        <div className={'col-12 col-md-9 bg-grey dashboard-panel'}>
                            <Header {...combinedProps} type={Header.TYPES.DASHBOARD}/>
                            <div className='row'>
                                <div className='col-12'>
                                    <Panel journeyMobileButton={journeyMobileButton} {...combinedProps} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.displayJourneyMobileMenu &&
                    <div className="d-block d-md-none journey-mobile-menu-backdrop"
                         onClick={() => this.displayJourneyMobileMenu(false)}>
                        <div className="journey-mobile-menu-contents"
                             role="document"
                             onClick={(e) => e.stopPropagation()}>
                            <div className='journey-mobile-menu-close-container float-end'>
                                <CloseIcon size={CloseIcon.SIZES.LARGE} onClick={() => this.displayJourneyMobileMenu(false)}/>
                            </div>
                            <SideMenu
                                {...combinedProps}
                                gotoSection={section => {
                                    this.handleGotoSection(section);
                                    this.displayJourneyMobileMenu(false);
                                }}
                            />
                        </div>
                    </div>
                }
            </Fragment>
        );
    }
}

export default Dashboard;