import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import {FieldRadioPicture} from "../../../../../form/components/radio";
import {FieldLabel} from "common-form/components/FieldLabel";
import {Alert, ALERTTYPE} from "../../../../../form/components/alert/Alert";

import {
    FIELDS,
    SOLICITOR_CHOICE_TYPE
} from "common-hypotheca";


export default function SolicitorChoice(parent, nextQuestionId) {
    
    return {
        block: (params) =>

            <QuestionBlock {...params}>

                <FieldLabel
                    label="Regarding a solicitor to handle the transaction, please tell us your preference"
                />

                <FieldRadioPicture
                    {...parent.getField(FIELDS.MA.SSI.SolicitorChoice)}
                />

                <Alert
                    text="Thank you for letting us know. Your advisor will be in touch shortly."
                    show={() => parent.getValue(FIELDS.MA.SSI.SolicitorChoice) === SOLICITOR_CHOICE_TYPE.FIND_ONE}
                    type={ALERTTYPE.SUCCESS}
                />

            </QuestionBlock>,


        validate: (context) => {
            context
            .mandatory(FIELDS.MA.SSI.SolicitorChoice)
            ;
            
            if (context.fieldValue(FIELDS.MA.SSI.SolicitorChoice) !== SOLICITOR_CHOICE_TYPE.MY_OWN) {
                context.cleanupIrrelevantField(FIELDS.MA.SSI.SolicFirm);
                context.cleanupIrrelevantField(FIELDS.MA.SSI.SolicName);
                context.cleanupIrrelevantField(FIELDS.MA.SSI.SolicNumber);
                context.cleanupIrrelevantField(FIELDS.MA.SSI.SolicFaxNumber);
                context.cleanupIrrelevantField(FIELDS.MA.SSI.SolicDXNumber);
                context.cleanupIrrelevantField(FIELDS.MA.SSI.SolicEmailAddr);
                context.cleanupIrrelevantField(FIELDS.MA.SSI.SolicAddressLine1);
                context.cleanupIrrelevantField(FIELDS.MA.SSI.SolicAddressLine2);
                context.cleanupIrrelevantField(FIELDS.MA.SSI.SolicTown);
                context.cleanupIrrelevantField(FIELDS.MA.SSI.SolicCounty);
                context.cleanupIrrelevantField(FIELDS.MA.SSI.SolicPostcode);
            }

        },

        nextQuestionId: nextQuestionId
    };

};