export const _PREFERRED_MORTGAGE_TYPE = {
    INTEREST_ONLY: 'interest_only',
    REPAYMENT: 'repayment'
};

_PREFERRED_MORTGAGE_TYPE.OPTIONS = [
    {
        code: _PREFERRED_MORTGAGE_TYPE.INTEREST_ONLY,
        text: 'Interest only, as I have my own repayment plan'
    },
    {
        code: _PREFERRED_MORTGAGE_TYPE.REPAYMENT,
        text: 'Repayment, as I want the loan to be repaid at the end of its term'
    }
];

_PREFERRED_MORTGAGE_TYPE.OPTIONS_ADMIN = [
    {
        code: '',
        text: ''
    },
    {
        code: _PREFERRED_MORTGAGE_TYPE.INTEREST_ONLY,
        text: 'Interest Only'
    },
    {
        code: _PREFERRED_MORTGAGE_TYPE.REPAYMENT,
        text: 'Repayment'
    }
];
