import {connect} from "react-redux";
import { APINAME, APIPATH } from "../constants";
import { setData, savedApplication } from '../redux/actions'
import { API } from "aws-amplify";
import { Component } from "react";
import uuidv1 from 'uuid/v1';
import {apiExtra} from "../util/wrap";

class PersistApplicationService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastSaved:null
        }
    }

    persistStateDataTimeout;
    versionerUuid = uuidv1();

    failures = 0;

    componentDidUpdate(prevProps) {
        if (!this.props.loggedIn) {
            return;
        }

        if (!this.props.data) {
            return;
        }

        // Ignore data changes that are not supposed to be saved
        if (this.props.data.lastChanged === this.props.preventPersistForLastChanged) {
            return;
        }

        //TODO stop it saving after it does a get

        this.schedule(1000)
    };

    schedule(timeout) {
        clearTimeout(this.persistStateDataTimeout);

        this.persistStateDataTimeout = setTimeout(() => {
            this.persistStateData()
        }, timeout);
    }

    persistStateData() {
        this.props.savedApplication(true, this.failures);
        let data = this.props.data;

        if (data.time === undefined)
            data.time = {};

        data.time.lastUpdated = Date.now();
        data.time.versionerUuid = this.versionerUuid;

        //    if(data.status === APPLICATION_STATUS.INITIAL){
        //     data.status = APPLICATION_STATUS.STARTED
        //     console.log('changed status to started')
        //    }

        API.post(APINAME, `${APIPATH.APPLICATION}/${data.applicationId}`, {
            body: data,
            ...apiExtra()
        })
            .then(response => {
                this.failures = 0;
                if (response.success) {
                    data.time.lastSaved = response.lastSaved;
                    this.props.savedApplication(response.lastSaved, this.failures);
                } else {    
                    console.log('Data sync error',response);

                    if (response.apiExtraFailure) {
                        this.props.savedApplication(false, false, response.error);
                    } else if (response.which === "2") {
                        this.getStateData();
                        // This causes the frontend to load the current state of the DB if it fails to save.
                        // This can get into a loop if the reason for saving it is not because of lastsavetime error
                    }
                   
                   // this.getStateData()
                }
            })
            .catch(error => {
                this.failures++;
                console.log(error);
                console.log('Failures: ' + this.failures);
                this.props.savedApplication(false, this.failures);
                this.schedule(20000);
            });
            // Finally not available on MS Edge
    }

    getStateData() { //for dev mode, loading a current appliction
        //  console.log('getting applicaiton data', this.props.applicationId)
        API.get(APINAME, `${APIPATH.APPLICATION}/${this.props.applicationId}`, apiExtra())
            .then(response => {
                this.props.setData(response.Item, true);
                this.setState({
                    lastSaved: response.Item.time.lastSaved
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    render() {
        return '';
    }
}

const mapStateToProps = (state, ownProps) => ({
    data: state.data,
    preventPersistForLastChanged: state.preventPersistForLastChanged,
    applicationId: state.applicationId,
    loggedIn:state.loggedIn
});

const mapDispatchToProps = dispatch => ({
    setData: (data, preventPersist) => dispatch(setData(data, preventPersist)),
    savedApplication: (when, failures, error) => dispatch(savedApplication(when, failures, error))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersistApplicationService)
