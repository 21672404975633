import * as React from "react";
import { CAN_SUBMIT } from "../../../../constants";
import MultiBlockFormSingleApplicant from "../../../../form/MultiBlockFormSingleApplicant";
import QuestionBlock from "../../../../form/QuestionBlock";
import FieldLabel from "common-form/components/FieldLabel";
import FormButton from "../../../../form/components/FormButton";
import FormImage, {IMAGE_BUDGET} from "../../../../form/components/FormImage";
import { LABEL_TYPE } from "../../../../form/components/Field";

import {
    SECTION,
    SITUATION
} from "common-hypotheca";

import EssentialItems from "./outgoings/EssentialItems";
import LifeItems from "./outgoings/LifeItems";
import ErrorPart from "../../../ErrorPart";

const BLOCK_WELCOME = 1;
const BLOCK_ESSENTIAL_ITEMS = 2;
const BLOCK_LIFE_ITEMS = 3;


class Outgoings extends MultiBlockFormSingleApplicant {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            sectionId: SECTION.SITUATION,
            partId: SITUATION.OUTGOINGS,

            slideIds: [BLOCK_WELCOME],
            activeSlideId: BLOCK_WELCOME
        };
    }

    /**
     * @returns {number} of expected slides for this part.
     */
    slidesExpected = () => {
        return 3;
    };

    checkForCompleteState = data => {
        this.partContainer().isComplete = true;
        this.currentSectionObject().isComplete = true;
        return data;
    };

    getField = (fieldName) => {
        return this.getFormField(null, fieldName);
    };

    getValue = (fieldName) => {
        return this.getField(fieldName).value;
    };

    shouldShowSharingCheck = () => {
        return this.getApplicantCount() > 1;
    };

    getQuestion = which => {
        switch(which) {

            case BLOCK_WELCOME:
                return {
                    block: (params)=>
                        <QuestionBlock {...params} controlButtons={null}>

                            <FieldLabel
                                label={this.getApplicantName() + ", let's talk about your other outgoings"}
                            />

                            <FieldLabel
                                label="We now need to run through your regular outgoings, categorised into two sections known as Essential costs and Quality of life costs. 
                                    This will allow us to get a complete picture of your living expenses in order to assess your general affordability. 
                                    Your advisor will need to cross check some of these payments to your household bank statements, so try to be as accurate as you can."
                                labelType={LABEL_TYPE.Description}
                            />

                            <FormButton
                                text="OK, let's go"
                                onClick={() => this.swiperNext()}
                            />
                            <FormImage imageType={IMAGE_BUDGET}/>
                        </QuestionBlock>,
                    canNext: () => true,
                    nextQuestionId: () => BLOCK_ESSENTIAL_ITEMS
                };

            case BLOCK_ESSENTIAL_ITEMS:
                return EssentialItems(this, BLOCK_LIFE_ITEMS);

            case BLOCK_LIFE_ITEMS:
                return LifeItems(this, CAN_SUBMIT);

            default:
                return ErrorPart();
        }
    };

}

export default Outgoings;
