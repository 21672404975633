export const _PROPERTY_TENURE = {
    FREEHOLD: 'freehold',
    LEASEHOLD: 'leasehold',
    COMMON_HOLD: 'common_hold',
    HERITABLE: 'heritable'
};

_PROPERTY_TENURE.OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: _PROPERTY_TENURE.FREEHOLD,
        text: 'Freehold'
    },
    {
        code: _PROPERTY_TENURE.LEASEHOLD,
        text: 'Leasehold'
    },
    {
        code: _PROPERTY_TENURE.COMMON_HOLD,
        text: 'Commonhold'
    },
    {
        code: _PROPERTY_TENURE.HERITABLE,
        text: 'Heritable for Scotland'
    }
];
