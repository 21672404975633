import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";

import {
    FIELDS,
} from "common-hypotheca";


export default function AskMoreProperties(parent, nextQuestionId) {

    return {
        block: (params)=>
            <QuestionBlock {...params}>
                <FieldRadioYesNo
                    label= {parent.getOtherPropertiesQuestion()}
                    {...parent.getField(FIELDS.MS.AS.MainResAndHHOwnMoreProps)}
                />
           </QuestionBlock>,

        validate: (context) => {
            context.mandatory(FIELDS.MS.AS.MainResAndHHOwnMoreProps);

            if (parent.fieldHasValueFalse(null, FIELDS.MS.AS.MainResAndHHOwnMoreProps)) {
                context.cleanupIrrelevantField(FIELDS.MS.AS.AddProperties);
            }
        },
        nextQuestionId: () => {
            return nextQuestionId(parent.getValue(FIELDS.MS.AS.MainResAndHHOwnMoreProps) === true);
        }
    };
  
};