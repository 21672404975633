import * as React from "react";

import {
    validateEmployment
} from './validation';


import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldLabel from "common-form/components/FieldLabel";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FormButton from "../../../../../form/components/FormButton";
import { LABEL_TYPE } from "common-form/components/Field";
import ValidationContext from "common-form/framework/ValidationContext";
import { Alert, ALERTTYPE } from '../../../../../form/components/alert/Alert';


export default function EmploymentList(parent) {

    return {

        onLoad: () => {

            parent.validateAllEmployments();

            parent.setState({
                addNewEmployment: null,
                editing: false
            });

            parent.resetSlides();
            parent.ensureNotSingleRetired();

        },

        block: (params) => {

            const employments = parent.getEmployments();

            const empBlocks = [];

            for (let i = 0; i < employments.length; i++) {

                const employment = employments[i];

                const desc = parent.getEmploymentDescription(employment);

                let isValid = true;

                if (!parent.state.editing) {

                    const context = new ValidationContext(() => employment);
                    validateEmployment(parent, context, i);
                    context.finishAll();
    
                    isValid = !context.hasErrors();    
                }

                empBlocks.push(
                    <div key={i}>
                        <div className={`row ${!isValid ? 'row-group-invalid ' : ''}add-field align-items-center`}>

                            <div className="col-sm-3">
                                {desc.name}
                            </div>

                            <div className="col-sm-3">
                                {desc.from}
                            </div>

                            <div className="col-sm-3">
                                {desc.to}
                            </div>

                            <div className="col-sm-3">
                                {
                                    <FormButton
                                        type="group-addable-edit"
                                        onClick={() => parent.editEmployment(i)}
                                        text="Edit"
                                    />
                                }
                                {
                                    i > 0 &&
                                    <FormButton
                                        type="group-addable-delete"
                                        onClick={() => parent.deleteEmployment(i)}
                                        text="Remove"
                                    />
                                }
                            </div>
                        </div>

                        <hr />

                    </div>
                );
            }


            return <QuestionBlock {...params}>

                <FieldLabel
                    label="Your employment history"
                />

                {empBlocks}

                <Alert
                    type={ALERTTYPE.DANGER}
                    show={!parent.state.employmentsValid}
                    text={`Please edit the red coloured items above and complete all missing information.`}
                />


                <FieldRadioYesNo
                    label="Do you have any other current jobs, or, have you had any other jobs over the last 2 years?"
                    labelType={LABEL_TYPE.Small}
                    field="addNewEmployment"
                    show={parent.state.employmentsValid}
                    value={parent.state.addNewEmployment}
                    handleFieldFilled={(ap, fld, value) => {
                        parent.setState({
                            addNewEmployment: value
                        });
                    }}
                />

            </QuestionBlock>
                ;
        },

        validate: (context) => {

            if (!parent.state.editing) {
                if (!parent.state.employmentsValid) {
                    context.addError(null, null, 'Please correct invalid employments');
                } else if (parent.state.addNewEmployment == null) {
                    context.addError(null, null, 'Please answer the question');
                }    
            }
        },

        nextQuestionId: () => parent.afterEmploymentList()
    };

};