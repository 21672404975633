import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";

import { Alert } from '../../../../../form/components';
import { ALERTTYPE } from '../../../../../form/components/alert/Alert';
import FieldDate from "common-form/components/FieldDate";
import FieldInput from "common-form/components/FieldInput";
import FieldRadioGroup from "../../../../../form/components/radio/FieldRadioGroup";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldDropdown from "../../../../../form/components/dropdown/FieldDropdown";
import FieldGroupAddable from "../../../../../form/components/FieldGroupAddable";
import { LABEL_TYPE } from "../../../../../form/components/Field";
import FieldLabel from "common-form/components/FieldLabel";
import AddressLookup from "../../../../../form/components/AddressLookup";
import CopyApplicantsData from "../../../../../form/components/CopyApplicantsData";

import { formatCurrency } from "../../../../../util/currency";

import {
    FIELDS,
    OWNER_TYPE,
    PROPERTY_USAGE,
    extractFieldName,
} from "common-hypotheca";


export default function PropertyList(parent, nextQuestionId) {

    function getPropertyDescription(property) {

        const propertyUsage = property[FIELDS.MS.AS.AddPropertyPropertyUsage] ?
            PROPERTY_USAGE.OPTIONS.find(p => p.code === property[FIELDS.MS.AS.AddPropertyPropertyUsage]).text : '';

        return (
            <div className="row">
                <div className="col-sm-4">
                    {property[FIELDS.MS.AS.AddPropertyAddressLine1.name] || 'Unnamed property'}
                </div>
                <div className="col-sm-4">
                    {property[FIELDS.MS.AS.AddPropertyMktValue.name] ? formatCurrency(property[FIELDS.MS.AS.AddPropertyMktValue.name]) : '£TBC'}
                </div>
                <div className="col-sm-4">
                    {propertyUsage}
                </div>
            </div>
        );
    }


    function isPropertyUsageBuyToLet(container) {
        return container[FIELDS.MS.AS.AddPropertyPropertyUsage.name] === PROPERTY_USAGE.BUY_TO_LET_RENTED;
    }

    function isPropertyUsageHolidayLet(container) {
        return container[FIELDS.MS.AS.AddPropertyPropertyUsage.name] === PROPERTY_USAGE.HOLIDAY_LET;
    }

    return {

        block: (params) =>

            <QuestionBlock {...params}>

                <FieldLabel
                    label="Please tell us about each additional property that you own"
                />

                <FieldLabel
                    label="If you have more than one additional property, then simply complete the first page and then click + Add new property to add another."
                    labelType={LABEL_TYPE.Description}
                />

                <FieldLabel
                    label="If the property is not located in the UK, then please continue to use the form below, but input the address in the best way you can."
                    labelType={LABEL_TYPE.Description}
                />

                <CopyApplicantsData
                    label="Copy an existing additional property from:"
                    sectionId={parent.state.sectionId}
                    partId={parent.state.partId}
                    currentApplicantId={parent.state.currentApplicantId}
                    adapter={parent.props.adapter}
                    field={parent.getField(FIELDS.MS.AS.AddProperties)}
                    emptyLabel="Select property ..."
                    selectSingleValues
                    formatSingleValue={(applicant, container) => {
                        return `${applicant.applicantName} - ${container[extractFieldName(FIELDS.MS.AS.AddPropertyAddressLine1)]} - ${container[extractFieldName(FIELDS.MS.AS.AddPropertyPostcode)]}`;
                    }}
                />

                <hr/>

                <FieldGroupAddable
                    allowDeleteAll={true}
                    ref={params.groupAddableRef}
                    addButtonLabel="Add new property"
                    deleteButtonLabel="Remove"
                    editButtonLabel="Edit"
                    saveButtonLabel="Save property"
                    itemDescriber={(fieldsContainer) => {
                        return getPropertyDescription(fieldsContainer);
                    }}
                    validate={(context) => {

                        let hasMortgage = !!context.fieldValue(FIELDS.MS.AS.AddPropertyHaveMortgage);

                        context
                            .date(FIELDS.MS.AS.AddPropertyDatePurchased)
                            .mandatory(FIELDS.MS.AS.AddPropertyAddressLine1)
                            .optional(FIELDS.MS.AS.AddPropertyAddressLine2)
                            .mandatory(FIELDS.MS.AS.AddPropertyTown)
                            .optional(FIELDS.MS.AS.AddPropertyCounty)
                            .mandatory(FIELDS.MS.AS.AddPropertyPostcode)
                            .mandatory(FIELDS.MS.AS.AddPropertyOwnedBy)
                            .mandatory(FIELDS.MS.AS.AddPropertyMktValue)
                            .mandatory(FIELDS.MS.AS.AddPropertyPropertyType)
                            .mandatory(FIELDS.MS.AS.AddPropertyPropertyUsage)
                            .mandatoryOrCleanup(
                                PROPERTY_USAGE.showDetails(context.fieldValue(FIELDS.MS.AS.AddPropertyPropertyUsage)),
                                FIELDS.MS.AS.AddPropertyPropertyUsageDetails,
                            )
                            .mandatory(FIELDS.MS.AS.AddPropertyHaveMortgage)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.AS.AddPropertyOwnedBy) === OWNER_TYPE.SHARED,
                                FIELDS.MS.AS.AddPropertyPcTOwned)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.AS.AddPropertyPropertyUsage) === PROPERTY_USAGE.BUY_TO_LET_RENTED,
                                [FIELDS.MS.AS.AddPropertyBTLMonthlyIncome, FIELDS.MS.AS.AddPropertyBTLCoversCosts])
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.AS.AddPropertyPropertyUsage) === PROPERTY_USAGE.HOLIDAY_LET,
                                [FIELDS.MS.AS.AddPropertyHolLetMonthlyIncome, FIELDS.MS.AS.AddPropertyHolLetCoversCosts])
                            .mandatoryOrCleanup(
                                hasMortgage,
                                [
                                    FIELDS.MS.AS.AddPropertyMortProviderName,
                                    FIELDS.MS.AS.AddPropertyMortProviderAccNo,
                                    FIELDS.MS.AS.AddPropertyMortTermYears,
                                    FIELDS.MS.AS.AddPropertyMortBorrowingAmount,
                                    FIELDS.MS.AS.AddPropertyMortType,
                                    FIELDS.MS.AS.AddPropertyMortCurrentBalOS,
                                    FIELDS.MS.AS.AddPropertyMortCurrentMonthPay,
                                    FIELDS.MS.AS.AddPropertyMortSellToPayOff
                                ])
                            .dateOrCleanup(hasMortgage, FIELDS.MS.AS.AddPropertyMortStartDate)
                            .mandatoryOrCleanup(
                                hasMortgage && context.fieldValue(FIELDS.MS.AS.AddPropertyMortSellToPayOff),
                                FIELDS.MS.AS.AddPropertyMortSelltoPayOffSalePrice)
                            .mandatoryOrCleanup(
                                hasMortgage && !context.fieldValue(FIELDS.MS.AS.AddPropertyMortSellToPayOff),
                                FIELDS.MS.AS.AddPropertyMortWillBeReleased
                            )
                            .mandatoryOrCleanup(
                                hasMortgage
                                && !context.fieldValue(FIELDS.MS.AS.AddPropertyMortSellToPayOff)
                                && context.fieldValue(FIELDS.MS.AS.AddPropertyMortWillBeReleased),
                                FIELDS.MS.AS.AddPropertyMortWillBeReleasedDescr
                            )
                            .mandatoryOrCleanup(
                                hasMortgage,
                                FIELDS.MS.AS.AddPropertyMortSecondCharge,
                            )
                            .mandatoryOrCleanup(
                                hasMortgage && context.fieldValue(FIELDS.MS.AS.AddPropertyMortSecondCharge),
                                [
                                    FIELDS.MS.AS.AddPropertyMortSecondChargeName,
                                    FIELDS.MS.AS.AddPropertyMortSecondChargeBalance,
                                    FIELDS.MS.AS.AddPropertyMortSecondChargeMonthlyPayment        
                                ]
                            )
                            ;
                    }}
                    {...parent.getField(FIELDS.MS.AS.AddProperties)}
                >

                    <AddressLookup
                        label="Property address"
                        labelType={LABEL_TYPE.Small} 
                        fields={[
                            FIELDS.MS.AS.AddPropertyAddressLine1, 
                            FIELDS.MS.AS.AddPropertyAddressLine2,
                            FIELDS.MS.AS.AddPropertyTown,
                            FIELDS.MS.AS.AddPropertyCounty,
                            FIELDS.MS.AS.AddPropertyPostcode
                        ]}
                    />

                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyDatePurchased}
                    />

                    <FieldRadioGroup
                        labelType={LABEL_TYPE.Small}
                        inline={true}
                        field={FIELDS.MS.AS.AddPropertyOwnedBy}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyOwnedBy.name] === OWNER_TYPE.SHARED}
                        field={FIELDS.MS.AS.AddPropertyPcTOwned}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMktValue}
                    />

                    <FieldDropdown
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyPropertyType}
                    />

                    <FieldDropdown
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyPropertyUsage}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        show={(container) => PROPERTY_USAGE.showDetails(container[FIELDS.MS.AS.AddPropertyPropertyUsage.name])}
                        field={FIELDS.MS.AS.AddPropertyPropertyUsageDetails}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        show={(container) => isPropertyUsageBuyToLet(container)}
                        field={FIELDS.MS.AS.AddPropertyBTLMonthlyIncome}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        show={(container) => isPropertyUsageBuyToLet(container)}
                        field={FIELDS.MS.AS.AddPropertyBTLCoversCosts}
                    />

                    <Alert
                        text="You can tell us what profit you make in the section called “Employment and other income!”"
                        show={(container) => isPropertyUsageBuyToLet(container) && container[FIELDS.MS.AS.AddPropertyBTLCoversCosts.name] === true }
                        type={ALERTTYPE.INFO}
                        field={FIELDS.MS.AS.AddPropertyBTLCoversCosts}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyHolLetMonthlyIncome}
                        show={(container) => isPropertyUsageHolidayLet(container)}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyHolLetCoversCosts}
                        show={(container) => isPropertyUsageHolidayLet(container)}
                    />

                    <Alert
                        text="You can tell us what profit you make in the section called “Employment and other income!”"
                        show={(container) => isPropertyUsageHolidayLet(container) && container[FIELDS.MS.AS.AddPropertyHolLetCoversCosts.name] === true }
                        type={ALERTTYPE.INFO} 
                        field={FIELDS.MS.AS.AddPropertyBTLCoversCosts}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyHaveMortgage}
                    />

                    <FieldLabel
                        field={FIELDS.MS.AS.AddPropertyHaveMortgage}
                        label="Please tell us about your other property mortgage"
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMortProviderName}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMortProviderAccNo}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true}
                    />

                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMortStartDate}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMortTermYears}
                        type="number"
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMortBorrowingAmount}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true}
                    />

                    <FieldRadioGroup
                        labelType={LABEL_TYPE.Small}
                        inline={true}
                        field={FIELDS.MS.AS.AddPropertyMortType}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMortCurrentBalOS}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMortCurrentMonthPay}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMortSecondCharge}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMortSecondChargeName}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true &&
                            container[FIELDS.MS.AS.AddPropertyMortSecondCharge.name] === true}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMortSecondChargeBalance}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true &&
                            container[FIELDS.MS.AS.AddPropertyMortSecondCharge.name] === true}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMortSecondChargeMonthlyPayment}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true &&
                            container[FIELDS.MS.AS.AddPropertyMortSecondCharge.name] === true}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMortSellToPayOff}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMortSelltoPayOffSalePrice}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true &&
                            container[FIELDS.MS.AS.AddPropertyMortSellToPayOff.name] === true}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMortWillBeReleased}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true &&
                            container[FIELDS.MS.AS.AddPropertyMortSellToPayOff.name] === false}
                    />

                    <FieldInput
                        label="Please provide us with more details"
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.AS.AddPropertyMortWillBeReleasedDescr}
                        show={(container) => container[FIELDS.MS.AS.AddPropertyHaveMortgage.name] === true &&
                            container[FIELDS.MS.AS.AddPropertyMortSellToPayOff.name] === false &&
                            container[FIELDS.MS.AS.AddPropertyMortWillBeReleased.name] === true}
                    />

                </FieldGroupAddable>

                
            </QuestionBlock>,

        validate: (context) => {

            if (!context.hasItems(FIELDS.MS.AS.AddProperties)) {
                context.addError(null, null, 'Please enter a property or go back to the previous screen and change your selection.');
            }

            if (parent.groupAddableRef && !parent.groupAddableRef.current.valid()) {
                context.addError(FIELDS.MS.AS.AddProperties, null, '');
            }

        },

        nextQuestionId: nextQuestionId
    };


};