import React from 'react';
import FormButton from '../form/components/FormButton';
import {welcomeLink} from "../util/links";


class Error extends React.Component {
   
    render() {
      

        return (
            <div className="row">
                
                <div className={'col-12 '}>
                <p>Error - no application data loaded or url is 404 </p>
                <FormButton text='Go home' path={welcomeLink()}/>
                </div>
            </div>
        );
    }
}

export default Error;