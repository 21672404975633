import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldDate from "common-form/components/FieldDate";
import FieldLabel from "common-form/components/FieldLabel";
import FieldInput from "common-form/components/FieldInput";
import FieldRadioGroup from "../../../../../form/components/radio/FieldRadioGroup";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldDropdown from "../../../../../form/components/dropdown/FieldDropdown";
import CopyApplicantsData from "../../../../../form/components/CopyApplicantsData";
import Alert, { ALERTTYPE } from "../../../../../form/components/alert/Alert";

import {
    FIELDS,
    OWNER_TYPE
} from "common-hypotheca";
import { LABEL_TYPE } from "../../../../../form/components/Field";


export default function CurrentProperty(parent, nextQuestionId) {

    function isOwnedShared() {
        return parent.getField(FIELDS.MS.AS.CurrentAddrOwnedBy).value === OWNER_TYPE.SHARED;
    }

    function isMortgageSecured() {
        return parent.getField(FIELDS.MS.AS.CurrentAddrHaveMortgage).value === true;
    }

    function willSellProperty() {
        return parent.getField(FIELDS.MS.AS.CurrentAddrMortSellToPayOff).value === true;
    }

    function sellToPayOffSelected() {
        return parent.getField(FIELDS.MS.AS.CurrentAddrMortSellToPayOff).value !== undefined;
    }

    function hasSecondMortgage() {
        return parent.getField(FIELDS.MS.AS.CurrentAddrMortSecondCharge).value === true;
    }

    function willBeReleasedPriorToMortgageCompletion() {
        return parent.getField(FIELDS.MS.AS.CurrentAddrMortWillBeReleased).value === true;
    }

    const address = parent.getCurrentAddress();

    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldLabel 
                    label={`Previously, ${parent.getApplicantName()}, you told us that you own the house you currently live in`}
                />

                <FieldLabel 
                    label={`Perhaps now you can tell us a bit more about that property and whether or not it has a mortgage.`}
                    labelType={LABEL_TYPE.Description}
                />

                <Alert 
                    type={ALERTTYPE.INFO} 
                    text={`
                        ${address.CurrentAddressAddressLine1 || ''}
                        ${address.CurrentAddressAddressLine2 || ''}
                        ${address.CurrentAddressTown || ''}
                        ${address.CurrentAddressCounty || ''}
                        ${address.CurrentAddressPostcode || ''}
                    `} 
                />


                <CopyApplicantsData
                    label="Copy home details from:"
                    sectionId={parent.state.sectionId}
                    partId={parent.state.partId}
                    currentApplicantId={parent.state.currentApplicantId}
                    adapter={parent.props.adapter}
                    fields={[
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrDatePurchased),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrPurchasePrice),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrOwnedBy),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMktValue),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrPropertyType),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrHaveMortgage),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMortProviderName),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMortProviderAccNo),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMortStartDate),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMortTermYears),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMortBorrowingAmount),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMortType),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMortCurrentBalOS),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMortCurrentMonthPay),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMortSellToPayOff),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMortSelltoPayOffSalePrice),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMortWillBeReleased),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMortWillBeReleasedDescr),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMortSecondCharge),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMortSecondChargeName),
                        parent.getFormField(null, FIELDS.MS.AS.CurrentAddrMortSecondChargeBalance),
                    ]}
                />

                <hr/>


                <div className="date-boxes">
                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        {...parent.getField(FIELDS.MS.AS.CurrentAddrDatePurchased)}
                    />
                </div>

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MS.AS.CurrentAddrPurchasePrice)}
                />

                <FieldRadioGroup 
                    inline={true}
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MS.AS.CurrentAddrOwnedBy)}>
                </FieldRadioGroup>

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    show={isOwnedShared()}
                    {...parent.getField(FIELDS.MS.AS.CurrentAddrPctOwned)}
                />

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MS.AS.CurrentAddrMktValue)}
                />

                <FieldDropdown
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MS.AS.CurrentAddrPropertyType)}
                />

                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MS.AS.CurrentAddrHaveMortgage)}
                />

                {
                    isMortgageSecured() &&
                    <>
                        <FieldLabel
                            label="Please tell us about your current home mortgage"
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.AS.CurrentAddrMortProviderName)}
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.AS.CurrentAddrMortProviderAccNo)}
                        />

                        <div className="date-boxes">
                            <FieldDate
                                labelType={LABEL_TYPE.Small}
                                {...parent.getField(FIELDS.MS.AS.CurrentAddrMortStartDate)}
                            />
                        </div>

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.AS.CurrentAddrMortTermYears)}
                            type="number"
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.AS.CurrentAddrMortBorrowingAmount)}
                        />

                        <FieldRadioGroup
                            labelType={LABEL_TYPE.Small}
                            inline={true}
                            {...parent.getField(FIELDS.MS.AS.CurrentAddrMortType)}
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.AS.CurrentAddrMortCurrentBalOS)}
                        />

                        <FieldInput
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.AS.CurrentAddrMortCurrentMonthPay)}
                        />

                        <FieldRadioYesNo
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.AS.CurrentAddrMortSecondCharge)}
                        />

                        {
                            hasSecondMortgage() &&
                            <>
                                <FieldInput
                                    labelType={LABEL_TYPE.Small}
                                    {...parent.getField(FIELDS.MS.AS.CurrentAddrMortSecondChargeName)}
                                />

                                <FieldInput
                                    labelType={LABEL_TYPE.Small}
                                    {...parent.getField(FIELDS.MS.AS.CurrentAddrMortSecondChargeBalance)}
                                />

                                <FieldInput
                                    labelType={LABEL_TYPE.Small}
                                    {...parent.getField(FIELDS.MS.AS.CurrentAddrMortSecondChargeMonthlyPayment)}
                                />
                            </>
                        }

                        <FieldRadioYesNo
                            labelType={LABEL_TYPE.Small}
                            {...parent.getField(FIELDS.MS.AS.CurrentAddrMortSellToPayOff)}
                        />

                        {
                            willSellProperty() &&
                            <FieldInput
                                labelType={LABEL_TYPE.Small}
                                {...parent.getField(FIELDS.MS.AS.CurrentAddrMortSelltoPayOffSalePrice)}
                            />
                        }

                        {
                            !willSellProperty() &&
                            sellToPayOffSelected() &&
                            <>
                                <FieldRadioYesNo
                                    labelType={LABEL_TYPE.Small}
                                    {...parent.getField(FIELDS.MS.AS.CurrentAddrMortWillBeReleased)}
                                />

                                <FieldInput
                                    labelType={LABEL_TYPE.Small}
                                    show={willBeReleasedPriorToMortgageCompletion()}
                                    {...parent.getField(FIELDS.MS.AS.CurrentAddrMortWillBeReleasedDescr)}
                                />
                            </>
                        }
                    </>
                }

            </QuestionBlock>,

        validate: (context) => {
            context
                .date(FIELDS.MS.AS.CurrentAddrDatePurchased)
                .mandatory(FIELDS.MS.AS.CurrentAddrPurchasePrice)
                .mandatory(FIELDS.MS.AS.CurrentAddrOwnedBy)
                .mandatory(FIELDS.MS.AS.CurrentAddrMktValue)
                .mandatory(FIELDS.MS.AS.CurrentAddrPropertyType)
                .mandatory(FIELDS.MS.AS.CurrentAddrHaveMortgage)
                .mandatoryOrCleanup(isOwnedShared(), FIELDS.MS.AS.CurrentAddrPctOwned)
                .mandatoryOrCleanup(isMortgageSecured(), FIELDS.MS.AS.CurrentAddrMortProviderName)
                .mandatoryOrCleanup(isMortgageSecured(), FIELDS.MS.AS.CurrentAddrMortProviderAccNo)
                .mandatoryOrCleanup(isMortgageSecured(), FIELDS.MS.AS.CurrentAddrMortStartDate)
                .mandatoryOrCleanup(isMortgageSecured(), FIELDS.MS.AS.CurrentAddrMortTermYears)
                .mandatoryOrCleanup(isMortgageSecured(), FIELDS.MS.AS.CurrentAddrMortBorrowingAmount)
                .mandatoryOrCleanup(isMortgageSecured(), FIELDS.MS.AS.CurrentAddrMortType)
                .mandatoryOrCleanup(isMortgageSecured(), FIELDS.MS.AS.CurrentAddrMortCurrentBalOS)
                .mandatoryOrCleanup(isMortgageSecured(), FIELDS.MS.AS.CurrentAddrMortCurrentMonthPay)
                .mandatoryOrCleanup(isMortgageSecured(), FIELDS.MS.AS.CurrentAddrMortSellToPayOff)
                .mandatoryOrCleanup(isMortgageSecured() && willSellProperty(), FIELDS.MS.AS.CurrentAddrMortSelltoPayOffSalePrice)
                .mandatoryOrCleanup(isMortgageSecured() && !willSellProperty(), FIELDS.MS.AS.CurrentAddrMortWillBeReleased)
                .mandatoryOrCleanup(isMortgageSecured() && !willSellProperty() && willBeReleasedPriorToMortgageCompletion(), FIELDS.MS.AS.CurrentAddrMortWillBeReleasedDescr)
                .mandatoryOrCleanup(isMortgageSecured(), FIELDS.MS.AS.CurrentAddrMortSecondCharge)
                .mandatoryOrCleanup(isMortgageSecured() && hasSecondMortgage(), FIELDS.MS.AS.CurrentAddrMortSecondChargeName)
                .mandatoryOrCleanup(isMortgageSecured() && hasSecondMortgage(), FIELDS.MS.AS.CurrentAddrMortSecondChargeBalance)
                .mandatoryOrCleanup(isMortgageSecured() && hasSecondMortgage(), FIELDS.MS.AS.CurrentAddrMortSecondChargeMonthlyPayment);
            },

        nextQuestionId: nextQuestionId
    };
};