export function formatCurrency(val, digits) {

    const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: digits != null ? digits : 0 
    });
    
    return formatter.format(val);
}
