import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import {FieldLabel} from "common-form/components/FieldLabel";
import {LABEL_TYPE} from "common-form/components/Field";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import {Alert, ALERTTYPE} from "../../../../../form/components/alert/Alert";

import {
    FIELDS
} from "common-hypotheca";


export default function HaveWill(parent, nextQuestionId) {

    function noWill() {
        return parent.getValue(FIELDS.MA.SSI.HaveAWill) === false;
    }

    function wantsTouchWithPartner() {
        return parent.getValue(FIELDS.MA.SSI.WantsTouchWithPartner) === true;
    }

    return {
        block: (params) =>

            <QuestionBlock {...params}>

                <FieldLabel
                    label={`Final question ${parent.getApplicantName()}, do you currently have a will?`}
                />

                <FieldLabel
                    label="When investing in holiday let properties, it is important to make sure that you have made adequate plans for the future."
                    labelType={LABEL_TYPE.Description}
                />

                <FieldRadioYesNo
                    {...parent.getField(FIELDS.MA.SSI.HaveAWill)}
                />

                <FieldRadioYesNo
                    labelType={LABEL_TYPE.Small}
                    show={noWill}
                    {...parent.getField(FIELDS.MA.SSI.WantsTouchWithPartner)}
                />

                <Alert
                    text="Thank you for letting us know. Your advisor will be in touch shortly."
                    show={() => noWill() && wantsTouchWithPartner()}
                    type={ALERTTYPE.SUCCESS}
                />

            </QuestionBlock>,


        validate: (context) => {

            context
            .mandatory(FIELDS.MA.SSI.HaveAWill)
            ;

            context
            .mandatoryOrCleanup(noWill(), FIELDS.MA.SSI.WantsTouchWithPartner)
            ;

        },

        nextQuestionId: () => nextQuestionId
    };

};