import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import {FieldLabel} from "common-form/components/FieldLabel";
import {LABEL_TYPE} from "common-form/components/Field";
import FieldInput from "common-form/components/FieldInput";
import AddressLookup from "../../../../../form/components/AddressLookup";

import {
    FIELDS
} from "common-hypotheca";


export default function SolicitorDetails(parent, nextQuestionId) {
    
    return {
        block: (params) =>

            <QuestionBlock {...params}>

                <FieldLabel
                    label="Please provide the contact details for your solicitor below"
                />

                <FieldLabel
                    label="Normally, the solicitor you appoint will act on behalf of the lender as well as yourself and, therefore, will need to be a member of 
                    the lender's panel. Until you verify this fact, try to avoid incurring any costs as you might want to switch solicitors if they are not on panel."
                    labelType={LABEL_TYPE.Description}
                />

                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MA.SSI.SolicFirm)}
                />
                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MA.SSI.SolicName)}
                />
                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MA.SSI.SolicNumber)}
                />
                <FieldInput
                    labelType={LABEL_TYPE.Small}
                    {...parent.getField(FIELDS.MA.SSI.SolicEmailAddr)}
                />

                <AddressLookup
                    label="Contact address"
                    labelType={LABEL_TYPE.Small}
                    fields={[
                        parent.getFormField(null, FIELDS.MA.SSI.SolicAddressLine1),
                        parent.getFormField(null, FIELDS.MA.SSI.SolicAddressLine2),
                        parent.getFormField(null, FIELDS.MA.SSI.SolicTown),
                        parent.getFormField(null, FIELDS.MA.SSI.SolicCounty),
                        parent.getFormField(null, FIELDS.MA.SSI.SolicPostcode)
                    ]}
                />

            </QuestionBlock>,

        validate: (context) => {
            context
            .mandatory(FIELDS.MA.SSI.SolicFirm)
            .mandatory(FIELDS.MA.SSI.SolicName)
            .mandatory(FIELDS.MA.SSI.SolicNumber)
            .cleanupIrrelevantField(FIELDS.MA.SSI.SolicFaxNumber)
            .cleanupIrrelevantField(FIELDS.MA.SSI.SolicDXNumber)
            .optional(FIELDS.MA.SSI.SolicEmailAddr)
            .mandatory(FIELDS.MA.SSI.SolicAddressLine1)
            .optional(FIELDS.MA.SSI.SolicAddressLine2)
            .mandatory(FIELDS.MA.SSI.SolicTown)
            .optional(FIELDS.MA.SSI.SolicCounty)
            .mandatory(FIELDS.MA.SSI.SolicPostcode)
            ;
        },

        nextQuestionId: () => nextQuestionId
    };

};