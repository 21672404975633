import * as React from "react";

import QuestionBlock from "../../../../form/QuestionBlock";
import FieldInput from "common-form/components/FieldInput";
import FieldTextArea from "../../../../form/components/FieldTextArea";
import FieldDropdown from "../../../../form/components/dropdown/FieldDropdown";
import FieldDropdownItem from "../../../../form/components/dropdown/FieldDropdownItem";
import { FieldCheckboxGroup } from "../../../../form/components/checkbox";
import FieldLabel from "common-form/components/FieldLabel";
import { LABEL_TYPE } from "../../../../form/components/Field";
import LoanToValue from "../../../../form/components/LoanToValue";

import { ALERTTYPE, Alert } from "../../../../form/components/alert/Alert";

import { formatCurrency } from "../../../../util/currency";

import {
    FIELDS,
    FUNDS_USAGE
} from "common-hypotheca";


export default function NewMortgageExistingAsset(parent, nextBlockId) {


    function hasFundsPlanOther() {
        const fundsPlans = parent.getValue(FIELDS.MM.MD.FundsPlan) || [];
        return fundsPlans.indexOf(FUNDS_USAGE.OTHER) > -1;
    }

    function borrowOptionChanged(i, field, option) {

        const propertyValue = parent.getPropertyValue();
        const borrowAmount = propertyValue && option && option !== 'custom'
            ? (propertyValue * parseInt(option) / 100)
            : null;

        parent.handleFilledField(null, FIELDS.MM.MD.BorrowAmount, borrowAmount);
        parent.handleFilledField(null, FIELDS.MM.MD.BorrowPercent, option);
    }

    function borrowAmountChanged(i, field, value) {
        parent.handleFilledField(null, FIELDS.MM.MD.BorrowPercent, 'custom');
        parent.handleFilledField(null, FIELDS.MM.MD.BorrowAmount, value);
    }

    const borrowOptions = [60, 65, 70, 75, 80].map(o => {
        return {
            value: o, label: `${o}%`
        };
    });

    borrowOptions.unshift({ value: '', label: '' });
    borrowOptions.push({ value: 'custom', label: 'Custom' });

    if (!parent.getValue(FIELDS.MM.MD.BorrowPercent) && parent.getValue(FIELDS.MM.MD.BorrowAmount)) {
        parent.updateValue(FIELDS.MM.MD.BorrowPercent, 'custom');
    }

    return {

        block: (params) =>

            <QuestionBlock {...params}>

                <FieldLabel
                    label="You wish to secure a new mortgage on your presently unencumbered holiday let"
                />

                <FieldLabel
                    label="Please tell us a little bit more about your reasons for wanting a mortgage and what your plans are for the funds."
                    labelType={LABEL_TYPE.Description}
                />

                <Alert
                    text={`Current property value : ${formatCurrency(parent.getPropertyValue())}`}
                    type={ALERTTYPE.SUCCESS}
                />

                <FieldDropdown
                    {...parent.getField(FIELDS.MM.MD.BorrowPercent)}
                    labelType={LABEL_TYPE.Small}
                    handleFieldFilled={borrowOptionChanged}
                    valid={false} // Remove green valid field enhancements around LTV in order not to confuse users that this LTV is OK
                >
                    {
                        borrowOptions.map((option, i) =>
                            <FieldDropdownItem key={i} code={option.value} label={option.label} />
                        )
                    }
                </FieldDropdown>

                {
                    parent.getValue(FIELDS.MM.MD.BorrowPercent) &&
                    <>
                        <FieldInput
                            {...parent.getField(FIELDS.MM.MD.BorrowAmount)}
                            handleFieldFilled={borrowAmountChanged}
                            show={() => parent.getValue(FIELDS.MM.MD.BorrowPercent)}
                            labelType={LABEL_TYPE.Small}
                        />

                        <LoanToValue
                            label="Proposed loan to value of {0}%"
                            loan={parent.getValue(FIELDS.MM.MD.BorrowAmount)}
                            value={parent.getPropertyValue()}
                        />


                        <FieldTextArea
                            {...parent.getField(FIELDS.MM.MD.MortgageReason)}
                            labelType={LABEL_TYPE.Small}
                        />

                        <FieldCheckboxGroup
                            {...parent.getField(FIELDS.MM.MD.FundsPlan)}
                            labelType={LABEL_TYPE.Small}
                        />

                        <FieldInput
                            {...parent.getField(FIELDS.MM.MD.FundsPlanOther)}
                            show={hasFundsPlanOther()}
                            labelType={LABEL_TYPE.Small}
                        />
                    </>
                }

            </QuestionBlock>,


        validate: (context) => {
            context
                .mandatory(FIELDS.MM.MD.BorrowAmount)
                .mandatory(FIELDS.MM.MD.BorrowPercent)
                .mandatory(FIELDS.MM.MD.MortgageReason)
                .mandatoryAtLeastOneSelection(FIELDS.MM.MD.FundsPlan)
                .mandatoryOrCleanup(hasFundsPlanOther(), FIELDS.MM.MD.FundsPlanOther);

            parent.updateLoanToValue(
                FIELDS.MM.MD.BorrowAmountLoanToValue,
                FIELDS.MM.MD.BorrowAmount,
                parent.getPropertyValue());
        },

        nextQuestionId: () => nextBlockId
    };
};