import React from "react";

import FieldLabel from "common-form/components/FieldLabel";
import FieldInput from "common-form/components/FieldInput";
import {LABEL_TYPE} from "common-form/components/Field";
import FieldDate from "common-form/components/FieldDate";

import QuestionBlock from '../../../../../form/QuestionBlock';
import {FieldDropdown} from "../../../../../form/components/dropdown";
import FieldGroupAddable from "../../../../../form/components/FieldGroupAddable";
import FormButton from "../../../../../form/components/FormButton";
import {ALERTTYPE} from "../../../../../form/components/alert/Alert";
import Alert from "../../../../../form/components/alert/Alert";
import TimeDiff from "../../../../../form/components/TimeDiff";
import AddressLookup from "../../../../../form/components/AddressLookup";

import {
    formatTimeDiff,
    atLeast3YearsAgo,
    getTimeDiff, formattedTimeDiffToNow, isDateValid
} from "common-hypotheca/src/dates";

import {CAN_SUBMIT} from "../../../../../constants";


import {
    extractFieldName, 
    FIELDS, 
    OTHER
} from "common-hypotheca";


export default function PreviousAddresses(parent) {

    function noMoreAddressesNeeded() {
        let value = parent.getFieldValue(null, FIELDS.MS.PD.PreviousAddresses);
        if (!Array.isArray(value) || value.length === 0)
            return false;

        for (let i = 0; i < value.length; i++) {
            let sinceValue = value[i][extractFieldName(FIELDS.MS.PD.PreviousAddressStartDate)];

            // Need additional addresses only if living in last address less than 3 years
            if (atLeast3YearsAgo(sinceValue)) {
                return true;
            }
        }

        return false;
    }

    function setEditing(editing) {
        parent.setState({
            editingPreviousAddress: editing
        });
    }

    return {
        block: (params)=>
            <QuestionBlock {...params}>
                <FieldLabel label='Your current address'/>

                <div className="form-group row align-items-center">
                    <div className="col-7">
                        {
                            (parent.getFieldValue(null, FIELDS.MS.PD.CurrentAddressAddressLine1) == null
                                || parent.getFieldValue(null, FIELDS.MS.PD.CurrentAddressStartDate) == null) &&
                            'Unfinished address'
                        }
                        {
                            parent.getFieldValue(null, FIELDS.MS.PD.CurrentAddressAddressLine1) != null &&
                            parent.getFieldValue(null, FIELDS.MS.PD.CurrentAddressStartDate) != null &&
                            (
                                parent.getFieldValue(null, FIELDS.MS.PD.CurrentAddressAddressLine1) + ' since ' + 
                                parent.getFieldValue(null, FIELDS.MS.PD.CurrentAddressStartDate) + ', for ' + 
                                formatTimeDiff(getTimeDiff(parent.getFieldValue(null, FIELDS.MS.PD.CurrentAddressStartDate)), "ym")
                            )
                        }
                    </div>
                    <div className="col-5">

                        <FormButton
                            type='group-addable-edit'
                            disabled={parent.state.editingPreviousAddress}
                            onClick={() => parent.swiperPrevious()}
                            text='Edit'
                        />

                    </div>
                </div>

                <FieldLabel 
                    label='Where else in the last 3 years have you lived?'
                />

                <FieldLabel 
                    labelType={LABEL_TYPE.Description} 
                    label='Please tell us about each one such that we capture a complete 3 year address history.'
                />

                <FieldLabel
                    label="If the property is not located in the UK, then please continue to use the form below, but input the address in the best way you can."
                    labelType={LABEL_TYPE.Description}
                />

                <FieldGroupAddable
                    ref={params.groupAddableRef}
                    addButtonLabel="Add address"
                    deleteButtonLabel="Remove"
                    editButtonLabel="Edit"
                    saveButtonLabel="Save address"
                    allowDeleteAll
                    itemDescriber={(fieldsContainer) => {
                        let ageDescription;
                        if (isDateValid(fieldsContainer[extractFieldName(FIELDS.MS.PD.PreviousAddressStartDate)])) {
                            ageDescription = ', which was ' +
                                formattedTimeDiffToNow(fieldsContainer[extractFieldName(FIELDS.MS.PD.PreviousAddressStartDate)])
                                + ' ago';
                        } else {
                            ageDescription = '';
                        }

                        if (fieldsContainer[extractFieldName(FIELDS.MS.PD.PreviousAddressAddressLine1)] === undefined
                            || fieldsContainer[extractFieldName(FIELDS.MS.PD.PreviousAddressStartDate)] === undefined) {
                            return 'Unfinished address';
                        } else {
                            return fieldsContainer[extractFieldName(FIELDS.MS.PD.PreviousAddressAddressLine1)] +
                                ' since ' +
                                fieldsContainer[extractFieldName(FIELDS.MS.PD.PreviousAddressStartDate)] +
                                ageDescription;
                        }
                    }}
                    disableAdding={noMoreAddressesNeeded}
                    validate={(context) => {
                        
                        context
                            .mandatory(FIELDS.MS.PD.PreviousAddressAddressLine1)
                            .optional(FIELDS.MS.PD.PreviousAddressAddressLine2)
                            .mandatory(FIELDS.MS.PD.PreviousAddressStartDate)
                            .mandatory(FIELDS.MS.PD.PreviousAddressTown)
                            .mandatory(FIELDS.MS.PD.PreviousAddressPostcode)
                            .optional(FIELDS.MS.PD.PreviousAddressCounty)
                            .date(FIELDS.MS.PD.PreviousAddressStartDate)
                            .mandatory(FIELDS.MS.PD.PreviousAddressOccuptType);

                        context.mandatoryOrCleanup(
                            context.fieldValue(FIELDS.MS.PD.PreviousAddressOccuptType) === OTHER,
                            FIELDS.MS.PD.PreviousAddressOccuptTypeOther
                        );

                        context.cleanupIrrelevantField(FIELDS.MS.PD.PreviousAddressAgeYears);
                        context.cleanupIrrelevantField(FIELDS.MS.PD.PreviousAddressAgeMonths);

                        if (isDateValid(context.fieldValue(FIELDS.MS.PD.PreviousAddressStartDate))) {
                            context.fieldValue(
                                FIELDS.MS.PD.PreviousAddressAge,
                                null,
                                formattedTimeDiffToNow(context.fieldValue(FIELDS.MS.PD.PreviousAddressStartDate)));
                        }
                    }}
                    beforeEdit={() => {
                        setEditing(true);
                    }}
                    afterEdit={() => {
                        setEditing(false);
                    }}
                    {...parent.getFormField(null, FIELDS.MS.PD.PreviousAddresses)}
                >


                    <AddressLookup
                        label="Previous address"
                        labelType={LABEL_TYPE.Small} 
                        fields={[
                            FIELDS.MS.PD.PreviousAddressAddressLine1, 
                            FIELDS.MS.PD.PreviousAddressAddressLine2,
                            FIELDS.MS.PD.PreviousAddressTown,
                            FIELDS.MS.PD.PreviousAddressCounty,
                            FIELDS.MS.PD.PreviousAddressPostcode
                        ]}
                    />

                    <FieldDate 
                        labelType={LABEL_TYPE.Small} 
                        field={FIELDS.MS.PD.PreviousAddressStartDate}
                    />

                    <TimeDiff
                        {...parent.getFormField(null, FIELDS.MS.PD.PreviousAddressStartDate)}
                        format="ym"
                        label="You started living at this address {0} ago."
                    />

                    <FieldDropdown 
                        labelType={LABEL_TYPE.Small} 
                        field={FIELDS.MS.PD.PreviousAddressOccuptType}
                    />
                    
                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        placeholder="Please describe"
                        show={(fieldsContainer) => fieldsContainer[extractFieldName(FIELDS.MS.PD.PreviousAddressOccuptType)] === OTHER}
                        field={FIELDS.MS.PD.PreviousAddressOccuptTypeOther}
                    />
                </FieldGroupAddable>
                {
                    noMoreAddressesNeeded() &&
                    <Alert type={ALERTTYPE.SUCCESS} text={`Thanks ${parent.getApplicantName()}, that’s the 3 years' address history we needed!`}/>
                }
            </QuestionBlock>,

        validate: (context) => {

            // Will also call group addable valid()
            if (!noMoreAddressesNeeded()) {
                context.addError(null, null, 'Please specify entire 3-year address history.');
            }

            if (parent.groupAddableRef && !parent.groupAddableRef.current.valid()) {
                context.addError(FIELDS.MS.PD.PreviousAddresses, null, '');
            }

        },
        nextQuestionId: () => CAN_SUBMIT
    };
}