import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";

import {extractFieldName, FIELDS, OTHER} from "common-hypotheca";
import FieldInput from "common-form/components/FieldInput";
import FieldGroupAddable from "../../../../../form/components/FieldGroupAddable";
import FieldLabel from "common-form/components/FieldLabel";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import { FieldDropdown } from "../../../../../form/components/dropdown";
import FieldDate from "common-form/components/FieldDate";

import { LABEL_TYPE } from "common-form/components/Field";

export default function OtherLoanList(parent, nextBlockId) {
    return {
        block: (params)=>
            <QuestionBlock {...params}>
                <FieldLabel
                    label="Please tell us about your other loans"
                />

                <FieldGroupAddable
                    ref={params.groupAddableRef}
                    addButtonLabel="Add other loan"
                    allowDeleteAll
                    deleteButtonLabel="Remove"
                    editButtonLabel="Edit"
                    saveButtonLabel="Save other loan"
                    itemDescriber={(fieldsContainer) => {
                        if (fieldsContainer[extractFieldName(FIELDS.MS.CC.OtherLoanName)] === undefined)
                            return 'A loan from unspecified provider';
                        else
                            return 'Loan from ' + fieldsContainer[extractFieldName(FIELDS.MS.CC.OtherLoanName)];
                    }}
                    validate={(context) => {
                        context
                            .mandatory(FIELDS.MS.CC.OtherLoanName)
                            .mandatory(FIELDS.MS.CC.OtherLoanPurpose)
                            .mandatory(FIELDS.MS.CC.OtherLoanAcctNum)
                            .mandatory(FIELDS.MS.CC.OtherLoanOrigBorrowAmt)
                            .date(FIELDS.MS.CC.OtherLoanOrigStartDate)
                            .mandatory(FIELDS.MS.CC.OtherLoanCurrentBal)
                            .mandatory(FIELDS.MS.CC.OtherLoanPayEachMonth)
                            .date(FIELDS.MS.CC.OtherLoanFinalRepayDate)
                            .mandatory(FIELDS.MS.CC.OtherLoanPaidOffCompletion)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.OtherLoanPaidOffCompletion),
                                FIELDS.MS.CC.OtherLoanPaidOffCompletionHow)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.OtherLoanPaidOffCompletion)
                                && context.fieldValue(FIELDS.MS.CC.OtherLoanPaidOffCompletionHow) === OTHER,
                                FIELDS.MS.CC.OtherLoanPaidOffCompletionHowOther)
                            // Cleanup obsolete fields (if present in data)
                            .cleanupIrrelevantField(FIELDS.MS.CC.OtherLoanCurrentIntRate)
                            .cleanupIrrelevantField(FIELDS.MS.CC.OtherLoanIsSecOnProperty)
                            .cleanupIrrelevantField(FIELDS.MS.CC.OtherLoanIsSecOnPropertyDescr)
                        ;
                    }}
                    {...parent.getFormField(null, FIELDS.MS.CC.OtherLoans)}
                >

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherLoanName}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherLoanPurpose}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherLoanAcctNum}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherLoanOrigBorrowAmt}
                    />

                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherLoanOrigStartDate}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherLoanCurrentBal}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherLoanPayEachMonth}
                    />

                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherLoanFinalRepayDate}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherLoanPaidOffCompletion}
                    />

                    <FieldDropdown
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.OtherLoanPaidOffCompletion)] }
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OtherLoanPaidOffCompletionHow}
                    />

                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.OtherLoanPaidOffCompletion)]
                            && container[extractFieldName(FIELDS.MS.CC.OtherLoanPaidOffCompletionHow)] === OTHER }
                        field={FIELDS.MS.CC.OtherLoanPaidOffCompletionHowOther}
                        labelType={LABEL_TYPE.Small}
                        placeholder="Other method description"
                    />

                </FieldGroupAddable>


            </QuestionBlock>,
 
        validate: (context) => {

            if (!context.hasItems(FIELDS.MS.CC.OtherLoans)) {
                context.addError(null, null, "Please enter an item or go back to the previous screen and change your selection.");
            }

            if (parent.groupAddableRef && !parent.groupAddableRef.current.valid()) {
                context.addError(FIELDS.MS.CC.OtherLoans, null, '');
            }

        },

        nextQuestionId: () => parent.nextSelectedCommitmentType(nextBlockId)
    };

}