import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";
import FieldInput from "common-form/components/FieldInput";
import FieldGroup from "../../../../../form/components/FieldGroup";
import FieldTextArea from "../../../../../form/components/FieldTextArea";
import FieldDropdown from "common-form/components/FieldDropdown";
import { LABEL_TYPE } from "common-form/components/Field";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import {extractFieldName, FIELDS, OTHER} from "common-hypotheca";

import FinancialTable from "./FinancialTable";
import { validateSoletraderDetails } from "./validation";

export default function SoletraderDetails(parent) {

    return {
        block: (params)=>
            <QuestionBlock {...params}>

                <FieldGroup
                    ref={params.groupAddableRef}
                    item={parent.getCurrentEmployment()}
                    {...parent.getField(FIELDS.MS.EI.Employments)}
                    validate={(context) => {

                        validateSoletraderDetails(parent, context, parent.state.employmentIndex);

                    }}

                >
                    <FieldRadioYesNo
                        field={FIELDS.MS.EI.SoleTradDoYouHaveAccountant}
                    />

                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.SoleTradDoYouHaveAccountant)] === true}
                        field={FIELDS.MS.EI.SoleTradAccountantName}
                    />

                    <FieldDropdown
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.SoleTradDoYouHaveAccountant)] === true}
                        field={FIELDS.MS.EI.SoleTradAccountantQual}
                    />

                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.SoleTradDoYouHaveAccountant)] === true &&
                            container[extractFieldName(FIELDS.MS.EI.SoleTradAccountantQual)] === OTHER}
                        placeholder="Other qualification description"
                        field={FIELDS.MS.EI.SoleTradAccountantQualOther}
                    />

                    <FieldRadioYesNo
                        field={FIELDS.MS.EI.SoleTradDoYouProdAccts}
                    />

                    <FinancialTable
                        container={parent.getCurrentEmployment()}
                        field={FIELDS.MS.EI.Sharings}
                        periodField={FIELDS.MS.EI.SharingPeriod}
                        validate={parent.state.nextTriggered}
                        currencyFields={[
                            {
                                ...FIELDS.MS.EI.SharingProfit,
                                allowNegative: true,
                                tooltip: "Please enter the Net Profit, which is most likely to be the same figure as shown on your income tax return. Please note that this is different to your business turnover!"
                            }
                        ]}
                        onChange={parent.saveEmployments}
                        onValidity={(context) => {
                            parent.setState({
                                financialValid: context.isValid
                            });
                        }}

                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.EI.SoleTradBusinessIncome}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.SoleTradBusinessIncome)] === true}
                        field={FIELDS.MS.EI.SoleTradBusinessIncomeChangedSignificantly}
                    />

                    <FieldTextArea
                        labelType={LABEL_TYPE.Small}
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.SoleTradBusinessIncome)] === false}
                        placeholder="For example, has the business ceased trading ..."
                        field={FIELDS.MS.EI.SoleTradBusinessIncomeNoReason}
                    />

                    <FieldTextArea
                        labelType={LABEL_TYPE.Small}
                        show={(container) => container[extractFieldName(FIELDS.MS.EI.SoleTradBusinessIncomeChangedSignificantly)] === true && 
                            container[extractFieldName(FIELDS.MS.EI.SoleTradBusinessIncome)] === true}
                        placeholder="For example, we sold the main division ..."
                        field={FIELDS.MS.EI.SoleTradBusinessIncomeChangedReason}
                    />

                </FieldGroup>

            </QuestionBlock>,

        validate: (context) => {
                    
            if (!parent.state.financialValid) {
                context.addError('financials', null, '');
            }

        },

        nextQuestionId: () => parent.afterEmploymentCompleted()
    };


    
};