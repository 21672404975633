import {extractFieldName} from "common-hypotheca";

import { isDateValid, isDateBefore, isDateAfter } from "common-hypotheca/src/dates";


class ValidationContext {
    constructor(partContainerFinder) {
        this.partContainerFinder = partContainerFinder;
    }

    setPartContainerFinder = (partContainerFinder) => {
        this.partContainerFinder = partContainerFinder;
    };

    currentField = undefined;
    currentParams = undefined;
    currentFieldKey = undefined;

    errors = {
    };

    errorsAccrued = {
    };

    validFields = {
    };

    error = (fieldName, partParams) => {
        return this.errors[this.fieldKey(fieldName, partParams)];
    };

    isValid = (fieldName, partParams) => {
        return this.validFields[this.fieldKey(fieldName, partParams)];
    };

    /**
     * Resets field validity state. Should be used when field is altered by another field for example.
     * @param fieldName
     * @param partParams
     */
    resetFieldValidity = (fieldName, partParams) => {
        let fieldKey = this.fieldKey(fieldName, partParams);
        this.errors[fieldKey] = undefined;
        this.validFields[fieldKey] = undefined;
    };

    fieldValue = (field, params, value) => {
        let fieldName = extractFieldName(field);

        if (params === null || params === undefined) {
            params = {};
        }

        let fieldKey = this.fieldKey(fieldName, params);

        // Unless there is already an error on the field mark it as validated.
        // Adding error on the field will reset this.
        if ((this.currentFieldKey === fieldKey || this.currentFieldKey === undefined) && this.errorsAccrued[fieldKey] === undefined) {
            this.validFields[this.fieldKey(fieldName, params)] = true;
        }

        let container = this.partContainerFinder(
            params.applicantId,
            params.sectionId,
            params.partId);

        const cnt = container.fields === undefined ? container : container.fields;
        if (value !== undefined) {
            cnt[fieldName] = value;
        }

        return cnt[fieldName];
    };

    isEmptyValue = (value) => {
        return value === null || value === undefined || (value.trim && value.trim() === '');
    };

    mandatory = (field, partParams) => {
        if (Array.isArray(field)) {
            field.forEach(f => this.mandatory(f, partParams));
            return this;
        }

        let value = this.fieldValue(field, partParams);

        // Cleans up field if value is obsolete item.
        if (!this.isEmptyValue(value)
            && field.options !== undefined) {

            let selectedOptionArray = field.options.filter(option => option.code === value);

            if (selectedOptionArray.length > 0
                && selectedOptionArray[0].obsolete) {

                this.cleanupIrrelevantField(field, partParams);
            }
        }

        if (this.isEmptyValue(value)) {
            this.addError(field, partParams, 'This field is mandatory.');
        }

        return this;
    };

    mandatoryValue = (field, mustValue, msg, partParams) => {

        let value = this.fieldValue(field, partParams);
        if (value !== mustValue) {
            this.addError(field, partParams, msg);
        }

        return this;
    };

    mandatoryFiles = (field, partParams, msg) => {
        let value = this.fieldValue(field, partParams);

        if (this.isEmptyValue(value) || value === '[]') {
            this.addError(field, partParams, msg || 'This field is mandatory.');
            return;
        }

        let files;
        try {
            files = JSON.parse(value) || []
        } catch (e) {
        }

        if (files === undefined) {
            this.addError(field, partParams, msg || 'Upload is broken, please try upload again.');
        }

        if (files.filter((element) => element.success).length === 0) {
            this.addError(field, partParams, msg || 'This field is mandatory.');
        }

        return this;
    };

    mandatoryAtLeastOneSelection = (field, partParams) => {
        let value = this.fieldValue(field, partParams);

        // Cleans up value of obsolete items.
        if (!this.isOneFieldValidation()
            && !this.isEmptyValue(value)
            && value.constructor === Array) {

            value = value.filter(selectedCode => {
                let selectedOptionArray = field.options.filter(option => option.code === selectedCode);
                return selectedOptionArray.length === 0 || !selectedOptionArray[0].obsolete;
            });

            // Update value
            this.fieldValue(field, partParams, value);
        }

        if (this.isEmptyValue(value)
            || (value.constructor === Array && value.length === 0)) {
            this.addError(field, partParams, 'At least one option must be selected.');
        }

        return this;
    };

    /**
     * Ensure field is present in data to appear in fact find as a field presented to the user but empty.
     * @param field
     * @param partParams
     */
    optional = (field, partParams) => {
        if (this.fieldValue(field, partParams) === undefined)
            this.fieldValue(field, partParams, null);

        return this;
    };

    minSize = (field, length, message, partParams) => {
        let value = this.fieldValue(field, partParams);

        if (this.isEmptyValue(value)) {
            return this;
        }

        if (value.trim) {
            value = value.trim();
        }

        if (value.length < length) {
            this.addError(field, partParams, message || 'This field should contain at least ' + length + ' characters.')
        }
        return this;
    };


    /**
     * Field must be greater than value.
     */
    greaterThan = (field, minValue, message, partParams) => {
        let value = this.fieldValue(field, partParams);
        if (this.isEmptyValue(value) || parseInt(value) <= minValue ) {
            this.addError(field, partParams, message || `Must be greater than ${minValue}`);
        }

        return this;
    };

    /**
     * Field value should be between minValue and maxValue.
     *
     * @param field
     * @param minValue
     * @param maxValue
     * @param message
     * @param partParams
     * @returns {ValidationContext}
     */
    between = (field, minValue, maxValue, message, partParams) => {
        let value = this.fieldValue(field, partParams);

        if (this.isEmptyValue(value))
            return this;

        if (parseInt(value) < minValue || parseInt(value) > maxValue) {
            this.addError(field, partParams, message || `Must be between ${minValue} and ${maxValue}`);
        }

        return this;
    };

    date = (field, partParams, msg, minYear, maxYear) => {
        let value = this.fieldValue(field, partParams);

        if (this.isEmptyValue(value) || !isDateValid(value, minYear, maxYear)) {
            this.addError(field, partParams, msg || `Enter a valid date between ${minYear || '1900'} and ${maxYear || '2050'}.`);
        }

        return this;
    };

    dateMin = (field, minValue, message, partParams) => {

        let value = this.fieldValue(field, partParams);
        if (!isDateValid(value)) {
            return this;
        }

        if (isDateBefore(value, minValue)) {
            this.addError(field, partParams, message);
        }

        return this;
    };

    dateMax = (field, maxValue, message, partParams) => {

        let value = this.fieldValue(field, partParams);
        if(!isDateValid(value)) {
            return this;
        }

        if (isDateAfter(value, maxValue)) {
            this.addError(field, partParams, message);
        }

        return this;
    };

    maxValue = (field, maxValue, message, partParams) => {

        let value = this.fieldValue(field, partParams);
        let valueI = typeof(value) === 'number' ? value : value != null ? parseInt(value) : 0;
        if(valueI > maxValue ) {
            this.addError(field, partParams, message);
        }

        return this;
    };

    addError = (field, partParams, message) => {
        let fieldNameKey = this.fieldKey(field, partParams);

        if (this.currentFieldKey !== undefined && fieldNameKey !== this.currentFieldKey)
            return;

        if (this.errorsAccrued[fieldNameKey] === undefined)
            this.errorsAccrued[fieldNameKey] = message;
        else
            this.errorsAccrued[fieldNameKey] = this.errorsAccrued[fieldNameKey] + ' ' + message;

        this.validFields[fieldNameKey] = undefined;
    };

    hasItems = (field) => {
        const value = this.fieldValue(field);
        return Array.isArray(value) && value.length > 0;
    };

    /**
     * Should be used only after call to finish* methods
     * @returns {boolean}
     */
    hasErrors = () => {
        return Object.keys(this.errors).length > 0;
    };

    /**
     * @returns {boolean} if there are errors already accrued during validation.
     */
    hasErrorsAccrued = () => {
        return Object.keys(this.errorsAccrued).length > 0;
    };

    finishAll = () => {
        this.errors = Object.assign({}, this.errorsAccrued);
        return this;
    };

    finishAllValid = () => {
        this.errors = {};
        return this;
    };

    startOneField = (field, params) => {
        this.currentField = field;
        this.currentParams = params;
        this.currentFieldKey = this.fieldKey(field, params);

        this.errorsAccrued[this.currentFieldKey] = undefined;
        return this;
    };

    finishOneField = () => {
        // Keep existing error message but remove if it changed
        if (this.errors[this.currentFieldKey] !== this.errorsAccrued[this.currentFieldKey])
            this.errors[this.currentFieldKey] = undefined;

        this.validFields[this.currentFieldKey] = !this.errorsAccrued[this.currentFieldKey];

        // reset root errors if circumstances changed.
        // TODO: Check why currentField is here instead of currentFieldKey
        if (this.errors['root'] !== this.errorsAccrued[this.currentField])
            this.errors['root'] = undefined;

        this.currentField = undefined;
        this.currentParams = undefined;
        this.currentFieldKey = undefined;

        return this;
    };

    optionalOrCleanup = (expr, field, partParams) => {

        if (Array.isArray(field)) {
            field.forEach(f => this.optionalOrCleanup(expr, f, partParams));
            return this;
        }

        if (expr)
            return this.optional(field, partParams);
        else {
            return this.cleanupIrrelevantField(field, partParams);
        }
    };

    recordedCheckboxOptions = (field, partParams = {}) => {
        let fieldName = extractFieldName(field);

        let container = this.partContainerFinder(
            partParams.applicantId,
            partParams.sectionId,
            partParams.partId);

        if (container.fields === undefined) {
            if (container[fieldName] === undefined) {
                container[fieldName] = [];
            }
        } else {
            if (container.fields[fieldName] === undefined) {
                container.fields[fieldName] = [];
            }
        }

        return this;
    }

    mandatoryOrCleanup = (expr, field, partParams) => {

        if (Array.isArray(field)) {
            field.forEach(f => this.mandatoryOrCleanup(expr, f, partParams));
            return this;
        }

        if (expr)
            return this.mandatory(field, partParams);
        else {
            return this.cleanupIrrelevantField(field, partParams);
        }
    };

    mandatoryAtLeastOneSelectionOrCleanup = (expr, field, partParams) => {
        if (expr)
            return this.mandatoryAtLeastOneSelection(field, partParams);
        else {
            return this.cleanupIrrelevantField(field, partParams);
        }
    };

    dateOrCleanup = (expr, field, partParams) => {
        if (expr)
            return this.date(field, partParams);
        else {
            return this.cleanupIrrelevantField(field, partParams);
        }
    };

    cleanupIrrelevantField = (field, partParams) => {
        if (this.isOneFieldValidation()) {
            return this;
        }

        let fieldName = extractFieldName(field);

        if (partParams === null || partParams === undefined) {
            partParams = {};
        }

        let container = this.partContainerFinder(
            partParams.applicantId,
            partParams.sectionId,
            partParams.partId);

        if (container.fields === undefined) {
            delete container[fieldName];
        } else {
            delete container.fields[fieldName]
        }
        return this;
    };

    isOneFieldValidation = () => {
        return !!this.currentField;
    };

    isFieldBeingValidated = (field, partParams) => {
        return this.isOneFieldValidation()
            && this.currentFieldKey === this.fieldKey(field, partParams);
    };

    fieldKey = (field, params) => {
        if (!field) {
            return 'root';
        }

        let fieldName = extractFieldName(field);
        return fieldName + '::' + (params && params.applicantId ? params.applicantId : '');
    }
}

export default ValidationContext;