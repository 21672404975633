export const _TRANSACTION_SPEED_TYPE = {
    ASAP: 'asap',
    NEXT_WEEKS: 'next_weeks',
    NEXT_MONTHS: 'next_months',
    NOW: 'now'
};

_TRANSACTION_SPEED_TYPE.DROPDOWN_OPTIONS = [
    {
        code: '',
        text: ''
    },
    {
        code: _TRANSACTION_SPEED_TYPE.ASAP,
        text: 'As soon as possible!'
    },
    {
        code: _TRANSACTION_SPEED_TYPE.NEXT_WEEKS,
        text: 'Within the next few weeks'
    },
    {
        code: _TRANSACTION_SPEED_TYPE.NEXT_MONTHS,
        text: 'Within the next few months'
    },
    {
        code: _TRANSACTION_SPEED_TYPE.NOW,
        text: 'Just exploring for now'
    }
];
