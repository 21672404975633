import * as React from "react";
import { CAN_SUBMIT } from "../../../../constants";
import MultiBlockFormSingleApplicant from "../../../../form/MultiBlockFormSingleApplicant";
import QuestionBlock from "../../../../form/QuestionBlock";
import FieldFileUpload from "../../../../form/components/FieldFileUpload";
import FieldLabel from "common-form/components/FieldLabel";
import FormButton from "../../../../form/components/FormButton";
import FormImage, {IMAGE_PIGGY_BANK} from "../../../../form/components/FormImage";

import {
    FIELDS,
    SECTION,
    SITUATION,
    COMMITMENT_TYPE
} from "common-hypotheca";

import CommitmentTypes from './credit/CommitmentTypes';
import CreditCardList from "./credit/CreditCardList";
import OperatingLeasesList from "./credit/OperatingLeasesList";
import PersonalLoanList from "./credit/PersonalLoanList";
import CarFinanceList from "./credit/CarFinanceList";
import OtherPurchaseList from "./credit/OtherPurchaseList";
import OverdraftList from "./credit/OverdraftList";
import OtherLoanList from "./credit/OtherLoanList";
import ErrorPart from "../../../ErrorPart";
import {LABEL_TYPE} from "../../../../form/components/Field";

const BLOCK_WELCOME = 1;
const BLOCK_COMMITMENT_TYPES = 2;
const BLOCK_CREDIT_CARDS = 3;
const BLOCK_PERSONAL_LOANS = 4;
const BLOCK_CAR_FINANCE = 5;
const BLOCK_OPERATING_LEASES = 6;
const BLOCK_OTHER_PURCHASES_USING_CREDIT = 7;
const BLOCK_OVERDRAFT = 8;
const BLOCK_OTHER_LOANS = 9;
const BLOCK_END = 10;


const COMMITMENT_BLOCKS = {};

COMMITMENT_BLOCKS[BLOCK_CREDIT_CARDS] = {
    type: COMMITMENT_TYPE.CREDIT_CARDS,
    next: BLOCK_PERSONAL_LOANS,
    field: FIELDS.MS.CC.CreditCards
};

COMMITMENT_BLOCKS[BLOCK_PERSONAL_LOANS] = {
    type: COMMITMENT_TYPE.PERSONAL_LOANS,
    next: BLOCK_CAR_FINANCE,
    field: FIELDS.MS.CC.PersonalLoans
};

COMMITMENT_BLOCKS[BLOCK_CAR_FINANCE] = {
    type: COMMITMENT_TYPE.CAR_FINANCE,
    next: BLOCK_OPERATING_LEASES,
    field: FIELDS.MS.CC.CarFinances
};

COMMITMENT_BLOCKS[BLOCK_OPERATING_LEASES] = {
    type: COMMITMENT_TYPE.OPERATING_LEASES,
    next: BLOCK_OTHER_PURCHASES_USING_CREDIT,
    field: FIELDS.MS.CC.OperatingLeases
};

COMMITMENT_BLOCKS[BLOCK_OTHER_PURCHASES_USING_CREDIT] = {
    type: COMMITMENT_TYPE.OTHER_PURCHASES,
    next: BLOCK_OVERDRAFT,
    field: FIELDS.MS.CC.OtherCredits
};

COMMITMENT_BLOCKS[BLOCK_OVERDRAFT] = {
    type: COMMITMENT_TYPE.OVERDRAFT,
    next: BLOCK_OTHER_LOANS,
    field: FIELDS.MS.CC.Overdraft
};

COMMITMENT_BLOCKS[BLOCK_OTHER_LOANS] = {
    type: COMMITMENT_TYPE.OTHER_LOANS,
    next: BLOCK_END,
    field: FIELDS.MS.CC.OtherLoans
};

COMMITMENT_BLOCKS[BLOCK_END] = {
    finish: true
};

COMMITMENT_BLOCKS.BLOCK_KEYS = [
    BLOCK_CREDIT_CARDS,
    BLOCK_PERSONAL_LOANS,
    BLOCK_CAR_FINANCE,
    BLOCK_OPERATING_LEASES,
    BLOCK_OTHER_PURCHASES_USING_CREDIT,
    BLOCK_OVERDRAFT,
    BLOCK_OTHER_LOANS
]

class Credit extends MultiBlockFormSingleApplicant {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            sectionId: SECTION.SITUATION,
            partId: SITUATION.CREDIT,

            slideIds: [BLOCK_WELCOME],
            activeSlideId: BLOCK_WELCOME
        };
    }

    /**
     * @returns {number} of expected slides for this part.
     */
    slidesExpected = () => {
        //number of credit types selected plus (welcome, select commitment types, end)
        const creditTypes = this.getFieldValue(null, FIELDS.MS.CC.MyCreditTypes);
        return (
            1 // BLOCK_WELCOME
            + 1 // BLOCK_COMMITMENT_TYPES
            + (creditTypes === undefined ? 0 : creditTypes.length) // 1 for each commitment type selected
            + 1 // BLOCK_END
        );
    };

    checkForCompleteState = data => {
        this.partContainer().isComplete = true;
        this.partContainer(null, SECTION.SITUATION, SITUATION.OUTGOINGS).isAvailable = true;
        return data;
    };

    nextSelectedCommitmentType = (startingWith) => {
        const commitmentTypes = this.fieldValue(FIELDS.MS.CC.MyCreditTypes);

        let blockConfig = COMMITMENT_BLOCKS[startingWith];

        if (blockConfig.finish !== undefined
            || commitmentTypes.includes(blockConfig.type)) {
            return startingWith;
        }

        return this.nextSelectedCommitmentType(blockConfig.next);
    };

    cleanupIrrelevantCommitments = (context) => {
        const commitmentTypes = this.fieldValue(FIELDS.MS.CC.MyCreditTypes);

        for (let blockKey of COMMITMENT_BLOCKS.BLOCK_KEYS) {
            const blockConfig = COMMITMENT_BLOCKS[blockKey];

            if (!blockConfig.type) {
                continue;
            }

            if (!commitmentTypes.includes(blockConfig.type)) {
                context.cleanupIrrelevantField(blockConfig.field);
            }
        }
    }

    getWelcomeLabel = () => {
        return `OK ${this.getApplicantName()}, let's move onto your financial and credit commitments. `;
    };

    getEndLabel = () => {
        return `Thank you for your patience ${this.getApplicantName()}. 
            As a final part of the financial section, can you please upload your latest credit report below
        `;
    };


    getQuestion = which => {
        switch (which) {
            case BLOCK_WELCOME:
                return {
                    block: (params) =>
                        <QuestionBlock {...params} controlButtons={null}>

                            <FieldLabel
                                label={this.getWelcomeLabel()}
                            />

                            <React.Fragment>
                                <div className="form-group mb-4">
                                    <div className="col-form-note">
                                        At the end of this section you will need to upload your current credit report. If you don't have one,
                                    you can normally get one for free from Experian (see our <a href="https://www.holidaycottagemortgages.co.uk/information-checklist" target="_blank" rel="noopener noreferrer">Information checklist</a> to see how).
                                    </div>
                                </div>
                            </React.Fragment>

                            <FieldLabel
                                labelType={LABEL_TYPE.Description}
                                label={`We recommend that you use your report to cross check that you have not missed anything, like an old credit card.`}
                            />

                            <FormButton
                                text="Got it"
                                onClick={() => this.swiperNext()}
                            />
                            <FormImage imageType={IMAGE_PIGGY_BANK}/>
                        </QuestionBlock>,
                    canNext: () => true,
                    nextQuestionId: () => BLOCK_COMMITMENT_TYPES
                };

            case BLOCK_COMMITMENT_TYPES:
                return CommitmentTypes(this, BLOCK_CREDIT_CARDS);

            case BLOCK_CREDIT_CARDS:
                return CreditCardList(this, BLOCK_PERSONAL_LOANS);

            case BLOCK_PERSONAL_LOANS:
                return PersonalLoanList(this, BLOCK_CAR_FINANCE);

            case BLOCK_CAR_FINANCE:
                return CarFinanceList(this, BLOCK_OPERATING_LEASES);

            case BLOCK_OPERATING_LEASES:
                return OperatingLeasesList(this, BLOCK_OTHER_PURCHASES_USING_CREDIT);

            case BLOCK_OTHER_PURCHASES_USING_CREDIT:
                return OtherPurchaseList(this, BLOCK_OVERDRAFT);

            case BLOCK_OVERDRAFT:
                return OverdraftList(this, BLOCK_OTHER_LOANS);

            case BLOCK_OTHER_LOANS:
                return OtherLoanList(this, BLOCK_END);

            case BLOCK_END:
                return {
                    block: (params) =>
                        <QuestionBlock {...params}>
                            <FieldLabel
                                label={this.getEndLabel()}
                            />

                            <React.Fragment>
                                <div className="form-group">
                                    <div className="col-form-note">
                                    If you don't already have access to a credit report, you can usually obtain a free one with Experian (see our <a href="https://www.holidaycottagemortgages.co.uk/information-checklist" target="_blank" rel="noopener noreferrer">Information checklist</a> to see how).
                                    </div>
                                </div>
                            </React.Fragment>

                            <FieldFileUpload
                                multi={false}
                                {...this.getFormField(null, FIELDS.MS.CC.CreditReportDoc)}
                            />
                        </QuestionBlock>,
                    canNext: () => true,

                    validate: (context) => {
                        context
                            .mandatoryFiles(FIELDS.MS.CC.CreditReportDoc, null, "Please upload your credit report by clicking the +add button.");
                        ;
                    },

                    nextQuestionId: () => CAN_SUBMIT
                };

            default:
                return ErrorPart();
        }
    };

}

export default Credit;
