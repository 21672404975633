import * as React from "react";

import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import FieldDate from "common-form/components/FieldDate";
import FieldInput from "common-form/components/FieldInput";
import FieldLabel from "common-form/components/FieldLabel";
import { LABEL_TYPE } from "common-form/components/Field";

import QuestionBlock from "../../../../form/QuestionBlock";
import FieldTextArea from "../../../../form/components/FieldTextArea";

import {
    FIELDS
} from "common-hypotheca";


export default function BuildDetails(parent, nextBlockId) {

    function isPurchaseNewBuildWarranty() {
        return parent.getValue(FIELDS.MP.PD.PurchaseNewBuildWarranty) === true;
    }

    function isNotPurchasingBuildWarranty() {
        return parent.getValue(FIELDS.MP.PD.PurchaseNewBuildWarranty) === false;
    }

    return {

        block: (params)=>           

            <QuestionBlock {...params}>

                <FieldRadioYesNo
                    {...parent.getField(FIELDS.MP.PD.PurchaseNewBuild)}
                />

                {
                    parent.getValue(FIELDS.MP.PD.PurchaseNewBuild) === true && 

                    <>
                        <FieldLabel
                            label="If the property is a new build project, please tell us more about the situation below."
                            labelType={LABEL_TYPE.Description}
                        />

                        <div className="date-boxes">
                        <FieldDate
                            {...parent.getField(FIELDS.MP.PD.PurchaseNewBuildDateofComp)}
                            labelType={LABEL_TYPE.Small}
                        />
                        </div>

                        <FieldRadioYesNo
                            {...parent.getField(FIELDS.MP.PD.PurchaseNewBuildLoanInstallments)}
                            labelType={LABEL_TYPE.Small}
                        />

                        <FieldRadioYesNo
                            {...parent.getField(FIELDS.MP.PD.PurchaseNewBuildWarranty)}
                            labelType={LABEL_TYPE.Small}
                        />

                        <FieldTextArea
                            show={() => isPurchaseNewBuildWarranty() }
                            {...parent.getField(FIELDS.MP.PD.PurchaseNewBuildWarrantyDescr)}
                            labelType={LABEL_TYPE.Small}
                        />

                        <FieldInput
                            show={() => isNotPurchasingBuildWarranty() }
                            {...parent.getField(FIELDS.MP.PD.PurchaseNewBuildArchitectDetails)}
                            labelType={LABEL_TYPE.Small}
                        />
                    </>
                }

            </QuestionBlock>
        ,

        validate: (context) => {

            context
                .mandatory(FIELDS.MP.PD.PurchaseNewBuild)
                .mandatoryOrCleanup(parent.fieldValueTrue(FIELDS.MP.PD.PurchaseNewBuild),
                [
                    FIELDS.MP.PD.PurchaseNewBuildDateofComp,
                    FIELDS.MP.PD.PurchaseNewBuildLoanInstallments,
                    FIELDS.MP.PD.PurchaseNewBuildWarranty
                ])
                .mandatoryOrCleanup(isPurchaseNewBuildWarranty(), FIELDS.MP.PD.PurchaseNewBuildWarrantyDescr)
                .mandatoryOrCleanup(isNotPurchasingBuildWarranty(), FIELDS.MP.PD.PurchaseNewBuildArchitectDetails)
                ;

            // For new building, remove existing building details since they become irrelevant
            if (parent.fieldValueTrue(FIELDS.MP.PD.PurchaseNewBuild)) {
                context
                    .cleanupIrrelevantField(FIELDS.MP.PD.HolidayLetDateOfConstruction)
                    .cleanupIrrelevantField(FIELDS.MP.PD.HolidayLetLessTenYrsWarranty)
                    .cleanupIrrelevantField(FIELDS.MP.PD.HolidayLetLessTenYrsWarrantyDescr)
                    .cleanupIrrelevantField(FIELDS.MP.PD.HolidayLetLessTenYrsWarrantyArchitect);
            }
        },

        nextQuestionId: () => nextBlockId()
    };
};