import * as React from "react";

import QuestionBlock from "../../../../../form/QuestionBlock";

import {extractFieldName, FIELDS, OTHER} from "common-hypotheca";
import FieldInput from "common-form/components/FieldInput";
import FieldGroupAddable from "../../../../../form/components/FieldGroupAddable";
import FieldLabel from "common-form/components/FieldLabel";
import FieldRadioYesNo from "common-form/components/FieldRadioYesNo";
import { FieldDropdown } from "../../../../../form/components/dropdown";
import FieldDate from "common-form/components/FieldDate";

import { LABEL_TYPE } from "../../../../../form/components/Field";

export default function OverdraftList(parent, nextBlockId) {
    return {
        block: (params)=>
            <QuestionBlock {...params}>
                <FieldLabel
                    label="Please tell us about your overdraft"
                />

                <FieldGroupAddable
                    ref={params.groupAddableRef}
                    addButtonLabel="Add overdraft"
                    allowDeleteAll
                    deleteButtonLabel="Remove"
                    editButtonLabel="Edit"
                    saveButtonLabel="Save overdraft"
                    itemDescriber={(fieldsContainer) => {
                        if (fieldsContainer[extractFieldName(FIELDS.MS.CC.OverdraftName)] === undefined)
                            return 'An overdraft from unspecified provider';
                        else
                            return 'Overdraft from ' + fieldsContainer[extractFieldName(FIELDS.MS.CC.OverdraftName)];
                    }}
                    validate={(context) => {
                        context
                            .mandatory(FIELDS.MS.CC.OverdraftCurrentBal)
                            .mandatory(FIELDS.MS.CC.OverdraftName)
                            .mandatory(FIELDS.MS.CC.OverdraftPurpose)
                            .mandatory(FIELDS.MS.CC.OverdraftAcctNum)
                            .mandatory(FIELDS.MS.CC.OverdraftLimit)
                            .date(FIELDS.MS.CC.OverdraftGrantDate)
                            .mandatory(FIELDS.MS.CC.OverdraftCurrentBal)
                            .mandatory(FIELDS.MS.CC.OverdraftPayEachMonth)
                            .date(FIELDS.MS.CC.OverdraftFinalRepayDate)
                            .mandatory(FIELDS.MS.CC.OverdraftPaidOffCompletion)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.OverdraftPaidOffCompletion),
                                FIELDS.MS.CC.OverdraftPaidOffCompletionHow)
                            .mandatoryOrCleanup(
                                context.fieldValue(FIELDS.MS.CC.OverdraftPaidOffCompletion)
                                && context.fieldValue(FIELDS.MS.CC.OverdraftPaidOffCompletionHow) === OTHER,
                                FIELDS.MS.CC.OverdraftPaidOffCompletionHowOther
                            )
                            // Cleanup obsolete fields (if present in data)
                            .cleanupIrrelevantField(FIELDS.MS.CC.OverdraftCurrentIntRate)
                            .cleanupIrrelevantField(FIELDS.MS.CC.OverdraftIsSecOnProperty)
                            .cleanupIrrelevantField(FIELDS.MS.CC.OverdraftIsSecOnPropertyDescr)
                        ;
                    }}
                    {...parent.getFormField(null, FIELDS.MS.CC.Overdraft)}
                >

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OverdraftName}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OverdraftPurpose}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OverdraftAcctNum}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OverdraftLimit}
                    />

                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OverdraftGrantDate}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OverdraftCurrentBal}
                    />

                    <FieldInput
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OverdraftPayEachMonth}
                    />

                    <FieldDate
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OverdraftFinalRepayDate}
                    />

                    <FieldRadioYesNo
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OverdraftPaidOffCompletion}
                    />

                    <FieldDropdown
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.OverdraftPaidOffCompletion)] }
                        labelType={LABEL_TYPE.Small}
                        field={FIELDS.MS.CC.OverdraftPaidOffCompletionHow}
                    />

                    <FieldInput
                        show={(container) => container[extractFieldName(FIELDS.MS.CC.OverdraftPaidOffCompletion)]
                            && container[extractFieldName(FIELDS.MS.CC.OverdraftPaidOffCompletionHow)] === OTHER }
                        field={FIELDS.MS.CC.OverdraftPaidOffCompletionHowOther}
                        labelType={LABEL_TYPE.Small}
                        placeholder="Other method description"
                    />

                </FieldGroupAddable>

            </QuestionBlock>,
 
 
        validate: (context) => {

            if (!context.hasItems(FIELDS.MS.CC.Overdraft)) {
                context.addError(null, null, "Please enter an item or go back to the previous screen and change your selection.");
            }

            if (parent.groupAddableRef && !parent.groupAddableRef.current.valid()) {
                context.addError(FIELDS.MS.CC.Overdraft, null, '');
            }

        },

        nextQuestionId: () => parent.nextSelectedCommitmentType(nextBlockId)
    };

}