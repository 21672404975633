import React from 'react';

class HtmlUtil {
    static renderFullRows() {
        let i = 0;

        return (
            <React.Fragment>
                {
                    [...arguments].map((value) => {
                        return (
                            <div key={i++} className='row' >
                                <div className='col-12'>
                                    {value}
                                </div>
                            </div>);
                    })
                }
            </React.Fragment>
        );
    };
}

export default HtmlUtil;